import { createAction, createReducer } from '@reduxjs/toolkit';

const pesList = createAction('PES_LIST');

const initialState = [];

const PesList = createReducer(initialState, (builder) => {
  builder
    .addCase(pesList, (state, action) => action.pesList)
    .addDefaultCase(state => state);
});

export default PesList;
