// Libraries
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, ButtonGroup, Button } from 'react-bootstrap';
import _isNaN from 'lodash/isNaN';
// Constants
import { TIME_SELECTOR } from '../../constants/constants';

export const TimeSelector = ({ time, setTime }) => {
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');
  const [period, setPeriod] = useState('');

  const validTime = (number, minValue, maxLimit, setValue) => {
    const validValue = !_isNaN(number) ? number : 0;
    const value = Math.max(0, Math.min(maxLimit, Number(validValue)));
    const validMinValue = minValue < 0 ? Math.abs(minValue) : minValue;

    const transformedValue = value !== 0 ? value : validMinValue.toString().slice(0, 2);
    setValue(transformedValue);
  };

  const getHour = (internalHour) => {
    if (internalHour > 12) return internalHour - 12;
    if (internalHour === 0) return 12;
    return internalHour;
  };

  const getPeriodHour = () => {
    if (hour === 12) {
      if (period === TIME_SELECTOR.AM) return 0;
      return 12;
    }
    if (period === TIME_SELECTOR.AM) return hour;
    return hour + 12;
  };

  const handleTime = () => {
    let customTime = time;
    if (!moment.isMoment(customTime)) customTime = moment();

    customTime.set(TIME_SELECTOR.HOUR, getPeriodHour());
    customTime.set(TIME_SELECTOR.MINUTE, minute);

    setTime(customTime);
  };

  const isButtonActive = timePeriod => (period === timePeriod ? 'focus' : '');

  useEffect(() => {
    if (time && moment.isMoment(time)) {
      const internalHour = time.get(TIME_SELECTOR.HOUR);
      setHour(getHour(internalHour));
      setMinute(time.get(TIME_SELECTOR.MINUTE));
      setPeriod(internalHour >= 12 ? TIME_SELECTOR.PM : TIME_SELECTOR.AM);
    }
  }, []);

  useEffect(() => {
    if (hour && minute !== '' && period) {
      handleTime();
    } else setTime(null);
  }, [hour, minute, period]);

  return (
    <Form.Group controlId="formAppointmentTime" className="time-selector">
      <Form.Label>Time</Form.Label>
      <div className="d-flex">
        <Form.Control
          name="hourInput"
          type="number"
          autoComplete="off"
          placeholder="HH"
          className="border bg-ccm-light-gray p-2"
          value={hour}
          onChange={e => validTime(e.target.value, '', 12, setHour)}
          data-test="timeSelector_hourInput"
        />
        <span className="align-self-center mx-2">:</span>
        <Form.Control
          name="minuteInput"
          type="number"
          autoComplete="off"
          placeholder="MM"
          className="border bg-ccm-light-gray p-2"
          value={minute}
          min={0}
          onChange={e => validTime(e.target.value, e.target.value, 59, setMinute)}
          data-test="timeSelector_minuteInput"
        />
        <ButtonGroup vertical className="ml-2">
          <Button
            variant="info-gray"
            className={isButtonActive(TIME_SELECTOR.AM)}
            onClick={() => setPeriod(TIME_SELECTOR.AM)}
            data-test="timeSelector_buttonAm"
          >
            {TIME_SELECTOR.AM}
          </Button>
          <Button
            variant="info-gray"
            className={isButtonActive(TIME_SELECTOR.PM)}
            onClick={() => setPeriod(TIME_SELECTOR.PM)}
            data-test="timeSelector_buttonPm"
          >
            {TIME_SELECTOR.PM}
          </Button>
        </ButtonGroup>
      </div>
    </Form.Group>
  );
};

export default TimeSelector;
