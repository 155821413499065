import { createAction, createReducer } from '@reduxjs/toolkit';

const setDefaultSearchParams = createAction('SET_DEFAULT_SEARCH_PARAMS');
const openSearch = createAction('OPEN_SEARCH');
const closeSearch = createAction('CLOSE_SEARCH');
const resetSearch = createAction('RESET_SEARCH');
const resetSidebarSearch = createAction('RESET_SIDEBAR_SEARCH');
const search = createAction('SEARCH');
const loadSession = createAction('LOAD_SESSION');
const loadEmptySession = createAction('LOAD_EMPTY_SESSION');
const paginate = createAction('PAGINATE');
const sort = createAction('SORT');
const logout = createAction('LOGOUT');


const initialState = {
  active: false,
  isSessionLoaded: false,
  searchParams: {},
  isSearchParamsOverwritten: true,
};

const Search = createReducer(initialState, (builder) => {
  builder
    .addCase(setDefaultSearchParams, (state, action) => {
      state.searchParams = action.searchParams
        || { ...initialState.searchParams };
    })
    .addCase(openSearch, (state) => {
      state.active = true;
      state.isSearchParamsOverwritten = false;
    })
    .addCase(closeSearch, (state) => {
      state.active = false;
      state.isSearchParamsOverwritten = false;
    })
    .addCase(resetSearch, (state) => {
      state.searchParams = initialState.searchParams;
    })
    .addCase(resetSidebarSearch, (state) => {
      const initialStatus = state.searchParams && state.searchParams.status ? state.searchParams.status : '';
      state.searchParams = {
        ...initialState.searchParams, ...(initialStatus && { status: initialStatus }),
      };
    })
    .addCase(search, (state, action) => {
      state.searchParams = action.searchParams;
      state.pageNumber = 0;
      state.isSearchParamsOverwritten = action.isSearchParamsOverwritten;
    })
    .addCase(loadSession, (state, action) => {
      state.searchParams = action.searchParams
        || { ...initialState.searchParams, isSessionLoaded: true };
      state.pageNumber = action.pageNumber;
      state.pageSize = action.pageSize;
      state.sortParams = action.sortParams;
      state.isSessionLoaded = true;
      state.isSearchParamsOverwritten = true;
    })
    .addCase(loadEmptySession, (state, action) => {
      const { cnPesId } = action;
      state.searchParams = action.searchParams
        || {
          ...initialState.searchParams,
          isSessionLoaded: true,
          ...(cnPesId && { careNavigatorId: cnPesId }),
        };
      state.isSessionLoaded = true;
    })
    .addCase(paginate, (state, action) => {
      state.pageNumber = action.pageNumber;
      state.pageSize = action.pageSize;
      state.isSearchParamsOverwritten = false;
    })
    .addCase(sort, (state, action) => {
      state.sortParams = action.sortParams;
      state.isSearchParamsOverwritten = false;
    })
    .addCase(logout, () => initialState)
    .addDefaultCase(state => state);
});

export default Search;
