// Libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import _orderBy from 'lodash/orderBy';
import _head from 'lodash/head';
import { connect } from 'react-redux';
// Assets
import videoAlmost from '../../videos/almost.mp4';
import videoCongrats from '../../videos/congrats.mp4';
import videoAnniversary from '../../videos/anniversary.mp4';
// Services
import { updateNotification } from '../../services/notifications';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// actions
import ShowNotification from '../../actions/notification';
import { UpdateAnniversary } from '../../actions/user';

const VIDEOS = [
  { type: 'PATIENTS_BILLED', src: videoCongrats, priority: 1 },
  { type: 'PATIENTS_BILLED_ALMOST', src: videoAlmost, priority: 2 },
  { type: 'ANNIVERSARY', src: videoAnniversary, priority: 3 },
];

export function AnimatedModal(props) {
  const {
    isModalOpen, setIsModalOpen, notificationList, showNotification, updateAnniversary,
  } = props;

  const [video, setVideo] = useState('');

  const getVideo = (type) => {
    const mediaSource = VIDEOS.find(el => el.type === type);
    return mediaSource ? setVideo(mediaSource.src) : setVideo('');
  };

  const markAsRead = (id) => {
    const updateNotificationRequest = updateNotification(id);
    const updateNotificationPromise = updateNotificationRequest.promise;

    updateNotificationPromise.then(() => {
      delete updateNotificationRequest.promise;
    }).catch((error) => {
      delete updateNotificationRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to remove notification',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  // Two billing notifications at the same time
  const billingNotifications = (notifications, billings) => billings.every(
    bill => notifications.map(item => item.type).includes(bill),
  );

  useEffect(() => {
    if (notificationList && notificationList.length > 0) {
      if (billingNotifications(notificationList, ['PATIENTS_BILLED', 'PATIENTS_BILLED_ALMOST'])) {
        notificationList.forEach((notification) => {
          if (notification.type !== 'ANNIVERSARY') markAsRead(notification.id);
        });

        // Show only the main billing notification
        const order = _orderBy(VIDEOS, 'priority');
        return getVideo(_head(order).type);
      }
      const { type, id } = _head(notificationList);
      if (type !== 'ANNIVERSARY') markAsRead(id);
      return getVideo(type);
    }
    return () => setVideo('');
  }, [notificationList]);

  const handleCloseModal = () => {
    if (notificationList.length === 1 && _head(notificationList).type === 'ANNIVERSARY') {
      updateAnniversary(false);
    }
    setIsModalOpen(false);
  };

  // Generate each confetti
  const renderConfetti = () => new Array(...Array(500)).map(
    (item, index) => <div key={index} className={`confetti-${index}`}>{item}</div>,
  );

  return (
    <Modal
      closeTimeoutMS={0}
      isOpen={isModalOpen}
      className="ccm-modal"
      overlayClassName="ccm-modal-wrapper animated-modal"
      contentLabel="User notification"
      onRequestClose={() => handleCloseModal()}
      data-test="animatedModal_onRequestClose"
    >
      {renderConfetti()}
      <video loop muted autoPlay preload="none" disablePictureInPicture>
        <source src={video} type="video/mp4" />
        Your browser does not support videos
      </video>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updateAnniversary: value => dispatch(UpdateAnniversary(value)),
  };
}

export default connect(null, mapDispatchToProps)(AnimatedModal);
