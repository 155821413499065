// libraries
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
// Config
import { store } from './config/store';
// views
import App from './App';
import NavigateSetter from './config/history';
// Hooks
import useTenant from './hooks/useTenant';
// Helpers
import { setKeyCloakTokens } from './services/mainHelpers';

const KEYCLOAK_INIT_OPTIONS = {
  responseMode: 'query',
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/index.html`,
};

export const MainApp = () => {
  const { keycloak, tenantError } = useTenant();

  const getEvent = (eventType) => {
    if (keycloak.authenticated && eventType === 'onReady') {
      setKeyCloakTokens(keycloak);
    }
  };

  return (
    tenantError ? (
      <div>{tenantError.message}</div>
    ) : (
      keycloak && (
      <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={getEvent}
        initOptions={KEYCLOAK_INIT_OPTIONS}
      >
        <Provider store={store}>
          <Router>
            <NavigateSetter />
            <App />
          </Router>
        </Provider>
      </ReactKeycloakProvider>)
    )
  );
};

export default MainApp;
