// libraries
import React, { Component } from 'react';
// constants
import { OFFICE_ADDRESS } from '../../../constants/constants';

export class LicenceAgreement extends Component {
  pageTop = React.createRef();

  componentDidMount() {
    if (this.pageTop && this.pageTop.current) {
      this.pageTop.current.scrollIntoView();
    }
  }

  render() {
    return (
      <div ref={this.pageTop}>
        <div className="terms-of-agreements licence-agreement">
          <div className="title-section my-3">
            <div className="title d-flex-center" data-test="licenceAgreement_header">SELECTPATIENT MANAGEMENT END USER LICENSE AGREEMENT</div>
            <div className="subtitle d-flex-center">Last updated:  April 24, 2024</div>
          </div>
          <p>
            The SelectPatient Management Chronic Care Management Solution, Clinical Intelligence
            Platform, associated mobile applications, Content, Services, Materials, related
            documentation, and any other information provided by SelectPatient Management
            relating thereto, as such may be modified from time to time by SelectPatient
            Management (collectively, the
            <b> &ldquo;CCM System&rdquo;</b>
            ) is provided by SelectPatient Management (
            <b> &ldquo;SelectPatient Management&rdquo;</b>
            or
            <b> &ldquo;us&rdquo;</b>
            or
            <b> &ldquo;our&rdquo;</b>
            ) to you, individually, and to your employer or contracting institution (collectively,
            <b> &ldquo;Licensee&rdquo;</b>
            or
            <b> &ldquo;you&rdquo;</b>
            or
            <b> &ldquo;your&rdquo;</b>
            ) under license ONLY IF YOU ACCEPT ALL OF THE TERMS IN THIS END USER LICENSE AGREEMENT
            (this
            <b> &ldquo;Agreement&rdquo;</b>
            ). SelectPatient Management is not willing to make the CCM System available, and you
            are not permitted to use the CCM System, under any other terms or subject to any
            other conditions.
          </p>
          <p>
            CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT. YOU ACKNOWLEDGE AND
            AGREE THAT BY ACCESSING OR USING THE CCM SYSTEM, BY CONTRIBUTING ANY DATA OR CONTENT
            FROM OR THROUGH THE CCM SYSTEM, OR DOWNLOADING ANY MATERIALS FROM THE CCM SYSTEM,
            YOU ARE INDICATING THAT YOU HAVE READ AND UNDERSTAND AND AGREE TO BE BOUND BY THE
            TERMS OF THIS AGREEMENT AND ARE AUTHORIZED TO BIND YOUR EMPLOYER OR CONTRATING
            INSTITUTION TO THE TERMS OF THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THE TERMS
            AND CONDITIONS OF THIS AGREEMENT, OR DO NOT OR ARE NOT ABLE TO REPRESENT THE FOREGOING,
            YOU MAY NOT USE THE CCM SYSTEM. Any use of the CCM System other than pursuant to the
            termsof this Agreement is a violation of SelectPatient Management’s rights and U.S. and
            international copyright laws and conventions.
          </p>
          <p>
            Should you have any questions regarding this Agreement, or wish to
            contact SelectPatient Management, please write to:
          </p>
          <address>
            SelectPatient Management
            <br />
            {OFFICE_ADDRESS.STREET}
            <br />
            {OFFICE_ADDRESS.UNIT}
            <br />
            {OFFICE_ADDRESS.CITY_STATE_ZIP}
            <br />
            Attn: Privacy Officer
          </address>
          <ol>
            <li>
              <b>Definitions. </b>
              <ol className="terms-alpha-list">
                <li>
                  <b>&ldquo;Content&rdquo; </b>
                  means any textual, graphical, pictorial, Materials, or other content
                  that is displayed or disseminated to Licensee through the CCM System,
                  and excluding Licensee Content.
                </li>
                <li>
                  <b>&ldquo;Covered Entity Employer&rdquo; </b>
                  means the Covered Entity for which Licensee performs healthcare-related
                  services as a partner, member, employee or other &ldquo;workforce member&rdquo;
                  (as such terms are defined in the HIPAA Rules).
                </li>
                <li>
                  <b>&ldquo;Device&rdquo; </b>
                  means any desktop computer, tablet, mobile device or other computing
                  system supported by SelectPatient Management.
                </li>
                <li>
                  <b>&ldquo;Licensee Content&rdquo; </b>
                  means any material that Licensee posts or otherwise transmits to or
                  through the CCM System or the Services, including without limitation any
                  messages, communications, images, text or other information or data.
                </li>
                <li>
                  <b>&ldquo;Materials&rdquo; </b>
                  means patient-specific clinical information, notes and recommendations and
                  related reports, in any format and media, to physicians and other members
                  of a patient’s care team designated by such physicians.
                </li>
                <li>
                  <b>&ldquo;Services&rdquo; </b>
                  means the services provided by SelectPatient Management in connection with use
                  of the CCM System, which Licensee accesses via an Internet connection provided
                  by Licensee, Covered Entity Employer, or a third party.
                </li>
              </ol>
            </li>
            <li>
              <b>License Grant and Restrictions. </b>
              <ol className="terms-alpha-list">
                <li>
                  <u>License Grant.</u>
                  Subject to the terms and conditions of this Agreement, SelectPatient Management
                  hereby grants to Licensee a non-exclusive, limited, revocable,
                  non-transferable, non-sublicensable license to (i) use the CCM System on or
                  through one or more Devices, (ii) install mobile application versions of the
                  CCM System, and (iii) view, print and download the Materials from the CCM
                  System solely for the purpose of treating the patient to whom such Materials
                  corresponds, in each case ((i), (ii), and (iii)) to access and use the CCM
                  System solely within a web-based portal or mobile application environment for
                  purposes related to the treatment of patients by Licensee, including but not
                  limited to communication of Licensee Content to authorized users of the CCM
                  System. The license rights granted to Licensee hereunder are expressly
                  conditioned upon Licensee’s compliance at all times with the terms and
                  conditions of this Agreement.
                </li>
                <li>
                  <u>License Restrictions.</u>
                  Licensee shall not, and shall ensure that others do not:
                  <ol className="terms-roman-list">
                    <li>
                      use, print, display, download, publish, disclose, transmit, record, copy,
                      encumber, transfer, assign, distribute or make available the CCM System, in
                      whole or in part, for any purpose whatsoever other than for the treatment of
                      patients, including but not limited to communication of Licensee Content to
                      other authorized users of the CCM System;
                    </li>
                    <li>
                      modify, translate, adapt, reverse engineer, decompile, disassemble, or
                      otherwise translate any portion of the CCM System, incorporate the CCM System,
                      in whole or in part, in any other product, or create derivative works based on
                      or derived from the CCM System, in whole or in part;
                    </li>
                    <li>
                      print, display, download, record, copy, remove, publish, disclose, transmit,
                      transfer, or distribute any Content for any purpose whatsoever outside the
                      context of the CCM System environment, provided, however, that Content that is
                      disseminated to Licensee through e-mail communications from SelectPatient
                      Management or imported by Licensee into Licensee’s or Covered Entity
                      Employer’s electronic medical record system may be utilized consistent with
                      the license grant of Section 2.a. and the limitations of Section 2.b.i.
                      above;
                    </li>
                    <li>
                      use the CCM System or the Services to provide any facility management, time
                      sharing, service bureau, application hosting, application service provider
                      (ASP), or other similar services, act as an intermediary or provider, or
                      otherwise grant rights to third parties with regard to the CCM System;
                    </li>
                    <li>
                      rent, lease, sell, disclose, distribute, license, or sublicense the CCM System
                      to a third party; or
                    </li>
                    <li>
                      remove any copyright, patent, trademark, proprietary rights, disclaimer or
                      warning notice included on or embedded in any part of the CCM System.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Registration Requirements.</u>
                  Access to and use of the CCM System is limited to healthcare professionals and
                  certain additional authorized individuals who are employees or workforce members
                  of a healthcare professional or Covered Entity Employer. Licensee must be a United
                  States resident and at least 18 years of age.
                </li>
                <li>
                  <u>Registration Information.</u>
                  Licensee shall be required to provide certain information as part of the
                  registration process for use of the CCM System (
                  <b>&ldquo;Registration Information&rdquo;</b>
                  ). Licensee shall ensure that all Registration Information provided to
                  SelectPatient Management is true, complete, accurate, current and
                  non-misleading. SelectPatient Management reserves the right, but does not
                  undertake any obligation, to verify any and all Registration Information
                  (including without limitation details of Licensee’s employment or workforce
                  member status with a Covered Entity Employer or Licensee’s professional
                  licensure information, as applicable), and may in its sole discretion restrict
                  or deny access to all or part of the CCM System. Licensee shall promptly notify
                  SelectPatient Management of any changes to the Registration Information to keep
                  it true, complete, accurate, current and non-misleading.
                </li>
                <li>
                  <u>Passwords.</u>
                  Licensee shall create a unique password in connection with the registration
                  process for use of the CCM Systems. Licensee shall not provide, share or otherwise
                  allow anyone other than Licensee to use such unique password, and Licensee is
                  solely responsibility for protecting such password from unauthorized access or
                  use, and is fully responsible and liable for any unauthorized access to and use of
                  the CCM System in connection therewith. If Licensee discovers unauthorized access
                  or use of Licensee’s account or password, or Licensee’s password is lost or
                  stolen, Licensee shall promptly notify SelectPatient Management.
                </li>
                <li>
                  <u>Acceptable Use of CCM System.</u>
                  Licensee shall not, and shall ensure that others do not:
                  <ol className="terms-roman-list">
                    <li>
                      use the CCM System for any purposes or in any manner contrary to applicable
                      laws or regulations or the terms of this Agreement;
                    </li>
                    <li>
                      access, collect, attempt to access or collect, or solicit any information
                      about another user of the CCM System, including without limitation another
                      user’s account name, password, email address, or any personal information,
                      without that user’s knowledge and express consent;
                    </li>
                    <li>
                      forge headers or other elements of network transmissions to disguise the
                      origin of any material transmitted through the CCM System to SelectPatient
                      Management or to any third party;
                    </li>
                    <li>
                      upload or attempt to upload content from the CCM System to another user’s
                      account by any method without that user’s express authorization and consent;
                    </li>
                    <li>
                      override or attempt to override any security measure or component implemented
                      in the CCM System;
                    </li>
                    <li>
                      create a user account on the CCM System purporting to describe a fictional
                      person or character, any individual other than yourself, or any individual or
                      entity other than one whom you have been authorized to represent;
                    </li>
                    <li>
                      use any automated software or scripts to access any portion of the CCM System;
                    </li>
                    <li>
                      use the CCM System to engage in illegal or abusive behavior, including gaining
                      unauthorized access to or use of any data, services, systems or network, or
                      probing, scanning or testing the vulnerabilities of any systems or networks;
                    </li>
                    <li>
                      impersonate, or use the account, login or registration information of any
                      other person or entity; or
                    </li>
                    <li>
                      interfere in any way with the operation of the CCM System or any server,
                      network or system associated with the CCM System, including, without
                      limitation: hacking, mail-bombing, flooding, overloading, or making “denial of
                      service” attacks; probing, scanning or testing the vulnerability of the CCM
                      System or any server, network or system associated with the Cohort System;
                      breaching or circumventing firewall, encryption, security or authentication
                      routines; accessing data not intended for you, or accessing another’s account
                      that you are not expressly authorized to access.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Restrictions on Communications via CCM System.</u>
                  You shall not, and shall ensure that others do not, do or attempt to use the CCM
                  System to post, email, link to, or otherwise transmit, communicate, or make
                  available any material that:
                  <ol className="terms-roman-list">
                    <li>
                      is offensive, obscene, pornographic, discriminatory, abusive, defamatory,
                      libelous, harassing, threatening, menacing, or otherwise harmful;
                    </li>
                    <li>
                      is likely to harm Cohort’s computer systems or any third party’s computer
                      systems, including without limitation material containing any malware,
                      spyware, virus, worm, Trojan horse, or other data or code that may cause any
                      defect, error, malfunction, corruption, denial of service, or other undesired
                      modification;
                    </li>
                    <li>
                      is
                      &ldquo;spam,&rdquo;
                      &ldquo;junk mail,&rdquo;
                      &ldquo;chain letters,&rdquo;
                      &ldquo;pyramid schemes,&rdquo;
                      or any other
                      manner of improper solicitation;
                    </li>
                    <li>
                      infringes any copyright, trademark,
                      patent, or other intellectual property right, or improperly discloses or
                      misappropriates trade secrets or other confidential or proprietary
                      information, in each case whether it belongs to Cohort or to a third party;
                    </li>
                    <li>
                      would harm minors in any way, including without limitation material that
                      violates child pornography laws, child sexual exploitation laws, or laws
                      prohibiting the depiction of minors engaged in sexual conduct.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Protected Health Information.</u>
                  If Licensee (in those cases where Licensee is a Covered Entity in their own right)
                  or Licensee’s Covered Entity employer has not executed a Business Associate
                  Agreement with SelectPatient Management, Licensee may not create, transmit or
                  maintain Protected Health Information using the CCM System.
                </li>
                <li>
                  <u>Updates; No Commitment of Support.</u>
                  Portions of the CCM System may launch when, and continue to run while Licensee’s
                  Devices are launched and running. SelectPatient Management may, in its sole
                  discretion (without obligation) modify or provide updates for the CCM System,
                  which may include bug fixes, patches and other error corrections and/or new
                  features (collectively,
                  <b>&ldquo;Updates&rdquo;</b>
                  ) at any time, for any reason, and without providing
                  notice of such modification or update to Licensee. Updates may also modify or
                  delete in their entirety certain features and functionality. This license shall
                  apply to any such Updates which are rightfully obtained by Licensee unless
                  expressly stated otherwise. This license does not grant Licensee any right to any
                  maintenance or services, including without limitation, any support, enhancement,
                  modification, Update or upgrade to the CCM System, and SelectPatient Management
                  is under no obligation to provide or inform Licensee of any such Updates.
                  SelectPatient Management may, from time to time, require the Licensee to
                  install Updates to mobile application versions of the CCM System as a condition
                  to continued use of such versions of the CCM System.
                </li>
                <li>
                  <u>Reservation of Rights.</u>
                  SelectPatient Management reserves the right at any time and for any reason,
                  with or without notice, and without liability to you or any other user, to:
                  <ol className="terms-roman-list">
                    <li>
                      modify, suspend or terminate the operation or any feature of the CCM System;
                    </li>
                    <li>
                      restrict, limit, suspend or terminate your and/or any other user’s access to
                      the CCM System;
                    </li>
                    <li>
                      monitor any user’s use of the CCM System to verify compliance with this
                      Agreement and/or any applicable law;
                    </li>
                    <li>
                      investigate any suspected or alleged misuse of the CCM System and cooperate
                      with law enforcement and/or third-parties in such investigation; and
                    </li>
                    <li>
                      disclose information about any user’s use of the CCM System
                      in connection with law enforcement investigation of alleged illegal or
                      unauthorized activity, or in response to a lawful court order or subpoena.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Third Party Software.</u>
                  The CCM System may contain or make use of third-party software that is protected
                  by copyright and other proprietary rights of SelectPatient Management
                  licensors. Licensee may use such third-party software solely
                  <ol className="terms-alpha-list">
                    <li>
                      for the purpose such software is included with the CCM System and for uses
                      permitted by this Agreement only, and
                    </li>
                    <li>
                      in accordance with any and all additional license terms and conditions which
                      may apply to the use of such third-party software. Licensee shall not use,
                      copy, license, or distribute any third-party software (in whole or in part)
                      on a standalone basis or in any way independently from the CCM System.
                    </li>
                  </ol>
                  Licensee may be held directly liable by SelectPatient Management’s licensors for
                  Licensee’s actions relating to the third-party software that are not
                  authorized by this Agreement.
                </li>
                <li>
                  <u>Links to Third Party Web Sites.</u>
                  By using any link to a third party site on the CCM System, you are leaving the CCM
                  System. Links to third party web sites are provided solely as a convenience to
                  you. SelectPatient Management has not reviewed these third party sites and does
                  not control and is not responsible for any of these sites or their content.
                  SelectPatient Management does not endorse or make any representations about
                  these third party sites, any information, software or other products or
                  materials found there, or any results that may be obtained from using them.
                  Your access of any third party site is at your own risk.
                </li>
              </ol>
            </li>
            <li>
              <b>Confidentiality; Privacy Policy. </b>
              <ol className="terms-alpha-list">
                <li>
                  <u>Definition.</u>
                  <b>&ldquo;Confidential Information&rdquo; </b>
                  means any information, technical data, or know-how, including, but not limited to,
                  that which relates to research, product plans, products, services, customers,
                  markets, software, developments, inventions, processes, designs, drawings,
                  engineering, hardware or software configuration information, marketing,
                  organization, business or finances of or relating to SelectPatient Management,
                  or any information obtained by SelectPatient Management in confidence or in
                  trust from a third party. Confidential Information shall also include, without
                  limitation, the CCM System, Content, and Services. Confidential Information
                  does not include information, technical data or know-how which (i) is Protected
                  Health Information provided by SelectPatient Management to Licensee or Covered
                  Entity Employer pursuant to the Master Services Agreement; (ii) is already in
                  the possession of Licensee without obligations of confidentiality at the time
                  of its disclosure by SelectPatient Management; (iii) is or becomes publicly
                  available other than by any actions or inactions of Licensee; or (iv) is
                  approved by SelectPatient Management, in writing, for public release.
                </li>
                <li>
                  <u>Confidentiality Obligations.</u>
                  Except as expressly set forth in this Agreement, Licensee shall not use in any way
                  for its own account or the account of any third party, nor disclose to any third
                  party, any Confidential Information. Licensee shall at all times maintain such
                  Confidential Information in confidence in the same manner and to the same extent
                  as Licensee protects its own most confidential and/or proprietary information
                  (which shall in no event be less than a reasonable degree of care). Upon the
                  expiration or termination of this Agreement or request of SelectPatient
                  Management, Licensee shall promptly return to SelectPatient Management or
                  delete all copies of any Confidential Information in Licensee’s possession as
                  directed by SelectPatient Management.
                </li>
                <li>
                  <u>Feedback.</u>
                  Licensee may from time to time provide suggestions, comments or other feedback to
                  SelectPatient Management regarding SelectPatient Management’s current or future
                  products and services, including the CCM System and the Services
                  (collectively, &ldquo;Feedback&rdquo;).
                  Licensee agrees that such Feedback, even if designated as confidential by
                  Licensee, shall not create any confidentiality obligation hereunder for or upon
                  SelectPatient Management or its suppliers. Except as otherwise provided herein
                  or in a separate subsequent written agreement signed by authorized
                  representatives of the parties, Licensee agrees that SelectPatient Management
                  shall be free to use, disclose, reproduce, license or otherwise distribute, and
                  exploit any and all Feedback provided to it as it sees fit, entirely without
                  obligation or restriction of any kind on account of intellectual property or
                  otherwise.
                </li>
                <li>
                  <u>Privacy Policy.</u>
                  Licensee acknowledges and agrees that Licensee has read the SelectPatient
                  Management Privacy Policy, which is available at&nbsp;
                  <a href="https://www.engooden.com/privacy-policy" rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                  </a>
                  , which may be amended from
                  time to time by SelectPatient Management, and consents to the handling and
                  processing of personal information that may be collected from or provided by
                  Licensee as set forth therein.
                </li>
              </ol>
            </li>
            <li>
              <b>HIPAA; DMCA.</b>
              <ol className="terms-alpha-list">
                <li>
                  <u>Standards and Regulations.</u>
                  The U.S. Department of Health and Human Services has issued regulations on
                  &ldquo;Standards for Privacy of Individually Identifiable Health
                  Information&rdquo; comprising 45 C.F.R. Parts 160 and 164, Subparts A and E (the
                  <b> &ldquo;Privacy Standards&rdquo;</b>
                  ), &ldquo;Security Standards for the Protection of Electronic Protected Health
                  Information&rdquo; comprising 45 C.F.R. Parts 160 and 164, Subpart C (the
                  <b> &ldquo;Security Standards&rdquo;</b>
                  ), &ldquo;Standards for Notification in the Case of Breach of Unsecured Protected
                  Health Information&rdquo; comprising 45 C.F.R. Parts 160 and 164, Subpart D (the
                  <b> &ldquo;Breach Notification Standards&rdquo;</b>
                  ), and &ldquo;Rules for Compliance and Investigations, Impositions of Civil
                  Monetary Penalties, and Procedures for Hearings&rdquo; comprising
                  45 C.F.R. Part 160, Subparts C, D, and E (the
                  <b> &ldquo;Enforcement Rule&rdquo;</b>
                  ), promulgated pursuant to the Health Insurance Portability and Accountability
                  Act of 1996 (
                  <b>&ldquo;HIPAA&rdquo;</b>
                  ), the Health Information Technology for Economic and Clinical Health Act (
                  <b>&ldquo;HITECH Act&rdquo;</b>
                  ), and the Genetic Information and Nondiscrimination Act of 2008 (
                  <b>&ldquo;GINA&rdquo;</b>
                  )(the Privacy Standards, the Security Standards, the Breach Notification
                  Standards, and the Enforcement Rule are collectively referred to herein as the
                  <b>&ldquo;HIPAA Rules&rdquo;</b>
                  ). Capitalized terms not otherwise defined herein shall have the
                  meaning set forth in the HIPAA Rules.
                </li>
                <li>
                  <u>HIPAA.</u>
                  Among other things, HIPAA and the HIPAA Rules govern the use and disclosure of
                  Protected Health Information (
                  <b>&ldquo;PHI&rdquo;</b>
                  ) by Covered Entities and their Business
                  Associates. If Licensee is a Covered Entity in their own right, Licensee
                  represents it has entered into a Business Associate Agreement directly with
                  SelectPatient Management. If Licensee is not a Covered Entity, Licensee
                  represents that an authorized official at Licensee’s Covered Entity employer
                  has executed a Business Associate Agreement with SelectPatient Management
                  before Licensee has used the CCM System in a manner involving PHI. Licensee
                  shall not create, transmit or maintain PHI using the CCM System without a prior
                  properly executed Business Associate Agreement.
                </li>
                <li>
                  <u>PHI.</u>
                  To the extent that Licensee uses the CCM System to create, transmit or maintain
                  PHI, including but not limited to the communication of Licensee Content to other
                  authorized users of the CCM System for purposes related to treatment of patients,
                  Licensee understands and agrees as follows:
                  <ol className="terms-roman-list">
                    <li>
                      Licensee shall maintain full compliance with HIPAA and the HIPAA Rules and any
                      other laws, now or hereafter existing, that are applicable to Licensee,
                      directly or indirectly, with respect to the gathering, use, transmission,
                      processing, receipt, reporting, disclosure, maintenance or storage of health
                      information.
                    </li>
                    <li>
                      Licensee understands and agrees that information created, received,
                      transmitted, or maintained as a result of Licensee’s use of the CCM System and
                      the content of such messages are not intended to and do not constitute a
                      Designated Record Set under HIPAA or the HIPAA Rules. Licensee further
                      acknowledges and agrees that information created, received, or transmitted
                      using the CCM System or the Services may be available to Licensee for only a
                      limited period of time. Licensee is solely responsible for properly recording
                      any information that is used to make treatment decisions about a patient in
                      electronic medical records maintained by Licensee or Covered Entity Employer,
                      and that SelectPatient Management has no responsibility to maintain ongoing
                      access to such content.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Conflict with BAA.</u>
                  To the extent that any terms herein conflict with the terms set forth in the
                  Business Associate Agreement between SelectPatient Management and Licensee or
                  Covered Entity Employer, as appropriate, the terms in such Business Associate
                  Agreement shall govern.
                </li>
                <li>
                  <u>Digital Millennium Copyright Act Notifications.</u>
                  If you believe that any content hosted on the CCM System violates your rights
                  under the copyright law of the United States, you may file a notification of such
                  claimed infringement with SelectPatient Management’s designated agent as
                  described below.
                  <br />
                  By Mail:
                  <address>
                    SelectPatient Management
                    <br />
                    {OFFICE_ADDRESS.STREET}
                    <br />
                    {OFFICE_ADDRESS.UNIT}
                    <br />
                    {OFFICE_ADDRESS.CITY_STATE_ZIP}
                    <br />
                    Attn: Privacy Officer
                  </address>
                  <br />
                  By Telephone:
                  <address>
                    (415) 617-5912
                  </address>
                  <br />
                  By Email:
                  <address>
                    privacy@engooden.com
                  </address>
                </li>
                <li>
                  Pursuant to 17 U.S.C. 512(c)(3), the following information must be provided for a
                  valid notification of claimed copyright infringement: (1) a physical or electronic
                  signature of a person authorized to act on behalf of the owner of an exclusive
                  right that is allegedly infringed; (2) identification of the copyrighted work
                  claimed to have been infringed, or, if multiple copyrighted works at a single
                  online site are covered by a single notification, a representative list of such
                  works at that site; (3) identification of the material that is claimed to be
                  infringing or to be the subject of infringing activity and that is to be removed
                  or access to which is to be disabled, and information reasonably sufficient to
                  permit the service provider to locate the material; (4) information reasonably
                  sufficient to permit the service provider to contact the complaining party, such
                  as an address, telephone number, and, if available, an electronic mail address at
                  which the complaining party may be contacted; (5) a statement that the complaining
                  party has a good faith belief that use of the material in the manner complained of
                  is not authorized by the copyright owner, its agent, or the law; and (6) a
                  statement that the information in the notification is accurate, and under penalty
                  of perjury, that the complaining party is authorized to act on behalf of the owner
                  of an exclusive right that is allegedly infringed.
                </li>
                <li>
                  If your content has been removed from the CCM System due to a notification filed
                  against you, then you may file a counter-notification with SelectPatient
                  Management’s designated agent as indicated above. Pursuant to 17 U.S.C.
                  512(g)(3), the following information must be provided in the
                  counter-notification: (1) a physical or electronic signature of the subscriber;
                  (2) identification of the material that has been removed or to which access has
                  been disabled and the location at which the material appeared before it was
                  removed or access to it was disabled; (3) a statement under penalty of perjury
                  that the subscriber has a good faith belief that the material was removed or
                  disabled as a result of mistake or misidentification of the material to be
                  removed or disabled; and (4) the subscriber’s name, address, and telephone
                  number, and a statement that the subscriber consents to the jurisdiction of
                  Federal District Court for the judicial district in which the address is
                  located, or if the subscriber’s address is outside of the United States, for
                  any judicial district in which the service provider may be found, and that the
                  subscriber will accept service of process from the person who provided
                  notification or an agent of such person.
                </li>
                <li>
                  If SelectPatient Management receives a valid counter-notification, the Digital
                  Millennium Copyright Act provides that the removed material be restored or
                  access re-enabled.
                </li>
              </ol>
            </li>
            <li>
              <b>Intellectual Property. </b>
              <ol className="terms-alpha-list">
                <li>
                  <u>Ownership of the CCM System.</u>
                  As between Licensee and SelectPatient Management and its licensors,
                  SelectPatient Management or its licensors are the sole and exclusive owners of
                  all right, title and interest in and to the CCM Systems, including without
                  limitation any and all worldwide copyrights, patents, trade secrets,
                  trademarks, and intellectual property rights of any other kind related thereto
                  (collectively,
                  <b> &ldquo;Intellectual Property Rights&rdquo;</b>
                  ).
                  Nothing in this Agreement grants Licensee any right, title or interest in or to
                  any portions of the CCM System except for the limited express rights granted
                  pursuant to Section 2 of this Agreement. All rights not expressly granted herein
                  are reserved in and to SelectPatient Management or its licensors and no implied
                  licenses are granted to Licensee hereunder.
                </li>
                <li>
                  <u>Ownership of Licensee Content; License Grant.</u>
                  Licensee hereby grants to SelectPatient Management a perpetual, irrevocable,
                  royalty-free, fully paid up, transferable, sublicensable license to use,
                  modify, create derivative works of, reproduce, distribute, disclose, publish
                  and otherwise exploit any Licensee Content as required for SelectPatient
                  Management to provide the Services and as otherwise permitted pursuant to the
                  Business Associate Agreement.
                </li>
              </ol>
            </li>
            <li>
              <b>Disclaimers; Limitation of Liability. </b>
              <ol className="terms-alpha-list">
                <li>
                  <b>CONTENT AND MATERIALS DISCLAIMERS.</b>
                  ALL MATERIALS PROVIDED ON OR VIA THE CCM SYSTEM ARE PROVIDED “AS IS” WITHOUT ANY
                  REPRESENTATIONS OR WARRANTIES OF ANY KIND, INCLUDING WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT.
                  <br />
                  SelectPatient Management does not guarantee or warrant that files available for
                  downloading through the CCM System are or will be free of infection or viruses,
                  worms, Trojan horses, or other similar code that manifest contaminating or
                  destructive properties. You are solely responsible for implementing sufficient
                  procedures and checkpoints to satisfy your particular requirements for accuracy
                  of data input and output, and for maintaining a means external to the CCM
                  System for the reconstruction of any lost data. SelectPatient Management is not
                  responsible for the accuracy and/or completeness of any Content or Materials on
                  the CCM System nor does SelectPatient Management undertake any obligation to
                  ensure the CCM System is updated.
                </li>
                <li>
                  <b>DISCLAIMER OF WARRANTIES.</b>
                  SELECTPATIENT MANAGEMENT MAKES NO WARRANTY OF ANY KIND, EXPRESS, IMPLIED OR
                  STATUTORY, REGARDING THE CCM SYSTEM, CONTENT, OR SERVICES, INCLUDING WITHOUT
                  LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, TITLE AND NON-INFRINGEMENT AND ANY WARRANTIES OR CONDITIONS ARISING
                  OUT OF COURSE OF DEALING OR USAGE OF TRADE. LICENSEE ACKNOWLEDGES THAT
                  SELECTPATIENT MANAGEMENT HAS NOT REPRESENTED OR WARRANTED THAT LICENSEE’S
                  ACCESS TO OR USE OF THE CCM SYSTEM, CONTENT, OR SERVICES SHALL BE
                  UNINTERRUPTED, ERROR FREE, VIRUS-FREE, SECURE OR WITHOUT DELAY. LICENSEE HAS
                  NOT RELIED ON ANYTHING EXCEPT AS EXPRESSLY SET FORTH HEREIN IN DECIDING TO
                  ENTER INTO THIS AGREEMENT. SELECTPATIENT MANAGEMENT MAKES NO WARRANTIES THAT
                  THE CCM SYSTEM, CONTENT, OR SERVICES SHALL OPERATE IN CONJUNCTION WITH ANY OTHER
                  SOFTWARE, HARDWARE OR OTHER SYSTEMS. WITHOUT LIMITING THE GENERALITY OF THE
                  FOREGOING, SELECTPATIENT MANAGEMENT MAKES NO WARRANTY, AND LICENSEE BEARS THE SOLE
                  OBLIGATION AND RESPONSIBILITY, REGARDING (I) VERIFICATION OF THE IDENTITY OF ANY
                  OTHER USERS OF THE CCM SYSTEM WITH WHOM LICENSEE ELECTS TO COMMUNICATE, AND (II)
                  WHETHER ANY MESSAGES THAT LICENSEE ATTEMPTS TO SEND OR RECEIVE VIA THE CCM SYSTEM
                  ARE SUCCESSFULLY DELIVERED OR AVAILABLE FOR FUTURE REVIEW.
                </li>
                <li>
                  <b>LIMITATION OF LIABILITY. </b>
                  NOTWITHSTANDING ANYTHING ELSE IN THIS AGREEMENT, THE BUSINESS ASSOCIATE AGREEMENT
                  OR OTHERWISE, SELECTPATIENT MANAGEMENT SHALL NOT BE LIABLE OR OBLIGATED WITH
                  RESPECT TO ANYTHING RELATING TO THE SUBJECT MATTER OF THIS AGREEMENT (INCLUDING
                  WITHOUT LIMITATION FROM THE USE, INTERRUPTION IN USE, OR FAILURE OF THE CCM
                  SYSTEM, MATERIALS, AND/OR ANY HIPAA OR DATA SECURITY-RELATED MATTERS, SUCH AS
                  BREACHES OF UNSECURED PHI) UNDER ANY BREACH OF CONTRACT, BREACH OF WARRANTY,
                  TORT (INCLUDING NEGLIGENCE AND RELIANCE), STRICT LIABILITY OR OTHER LEGAL OR
                  EQUITABLE THEORY FOR ANY DAMAGES, INCLUDING DIRECT, INDIRECT, SPECIAL,
                  PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION LOSS
                  OF REVENUE OR GOODWILL, LOSS OF SAVINGS, LOSS OF DATA, LOSS OF USE, LOST
                  BUSINESS, LOST PROFITS OR DATA BREACHES), EVEN IF SELECTPATIENT MANAGEMENT HAS
                  BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE. LICENSEE’S SOLE AND
                  EXCLUSIVE REMEDY FOR ANY CLAIM ARISING UNDER THIS AGREEMENT IS TO DISCONTINUE
                  USE OF THE CCM SYSTEM.
                </li>
                <li>
                  <b>REMEDIES. </b>
                  IF APPLICABLE LAW RESTRICTS THE EXCLUSION OR LIMITATION OF DAMAGES, THEN THIS
                  SECTION 6 SHALL BE DEEMED TO EXCLUDE AND LIMIT SELECTPATIENT MANAGEMENT’S
                  LIABILITY FOR ANY SUCH DAMAGES TO THE MAXIMUM EXTENT PERMITTED BY LAW. THE
                  PROVISIONS OF THIS SECTION 6 ALLOCATE THE RISKS UNDER THIS AGREEMENT BETWEEN
                  THE PARTIES, AND THE PARTIES HAVE RELIED ON THE LIMITATIONS SET FORTH HEREIN IN
                  DETERMINING WHETHER TO ENTER INTO THIS AGREEMENT.
                </li>
              </ol>
            </li>
            <li>
              <b>Indemnification. </b>
              Licensee shall indemnify, defend and hold harmless SelectPatient Management, its
              subcontractors, licensors and suppliers, and their respective officers, directors,
              agents and employees, from and against any and all claims, losses, demands,
              liabilities, damages, costs and expenses (including reasonable attorneys’ fees),
              arising out of or relating to Licensee’s access to or use of the CCM System, in whole
              or in part.
            </li>
            <li>
              <b>Term and Termination. </b>
              <ol className="terms-alpha-list">
                <li>
                  <u>Term.</u>
                  This Agreement shall commence as of the date when
                  Licensee accepts this Agreement and shall continue until
                  terminated pursuant to the terms hereof.
                </li>
                <li>
                  <u>Suspension.</u>
                  SelectPatient Management may suspend Licensee’s access to all or any portion of
                  the CCM System at any time and for any reason.
                </li>
                <li>
                  <u>Termination.</u>
                  SelectPatient Management may terminate this Agreement at any time and for any
                  reason or no reason upon notice to Licensee. Licensee may terminate this
                  Agreement at any time by deleting all copies of the Content and any software
                  associated with the CCM System in its control and discontinuing all use of the
                  CCM System. This Agreement shall automatically and immediately terminate without
                  notice to Licensee if Licensee breaches any term of this Agreement.
                </li>
                <li>
                  <u>Survival.</u>
                  Sections 1, 2(b), 2(e), 3, 5-7, 8(d) and 9 shall survive any expiration or
                  termination of this Agreement.
                </li>
              </ol>
            </li>
            <li>
              <b>Miscellaneous. </b>
              <ol className="terms-alpha-list">
                <li>
                  <u>Equitable Relief.</u>
                  Licensee hereby acknowledges that unauthorized disclosure or use of any portion of
                  the CCM System would cause irreparable harm and significant injury to
                  SelectPatient Management that may be difficult to ascertain. Accordingly,
                  Licensee agrees that SelectPatient Management shall have the right to seek
                  immediate injunctive relief, without posting a bond or other security, to
                  enforce Licensee’s obligations under this Agreement in addition to any other
                  rights and remedies SelectPatient Management may have.
                </li>
                <li>
                  <u>Export Restrictions.</u>
                  Licensee shall not directly or indirectly export, re-export or transport the CCM
                  System, in whole or in party, or any technical data or personal information of any
                  type received under this Agreement in any form without the prior written consent
                  of SelectPatient Management and the appropriate United States and foreign
                  government licenses and in full compliance with all applicable national and
                  international laws that apply to the CCM System, including the United States
                  Export Administration Regulations, as well as end-user, end-use and destination
                  restrictions issued by the United States and other governments. Licensee’s failure
                  to comply with this provision is a material breach of this Agreement.
                </li>
                <li>
                  <u>Assignment.</u>
                  Licensee shall not assign this Agreement or any portion hereof without
                  SelectPatient Management’s prior written approval. Any purported assignment in
                  violation of the foregoing shall be null and void. This Agreement shall be
                  binding upon and accrue to the benefit of any permitted successors and assigns.
                </li>
                <li>
                  <u>Governing Law.</u>
                  This Agreement shall be governed by the internal substantive laws of the State of
                  Delaware, without regard to its conflict of laws provisions. The parties expressly
                  waive and exclude the application of the Uniform Computer Information Transactions
                  Act and the United Nations Convention on Contracts for the International Sale of
                  Goods.
                </li>
                <li>
                  <u>Arbitration.</u>
                  Subject to Section 9(a), any dispute arising out of or relating in any way to this
                  Agreement shall be subject exclusively to confidential binding arbitration. Such
                  arbitration shall be conducted by a single, mutually selected arbitrator, in the
                  State of New York, in accordance with the then-current rules of the American
                  Arbitration Association (&ldquote;AAA&rdquote;). No claims of any other parties
                  may be joined or
                  otherwise combined in the arbitration proceeding and no class action arbitration
                  shall be permitted. The parties shall equally share all AAA charges and fees
                  associated with the arbitration, but each party shall bear its own attorneys’ fees
                  without regard to which party is deemed the prevailing party in the arbitration
                  proceeding. This Section 9(e) shall not apply in the event of actual or threatened
                  breaches of Sections 3, 5 or 7 of this Agreement.
                </li>
                <li>
                  <u>Notices.</u>
                  All notices required under this Agreement shall be made in writing, if to
                  SelectPatient Management, to the address set forth in the preamble of this
                  Agreement, and if to Licensee, to the address submitted to SelectPatient
                  Management during the CCM System registration process, and shall be deemed
                  received (i) when delivered by courier or in person, or (ii) one day following
                  deposit with a nationally recognized overnight delivery service, postage
                  prepaid, return signature requested. The notice address for either party may be
                  changed upon written notice delivered to the other party.
                </li>
                <li>
                  <u>Severability.</u>
                  If any provision of this Agreement is determined by a court of competent
                  jurisdiction to be unenforceable, the provision shall be deemed modified to the
                  extent necessary to allow it to be enforced to the extent permitted by law, or if
                  it cannot be modified, the provision shall be severed and deleted from the
                  Agreement, and the remainder of the Agreement shall continue in effect.
                </li>
                <li>
                  <u>Amendment; Waiver.</u>
                  No changes or modifications to this Agreement or waivers of any provision hereof
                  shall be effective unless evidenced in a writing referencing this Agreement and
                  signed by a duly authorized representative on behalf of each party. Failure by any
                  party to enforce any term of this Agreement shall not be deemed a waiver of future
                  enforcement of that or any other term in this Agreement or any other agreement
                  that may be in place between the parties.
                </li>
                <li>
                  <u>Force Majeure.</u>
                  The parties hereto shall be excused from any failure or delay in performing any
                  obligation hereunder to the extent such failure results from a cause beyond the
                  reasonable control of such party, including without limitation act of God,
                  accident, earthquake, fire, flood, hurricane, tornado, storm or other weather
                  condition, war, terrorism, cyberterrorism, hacking, sabotage, riot, civil
                  disorder, act or decree of any governmental body, power or network failure,
                  lockout, strike or other labor disturbance, illness, death or any other natural or
                  artificial disaster. In the event of any such failure or delay, the parties shall
                  use commercially reasonable efforts to perform as soon as practicable.
                </li>
                <li>
                  <u>Entire Agreement.</u>
                  The terms and conditions set forth in this Agreement constitute the entire
                  agreement between the parties with respect to the CCM System which are the subject
                  matter hereof, and merges and supersedes all prior and contemporaneous agreements,
                  understandings, negotiations and discussions; provided, however, that if any term
                  of this Agreement stands in conflict with the terms of a Master Services Agreement
                  entered between SelectPatient Management and Licensee’s employer, the
                  provisions of such Master Services Agreement between SelectPatient Management
                  and Licensee’s employer shall supersede and control the subject matter in
                  question. The section headings contained in this Agreement are for convenience
                  of reference only and shall have no effect on the substantive interpretation
                  hereof.
                </li>
              </ol>
            </li>
          </ol>

        </div>
      </div>
    );
  }
}

export default LicenceAgreement;
