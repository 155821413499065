// Libraries
import React, { useState, useContext, Fragment } from 'react';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _intersectionBy from 'lodash/intersectionBy';
import moment from 'moment';
import {
  Accordion, Card, Button, Dropdown,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import ShowNotification from '../../../actions/notification';
// Services
import { deletePrompt } from '../../../services/administration';
// Hooks
import useLoadAdministrationPrompts from '../../../hooks/services/useLoadAdministrationPrompts';
// Constants
import { DATE_FORMAT, EMPTY_STRING, NOTIFICATION_TYPE } from '../../../constants/constants';
import {
  PROMPT_CONFIRMATION_TYPES, PROMPT_FILTERS, PROMPT_FILTER_NAMES,
  PROMPT_MONTHS, PROMPT_ONLY, PROMPT_TENURE_OPTS, HF_REPEATED, PROMPT_APPT_DATE,
} from '../../../constants/administration';
// Views
import PromptModal from './PromptModal';
import { getInterventionType } from '../../../services/helpers';

export const PromptsList = () => {
  const [filters, setFilters] = useState({ category: '', status: 'ENABLED' });
  const { administration: { healthFactors } } = useSelector(state => state);
  const { prompts, refetch } = useLoadAdministrationPrompts(filters);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditPrompt = (prompt) => {
    setSelectedPrompt(prompt);
    setIsModalOpen(true);
  };

  const renderEmptyRow = () => (
    <tr data-test="promptsList_emptyMsg">
      <td colSpan="7" className="p-2 border-0">
        No prompts found
      </td>
    </tr>);

  const getDateString = date => moment.utc(date).format(DATE_FORMAT.FULL);
  const getConfirmationString = (type, isRequiredTopic) => type
    && PROMPT_CONFIRMATION_TYPES(isRequiredTopic)[type].value;

  const renderDropDownItems = listName => Object.keys(PROMPT_FILTERS[listName])
    .map((key, index) => (
      <Dropdown.Item
        key={`qas_filter_${index}`}
        onClick={() => {
          setFilters({ ...filters, [listName.toLowerCase()]: key !== 'ALL' ? key : '' });
        }}
        as="button"
        className="d-flex my-1"
        data-test="promptList_promptFilterItem"
      >
        <div className="pr-3">{PROMPT_FILTERS[listName][key].name}</div>
      </Dropdown.Item>
    ));

  const removePrompt = (itemId) => {
    const deletePromptRequest = deletePrompt(itemId);
    const deletePromptPromise = deletePromptRequest.promise;

    return deletePromptPromise.then(() => {
      delete deletePromptRequest.promise;
      refetch();
    }).catch((error) => {
      delete deletePromptRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while getting the surveys',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getCriteria = (item) => {
    if (item.criteriaType === PROMPT_ONLY) {
      const result = [];
      let criteriaText;
      if (item.dateRuleEnabled) {
        criteriaText = 'Based on Date of MOC:';
        criteriaText = item.dateRuleMonths.length ? `${criteriaText} ${PROMPT_MONTHS.filter(month => item.dateRuleMonths.includes(month.value)).map(month => month.label).join(', ')}`
          : `${criteriaText} from ${moment(item.dateRuleStart).format(DATE_FORMAT.FULL)} to ${moment(item.dateRuleEnd).format(DATE_FORMAT.FULL)}`;
        result.push(criteriaText);
      }
      if (item.conditionRuleEnabled) {
        criteriaText = `Based on Chronic Condition(s): ${item.conditions.map(c => c.name).join(', ')}`;
        result.push(criteriaText);
      }
      if (item.sdohRuleEnabled) {
        const factorsUpdated = _intersectionBy(healthFactors, item.factors, 'id');
        criteriaText = `Based on Health Factor(s): ${factorsUpdated.map((c) => {
          const name = HF_REPEATED.includes(c.displayName) ? `${c.displayName} (${c.category})` : `${c.displayName}`;
          return name;
        }).join(', ')}`;
        result.push(criteriaText);
      }
      if (item.tagRuleEnabled) {
        criteriaText = `Based on Attribute: ${item.tag.displayName}`;
        result.push(criteriaText);
      }
      if (item.tenureRuleEnabled) {
        criteriaText = `Based on patient tenure: ${item.tenureRule ? PROMPT_TENURE_OPTS.find(opt => opt.value === item.tenureRule).label : ''}`;
        result.push(criteriaText);
      }
      if (item.icd10RuleEnabled) {
        criteriaText = `Based on ICD10 Code(s): [${item.icd10RuleValues.join(', ')}]`;
        result.push(criteriaText);
      }
      if (item.zipCodeRuleEnabled) {
        criteriaText = `Based on Zip Code(s): [${item.zipCodeRuleValues.join(', ')}]`;
        result.push(criteriaText);
      }
      if (item.apptRuleEnabled) {
        const type = item.apptRuleType ? `Type: ${item.apptRuleType}, ` : '';
        const purpose = item.apptRulePurpose ? `Purpose: ${item.apptRulePurpose}, ` : '';
        const date = item.apptRuleDate ? `Date: ${PROMPT_APPT_DATE.filter(datelabel => datelabel.value === item.apptRuleDate)[0].label}` : '';
        criteriaText = `Based on Appointment: ${type}${purpose}${date}`;
        result.push(criteriaText);
      }
      if (item.genderRuleEnabled) {
        criteriaText = `Based on Gender: ${item.genderRule === 'M' ? 'Male' : 'Female'}`;
        result.push(criteriaText);
      }
      if (item.ageRuleEnabled) {
        let ageText = '';
        if (item.ageRuleLessThan != null && item.ageRuleGreaterThan != null) {
          ageText = `<= ${item.ageRuleLessThan}, >= ${item.ageRuleGreaterThan}`;
        } else if (item.ageRuleLessThan != null) {
          ageText = `<= ${item.ageRuleLessThan}`;
        } else if (item.ageRuleGreaterThan != null) {
          ageText = `>= ${item.ageRuleGreaterThan}`;
        }

        criteriaText = `Based on Age: ${ageText}`;
        result.push(criteriaText);
      }
      if (item.telehealthProviderRuleEnabled) {
        criteriaText = `Based on Telehealth Group(s): ${item.telehealthProviderRule.map(t => t.code).join(', ')}`;
        result.push(criteriaText);
      }
      return result.length > 0 ? `${result.join(', ')}.` : EMPTY_STRING;
    }
    return 'Prompt all patients on every call';
  };

  const renderPromptsRows = () => {
    if (prompts && !!prompts.length) {
      const sortedListByDate = _reverse(
        _sortBy(prompts, el => moment(el.createdAt)),
      );
      return sortedListByDate.map(item => (
        <tr key={`prompt__row-${item.id}`} data-test="promptsList_entryRow">
          <td colSpan="8" className="p-0">
            <Accordion>
              <Card className="border-0 rounded-0">
                <Card.Header className="bg-light p-0">
                  <table className="table mb-0">
                    <tbody>
                      <tr data-test="promptsList_row">
                        <td className="prompt__name">
                          {item.name || EMPTY_STRING}
                        </td>
                        <td className="prompt__category text-capitalize">
                          {(item.category && item.category.toLowerCase()) || EMPTY_STRING}
                        </td>
                        <td className="prompt__prompt-type hidden">
                          {item.promptType || EMPTY_STRING}
                        </td>
                        <td className="prompt__confirmation">
                          {getConfirmationString(item.confirmationType, item.isRequiredTopic)}
                        </td>
                        <td className="prompt__date">
                          {item.updatedAt ? getDateString(item.updatedAt) : EMPTY_STRING}
                        </td>
                        <td className="prompt__user">
                          {item.lastUpdatedByUser || EMPTY_STRING}
                        </td>
                        <td className="prompt__status">
                          {item.status || EMPTY_STRING}
                        </td>
                        <td className="prompt__action px-0">
                          <CustomToggle eventKey="0" callback={() => { }} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p className="flex-grow-1 mb-2" data-test="promptsList_promptText">
                      <span className="mr-3 pr-1">Prompt Text:</span>
                      <i className="text-ccm-lipstick">{item.promptText || EMPTY_STRING}</i>
                    </p>
                    <div className="d-flex-center-between">
                      <div>
                        <div className="d-flex">
                          <p className="mb-2" data-test="promptsList_guidanceText">
                            <span className="mr-2">Guidance Text:</span>
                          </p>
                          <div className="w-75">
                            <i className="white-space-pre-line text-ccm-lipstick">
                              {item.guidanceText !== null
                                ? item.guidanceText : EMPTY_STRING}
                            </i>
                          </div>
                        </div>
                        {item && item.interventionCategory && (
                          <div>
                            <div className="d-flex mt-2">
                              <p className="flex-grow-1 mb-2 mr-5">
                                <span className="mr-3 pr-1">Intervention Category:</span>
                                {item.interventionCategory
                                  ? getInterventionType(item.interventionCategory) : EMPTY_STRING}
                              </p>
                              <p className="flex-grow-1 mb-2">
                                <span className="mr-3 pr-1">Intervention Type:</span>
                                {item.interventionType || EMPTY_STRING}
                              </p>
                            </div>
                            <p className="flex-grow-1 mb-2">
                              <span className="mr-3 pr-1">Intervention Note:</span>
                              {item.interventionNote || EMPTY_STRING}
                            </p>
                          </div>)}
                      </div>
                      {item.status !== 'DELETED' && (
                        <Button
                          variant="primary"
                          size="sm"
                          className="text-capitalize m-3"
                          onClick={() => handleEditPrompt(item)}
                          data-test="promptsList_editBtn"
                        >
                          Edit
                        </Button>)}
                    </div>
                    <div className="d-flex-center-between">
                      <div className="w-75">
                        <p className="mb-2 mt-3" data-test="promptsList_promptText">
                          <span className="mr-3 pr-1">Qualifying Criteria:</span>
                          {getCriteria(item)}
                        </p>
                      </div>
                      {item.status !== 'DELETED' && (
                        <Button
                          variant="danger"
                          size="sm"
                          className="text-capitalize m-3"
                          onClick={() => removePrompt(item.id)}
                          data-test="promptsList_deleteBtn"
                        >
                          Delete this prompt
                        </Button>)}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </td>
        </tr>
      ));
    }
    return renderEmptyRow();
  };

  return (
    <Fragment>
      <div className="prompts card border-0">
        <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
          <div className="position-absolute d-flex" style={{ left: 1 }}>
            <Dropdown
              data-test="promptsCategoryFilter"
            >
              <Dropdown.Toggle
                variant="ccm-light-gray"
                className="py-0"
              >
                {`Category: ${PROMPT_FILTERS.CATEGORY[filters.category] ? PROMPT_FILTERS.CATEGORY[filters.category].name : 'All'}`}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {renderDropDownItems(PROMPT_FILTER_NAMES.CATEGORY)}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              data-test="promptsStatusFilter"
            >
              <Dropdown.Toggle
                variant="ccm-light-gray"
                className="py-0"
              >
                {`Status: ${PROMPT_FILTERS.STATUS[filters.status].name}`}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {renderDropDownItems(PROMPT_FILTER_NAMES.STATUS)}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <span className="text-uppercase">Prompts</span>
          <Button
            size="sm"
            variant="link-dark"
            className="position-absolute"
            style={{ right: 10 }}
            onClick={() => handleEditPrompt(null)}
            data-test="promptList_addBtn"
          >
            <i className="d-flex-center bi-plus-lg" />
          </Button>
        </div>
        <div className="card-body ccm-table-container px-0">
          <table className="table w-100 text-left">
            <thead>
              <tr>
                <th className="py-2 prompt__name" data-test="promptsListColumnName">Name</th>
                <th className="py-2 prompt__category" data-test="promptsListColumnCategory">Category</th>
                <th className="py-2 prompt__prompt-type hidden" data-test="promptsListColumnType">Type</th>
                <th className="py-2 prompt__confirmation" data-test="promptsListColumnConfirmation">Confirmation</th>
                <th className="py-2 prompt__date" data-test="promptsListColumnLastUpdated">Last Updated</th>
                <th className="py-2 prompt__user" data-test="promptsListColumnLastUpdatedBy">Last Updated By</th>
                <th className="py-2 prompt__status" data-test="promptsListColumnStatus">Status</th>
                <th className="py-2 prompt__action px-0" />
              </tr>
            </thead>
            <tbody>
              {renderPromptsRows()}
            </tbody>
          </table>
        </div>
      </div>
      <PromptModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        initialPrompt={selectedPrompt}
        callbackAction={refetch}
      />
    </Fragment>
  );
};

export const CustomToggle = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey,
    () => callback && !isCurrentEventKey && callback());

  return (
    <Button variant="link-dark" className="pl-0" onClick={decoratedOnClick} data-test="promptsList_customToggleButton">
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

export default PromptsList;
