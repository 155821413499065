// Libraries
import React from 'react';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
import useConfirm from '../../hooks/useConfirm';
// Constants
import { DIALOG_STYLES } from '../../constants/constants';


const ConfirmModal = () => {
  const {
    prompt = '',
    promptTitle = '',
    promptProceed = '',
    promptCancel = '',
    isOpen = false,
    proceed,
    cancel,
  } = useConfirm();

  return (
    <Modal
      isOpen={isOpen}
      style={{ ...DIALOG_STYLES, overlay: { ...DIALOG_STYLES.overlay, zIndex: 115 } }}
      onRequestClose={cancel}
      contentLabel="Progress Modal"
      data-test="confirmModal_modal"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {promptTitle}
          <button
            type="button"
            className="close-icon i-close"
            onClick={cancel}
          />
        </div>
        <div className="dialog-content text-left" data-test="confirmModal_engoodenText">
          {`${prompt}`}
        </div>
        <div className="dialog-buttons justify-content-end px-4">
          <Button variant="light" onClick={cancel} data-test="confirmModal_cancelBtn">{promptCancel}</Button>
          <Button
            variant="primary"
            className="ml-2"
            onClick={proceed}
            data-test="confirmModal_proceedBtn"
          >
            {promptProceed}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmModal;
