// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getPatientPrompts } from '../../services/patient';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';


const useLoadPrompts = ({ patientId }) => {
  const [prompts, setPromts] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPrompts = useCallback(async () => {
    const getPatientPromptsRequest = getPatientPrompts(patientId);
    const getPatientPromptsPromise = getPatientPromptsRequest.promise;
    try {
      const data = await getPatientPromptsPromise;
      setPromts(data);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to load prompts.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [patientId]);

  useEffect(() => { fetchPrompts(); }, [fetchPrompts]);

  return { prompts, refetch: fetchPrompts };
};

export default useLoadPrompts;
