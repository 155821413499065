// Libraries
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
// Actions
import { UpdatePatient } from '../../../actions/patient';
import ShowNotification from '../../../actions/notification';
// Services
import { sendAppointmentReminders } from '../../../services/patient';
import { isLessThanXHours } from '../../../services/helpers';
// Constants
import { NOTIFICATION_TYPE, EMPTY_STRING } from '../../../constants/constants';

export function PatientAvailability(props) {
  const { id: patientId } = useParams();

  const {
    updatePatient, showNotification, patient: {
      enableSendingApptReminder = false,
      apptReminderResponseValue, otherApptResponseText, lastApptReminderDate,
    } = {}, reloadPatientInfo,
  } = props;

  const sendText = () => {
    const fetchRequest = sendAppointmentReminders(patientId);
    const fetchPromise = fetchRequest.promise;

    return fetchPromise.then(() => {
      delete fetchPromise.promise;
      updatePatient({
        apptReminderResponseValue: 'No response',
        otherApptResponseText: null,
      });

      reloadPatientInfo();
    }).catch((error) => {
      delete fetchPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to send text',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getDateString = date => (moment(date).isValid()
    ? moment(moment.utc(date)).local().calendar() : EMPTY_STRING);

  return (
    enableSendingApptReminder && (
    <div className="d-flex-center-between box-wrapper p-2" data-test="patientAvailability_mainComponent">
      <span className="patient-summary-title text-uppercase">Patient Availability Check</span>
      <div>
        <span className="text-uppercase">
          Last sent:
        </span>
        <span className="ml-1" data-test="patientAvailability_lastApptDate">
          {getDateString(lastApptReminderDate)}
        </span>
      </div>
      <div className="d-flex-center">
        <div>
          <span className="text-uppercase">
            Response:
          </span>
          <span className="ml-1" data-test="patientAvailability_apptLabel">
            {apptReminderResponseValue || EMPTY_STRING}
          </span>
        </div>
        {otherApptResponseText && (
          <OverlayTrigger
            placement="right"
            overlay={(
              <Tooltip>
                {otherApptResponseText}
              </Tooltip>)}
          >
            <div className="rounded-circle bg-ccm-light-gray py-1 px-2 ml-2" data-test="patientAvailability_responseTooltip">
              <Button variant="link-dark" className="p-0"><i className="bi-chat-left-text" /></Button>
            </div>
          </OverlayTrigger>
        )}
      </div>
      <Button
        size="sm"
        variant="primary"
        onClick={() => sendText()}
        disabled={isLessThanXHours(lastApptReminderDate, 4)}
        data-test="patientAvailability_sendTextButton"
      >
        Send Text
      </Button>
    </div>)
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAvailability);
