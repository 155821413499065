export default function SetUser(data) {
  return {
    type: 'USER_LOADED',
    ...data,
  };
}

export function UpdateUserAuth(authData) {
  return {
    type: 'USER_AUTH',
    authData,
  };
}

export function UpdateUserGoals(value) {
  return {
    type: 'UPDATE_USER_GOALS',
    progressMap: value,
  };
}

export function UpdateAnniversary(value) {
  return {
    type: 'UPDATE_ANNIVERSARY',
    isAnniversary: value,
  };
}

export function Logout() {
  return {
    type: 'LOGOUT',
  };
}

export function UpdateUserStatus(value) {
  return {
    type: 'UPDATE_USER_STATUS',
    value,
  };
}

export function UpdateUserBillingMetrics(value) {
  return {
    type: 'UPDATE_USER_BILLING_METRICS',
    billingMetrics: value,
  };
}
