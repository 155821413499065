// Libraries
import { createAction, createReducer } from '@reduxjs/toolkit';
// Constants
import { DEFAULT_BILL_METRICS } from '../constants/metrics';

const setBillMetrics = createAction('SET_BILL_METRICS');

const initialState = {
  billMetrics: DEFAULT_BILL_METRICS,
};

const BillMetrics = createReducer(initialState, (builder) => {
  builder
    .addCase(setBillMetrics, (state, action) => {
      state.billMetrics = action.billMetrics;
    })
    .addDefaultCase(state => state);
});

export default BillMetrics;
