import { makeAuthorizedRequest } from './requests';

export function getAlerts({
  pageSize, pageNumber, type = '', status = '', patientid = '', ehrid = '',
}) {
  return makeAuthorizedRequest('GET', `/user/alerts?pageSize=${pageSize}&pageNumber=${pageNumber}&sort=createdAt,desc&type=${type}&status=${status}&patientid=${patientid}&ehrid=${ehrid}`);
}

export function dismissAlert(alertId) {
  return makeAuthorizedRequest('POST', `/user/alert/${alertId}/dismiss`);
}

export function dismissAllAlerts() {
  return makeAuthorizedRequest('POST', '/user/alerts/dismiss');
}
