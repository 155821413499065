// Libraries
import { object, string, number } from 'yup';


export const resendReportSchema = () => object({
  patientEhrId: string()
    .required('The Patient EHR ID is required'),
  billingReportId: number()
    .required('The Billing Report ID is required'),
  user: string()
    .required('The Username is required'),
});

export const automatedPatientSurveysSchema = () => object({
  user: string()
    .required('The Username is required'),
});

export const generateProblemReportSchema = () => object({
  user: string()
    .required('The Username is required'),
});
