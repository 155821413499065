// Helpers
import { getTenant } from './helpers';
// Config
import { store } from '../config/store';
import { History } from '../config/history';
// Services
import { makeAuthorizedRequest, makeRequest } from './requests';
import { clearUserFromStorage, checkUserInStorage } from './userStorage';
import { getCognitoUser, signOut as cognitoLogout } from './amazon-cognito';
// Actions
import { Logout as clearUserFromRedux } from '../actions/user';

class Login {
  static signUp(params) {
    return makeRequest('POST', '/signup', params);
  }
}

export function getUser() {
  return makeAuthorizedRequest('GET', '/me/info');
}

export function getSession() {
  return makeAuthorizedRequest('GET', '/session');
}

export function updateUser(data) {
  return makeAuthorizedRequest('PUT', '/profile', data);
}

export function backendSignIn() {
  return makeAuthorizedRequest('PUT', '/me/signin');
}

export function backendLogout() {
  return makeAuthorizedRequest('PUT', '/me/logout');
}

export function logoutUser(keycloak = null, redirectToRoot = true) {
  const tenantUrl = getTenant();

  const logoutUserFromBackAndApp = () => new Promise((resolve, reject) => {
    if (checkUserInStorage(tenantUrl)) {
      const logoutRequest = backendLogout();
      const logoutPromise = logoutRequest.promise;
      return logoutPromise.then(() => {
        delete logoutRequest.promise;

        clearUserFromStorage(tenantUrl);
        store.dispatch(clearUserFromRedux());
        return resolve();
      }).catch((logoutErr) => {
        delete logoutRequest.promise;

        if (logoutErr.status === 401) {
          clearUserFromStorage(tenantUrl);
          store.dispatch(clearUserFromRedux());
          if (redirectToRoot) History.push(`/${tenantUrl}`);
        }
        return reject();
      });
    }

    return resolve();
  });

  logoutUserFromBackAndApp().then(() => {
    if (keycloak && keycloak.authenticated) {
      keycloak.logout({
        ...(redirectToRoot && { redirectUri: `${window.location.origin}/#/${tenantUrl}` }),
      });
    } else if (getCognitoUser()) {
      cognitoLogout();
      if (redirectToRoot) {
        History.push(`/${tenantUrl}`);
      }
    } else if (redirectToRoot) History.push(`/${tenantUrl}`);
  }).catch(() => {
  });
}

export function setLastPasswordUpdate(jwtToken) {
  let headers;

  if (jwtToken) {
    headers = [];

    headers.push({
      name: 'Authorization',
      value: `Bearer ${jwtToken}`,
    });
  }

  return makeAuthorizedRequest('PUT', '/me/lastpasswordupdate', null, headers);
}

export function getUserDeactivatedStatus(username, tenant) {
  return makeRequest('GET', `/tenants/${tenant}/users/${username}/state`);
}

export default Login;
