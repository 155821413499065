// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Actions
import ShowNotification, { HideNotification } from '../../../actions/notification';
import { UpdateUserGoals } from '../../../actions/user';
// Constants
import { NOTIFICATION_TYPE, USER_ROLES } from '../../../constants/constants';
// Services
import { getGoals } from '../../../services/goals';
import { isObjectsEqual } from '../../../services/helpers';
import { finalizeCarePlan, sendRealTimeSatisfactionSurvey } from '../../../services/patient';
import { updateUserInfoInStorage } from '../../../services/userStorage';
import { getActivityToCarePlanPdf } from '../../../services/administration';

export function FinalizeButton(props) {
  const {
    user: { role }, loading, hideNotification, showNotification, refetchFinalizeAlerts,
    disabled, refetchLoadPatient, sendSatisfactionSurvey, setSendSatisfactionSurvey,
  } = props;
  const { id: patientId, tenant: tenantUrl } = useParams();

  const [isActivityToPdfActive, setIsActivityToPdfActive] = useState(false);

  const isCnUser = (role && role === USER_ROLES.CN);
  const isAdminUser = (role && role === USER_ROLES.ADMIN);

  const loadActivityToCarePlan = () => {
    const getActivityToCarePlanPdfRequest = getActivityToCarePlanPdf();
    const getActivityToCarePlanPdfPromise = getActivityToCarePlanPdfRequest.promise;

    return getActivityToCarePlanPdfPromise.then((data) => {
      delete getActivityToCarePlanPdfRequest.promise;

      setIsActivityToPdfActive(data.value);
    }).catch((error) => {
      delete getActivityToCarePlanPdfRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not get setting: time tracking activity to care plan pdf.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getGoalsData = () => {
    const { user: { progressMap }, updateGoals } = props;

    const getGoalsRequest = getGoals();
    const getGoalsPromise = getGoalsRequest.promise;

    return getGoalsPromise.then((data) => {
      delete getGoalsRequest.promise;

      if (!isObjectsEqual(progressMap, data)) {
        updateGoals(data);
        updateUserInfoInStorage({ progressMap: data }, tenantUrl);
      }
    }).catch((error) => {
      delete getGoalsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load goals data',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const saveRealTimeSatisfactionSurvey = () => {
    const sendRealTimeSatisfactionSurveyRequest = sendRealTimeSatisfactionSurvey(patientId);
    const sendRealTimeSatisfactionSurveyPromise = sendRealTimeSatisfactionSurveyRequest.promise;

    return sendRealTimeSatisfactionSurveyPromise.then(() => {
      delete sendRealTimeSatisfactionSurveyRequest.promise;
      if (refetchFinalizeAlerts) refetchFinalizeAlerts();
    }).catch((error) => {
      delete sendRealTimeSatisfactionSurveyRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: `Could not send satisfaction survey due to: ${error.message}`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleFinalizeCarePlan = async () => {
    if (loading) return null;

    hideNotification();


    const finalizeCarePlanRequest = finalizeCarePlan(patientId);
    const finalizeCarePlanPromise = finalizeCarePlanRequest.promise;

    return finalizeCarePlanPromise.then(() => {
      delete finalizeCarePlanRequest.promise;
      refetchLoadPatient();
      showNotification({
        message: 'Care plan has been finalized',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      if (!isAdminUser) {
        getGoalsData();
      }
      if (sendSatisfactionSurvey) {
        saveRealTimeSatisfactionSurvey();
        setSendSatisfactionSurvey(false);
      }
    }).catch((error) => {
      delete finalizeCarePlanRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: `Could not finalize care plan due to: ${error.message}`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleFinalizeButtonClick = async () => {
    const continueFinalizeCarePlan = () => {
      handleFinalizeCarePlan();
    };

    if (isActivityToPdfActive && (isCnUser || isAdminUser)) {
      continueFinalizeCarePlan();
    } else {
      continueFinalizeCarePlan();
    }
  };

  useEffect(() => {
    loadActivityToCarePlan();
  }, []);

  return (
    <Button onClick={() => handleFinalizeButtonClick()} disabled={!!loading || disabled} data-test="finalizeButton_finalizeBtn">Finalize</Button>
  );
}

function mapStateToProps(state) {
  const { patient } = state;

  return {
    user: state.user,
    loading: state.requestsInProgress.count,
    shouldFinalizationBeConfirmed: !patient.hasInterventions && !patient.hasGoals,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updateGoals: value => dispatch(UpdateUserGoals(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinalizeButton);
