// Libraries
import React from 'react';
import { connect } from 'react-redux';
// Constants

export function Diagnoses(props) {
  const { patient: { diagnoses } = {} } = props;

  let renderDiagnosesItems = (
    <div className="col-6 p-0 text-left ccm-gray">
        No diagnoses data to display.
    </div>
  );

  renderDiagnosesItems = diagnoses && diagnoses.length ? diagnoses.map((diagnosis, index) => (
    <div className="col-6 d-flex flex-column mb-3 px-2" key={`diagnosis_${diagnosis.title}_${index}`}>
      <span>{diagnosis.title}</span>
      <small>{diagnosis.subTitle}</small>
    </div>
  )) : renderDiagnosesItems;

  return (
    <div className="row no-gutters text-left">
      {renderDiagnosesItems}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(Diagnoses);
