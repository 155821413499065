/* eslint-disable strict */
/* eslint-disable import/no-extraneous-dependencies */

'use strict';

const raf = require('raf');
const es6Extensions = require('promise/lib/es6-extensions.js');
const rejectionTracking = require('promise/lib/rejection-tracking');

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  rejectionTracking.enable();
  window.Promise = es6Extensions;
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
  raf.polyfill(global);
}
