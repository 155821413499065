// Libraries
import React, { Fragment, useState } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  Button, ButtonGroup, Dropdown, DropdownButton,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Actions
import { UpdatePatient } from '../../actions/patient';
import ShowNotification from '../../actions/notification';
// Constants
import {
  NEW_DATA_DATE_RANGE, MEDICATIONS_SOURCES,
  MEDICATIONS_STATUS, MAX_LENGTH_TRUNCATE_STRING, NOTIFICATION_TYPE,
} from '../../constants/constants';
// Services
import { deleteMedication } from '../../services/patient';
import { longStringTruncate } from '../../services/helpers';
// Views
import MedicationsModal from './medications/MedicationsModal';
import InterventionsModal from './carePlan/interventions/InterventionModal';

export const Medications = (props) => {
  const { id: patientId } = useParams();
  const { loading } = props;

  const [sourceFilter, setSourceFilter] = useState(MEDICATIONS_SOURCES.ALL);
  const [statusFilter, setStatusFilter] = useState(Object.keys(MEDICATIONS_STATUS)[1]);

  const [initialMedication, setInitialMedication] = useState(null);
  const [initialIntervention, setInitialIntervention] = useState(null);
  const [isMedicationsModalOpen, setIsMedicationsModalOpen] = useState(false);
  const [isInterventionsModalOpen, setIsInterventionsModalOpen] = useState(false);

  const handleClickStatusFilter = (status) => {
    setStatusFilter(status);
  };

  const handleClickSourceFilter = (source) => {
    setSourceFilter(source);
  };

  const handleAddEditMedication = (medication) => {
    setInitialMedication(medication);
    setIsMedicationsModalOpen(true);
  };

  const handleAddIntervention = (medication) => {
    setInitialIntervention({
      type: 'MEDICATION',
      note: `${medication.title}${medication.route ? `, ${medication.route}` : ''}${medication.dosage ? `, ${medication.dosage}` : ''}.`,
    });
    setIsInterventionsModalOpen(true);
  };

  const handleDeleteMedication = (medication) => {
    const { patient: { otcMeds }, updatePatient, showNotification } = props;


    const deleteMedicationRequest = deleteMedication(medication);
    const deleteMedicationPromise = deleteMedicationRequest.promise;

    return deleteMedicationPromise.then(() => {
      delete deleteMedicationRequest.promise;
      const updatedMedications = otcMeds.filter(el => el.id !== medication.id);
      updatePatient({ otcMeds: updatedMedications });
    }).catch((error) => {
      delete deleteMedicationRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient medication, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const filterMedications = () => {
    const { patient: { prescriptionMeds = [], otcMeds = [] } } = props;

    let filteredArray = [...prescriptionMeds, ...otcMeds];

    // Filter by Status
    if (MEDICATIONS_STATUS[statusFilter] !== MEDICATIONS_STATUS.ALL) {
      filteredArray = filteredArray.filter(
        medication => MEDICATIONS_STATUS[statusFilter].value === medication.active,
      );
    }

    // Filter by Source
    if (MEDICATIONS_SOURCES[sourceFilter] !== MEDICATIONS_SOURCES.ALL) {
      filteredArray = filteredArray.filter(medication => sourceFilter === medication.source);
    }

    return filteredArray;
  };

  const renderMedications = () => {
    const filteredMedications = filterMedications();
    if (!filteredMedications || !filteredMedications.length) {
      return (
        <span className="text-muted" data-test="medications_emptyMsg">
          <i className="bi-info-circle mr-2" />
          No medications to display
        </span>);
    }

    const checkDataRange = date => moment(date).isBetween(
      moment().subtract(NEW_DATA_DATE_RANGE, 'days'), moment(),
    );

    return filteredMedications.map(medication => (
      <div key={`patient-medication__${medication.id}`} className="col-3 p-1" data-test="medications_card">
        <div className={`patient-medication-card ${medication.active ? 'active' : 'inactive'}-medication d-flex flex-column h-100 p-2`}>
          <div className="d-flex align-items-start justify-content-between mb-1">
            <span
              className="medication-title"
              data-for={medication.title && medication.title.length > MAX_LENGTH_TRUNCATE_STRING && 'tooltip-patientMedications'}
              data-tip={medication.title}
              data-test="medications_title"
            >
              {(medication.title && longStringTruncate(medication.title)) || <small className="text-danger">Medication title missing</small>}
            </span>
            <Button variant="link-dark" className="d-none p-0" disabled>
              <i className="bi-question-circle" />
            </Button>
          </div>

          <small className="medication-route d-block mb-3" data-test="medications_route">
            {medication.route || ''}
          </small>

          <p className="medication-dosage" data-test="medications_dosage">
            {medication.dosage || ''}
          </p>

          <div className="d-flex-center-between mt-auto">
            <span className="medication-source" data-test="medications_sourceName">{MEDICATIONS_SOURCES[medication.source]}</span>

            {medication.startedAt && checkDataRange(medication.startedAt) && (
            <span className="badge badge-pill badge-success ml-1 mr-auto" data-test="medications_cardNewLabel">New</span>)}

            <div>
              {medication.source === 'COHORT' && (
                <Fragment>
                  <Button variant="link-dark" className="p-0 mr-1" onClick={() => handleAddEditMedication(medication)} data-test="medications_editBtn">
                    <i className="bi-pencil" />
                  </Button>
                  <Button variant="link-dark" className="p-0 mr-1" onClick={() => handleDeleteMedication(medication)} data-test="medications_deleteBtn">
                    <i className="bi-trash" />
                  </Button>
                </Fragment>
              )}
              <Button variant="link-dark" className="p-0" onClick={() => handleAddIntervention(medication)} data-test="medications_addInterventionBtn" data-for="tooltip-patientMedications" data-tip="Add Intervention">
                <i className="bi-plus-circle-fill" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className={`patient-medications h-100 overflow-auto pr-3 ${loading ? ' d-none' : ''}`}>
      <h4 className="text-uppercase text-left">Medications</h4>
      <div className="d-flex-center mb-3">
        <div className="d-flex-center">
          <span className="text-uppercase">Status:</span>
          <DropdownButton
            size="sm"
            as={ButtonGroup}
            variant="link"
            title={<span className="text-uppercase">{MEDICATIONS_STATUS[statusFilter].label || '--'}</span>}
            data-test="medications_statusFilter"
          >
            {Object.keys(MEDICATIONS_STATUS).map(status => (
              <Dropdown.Item
                key={`medication-statusFilter__${status}`}
                active={statusFilter === status}
                onClick={() => handleClickStatusFilter(status)}
                data-test="medications_statusFilter-item"
              >
                {MEDICATIONS_STATUS[status].label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <div className="d-flex-center ml-4">
          <span className="text-uppercase">Source:</span>
          <DropdownButton
            size="sm"
            as={ButtonGroup}
            variant="link"
            title={<span className="text-uppercase">{MEDICATIONS_SOURCES[sourceFilter] || '--'}</span>}
            data-test="medications_sourceFilter"
          >
            {Object.keys(MEDICATIONS_SOURCES).map(source => (
              <Dropdown.Item
                key={`medication-sourceFilter__${source}`}
                active={sourceFilter === source}
                onClick={() => handleClickSourceFilter(source)}
                data-test="medications_sourceFilter-item"
              >
                {MEDICATIONS_SOURCES[source]}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
        <Button
          size="sm"
          variant="primary"
          className="d-flex-center text-uppercase ml-auto"
          onClick={() => handleAddEditMedication(null)}
          data-test="medications_addBtn"
        >
          <i className="bi-plus-circle d-flex-center mr-2" />
          Add
        </Button>
      </div>

      <div className="row no-gutters">
        {renderMedications()}
      </div>

      <MedicationsModal
        patientId={patientId}
        initialMedication={initialMedication}
        isModalOpen={isMedicationsModalOpen}
        setIsModalOpen={setIsMedicationsModalOpen}
      />

      <InterventionsModal
        patientId={patientId}
        initialIntervention={initialIntervention}
        isModalOpen={isInterventionsModalOpen}
        setIsModalOpen={setIsInterventionsModalOpen}
      />

      <ReactTooltip id="tooltip-patientMedications" type="info" effect="float" place="bottom" />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    patient: state.patient,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Medications);
