// libraries
import React, { Component } from 'react';
import {
  Dropdown, DropdownButton, Button, ButtonGroup, Form,
} from 'react-bootstrap';
// services
import { formatNumbers } from '../../../services/helpers';

/**
 * PatientsSelect component, requires props:
 * selectPatientsCallback -- callback for selecting N or all patients
 * selectAllOnPageCallback -- callback for selecting all on page patients
 * unSelectCallback -- callback for unselecting patients
 * selectedPatientsCount -- count of selected patients
 */
class PatientsSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otherValue: '',
    };
  }

  handleChangeOtherValue = (event) => {
    let { value } = event.target;
    value = formatNumbers(value);

    this.setState({
      otherValue: value,
    });
  }

  render() {
    const {
      selectedPatientsCount, selectPatientsCallback, selectAllOnPageCallback, unSelectCallback,
    } = this.props;
    const { otherValue } = this.state;

    return (
      <DropdownButton
        as={ButtonGroup}
        variant="link"
        title={(
          <span>
            Select
            {' '}
            {selectedPatientsCount ? `(${selectedPatientsCount})` : ''}
          </span>)
        }
        size="sm"
      >
        <Dropdown.Item className="py-2" onClick={() => selectAllOnPageCallback()} data-test="patientSelect_selectAllPage">
          All on this page
        </Dropdown.Item>
        <Dropdown.Item className="py-2" onClick={() => selectPatientsCallback(50)} data-test="patientSelect_select50">
          50 patients
        </Dropdown.Item>
        <Dropdown.Item className="py-2" onClick={() => selectPatientsCallback(100)} data-test="patientSelect_select100">
          100 patients
        </Dropdown.Item>
        <Dropdown.Item className="py-2" onClick={() => selectPatientsCallback(0)} data-test="patientSelect_selectAll">
          All patients
        </Dropdown.Item>
        <Dropdown.ItemText className="py-2">
          Other value
          <div className="d-flex flex-nowrap pt-1">
            <Form.Control
              size="sm"
              type="number"
              className="mr-1"
              value={otherValue}
              onChange={this.handleChangeOtherValue}
              maxLength="3"
              data-test="patientsSelect_selectFewPatients"
            />
            <Button
              size="xs"
              variant="primary"
              disabled={(!otherValue || !parseInt(otherValue, 10))}
              onClick={() => selectPatientsCallback(parseInt(otherValue, 10))}
              data-test="patientsSelect_selectFewPatientsButton"
            >
              Select
            </Button>
          </div>
        </Dropdown.ItemText>
        <Dropdown.Divider />
        <Dropdown.Item className="py-2" onClick={() => unSelectCallback()} data-test="patientSelect_unselect">
          Unselect All
        </Dropdown.Item>
      </DropdownButton>
    );
  }
}

export default PatientsSelect;
