// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
// Constants
import { SUPER_ROLES_FULL_NAMES, SUPER_USER_MENU_ITEMS, SUPER_USER_ROLES } from '../../../constants/globalAdminUi';

export const GlobalUserPageMenu = () => {
  const {
    tenant: { name: tenantName },
    user: { firstName, lastName, roleName: userRole },
  } = useSelector(state => state);

  const renderMenuItems = () => SUPER_USER_MENU_ITEMS.map((item) => {
    if (item.roles && item.roles.length
        && !item.roles.some(role => role === userRole)) {
      return null;
    }

    const menuItem = [];

    menuItem.push(
      <NavLink
        key={`admin_menuItem-${item.url}`}
        to={item.url}
        className={({ isActive }) => `d-block text-left my-2${isActive ? ' active' : ''}`}
        data-test={`userPageMenu_${item.url}-link`}
      >
        {item.title}
      </NavLink>,
    );

    return menuItem;
  });

  return (
    <div className="ccm-admin-menu-wrapper d-flex flex-column h-100">
      <div className="admin-general-info box-wrapper d-flex p-3">
        <span
          title={`${firstName.charAt(0)}${lastName.charAt(0)}`}
          className="avatar-icon bg-ccm-yellow text-white rounded-circle flex-shrink-0"
        >
          {`${firstName.charAt(0)}${lastName.charAt(0)}`}
        </span>
        <div className="d-flex justify-content-center flex-column text-left ml-3">
          <div className="admin__name">{`${firstName} ${lastName}`}</div>
          <div className="admin_tenant">{tenantName}</div>
          <div className="admin__role mt-1">
            {userRole === SUPER_USER_ROLES.SUPER_ADMIN ? 'Super Administrator' : SUPER_ROLES_FULL_NAMES[userRole]}
          </div>
        </div>
      </div>
      <div className="admin-menu-items box-wrapper flex-grow-1 overflow-auto mt-2 py-2" data-test="userPageMenu_adminMenu">
        {renderMenuItems()}
      </div>
    </div>
  );
};

export default GlobalUserPageMenu;
