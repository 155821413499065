// libraries
import React from 'react';
// views
import QaRow from '../QaRow';

/**
 * TableContent component, requires props:
 * patients -- array of patients
 * params
 */
export function TableContent(props) {
  const renderPatients = () => (
    props.patients.map(patient => (
      <QaRow
        data={patient}
        key={`patient-item-${patient.id}`}
      />
    ))
  );

  return (
    <tbody>
      {renderPatients()}
    </tbody>
  );
}

export default TableContent;
