import { createAction, createReducer } from '@reduxjs/toolkit';

const setHealthFactorList = createAction('SET_HF_LIST');
const setAdminHealthFactor = createAction('SET_ADMIN_HF');
const setHFResources = createAction('SET_HF_RESOURCES');

const initialState = {};

const healthFactors = createReducer(initialState, (builder) => {
  builder
    .addCase(setHealthFactorList, (state, action) => {
      state.healthFactors = action.healthFactors;
    })
    .addCase(setAdminHealthFactor, (state, action) => {
      state.adminHealthFactors = action.adminHealthFactors;
    })
    .addCase(setHFResources, (state, action) => {
      state.resources = action.resources;
    })
    .addDefaultCase(state => state);
});

export default healthFactors;
