// Libraries
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { SetGlobalLogin, SetShowGlobalTopBar } from '../../../actions/tenants';
import { ROLES_WITH_SNOOK_ACCESS } from '../../../constants/globalAdminUi';

export const GlobalMenu = () => {
  const { user: { tenants, roleName } } = useSelector(state => state);
  const dispatch = useDispatch();

  const haveSnookAccess = () => ROLES_WITH_SNOOK_ACCESS.includes(roleName);

  const navigateToTenant = (tenantUrl) => {
    dispatch(SetGlobalLogin(true));
    window.open(`${window.location.origin}/#/${tenantUrl}`, '_blank');
  };

  const renderUserTenants = () => tenants.map(item => (
    <div className="col-4 px-2 py-3" key={`tenant_container_${item.tenantId}`}>
      <Button
        key={`tenant_${item.tenantId}`}
        variant="ccm-lipstick"
        className="px-5 rounded w-100 p-3"
        onClick={() => navigateToTenant(item.tenantSystemName)}
        data-test={`globalMenu_tenantButton${item.tenantSystemName}`}
      >
        {item.tenantDisplayName}
      </Button>
    </div>
  ));

  return (
    <div className="ccm-snook-list d-flex-center h-100">
      <div className="col-7 d-flex-center">
        <div className="wrapper-list flex-grow-1 p-4">
          {tenants && tenants.length > 0
            ? <div className="row no-gutters col-12">{renderUserTenants()}</div>
            : (
              <div className="py-5">
                <strong>This user has not been granted access to any customer in the system</strong>
              </div>)
          }
          <div className="row no-gutters col-12">
            <div className="col-4 offset-4 p-2">
              {haveSnookAccess() && <Button className="w-100 rounded p-3 text-uppercase" onClick={() => dispatch(SetShowGlobalTopBar(true))} data-test="globalMenu_snookButton">Snook</Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalMenu;
