// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
import _isNaN from 'lodash/isNaN';
// actions
import ShowNotification from '../../../actions/notification';
// constants
import { NOTIFICATION_TYPE } from '../../../constants/constants';
// Components
import { withRouter } from '../../shared/WithRouter';
// services
import {
  getWorkloadData, getDailyEnrollmentGoal, getMonthlyEnrollmentGoal,
  updateDailyEnrollmentGoal, updateMonthlyEnrollmentGoal,
} from '../../../services/administration';
// views
import LoadingBlock from '../../menu/LoadingBlock';

export class Workload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      workloadData: {},
      dailyEnrollment: 0,
      monthlyEnrollment: 0,
    };

    this.promises = {};

    this.assign = this.assign.bind(this);
    this.getGoals = this.getGoals.bind(this);
    this.setGoal = this.setGoal.bind(this);
    this.getWorkloadData = this.getWorkloadData.bind(this);
    this.handleDebounceGoal = _debounce(this.updateGoal, 500);
  }

  componentDidMount() {
    this.getWorkloadData();
    this.getGoals();
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
    this.handleDebounceGoal.cancel();
  }

  getWorkloadData() {
    const { showNotification } = this.props;
    const promiseName = 'getWorkloadData';
    const getWorkloadDataRequest = getWorkloadData();

    const getWorkloadDataPromise = getWorkloadDataRequest.promise;
    this.promises[promiseName] = getWorkloadDataRequest;

    getWorkloadDataPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        workloadData: data,
        loading: false,
      });
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load workload data.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getGoals() {
    const { showNotification } = this.props;
    const goalServices = [
      { name: 'getDailyEnrollmentGoal', service: getDailyEnrollmentGoal() },
      { name: 'getMonthlyEnrollmentGoal', service: getMonthlyEnrollmentGoal() },
    ];
    const goalPromises = goalServices.map(goal => goal.service.promise);
    goalServices.forEach((goal) => { this.promises[goal.name] = goal.service; });

    const mapGoal = (goal, prevGoal) => (goal.status !== 'rejected' ? goal.value.value : prevGoal);
    const throwError = (error) => {
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'An error occurred while trying to get a goal.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    };

    Promise.allSettled(goalPromises).then((goals) => {
      this.setState(prevState => ({
        ...prevState,
        dailyEnrollment: mapGoal(goals[0], prevState.dailyEnrollment),
        monthlyEnrollment: mapGoal(goals[1], prevState.monthlyEnrollment),
      }));

      goals.forEach((goal) => {
        if (goal.status === 'rejected') throwError(goal.reason);
      });

      goalServices.forEach(goal => (delete this.promises[goal.name]));
    });
  }

  setGoal(number, property) {
    const validValue = !_isNaN(number) ? number : 0;
    const value = Math.max(0, Math.min(100000, Number(validValue)));

    this.setState(prevState => ({
      ...prevState,
      [property]: value,
    }), () => { this.handleDebounceGoal(property, value); });
  }

  updateGoal(property, value) {
    const { showNotification } = this.props;

    const update = (promiseName, service, newValue) => {
      const serviceRequest = service({ value: newValue });
      const servicePromise = serviceRequest.promise;
      this.promises[promiseName] = serviceRequest;

      servicePromise.then(() => {
        delete this.promises[promiseName];
        this.setState({ loading: false });
        showNotification({
          message: 'Successful goal update',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.SUCCESS,
        });
      }).catch((error) => {
        delete this.promises[promiseName];

        if (error.status === 401 || error.status === 403 || error.isCanceled) {
          return;
        }

        this.setState({ loading: false });

        const msgError = error.data && error.data.errors && error.data.errors.value;

        showNotification({
          message: msgError || 'An error occurred while attempting to update the goal.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
    };

    switch (property) {
      case 'dailyEnrollment':
        update('updateDailyEnrollmentGoal', updateDailyEnrollmentGoal, value);
        break;
      case 'monthlyEnrollment':
        update('updateMonthlyEnrollmentGoal', updateMonthlyEnrollmentGoal, value);
        break;
      default:
        showNotification({
          message: 'An error occurred while attempting to update the goal.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
        break;
    }
  }

  assign() {
    const { navigate, params: { tenant: tenantUrl } } = this.props;
    navigate(`/${tenantUrl}/cn/list`);
  }

  render() {
    let content;
    const {
      loading, workloadData, dailyEnrollment, monthlyEnrollment,
    } = this.state;

    if (loading) {
      content = <LoadingBlock />;
    } else {
      content = (
        <div className="flex-container workload">
          <div className="box workload-box">
            <div className="flex-container workload-row">
              <div>Patients - Total:</div>
              <div>{workloadData.totalOfConnectedToTheSystem}</div>
            </div>
            <div className="flex-container workload-row">
              <div>Patients - Assigned:</div>
              <div>{workloadData.totalOfAssignedToCn}</div>
            </div>
            <div className="flex-container workload-row">
              <div>Patients - Not Assigned:</div>
              <div>{workloadData.totalOfNotAssignedToCn}</div>
            </div>
            <div className="flex-container workload-row mt-5">
              <div>Daily Enrollment Goal:</div>
              <Form.Control
                type="number"
                value={Number(dailyEnrollment).toString()}
                className="w-25 float-right"
                data-test="workload_inputDailyEnrollment"
                onChange={e => this.setGoal(e.target.value, 'dailyEnrollment')}
              />
            </div>
            <div className="flex-container workload-row">
              <div>Monthly Enrollment Goal:</div>
              <Form.Control
                type="number"
                value={Number(monthlyEnrollment).toString()}
                className="w-25 float-right"
                data-test="workload_inputMonthlyEnrollment"
                onChange={e => this.setGoal(e.target.value, 'monthlyEnrollment')}
              />
            </div>
          </div>
          <div className="box workload-box flex-container">
            <button
              type="button"
              className="button action-button h-50"
              onClick={this.assign}
            >
              Assign
            </button>
          </div>
        </div>
      );
    }

    return (
      <div
        className="admin-section my-4"
        id="workload"
      >
        <h5 className="text-uppercase my-3">Workload</h5>
        <div className="content workload-content">
          {content}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(null, mapDispatchToProps, null, { forwardRef: true })(Workload));
