// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// Services
import { getLetterBatchFile } from '../../services/patient';
import { getPatientsProvider } from '../../services/reports';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../constants/constants';
import { downloadFile } from '../../services/helpers';

export const EligiblePatientsProvider = () => {
  const dispatch = useDispatch();
  const [providers, setProviders] = useState([]);

  const getPatients = () => {
    const getPatientsProviderRequest = getPatientsProvider();
    const getPatientsProviderPromise = getPatientsProviderRequest.promise;

    return getPatientsProviderPromise.then((response) => {
      delete getPatientsProviderRequest.promise;

      setProviders(response);
    }).catch((error) => {
      delete getPatientsProviderRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      dispatch(ShowNotification({
        message: 'Could not get Eligible Patients by Provider, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const downloadReport = async (batchId) => {
    const getBillingExcelRequest = getLetterBatchFile(batchId);
    const getBillingExcelPromise = getBillingExcelRequest.promise;

    try {
      delete getBillingExcelPromise.promise;
      const resp = await getBillingExcelPromise;
      downloadFile(resp.data, resp.fileName);
    } catch (error) {
      delete getBillingExcelPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      dispatch(ShowNotification({
        message: 'Can\'t download report.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    }
  };

  useEffect(() => {
    getPatients();
  }, []);

  const renderPatientsRows = () => {
    if (providers.length > 0) {
      return providers.map(provider => (
        <tr
          key={`provider_${provider.batchId}`}
          onClick={() => downloadReport(provider.batchId)}
          data-test="eligiblePatientsProvider_reportRow"
        >
          <td data-test="eligiblePatientsProvider_providers">
            {provider.physicians.length > 0 ? provider.physicians.join(', ') : 'Not Specified'}
          </td>
          <td data-test="eligiblePatientsProvider_dateCreated">
            {moment(provider.createdDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL)}
          </td>
          <td><i className="bi-download mr-2" data-test="eligiblePatientsProvider_downloadBtn" /></td>
        </tr>
      ));
    }

    return (
      <tr data-test="eligiblePatientsProvider_emptyMsg">
        <td colSpan="3" className="p-2 border-0">No eligible patients</td>
      </tr>
    );
  };

  return (
    <div className="reports-enrollment h-100 overflow-auto pr-3">
      <div className="reports-header d-flex align-items-center">
        <h4
          className="text-left my-3"
          data-test="eligiblePatientsProvider_headingText"
        >
          Eligible Patients by Provider
        </h4>
      </div>
      <div className="reports-content ccm-table-container px-0">
        <table className="table table-hover text-left">
          <thead>
            <tr>
              <th className="py-2" data-test="eligiblePatientsProvider_providerHeader">Provider(s)</th>
              <th className="py-2" data-test="eligiblePatientsProvider_dateHeader">Date Created</th>
              <th className="py-2" data-test="eligiblePatientsProvider_actionHeader">Action</th>
            </tr>
          </thead>
          <tbody>
            {renderPatientsRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EligiblePatientsProvider;
