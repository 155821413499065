// Libraries
import { useState, useEffect } from 'react';
import Keycloak from 'keycloak-js';
// Config
import env from '../env';
import { store } from '../config/store';
// Services
import { getCurrentTenant } from '../services/tenants';
// Actions
import SetEnv from '../actions/env';
import AddTenants from '../actions/tenants';
// Helpers
import { getTenant } from '../services/helpers';
// Constants
import { CONFIG_NAME } from '../constants/globalAdminUi';

const useTenant = () => {
  const [keycloak, setKeycloak] = useState(null);
  const [tenantError, setTenantError] = useState(null);

  useEffect(() => {
    const tenantUrl = getTenant();

    store.dispatch(SetEnv(env));

    const getCurrentTenantRequest = getCurrentTenant(tenantUrl);
    const getCurrentTenantPromise = getCurrentTenantRequest.promise;

    getCurrentTenantPromise.then((tenantInfo) => {
      if (tenantInfo) {
        store.dispatch(AddTenants({
          ...tenantInfo,
          ...(tenantUrl === CONFIG_NAME && { name: CONFIG_NAME }),
          globalTenant: tenantUrl === CONFIG_NAME,
        }));

        if (tenantInfo.ssoEnabled) {
          const initKeycloak = new Keycloak({
            url: tenantInfo.ssoUrl,
            realm: tenantInfo.ssoRealm,
            clientId: tenantInfo.ssoClientId,
          });
          setKeycloak(initKeycloak);
        } else {
          const initKeycloak = new Keycloak();
          setKeycloak(initKeycloak);
        }
      } else {
        setTenantError({
          notificationType: 'ERROR',
          message: 'Please be sure the Tenant address is correct.',
        });
      }
    }).catch((error) => {
      if (error.isCanceled || error.status === 401 || error.status === 403) return;

      setTenantError({
        notificationType: 'ERROR',
        message: 'The page you are trying to open does not exist. Please be sure the page address is correct.',
      });
    });
  }, []);

  return {
    keycloak,
    tenantError,
  };
};

export default useTenant;
