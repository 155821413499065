// Libraries
import React from 'react';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
// Constants
import { DIALOG_DEFAULTS } from '../../../constants/constants';

function EmrNoticeModal(props) {
  const { isModalOpen, setIsModalOpen } = props;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      data-test="noticePreferences"
      isOpen={isModalOpen}
      style={DIALOG_DEFAULTS}
      contentLabel="Notice"
    >
      <div className="simple-dialog">
        <div className="dialog-title">
          Notice
          <button
            data-test="emrNoticeModal_closeIconButton"
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
          />
        </div>
        <div className="w-100 px-3">
          <div className="d-flex flex-column align-items-center py-3 ">
            <span data-test="emrNoticeModal_text">
              Next outreach contact method is set to text message, but this patient
              does not consent to text messaging. Please update next outreach details.
            </span>
            <Button
              variant="primary"
              className="w-25 mt-3"
              onClick={() => handleCloseModal()}
              data-test="emrNoticeModal_okIconButton"
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default EmrNoticeModal;
