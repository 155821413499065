// Libraries
import React, { useEffect } from 'react';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Services
import { getPatientOutreachInfo } from '../../../services/patient';
// Constants
import {
  NOTIFICATION_TYPE, DATE_FORMAT, EMPTY_STRING, BATCH_STATUS_OPTIONS, USER_ROLES,
} from '../../../constants/constants';

export const Outreach = (props) => {
  const { patientId } = props;

  const fetchPatientOutreach = () => {
    const { showNotification, updatePatient } = props;

    const fetchRequest = getPatientOutreachInfo(patientId);
    const fetchPromise = fetchRequest.promise;

    return fetchPromise.then((data) => {
      delete fetchPromise.promise;

      updatePatient({ outreach: data });
    }).catch((error) => {
      delete fetchPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error has occurred while attempting to load the patient outreach.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderEmptyRow = () => (
    <tr data-test="patientOutreach_emptyMsg">
      <td colSpan="5" className="p-2 border-0">
        No additional outreach has been documented for this patient
      </td>
    </tr>);

  const findStatus = (value) => {
    const enabledStatuses = BATCH_STATUS_OPTIONS
      .filter(el => el.enabledToCnOrPes)
      .map(el => el.value);
    return enabledStatuses.includes(value);
  };

  const renderOutreachRows = () => {
    const { patient: { outreach = [] } = {}, userRole } = props;
    const isCnOrPes = (userRole && (userRole === USER_ROLES.CN || userRole === USER_ROLES.PES));
    const outreachList = isCnOrPes
      ? outreach.filter(el => findStatus(el.currentStatus))
      : outreach;

    if (outreachList && !!outreachList.length) {
      const sortedData = _reverse(_sortBy(outreachList, el => moment(el.mostRecentUpdateDate)));

      return sortedData.map((batchLetter, index) => {
        const getDateString = date => moment(date).format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS);
        const getBatchStatus = (status) => {
          const el = BATCH_STATUS_OPTIONS.find(({ value }) => value === status);
          if (el) return el.label;
          return status;
        };
        return (
          <tr key={`outreach_row_${index}_${batchLetter.currentStatus}`} data-test="patientOutreach_rows">
            <td className="batch__date">
              {batchLetter.mostRecentUpdateDate && getDateString(batchLetter.mostRecentUpdateDate)}
            </td>
            <td className="batch__type">
              {batchLetter.outreachType || EMPTY_STRING}
            </td>
            <td className="batch__status">
              {getBatchStatus(batchLetter.currentStatus)}
            </td>
            <td className="batch__id">
              {batchLetter.batchId || EMPTY_STRING}
            </td>
            <td className="batch__comment">
              <span>{batchLetter.description || EMPTY_STRING}</span>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  useEffect(() => {
    fetchPatientOutreach();
  }, []);

  return (
    <div className="row text-left border-bottom no-gutters pb-3 mb-3">
      <div className="col patient-outreach ccm-table-container">
        <h5>Outreach</h5>
        <table className="table w-100 text-left" data-test="outreach_section">
          <thead>
            <tr data-test="outreach_dataSection">
              <th className="py-2 batch__date">Date</th>
              <th className="py-2 batch__type">Type</th>
              <th className="py-2 batch__status">Status</th>
              <th className="py-2 batch__id">Batch #</th>
              <th className="py-2 batch__comment">Comment</th>
            </tr>
          </thead>
          <tbody>
            {renderOutreachRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    patient: state.patient,
    userRole: state.user && (state.user.role || ''),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Outreach);
