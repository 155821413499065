// Libraries
import React, {
  useEffect, Fragment, useContext,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// Views
import { Button, Accordion } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import ReactTooltip from 'react-tooltip';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE, DATE_FORMAT, MAX_DIAGNOSIS, USER_ROLES,
  USER_BILLING_STATUSES,
} from '../../../constants/constants';
import { NON_PRIMARY_DX_CODES } from '../../../constants/codes/nonPrimaryDxCodes';
// Services
import { getDiagnosesList } from '../../../services/patient';

const REGEXP_CODE_STRING = new RegExp('^[aeiou].*', 'i');
const VALID_PROBLEM_CODE = 'ICD10';


export function MedicalDiagnosisTable(props) {
  const {
    patientId, patient, patient: { ehrProblems, billing } = {},
    updatePatient, user: { role: userRole } = {}, showNotification,
  } = props;
  const isAdminOrCNUser = (userRole && (
    userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.CN
  ));
  const totalEhrProblems = ehrProblems ? ehrProblems.length : 0;
  const tooltipId = 'tooltip-billingMedicalDiagnosis';
  const billingInfo = billing && billing.billingInfo;
  const isUserPES = userRole === USER_ROLES.PES;
  const canAddDiagnosis = billingInfo && billingInfo.problems
    && billingInfo.problems.length < MAX_DIAGNOSIS
    && !isUserPES && USER_BILLING_STATUSES.includes(patient.status);
  const problemsSize = billingInfo && billingInfo.problems && billingInfo.problems
    ? billingInfo.problems.length
    : totalEhrProblems;

  function renderEmptyRow() {
    return (
      <tr>
        <td colSpan="4" className="p-2 border-0">
          This patient has no medical diagnoses
        </td>
      </tr>
    );
  }

  const isPrimaryDxInvalid = diagnosis => NON_PRIMARY_DX_CODES.includes(diagnosis.code);

  const validateUniqueCode = (code) => {
    const repeatedCode = billing && billing.billingInfo.problems.filter(p => p.code === code);
    return !!repeatedCode.length;
  };

  const addTempDiagnosis = (diagnosis) => {
    if (isPrimaryDxInvalid(diagnosis) && diagnosis.order === 0) {
      showNotification({
        message: `Unacceptable principal diagnosis: ${diagnosis.code}. Please check before adding this diagnosis.`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return;
    }

    if (validateUniqueCode(diagnosis.code)) {
      showNotification({
        message: `diagnosis code: ${diagnosis.code} must be unique. Please check before adding this diagnosis.`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return;
    }

    const billingFormProblems = billingInfo.problems.map((problem, index) => ({
      ...problem,
      order: index + 1,
    }));

    const newProblem = {
      ...diagnosis,
      order: billingFormProblems.length + 1,
    };
    billingFormProblems.push(newProblem);

    updatePatient(
      {
        billing: {
          ...billing, billingInfo: { ...billingInfo, problems: [...billingFormProblems] },
        },
      },
    );
  };

  const handleAddEditDiagnosis = (ehrDiagnosis) => {
    const isValidProblem = ehrDiagnosis.primaryCodeSystem === VALID_PROBLEM_CODE;
    const diagnoseInfo = (ehrDiagnosis.id && isValidProblem) ? { ...ehrDiagnosis } : null;
    const newProblemsCounter = billingInfo.problems.length || 0;
    const newDiagnosis = {
      id: '',
      name: ehrDiagnosis.title || '',
      newIndex: `np${newProblemsCounter}`,
      code: ehrDiagnosis.icd10Code || '',
      isNew: true,
      startedAt: ehrDiagnosis.onsetDate || '',
      order: newProblemsCounter,
      diagnoseInfo,
    };
    addTempDiagnosis(newDiagnosis);
  };

  const renderMedicalDiagnosesRows = (problems) => {
    if (problems && problems.length) {
      const sortedData = problems;
      return sortedData.map((ehrProblem) => {
        const isInvalidProblem = ehrProblem.primaryCodeSystem !== VALID_PROBLEM_CODE;
        const onsetDate = ehrProblem.onsetDate || ehrProblem.newProblemAt;
        const onsetDateFormatted = onsetDate
          ? moment(onsetDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.SHORT)
          : 'N/A';
        const primaryCodeString = REGEXP_CODE_STRING.test(ehrProblem.primaryCodeSystem)
          ? `an ${ehrProblem.primaryCodeSystem}`
          : `a ${ehrProblem.primaryCodeSystem}`;

        return (
          <tr key={`diagnosis_row_${ehrProblem.title}-${ehrProblem.id}`}>
            <td className="billing__ehr-problem" data-test="medicalDiagnosisTable_problemName">
              {ehrProblem.title || ehrProblem.description}
            </td>
            <td className="billing__ehr-code">
              {ehrProblem.icd10Code}
              {isInvalidProblem ? (
                <Fragment>
                  <i
                    className="text-danger bi bi-exclamation-circle ml-1"
                    data-tip={`The problem has ${primaryCodeString} code that was identified as eligible for CCM, however the problem's ICD10 is not considered eligible. As a result, this item will appear as a manual using the ICD10 name and code when added to the billing list. If you think this is an error, please contact support.`}
                    data-for={tooltipId}
                    data-tip-disable={!isInvalidProblem}
                  />
                </Fragment>
              ) : ''}
            </td>
            <td className="billing__ehr-associated-condition">
              {ehrProblem.chronicConditions}
            </td>
            <td className="billing__ehr-ccm">
              {ehrProblem.isCcm ? <span className="badge badge-ccm-orange py-2 px-4" data-test="medicalDiagnosisTable_eligibleTag">Eligible</span> : ''}
            </td>
            <td>
              {onsetDateFormatted}
            </td>
            <td className="billing__ehr-actions">
              { isAdminOrCNUser && canAddDiagnosis && (
                <Button
                  data-test={`medicalDiagnosisTable_addButton_${ehrProblem.id}`}
                  variant="link-dark"
                  className="px-1"
                  onClick={() => handleAddEditDiagnosis(ehrProblem)}
                  data-tip="Add diagnosis to billing diagnoses"
                  data-for={tooltipId}
                >
                  <i className="d-flex-center bi-plus-lg" data-test="medicalDiagnosisTable_addButton" />
                </Button>)
              }
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  useEffect(() => {
    const getDiagnosesListRequest = getDiagnosesList(patientId);
    const getDiagnosesListPromise = getDiagnosesListRequest.promise;

    getDiagnosesListPromise.then((data) => {
      delete getDiagnosesListRequest.promise;
      updatePatient({
        ehrProblems: data,
      });
    }).catch((error) => {
      delete getDiagnosesListRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load patient diagnoses data, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }, [problemsSize]);

  const isAccordionExpanded = !(billing && billing.issues
    && USER_BILLING_STATUSES.includes(patient.status));

  return (
    <div className="card border-0">
      <Accordion defaultActiveKey={isAccordionExpanded ? '0' : ''}>
        <div className="card-header p-1 rounded-0 bg-ccm-light-gray border text-ccm-bismark" data-test="medicalDiagnosisTable_medicalDiagnosis">
          <span className="text-uppercase" data-test="medicalDiagnosisTable_medicalDiagnosisHeader">Medical Diagnoses</span>
          <CustomToggle
            data-test="medicalDiagnosisTable_customToggle"
            eventKey="0"
            count={totalEhrProblems}
          />
        </div>
        <Accordion.Collapse eventKey="0">
          <div className="card-body ccm-table-container py-2 px-0">
            <table className="table w-100 text-left">
              <thead>
                <tr>
                  <th className="py-2">Problem</th>
                  <th className="py-2">Code</th>
                  <th className="py-2">Associated Condition</th>
                  <th className="py-2">CCM</th>
                  <th className="py-2">ONSET</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {renderMedicalDiagnosesRows(ehrProblems)}
              </tbody>
            </table>
            <ReactTooltip id={tooltipId} type="info" effect="float" place="bottom" />
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
}

export const CustomToggle = ({ count, eventKey, callback }) => {
  const activeEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <div className="position-absolute" style={{ right: 0, top: 0 }}>
      <small data-test="medicalDiagnosisTable_collapsed">{!isCurrentEventKey ? `(${count} collapsed)` : ''}</small>
      <Button variant="link-dark" onClick={decoratedOnClick} data-test="medicalDiagnosisTable_customToggleButton">
        <i className={`d-flex-center ${!isCurrentEventKey ? 'bi-caret-down-fill' : 'bi-caret-up-fill'}`} />
      </Button>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    patient: state.patient,
    timezone: state.tenant && state.tenant.timezone,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalDiagnosisTable);
