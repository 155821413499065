// Libraries
import React from 'react';
import { Button } from 'react-bootstrap';

export const SuspendBillingContent = (props) => {
  const { openModal, reason, onHold } = props;

  const finalReason = () => (reason && onHold ? reason : 'No billing notes have been entered.');

  return (
    <div className="d-flex border justify-content-between p-2">
      <p data-test="suspendBillingContent_billingNotes" className="text-ccm-black note-body mb-0">{finalReason()}</p>
      <Button
        size="lg"
        variant="link"
        className="p-0"
        disabled={!onHold}
        onClick={() => openModal()}
        data-test="suspendedBilling_editBtn"
      >
        <span className="d-flex-center bi-pencil-square" />
      </Button>
    </div>
  );
};

export default SuspendBillingContent;
