// Libraries
import React, {
  useEffect, useState, useRef, Fragment,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';
// Constants
import { toolbarConfig } from '../../constants/constants';
import { parseStringToDraft } from '../../services/helpers';


export const Note = (props) => {
  const {
    readOnly, note, placeholder, handleChangeQaNote, preventToClearNote = false,
  } = props;

  let tooltipRef = useRef();

  const prepareNote = (newNote) => {
    let tempEditorState;
    try {
      tempEditorState = readOnly
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(newNote.text || newNote.note)))
        : EditorState.moveFocusToEnd(
          EditorState.createWithContent(convertFromRaw(JSON.parse(newNote.text || newNote.note))),
        );
    } catch (err) {
      tempEditorState = EditorState.createEmpty();
    }
    return tempEditorState;
  };

  const [editorState, setEditorState] = useState(
    note ? prepareNote(note) : EditorState.createEmpty(),
  );

  const onEditorStateChange = (newEditorState) => {
    const noteText = JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));

    if (preventToClearNote) {
      if (JSON.parse(parseStringToDraft(noteText)).blocks[0].text !== '') {
        setEditorState(newEditorState);
        handleChangeQaNote(noteText);
        ReactTooltip.hide(tooltipRef);
      } else {
        ReactTooltip.show(tooltipRef);
        setTimeout(() => {
          ReactTooltip.hide(tooltipRef);
        }, 2500);
      }
    } else {
      setEditorState(newEditorState);
      handleChangeQaNote(noteText);
    }
  };

  const prevNoteRef = useRef();
  useEffect(() => {
    prevNoteRef.current = note;
  });
  const prevNote = prevNoteRef.current;

  useEffect(() => {
    if (note && prevNote && note.id !== prevNote.id) {
      setEditorState(prepareNote(note));
    }
  }, [note]);

  return (
    <Fragment>
      <span
        data-for="tooltip-noteEditor"
        ref={(ref) => { tooltipRef = ref; }}
        data-tip="If you wish to clear the note, select all the text and then type a new note"
      />
      <ReactTooltip id="tooltip-noteEditor" type="info" effect="solid" place="top" />
      <Editor
        key="editor"
        spellCheck
        readOnly={readOnly}
        placeholder={placeholder}
        editorState={editorState}
        wrapperClassName="wysiwyg-wrapper"
        toolbarClassName="wysiwyg-toolbar"
        editorClassName="wysiwyg-editor"
        toolbar={toolbarConfig}
        toolbarHidden={readOnly}
        onEditorStateChange={onEditorStateChange}
        data-test="noteEditor_mainComponent"
      />
    </Fragment>
  );
};

export default Note;
