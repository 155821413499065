// Libraries
import _debounce from 'lodash/debounce';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select, { Async } from 'react-select';
// Services
import { fetchData } from '../../../services/helpers';
import { getPhysicianTypeahead, getLocations, getPhysician } from '../../../services/providers';
// Actions
import { CloseQaSearch, QaSearch, QaSetFilterNames } from '../../../actions/qaSearch';

export const QaPcpFilter = () => {
  const {
    qaSearch: { searchParams: stateSearchParams },
    requestsInProgress: { count: loading },
  } = useSelector(state => state);
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});
  const [noResultsText, setNoResultsText] = useState('No results found...');
  const [physicianValue, setPhysicianValue] = useState(null);
  const [pcpLocationsList, setPcpLocationsList] = useState([]);
  const dispatch = useDispatch();

  const handleSearch = (params) => {
    const newParams = _cloneDeep(params);

    Object.keys(newParams).forEach((key) => {
      if (!newParams[key]) {
        delete newParams[key];
      }
    });
    dispatch(QaSearch({ ...newParams }, true));
    dispatch(CloseQaSearch());
  };

  const debouncedHandleSearch = useMemo(() => _debounce(handleSearch, 1000), []);

  const handleSearchInputChange = (event) => {
    if (loading) {
      return;
    }
    const {
      target: {
        name, value = '', type = '', checked = null,
      } = {},
    } = event || {};

    const updatedSearchParams = {
      ...searchParams,
      [name]: type === 'checkbox' ? checked : value,
    };

    Object.keys(updatedSearchParams).forEach((key) => {
      if (!updatedSearchParams[key]) {
        delete updatedSearchParams[key];
      }
    });

    setSearchParams(updatedSearchParams);
    debouncedHandleSearch(updatedSearchParams);
  };

  const preparePhysiciansList = (physiciansData = []) => {
    const physiciansList = [];

    physiciansData.forEach((el) => {
      physiciansList.push({
        ...el,
        value: el.id,
        label: `${el.lastName ? `${el.lastName}, ` : ''}${el.firstName ? el.firstName : ''}`,
      });
    });

    physiciansList.sort((a, b) => a.label.localeCompare(b.label));

    return physiciansList;
  };

  const handlePhysiciansAsyncList = async (input) => {
    if (input && input.length > 1) {
      const physiciansData = await fetchData(getPhysicianTypeahead(
        { filter: input },
      ));
      return { options: preparePhysiciansList(physiciansData) };
    }
    return { options: [] };
  };

  const handleLocationsAsyncList = async () => {
    if (pcpLocationsList.length === 0) {
      const locationsData = await fetchData(getLocations());
      setPcpLocationsList(locationsData);
      return locationsData;
    }
    return pcpLocationsList;
  };

  const loadPhysicianData = async (physicianId) => {
    const physicianData = await fetchData(getPhysician(physicianId));
    setPhysicianValue({
      value: physicianData.id,
      label: `${physicianData.lastName ? `${physicianData.lastName}, ` : ''}${physicianData.firstName ? physicianData.firstName : ''}`,
    });
  };

  useEffect(() => {
    if (stateSearchParams.physicianId) {
      loadPhysicianData(stateSearchParams.physicianId);
    }
    handleLocationsAsyncList();
  }, []);

  useEffect(() => {
    setSearchParams(stateSearchParams);
    if (!stateSearchParams.physicianId) setPhysicianValue(null);
  }, [stateSearchParams]);

  useEffect(() => () => {
    debouncedHandleSearch.cancel();
  }, []);

  return (
    <Card.Body className="pr-4">
      <Form className="text-left">
        <Form.Group controlId="formPCPhysicianName" className="mb-2" data-test="qaPcpFilter_pcpNameFilter">
          <Form.Label className="mb-0">PCP name</Form.Label>
          <Async
            name="physicianId"
            className={`patientSearch_pcpName w-100 ${stateSearchParams && stateSearchParams.physicianId ? 'is-active' : ''}`}
            placeholder=""
            noResultsText={noResultsText}
            onInputChange={input => (input.length < 2 ? setNoResultsText('Type 2 characters min') : setNoResultsText('No results found...'))}
            loadOptions={handlePhysiciansAsyncList}
            value={physicianValue}
            onChange={(ev) => {
              setPhysicianValue(ev);
              dispatch(QaSetFilterNames({ pcpPhysicianName: `${ev && ev.lastName ? `${ev.lastName}, ` : ''}${ev && ev.firstName ? ev.firstName : ''}` }));
              handleSearchInputChange({ target: { value: ev && ev.value, name: 'physicianId' } });
            }}
            data-test="qaPcpFilter_physicianId"
          />
        </Form.Group>
        <Form.Group controlId="formPCPLocation" className="mb-2" data-test="qaPcpFilter_pcpLocationFilter">
          <Form.Label className="mb-0">PCP location</Form.Label>
          <Select
            name="primaryPhysicianLocationId"
            className={`patientSearch_pcpLocation w-100 ${stateSearchParams && stateSearchParams.primaryPhysicianLocationId ? 'is-active' : ''}`}
            placeholder=""
            valueKey="id"
            labelKey="name"
            noResultsText="Location not found..."
            options={pcpLocationsList}
            value={searchParams && searchParams.primaryPhysicianLocationId}
            onChange={(ev) => {
              dispatch(QaSetFilterNames({ pcpLocationName: ev && ev.name ? ev.name : '' }));
              handleSearchInputChange({ target: { value: ev && ev.id, name: 'primaryPhysicianLocationId' } });
            }}
            isLoading={!!loading}
            data-test="qaPcpFilter_primaryPhysicianLocationId"
          />
        </Form.Group>
      </Form>
    </Card.Body>
  );
};

export default QaPcpFilter;
