// libraries
import React from 'react';
import { useSelector } from 'react-redux';
// Views
import ResourceElement from './ResourceElement';

const ResourcesList = () => {
  const { administration: { resources } } = useSelector(state => state);
  return (
    <div className="resource-configuration">
      {resources && resources
        && Object.keys(resources).map(key => (
          <ResourceElement resourcesTitle={key} resources={resources[key].resources} categoryId={resources[key].categoryId} key={`resource_element_${key}_${resources[key].categoryId}`} />
        ))}
    </div>
  );
};

export default ResourcesList;
