// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ShowNotification, { HideNotification } from '../../../../../actions/notification';
// constants
import { NOTIFICATION_TYPE } from '../../../../../constants/constants';
// services
import {
  getBillingHistoryReports,
  getBillingHistoryReportsFile,
} from '../../../../../services/patient';
import { downloadFile, isObjectEmpty } from '../../../../../services/helpers';
// views
import BillingHistoryTable from '../../../../shared/billingHistory/BillingHistoryTable';


// exported for testing purposes
export function prepareSearchQuery(params) {
  const { sort, dateRange, currentPage } = params;
  const result = {};

  if (sort.key) {
    result.sortBy = sort.key;
    result.sortOrder = sort.reverse ? 'DESC' : 'ASC';
  }

  if (!isObjectEmpty(dateRange)) {
    if (dateRange.from) {
      result.fromDate = `${dateRange.from} 00:00`;
    }

    if (dateRange.to) {
      result.toDate = `${dateRange.to} 23:59`;
    }
  }

  result.pageNumber = currentPage;

  return result;
}

export class EligibilityBillingHistory extends Component {
  state = {
    billingHistory: [],
    totalPages: 0,
    parameters: {},
  };

  promises = {};

  componentDidMount() {
    this.getBillingHistoryData();
  }

  componentWillUnmount() {
    Object.keys(this.promises)
      .forEach((key) => {
        this.promises[key].cancel();
      });
  }

  getBillingHistoryData = async () => {
    const { showNotification, patientId } = this.props;
    const { parameters } = this.state;

    const promiseName = 'getBillingHistoryReports';
    const getBillingHistoryReportRequest = getBillingHistoryReports(patientId, parameters);
    const getBillingHistoryReportPromise = getBillingHistoryReportRequest.promise;
    this.promises[promiseName] = getBillingHistoryReportRequest;
    try {
      const data = await getBillingHistoryReportPromise;
      delete this.promises[promiseName];

      this.setState({
        billingHistory: data.billingReports,
        totalPages: data.totalPages,
      });
    } catch (error) {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load patient billing reports.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  getBillingHistory = (params) => {
    this.setState({
      parameters: prepareSearchQuery(params),
    }, this.getBillingHistoryData);
  };

  downloadReport = async (params) => {
    const { showNotification, patientId } = this.props;
    const promiseName = 'getBillingExcel';
    const getBillingExcelRequest = getBillingHistoryReportsFile(
      patientId, prepareSearchQuery(params),
    );
    const getBillingExcelPromise = getBillingExcelRequest.promise;
    this.promises[promiseName] = getBillingExcelRequest;

    try {
      const resp = await getBillingExcelPromise;
      delete this.promises[promiseName];
      downloadFile(resp.data, resp.fileName);
    } catch (error) {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Can\'t download report.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  render() {
    return (
      <div className="container">
        <BillingHistoryTable
          getBillingHistory={this.getBillingHistory}
          downloadReport={(dateRange) => {
            this.downloadReport(dateRange);
          }}
          billingHistory={this.state.billingHistory}
          totalPages={this.state.totalPages}
          data-test="eligibilityBillingHistory_table"
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(EligibilityBillingHistory);
