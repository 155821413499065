// actions
import ShowNotification from '../../actions/notification';
// constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// history
import { History } from '../../config/history';
// redux
import { store } from '../../config/store';
// services
import { makeCancelablePromise } from '../cancelablePromise';
// Helpers
import { getTenant } from '../helpers';
import { makeRequest } from '../requests';

export function makeGlobalAuthorizedRequest(method, url, data, customHeaders) {
  let authHeader;

  if (customHeaders && customHeaders.length) {
    authHeader = customHeaders.find(elem => elem.name === 'Authorization');
  }

  let headers = [];

  if (!authHeader) {
    try {
      headers.push({
        name: 'Authorization',
        value: `Bearer ${store.getState().user.authData.accessToken.jwtToken}`,
      });
    } catch (e) {
      return Promise.reject({
        status: 401,
      });
    }
  }

  if (customHeaders && customHeaders.length) {
    headers = headers.concat(customHeaders);
  }

  const request = makeRequest(method, url, data, headers);
  const requestPromise = request.promise;

  requestPromise.catch((error) => {
    const tenantUrl = getTenant();
    if (error.status === 401) {
      switch (error.data.reason) {
        case 'TOKEN_EXPIRED':
          History.push(`/${tenantUrl}/expired`);
          return Promise.reject(error);
        case 'ANOTHER_DEVICE': {
          const locationHash = window.location.hash;
          if (locationHash.match(/^#\/$/) || locationHash.match(/^#\/[a-zA-Z0-9-_]+$/)) {
            return Promise.reject(error);
          }

          store.dispatch(ShowNotification({
            message: 'Your account have been logged in on another device.',
            autoHide: true,
            notificationType: NOTIFICATION_TYPE.ERROR,
          }));

          History.push(`/${tenantUrl}/401`);
          return Promise.reject(error);
        }
        case 'OTHER':
        default:
          store.dispatch(ShowNotification({
            message: error && error.data && error.data.message ? error.data.message : 'An error has ocurred.',
            autoHide: true,
            notificationType: NOTIFICATION_TYPE.ERROR,
          }));
          History.push(`/${tenantUrl}/401`);
          return Promise.reject(error);
      }
    } else if (error.status === 403) {
      History.push(`/${tenantUrl}/403`);
      return Promise.reject(error);
    } else if (error.status === 500) {
      store.dispatch(ShowNotification({
        message: `Internal server error has occured. Error details: ${error.data.details}`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
      return Promise.reject(error);
    }

    return Promise.reject(error);
  });

  return makeCancelablePromise(requestPromise);
}
