// Libraries
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
// Services
import { getCallTimeLabel, getQaNoteInfo } from '../../../services/helpers';
// Constants
import {
  BILLING_STATUSES, USER_ROLES, DATE_FORMAT,
  USER_BILLING_STATUSES, TENANT_FEATURES_NAMES,
} from '../../../constants/constants';
// Views
import PatientInfo from './PatientInfo';
import CCMCategories from '../../shared/CCMCategories';
import PatientAttributesRow from '../../shared/PatientAttributesRow';
import TotalCCM from './TotalCCM';

/**
 * PatientRow component, requires props:
 * patients -- array of patients
 * selectPatientCallback -- callback after select patient
 * canPatientStatusBeChanged -- function to check is patient status can be changed
 * params
 */
export function PatientRow(props) {
  const navigate = useNavigate();
  const { tenant: tenantUrl } = useParams();

  const {
    data: patient, user, user: { isExternal: isExternalUser = true, role } = {},
    data: { permissions: patientPermissions, nextActionDateReminderInfo, billingSettings },
    canPatientStatusBeChanged, selectCallback, tenant: { features },
  } = props;

  const {
    reminderDate = '', reminderTime = '', contactMethod = '', type: nextActionType = '',
  } = nextActionDateReminderInfo || {};

  const billing = patient.billing || {};
  const billingDaysLeft = billing.daysLeft;
  const billingIssues = billing.issues || [];
  const billingStatus = billing.sendingStatus || '';
  const billingCanNotBeSubmittedReason = billing.canNotBeSubmittedReason || '';

  const isPesUser = role === USER_ROLES.PES;
  const isPending = billingStatus === 'PENDING_REVIEW';
  const isCSDoNotBill = billingSettings && billingSettings.doNotBill && patient.status === 'CS';

  const callAttemptsLabel = patient.callAttempts;
  const directContactLabel = patient.recentDirectContact
    ? moment(patient.recentDirectContact, DATE_FORMAT.FULL_WITH_TIME).format(DATE_FORMAT.SHORT)
    : '---';
  const callAttemptLabel = patient.recentCallAttempt
    ? moment(patient.recentCallAttempt, DATE_FORMAT.FULL_WITH_TIME).format(DATE_FORMAT.SHORT)
    : '---';

  const showRafScores = features
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.SHOW_RAF_SCORES.name).enabled;
  const isAdminUser = (user.role && user.role === USER_ROLES.ADMIN);
  const isCnUser = (user.role && user.role === USER_ROLES.CN);
  const isCnOwner = patient.assignedCareNavigator && patient.assignedCareNavigator.id === user.id;

  function openPatient() {
    const patientId = patient.id;

    if (isPesUser) {
      navigate(`/${tenantUrl}/cn/summary-patient/${patientId}/overview`);
    } else {
      navigate(`/${tenantUrl}/cn/patient/${patientId}/summary`);
    }
  }

  function selectPatient(event) {
    event.stopPropagation();


    const canBePend = (patientPermissions
      && canPatientStatusBeChanged(patientPermissions.canBeChangedTo));
    const patientData = patient;
    selectCallback(patient.id, canBePend, patientData);
  }

  function renderTotalCcmBlock() {
    const patientCanBeBilled = patientPermissions ? patientPermissions.canBeBilled : false;
    const isQaNote = patient.lastQaNote && (isAdminUser || (isCnUser && isCnOwner));

    // Only to dispay the QA label
    if (!['CC', 'CN', 'CS'].includes(patient.status) && isQaNote) {
      return (
        <div className="d-flex-center">
          <div className={`qa-note-info qa-${patient.lastQaNote && patient.lastQaNote.status.toLowerCase()} d-flex-center`}>
            {getQaNoteInfo(patient.lastQaNote.status)}
          </div>
        </div>
      );
    }

    if (!patientCanBeBilled && !isCSDoNotBill) return null;

    // Only to dispay the on hold Billing status and/or QA label
    if (billingStatus === 'ON_HOLD' || isCSDoNotBill) {
      return (
        <div className="d-flex-center">
          <span
            data-for="tooltip-billingStatus"
            data-tip="Patient billing on hold"
            className="ccm-on-hold bi bi-dash-circle text-ccm-red"
          />
          {isQaNote && (
            <div className="ml-3">
              <div className={`qa-note-info qa-${patient.lastQaNote && patient.lastQaNote.status.toLowerCase()} d-flex-center`}>
                {getQaNoteInfo(patient.lastQaNote.status)}
              </div>
            </div>
          )}
        </div>
      );
    }

    // Only to dispay the Billing info and/or QA label
    return (
      <TotalCCM
        value={patient.totalCcmMtd}
        maxCcmMtd={patient.maxCcmMtd}
        daysLeft={billingDaysLeft}
        billingStatus={billingStatus}
        billingIssues={billingIssues}
        billingCanNotBeSubmittedReason={billingCanNotBeSubmittedReason}
        isPending={isPending}
        lastQaNote={patient.lastQaNote}
        assignedCareNavigator={patient.assignedCareNavigator}
      />
    );
  }

  const renderSelectBlock = () => {
    if (isAdminUser) {
      return (
        <td className="patientCol__actionSelect px-2" onClick={selectPatient} data-test="patientRow_patientTableDataCell">
          <input type="checkbox" checked={patient.selected || false} onChange={() => {}} />
        </td>
      );
    }
    return null;
  };

  const renderCnData = () => {
    if (patient.assignedCareNavigator) {
      return `${patient.assignedCareNavigator.firstName || ''} ${patient.assignedCareNavigator.lastName || ''}`;
    }
    return 'N/A';
  };

  const getBillingStatusColor = () => {
    if (USER_BILLING_STATUSES.includes(patient.status)) {
      if (billingStatus && BILLING_STATUSES[billingStatus]) {
        return BILLING_STATUSES[billingStatus].colour;
      }
    }
    return 'no-colour';
  };

  const getNextActionDateIcon = () => {
    if (nextActionType === 'REMINDER') return 'journal-check';
    if (contactMethod === 'phone') return 'telephone-fill';
    if (contactMethod === 'text') return 'chat-right-dots';
    return '';
  };

  return (
    <tr
      className="patientRow__wrapper"
      onClick={openPatient}
      data-test="patientRow_patientTableRow"
    >
      <td className={`patientCol__status bg-ccm-${getBillingStatusColor()} p-0`} />
      <PatientInfo patient={{ ...patient }} />
      <td className="patientCol__conditions text-left px-1">
        { isExternalUser ? (
          <CCMCategories
            condition={patient.chronicConditions}
            patientName={`${patient.firstName} ${patient.lastName}`}
          />) : (<PatientAttributesRow patient={patient} />)
        }
      </td>
      <td className="patientCol__assignedCn px-1">
        <p>{renderCnData()}</p>
        {patient.tempTakeoverUser && (
          <p className="takeover__name mr-2 text-ccm-red" data-test="patientRow_takeoverName">
            {`TT: ${patient.tempTakeoverUser.firstName || ''} ${patient.tempTakeoverUser.lastName || ''}`}
          </p>
        )}
      </td>
      <td className="hidden patientCol__calls">0/1</td>
      <td className="patientCol__lastPcp px-2">
        {reminderDate ? moment.utc(reminderDate).format(DATE_FORMAT.FULL) : 'N/A'}
        {reminderTime ? ` (${getCallTimeLabel(reminderTime)})` : ''}
        <span className={`bi-${getNextActionDateIcon()} ml-2`} />
      </td>
      {showRafScores && isAdminUser && <td className="patientCol__rafUplift">{patient.potentialRafUplift || ''}</td>}
      <td className="patientCol__monthlyCalls text-left" data-test="patientRow_monthlyCalls">
        <p data-test="patientRow_callAttemptsLabel">{`Attempts: ${callAttemptsLabel}`}</p>
        <p data-test="patientRow_directContactLabel">{`Last Direct: ${directContactLabel}`}</p>
        <p data-test="patientRow_lastCallAttemptLabel">{`Last Attempt: ${callAttemptLabel}`}</p>
      </td>
      <td className="patientCol__totalCcm pl-1 pr-3" data-test="patientRow_totalCCMTime">
        {renderTotalCcmBlock()}
      </td>
      {renderSelectBlock()}
    </tr>
  );
}

export function mapStateToProps(state) {
  return {
    user: state.user,
    tenant: state.tenant,
  };
}

export default connect(mapStateToProps)(PatientRow);
