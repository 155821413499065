// Libraries
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Routes, Route, useParams,
} from 'react-router-dom';
// Actions
import SetUser from '../../../../actions/user';
// Services
import { getUserFromStorage } from '../../../../services/userStorage';
// Global view
import Global from '../Global';
// Login views
import GlobalSignIn from '../../login/GlobalSignIn';
// Error views
import NotFound from '../../../base/errors/404';
import AccessDenied from '../../../base/errors/403';
import Unauthorized from '../../../base/errors/401';
import SessionExpired from '../../../base/errors/SessionExpired';
// Components
import PrivateRoute from '../../../base/entity/PrivateRoute';

export const GlobalTenant = () => {
  const { tenant: tenantUrl } = useParams();
  const user = getUserFromStorage(tenantUrl);

  const dispatch = useDispatch();

  const setUser = () => {
    if (user) {
      dispatch(SetUser(user));
    }
  };

  useEffect(() => {
    setUser();
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<GlobalSignIn />}
      />
      <Route
        path="sign-up"
        element={<GlobalSignIn />}
      />
      <Route
        path="/snook/*"
        element={(
          <PrivateRoute>
            <Global />
          </PrivateRoute>)}
      />
      <Route
        path="403"
        element={<AccessDenied />}
      />
      <Route
        path="401"
        element={<Unauthorized />}
      />
      <Route
        path="expired"
        element={<SessionExpired />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default GlobalTenant;
