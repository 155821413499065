// Libraries
import React, {
  useContext, useState, useEffect, lazy, Suspense,
} from 'react';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Accordion, Card, Button, Dropdown,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdatePatient, UpdatePatientCarePlan } from '../../../../actions/patient';
// Services
import { deleteGoal, deleteGoalProgress } from '../../../../services/patient';
import { getPlainValueFromDraft } from '../../../../services/helpers';
// Constants
import {
  EMPTY_STRING, GOAL_FILTERS, GOAL_STATUSES,
  GOAL_TARGET_COMPLETION, DATE_FORMAT, NOTIFICATION_TYPE, TENANT_FEATURES_NAMES,
} from '../../../../constants/constants';
// Views
import { Loading } from '../../../base/Loading';
// Lazy
const GoalsModal = lazy(() => import('./GoalsModal'));
const GoalsProgressModal = lazy(() => import('./GoalsProgressModal'));

const defaultYear = moment.utc().format(DATE_FORMAT.YEAR);

export function Goals(props) {
  const {
    patientId, carePlan: { goals = [] } = {}, features,
  } = props;

  GOAL_FILTERS[defaultYear] = { name: 'This Year' };

  const goalsWithYear = goals && goals.map(
    goal => ({ ...goal, createdAtYear: moment.utc(goal.createdAt).format(DATE_FORMAT.YEAR) }),
  );
  const goalsYearGrouped = _groupBy(goalsWithYear, goal => goal.createdAtYear);
  const goalsStatusGrouped = _groupBy(goalsWithYear, goal => goal.status);

  if (goalsStatusGrouped.IN_PROGRESS || goalsStatusGrouped.NOT_STARTED
    || goalsStatusGrouped.STRUGGLING || goalsStatusGrouped.DOING_WELL) {
    goalsStatusGrouped.ACTIVE = [
      ...goalsStatusGrouped.IN_PROGRESS || [],
      ...goalsStatusGrouped.NOT_STARTED || [],
      ...goalsStatusGrouped.STRUGGLING || [],
      ...goalsStatusGrouped.DOING_WELL || []];
  }

  Object.keys(goalsYearGrouped).forEach((year) => {
    GOAL_FILTERS[year] = {
      name: `${defaultYear === year ? 'This Year' : year}`,
    };
  });

  const [collapseAll, setCollapseAll] = useState(false);
  const [goalFilter, setGoalFilter] = useState('ACTIVE');
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [selectedProgress, setSelectedProgress] = useState(null);
  const [isGoalsModalOpen, setIsGoalsModalOpen] = useState(false);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [modalsOpened, setModalsOpened] = useState([]);

  const handleAddEditGoal = (goal) => {
    setSelectedGoal(goal);
    setIsGoalsModalOpen(true);
  };

  const handleAddEditProgress = (goal, progress) => {
    setSelectedGoal(goal);
    setSelectedProgress(progress);
    setIsProgressModalOpen(true);
  };

  const isLifeStyleOnly = features
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.LIFESTYLE_GOALS_ONLY.name)
      .enabled;

  const handleDeleteProgressGoal = (goal, progress) => {
    const {
      carePlan, updatePatientCarePlan, showNotification,
    } = props;
    if (!window.confirm('Are you sure you want to remove this progress?')) {
      return;
    }

    const deleteGoalProgressRequest = deleteGoalProgress(goal.id, progress);
    const deleteGoalProgressPromise = deleteGoalProgressRequest.promise;

    deleteGoalProgressPromise.then(() => {
      delete deleteGoalProgressRequest.promise;
      const updatedProgresses = goal.goalProgresses.filter(gp => gp.id !== progress.id);
      const updatedGoal = { ...goal, goalProgresses: updatedProgresses };
      const updatedGoals = goals.map(g => (g.id === goal.id ? updatedGoal : g));

      updatePatientCarePlan({
        ...carePlan,
        goals: updatedGoals,
      });
    }).catch((error) => {
      delete deleteGoalProgressRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient goal progress, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleDeleteGoal = (goal) => {
    const {
      carePlan, updatePatient, updatePatientCarePlan, showNotification,
    } = props;

    if (!window.confirm('Are you sure you want to remove the selected goal?')) {
      return;
    }

    const deleteGoalRequest = deleteGoal(goal);
    const deleteGoalPromise = deleteGoalRequest.promise;

    deleteGoalPromise.then(() => {
      delete deleteGoalRequest.promise;
      const updatedGoals = goals.filter(g => g.id !== goal.id);

      updatePatient({
        hasGoals: updatedGoals && updatedGoals.length,
      });
      updatePatientCarePlan({
        ...carePlan,
        goals: updatedGoals,
      });
    }).catch((error) => {
      delete deleteGoalRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient goal, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderDropDownItems = Object.keys(GOAL_FILTERS).reverse().map((key, index) => (
    <Dropdown.Item
      key={`qas_filter_${index}`}
      onClick={() => {
        setGoalFilter(key);
      }}
      as="button"
      className="d-flex my-1"
      data-test="goals_goalsFilterItem"
    >
      <div className="pr-3">{GOAL_FILTERS[key].name}</div>
    </Dropdown.Item>
  ));

  function renderEmptyRow() {
    return (
      <tr data-test="goals_emptyMsg">
        <td colSpan="6" className="p-2 border-0">
          No goals have been defined for this patient.
        </td>
      </tr>
    );
  }

  useEffect(() => {
    if (isGoalsModalOpen && !modalsOpened.includes('isGoalsModalOpen')) {
      setModalsOpened([...modalsOpened, 'isGoalsModalOpen']);
    }

    if (isProgressModalOpen && !modalsOpened.includes('isProgressModalOpen')) {
      setModalsOpened([...modalsOpened, 'isProgressModalOpen']);
    }
  }, [isGoalsModalOpen, isProgressModalOpen]);

  const renderGoalsRows = () => {
    const goalsToRender = goalsYearGrouped[goalFilter] || goalsStatusGrouped[goalFilter];
    if (goalsToRender && goalsToRender.length) {
      const sortedGoalsByDate = _orderBy(goalsToRender, [el => moment(el.createdAt), 'id'], ['desc', 'desc']);
      return sortedGoalsByDate.map((goal) => {
        const isLegacyGoal = (goal.metrics && !!goal.metrics.length) || goal.targetAt;

        const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);

        const getTargetCompletionLabel = (target, createdAt) => {
          const targetCompletion = GOAL_TARGET_COMPLETION.find(el => el.value === target);

          if (!targetCompletion) return EMPTY_STRING;
          if (!targetCompletion.months || moment(createdAt).isBetween(moment().subtract(targetCompletion.months, 'months'), moment())) {
            return targetCompletion.label;
          }
          return (
            <div className="d-flex-center">
              <span>{targetCompletion.label}</span>
              <small className="ml-2 text-uppercase text-danger border border-danger rounded-pill px-2">
                Overdue
              </small>
            </div>
          );
        };

        const getGoalStatusLabel = (goalStatus) => {
          if (GOAL_STATUSES[goalStatus]) {
            return GOAL_STATUSES[goalStatus].name;
          }

          return GOAL_STATUSES.NOT_STARTED.name;
        };

        const getGoalConditions = conditions => conditions.map(el => el.name).join(', ');

        const getGoalProgresses = goalProgresses => goalProgresses.map(progress => (
          <div key={`goalProgress-${progress.id}`} className="goal__progress d-flex mb-2" data-test="goals_progressItem">
            <span className="mr-3">{progress.createdAt && getDateString(progress.createdAt)}</span>
            <div>
              {getPlainValueFromDraft(progress.note, EMPTY_STRING)}
            </div>
            <i className="bi-pencil-square mx-2" onClick={() => handleAddEditProgress(goal, progress)} data-test="goals_editProgressBtn" />
            <i className="bi-trash text-ccm-red" onClick={() => handleDeleteProgressGoal(goal, progress)} data-test="goals_deleteProgressBtn" />
          </div>
        ));

        const getGoalMetrics = goal.metrics.map(metric => (
          <li key={`goalMetric-${metric.id}`} data-test="goals_metricItem">
            {`Name: ${metric.name}, Target Value: ${metric.targetValue}, Target Range:  ${metric.targetRange}`}
          </li>));

        const descriptionText = getPlainValueFromDraft(goal.text, EMPTY_STRING);

        return (
          <tr key={`goal__row-${goal.id}`} data-test="goals_entryRow">
            <td colSpan="6" className="p-0">
              <Accordion defaultActiveKey="0">
                <Card className="border-0 rounded-0">
                  <Card.Header className="bg-light border-0 p-0">
                    <table className="table mb-0 table-hover">
                      <tbody>
                        <tr>
                          <td className="goal__title" onClick={() => handleAddEditGoal(goal)} data-test="goals_editBtn">
                            {goal.title || EMPTY_STRING}
                          </td>
                          <td className="goal__date" onClick={() => handleAddEditGoal(goal)} data-test="goals_editBtn">
                            {goal.createdAt && getDateString(goal.createdAt)}
                          </td>
                          <td className="goal__target" onClick={() => handleAddEditGoal(goal)} data-test="goals_editBtn">
                            {getTargetCompletionLabel(goal.targetCompletion, goal.createdAt)}
                          </td>
                          <td className={`goal__status text-${goal.status && GOAL_STATUSES[goal.status] && GOAL_STATUSES[goal.status].classname}`} onClick={() => handleAddEditGoal(goal)} data-test="goals_editBtn">
                            {getGoalStatusLabel(goal.status)}
                          </td>
                          <td className="goal__action px-0">
                            <CustomToggle eventKey="0" collapseAll={collapseAll} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="mb-2">
                        <span className="mr-2">Associated Categories/Conditions:</span>
                        {goal.conditions && !!goal.conditions.length
                          ? getGoalConditions(goal.conditions) : EMPTY_STRING}
                      </p>
                      {!isLifeStyleOnly && (
                        <div className="d-flex mb-2">
                          <p>
                            <span className="mr-2">Baseline:</span>
                            {goal.baseline || EMPTY_STRING}
                          </p>
                          <p className="ml-5">
                            <span className="mr-2">Target:</span>
                            {goal.target || EMPTY_STRING}
                          </p>
                        </div>)}
                      <div className="d-flex">
                        <p className="mr-2">Description:</p>
                        <div className="flex-grow-1">
                          <i className="white-space-pre-line">{descriptionText}</i>
                        </div>
                      </div>
                      { isLegacyGoal && (
                        <div className="mt-2">
                          <p>Additional Info:</p>
                          { goal.targetAt && (
                            <p className="mt-1">
                              <span className="ml-3 mr-1 mt-1">Target Date:</span>
                              {goal.targetAt}
                            </p>
                          )}
                          { (goal.metrics && !!goal.metrics.length) && (
                            <div>
                              <p className="ml-3 mr-1 mt-1">Metrics:</p>
                              <ul className="ml-3">
                                {getGoalMetrics}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                      <hr />
                      {goal.goalProgresses && !!goal.goalProgresses.length
                        ? getGoalProgresses(goal.goalProgresses)
                        : (
                          <p className="goal__progress" data-test="goals_noProgressHeadingTxt">No goal progress has been recorded</p>)
                      }
                      <div className="text-right">
                        <Button size="xs" variant="danger" className="mr-2" onClick={() => handleDeleteGoal(goal)} data-test="goals_deleteBtn">
                          Delete Goal
                          <i className="bi-trash ml-2" />
                        </Button>
                        <Button size="xs" variant="primary" onClick={() => handleAddEditProgress(goal, null)} data-test="goals_addProgressBtn">
                          Add Progress
                          <i className="bi-plus-circle ml-2" />
                        </Button>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  return (
    <div className="care-plan-goals card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <Dropdown
          className="position-absolute"
          style={{ left: 1 }}
          data-test="goals_goalsFilter"
        >
          <Dropdown.Toggle
            variant="ccm-light-gray"
            className="py-0"
          >
            {`Filter: ${GOAL_FILTERS[goalFilter].name}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {renderDropDownItems}
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-uppercase">Goals</span>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '3rem' }}
          onClick={() => setCollapseAll(!collapseAll)}
          data-test="goals_collapseAllBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => handleAddEditGoal(null)}
          data-test="goals_addBtn"
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2 goal__title">Title</th>
              <th className="py-2 goal__date">Date</th>
              <th className="py-2 goal__target">Target Completion</th>
              <th className="py-2 goal__status">Status</th>
              <th className="py-2 goal__action px-0" />
            </tr>
          </thead>
          <tbody>
            {renderGoalsRows()}
          </tbody>
        </table>
      </div>
      { modalsOpened.includes('isGoalsModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          <GoalsModal
            patientId={patientId}
            initialGoal={selectedGoal}
            isModalOpen={isGoalsModalOpen}
            setIsModalOpen={setIsGoalsModalOpen}
          />
        </Suspense>)
      }
      { modalsOpened.includes('isProgressModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          <GoalsProgressModal
            goal={selectedGoal}
            initialProgress={selectedProgress}
            isModalOpen={isProgressModalOpen}
            setIsModalOpen={setIsProgressModalOpen}
          />
        </Suspense>)
      }
    </div>
  );
}

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0" onClick={decoratedOnClick}>
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

function mapStateToProps(state) {
  return {
    carePlan: state.patient && state.patient.carePlan,
    features: state.tenant && state.tenant.features,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updatePatientCarePlan: patientData => dispatch(UpdatePatientCarePlan(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Goals);
