import { useField } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

export const IntegerInput = ({
  label, subtext, styles = {}, ...props
}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <Form.Group className={styles.formGroup}>
      <Form.Label
        htmlFor={props.id || props.name}
        className={styles.formLabel}
      >
        {label || ''}
      </Form.Label>
      <Form.Control
        aria-describedby={`${props.name} Integer Input`}
        onChange={props.handleChange}
        isValid={meta.value && (meta.touched && !meta.error)}
        isInvalid={meta.error}
        className={styles.formControl}
        type="number"
        {...field}
        {...props}
      />
      {subtext && <Form.Text muted>{subtext}</Form.Text>}
      <Form.Control.Feedback type="invalid" className={styles.formInvalid} muted>{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};
