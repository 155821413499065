// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { Button, Form } from 'react-bootstrap';
// Services
import { createMedication, updateMedication } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  DATE_FORMAT, NOTIFICATION_TYPE, DIALOG_STYLES,
} from '../../../constants/constants';
// Components
import { TextInput } from '../../base/forms/TextInput';
import { Datepicker } from '../../base/forms/Datepicker';

export function MedicationsModal(props) {
  const {
    initialMedication, isModalOpen, setIsModalOpen,
  } = props;

  const DEFAULT_MEDICATION = {
    id: null,
    title: '',
    route: '',
    dosage: '',
    endedAt: null,
    startedAt: null,
  };

  const [newMedication, setNewMedication] = useState(initialMedication || DEFAULT_MEDICATION);

  useEffect(() => {
    if (initialMedication) {
      setNewMedication({
        id: initialMedication.id || '',
        title: initialMedication.title || '',
        route: initialMedication.route || '',
        dosage: initialMedication.dosage || '',
        endedAt: !initialMedication.endedAt
          ? null : moment(initialMedication.endedAt).format(DATE_FORMAT.SHORT),
        startedAt: !initialMedication.startedAt
          ? null : moment(initialMedication.startedAt).format(DATE_FORMAT.SHORT),
      });
    } else {
      setNewMedication(DEFAULT_MEDICATION);
    }
  }, [initialMedication]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const savePatientMedication = (medicationEntries) => {
    const {
      patientId, patient: { otcMeds }, showNotification, updatePatient,
    } = props;

    const isNewMedication = !medicationEntries.id;

    const medicationRequest = isNewMedication
      ? createMedication(patientId, medicationEntries) : updateMedication(medicationEntries);
    const medicationPromise = medicationRequest.promise;

    return medicationPromise.then((data) => {
      delete medicationRequest.promise;
      let updatedMedications;
      if (isNewMedication) {
        updatedMedications = [...otcMeds, data];
      } else {
        updatedMedications = otcMeds.map(p => (p.id === data.id ? data : p));
      }
      updatePatient({ otcMeds: updatedMedications });

      handleCloseModal();
    }).catch((error) => {
      delete medicationRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this medication.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Medications Modal"
      data-test="medicationsModal_modal"
    >
      <div className="simple-dialog">
        <div className="dialog-title">
          {`${initialMedication ? 'Edit' : 'Add'} Non EHR Medication`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="medicationsModal_modalCloseButton"
          />
        </div>
        <Formik
          initialValues={newMedication}
          validationSchema={Yup.object({
            title: Yup.string()
              .required('Required'),
            route: Yup.string(),
            dosage: Yup.string(),
            startedAt: Yup.date('Must be a valid date').typeError('Must be a valid date').nullable(),
            endedAt: Yup.date('Must be a valid date').typeError('Must be a valid date').nullable()
              .when('startedAt', (startedAt, yup) => (moment(startedAt).isValid() ? yup.min(startedAt, 'End date cannot be before start date') : yup)),
          })}
          onSubmit={(values, { resetForm }) => {
            const formattedEndedAt = !values.endedAt ? '' : moment(values.endedAt).format(DATE_FORMAT.FULL_SERVER);
            const formattedStartedAt = !values.startedAt ? '' : moment(values.startedAt).format(DATE_FORMAT.FULL_SERVER);
            const updatedValues = {
              ...values,
              endedAt: formattedEndedAt,
              startedAt: formattedStartedAt,
            };
            savePatientMedication(updatedValues);
            resetForm();
          }}
          data-test="medicationsModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="dialog-content text-left">
                <TextInput
                  label="Name"
                  name="title"
                  data-test="medicationsModal_nameTextBox"
                />
                <TextInput
                  label="Dose"
                  name="dosage"
                  data-test="medicationsModal_dosageTextBox"
                />
                <TextInput
                  label="Route of Administration"
                  name="route"
                  data-test="medicationsModal_routeOfAdminTextBox"
                />
                <div className="row align-items-center">
                  <div className="col" data-test="medicationsModal_startDate">
                    <Datepicker
                      label="Start Date"
                      name="startedAt"
                    />
                  </div>
                  <div className="col" data-test="medicationsModal_endDate">
                    <Datepicker
                      label="End Date"
                      name="endedAt"
                    />
                  </div>
                </div>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="medicationsModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="medicationsModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationsModal);
