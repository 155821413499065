export default function AddTenants(data) {
  return {
    type: 'TENANTS_LOADED',
    tenant: data,
  };
}

export function SetTenantTimezone(timezone) {
  return {
    type: 'TIMEZONE_LOADED',
    timezone,
  };
}

export function SetIsDemoEnv(data) {
  return {
    type: 'IS_DEMO_ENV_LOADED',
    isDemoEnv: data,
  };
}

export function SetTenantTags(data) {
  return {
    type: 'SET_TENANT_TAGS',
    tags: data,
  };
}

export function SetTenantFeatures(data) {
  return {
    type: 'SET_TENANT_FEATURES',
    features: data,
  };
}

export function SetGlobalTenant(data) {
  return {
    type: 'SET_GLOBAL_TENANT',
    globalTenant: data,
  };
}

export function SetGlobalLogin(data) {
  return {
    type: 'SET_GLOBAL_LOGIN',
    globalLogin: data,
  };
}

export function SetShowGlobalTopBar(data) {
  return {
    type: 'SET_SHOW_GLOBAL_TOP_BAR',
    showGlobalTopBar: data,
  };
}

export function SetConsentUrl(data) {
  return {
    type: 'SET_CONSENT_URL',
    consentUrl: data,
  };
}
