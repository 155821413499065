// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import _isArray from 'lodash/isArray';
// Services
import { addPatientImmunization, updatePatientImmunization } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient, UpdatePatientHistory } from '../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT, VACCINATION_TYPES,
} from '../../../constants/constants';
// Components
import { Select } from '../../base/forms/Select';
import { Datepicker } from '../../base/forms/Datepicker';
import { TextArea } from '../../base/forms/TextArea';

export const immunizationsSchema = () => Yup.object({
  date: Yup.date().required('Required')
    .max(moment.utc().format(DATE_FORMAT.SHORT), 'Must not be in the future'),
  name: Yup.string().required('Required'),
  note: Yup.string(),
});

export function ImmunizationsModal(props) {
  const {
    patientId, showNotification,
    isModalOpen, setIsModalOpen, initialImmunization,
  } = props;

  const DEFAULT_IMMUNIZATION = {
    id: null,
    note: '',
    date: moment.utc().format(DATE_FORMAT.SHORT),
    name: '',
  };

  const [immunization, setImmunization] = useState(initialImmunization || DEFAULT_IMMUNIZATION);

  useEffect(() => {
    if (initialImmunization) {
      setImmunization({
        id: initialImmunization.id || '',
        note: initialImmunization.note || '',
        date: moment(initialImmunization.date).format(DATE_FORMAT.SHORT)
          || moment.utc().format(DATE_FORMAT.SHORT),
        name: initialImmunization.name || '',
      });
    } else {
      setImmunization(DEFAULT_IMMUNIZATION);
    }
  }, [initialImmunization]);

  const addNewPatientImmunization = (immunizationData) => {
    const {
      patientHistory: { immunizations } = {}, patientHistory,
      updatePatient, updatePatientHistory,
    } = props;

    const isNewImmunization = !immunizationData.id;
    const addImmunizationsRequest = isNewImmunization
      ? addPatientImmunization(patientId, immunizationData)
      : updatePatientImmunization(patientId, immunizationData.id, immunizationData);
    const addImmunizationPromise = addImmunizationsRequest.promise;

    return addImmunizationPromise.then((data) => {
      delete addImmunizationsRequest.promise;
      const oldImmunizations = _isArray(immunizations) ? immunizations : [];
      let updatedImmunizations;
      if (isNewImmunization) {
        updatedImmunizations = [...oldImmunizations, data];
      } else {
        updatedImmunizations = oldImmunizations.map(p => (p.id === data.id ? data : p));
      }
      updatePatient({
        immunizations: updatedImmunizations,
      });
      updatePatientHistory({
        ...patientHistory,
        immunizations: updatedImmunizations,
      });
    }).catch((error) => {
      delete addImmunizationsRequest.promise;

      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this immunization',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateImmunizations = (values) => {
    addNewPatientImmunization(values);
    handleCloseModal();
  };

  const getImmunizationsValues = () => {
    const options = VACCINATION_TYPES.VACCINATION.map(key => ({
      value: key,
      label: key,
    }));
    options.push({ value: 'Other', label: 'Other' });
    return options;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Immunization"
      data-test="immunizationsModal_onRequestClose"
    >
      <div className="simple-dialog small-dialog">
        <div className="dialog-title">
          {`${initialImmunization ? 'Edit' : 'Add'} Immunization`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="immunizationsModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={immunization}
          validationSchema={immunizationsSchema()}
          onSubmit={(values) => {
            const formattedDate = moment(values.date).format(DATE_FORMAT.FULL_SERVER);
            const updatedValues = { ...values, date: formattedDate };
            handleCreateImmunizations(updatedValues);
          }}
          data-test="immunizationsModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="dialog-content text-left">
                <div className="col">
                  <Datepicker
                    label="Date"
                    name="date"
                  />
                </div>
                <div className="col">
                  <Select
                    label="Name"
                    name="name"
                    options={getImmunizationsValues()}
                  />
                </div>
                <div className="col">
                  <TextArea
                    label="Note"
                    name="note"
                    rows={5}
                    data-test="immunizationsModal_note"
                    maxLength="500"
                  />
                </div>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="immunizationsModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  data-test="immunizationsModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patientHistory: state.patient && state.patient.patientHistory,
    timezone: state.tenant && state.tenant.timezone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updatePatientHistory: patientHistoryData => dispatch(UpdatePatientHistory(patientHistoryData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImmunizationsModal);
