export function SetPhysiciansList(data) {
  return {
    type: 'PHYSICIANS_LIST',
    data,
  };
}

export function SetBillingPhysiciansList(data) {
  return {
    type: 'BILLING_PHYSICIANS_LIST',
    billingPhysiciansList: data || [],
  };
}
