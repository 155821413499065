import { makeAuthorizedRequest } from './requests';
import { formQueryString } from './helpers';

export function getPhysicians(pageSize, params, billable, active, lastName) {
  const queryString = formQueryString({ pageSize, ...params });
  const billableParam = billable === undefined ? '' : `&billable=${billable}`;
  const activeParam = active === undefined ? '' : `&active=${active}`;
  const lastNameParam = !lastName ? '' : `&lastName=${lastName}`;
  return makeAuthorizedRequest('GET', `/physicians-info${queryString}${billableParam}${activeParam}${lastNameParam}`);
}

export function updateBillablePhysicians(physicianId, data) {
  return makeAuthorizedRequest('PUT', `/physicians/${physicianId}`, data);
}

export function reassignBillablePhysician(physicianOldId, physicianNewId) {
  return makeAuthorizedRequest('PUT', `/physicians/change/${physicianOldId}/${physicianNewId}`);
}
