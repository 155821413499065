export default function ShowNotification(params) {
  return {
    type: 'SHOW_NOTIFICATION',
    autoHide: params.autoHide,
    message: params.message,
    notificationType: params.notificationType,
  };
}

export function HideNotification() {
  return {
    type: 'HIDE_NOTIFICATION',
  };
}
