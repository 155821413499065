import { createAction, createReducer } from '@reduxjs/toolkit';

const usersList = createAction('USERS_LIST');

const initialState = {
  usersList: {
    Enabled: {
      data: [],
      loadedPages: [],
      totalUserPages: 0,
    },
  },
};

const UsersList = createReducer(initialState, (builder) => {
  builder
    .addCase(usersList, (state, action) => {
      state.usersList = action.data.usersList || state.usersList;
    })
    .addDefaultCase(state => state);
});

export default UsersList;
