// Libraries
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// Services
import { dismissAlert } from '../../../services/alerts';
// Actions
import ShowNotification from '../../../actions/notification';
// Views
import PatientAlertRow from './PatientAlertRow';
// Components
import Pager from '../../patients/Pager';
// Constants
import { NOTIFICATION_TYPE } from '../../../constants/constants';
import { DEFAULT_PAGE_SIZE } from '../../../constants/pageSizes';

export const PatientAlerts = (props) => {
  const { alerts = [], setAlerts } = props;
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [collapseAll, setCollapseAll] = useState(false);

  const removePatientAlert = (id, isMultiple = false) => {
    const dismissAlertRequest = dismissAlert(id);
    const dismissAlertPromise = dismissAlertRequest.promise;

    dismissAlertPromise.then(() => {
      delete dismissAlertRequest.promise;
      if (!isMultiple) {
        setAlerts(alerts.filter(a => a.patientAlertid !== id));
        showNotification({
          message: 'Patient alert dismissed',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.SUCCESS,
        });
      } else {
        setAlerts([]);
      }
    }).catch((error) => {
      delete dismissAlertRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not remove Flight Plan Alert, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const removeAllAlerts = () => {
    alerts.forEach(alert => removePatientAlert(alert.patientAlertid, true));
    showNotification({
      message: 'Patient alerts dismissed',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.SUCCESS,
    });
    setTotalCount(0);
    setTotalPages(0);
  };

  const renderNoResults = () => (
    <div className="align-content w-100 mt-5">
      <span>No alerts found</span>
    </div>
  );

  const showingResults = () => {
    const tempCount = (currentPage + 1) === totalPages
      ? totalCount : DEFAULT_PAGE_SIZE * (currentPage + 1);
    return `${(currentPage * DEFAULT_PAGE_SIZE) + 1} - ${tempCount} of ${totalCount}`;
  };

  return (
    <div className="patient-summary-alerts h-100 overflow-auto pr-3 mt-4">
      <div className="d-flex filter align-items-center mt-0 mb-3">
        <h4 className="text-left" data-test="alerts_headingText">Alerts</h4>
        <div className="ml-auto d-flex-center">
          <Button
            size="sm"
            variant="light"
            className="d-flex-center mr-2"
            onClick={() => removeAllAlerts()}
            data-test="patientAlerts_removeAllAlertsButton"
          >
            <span>Dismiss All Alerts</span>
          </Button>
          <span data-test="patientAlerts_resultsInfo">{`Results: ${showingResults()}`}</span>
          <Button
            size="sm"
            variant="link-dark"
            className="d-flex-center ml-2"
            onClick={() => setCollapseAll(!collapseAll)}
            data-test="patientAlerts_collapseAllButton"
          >
            <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
            <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
          </Button>
        </div>
      </div>
      <div className="row no-gutters alert-content">
        {alerts.map(alert => (
          <PatientAlertRow
            key={`patient-alert__${alert.patientAlertid}`}
            alert={alert}
            removePatientAlert={removePatientAlert}
            collapseAll={collapseAll}
            data-test="patientAlerts_content"
          />
        ))}
        {!alerts.length && renderNoResults()}
      </div>
      <div className="row recent-work-pager no-gutters d-flex-center my-3">
        <Pager
          totalPages={totalPages}
          maxShownCount={5}
          isNextPrevShown
          currentPage={currentPage}
          callback={page => setCurrentPage(page)}
          data-test="patientAlerts_pager"
        />
      </div>
    </div>
  );
};

export default PatientAlerts;
