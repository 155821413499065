// Libraries
import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
// Views
import { Button } from 'react-bootstrap';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../../../constants/constants';
import { NON_PRIMARY_DX_CODES } from '../../../../constants/codes/nonPrimaryDxCodes';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdatePatient } from '../../../../actions/patient';


export function DiagnosisElement(props) {
  const {
    problem = {}, handleEdit, handleDelete, showNotification, editMode,
    patient: { billing, billing: { billingInfo } = {} } = {}, updatePatient,
  } = props;
  const onsetDate = problem.onsetDate || problem.newProblemAt || problem.startedAt;
  const onsetDateFormatted = onsetDate ? moment(onsetDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.SHORT) : '';
  const tooltipId = 'tooltip-billingDiagnosis';
  const maxNumProblems = billingInfo && billingInfo.problems && billingInfo.problems.length;

  const getProblemString = () => {
    let text = (
      <strong
        data-tip="Manually added"
        data-for={tooltipId}
      >
        M
      </strong>
    );

    if (problem.diagnoseInfo) {
      text = (
        <strong data-tip="EHR diagnose" data-for={tooltipId}>EHR</strong>
      );
    }

    return (
      text
    );
  };

  const isPrimaryDxInvalid = diagnosis => NON_PRIMARY_DX_CODES.includes(diagnosis.code);

  const handleUpItem = () => {
    if (isPrimaryDxInvalid(problem) && (problem.order - 1) === 1) {
      showNotification({
        message: `Unacceptable principal diagnosis: ${problem.code}. Please correct before adding this diagnosis.`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return;
    }

    const billingFormProblems = billingInfo.problems.map((diagnosis) => {
      if (diagnosis.order === (problem.order - 1)) {
        return ({
          ...diagnosis,
          isEdit: true,
          order: diagnosis.order + 1,
        });
      }
      if ((diagnosis.id && diagnosis.id === problem.id) || (diagnosis.code === problem.code)) {
        return ({
          ...diagnosis,
          isEdit: true,
          order: problem.order - 1,
        });
      }
      return diagnosis;
    });

    updatePatient(
      {
        billing: {
          ...billing, billingInfo: { ...billingInfo, problems: [...billingFormProblems] },
        },
      },
    );
  };

  const handleDownItem = () => {
    const billingFormProblems = billingInfo.problems.map((diagnosis) => {
      if (diagnosis.order === (problem.order + 1)) {
        return ({
          ...diagnosis,
          isEdit: true,
          order: diagnosis.order - 1,
        });
      }
      if ((diagnosis.id && diagnosis.id === problem.id) || (diagnosis.code === problem.code)
      ) {
        return ({
          ...diagnosis,
          isEdit: true,
          order: problem.order + 1,
        });
      }
      return diagnosis;
    });

    updatePatient(
      {
        billing: {
          ...billing, billingInfo: { ...billingInfo, problems: [...billingFormProblems] },
        },
      },
    );
  };

  const getIsCCM = () => problem.diagnoseInfo && problem.diagnoseInfo.isCcm;

  return (
    <tr
      key={`diagnosis_row_${problem.name}-${problem.id}`}
      className={`${problem.isNew ? 'new-problem' : ''} ${problem.isEdit && !problem.isNew ? 'edited-problem' : ''}`}
    >
      <td>
        {
          editMode && (
            <div className="d-flex flex-column">
              { (problem.order !== 1) && (
                <Button
                  variant="link-dark"
                  className="p-0"
                  onClick={() => handleUpItem()}
                  data-for={tooltipId}
                  data-tip="Up"
                  data-test="diagnosisElement_arrowUpButton"
                >
                  <i className="bi bi-arrow-up-short" />
                </Button>
              )}
              { (problem.order < maxNumProblems) && (
                <Button
                  variant="link-dark"
                  className="p-0"
                  onClick={() => handleDownItem()}
                  data-for={tooltipId}
                  data-tip="Down"
                  data-test="diagnosisElement_downUpButton"
                >
                  <i className="bi bi-arrow-down-short" />
                </Button>
              )}
            </div>
          )
        }
      </td>
      <td className="billing__source" data-test="diagnosisElement_billingSource">
        {getProblemString()}
      </td>
      <td className="billing__problem" data-test="diagnosisElement_billingProblems">
        {problem.name}
      </td>
      <td className="billing__ccm" data-test="diagnosisElement_billingCCM">
        {getIsCCM() ? <span className="badge badge-ccm-orange py-2 px-4">Eligible</span> : ''}
      </td>
      <td data-test="diagnosisElement_problemCode">
        {problem.code}
      </td>
      <td data-test="diagnosisElement_onsetDate">
        {onsetDateFormatted}
      </td>
      <td className="billing__actions">
        {
          editMode && (
            <Fragment>
              {!problem.diagnoseInfo && (
                <Button
                  variant="link-dark"
                  className="px-1"
                  onClick={() => handleEdit(problem, true)}
                  data-test="diagnosisElement_editButton"
                >
                  <i className="bi bi-pencil-square" />
                </Button>)
              }
              <Button
                variant="link-dark"
                className="px-1 float-right"
                onClick={() => handleDelete(problem)}
                data-test="diagnosisElement_deleteButton"
              >
                <i className="bi-trash" />
              </Button>
            </Fragment>
          )
        }
      </td>
    </tr>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisElement);
