// Libraries
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
// Views
import ReactTooltip from 'react-tooltip';
import PreferredNameModal from './PreferredNameModal';
// Services
import { formatPhone } from '../../../services/helpers';
import { savePatientProfile, updatePatientProfile, saveGeneralInfo } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  EMPTY_STRING, NOTIFICATION_TYPE, NONE_SPECIFIED_STRING, DEFAULT_PROFILE, EMPTY_ADDRESS,
} from '../../../constants/constants';
// Hooks
import useConfirm from '../../../hooks/useConfirm';

const MAP_PHONES = {
  homePhoneInvalid: 'homePhone',
  cellPhoneInvalid: 'mobilePhone',
  workPhoneInvalid: 'workPhone',
};

export function Contact(props) {
  const {
    patient: {
      addressInfo,
      contactInfo: {
        email, workPhone, homePhone, mobilePhone, preferredPhone,
      } = {},
      contactInfo = {},
      guardianContact = {},
      profile = {},
    } = {}, patientId, preferredName,
  } = props;

  let isNotEmptyAddressInfo = true;
  if (addressInfo) {
    isNotEmptyAddressInfo = Object.values(addressInfo).some(e => Boolean(e));
  }

  const { isConfirmed } = useConfirm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chosenName, setChosenName] = useState(preferredName);

  const updateContactInfo = (newValue) => {
    const { updatePatient, patient } = props;
    const patientUpdated = {
      ...patient,
      contactInfo: {
        ...patient.contactInfo,
        preferredPhone: newValue,
      },
    };

    const saveGeneralInfoRequest = saveGeneralInfo(patientUpdated, patientId);
    const saveGeneralInfoPromise = saveGeneralInfoRequest.promise;

    return saveGeneralInfoPromise.then(() => {
      delete saveGeneralInfoRequest.promise;
      updatePatient({
        contactInfo: patientUpdated.contactInfo,
        guardianContact: patientUpdated.guardianContact,
      });
    }).catch((error) => {
      delete saveGeneralInfoRequest.promise;
      const { showNotification } = props;

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not update preferred phone number.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const updateProfileData = (key, value) => {
    const {
      patient, updatePatient, showNotification,
    } = props;
    const newProfileData = profile.createdAt
      ? { ...patient.profile, [key]: value }
      : { ...DEFAULT_PROFILE, [key]: value };

    const updateProfileRequest = profile.createdAt
      ? updatePatientProfile(patientId, newProfileData)
      : savePatientProfile(patientId, newProfileData);
    const updateProfilePromise = updateProfileRequest.promise;
    const phoneMustBeUpdated = value && contactInfo[MAP_PHONES[key]] === preferredPhone;

    return updateProfilePromise.then((data) => {
      delete updateProfileRequest.promise;
      updatePatient({ profile: data });

      if (phoneMustBeUpdated) updateContactInfo('');
      if (key === 'preferredName') setChosenName(value);
    }).catch((error) => {
      delete updateProfileRequest.promise;

      if (error.status === 401 || error.status === 403 || error.isCanceled) return;

      showNotification({
        message: 'An error occurred while attempting to update this patient\'s profile',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const activeOrInactivePhone = async (phoneType, state) => {
    const confirmationMessage = !state ? 'Do you want to mark this phone number invalid?' : 'Do you want to mark this phone number valid?';
    const confirmed = await isConfirmed(confirmationMessage);
    if (confirmed) {
      updateProfileData(phoneType, !profile[phoneType]);
    }
  };

  const removePreferredName = async () => {
    const confirmed = await isConfirmed('Are you sure to remove the preferred name?');
    if (confirmed) updateProfileData('preferredName', null);
  };

  useEffect(() => {
    if (preferredName) setChosenName(preferredName);
  }, [preferredName]);

  const renderBasicContact = () => {
    const emergencyContact = `${guardianContact.name ? guardianContact.name : ''} (${guardianContact.relation ? guardianContact.relation : ''}) ${formatPhone(guardianContact.phoneNumber)}`;
    return (
      <Fragment>
        <div className="d-flex align-items-center">
          <span className="mr-1" data-test="contact_changePreferredNameLabel">Preferred Name:</span>
          <Button
            variant="link"
            className="p-0"
            onClick={() => setIsModalOpen(true)}
            data-test="contact_changePreferredName"
          >
            <span>{chosenName || NONE_SPECIFIED_STRING}</span>
          </Button>
          {chosenName && (
            <Button
              variant="link-dark"
              className="p-0 ml-1"
              onClick={() => removePreferredName()}
            >
              <span className="bi-x" />
            </Button>
          )}
        </div>
        <div className="d-flex align-items-center">
          <span className="mr-1" data-test="contact_PreferredEHRNameLabel">Preferred EHR Name:</span>
          <span data-test="contact_PreferredEHRNameValue">{ profile.emrPreferredName || EMPTY_STRING}</span>
        </div>
        <div className="d-flex align-items-center">
          <span className="mr-1">Home:</span>
          <Button
            variant="link"
            className="p-0"
            onClick={() => activeOrInactivePhone('homePhoneInvalid', profile.homePhoneInvalid)}
            data-test="contact_activeOrInactiveHomePhone"
            disabled={!homePhone}
          >
            <span>{homePhone ? formatPhone(homePhone) : EMPTY_STRING}</span>
          </Button>
          { homePhone && profile.homePhoneInvalid && (
            <i
              className="bi bi-exclamation-lg text-ccm-red"
              data-tip="Invalid Phone Number"
              data-for="contact_reactTooltip"
            />
          )}
        </div>
        <div className="d-flex align-items-center">
          <span className="mr-1">Cell:</span>
          <Button
            variant="link"
            className="p-0"
            onClick={() => activeOrInactivePhone('cellPhoneInvalid', profile.cellPhoneInvalid)}
            data-test="contact_activeOrInactiveCellPhone"
            disabled={!mobilePhone}
          >
            <span>
              {mobilePhone ? formatPhone(mobilePhone) : EMPTY_STRING}
            </span>
          </Button>
          { mobilePhone && profile.cellPhoneInvalid && (
            <i
              className="bi bi-exclamation-lg text-ccm-red"
              data-tip="Invalid Phone Number"
              data-for="contact_reactTooltip"
            />
          )}
        </div>
        <div className="d-flex align-items-center">
          <span className="mr-1">Work:</span>
          <Button
            variant="link"
            className="p-0"
            onClick={() => activeOrInactivePhone('workPhoneInvalid', profile.workPhoneInvalid)}
            data-test="contact_activeOrInactiveWorkPhone"
            disabled={!workPhone}
          >
            <span>{workPhone ? formatPhone(workPhone) : EMPTY_STRING}</span>
          </Button>
          { workPhone && profile.workPhoneInvalid && (
            <i
              className="bi bi-exclamation-lg text-ccm-red"
              data-tip="Invalid Phone Number"
              data-for="contact_reactTooltip"
            />
          )}
        </div>
        <div>
          <span className="mr-1">email:</span>
          <span>{ email || EMPTY_STRING}</span>
        </div>
        <div>
          <span className="mr-1">Emergency Contact:</span>
          <span>
            { guardianContact.phoneNumber ? emergencyContact : EMPTY_STRING }
          </span>
        </div>
        <div>
          <span className="mr-1">Address:</span>
          {addressInfo && isNotEmptyAddressInfo ? (
            <span data-test="patientAddress">
              { addressInfo.street ? `${addressInfo.street} ` : '' }
              { addressInfo.city ? `${addressInfo.city}, ` : '' }
              { addressInfo.state ? `${addressInfo.state} ` : '' }
              { addressInfo.postalCode ? `${addressInfo.postalCode} ` : '' }
            </span>
          )
            : (
              <span data-test="patientAddress">
                {EMPTY_ADDRESS}
              </span>
            )
          }
        </div>
        <ReactTooltip id="contact_reactTooltip" type="info" effect="float" place="bottom" />
        <PreferredNameModal
          preferredName={chosenName}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          updateProfileData={updateProfileData}
        />
      </Fragment>
    );
  };

  return renderBasicContact();
}

function mapStateToProps(state) {
  const profile = state.patient.profile || {};
  const preferredName = profile && (profile.preferredName || '');
  return {
    preferredName,
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
