// libraries
import React, { Fragment } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// Global Views
import GlobalMenu from './GlobalMenu';
import GlobalUser from './entity/GlobalUser';
import GlobalRedirectUser from './entity/GlobalRedirectUser';

export const Global = () => (
  <Fragment>
    <Routes>
      <Route
        path="redirect"
        element={<GlobalRedirectUser />}
      />
      <Route
        path="list"
        element={<GlobalMenu />}
      />
      <Route
        path="*"
        element={<GlobalUser />}
      />
      <Route
        path="/"
        element={<Navigate to="list" />}
      />
    </Routes>
  </Fragment>
);

export default Global;
