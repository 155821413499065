// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
// actions
import ShowNotification from '../../../actions/notification';
// constants
import {
  DIALOG_STYLES, DEFAULT_FIELDS_LENGTH,
  HINT_TEXT, DATE_FORMAT, DIGITAL_PHONE_LENGTH,
  INVITE_FORM_STATE as INITIAL_FORM_STATE, ROLE_FULL_NAMES,
  NOTIFICATION_TYPE, USER_ROLES, USER_TYPES, PASSWORD_REGEX_CHARACTERS,
  PASSWORD_REGEX_NUMBERS, PASSWORD_REGEX_UPPERCASE, PASSWORD_REGEX_LOWERCASE,
} from '../../../constants/constants';
// services
import {
  formatPhoneForRequest, formatPhone,
  isObjectsEqual, isObjectEmpty, formatPhoneOnlyDigits,
  copyToClipboard, autoGeneratePassword,
} from '../../../services/helpers';
import { inviteUser } from '../../../services/administration';
// views
import LoadingBlock from '../../menu/LoadingBlock';
// Components
import { Select } from '../../base/forms/Select';
import { Datepicker } from '../../base/forms/Datepicker';
import { TextInput } from '../../base/forms/TextInput';
import { Checkbox } from '../../base/forms/Checkbox';

const EXTERNAL_KEY = 'external';

function prepareRoleList(roles) {
  return Object.keys(roles).map(key => (
    {
      value: roles[key],
      label: `${key} - ${ROLE_FULL_NAMES[roles[key]]}`,
    }
  ));
}

const getTypes = () => Object.keys(USER_TYPES).map(key => ({
  value: EXTERNAL_KEY === key,
  label: USER_TYPES[key],
}));

export class InviteUser extends Component {
  constructor(props) {
    super(props);

    const { currentUser: { role } = {} } = props;
    const isCustomerUser = role === USER_ROLES.CUSTOMER;
    const roles = prepareRoleList(props.roles || []);
    const types = getTypes();

    this.state = {
      loading: false,
      modalOpened: false,
      errorText: '',
      errors: null,
      showPassword: false,
      form: {
        ...INITIAL_FORM_STATE,
        role: (roles[0] && roles[0].value) ? roles[0].value : null,
        external: isCustomerUser,
      },
      roles,
      types,
    };

    this.refs = {};
    this.promises = {};

    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { roles } = this.props;
    if (!isObjectsEqual(roles, prevProps.roles)) {
      const rolesList = prepareRoleList(roles || []);
      this.setState(state => ({
        form: {
          ...state.form,
          role: (rolesList[0] && rolesList[0].value) ? rolesList[0].value : null,
        },
        roles,
      }));
    }
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  handleTypeSelect = ({ value }) => {
    this.setState(state => ({
      form: {
        ...state.form,
        external: value,
      },
    }));
  };

  submit(values, resetCallback) {
    if (!this.state.loading && this.validate()) {
      const userData = { ...this.state.form };
      userData.phoneNumber = formatPhoneForRequest(this.state.form.phoneNumber);

      // we never want to put an admin user into pending-review mode
      if (userData.role === USER_ROLES.ADMIN) {
        userData.reviewRequired = false;
      }

      // conditional when phone is empty
      if (/^(""|''|)$/.test(userData.mobile) || !userData.mobile) {
        userData.mobile = null;
      }

      // conditional when ringCentralPhone is empty
      if (/^(""|''|)$/.test(userData.ringCentralPhone) || !userData.ringCentralPhone) {
        userData.ringCentralPhone = null;
      }

      // conditional when zoomPhone is empty
      if (/^(""|''|)$/.test(userData.zoomPhone) || !userData.zoomPhone) {
        userData.zoomPhone = null;
      }

      this.setState({
        loading: true,
        errorText: '',
      });

      const { showNotification, reloadData } = this.props;

      const promiseName = 'inviteUser';
      const inviteUserRequest = inviteUser(userData);
      const inviteUserPromise = inviteUserRequest.promise;
      this.promises[promiseName] = inviteUserRequest;

      inviteUserPromise.then(() => {
        delete this.promises[promiseName];

        showNotification({
          message: 'User has been successfully created.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.SUCCESS,
        });
        if (resetCallback) resetCallback();
        reloadData();
        this.setState({
          loading: false,
        }, this.handleCloseModal);
      }).catch((error) => {
        if (error.isCanceled) {
          return;
        }

        delete this.promises[promiseName];
        if (error && error.data && error.data.errors) {
          Object.keys(error.data.errors).forEach((key) => {
            if (this.refs[key]) {
              this.refs[key].setValidation(error.data.errors[key]);
            }
          });
        }
        this.setState({
          loading: false,
          errorText: `${error.data.message}`,
          errors: error.data.errors,
        });
      });
    }
  }

  validate() {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      result = this.refs[key].validate() && result;
    });

    return result;
  }

  openDialog() {
    this.setState({
      modalOpened: true,
    });
  }

  handleCloseModal() {
    if (this.state.loading) {
      return;
    }
    const { currentUser: { role } = {} } = this.props;
    const isCustomerUser = role === USER_ROLES.CUSTOMER;
    this.setState(state => ({
      modalOpened: false,
      errorText: '',
      form: {
        ...INITIAL_FORM_STATE,
        role: state.roles[0].value,
        external: isCustomerUser,
      },
    }));
  }

  handleSelect(role) {
    if (role && role.value) {
      this.setState(state => ({
        form: {
          ...state.form,
          role: role.value,
        },
      }));
    }
  }

  render() {
    const { roles, types, showPassword } = this.state;
    const { currentUser: { isExternal: isExternalUser = true, role } = {} } = this.props;
    const isInternalAdmin = !isExternalUser
      && (isExternalUser !== null) && (role === USER_ROLES.ADMIN);

    if (!roles) {
      return null;
    }

    const { errorText, errors, form } = this.state;

    let loading;
    if (this.state.loading) {
      loading = <LoadingBlock />;
    }

    let errorBlock;
    if (errorText && errorText.length) {
      let errorsBlock;
      if (errors && !isObjectEmpty(errors)) {
        errorsBlock = (
          <ul>
            {Object.keys(errors).map(key => <li key={`error_${key}`}>{`${key}: ${errors[key]}`}</li>)}
          </ul>
        );
      }
      errorBlock = (
        <div className="error-hint text-ccm-red border border-ccm-red">
          {errorText}
          {errorsBlock}
        </div>
      );
    }
    const rolesToValidate = roles && roles.length > 0 ? roles.map(r => r.value) : [];

    const setShowPassword = () => {
      this.setState({
        showPassword: !showPassword,
      });
    };

    return (
      <div className="flex-container">
        <Button
          data-test="inviteUser_addUserBtn"
          variant="light"
          onClick={this.openDialog}
        >
          Add User
        </Button>
        <Modal
          isOpen={this.state.modalOpened}
          style={DIALOG_STYLES}
          onRequestClose={this.handleCloseModal}
          contentLabel="Change Patient Assignment"
        >
          <div className="simple-dialog xl-dialog">
            <div className="dialog-title">
              Add user
              <div
                data-test="inviteUser_closeBtn"
                className="close-icon i-close"
                onClick={this.handleCloseModal}
              />
            </div>
            <Formik
              initialValues={{
                firstName: form.firstName || '',
                lastName: form.lastName || '',
                startDate: form.startDate || '',
                username: form.username || '',
                password: form.password || '',
                'role-select': form.role || '',
                'type-select': form.external,
                reviewRequired: false,
                mobile: form.mobile || '',
                ringCentralPhone: form.ringCentralPhone || '',
                zoomPhone: form.zoomPhone || '',
                email: form.email || '',
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required('Required').max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH)
                  .strict(true)
                  .trim('The First Name cannot include leading or trailing spaces.'),
                lastName: Yup.string().required('Required').max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH)
                  .strict(true)
                  .trim('The Last Name cannot include leading or trailing spaces.'),
                startDate: Yup.date().typeError('Must be a valid date')
                  .when(['type-select', 'role-select'],
                    (typeSelect, roleSelect) => {
                      if (!typeSelect && roleSelect !== USER_ROLES.ADMIN) return Yup.date().required('Required');
                      return Yup.date().nullable(true);
                    }),
                email: Yup.string().required('Required').email('Invalid email').max(DEFAULT_FIELDS_LENGTH.DEFAULT_SECOND_LENGTH),
                mobile: Yup.string().test('Length',
                  'must contain 10 digits',
                  (value) => {
                    const valueFormatted = formatPhone(value);
                    return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
                  }),
                ringCentralPhone: Yup.string().test('Length',
                  'must contain 10 digits',
                  (value) => {
                    const valueFormatted = formatPhone(value);
                    return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
                  }),
                zoomPhone: Yup.string().test('Length',
                  'must contain 10 digits',
                  (value) => {
                    const valueFormatted = formatPhone(value);
                    return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
                  }),
                username: Yup.string().required('Required').max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH),
                password: Yup.string().max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH)
                  .required('Required')
                  .min(6, 'Password is too short - should be 6 chars minimum.')
                  .matches(PASSWORD_REGEX_LOWERCASE, 'Requires lowercase letters')
                  .matches(PASSWORD_REGEX_UPPERCASE, 'Requires uppercase letters')
                  .matches(PASSWORD_REGEX_NUMBERS, 'Requires numbers')
                  .matches(PASSWORD_REGEX_CHARACTERS, 'Requires special characters'),
                'role-select': Yup.string().typeError('Please select a value from the list').required('Required').oneOf(rolesToValidate),
                'type-select': Yup.boolean().typeError('Please select a value from the list').required('Required'),
              })}
              onSubmit={(values, { resetForm }) => {
                const formattedDate = values.startDate
                  ? moment(values.startDate).format(DATE_FORMAT.FULL) : values.startDate;
                const mobileDigits = formatPhoneOnlyDigits(values.mobile);
                const ringCentralDigits = formatPhoneOnlyDigits(values.ringCentralPhone);
                const zoomPhoneDigits = formatPhoneOnlyDigits(values.zoomPhone);
                const mobileValue = mobileDigits && mobileDigits.length === DIGITAL_PHONE_LENGTH
                  ? formatPhoneForRequest(values.mobile) : values.mobile;
                const ringCentralPhoneValue = ringCentralDigits
                  && ringCentralDigits.length === DIGITAL_PHONE_LENGTH
                  ? formatPhoneForRequest(values.ringCentralPhone) : values.ringCentralPhone;
                const zoomPhoneValue = zoomPhoneDigits
                  && zoomPhoneDigits.length === DIGITAL_PHONE_LENGTH
                  ? formatPhoneForRequest(values.zoomPhone) : values.zoomPhone;
                this.setState(() => ({
                  form: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    startDate: formattedDate,
                    username: values.username,
                    password: values.password,
                    role: values['role-select'],
                    external: values['type-select'],
                    reviewRequired: values.reviewRequired,
                    mobile: mobileValue,
                    ringCentralPhone: ringCentralPhoneValue,
                    zoomPhone: zoomPhoneValue,
                    email: values.email,
                  },
                }), () => this.submit(values, resetForm));
              }}
              data-test="inviteUser_formikComponent"
            >
              {formik => (
                <Form>
                  <div className="dialog-content text-left">
                    {loading}
                    {errorBlock}
                    <Row>
                      <div className="col-4">
                        <TextInput
                          label="User ID"
                          name="username"
                          subtext={HINT_TEXT}
                        />
                      </div>
                      <div className="col-4 d-flex">
                        <TextInput
                          styles={{ formGroup: 'w-100' }}
                          label="Password"
                          name="password"
                          type={showPassword ? 'text' : 'password'}
                          autoComplete="current-password"
                          data-test="inviteUser_currentPassword"
                        />
                        <Button
                          variant="link-dark"
                          className="px-1 align-self-center"
                          onClick={() => setShowPassword()}
                          data-test="inviteUser_showPassword"
                        >
                          <i className={showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'} aria-hidden="true" />
                        </Button>
                      </div>
                      <div className="col-4 align-self-center">
                        <Button
                          variant="primary"
                          className="ml-2"
                          onClick={() => {
                            const password = autoGeneratePassword();
                            copyToClipboard(password);
                            formik.setFieldValue('password', password);
                          }}
                          data-test="inviteUser_generatePassword"
                        >
                          Auto-Generate Password
                        </Button>
                        <Form.Text muted>Creates new password and copies to clipboard</Form.Text>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-4">
                        <TextInput
                          label="First name"
                          name="firstName"
                        />
                      </div>
                      <div className="col-4">
                        <TextInput
                          label="Last name"
                          name="lastName"
                        />
                      </div>
                      <div className="col-4">
                        <Datepicker
                          label="Start date"
                          name="startDate"
                          subtext="SelectPatient Management start date"
                          autoComplete="off"
                          todayButton="Today"
                        />
                      </div>
                    </Row>
                    <Row className="align-items-center">
                      <div className="col-4">
                        <TextInput
                          label="Email"
                          name="email"
                        />
                      </div>
                      <div className="col-4">
                        <TextInput
                          label="Cell Phone"
                          name="mobile"
                          maxLength="14"
                          value={(
                            formik.values.mobile
                            && formatPhone(formik.values.mobile)
                          )}
                        />
                      </div>
                    </Row>
                    <Row className="align-items-center">
                      <div className="col-4">
                        <TextInput
                          label="Ring Central Phone Number"
                          name="ringCentralPhone"
                          maxLength="14"
                          value={(
                            formik.values.ringCentralPhone
                            && formatPhone(formik.values.ringCentralPhone)
                          )}
                        />
                      </div>
                      <div className="col-4">
                        <TextInput
                          label="Zoom Phone Number"
                          name="zoomPhone"
                          maxLength="14"
                          value={(
                            formik.values.zoomPhone
                            && formatPhone(formik.values.zoomPhone)
                          )}
                        />
                      </div>
                    </Row>
                    <Row className="align-items-end" data-test="inviteUser_lastRow">
                      <div className="col-4" data-test="inviteUser_userRole">
                        <Select
                          label="User role"
                          name="role-select"
                          placeholder="Role"
                          options={roles}
                          searchable={false}
                          onChange={(event) => {
                            formik.setFieldValue('role-select', event.value);
                            if (event.value === USER_ROLES.CUSTOMER) {
                              formik.setFieldValue('type-select', true);
                            } else {
                              formik.setFieldValue('type-select', false);
                            }
                          }}
                        />
                      </div>
                      <div className="col-4" data-test="inviteUser_userType">
                        {isInternalAdmin && (
                          <Select
                            label="User type"
                            name="type-select"
                            placeholder="Type"
                            options={types}
                            searchable={false}
                          />
                        )}
                      </div>
                      {!(formik.values['role-select'] === USER_ROLES.ADMIN) && (
                        <div className="col-4">
                          <Checkbox
                            label="Require Review"
                            name="reviewRequired"
                            type="switch"
                          />
                        </div>
                      )}
                    </Row>
                  </div>
                  <div className="dialog-buttons justify-content-end px-4 mt-1 mb-3">
                    <Button
                      variant="primary"
                      className="ml-2"
                      onClick={() => formik.handleSubmit()}
                      disabled={!formik.isValid}
                      data-test="inviteUser_createBtn"
                    >
                      Create account
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    roles: state.roles,
    currentUser: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteUser);
