// libraries
import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// Components
import {
  Container, Row, Table, Button,
} from 'react-bootstrap';
import { Checkbox } from '../base/forms/Checkbox';
import { TextInput } from '../base/forms/TextInput';
// Actions
import ShowNotification from '../../actions/notification';
import { SetPatientsGenerated } from '../../actions/patientsGenerated';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { saveTestPatient } from '../../services/administration';
// Data Tests
import { PATIENT_LIST } from '../../__e2e-tests__/data/patientList';
import maleNames from '../../__e2e-tests__/data/maleNames.json';
import femaleNames from '../../__e2e-tests__/data/femaleNames.json';
import lastNames from '../../__e2e-tests__/data/lastNames.json';

const TENANT_ROOT_KEYWORD = '--tenant_root--';
const TENANT_NAME_KEYWORD = '--tenant_name--';
const DEFAULT_DEMO_SETTINGS = { specificName: false, firstName: '', lastName: '' };
const FIELD_STYLES = { formLabel: 'float-left' };

export const demoSettingsSchema = () => Yup.object({
  specificName: Yup.boolean(),
  firstName: Yup.string().when('specificName', {
    is: true,
    then: Yup.string().required('Required').max(40, 'Max 40 characters allowed.'),
  }),
  lastName: Yup.string().when('specificName', {
    is: true,
    then: Yup.string().required('Required').max(40, 'Max 40 characters allowed.'),
  }),
});

export function DemoSettings(props) {
  const { patientsGenerated, isDemoEnv = false } = props;
  const [patientList, setPatientList] = useState(patientsGenerated || []);

  const generateRandomName = (sexIndex) => {
    const nameIndex = Math.floor(Math.random() * (femaleNames.length + 1));
    const lastNameIndex = Math.floor(Math.random() * (maleNames.length + 1));
    return [{
      given: [sexIndex === 0 ? femaleNames[nameIndex] : maleNames[nameIndex], 'Test'],
      family: `Test${lastNames[lastNameIndex]}`,
    }];
  };

  const generateSpecificName = values => [{
    given: [values.firstName],
    family: values.lastName,
  }];

  const generatePatient = async (values) => {
    const { showNotification, setPatientsGenerated } = props;
    if (isDemoEnv) {
      const patientId = Date.now();
      const sexIndex = Math.floor(Math.random() * 2);
      const patientIndex = Math.floor(Math.random() * 5);
      const patientTestData = PATIENT_LIST[patientIndex];

      patientTestData.document.demographics.names = values.specificName
        ? generateSpecificName(values)
        : generateRandomName(sexIndex);
      patientTestData.document.demographics.ids = [{
        root: TENANT_ROOT_KEYWORD, extension: patientId,
      }];
      patientTestData.document.demographics.administrativeGenderCode = sexIndex === 0 ? 'F' : 'M';
      patientTestData.tenantName = TENANT_NAME_KEYWORD;

      const fetchRequest = saveTestPatient(patientTestData);
      const fetchPromise = fetchRequest.promise;

      return fetchPromise.then(() => {
        const { demographics } = patientTestData.document;
        const newPatient = {
          mrn: demographics.ids[0].extension,
          firstName: demographics.names[0].given[0],
          lastName: demographics.names[0].family,
          dob: moment(demographics.birthTime).format(DATE_FORMAT.FULL),
          sex: demographics.administrativeGenderCode,
        };

        setPatientsGenerated([...patientList, newPatient]);
        showNotification({
          message: `The patient with MRN ${patientId} will be created in approximately 1 minute or less.`,
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.SUCCESS,
        });
      }).catch((error) => {
        delete fetchPromise.promise;

        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }
        showNotification({
          message: 'An error has occurred while attempting to generate this test patient.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
    }
    return showNotification({
      message: 'Test patient generation is not enabled for this system.',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
  };

  const resetValues = ({ values, resetForm }) => {
    if (values.specificName) resetForm();
  };

  useEffect(() => {
    setPatientList(patientsGenerated);
  }, [patientsGenerated]);

  return (
    <div className="demo-settings h-100 overflow-auto px-4">
      <Container fluid>
        <Row className="d-flex align-items-center mb-3">
          <h4 className="text-left my-3">Demo Settings</h4>
        </Row>
        <Row>
          <Formik
            initialValues={DEFAULT_DEMO_SETTINGS}
            validationSchema={demoSettingsSchema()}
            onSubmit={values => generatePatient(values)}
            data-test="demoSettings_formikComponent"
          >
            {formik => (
              <Form className="w-100">
                <div className="d-flex align-items-start justify-content-between">
                  <Checkbox
                    type="checkbox"
                    label="Specific Name"
                    name="specificName"
                    onClick={() => resetValues(formik)}
                    data-test="demoSettings_checkbox"
                  />
                  {formik.values.specificName && (
                    <Fragment>
                      <TextInput
                        type="text"
                        label="First Name"
                        name="firstName"
                        maxLength={40}
                        styles={FIELD_STYLES}
                      />
                      <TextInput
                        type="text"
                        label="Last Name"
                        name="lastName"
                        maxLength={40}
                        styles={FIELD_STYLES}
                      />
                    </Fragment>
                  )}
                  <Button
                    size="sm"
                    variant="success"
                    disabled={!formik.isValid}
                    onClick={() => formik.handleSubmit()}
                    data-test="demoSettings_generateButton"
                  >
                    Generate Test Patients
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Row>
        <Row className="ccm-table-container">
          <Table className="text-left w-100 ">
            <thead>
              <tr>
                <th>Mrn</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Dob</th>
                <th>Sex</th>
              </tr>
            </thead>
            <tbody>
              {(patientsGenerated && patientsGenerated.length > 0) ? (
                patientsGenerated.map(item => (
                  <tr key={`generatedPatient__${item.mrn}`}>
                    <td>{item.mrn}</td>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.dob}</td>
                    <td>{item.sex}</td>
                  </tr>
                ))
              ) : (
                <tr data-test="demoSettings_emptyMsg">
                  <td colSpan="5" className="p-2 border-0">
                    No test patients have been generated!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Row>
      </Container>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    patientsGenerated: state.patientsGenerated,
    isDemoEnv: state.tenant && state.tenant.isDemoEnv,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    setPatientsGenerated: patientListData => dispatch(SetPatientsGenerated(patientListData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoSettings);
