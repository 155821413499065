// Libraries
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Accordion, Card, Button } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
// Actions
import ShowNotification from '../../../../actions/notification';
// Services
import { deleteCareTeam } from '../../../../services/patient';
// Custom Hooks
import useConfirm from '../../../../hooks/useConfirm';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../../../constants/constants';
import { CATEGORY_CODES } from '../../../../constants/careTeam';


export const CareTeamRow = (props) => {
  const {
    refetch, careTeamList, collapseAll, handleCareTeam,
  } = props;

  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();
  const { id: patientId } = useParams();

  const showNotification = data => dispatch(ShowNotification(data));

  const phoneFormat = phone => phone && phone.substring(1).replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1)-$2-$3');

  const notEmptyAddressInfo = ({
    streetAddress, city, state, zipcode,
  }) => (!!streetAddress || !!city || !!state || !!zipcode);

  const removeCareTeam = async (careTeamId) => {
    const deleteCareTeamRequest = deleteCareTeam(patientId, careTeamId);
    const deleteCareTeamPromise = deleteCareTeamRequest.promise;

    try {
      await deleteCareTeamPromise;
      delete deleteCareTeamRequest.promise;
      refetch();
      showNotification({
        message: 'Care Team deleted.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    } catch (error) {
      delete deleteCareTeamRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to delete this Care Team.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const confirmRemoval = async (careTeamId) => {
    const confirmed = await isConfirmed('Are you sure you want to delete this Care Team contact?');
    if (confirmed) await removeCareTeam(careTeamId);
  };

  if (careTeamList.length > 0) {
    return careTeamList.map((careTeam, index) => (
      <tr key={`careTeam_row_${index}-${careTeam.id}}`} data-test="careTeamRow_entryRow">
        <td colSpan="5" className="p-0">
          <Accordion>
            <Card className="border-0 rounded-0">
              <Card.Header className="bg-light border-0 p-0">
                <table className="table mb-0">
                  <tbody className="table-body">
                    <tr>
                      <td>{careTeam.name}</td>
                      <td>{careTeam.category}</td>
                      <td>{careTeam.type}</td>
                      <td>{phoneFormat(careTeam.phoneNumber)}</td>
                      <td className="text-right">
                        <Button
                          variant="link-dark"
                          className="p-0 pl-2"
                          data-test="careTeamRow_editBtn"
                          onClick={() => handleCareTeam(careTeam)}
                        >
                          <i className="bi-pencil" />
                        </Button>
                        <CustomToggle eventKey="1" collapseAll={collapseAll} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="px-3 pt-1">
                  {careTeam.note && (
                    <div className="row font-weight-light">
                      <div className="col">
                        {careTeam.note}
                      </div>
                    </div>
                  )}
                  <div className="row pt-2">
                    {notEmptyAddressInfo(careTeam) && (
                      <div className="col-3">
                        <div className="row no-gutters">
                          <div className="col-1 mr-2"><i className="bi bi-geo-alt" /></div>
                          <div className="col">
                            {`${careTeam.streetAddress || ''} ${`${careTeam.city}, ` || ''} ${careTeam.state || ''} ${careTeam.zipcode || ''}`}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-3">
                      <div className="row no-gutters">
                        <div className="col-1 mr-2">
                          <i className={`bi bi-${careTeam.isInEhr ? 'check' : 'x'}-lg text-ccm-${careTeam.isInEhr ? 'green' : 'red'}`} />
                        </div>
                        <div className="col">
                          <div className="row no-gutters">{`${careTeam.isInEhr ? '' : 'Not'} Listed on EHR`}</div>
                          {careTeam.hipaaConsentType
                            && careTeam.category === CATEGORY_CODES.hipaaAuthorized.value && (
                            <div className="row no-gutters mt-2">
                              {`Consent: ${careTeam.hipaaConsentType.charAt(0) + careTeam.hipaaConsentType.slice(1).toLowerCase()}`}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row no-gutters">
                        <div className="col-2 text-capitalize">history</div>
                        <div className="col">
                          <div className="row no-gutters">
                            {`Added ${moment(careTeam.createdAt, DATE_FORMAT.FULL_SERVER_WITH_TIME).format(DATE_FORMAT.SHORT)} by ${careTeam.createdByFullname}`}
                          </div>
                          {careTeam.updatedBy && (
                            <div className="row no-gutters">
                              {`Updated ${moment(careTeam.updatedAt, DATE_FORMAT.FULL_SERVER_WITH_TIME).format(DATE_FORMAT.SHORT)} by ${careTeam.updatedByFullname}`}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end pt-2">
                    <Button
                      size="sm"
                      variant="danger"
                      className="text-capitalize"
                      onClick={() => confirmRemoval(careTeam.id)}
                      data-test="careTeamRow_deleteBtn"
                    >
                      delete
                    </Button>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </td>
      </tr>
    ));
  }

  return <tr><td colSpan="5" className="border-0">This patient has no care team members.</td></tr>;
};

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0 pl-4" onClick={decoratedOnClick}>
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

export default CareTeamRow;
