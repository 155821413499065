// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getPatientAdditionalInfo } from '../../services/patient';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';


const useLoadPatientAdditionalInfo = ({ patientId }) => {
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchAdditionalInfo = useCallback(async () => {
    const getPatientAdditionalInfoRequest = getPatientAdditionalInfo(patientId);
    const getPatientAdditionalInfoPromise = getPatientAdditionalInfoRequest.promise;
    try {
      const data = await getPatientAdditionalInfoPromise;
      setAdditionalInfo(data);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to load additional information.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [patientId]);

  useEffect(() => { fetchAdditionalInfo(); }, [fetchAdditionalInfo]);

  return { additionalInfo, refetch: fetchAdditionalInfo };
};

export default useLoadPatientAdditionalInfo;
