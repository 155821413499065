// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getPatientInfo, getBillingInfo } from '../../services/patient';
// Actions
import { UpdatePatient } from '../../actions/patient';

const useLoadPatientInfo = ({ patientId, loadBillingInfo = false }) => {
  const [patientInfo, setPatientInfo] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPatientInfo = useCallback(async () => {
    const getPatientInfoRequest = getPatientInfo(patientId);
    const getPatientInfoPromise = getPatientInfoRequest.promise;
    try {
      const data = await getPatientInfoPromise;
      setPatientInfo(data);
      dispatch(UpdatePatient(data));
      if (loadBillingInfo) {
        const getBillingInfoRequest = getBillingInfo(patientId);
        const getBillingInfoPromise = getBillingInfoRequest.promise;
        try {
          const billingInfo = await getBillingInfoPromise;
          dispatch(UpdatePatient({ billing: { ...data.billing, billingInfo } }));
        } catch (error) {
          if (error.isCanceled || error.status === 401 || error.status === 403) {
            return;
          }
          showNotification({
            message: 'An unexpected error has occurred while trying to load patient info.',
            autoHide: true,
            notificationType: NOTIFICATION_TYPE.ERROR,
          });
        }
      }
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patient info.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [patientId]);

  useEffect(() => {
    fetchPatientInfo();
  }, [fetchPatientInfo]);

  return { patientInfo, refetch: fetchPatientInfo };
};

export default useLoadPatientInfo;
