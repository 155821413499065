import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Formik } from 'formik';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Form } from 'react-bootstrap';
import _intersectionBy from 'lodash/intersectionBy';
// Services
import { createPrompt, updatePrompt } from '../../../services/administration';
import { getCategories, getSubcategories, fetchData } from '../../../services/helpers';
import { getCcmConditions, getTelehealthProviders } from '../../../services/providers';
// Actions
import ShowNotification from '../../../actions/notification';
// Constants
import { NOTIFICATION_TYPE, DIALOG_STYLES, TENANT_FEATURES_NAMES } from '../../../constants/constants';
import { PROMPT_MODAL } from '../../../constants/forms/schemas/administrationSchemas';
import {
  DEFAULT_CONF_VALUES, PROMPT_CATEGORY, DATE_TYPE_MONTHS, PROMPT_MONTHS,
  PROMPT_ONLY, HF_REPEATED, PROMPT_CONFIRMATION, DATE_TYPE_RANGE, PROMPT_TENURE_OPTS,
  CONFIRMATION_VALUES, PROMPT_EVERY, PROMPT_APPT_DATE, PROMPT_APPT_DATE_CODES, PROMPT_GENDER_OPTS,
} from '../../../constants/administration';
import { INTERVENTION_SUBCATEGORIES } from '../../../constants/interventions';
// Components
import { Select } from '../../base/forms/Select';
import { TextArea } from '../../base/forms/TextArea';
import { TextInput } from '../../base/forms/TextInput';
import { Checkbox } from '../../base/forms/Checkbox';
import { Datepicker } from '../../base/forms/Datepicker';
import { IntegerInput } from '../../base/forms/IntegerInput';

const FIELD_STYLES = {
  formGroup: 'd-flex align-items-center m-0',
  formLabel: 'text-left mr-3 mb-0 simple-form-label',
  formInvalid: 'form-invalid-bottom',
};

const DISABLED_STATUS = 'DISABLED';

const DEFAULT_PROMPT = {
  name: '',
  category: '',
  status: DISABLED_STATUS,
  promptText: '',
  guidanceText: '',
  confirmationType: '',
  isRequiredTopic: false,
  interventionCategory: '',
  interventionType: '',
  interventionNote: '',
  criteriaType: PROMPT_EVERY,
  dateRuleEnabled: false,
  dateType: DATE_TYPE_MONTHS,
  dateRuleMonths: [],
  dateRuleEnd: null,
  dateRuleStart: null,
  conditionRuleEnabled: false,
  conditions: [],
  sdohRuleEnabled: false,
  factors: [],
  tenureRuleEnabled: false,
  tenureRule: null,
  icd10RuleEnabled: false,
  icd10RuleRecentOnly: true,
  icd10RuleValues: '',
  zipCodeRuleEnabled: false,
  zipCodeRuleValues: '',
  apptRuleEnabled: false,
  apptRuleType: '',
  apptRuleTypeExact: false,
  apptRulePurpose: '',
  apptRulePurposeExact: false,
  apptRuleDate: PROMPT_APPT_DATE_CODES.anyDate.value,
  telehealthProviderRuleEnabled: false,
  telehealthProviderRule: [],
};

const CHECKBOX_STYLES = { formGroup: 'mb-0', formLabel: 'd-none' };

export function PromptModal(props) {
  const { isModalOpen, setIsModalOpen, initialPrompt } = props;
  const dispatch = useDispatch();
  const { administration: { healthFactors }, tenant: { tags } } = useSelector(state => state);
  const showNotification = data => dispatch(ShowNotification(data));

  const [newPrompt, setNewPrompt] = useState(initialPrompt || DEFAULT_PROMPT);
  const [ccmConditionsList, setCcmConditionsList] = useState([]);
  const [telehealthProviderList, setTelehealthProviderList] = useState([]);

  const { tenant: { features } } = useSelector(state => state);

  const telehealthProviderFeature = features && features.length > 0
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_MULTI_TELEHEALTH.name);
  const showTelehealthProviders = telehealthProviderFeature && telehealthProviderFeature.enabled;

  const handleCcmAsyncList = async () => {
    if (ccmConditionsList.length === 0) {
      const conditionsData = await fetchData(getCcmConditions());
      setCcmConditionsList(conditionsData);
      return conditionsData;
    }
    return ccmConditionsList;
  };

  const handleTelehealthProvidersAsyncList = async () => {
    if (telehealthProviderList.length === 0) {
      const telehalthProviderData = await fetchData(getTelehealthProviders());
      setTelehealthProviderList(telehalthProviderData);
      return telehalthProviderData;
    }
    return telehealthProviderList;
  };

  const handleHealthFactorsList = () => {
    const factorsMapped = healthFactors.map((f) => {
      const name = HF_REPEATED.includes(f.displayName) ? `${f.displayName} (${f.category})` : `${f.displayName}`;
      return { id: f.id, name };
    });
    return factorsMapped;
  };

  const handleTags = () => {
    const tagsMapped = tags && tags.filter(t => !t.deleted)
      .map(t => ({ value: t.id, label: t.displayName }));
    return tagsMapped;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const savePrompt = (prompt) => {
    const isNewPrompt = !prompt.id;
    let addPromptRequest;

    addPromptRequest = isNewPrompt
      ? addPromptRequest = createPrompt(prompt) : addPromptRequest = updatePrompt(prompt);

    const addPromptPromise = addPromptRequest.promise;

    return addPromptPromise.then(() => {
      delete addPromptPromise.promise;
      if (props.callbackAction) props.callbackAction();
      handleCloseModal();
    }).catch((error) => {
      delete addPromptPromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this prompt',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getFormConfirmationType = (confirmationType, isRequiredTopic) => {
    if (confirmationType && confirmationType.includes(DEFAULT_CONF_VALUES.INTERVENTION)) {
      return isRequiredTopic
        ? CONFIRMATION_VALUES.INTERVENTION : CONFIRMATION_VALUES.INTERVENTION_FALSE;
    }
    if (confirmationType && confirmationType.includes(DEFAULT_CONF_VALUES.ONLY)) {
      return isRequiredTopic ? CONFIRMATION_VALUES.ONLY : CONFIRMATION_VALUES.ONLY_FALSE;
    }
    return '';
  };

  const getFactorsFormatted = (factors) => {
    const factorsUpdated = _intersectionBy(healthFactors, factors, 'id');
    const factorsFormatted = factorsUpdated.map((c) => {
      const name = HF_REPEATED.includes(c.displayName) ? `${c.displayName} (${c.category})` : `${c.displayName}`;
      return { id: c.id, name };
    });
    return factorsFormatted;
  };

  useEffect(() => {
    if (initialPrompt) {
      setNewPrompt({
        id: initialPrompt.id,
        name: initialPrompt.name || '',
        category: initialPrompt.category || '',
        status: initialPrompt.status || '',
        promptText: initialPrompt.promptText || '',
        guidanceText: initialPrompt.guidanceText || '',
        confirmationType: getFormConfirmationType(
          initialPrompt.confirmationType, initialPrompt.isRequiredTopic,
        ),
        isRequiredTopic: initialPrompt.isRequiredTopic || false,
        interventionCategory: initialPrompt.interventionCategory || '',
        interventionType: initialPrompt.interventionType || '',
        interventionNote: initialPrompt.interventionNote || '',
        criteriaType: initialPrompt.criteriaType || PROMPT_EVERY,
        dateRuleEnabled: initialPrompt.dateRuleEnabled || false,
        dateType: initialPrompt.dateRuleMonths.length ? DATE_TYPE_MONTHS : DATE_TYPE_RANGE,
        dateRuleMonths: initialPrompt.dateRuleMonths || [],
        dateRuleEnd: initialPrompt.dateRuleEnd ? moment(initialPrompt.dateRuleEnd) : null,
        dateRuleStart: initialPrompt.dateRuleStart ? moment(initialPrompt.dateRuleStart) : null,
        conditionRuleEnabled: initialPrompt.conditionRuleEnabled || false,
        conditions: initialPrompt.conditions || [],
        sdohRuleEnabled: initialPrompt.sdohRuleEnabled || false,
        factors: initialPrompt.factors ? getFactorsFormatted(initialPrompt.factors) : [],
        tagRuleEnabled: initialPrompt.tagRuleEnabled || false,
        tag: initialPrompt.tag ? initialPrompt.tag.id : null,
        tenureRuleEnabled: initialPrompt.tenureRuleEnabled || false,
        tenureRule: initialPrompt.tenureRule || null,
        icd10RuleEnabled: initialPrompt.icd10RuleEnabled || false,
        icd10RuleRecentOnly: initialPrompt.icd10RuleRecentOnly || false,
        icd10RuleValues: (initialPrompt.icd10RuleValues && initialPrompt.icd10RuleValues.join(', ')) || '',
        zipCodeRuleEnabled: initialPrompt.zipCodeRuleEnabled || false,
        zipCodeRuleValues: (initialPrompt.zipCodeRuleValues && initialPrompt.zipCodeRuleValues.join(', ')) || '',
        apptRuleEnabled: initialPrompt.apptRuleEnabled || false,
        apptRuleType: initialPrompt.apptRuleType || '',
        apptRuleTypeExact: initialPrompt.apptRuleTypeExact || false,
        apptRulePurpose: initialPrompt.apptRulePurpose || '',
        apptRulePurposeExact: initialPrompt.apptRulePurposeExact || false,
        apptRuleDate: initialPrompt.apptRuleDate || PROMPT_APPT_DATE_CODES.anyDate.value,
        surveyRuleEnabled: initialPrompt.surveyRuleEnabled || false,
        genderRuleEnabled: initialPrompt.genderRuleEnabled || false,
        genderRule: initialPrompt.genderRule || '',
        ageRuleEnabled: initialPrompt.ageRuleEnabled || false,
        ageRuleLessThan: initialPrompt.ageRuleLessThan || null,
        ageRuleGreaterThan: initialPrompt.ageRuleGreaterThan || null,
        telehealthProviderRuleEnabled: initialPrompt.telehealthProviderRuleEnabled || false,
        telehealthProviderRule: initialPrompt.telehealthProviderRule || [],
      });
    } else {
      setNewPrompt(DEFAULT_PROMPT);
    }
  }, [initialPrompt]);

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Prompt Modal"
      shouldCloseOnOverlayClick={false}
      data-test="promptModal_modal"
    >
      <div className="simple-dialog xxl-dialog">
        <div className="dialog-title">
          {`${initialPrompt ? 'Edit' : 'Add'} Prompt`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="promptModal_modalCloseButton"
          />
        </div>
        <Formik
          initialValues={newPrompt}
          validationSchema={PROMPT_MODAL}
          onSubmit={(values, { resetForm }) => {
            const interventionCategory = values.interventionCategory
              ? values.interventionCategory : null;
            const icd10RuleValues = values.icd10RuleValues ? values.icd10RuleValues.split(',').map(val => val.trim()) : [];
            const zipCodeRuleValues = values.zipCodeRuleValues ? values.zipCodeRuleValues.split(',').map(val => val.trim()) : [];
            const type = values.confirmationType.includes(DEFAULT_CONF_VALUES.INTERVENTION)
              ? DEFAULT_CONF_VALUES.INTERVENTION : DEFAULT_CONF_VALUES.ONLY;
            const tag = values.tag ? { id: values.tag } : {};
            const apptRuleType = !values.apptRuleEnabled
              ? DEFAULT_PROMPT.apptRuleType : values.apptRuleType;
            const apptRuleTypeExact = !values.apptRuleEnabled
              ? DEFAULT_PROMPT.apptRuleTypeExact : values.apptRuleTypeExact;
            const apptRulePurpose = !values.apptRuleEnabled
              ? DEFAULT_PROMPT.apptRulePurpose : values.apptRulePurpose;
            const apptRulePurposeExact = !values.apptRuleEnabled
              ? DEFAULT_PROMPT.apptRulePurposeExact : values.apptRulePurposeExact;
            const apptRuleDate = !values.apptRuleEnabled
              ? DEFAULT_PROMPT.apptRuleDate : values.apptRuleDate;
            const updatedValues = {
              ...values,
              confirmationType: type,
              icd10RuleValues,
              zipCodeRuleValues,
              interventionCategory,
              tag,
              apptRuleType,
              apptRuleTypeExact,
              apptRulePurpose,
              apptRulePurposeExact,
              apptRuleDate,
            };
            savePrompt(updatedValues);
            resetForm();
          }}
          data-test="promptModal_formikComponent"
        >
          {({
            values, setValues, handleSubmit, isValid, errors,
          }) => (
            <Form>
              <div className="text-left dialog-content">
                <Row className="align-items-center mb-4">
                  <div className="col-4">
                    <TextInput
                      styles={FIELD_STYLES}
                      label="Name"
                      name="name"
                      data-test="promptModal_name"
                    />
                  </div>
                  <div className="col-3" data-test="promptModal_category">
                    <Select
                      styles={FIELD_STYLES}
                      label="Category"
                      name="category"
                      options={PROMPT_CATEGORY}
                    />
                  </div>
                  <div className="col-2 d-flex justify-content-between text-right" data-test="promptModal_enabled">
                    <Checkbox
                      id="statusEnabledRadio"
                      label={<span className="simple-form-label">Enabled</span>}
                      name="status"
                      styles={CHECKBOX_STYLES}
                      data-test="promptModal_enabledCheckbox"
                      type="radio"
                      value="ENABLED"
                    />
                    <Checkbox
                      id="statusDisabledRadio"
                      label={<span className="simple-form-label">Disabled</span>}
                      name="status"
                      styles={CHECKBOX_STYLES}
                      data-test="promptModal_enabledCheckbox"
                      type="radio"
                      value={DISABLED_STATUS}
                    />
                  </div>
                </Row>
                <Row className="mb-4">
                  <div className="col-6" data-test="promptModal_text">
                    <TextArea
                      rows={5}
                      name="promptText"
                      label="Prompt Text"
                      placeholder="Enter here the text to be displayed at the prompt."
                      data-test="promptModal_promptText"
                    />
                  </div>
                  <div className="col-6">
                    <TextArea
                      rows={5}
                      label="Prompt Guidance"
                      name="guidanceText"
                      placeholder="Enter here the text guidance."
                      data-test="promptModal_guidanceText"
                    />
                  </div>
                </Row>
                <Row className="align-items-center mb-4">
                  <div className="col-3 align-self-start" data-test="promptModal_status">
                    <Select
                      styles={FIELD_STYLES}
                      label="Confirmation"
                      name="confirmationType"
                      options={PROMPT_CONFIRMATION}
                      onChange={(opt) => {
                        if (opt) {
                          setValues({
                            ...values,
                            confirmationType: opt.value,
                            isRequiredTopic: opt.isRequiredTopic,
                          });
                        }
                      }}
                      data-test="promptModal_confirmationSelect"
                    />
                  </div>
                  {values && values.confirmationType
                    && values.confirmationType.includes(DEFAULT_CONF_VALUES.INTERVENTION)
                    && (
                      <div className="col-9" data-test="promptModal_targetCompletion">
                        <div className="border border-ccm-bluewood px-3">
                          <p className="my-2">Auto-generated intervention, if confirmed:</p>
                          <Row className="align-items-center mb-4">
                            <div className="col-4">
                              <Select
                                styles={FIELD_STYLES}
                                label="Category"
                                name="interventionCategory"
                                options={getCategories()}
                                onChange={(option) => {
                                  if (option) {
                                    setValues({
                                      ...values,
                                      interventionCategory: option.value,
                                      interventionType: '',
                                    });
                                  }
                                }}
                                data-test="promptModal_interventionCategorySelect"
                              />
                            </div>
                            <div className="col-4">
                              <Select
                                styles={FIELD_STYLES}
                                label="Type"
                                name="interventionType"
                                options={INTERVENTION_SUBCATEGORIES[
                                  values && values.interventionCategory
                                ]
                                  ? getSubcategories(INTERVENTION_SUBCATEGORIES[
                                    values && values.interventionCategory]) : []}
                              />
                            </div>
                          </Row>
                          <Row className="align-items-center">
                            <div className="col-12">
                              <TextArea
                                rows={2}
                                name="interventionNote"
                                label="Default Note"
                                data-test="promptModal_interventionDefaultNote"
                              />
                            </div>
                          </Row>
                        </div>
                      </div>)
                  }
                </Row>
                <Row className="align-items-center mb-4">
                  <div className="col-7 d-flex justify-content-between align-items-center">
                    <p className="my-0 mr-2 simple-form-label">Prompt Rules:</p>
                    <Checkbox
                      id="always_call"
                      label={<span className="simple-form-label">Prompt all patients on every call</span>}
                      name="criteriaType"
                      type="radio"
                      styles={{ formGroup: 'mb-0' }}
                      value={PROMPT_EVERY}
                      data-test="promptModal_criteriaTypeEvery"
                    />
                    <Checkbox
                      id="rule_based_call"
                      label={<span className="simple-form-label">Only prompt when specific criteria is met</span>}
                      name="criteriaType"
                      type="radio"
                      styles={{ formGroup: 'mb-0 mt-1' }}
                      value={PROMPT_ONLY}
                      validate
                      data-test="promptModal_criteriaTypeOnly"
                    />
                  </div>
                </Row>
                {values && values.criteriaType === PROMPT_ONLY && (
                  <Fragment>
                    <Row className="align-items-center mb-4">
                      <div className="col-3 align-self-start">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Date of MOC</span>}
                          name="dateRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_MOC"
                        />
                        <div className="ml-3" data-test="promptModal_MocRule">
                          <div className="d-flex align-items-center mb-4">
                            <Checkbox
                              id="specific_month"
                              label={<span className="simple-form-label">Specific Month(s)</span>}
                              type="radio"
                              name="dateType"
                              value={DATE_TYPE_MONTHS}
                              styles={{ formGroup: 'mb-0' }}
                              disabled={values && !values.dateRuleEnabled}
                              onChange={(value) => {
                                if (value) {
                                  setValues({
                                    ...values,
                                    dateType: DATE_TYPE_MONTHS,
                                    dateRuleStart: null,
                                    dateRuleEnd: null,
                                  });
                                }
                              }}
                              data-test="promptModal_specificMonth"
                            />
                            <div className="ml-3 flex-grow-1">
                              <Select
                                multi
                                returnValues
                                name="dateRuleMonths"
                                options={PROMPT_MONTHS}
                                disabled={values
                                  && (values.dateType !== DATE_TYPE_MONTHS
                                    || !values.dateRuleEnabled)
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <Checkbox
                              id="range_date"
                              label={<span className="simple-form-label mr-3">From</span>}
                              type="radio"
                              name="dateType"
                              value={DATE_TYPE_RANGE}
                              styles={CHECKBOX_STYLES}
                              disabled={values && !values.dateRuleEnabled}
                              onChange={(value) => {
                                if (value) {
                                  setValues({
                                    ...values,
                                    dateType: DATE_TYPE_RANGE,
                                    dateRuleMonths: [],
                                  });
                                }
                              }}
                              data-test="promptModal_fromToCheckbox"
                            />
                            <Datepicker
                              id="startDate"
                              name="dateRuleStart"
                              styles={{ formGroup: 'align-items-center m-0' }}
                              onChange={(date) => {
                                if (date) {
                                  const { dateRuleEnd } = values;
                                  setValues({
                                    ...values,
                                    dateRuleStart: date,
                                    ...(dateRuleEnd && date > dateRuleEnd && { dateRuleEnd: null }),
                                  });
                                }
                              }}
                              disabled={values
                                && (values.dateType !== DATE_TYPE_RANGE || !values.dateRuleEnabled)}
                              data-test="promptModal_fromDatepicker"
                            />
                            <span className="simple-form-label mx-2">To</span>
                            <Datepicker
                              id="endDate"
                              name="dateRuleEnd"
                              styles={{ formGroup: 'align-items-center m-0' }}
                              minDate={values.dateRuleStart}
                              disabled={values
                                && (values.dateType !== DATE_TYPE_RANGE || !values.dateRuleEnabled)}
                              data-test="promptModal_toDatepicker"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-3 align-self-start">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Chronic Condition(s)</span>}
                          name="conditionRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_CC"
                        />
                        <div className="ml-3" data-test="promptModal_chronicCondition">
                          <Select
                            multi
                            label="Condition(s)"
                            name="conditions"
                            valueKey="id"
                            labelKey="name"
                            noResultsText="CCM Condition not found..."
                            styles={FIELD_STYLES}
                            options={ccmConditionsList}
                            onFocus={handleCcmAsyncList}
                            disabled={values && !values.conditionRuleEnabled}
                            data-test="promptModal_chronicConditions"
                          />
                        </div>
                      </div>
                      <div className="col-3 align-self-start">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Health Factor(s)</span>}
                          name="sdohRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_HF"
                        />
                        <div className="ml-3" data-test="promptModal_healthFactor">
                          <Select
                            multi
                            styles={FIELD_STYLES}
                            label="Factor(s)"
                            name="factors"
                            valueKey="id"
                            labelKey="name"
                            noResultsText="Factor not found..."
                            options={handleHealthFactorsList()}
                            disabled={values && !values.sdohRuleEnabled}
                            data-test="promptModal_sDoHFactors"
                          />
                        </div>
                      </div>
                      <div className="col-3 align-self-start">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Attribute</span>}
                          name="tagRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_BOA"
                        />
                        <div className="ml-3" data-test="promptModal_attribute">
                          <Select
                            styles={FIELD_STYLES}
                            label="Attribute"
                            name="tag"
                            noResultsText="Attribute not found..."
                            options={handleTags()}
                            disabled={values && !values.tagRuleEnabled}
                            data-test="promptModal_tagRuleEnabled"
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-3">
                        <Checkbox
                          label={<span className="simple-form-label">Based on patient tenure</span>}
                          name="tenureRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_PT"
                        />
                        <div className="ml-3">
                          <Select
                            styles={FIELD_STYLES}
                            label="Tenure"
                            name="tenureRule"
                            options={PROMPT_TENURE_OPTS}
                            disabled={values && !values.tenureRuleEnabled}
                            data-test="promptModal_tenure"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <Checkbox
                          label={<span className="simple-form-label">Based on ICD10 Code(s)</span>}
                          name="icd10RuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_ICD10code"
                        />
                        <div className="ml-3">
                          <TextInput
                            styles={FIELD_STYLES}
                            label="Code(s)"
                            name="icd10RuleValues"
                            disabled={values && !values.icd10RuleEnabled}
                            data-test="promptModal_icd10code"
                          />
                          <Checkbox
                            label={<span className="simple-form-label">Only prompt on recent diagnosis (last 30 days)</span>}
                            name="icd10RuleRecentOnly"
                            styles={{ ...CHECKBOX_STYLES, formGroup: 'mb-0 mt-2' }}
                            disabled={values && !values.icd10RuleEnabled}
                            data-test="promptModal_icd10RuleRecentOnly"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Zip Code(s)</span>}
                          name="zipCodeRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_zipCode"
                        />
                        <div className="ml-3">
                          <TextInput
                            styles={{ ...FIELD_STYLES, formLabel: 'label-size-m m-0' }}
                            label={<span className="simple-form-label label-size-m">Zip Code(s)</span>}
                            name="zipCodeRuleValues"
                            disabled={values && !values.zipCodeRuleEnabled}
                            data-test="promptModal_zipCode"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Appointment</span>}
                          name="apptRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test=""
                        />
                        <div className="ml-3">
                          <div className={`row no-gutters align-items-center mb-${errors && errors.apptRuleType ? '3' : '1'}`}>
                            <div className="col mr-2">
                              <TextInput
                                styles={{ ...FIELD_STYLES, formLabel: 'label-size-m m-0', formInvalid: 'position-absolute fixed-top text-nowrap form-invalid-appt' }}
                                label={<span className="simple-form-label label-size-m">Type</span>}
                                name="apptRuleType"
                                disabled={values && !values.apptRuleEnabled}
                                data-test=""
                              />
                            </div>
                            <div className="col-4">
                              <Checkbox
                                label={<span className="simple-form-label">Exact Match</span>}
                                name="apptRuleTypeExact"
                                styles={CHECKBOX_STYLES}
                                disabled={values && !values.apptRuleEnabled}
                                data-test=""
                              />
                            </div>
                          </div>
                          <div className={`row no-gutters align-items-center mb-${errors && errors.apptRulePurpose ? '3' : '1'}`}>
                            <div className="col  mr-2">
                              <TextInput
                                styles={{ ...FIELD_STYLES, formLabel: 'label-size-m m-0', formInvalid: 'position-absolute fixed-top text-nowrap form-invalid-appt' }}
                                label={<span className="simple-form-label label-size-m">Purpose</span>}
                                name="apptRulePurpose"
                                disabled={values && !values.apptRuleEnabled}
                                data-test=""
                              />
                            </div>
                            <div className="col-4">
                              <Checkbox
                                label={<span className="simple-form-label">Exact Match</span>}
                                name="apptRulePurposeExact"
                                styles={CHECKBOX_STYLES}
                                disabled={values && !values.apptRuleEnabled}
                                data-test=""
                              />
                            </div>
                          </div>
                          <div className="row no-gutters align-items-center">
                            <div className="col-8  pr-2">
                              <Select
                                styles={FIELD_STYLES}
                                label="Date"
                                name="apptRuleDate"
                                options={PROMPT_APPT_DATE}
                                disabled={values && !values.apptRuleEnabled}
                                resetValue={PROMPT_APPT_DATE_CODES.anyDate.value}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-3">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Gender</span>}
                          name="genderRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_Gender"
                        />
                        <div className="ml-3">
                          <Select
                            styles={FIELD_STYLES}
                            label="Gender"
                            name="genderRule"
                            options={PROMPT_GENDER_OPTS}
                            disabled={values && !values.genderRuleEnabled}
                            data-test="promptModal_gender"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <Checkbox
                          label={<span className="simple-form-label">Based on Age</span>}
                          name="ageRuleEnabled"
                          styles={CHECKBOX_STYLES}
                          data-test="promptModal_Age"
                        />
                        <div className="ml-3">
                          <IntegerInput
                            styles={{ ...FIELD_STYLES, formLabel: 'label-size-m m-0' }}
                            label={<span className="simple-form-label label-size-m">&lt;=</span>}
                            name="ageRuleLessThan"
                            disabled={values && !values.ageRuleEnabled}
                            data-test="promptModal_age_lessThan"
                          />
                          <IntegerInput
                            styles={{ ...FIELD_STYLES, formLabel: 'label-size-m m-0' }}
                            label={<span className="simple-form-label label-size-m">&gt;=</span>}
                            name="ageRuleGreaterThan"
                            disabled={values && !values.ageRuleEnabled}
                            data-test="promptModal_age_greaterThan"
                          />
                        </div>
                      </div>
                      {showTelehealthProviders && (
                        <div className="col-3 align-self-start">
                          <Checkbox
                            label={<span className="simple-form-label">Based on Telehealth Group</span>}
                            name="telehealthProviderRuleEnabled"
                            styles={CHECKBOX_STYLES}
                            data-test="promptModal_TG"
                          />
                          <div className="ml-3" data-test="promptModal_telehealthProvider">
                            <Select
                              multi
                              label="Telehealth Group(s)"
                              name="telehealthProviderRule"
                              valueKey="id"
                              labelKey="code"
                              noResultsText="Telehealth Groups not found..."
                              styles={FIELD_STYLES}
                              options={telehealthProviderList}
                              onFocus={handleTelehealthProvidersAsyncList}
                              disabled={values && !values.telehealthProviderRuleEnabled}
                              data-test="promptModal_telehealthProviders"
                            />
                          </div>
                        </div>
                      )}
                    </Row>
                    {values.id && (
                      <Row>
                        <div className="col-3 align-self-start">
                          <Checkbox
                            label={<span className="simple-form-label">Based on survey rules</span>}
                            name="surveyRuleEnabled"
                            styles={CHECKBOX_STYLES}
                            data-test="promptModal_surveyRule"
                          />
                        </div>
                      </Row>
                    )}
                  </Fragment>
                )}
              </div>
              <div className="d-flex mt-3 mb-2 justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="promptModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => handleSubmit()}
                  disabled={!isValid}
                  data-test="promptModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default PromptModal;
