// Libraries
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
// Services
import { formatPhone } from '../../../services/helpers';
import {
  saveGeneralInfo, savePatientProfile, updatePatientProfile, updateNextActionDate,
} from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Views
import DropdownSelect from './DropdownSelect';
// Constants
import {
  DEFAULT_PROFILE,
  NOTIFICATION_TYPE,
  NONE_SPECIFIED_STRING,
  PATIENT_CALL_TIME_OPTIONS,
  PATIENT_OPT_IN_TEXT_OPTIONS,
  PATIENT_SCHEDULE_SENSITIVE_OPTIONS,
  PATIENT_OPT_IN_ENGOODEN_TEXT_OPTIONS,
  NEXT_OUTREACH_DATE_BLANK,
} from '../../../constants/constants';
// Hooks
import useConfirm from '../../../hooks/useConfirm';

const ENGOODEN_MSG = 'Changing SelectPatient Management text consent to no will cause the Next Outreach Date for this patient to be reset. Continue?';

export function Preferences(props) {
  const {
    patient: {
      contactInfo: {
        workPhone, homePhone, mobilePhone, preferredPhone,
      } = {},
      guardianContact = {},
      profile: {
        id: profileId, createdAt, monthCallEarly, preferredCallTime,
        textMessageConsent: engoodenTextConsent, consentToTextMessages: emrTextConsent,
      } = {},
      languageInfo, profile = {},
    } = {},
    patientId, loading, contactMethod,
  } = props;

  const [patientPreferredPhone, setPatientPreferredPhone] = useState(preferredPhone);
  const [customPreferredPhone, setCustomPreferredPhone] = useState(preferredPhone);
  const [callTime, setCallTime] = useState(preferredCallTime);
  const [optInTextEngooden, setOptInTextEngooden] = useState(engoodenTextConsent);
  const [scheduleSensitive, setScheduleSensitive] = useState(monthCallEarly);
  const [language] = useState(languageInfo);
  const [optInTextEmr, setOptInTextEmr] = useState(emrTextConsent);
  const { isConfirmed } = useConfirm();

  useEffect(() => {
    setCallTime(preferredCallTime);
    setOptInTextEngooden(engoodenTextConsent);
    setScheduleSensitive(monthCallEarly);
    setPatientPreferredPhone(preferredPhone);
    setOptInTextEmr(emrTextConsent);
  }, [monthCallEarly, preferredCallTime, engoodenTextConsent, preferredPhone, emrTextConsent]);

  const getOptInTextName = (value) => {
    const item = PATIENT_OPT_IN_TEXT_OPTIONS.find(el => el.value === value);
    return item ? item.name : NONE_SPECIFIED_STRING;
  };

  const updateContactInfo = (newValue) => {
    const { updatePatient, patient } = props;
    const patientUpdated = {
      ...patient,
      contactInfo: {
        ...patient.contactInfo,
        preferredPhone: newValue,
      },
    };

    const saveGeneralInfoRequest = saveGeneralInfo(patientUpdated, patientId);
    const saveGeneralInfoPromise = saveGeneralInfoRequest.promise;

    return saveGeneralInfoPromise.then(() => {
      delete saveGeneralInfoRequest.promise;
      updatePatient({
        contactInfo: patientUpdated.contactInfo,
        guardianContact: patientUpdated.guardianContact,
      });
    }).catch((error) => {
      delete saveGeneralInfoRequest.promise;
      const { showNotification } = props;

      if (error.isCanceled) {
        return;
      }

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not update phone number.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const updateProfileData = (key, value) => {
    const {
      patient, updatePatient, showNotification,
    } = props;
    const newProfileData = createdAt
      ? { ...patient.profile, [key]: value } : { ...DEFAULT_PROFILE, [key]: value };

    if (loading) {
      return {};
    }

    const updateProfileRequest = createdAt && profileId
      ? updatePatientProfile(patientId, newProfileData)
      : savePatientProfile(patientId, newProfileData);
    const updateProfilePromise = updateProfileRequest.promise;

    return updateProfilePromise.then((data) => {
      delete updateProfileRequest.promise;

      updatePatient({ profile: data });
    }).catch((error) => {
      delete updateProfileRequest.promise;

      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to update this patient\'s profile',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const resetNextOutreachDate = (updatedNextOutreachInfo) => {
    const { showNotification, updatePatient } = props;

    if (loading) return {};

    const updateNodRequest = updateNextActionDate(patientId, updatedNextOutreachInfo);
    const updateNodPromise = updateNodRequest.promise;

    return updateNodPromise.then(() => {
      delete updateNodPromise.promise;

      updatePatient({
        nextActionDateReminderInfo: {
          id: updatedNextOutreachInfo.id,
          reminderDate: updatedNextOutreachInfo.date,
          contactMethod: updatedNextOutreachInfo.contactMethod,
          reminderTime: updatedNextOutreachInfo.preferredCallTime,
        },
      });

      showNotification({
        message: 'Next outreach info saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete updateNodPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) return;

      showNotification({
        message: 'Could not update next outreach info.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleEngoodenValue = async (newValue) => {
    const notSame = newValue !== optInTextEngooden;
    const isNegativeValue = newValue === 'NOTEXTS' || newValue === 'NONE';
    const isText = optInTextEngooden === 'ALLTEXTS';

    if (notSame && isNegativeValue && !optInTextEmr && isText && contactMethod === 'text') {
      const confirmed = await isConfirmed(ENGOODEN_MSG, 'Notice', 'Yes', 'No');
      if (confirmed) {
        updateProfileData('textMessageConsent', newValue);
        resetNextOutreachDate(NEXT_OUTREACH_DATE_BLANK);
      }
      return;
    }
    updateProfileData('textMessageConsent', newValue);
  };

  const renderPreferences = () => {
    let contactPhones = [
      {
        name: 'Home phone',
        value: homePhone,
        profileName: 'homePhoneInvalid',
      }, {
        name: 'Mobile phone',
        value: mobilePhone,
        profileName: 'cellPhoneInvalid',
      }, {
        name: 'Work phone',
        value: workPhone,
        profileName: 'workPhoneInvalid',
      },
    ];

    contactPhones = contactPhones.filter(phone => !!phone.value && !profile[phone.profileName]);
    contactPhones.push({
      name: 'Guardian phone',
      value: guardianContact.phoneNumber,
    });

    const renderSelectPreferredPhone = (
      <DropdownSelect
        data-test="preferences_preferredPhone"
        items={contactPhones}
        hookValue={patientPreferredPhone}
        setHookValue={setPatientPreferredPhone}
        handleSaveAction={updateContactInfo}
        haveCustomPhone
        customValue={customPreferredPhone}
        setCustomValue={setCustomPreferredPhone}
        format={formatPhone}
        iconClass="bi-telephone-fill mr-2"
      />
    );

    const renderSelectCallTime = (
      <DropdownSelect
        data-test="preferences_preferredCallTime"
        items={PATIENT_CALL_TIME_OPTIONS}
        hookValue={callTime}
        setHookValue={setCallTime}
        handleSaveAction={newValue => updateProfileData('preferredCallTime', newValue)}
      />
    );

    const renderSelectScheduleSensitive = (
      <DropdownSelect
        data-test="preferences_monthCallEarly"
        items={PATIENT_SCHEDULE_SENSITIVE_OPTIONS}
        hookValue={scheduleSensitive}
        setHookValue={setScheduleSensitive}
        handleSaveAction={newValue => updateProfileData('monthCallEarly', newValue)}
      />
    );

    const renderSelectOptInText = (
      <DropdownSelect
        data-test="preferences_engoodenTextConsent"
        items={PATIENT_OPT_IN_ENGOODEN_TEXT_OPTIONS}
        hookValue={optInTextEngooden}
        setHookValue={newValue => (newValue !== optInTextEngooden ? setOptInTextEngooden : null)}
        handleSaveAction={newValue => handleEngoodenValue(newValue)}
      />
    );

    const renderSelectLanguage = (
      <span className="pr-3" data-test="preferences_language">{(language && language.displayName) || NONE_SPECIFIED_STRING}</span>
    );

    const renderOptInTextEmr = (
      <span className="pr-3" data-test="preferences_emrTextConsent">{getOptInTextName(optInTextEmr)}</span>
    );

    return (
      <Fragment>
        <div className="d-flex-center-between w-100">
          <span>Contact Phone: </span>
          {renderSelectPreferredPhone}
        </div>
        <div className="d-flex-center-between w-100">
          <span>Call Time: </span>
          {renderSelectCallTime}
        </div>
        <div className="d-flex-center-between w-100">
          <span>Schedule Sensitive: </span>
          {renderSelectScheduleSensitive}
        </div>
        <div className="d-flex-center-between w-100" data-test="preferences_engoodenText">
          <span>SelectPatient Management Texts: </span>
          {renderSelectOptInText}
        </div>
        <div className="d-flex-center-between w-100">
          <span>Opt-In Text (EMR): </span>
          {renderOptInTextEmr}
        </div>
        <div className="d-flex-center-between w-100">
          <span>Language: </span>
          {renderSelectLanguage}
        </div>
      </Fragment>
    );
  };

  return renderPreferences();
}

function mapStateToProps(state) {
  const nextAction = state.patient.nextActionDateReminderInfo || {};
  const contactMethod = nextAction && (nextAction.contactMethod || '');
  return {
    patient: state.patient,
    loading: state.requestsInProgress.count,
    contactMethod,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
