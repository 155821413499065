// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'react-bootstrap';
// actions
import { HideNotification } from '../../actions/notification';

const typeToClassMap = {
  ERROR: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export class Notification extends Component {
  constructor(props) {
    super(props);

    this.notificationTimeout = null;
  }

  componentDidUpdate() {
    const { hideNotification, autoHide } = this.props;

    if (autoHide) {
      this.notificationTimeout = setTimeout(() => {
        hideNotification();
      }, 5000);
    }
  }

  componentWillUnmount() {
    if (this.notificationTimeout) {
      clearTimeout(this.notificationTimeout);
    }
  }

  render() {
    const {
      shown, message, notificationType, hideNotification,
    } = this.props;

    return (
      <div className="ccm-notification-container">
        <Alert
          show={shown}
          className="d-flex-center-between"
          variant={typeToClassMap[notificationType]}
        >
          <p className="text-left m-0" data-test="notification_toastMsg">{message}</p>
          <Button
            className="p-0 ml-2"
            data-test="notification_closeToastMsg"
            onClick={() => hideNotification()}
            variant={`outline-${typeToClassMap[notificationType]}`}
          >
            <i className="d-flex-center bi-x" />
          </Button>
        </Alert>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.notification.message,
    autoHide: state.notification.autoHide,
    shown: state.notification.shown,
    notificationType: state.notification.notificationType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => dispatch(HideNotification()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
