// Libraries
import React, {
  Component, Fragment, useEffect, useState,
} from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
// Constants
import { STATUSES } from '../../constants/statuses';
import {
  DATE_FORMAT, GENDERS, NOTIFICATION_TYPE, USER_ROLES,
  NEXT_OUTREACH_DATE_BLANK, TENANT_FEATURES_NAMES,
} from '../../constants/constants';
// Services
import { copyToClipboard, getCallTimeLabel, getContactMethodIcon } from '../../services/helpers';
// Actions
import { UpdatePatient } from '../../actions/patient';
// Views
import EmrNoticeModal from '../patient/patientProfile/EmrNoticeModal';
import CCMCategories from '../shared/CCMCategories';
import PhoneNumberDropdown from '../patient/phoneNumber/PhoneNumberDropdown';
import ApproveBilling from '../base/ApproveBilling';
import AuditingModal from '../patient/auditing/AuditingModal';
import NextOutreachDateModal from '../patient/nextOutreachDate/NextOutreachDateModal';
// Services
import { getPatientProfile, refreshEmrReconcile, updateNextActionDate } from '../../services/patient';
import ShowNotification from '../../actions/notification';

export class MenuPatientInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEmrNotice: false,
      hideBillingButton: false,
      isAuditingModalOpen: false,
    };

    this.promises = {};

    this.hideApproveBillingButton = this.hideApproveBillingButton.bind(this);
    this.renderApproveBillingButton = this.renderApproveBillingButton.bind(this);
  }

  componentDidMount() {
    this.loadPatientProfile();
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      if (this.promises[key].cancel) {
        this.promises[key].cancel();
      }
    });
  }

  noticeEmr = () => {
    const {
      patient: {
        nextActionDateReminderInfo,
        profile: {
          textMessageConsent: engoodenTextConsent,
          consentToTextMessages: emrTextConsent,
        } = {},
      },
    } = this.props;
    const { contactMethod = '' } = nextActionDateReminderInfo || {};
    const noEngoodenText = engoodenTextConsent && (engoodenTextConsent === 'NOTEXTS' || engoodenTextConsent === 'NONE');

    if (!emrTextConsent && noEngoodenText && contactMethod === 'text') {
      this.setState({ isEmrNotice: true });
    }
  }

  loadPatientProfile = () => {
    const {
      patientId, updatePatient, showNotification,
    } = this.props;
    const getProfileRequest = getPatientProfile(patientId);
    const getProfilePromise = getProfileRequest.promise;

    getProfilePromise.then((data) => {
      delete getProfileRequest.promise;
      updatePatient({ profile: data || {} });
      this.noticeEmr();
    }).catch((error) => {
      delete getProfileRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load patient profile information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  refreshEmrReconcile = () => {
    const { patientId, showNotification } = this.props;
    const refreshEmrReconcileRequest = refreshEmrReconcile(patientId);
    const refreshEmrReconcilePromise = refreshEmrReconcileRequest.promise;

    refreshEmrReconcilePromise.then(() => {
      delete refreshEmrReconcileRequest.promise;
      showNotification({
        message: 'Success! Anita now contains the latest EMR updates.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete refreshEmrReconcileRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Hmmm, something went wrong. Try again. If Anita still does not refresh, post a message to the #anita-support slack channel',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  hideApproveBillingButton(shouldHide) {
    this.setState({
      hideBillingButton: shouldHide,
    });
  }

  renderApproveBillingButton() {
    const {
      user, patient, patientId, reloadData,
    } = this.props;
    const { hideBillingButton } = this.state;

    if (user && user.role === USER_ROLES.ADMIN && !hideBillingButton) {
      if (patient && patient.billing && patient.billing.sendingStatus === 'PENDING_REVIEW') {
        return (
          <ApproveBilling
            selectedPatientsIds={[patientId]}
            renderCompact
            submitCallback={this.hideApproveBillingButton}
            reloadData={reloadData}
          />
        );
      }
    }
    return '';
  }

  renderRefreshPatientRecordButton = () => {
    const { tenant: { features }, user } = this.props;
    const isAdminUser = user.role === USER_ROLES.ADMIN;
    const emrManualFeature = features
      && features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_MANUAL_EMR_UPDATE.name);
    const showRefreshButton = emrManualFeature && emrManualFeature.enabled && isAdminUser;
    return (
      <Fragment>
        {showRefreshButton && (
          <Button
            size="sm"
            className="mb-2"
            variant="outline-primary"
            onClick={() => this.refreshEmrReconcile()}
            data-test="patientInfo_refreshButton"
            data-for="tooltip-profilePreferences"
            data-tip="Refresh Patient"
          >
            <i className="bi-arrow-repeat" />
          </Button>)
        }
      </Fragment>
    );
  }

  renderAuditingActivityButton = () => {
    const { patientId } = this.props;
    const { isAuditingModalOpen } = this.state;

    return (
      <Fragment>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => this.setState({
            isAuditingModalOpen: true,
          })}
          data-test="patientInfo_auditingModalButton"
          data-for="tooltip-profilePreferences"
          data-tip="Add time tracking entry"
        >
          <i className="bi-card-list" />
        </Button>
        <AuditingModal
          patientId={patientId}
          initialActivity={null}
          isModalOpen={isAuditingModalOpen}
          setIsModalOpen={isOpened => this.setState({
            isAuditingModalOpen: isOpened,
          })}
          data-test="patientInfo_auditingModal"
        />
      </Fragment>
    );
  };

  render() {
    const { patientId, patient } = this.props;
    const { isEmrNotice } = this.state;

    return (
      <Fragment>
        <div className="general-info box-wrapper position-relative">
          <PatientInfo patient={patient} />
          <div className="general-info-buttons h-100 d-flex flex-column">
            {this.renderApproveBillingButton()}
            <div className="d-flex flex-column mt-auto">
              {this.renderRefreshPatientRecordButton()}
              {this.renderAuditingActivityButton()}
            </div>
          </div>
        </div>
        <div className="general-categories box-wrapper text-left mt-2 p-3">
          <span className="categories__title text-uppercase d-block mb-2">CCM Categories:</span>
          <span className="categories__conditions d-block mb-4" data-test="patientInfo_ccmEligibleConditions">
            <CCMCategories
              condition={patient.chronicConditions}
              patientName={`${patient.firstName} ${patient.lastName}`}
              maxColumnsCount={3}
            />
          </span>
          <NextOutreachDateElement
            patientId={patientId}
            patient={patient}
          />
        </div>
        <EmrNoticeModal
          isModalOpen={isEmrNotice}
          setIsModalOpen={isOpened => this.setState({
            isEmrNotice: isOpened,
          })}
          data-test="patientInfo_emrModal"
        />
      </Fragment>
    );
  }
}

export const PatientInfo = ({
  patient, toUrl, fullInfo = true, refreshCallback, showNodElement,
}) => {
  const {
    firstName, lastName, dateOfBirth, age, gender, ehrId,
    status = 'N', primaryPhysicians, lastLoadImport, hasReminders,
    profile: {
      textMessageConsent, monthCallEarly, preferredName, emrPreferredName,
    } = {},
    nonUniquePatientIdentifications,
  } = patient;
  let newPreferredName = '';
  if (emrPreferredName) {
    newPreferredName = emrPreferredName;
  }
  if (preferredName) {
    newPreferredName = preferredName;
  }
  const fullName = `${firstName} ${lastName} ${newPreferredName ? `(${newPreferredName})` : ''}`;
  const lastEmrUpdate = lastLoadImport && lastLoadImport.length > 0 ? moment(lastLoadImport).format(DATE_FORMAT.SHORT) : 'Unknown';
  const primaryStatus = status.charAt(0);
  const statusColor = STATUSES[primaryStatus] ? STATUSES[primaryStatus].color : 'yellow';

  const renderStatusTitle = () => {
    if (STATUSES[primaryStatus]
      && STATUSES[primaryStatus].subStatuses && STATUSES[primaryStatus].subStatuses[status]) {
      return STATUSES[primaryStatus].subStatuses[status].name || '';
    }
    return '';
  };

  const renderPcpName = (physician) => {
    const physicianName = `${physician.firstName || ''} ${physician.middleName || ''} ${physician.lastName || ''}`;
    const locationName = `${physician.location ? physician.location : 'Unknown location'}`;
    return `${physicianName} (${locationName})`;
  };

  const renderPatientIdentification = () => {
    if (
      nonUniquePatientIdentifications === undefined
      || nonUniquePatientIdentifications.length === 0
    ) {
      return (
        <div className="d-flex my-1">
          <span className="patient__id" data-test="patientInfo_id">
            {`ID: ${ehrId || 'N/A'}`}
          </span>
          <Button
            size="xs"
            className="ml-1"
            variant="outline-primary"
            onClick={() => {
              copyToClipboard(ehrId);
            }}
            data-test="patientInfo_copyNonUniquePatientIdentification"
            data-for="tooltip-ehrId"
            data-tip="Copy"
          >
            <i className="bi-copy" />
          </Button>
          <ReactTooltip id="tooltip-ehrId" type="info" effect="float" place="right" />
        </div>
      );
    }

    const nupis = nonUniquePatientIdentifications.map(nupi => (
      <div className="d-flex-center-between px-3 my-1 align-middle">
        <span className="pr-4">{nupi.root}</span>
        <div className="align-items-end">
          <span className="pr-2">{nupi.extension}</span>
          <Button
            size="xs"
            variant="outline-primary"
            onClick={() => {
              copyToClipboard(nupi.extension);
            }}
            data-test="patientInfo_copyNonUniquePatientIdentification"
            data-for="tooltip-nupis"
            data-tip="Copy"
          >
            <i className="bi-copy" />
          </Button>
        </div>
      </div>
    ));

    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="link"
          className="patient__id p-0 border-0"
        >
          {`ID: ${ehrId || 'N/A'}`}
        </Dropdown.Toggle>
        <Button
          size="xs"
          className="ml-1"
          variant="outline-primary"
          onClick={() => {
            copyToClipboard(ehrId);
          }}
          data-test="patientInfo_copyNonUniquePatientIdentification"
          data-for="tooltip-ehrId"
          data-tip="Copy"
        >
          <i className="bi-copy" />
        </Button>
        <ReactTooltip id="tooltip-ehrId" type="info" effect="float" place="right" />
        <Dropdown.Menu>
          {nupis}
          <ReactTooltip id="tooltip-nupis" type="info" effect="float" place="right" />
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [textMessageConsent, monthCallEarly]);

  return (
    <div className="d-flex p-3">
      <div className="d-flex flex-column mr-3">
        <span
          title={renderStatusTitle()}
          className={`avatar-icon bg-ccm-${statusColor} text-white rounded-circle flex-shrink-0`}
          data-test="patientInfo_statusAvatarLabel"
        >
          {status}
          {hasReminders > 0 && (
            <span data-test="patientInfo_avatarBadgeReminders" className="avatar-badge d-flex-center rounded-circle">
              {hasReminders}
            </span>
          )}
        </span>
        {fullInfo && (
          <div className="d-flex flex-column mt-2">
            {monthCallEarly && (
              <i
                className="bi-alarm mx-1"
                data-for="tooltip-profilePreferences"
                data-tip="This patient should be called early in the month"
              />
            )}
            {textMessageConsent && textMessageConsent === 'ALLTEXTS' && (
              <i
                className="bi-chat-right-dots mx-1"
                data-for="tooltip-profilePreferences"
                data-tip="The patient/caregiver agrees to receive communication through text message"
                data-test="patientInfo_textIcon"
              />
            )}
          </div>)}
        <ReactTooltip id="tooltip-profilePreferences" type="info" effect="float" place="right" />
      </div>
      <div className="flex-grow-1 text-left">
        {toUrl ? (
          <Link to={toUrl} className="patient__name d-block" data-test="patientInfo_fullName">
            {fullName}
          </Link>
        ) : (
          <span className="patient__name d-block" data-test="patientInfo_name">
            {fullName}
          </span>
        )}
        {fullInfo && (
          <Fragment>
            <span className="patient__gender d-block mt-2" data-test="patientInfo_gender">
              {`${GENDERS[gender] || 'N/A'}, ${age || 'N/A'}`}
            </span>
            {renderPatientIdentification()}
            <span className="patient__dob d-block mb-1" data-test="patientInfo_dob">{`DOB: ${dateOfBirth ? `${moment(dateOfBirth, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL)}` : ''}`}</span>
            <span className="patient__pcp d-block mb-2" style={{ width: '85%' }} data-test="patientInfo_pcpName">
              {`PCP: ${primaryPhysicians && primaryPhysicians.length ? renderPcpName(primaryPhysicians[0]) : 'no PCP in EMR'}`}
            </span>
            <PhoneNumberDropdown patient={patient} refreshCallback={refreshCallback} className="patient__phone" />
            <small className="patient__emr d-block text-uppercase mt-2" data-test="patientInfo_lastEMR">
              Last EMR Update:&nbsp;
              {lastEmrUpdate}
            </small>
          </Fragment>)}
        {patient.nextActionDateReminderInfo && showNodElement && (
          <NextOutreachDateElement patientId={patient.id} patient={patient} />
        )}
      </div>
    </div>
  );
};


export const NextOutreachDateElement = (props) => {
  const { patientId, patient: { nextActionDateReminderInfo } } = props;
  const [isNextOutreachDateModalOpen, setIsNextOutreachDateModalOpen] = useState(false);
  const [nextActionDate, setNextActionDate] = useState(nextActionDateReminderInfo || {});
  const { reminderDate = '', reminderTime = '', contactMethod = '' } = nextActionDate || {};
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const updatePatient = patientData => dispatch(UpdatePatient(patientData));

  const saveNextOutreachDate = (updatedNextOutreachInfo) => {
    const updateNextOutreachDateRequest = updateNextActionDate(patientId, updatedNextOutreachInfo);
    const updateNextOutreachDatePromise = updateNextOutreachDateRequest.promise;
    return updateNextOutreachDatePromise.then(() => {
      delete updateNextOutreachDatePromise.promise;
      updatePatient({
        nextActionDateReminderInfo: {
          id: updatedNextOutreachInfo.id,
          reminderDate: updatedNextOutreachInfo.date,
          contactMethod: updatedNextOutreachInfo.contactMethod,
          reminderTime: updatedNextOutreachInfo.preferredCallTime,
        },
      });
      setNextActionDate({
        id: updatedNextOutreachInfo.id,
        reminderDate: updatedNextOutreachInfo.date,
        contactMethod: updatedNextOutreachInfo.contactMethod,
        reminderTime: updatedNextOutreachInfo.preferredCallTime,
      });
      showNotification({
        message: 'Next outreach info saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete updateNextOutreachDatePromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) return;
      showNotification({
        message: 'Could not update next outreach info.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center" data-test="patientInfo_NextActionDateRow">
        <small className="text-uppercase text-nowrap mr-3">Next Outreach:</small>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex flex-grow-1 overflow-hidden border-bottom p-0"
          onClick={() => setIsNextOutreachDateModalOpen(true)}
          data-test="patientInfo_nextOutreachDateButton"
        >
          <span className="categories__outreachDate">
            {`${reminderDate ? moment(reminderDate).format(DATE_FORMAT.FULL) : 'Click to Edit'} ${(reminderDate || (reminderTime && reminderTime !== 'NONE')) ? `(${getCallTimeLabel(reminderTime)})` : ''}`}
          </span>
          {(reminderDate || (reminderTime && reminderTime !== 'NONE')) && <span className={`bi-${getContactMethodIcon(contactMethod)} ml-2`} />}
        </Button>
        {(reminderDate || (reminderTime && reminderTime !== 'NONE')) && (
          <Button
            variant="link-dark"
            onClick={() => saveNextOutreachDate(NEXT_OUTREACH_DATE_BLANK)}
            data-test="patientInfo_clearNODButton"
          >
            <span className="bi-x" />
          </Button>
        )}
      </div>
      <NextOutreachDateModal
        patientId={patientId}
        initialNextOutreachDate={nextActionDateReminderInfo}
        nextOutreachElementSave={setNextActionDate}
        isModalOpen={isNextOutreachDateModalOpen}
        setIsModalOpen={setIsNextOutreachDateModalOpen}
        data-test="patientInfo_nodModal"
      />
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
    tenant: state.tenant,
    loading: state.requestsInProgress.count,
  };
}

const mapDispatchToProps = dispatch => ({
  updatePatient: patientData => dispatch(UpdatePatient(patientData)),
  showNotification: notificationData => dispatch(ShowNotification(notificationData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuPatientInfo);
