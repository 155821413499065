const METRICS_VALUES = {
  done: 0,
  goal: 0,
};

export const DEFAULT_METRICS = {
  BILLED_PATIENTS_PER_MONTH: METRICS_VALUES,
  ENROLLED_PATIENTS_PER_MONTH: METRICS_VALUES,
  ENROLLED_PATIENTS_PER_DAY: METRICS_VALUES,
  BILLED_PATIENTS_PER_DAY: METRICS_VALUES,
};

export const DEFAULT_BILLING_METRICS = [
  {
    firstName: 'JuanCN',
    lastName: 'UmbarilaCN',
    metrics: {
      assignedCount: 10,
      doNotBillCount: 0,
      billedTodayCount: 0,
      billedThisMonthCount: 0,
    },
  },
  {
    firstName: 'Enrollment Specialist',
    lastName: 'Winfield - PES INTERNAL',
    metrics: {
      assignedCount: 6,
      doNotBillCount: 1,
      billedTodayCount: 0,
      billedThisMonthCount: 0,
    },
  },
  {
    firstName: 'Doctestdev',
    lastName: 'PES',
    metrics: {
      assignedCount: 2,
      doNotBillCount: 1,
      billedTodayCount: 0,
      billedThisMonthCount: 0,
    },
  },
];

export const DEFAULT_CHART_VALUES = {
  daily: {
    ...METRICS_VALUES,
    percentage: 0,
  },
  monthly: {
    ...METRICS_VALUES,
    percentage: 0,
  },
};

export const METRICS_USER_MESSAGES = {
  billing: {
    dailyDiff: '[x] more patients are waiting to hear from you today!',
    dailyComplete: 'Great work! You’ve made a difference in [x] patient’s lives today!',
    monthlyDiff: 'Bill [x] more patients this month to meet your monthly billing goal!',
    monthlyComplete: 'Congratulations! You are on track to meet the monthly goal!',
    none: 'No daily or monthly billing goals have been set. Please talk to your manager about setting up daily and monthly billing goals.',
  },
  enrollment: {
    dailyDiff: '[x] more patients are waiting to meet you today!',
    dailyComplete: 'Great work! You’ve made connections with [x] new patients today!',
    monthlyDiff: 'Enroll [x] more patients this month to meet your monthly enrollment goal!',
    monthlyComplete: 'Congratulations! You are on track to meet your monthly goal!',
    none: 'No daily or monthly enrollment goals have been set. Please talk to your manager about setting up daily and monthly enrollment goals.',
  },
};

export const METRICS_TEAM_MESSAGES = {
  billing: {
    dailyDiff: 'Your team needs to bill [x] more patients today to stay on track',
    dailyComplete: 'Congratulations! Your team is on track to meet today’s billing goal!',
    monthlyDiff: 'Your team needs to bill [x] more patients this month to stay on track',
    monthlyComplete: 'Congratulations! Your team is on track to meet the monthly goal!',
    none: 'No daily or monthly billing goals have been set for this customer. Please talk to your manager about setting up daily and monthly billing goals.',
    noneAdmin: 'No daily or monthly billing goals have been set for this customer. Please go to Administration -> Workload to set these goals.',
  },
  enrollment: {
    dailyDiff: 'Your team needs to enroll [x] more patients today to stay on track',
    dailyComplete: 'Congratulations! Your team is on track to meet today’s enrollment goal!',
    monthlyDiff: 'Your team needs to enroll [x] more patients this month to stay on track',
    monthlyComplete: 'Congratulations! Your team is on track to meet the monthly enrollment goal!',
    none: 'No daily or monthly enrollment goals have been set for this customer. Please talk to your manager about setting up daily and monthly enrollment goals.',
    noneAdmin: 'No daily or monthly enrollment goals have been set for this customer. Please go to Administration -> Workload to set these goals.',
  },
};

export const DEFAULT_BILL_METRICS = {
  assignedCount: 0,
  doNotBillCount: 0,
  billedTodayCount: 0,
  billedThisMonthCount: 0,
  billedBonusCount: 0,
};
