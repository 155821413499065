// Libraries
import React, { useState } from 'react';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _first from 'lodash/first';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Button, Dropdown } from 'react-bootstrap';
// Constants
import {
  EMPTY_STRING, DATE_FORMAT, TIME_OPTIONS, BLOOD_SUGAR_TYPE_OPTIONS,
} from '../../../../constants/constants';
// Views
import VitalsModal from './VitalsModal';
import InterventionsModal from '../interventions/InterventionModal';

const defaultYear = moment.utc().format(DATE_FORMAT.YEAR);

export const Vitals = (props) => {
  const {
    patientHistory: { vitalEntries = {}, vitals = [] } = {}, patientId,
  } = props;
  const VITALS_FILTERS = { [defaultYear]: { name: defaultYear } };

  Object.keys(vitalEntries).forEach((year) => {
    VITALS_FILTERS[year] = {
      name: `${year}`,
    };
  });

  const [vitalsFilter, setVitalsFilter] = useState(defaultYear);
  const [selectedVitals, setSelectedVitals] = useState(null);
  const [initialIntervention, setInitialIntervention] = useState(null);
  const [isVitalsModalOpen, setIsVitalsModalOpen] = useState(false);
  const [isInterventionsModalOpen, setIsInterventionsModalOpen] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);

  const handleAddIntervention = (vitalsEntry) => {
    const bmiText = vitalsEntry.bmi ? `, BMI: ${vitalsEntry.bmi}` : '';
    const spo2Text = vitalsEntry.spO2 ? `, SPO2: ${vitalsEntry.spO2}` : '';
    const heightText = vitalsEntry.height ? `Height: ${vitalsEntry.height}` : '';
    const weightText = vitalsEntry.weight ? `, Weight: ${vitalsEntry.weight}` : '';
    const bsText = vitalsEntry.bloodSugar ? `, BS: ${vitalsEntry.bloodSugar}` : '';
    const bpText = vitalsEntry.bpSystolic && vitalsEntry.bpDiastolic ? `, BP: ${vitalsEntry.bpSystolic}/${vitalsEntry.bpDiastolic}` : '';
    setInitialIntervention({
      note: `Vitals - ${heightText}${weightText}${bmiText}${bpText}${bsText}${spo2Text}.`,
    });
    setIsInterventionsModalOpen(true);
  };

  const handleAddEditVitals = (vitalsEntry) => {
    setSelectedVitals(vitalsEntry);
    setIsVitalsModalOpen(true);
  };

  const renderDropDownItems = Object.keys(VITALS_FILTERS).map((key, index) => {
    const yearName = defaultYear === key ? 'This Year' : key;
    return (
      <Dropdown.Item
        key={`vitals_filter_${index}`}
        as="button"
        className="d-flex my-1"
        onClick={() => {
          setVitalsFilter(VITALS_FILTERS[key].name);
        }}
        data-test="vitals_dateFilterItem"
      >
        <div className="pr-3">{yearName}</div>
      </Dropdown.Item>
    );
  });

  const renderEmptyRow = () => (
    <tr data-test="vitals_emptyMsg">
      <td colSpan="9" className="p-2 border-0">
        This patient has no recorded vitals
      </td>
    </tr>
  );

  const renderVitalsRows = () => {
    if (vitalEntries[vitalsFilter] && vitalEntries[vitalsFilter].length) {
      let sortedVitalsByDate = _reverse(
        _sortBy(vitalEntries[vitalsFilter], el => moment(el.date)),
      );
      if (collapseAll) {
        sortedVitalsByDate = [_first(sortedVitalsByDate)];
      }
      return sortedVitalsByDate.map((vitalsEntry, index) => {
        const dateString = vitalsEntry.date
          ? moment.utc(vitalsEntry.date).format(DATE_FORMAT.SHORT) : EMPTY_STRING;
        const getBloodSugarTimeLabel = (timeValue) => {
          const bsTime = TIME_OPTIONS.find(x => x.value === timeValue);
          return bsTime ? bsTime.label : EMPTY_STRING;
        };
        const getBloodSugarTypeLabel = (typeValue) => {
          const bsType = BLOOD_SUGAR_TYPE_OPTIONS.find(x => x.value === typeValue);
          return bsType ? bsType.label : EMPTY_STRING;
        };

        const reporter = vitalsEntry.source === 'COHORT' ? 'Patient' : 'EMR';
        const bpString = vitalsEntry.bpSystolic && vitalsEntry.bpDiastolic ? `${vitalsEntry.bpSystolic}/${vitalsEntry.bpDiastolic}` : EMPTY_STRING;
        const bsDataString = `${(vitalsEntry.bloodSugarTime && vitalsEntry.bloodSugarType)
          ? ` (${getBloodSugarTimeLabel(vitalsEntry.bloodSugarTime)}, ${getBloodSugarTypeLabel(vitalsEntry.bloodSugarType)})`
          : `${(vitalsEntry.bloodSugarTime && !vitalsEntry.bloodSugarType)
            ? ` (${getBloodSugarTimeLabel(vitalsEntry.bloodSugarTime)})`
            : `${(!vitalsEntry.bloodSugarTime && vitalsEntry.bloodSugarType)
              ? ` (${getBloodSugarTypeLabel(vitalsEntry.bloodSugarType)})`
              : ''}`}`}`;

        const bsString = vitalsEntry.bloodSugar ? `${vitalsEntry.bloodSugar}${bsDataString}` : EMPTY_STRING;

        const onRowClick = vitalsEntry.source === 'COHORT' ? () => handleAddEditVitals(vitalsEntry) : undefined;

        return (
          <tr key={`vitals_row_${index}_${vitals.date}`} data-test="vitals_entryRow" className={vitalsEntry.source === 'COHORT' ? '' : 'no-editable'}>
            <td onClick={onRowClick} data-test="vitals_editBtn">
              {dateString}
              {vitalsEntry.baseline && <small className="d-block">Baseline</small>}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn vitals_reporterCol">
              {reporter}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn">
              {vitalsEntry.height || EMPTY_STRING}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn">
              {vitalsEntry.weight || EMPTY_STRING}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn">
              {vitalsEntry.bmi || EMPTY_STRING}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn">
              {bpString}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn vitals_bsCol">
              {bsString}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn">
              {vitalsEntry.spO2 || EMPTY_STRING}
            </td>
            <td onClick={onRowClick} data-test="vitals_editBtn">
              {vitalsEntry.heartRate || EMPTY_STRING}
            </td>
            <td className="text-right">
              <Button variant="link-dark" className="px-1" onClick={() => handleAddIntervention(vitalsEntry)} data-test="vitals_addInterventionBtn" data-for="tooltip-cpInterventions" data-tip="Add Intervention">
                <i className="bi-plus-circle-fill" />
              </Button>
              <Button variant="link-dark" className="px-1 hidden" disabled>
                <i className="bi-trash" />
              </Button>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  const renderVitalsMoreRow = () => {
    if (
      vitalEntries[vitalsFilter] && vitalEntries[vitalsFilter].length
      && vitalEntries[vitalsFilter].length > 1
    ) {
      const nMore = vitalEntries[vitalsFilter].length - 1;
      const onRowClick = () => setCollapseAll(!collapseAll);
      return (
        <tr key="vitals_more_row">
          <td colSpan={10} onClick={onRowClick} className="btn-link text-center" data-test="vitals_plusMore">
            {`+${nMore} more`}
          </td>
        </tr>
      );
    }
    return null;
  };

  return (
    <div className="card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <Dropdown
          className="position-absolute"
          style={{ left: 1 }}
          data-test="vitals_dateFilter"
        >
          <Dropdown.Toggle
            variant="ccm-light-gray"
            className="py-0"
          >
            {`Filter: ${defaultYear === vitalsFilter ? 'This Year' : vitalsFilter}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {renderDropDownItems}
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-uppercase">Vitals</span>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '3rem' }}
          onClick={() => {
            setCollapseAll(!collapseAll);
          }}
          data-test="vitals_collapseAllBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => handleAddEditVitals(null)}
          data-test="vitals_addBtn"
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left table-hover">
          <thead data-test="vitals_vitalsAllFields">
            <tr>
              <th className="py-1">Date</th>
              <th className="py-1">Reporter</th>
              <th className="py-1">
                Height
                <span className="text-lowercase d-block">(inches)</span>
              </th>
              <th className="py-1">
                Weight
                <span className="text-lowercase d-block">(pounds)</span>
              </th>
              <th className="py-1">BMI</th>
              <th className="py-1">BP</th>
              <th className="py-1">BS</th>
              <th className="py-1">SPO2</th>
              <th className="py-1">HR</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {renderVitalsRows()}
            {collapseAll && renderVitalsMoreRow()}
          </tbody>
        </table>
        <VitalsModal
          patientId={patientId}
          initialVitals={selectedVitals}
          isModalOpen={isVitalsModalOpen}
          setIsModalOpen={setIsVitalsModalOpen}
        />
        <InterventionsModal
          patientId={patientId}
          initialIntervention={initialIntervention}
          isModalOpen={isInterventionsModalOpen}
          setIsModalOpen={setIsInterventionsModalOpen}
        />
        <ReactTooltip id="tooltip-cpInterventions" type="info" effect="float" place="bottom" />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

export default connect(mapStateToProps)(Vitals);
