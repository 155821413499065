// libraries
import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import shortid from 'shortid';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
// actions
import ShowNotification from '../../actions/notification';
import SetPatient, { UpdatePatient } from '../../actions/patient';
import { BlockRouteTransitions, UnBlockRouteTransitions } from '../../actions/router';
// constants
import { AUDITING_PAGE_SIZE } from '../../constants/pageSizes';
import {
  DATE_FORMAT, NOTIFICATION_TYPE, DIALOG_STYLES, ELIGIBILITY_ISSUES,
  USER_BILLING_STATUSES, USER_ROLES,
} from '../../constants/constants';
// services
import {
  createActivitiesPdf,
  deleteActivity,
  getActivities,
} from '../../services/patient';
import { downloadFile, validateDate } from '../../services/helpers';
// views
import OneFieldDatePicker from '../base/OneFieldDatePicker';
import Activity from './auditing/Activity';
import Pager from '../patients/Pager';
import LoadingBlock from '../menu/LoadingBlock';
// Components
import { withRouter } from '../shared/WithRouter';


const ELEMENT_NAME_TO_BLOCK = 'isActivity';
const MAX_SHOWN_PAGES = 3;

function haveNewActivities(activities) {
  return activities.some(activity => activity.isNew);
}

export class Auditing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dateRange: {},
      currentPage: 0,
      modalOpened: false,
    };

    this.promises = {};

    this.addActivity = this.addActivity.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.renderActivities = this.renderActivities.bind(this);
    this.updateDateRange = this.updateDateRange.bind(this);
    this.downloadActivitiesPdf = this.downloadActivitiesPdf.bind(this);
  }

  componentDidMount() {
    this.loadAuditingData();
  }

  componentDidUpdate() {
    const {
      auditing: { activities = [] } = {}, blockTransitions, unblockTransitions,
    } = this.props;

    // separate blocking actions for Logout button
    if (haveNewActivities(activities)) {
      blockTransitions(ELEMENT_NAME_TO_BLOCK);
    } else {
      unblockTransitions(ELEMENT_NAME_TO_BLOCK);
    }
  }

  componentWillUnmount() {
    const { unblockTransitions } = this.props;
    unblockTransitions(ELEMENT_NAME_TO_BLOCK);

    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  openModal = () => {
    this.setState({
      modalOpened: true,
    });
  }

  closeModal = () => {
    if (this.state.loading) {
      return;
    }

    this.setState({
      modalOpened: false,
    });
  }

  goToPage = (pageNumber) => {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      currentPage: pageNumber,
    }, () => this.loadAuditingData());
  }

  loadAuditingData = async () => {
    const { dateRange, currentPage } = this.state;
    const {
      updatePatient, showNotification, navigate, params: { tenant: tenantUrl, id: patientId },
    } = this.props;

    this.setState({
      loading: true,
    });

    const params = {
      pageSize: AUDITING_PAGE_SIZE,
      pageNumber: currentPage,
    };
    if (dateRange.from) {
      params.from = moment(dateRange.from).format(DATE_FORMAT.FULL);
    }
    if (dateRange.to) {
      params.to = moment(dateRange.to).format(DATE_FORMAT.FULL);
    }

    const promiseName = 'getActivities';
    const getActivitiesRequest = getActivities(patientId, params);
    const getActivitiesPromise = getActivitiesRequest.promise;
    this.promises[promiseName] = getActivitiesRequest;

    getActivitiesPromise.then((data) => {
      delete this.promises[promiseName];

      updatePatient({
        auditing: data,
      });

      this.setState({
        loading: false,
      });
    }).catch((error) => {
      delete this.promises[promiseName];
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }

      navigate(`/${tenantUrl}/cn/list`);

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load patient tracking data',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  addActivity() {
    const {
      auditing, auditing: { activities },
      updatePatient, showNotification,
    } = this.props;

    if (haveNewActivities(activities)) {
      showNotification({
        message: 'You cannot create new activity before saving previous one.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return;
    }


    const { timezone, patientCanBeBilled } = this.props;
    const uniqueKey = shortid.generate();
    const newActivity = {
      isNew: true,
      title: '',
      notes: '',
      date: moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER_WITH_TIME),
      duration: 0,
      id: -1,
      billable: patientCanBeBilled,
      directContactIsMade: false,
      uniqueKey,
    };

    updatePatient({
      auditing: {
        ...auditing,
        activities: [
          newActivity,
          ...activities,
        ],
      },
    });
  }

  removeActivity(currentActivity) {
    const { auditing, auditing: { activities }, updatePatient } = this.props;


    const newActivities = activities.filter(activity => activity.id !== currentActivity.id);

    updatePatient({
      auditing: {
        ...auditing,
        currentPage: 0,
        activities: newActivities,
      },
    });
  }

  deleteActivity(activity) {
    if (activity.isNew) {
      this.removeActivity(activity);
      return;
    }

    this.setState({
      loading: true,
    });

    const promiseName = 'deleteActivity';
    const deleteActivityRequest = deleteActivity(activity);
    const deleteActivityPromise = deleteActivityRequest.promise;
    this.promises[promiseName] = deleteActivityRequest;

    deleteActivityPromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });

      this.removeActivity(activity);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });
    });
  }

  updateDateRange(key, value, isValid) {
    const { loading } = this.state;

    if (!isValid || loading) {
      return;
    }

    this.setState(state => ({
      dateRange: {
        ...state.dateRange,
        [key]: value,
      },
    }), () => this.loadAuditingData());
  }

  downloadActivitiesPdf() {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const { showNotification, params: { id: patientId } } = this.props;

    const { dateRange } = this.state;

    const params = {};
    if (dateRange.from) {
      params.from = moment(dateRange.from).format(DATE_FORMAT.FULL);
    }
    if (dateRange.to) {
      params.to = moment(dateRange.to).format(DATE_FORMAT.FULL);
    }

    const promiseName = 'createActivitiesPdf';
    const createActivitiesPdfRequest = createActivitiesPdf(patientId, params);
    const createActivitiesPdfPromise = createActivitiesPdfRequest.promise;
    this.promises[promiseName] = createActivitiesPdfRequest;

    createActivitiesPdfPromise.then((data) => {
      delete this.promises[promiseName];

      downloadFile(data.data, data.fileName);
      this.setState({
        loading: false,
      }, this.closeModal);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not generate PDF, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  renderActivities() {
    const {
      params: { id: patientId },
      auditing: { activities = [], maxCcmMtd = 0 } = {},
    } = this.props;
    const { loading } = this.state;

    return activities.map(activity => (
      <Activity
        loadCallback={() => this.loadAuditingData()}
        patientId={patientId}
        key={activity.id}
        activity={activity}
        maxCcmMtd={maxCcmMtd}
        deleteCallback={this.deleteActivity}
        changeCallback={() => this.loadAuditingData()}
        activityID={activity.id}
        disabled={loading}
      />
    ));
  }

  renderActivitytTotal = () => {
    const { loading } = this.state;
    const {
      patient: { billing, status, billingSettings },
      auditing: { totalTime = 0, totalCcmMtd = 0, daysLeft: billingDaysLeft = 0 } = {},
    } = this.props;
    const isIssueOnHold = billing && billing.sendingStatus && billing.sendingStatus === 'ON_HOLD';
    const isCSDoNotBill = billingSettings && billingSettings.doNotBill && status === 'CS';
    let billableActivitiesDurationBlock;
    let billingDaysLeftBlock;
    let isIssuesExist = false;
    let issuesBlock;
    let tooltipText = [];

    const totalActivityTime = (
      <div className="d-flex-center px-2">
        Total activity time:
        {!loading && (
          <span className="px-1">
            {` ${totalTime} min`}
          </span>
        )}
      </div>
    );

    if (this.props.patientCanBeBilled) {
      billableActivitiesDurationBlock = (
        <div className="d-flex-center px-2" data-test="auditing_totalCCMTimeMTD">
          Total CCM time MTD:
          {!loading && (
            <span className="px-1">
              {` ${totalCcmMtd} min`}
            </span>
          )}
        </div>
      );

      if (billingDaysLeft || billingDaysLeft === 0) {
        let billingDaysLeftText;

        if (billingDaysLeft === 0) {
          billingDaysLeftText = 'today';
        } else {
          billingDaysLeftText = `in ${billingDaysLeft} day`;

          if (billingDaysLeft > 1) {
            billingDaysLeftText += 's';
          }
        }

        billingDaysLeftBlock = (
          <div className="d-flex-center pr-2 billing-cycle" data-test="auditing_billingCycleEnds">
            Billing cycle ends:
            {' '}
            {!loading && (<span className="px-1">{billingDaysLeftText}</span>)}
          </div>
        );
      }
    }

    if (billing && billing.issues && billing.issues.length
      && USER_BILLING_STATUSES.includes(status)) {
      isIssuesExist = true;

      tooltipText = billing.issues
        .filter(issue => ELIGIBILITY_ISSUES[issue])
        .map(issue => ELIGIBILITY_ISSUES[issue].name);
    }

    if (!isIssueOnHold && !isCSDoNotBill) {
      if (isIssuesExist) {
        issuesBlock = (
          <div className="d-flex-center w-100">
            <div className="d-flex ml-auto">
              <span className="bi-exclamation-triangle text-ccm-bright-red mr-2" />
              <span>Actions required before</span>
              &nbsp;
              {billingDaysLeftBlock}
            </div>
            <span
              data-tip={tooltipText.join('<br />')}
              data-for="tooltip-billingInfo"
              className="bi bi-question-circle text-ccm-bright-red ml-auto"
            />
          </div>
        );
      }
    } else {
      issuesBlock = (
        <Fragment>
          <div className="d-flex-center w-100" data-test="auditing_issueTitle">
            <div className="ml-auto">
              <span className="bi bi-dash-circle text-ccm-bright-red mr-2" />
              <span data-test="auditing_suspendBillingAlert" className="text-uppercase text-dark">Billing is on hold for this patient</span>
            </div>
            <span
              data-tip={tooltipText.join('<br />')}
              data-for="tooltip-billingInfo"
              className="bi bi-question-circle text-ccm-bright-red ml-auto"
            />
          </div>
        </Fragment>
      );
    }

    return (
      <div className={`card-header rounded-0 d-flex py-1 my-2 activity-total ${(isIssuesExist || isIssueOnHold || isCSDoNotBill) ? 'issues-exist' : ''}`}>
        {isIssuesExist || isIssueOnHold || isCSDoNotBill
          ? (
            <Fragment>
              {issuesBlock}
              <ReactTooltip id="tooltip-billingInfo" type="info" place="bottom" effect="float" multiline />
            </Fragment>
          )
          : (
            <div className="d-flex-center">
              {billingDaysLeftBlock}
              {billableActivitiesDurationBlock}
              {totalActivityTime}
            </div>
          )
        }
      </div>
    );
  }

  render() {
    const { dateRange, loading, currentPage } = this.state;
    const {
      timezone,
      auditing: { totalPages = 0 } = {},
      userRole,
    } = this.props;

    let modalLoading;
    if (loading) {
      modalLoading = <LoadingBlock />;
    }

    const isAdmin = userRole && userRole === USER_ROLES.ADMIN;

    return (
      <div className={`time-tracking-section h-100 pr-2 overflow-auto ${loading ? ' d-none' : ''}`}>
        <h4 className="text-uppercase text-left mb-0 mt-3">Time Tracking</h4>
        <div className="d-flex justify-content-end align-items-center mb-1">
          {isAdmin && (
            <div className="d-flex justify-content-end mr-2">
              <Button
                size="sm"
                variant="link-dark"
                className="text-uppercase px-2"
                data-test="auditingActivity_historyButton"
                onClick={this.openModal}
              >
                <span className="bi-download mr-2" />
                Activity history
              </Button>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <Button
              id="add-time-tracking-button"
              size="sm"
              variant="primary"
              className="d-flex-center text-uppercase ml-auto"
              onClick={this.addActivity}
              disabled={loading}
              data-test="addActivityButton"
            >
              <i className="d-flex-center bi-plus-lg" />
              Add
            </Button>
          </div>
        </div>
        <div className="card border-0 mb-4">
          {this.renderActivitytTotal()}
          {this.renderActivities()}
          <Pager
            totalPages={totalPages}
            callback={this.goToPage}
            maxShownCount={MAX_SHOWN_PAGES}
            currentPage={currentPage}
          />
        </div>
        <Modal
          isOpen={this.state.modalOpened}
          style={DIALOG_STYLES}
          onRequestClose={this.closeModal}
          contentLabel="Download Time Tracking"
        >
          <div className="simple-dialog medium-dialog time-tracking-modal">
            <div className="dialog-title">
              Download
              <div
                className="close-icon i-close"
                onClick={this.closeModal}
              />
            </div>
            <div className="dialog-content">
              {modalLoading}
              <div className="auditing-date-range">
                <div className="form-row" data-test="auditing_fromDate">
                  <div className="input-label">From:</div>
                  <OneFieldDatePicker
                    submitCallback={this.updateDateRange}
                    fieldKey="from"
                    initialValue={dateRange.from}
                    maxDate={
                      dateRange.to
                      || moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER)
                    }
                    maxDateName="End date"
                    dateName="Start date"
                    validationCallback={validateDate}
                    disabled={loading}
                    dataTest="auditing_fromDatepicker"
                  />
                </div>
                <div className="form-row" data-test="auditing_toDate">
                  <div className="input-label">To:</div>
                  <OneFieldDatePicker
                    submitCallback={this.updateDateRange}
                    fieldKey="to"
                    initialValue={dateRange.to}
                    minDate={dateRange.from}
                    minDateName="Start date"
                    maxDate={moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER)}
                    dateName="End date"
                    validationCallback={validateDate}
                    disabled={loading}
                    dataTest="auditing_toDatepicker"
                  />
                </div>

              </div>
            </div>
            <div className="dialog-buttons justify-content-end px-4">
              <Button
                variant="light"
                onClick={this.closeModal}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                data-test="auditing_downloadBtn"
                className="ml-2"
                onClick={this.downloadActivitiesPdf}
                disabled={loading}
              >
                Download
              </Button>
            </div>
          </div>
        </Modal>
        <ReactTooltip
          id="tooltip-activity-name"
          type="info"
          place="bottom"
          event="mouseenter"
          eventOff="mouseleave"
          globalEventOff="click"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const patientPermissions = state.patient.permissions || {};

  return {
    userRole: state.user && (state.user.role || ''),
    timezone: state.tenant && state.tenant.timezone,
    patient: state.patient,
    auditing: state.patient && state.patient.auditing,
    patientCanBeBilled: patientPermissions.canBeBilled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPatient: patientData => dispatch(SetPatient(patientData)),
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    blockTransitions: elem => dispatch(BlockRouteTransitions(elem)),
    unblockTransitions: elem => dispatch(UnBlockRouteTransitions(elem)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auditing));
