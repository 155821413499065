// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// actions
import ShowNotification from '../../actions/notification';
import { UpdatePatient } from '../../actions/patient';
import { UpdateUserGoals } from '../../actions/user';
// services
import { getGoals } from '../../services/goals';
import { isObjectsEqual } from '../../services/helpers';
import { updateUserInfoInStorage } from '../../services/userStorage';
import { getPatientInfo, setOnHold } from '../../services/patient';
// constants
import {
  NOTIFICATION_TYPE, SUSPEND_BILLING_CATEGORIES, USER_ROLES, DATE_FORMAT,
} from '../../constants/constants';
// views
import SuspendBillingContent from './suspendedBilling/SuspendBillingContent';
import SuspendBillingModal from './suspendedBilling/SuspendBillingModal';
import SuspendBillingConfirmModal from './suspendedBilling/SuspendBillingConfirmModal';
// Components
import { withRouter } from '../shared/WithRouter';

const defaultOnHoldStatus = {
  onHold: false,
  category: '',
  reason: '',
};

export class EligibilityRulesBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onHoldStatusInfo: defaultOnHoldStatus,
      isModalOpen: false,
      isConfirmModalOpen: false,
    };
    this.promises = {};
  }

  componentDidUpdate(prevProps) {
    const { patient } = this.props;
    if (
      patient.billing && patient.billing.billingInfo
      && prevProps.patient && ((prevProps.patient.billing
      && patient.billing.billingInfo !== prevProps.patient.billing.billingInfo)
      || (!prevProps.patient.billing && patient.billing.billingInfo))
    ) {
      const { billingInfo: { onHoldStatusInfo } } = patient.billing;
      this.setState({
        onHoldStatusInfo,
        isModalOpen: false,
        isConfirmModalOpen: false,
      });
    }
  }

  componentWillUnmount() {
    Object.values(this.promises).forEach((promise) => {
      try {
        promise.cancel();
      } catch (e) {}
    });
  }

  getGoalsData = () => {
    const { user: { progressMap }, params: { tenant: tenantUrl }, updateGoals } = this.props;

    const promiseName = 'getGoals';
    const getGoalsRequest = getGoals();
    const getGoalsPromise = getGoalsRequest.promise;

    this.promises[promiseName] = getGoalsPromise;

    getGoalsPromise.then((data) => {
      delete this.promises[promiseName];

      if (!isObjectsEqual(progressMap, data)) {
        updateGoals(data);
        updateUserInfoInStorage({ progressMap: data }, tenantUrl);
      }
    }).catch(data => this.catchLoadData(data, promiseName, 'Could not load goals data'));
  };

  updatePatientData = (onHoldUpdated = null) => {
    const { updatePatient, patientId, patient: { billing } } = this.props;

    const promiseName = 'getPatientInfo';
    const getPatientInfoRequest = getPatientInfo(patientId);
    const getPatientInfoPromise = getPatientInfoRequest.promise;
    this.promises[promiseName] = getPatientInfoRequest;

    getPatientInfoPromise.then(async (data) => {
      delete this.promises[promiseName];
      const updatedBillingInfo = onHoldUpdated
        ? { ...billing.billingInfo, onHoldStatusInfo: onHoldUpdated } : billing.billingInfo;
      updatePatient({ ...data, billing: { ...data.billing, billingInfo: updatedBillingInfo } });

      await this.getGoalsData();
    }).catch(data => this.catchLoadData(data, promiseName, 'Could not update patient data'));
  };

  updateOnHold = (newOnHold) => {
    const { patientId, user: { firstName, lastName } } = this.props;

    const promiseName = 'setOnHold';
    const setOnHoldRequest = setOnHold(patientId, newOnHold);
    const setOnHoldPromise = setOnHoldRequest.promise;
    this.promises[promiseName] = setOnHoldRequest;

    return setOnHoldPromise.then(() => {
      delete this.promises[promiseName];
      const onHoldUpdated = {
        ...newOnHold, userName: `${firstName} ${lastName}`, date: moment().format(DATE_FORMAT.FULL_SERVER),
      };
      this.updatePatientData(onHoldUpdated);
      this.setState({
        isModalOpen: false,
        isConfirmModalOpen: false,
        onHoldStatusInfo: onHoldUpdated,
      });
    }).catch(data => this.catchLoadData(
      data,
      promiseName,
      'Error occurred during request, please try again later.',
    ));
  };

  catchLoadData = (error, promiseName, message) => {
    delete this.promises[promiseName];

    if (error.isCanceled || error.status === 401 || error.status === 403) {
      return;
    }

    const { showNotification } = this.props;

    showNotification({
      message,
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
  };

  render() {
    const { user: { role, reviewRequired }, patient: { billingSettings, status } } = this.props;
    const { isModalOpen, isConfirmModalOpen, onHoldStatusInfo } = this.state;
    const isCSDoNotBill = billingSettings && billingSettings.doNotBill && status === 'CS';

    const { onHold, category, reason } = onHoldStatusInfo;
    const isOnHold = onHold || isCSDoNotBill;

    const getCategoryName = (value) => {
      const item = SUSPEND_BILLING_CATEGORIES.find(el => el.value === value);
      return isOnHold && item ? item.label : '----';
    };

    const openSuspendBillingModal = () => {
      this.setState({
        isModalOpen: true,
      });
    };

    const openSuspendBillingConfirmModal = () => {
      this.setState({
        isConfirmModalOpen: true,
      });
    };

    const ableToEdit = () => {
      const isNotAdmin = USER_ROLES.ADMIN !== role;
      return `${isNotAdmin && reviewRequired ? 'deactivate' : ''}`;
    };

    return (
      <div className={`border p-3 box box-full ${ableToEdit()}`}>
        <div
          data-test="eligibilityRules_toggle"
          className="patient-eligibility-toggle d-flex mb-2"
          onClick={() => (isOnHold ? openSuspendBillingConfirmModal() : openSuspendBillingModal())}
        >
          <div data-test="eligibilityRules_doNotBill_toggleButton" className={`direct-contact-toggle ${isOnHold ? 'active' : ''}`} />
          <span className="mr-4">Do not bill</span>
          <span data-test="eligibilityRules_category_label" className="w-50">{`Category: ${getCategoryName(category)}`}</span>
        </div>
        <SuspendBillingContent
          openModal={openSuspendBillingModal}
          reason={reason}
          onHold={isOnHold}
        />
        <SuspendBillingModal
          isModalOpen={isModalOpen}
          onHoldStatusInfo={onHoldStatusInfo}
          saveSuspendBilling={this.updateOnHold}
          setIsModalOpen={isOpened => this.setState({ isModalOpen: isOpened })}
          data-test="eligibilityRules_suspendBillingModal"
        />
        <SuspendBillingConfirmModal
          isModalOpen={isConfirmModalOpen}
          saveSuspendBilling={this.updateOnHold}
          setIsModalOpen={isOpened => this.setState({ isConfirmModalOpen: isOpened })}
          data-test="eligibilityRules_suspendBillingConfirmModal"
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updateGoals: value => dispatch(UpdateUserGoals(value)),
  };
}

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps, null, { forwardRef: true },
)(EligibilityRulesBlock));
