import Cookies from 'js-cookie';
import { LOCAL_STORAGE_MAIN_KEY } from '../constants/constants';

/**
 * this method is exported for unit testing purposes
 * this method used to check is local storage is available
 * @returns {boolean}
 */
export function localStorageSupported() {
  const testKey = 'test';
  const storage = window.localStorage;

  try {
    storage.setItem(testKey, '1');
    storage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * this method is responsible for saving user data provided from cognito sign-in response
 * @param user -- user data,
 * @param tenant -- tenant name
 */
export function saveUserInStorage(user, tenantId) {
  if (localStorageSupported()) {
    const data = {
      user,
      tenant: tenantId,
      timestamp: Date.now(),
    };

    const LS_KEY = `${LOCAL_STORAGE_MAIN_KEY}_${tenantId}`;
    localStorage.setItem(LS_KEY, JSON.stringify(data));
    const jwtToken = user && user.authData
      && user.authData.idToken && user.authData.idToken.jwtToken;
    if (jwtToken) {
      Cookies.set(LOCAL_STORAGE_MAIN_KEY, jwtToken, {
        secure: true,
      });
    }
  }
}

/**
 * this method is responsible for update user auth data provided from cognito
 * @param authData -- auth data
 */
export function updateUserAuthInStorage(authData, tenantId) {
  if (localStorageSupported()) {
    const LS_KEY = `${LOCAL_STORAGE_MAIN_KEY}_${tenantId}`;
    const prevUserData = JSON.parse(localStorage.getItem(LS_KEY));
    const data = {
      ...prevUserData,
      user: {
        ...prevUserData.user,
        authData,
      },
    };
    localStorage.setItem(LS_KEY, JSON.stringify(data));
    const jwtToken = data.user.authData
      && data.user.authData.idToken && data.user.authData.idToken.jwtToken;
    if (jwtToken) {
      Cookies.set(LOCAL_STORAGE_MAIN_KEY, jwtToken, {
        secure: true,
      });
    }
  }
}

/**
 * this method is responsible for updating any user data
 * @param payload -- user data that sounld be updated,
 */
export function updateUserInfoInStorage(payload, tenantId) {
  if (localStorageSupported()) {
    const LS_KEY = `${LOCAL_STORAGE_MAIN_KEY}_${tenantId}`;

    const prevUserData = JSON.parse(localStorage.getItem(LS_KEY));
    const data = {
      ...prevUserData,
      user: {
        ...prevUserData.user,
        info: {
          ...prevUserData.user.info,
          ...payload,
        },
      },
    };

    localStorage.setItem(LS_KEY, JSON.stringify(data));
    const jwtToken = data.user.authData
      && data.user.authData.idToken && data.user.authData.idToken.jwtToken;
    if (jwtToken) {
      Cookies.set(LOCAL_STORAGE_MAIN_KEY, jwtToken, {
        secure: true,
      });
    }
  }
}

/**
 * this method is used to clear saved user data
 */
export function clearUserFromStorage(tenantId) {
  if (localStorageSupported()) {
    try {
      const LS_KEY = `${LOCAL_STORAGE_MAIN_KEY}_${tenantId}`;

      const data = JSON.parse(localStorage.getItem(LS_KEY));

      data.user = null;
      data.tenant = null;
      data.timestamp = 0;

      localStorage.setItem(LS_KEY, JSON.stringify(data));
      Cookies.remove(LOCAL_STORAGE_MAIN_KEY);
    } catch (err) {
    }
  }
}

/**
 * this method should return user data from local storage
 * saved time should be considered when implementing auto logout
 */
export function getUserFromStorage(tenantId) {
  if (localStorageSupported()) {
    const LS_KEY = `${LOCAL_STORAGE_MAIN_KEY}_${tenantId}`;
    let data = localStorage.getItem(LS_KEY);

    if (data) {
      try {
        data = JSON.parse(data);
      } catch (err) {
        return null;
      }
    } else {
      return null;
    }

    if (data.tenant === tenantId && data.timestamp) {
      const newData = {
        user: data.user,
        tenant: tenantId,
        timestamp: Date.now(),
      };

      localStorage.setItem(LS_KEY, JSON.stringify(newData));
      return data.user;
    }
    clearUserFromStorage(tenantId);
    return null;
  }
  return null;
}

export function checkUserInStorage(tenantId) {
  if (localStorageSupported()) {
    const LS_KEY = `${LOCAL_STORAGE_MAIN_KEY}_${tenantId}`;
    let data = localStorage.getItem(LS_KEY);

    if (data) {
      try {
        data = JSON.parse(data);
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }

    return data.tenant === tenantId;
  }
  return false;
}
