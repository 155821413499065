// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  InputGroup, Button, Form, FormControl,
} from 'react-bootstrap';
// actions
import SetUsersList from '../../../actions/users';
import ShowNotification from '../../../actions/notification';
// constants
import { TEAM_MEMBERS_PAGE_SIZE } from '../../../constants/pageSizes';
import {
  NOTIFICATION_TYPE, USER_ROLES, KEY_CODE, USERS_SORT_STATUSES as SORT_STATUSES,
} from '../../../constants/constants';
// services
import { getTeamMembers } from '../../../services/administration';
import { getUser } from '../../../services/login';
import { updateUserInfoInStorage } from '../../../services/userStorage';
// views
import TeamMember from './team/TeamMember';
import InviteUser from './InviteUser';
import Pager from '../../patients/Pager';
import { UpdateUserStatus } from '../../../actions/user';
// Components
import { withRouter } from '../../shared/WithRouter';

const MAX_SHOWN_PAGES = 3;
const ACTIVITY_STATUSES = {
  enabled: 'Enabled',
  disabled: 'Disabled',
  all: 'All',
};

const SORT_VALUES = {
  lastName: 'lastName',
};
export class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalPages: 0,
      currentPage: 0,
      activityStatus: SORT_STATUSES.enabled,
      filterField: '',
      sort: {
        key: 'lastName',
        reverse: props.users && props.users.usersList
          && props.users.usersList[SORT_STATUSES.enabled]
          ? props.users.usersList[SORT_STATUSES.enabled].reverse : false,
      },
    };

    this.promises = {};
    this.onKeyUp = this.onKeyUp.bind(this);
    this.buildTeamMembers = this.buildTeamMembers.bind(this);
    this.loadTeamMembers = this.loadTeamMembers.bind(this);
    this.changeTeamMembersCallback = this.changeTeamMembersCallback.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.goToPage = this.goToPage.bind(this);
  }

  componentDidMount() {
    this.loadTeamMembers();
    this.getCurrentUserData();
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      if (this.promises[key].cancel) {
        this.promises[key].cancel();
      }
    });
    this.resetUserListFilteredByStatus(this.state.activityStatus);
  }

  onKeyUp(event) {
    const { value } = event.target;
    const { activityStatus } = this.state;

    if (event.keyCode === KEY_CODE.ENTER) {
      this.setState({
        filterField: value,
      }, this.loadTeamMembers);
    }
    if (!event.target.value) {
      this.resetUserListByStatus(activityStatus);
      this.setState({
        filterField: value,
      }, this.loadTeamMembers);
    }
  }

  setActivityStatus= (event) => {
    const { value } = event.target;
    const { users: { usersList } } = this.props;
    const { activityStatus } = this.state;

    if (activityStatus === value) {
      return;
    }
    this.resetUserListFilteredByStatus(activityStatus);
    this.setState({
      currentPage: 0,
      activityStatus: value,
      totalPages: usersList[value] ? usersList[value].totalUserPages : 0,
      filterField: '',
      sort: {
        key: '',
        reverse: usersList
          && usersList[activityStatus]
          ? usersList[activityStatus].reverse : false,
      },
    }, this.loadTeamMembers);
  };

  getCurrentUserData = () => {
    const {
      showNotification, updateUserStatus,
      user: { external }, params: { tenant: tenantUrl },
    } = this.props;

    const promiseName = 'getUser';
    const getUserRequest = getUser();
    const getUserPromise = getUserRequest.promise;

    this.promises[promiseName] = getUserPromise;


    getUserPromise.then((data) => {
      delete this.promises[promiseName];

      if (external !== data.external) {
        updateUserStatus(data.external);
        updateUserInfoInStorage({ external: data.external }, tenantUrl);
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load Care Navigators list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  renderRadioBtn = (value) => {
    const { loading, activityStatus } = this.state;
    const sortKey = Object.keys(SORT_STATUSES).find(key => SORT_STATUSES[key] === activityStatus);
    const activityStatusFinal = sortKey ? ACTIVITY_STATUSES[sortKey] : activityStatus;
    return (
      <Form.Check
        {...(loading && { disabled: true })}
        className="mr-3"
        type="radio"
        name="status"
        value={value}
        label={value}
        checked={activityStatusFinal === value}
        onChange={loading ? () => {} : this.setActivityStatus}
      >
      </Form.Check>
    );
  };

  calculateClass = (prop) => {
    const { sort: { reverse, key } } = this.state;

    let result = '';

    if (key === prop) {
      result += ' active';
      result += reverse ? ' reverse' : ' ';
    }

    return result;
  };

  resetUserListByStatus = (statusValue) => {
    const { users: { usersList }, setUsersList } = this.props;
    const userListUpdated = { ...usersList };
    userListUpdated[statusValue] = {
      ...usersList[statusValue],
      data: [],
      loadedPages: [],
      filtered: false,
    };

    setUsersList({
      usersList: userListUpdated,
    });
  }

  resetUserListFilteredByStatus = (statusValue) => {
    const { users: { usersList }, setUsersList } = this.props;

    if (usersList[statusValue] && usersList[statusValue].filtered) {
      const userListUpdated = { ...usersList };
      userListUpdated[statusValue] = {
        ...usersList[statusValue],
        data: [],
        loadedPages: [],
        filtered: false,
      };

      setUsersList({
        usersList: userListUpdated,
      });
    }
  }

  sortBy = (property) => {
    const { activityStatus, sort } = this.state;
    const { users: { usersList } } = this.props;
    const sortKey = Object.keys(SORT_STATUSES).find(key => SORT_STATUSES[key] === activityStatus);
    const value = sortKey ? SORT_STATUSES[sortKey] : SORT_STATUSES[activityStatus.toLowerCase()];

    if (sort.key === property) {
      this.resetUserListByStatus(value);
    }

    this.setState(prevState => ({
      ...prevState,
      currentPage: 0,
      activityStatus: value,
      totalPages: usersList[value] ? usersList[value].totalUserPages : 0,
      sort: {
        key: property,
        reverse: (prevState.sort.key === property)
          ? !prevState.sort.reverse
          : usersList[activityStatus].reverse,
      },
    }), this.loadTeamMembers);
  };

  loadTeamMembers(reloadData = false) {
    const {
      loading, currentPage, activityStatus, sort, filterField,
    } = this.state;
    const { users: { usersList }, setUsersList, showNotification } = this.props;

    if (usersList[activityStatus]
        && usersList[activityStatus].loadedPages[currentPage]
        && !filterField && !reloadData) {
      this.setState({
        totalPages: usersList[activityStatus].totalUserPages,
      });

      return;
    }

    if (loading) return;

    this.setState({ loading: true });

    const sortParams = {
      sortBy: sort.key,
      sortOrder: sort.reverse ? 'DESC' : 'ASC',
    };

    const filterParams = filterField ? {
      firstName: filterField,
      lastName: filterField,
      username: filterField,
    } : '';

    const sortKey = Object.keys(SORT_STATUSES).find(key => SORT_STATUSES[key] === activityStatus);
    const status = activityStatus !== ACTIVITY_STATUSES.all
      && ACTIVITY_STATUSES[sortKey] !== ACTIVITY_STATUSES.all
      && (ACTIVITY_STATUSES[sortKey] || activityStatus);

    const promiseName = 'getTeamMembers';
    const getTeamMembersRequest = getTeamMembers(
      TEAM_MEMBERS_PAGE_SIZE, currentPage, status, sortParams, filterParams,
    );

    if (filterField) {
      this.resetUserListByStatus(activityStatus);
    }

    const getTeamMembersPromise = getTeamMembersRequest.promise;
    this.promises[promiseName] = getTeamMembersRequest;

    getTeamMembersPromise.then((data) => {
      delete this.promises[promiseName];

      let usersListData = usersList[activityStatus] ? [...usersList[activityStatus].data] : [];
      if (usersListData.length === 0) {
        usersListData = new Array(data.totalPages * TEAM_MEMBERS_PAGE_SIZE).fill(null);
      }

      let usersListPages = usersList[activityStatus]
        ? [...usersList[activityStatus].loadedPages] : [];
      if (usersListPages.length === 0) {
        usersListPages = new Array(data.totalPages).fill(false);
      }
      usersListPages[currentPage] = true;

      const splicedUsersList = [...usersListData];
      splicedUsersList.splice(
        currentPage * TEAM_MEMBERS_PAGE_SIZE,
        TEAM_MEMBERS_PAGE_SIZE,
        ...data.users,
      );

      const usersListLoaded = { ...usersList };

      usersListLoaded[activityStatus] = {
        ...usersList[activityStatus],
        data: splicedUsersList,
        loadedPages: usersListPages,
        totalUserPages: data.totalPages,
        filtered: filterField !== '',
        reverse: this.state.sort.reverse,
      };

      this.setState({
        loading: false,
        totalPages: data.totalPages,
      });

      setUsersList({ usersList: usersListLoaded });

      if ((!data.users || !data.users.length) && currentPage) {
        this.goToPage(currentPage - 1);
      }
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({ loading: false });

      showNotification({
        message: 'Could not load users data.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  changeTeamMembersCallback() {
    const { changeRolesCallback } = this.props;

    this.loadTeamMembers();

    if (changeRolesCallback) {
      changeRolesCallback();
    }
  }

  refreshList(reloadData = false) {
    this.loadTeamMembers(reloadData);
  }

  buildTeamMembers() {
    const { users: { usersList } } = this.props;
    const { currentPage, activityStatus } = this.state;

    if (usersList[activityStatus] && !usersList[activityStatus].loadedPages[currentPage]) {
      return null;
    }

    let slicedUsersList = usersList[activityStatus]
      ? usersList[activityStatus].data.slice(
        currentPage * TEAM_MEMBERS_PAGE_SIZE,
        (currentPage + 1) * TEAM_MEMBERS_PAGE_SIZE,
      ) : [];

    slicedUsersList = slicedUsersList.filter(user => user);

    return slicedUsersList.length > 0 ? slicedUsersList.map(member => (
      <TeamMember
        teamMember={member}
        key={`team-member-${member.id}`}
        refreshList={this.refreshList}
        changeCallback={this.changeTeamMembersCallback}
        activityStatus={activityStatus}
      />
    )) : <div className="empty-value">No users found.</div>;
  }

  goToPage(page) {
    const { loading } = this.state;

    if (loading) {
      return;
    }


    this.setState({
      currentPage: page,
    }, this.loadTeamMembers);
  }

  render() {
    const {
      totalPages, currentPage, loading, filterField,
    } = this.state;
    const { user: { isExternal: isExternalUser = true, role } } = this.props;
    const isInternalAdmin = !isExternalUser
      && (isExternalUser !== null) && (role === USER_ROLES.ADMIN);

    const handleChange = (event) => {
      const { value } = event.target;
      this.setState({
        filterField: value,
      });
    };

    const clickOnSearchIcon = () => {
      if (loading) {
        return;
      }
      this.loadTeamMembers();
    };

    return (
      <div className="card border-0" id="team">
        <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-2">
          <span className="text-uppercase">Team</span>
          <InputGroup className="position-absolute" style={{ right: 5, width: '30%' }}>
            <InputGroup.Prepend>
              <Button variant="outline" onClick={clickOnSearchIcon}>
                <span className="bi bi-search" />
              </Button>
            </InputGroup.Prepend>
            <FormControl
              aria-describedby="searchUser"
              type="search"
              name="searchUser"
              className="pl-3"
              placeholder="Search by name or username"
              value={filterField}
              onKeyUp={this.onKeyUp}
              onInput={this.onKeyUp}
              onChange={handleChange}
              data-test="searchUser-field"
            />
          </InputGroup>
        </div>
        <div className="card-body px-0">
          <div className="d-flex justify-content-end mb-3">
            <Form className="d-flex-center">
              {this.renderRadioBtn(ACTIVITY_STATUSES.enabled)}
              {this.renderRadioBtn(ACTIVITY_STATUSES.disabled)}
              {this.renderRadioBtn(ACTIVITY_STATUSES.all)}
            </Form>
            <InviteUser reloadData={this.loadTeamMembers} />
          </div>

          <div>
            <div className="team-list-title">
              <div className="arrow-placeholder" />
              {isInternalAdmin && <div className="team-member-icon-cell" />}
              <div
                className={`team-member-name sortable ${this.calculateClass(SORT_VALUES.lastName)}`}
                onClick={loading ? null : () => (this.sortBy(SORT_VALUES.lastName))}
              >
                Name
              </div>
              <div className="team-member-cell">Role</div>
              <div className="last-login">Last login</div>
              <div className="team-member-cell">Patients managed</div>
              <div className="team-member-status">Status</div>
              <div className="team-member-cell">Require Review</div>
            </div>
            <div className="admin-section">
              {this.buildTeamMembers()}
            </div>
            <div className="my-3">
              <Pager
                totalPages={totalPages}
                callback={this.goToPage}
                maxShownCount={MAX_SHOWN_PAGES}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    users: state.users,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    setUsersList: usersData => dispatch(SetUsersList(usersData)),
    updateUserStatus: userData => dispatch(UpdateUserStatus(userData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Team));
