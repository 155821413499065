export const EHR_OPTIONS = [
  { id: 'yesCareTeamOption', label: 'Yes', value: 'yes' },
  { id: 'noCareTeamOption', label: 'No', value: 'no' },
];

export const HIPAA_OPTIONS = [
  { id: 'writtenCareTeamOption', label: 'Written', value: 'WRITTEN' },
  { id: 'verbalCareTeamOption', label: 'Verbal', value: 'VERBAL' },
];

export const HIPAA_CONSENT_OPTIONS = [
  { id: 'patientCareTeamOption', label: 'Patient', value: 'Patient' },
  { id: 'caregiverCareTeamOption', label: 'Caregiver', value: 'Caregiver' },
];

export const DEFAULT_CARE_TEAM = {
  id: null,
  category: '',
  type: '',
  isInEhr: EHR_OPTIONS[1].value,
  name: '',
  phoneNumber: '',
  hipaaConsentType: '',
  streetAddress: '',
  city: '',
  state: '',
  zipcode: '',
  note: '',
};

export const CATEGORY_CODES = {
  hipaaAuthorized: { value: 'HIPAA Authorized Contact', label: 'HIPAA Authorized Contact' },
  specialist: { value: 'Specialist', label: 'Specialist' },
  mentalHealthSpecialist: { value: 'Mental Health Specialist', label: 'Mental Health Specialist' },
  rehabilitation: { value: 'Rehabilitation', label: 'Rehabilitation' },
  pharmacy: { value: 'Pharmacy', label: 'Pharmacy' },
  socialWorker: { value: 'Social Worker', label: 'Social Worker' },
  vaHospital: { value: 'VA Hospital', label: 'VA Hospital' },
  other: { value: 'Other', label: 'Other' },
};

const TYPE_CODES = {
  spouse: { value: 'Spouse', label: 'Spouse' },
  son: { value: 'Son', label: 'Son' },
  daughter: { value: 'Daughter', label: 'Daughter' },
  relative: { value: 'Relative', label: 'Relative' },
  friend: { value: 'Friend', label: 'Friend' },
  caregiver: { value: 'Caregiver', label: 'Caregiver' },
  lifePartner: { value: 'Life Partner', label: 'Life Partner' },
  endocrinology: { value: 'Endocrinology', label: 'Endocrinology' },
  neurology: { value: 'Neurology', label: 'Neurology' },
  cardiology: { value: 'Cardiology', label: 'Cardiology' },
  oncology: { value: 'Oncology', label: 'Oncology' },
  ophthalmology: { value: 'Ophthalmology', label: 'Ophthalmology' },
  gastroenterology: { value: 'Gastroenterology', label: 'Gastroenterology' },
  urology: { value: 'Urology', label: 'Urology' },
  dermatology: { value: 'Dermatology', label: 'Dermatology' },
  orthopedics: { value: 'Orthopedics', label: 'Orthopedics' },
  gynecology: { value: 'Gynecology', label: 'Gynecology' },
  allergyImmunology: { value: 'Allergy/Immunology', label: 'Allergy/Immunology' },
  infectious: { value: 'Infectious Disease', label: 'Infectious Disease' },
  other: { value: 'Other', label: 'Other' },
  psychiatrist: { value: 'Psychiatrist', label: 'Psychiatrist' },
  psychologist: { value: 'Psychologist', label: 'Psychologist' },
  therapist: { value: 'Therapist', label: 'Therapist' },
  physical: { value: 'Physical Therapy', label: 'Physical Therapy' },
  occupational: { value: 'Occupational Therapy', label: 'Occupational Therapy' },
  speech: { value: 'Speech Therapy', label: 'Speech Therapy' },
  respiratory: { value: 'Respiratory Therapy', label: 'Respiratory Therapy' },
  primaryPreferred: { value: 'Primary/Preferred', label: 'Primary/Preferred' },
  secondaryAlternate: { value: 'Secondary/Alternate', label: 'Secondary/Alternate' },
  specialtyCompounding: { value: 'Specialty/Compounding', label: 'Specialty/Compounding' },
  community: { value: 'Community Health Worker', label: 'Community Health Worker' },
  addiction: { value: 'Addiction/Substance Abuse Social Worker', label: 'Addiction/Substance Abuse Social Worker' },
  case: { value: 'Case Manager', label: 'Case Manager' },
  clinical: { value: 'Clinical Social Worker', label: 'Clinical Social Worker' },
  disability: { value: 'Disability Social Worker', label: 'Disability Social Worker' },
  geriatric: { value: 'Geriatric Social Worker', label: 'Geriatric Social Worker' },
  trauma: { value: 'Trauma Social Worker', label: 'Trauma Social Worker' },
  dietitian: { value: 'Dietitian', label: 'Dietitian' },
  infusion: { value: 'Infusion Nurse', label: 'Infusion Nurse' },
  pcp: { value: 'PCP', label: 'PCP' },
};

export const CATEGORY_OPTIONS = [
  { ...CATEGORY_CODES.hipaaAuthorized },
  { ...CATEGORY_CODES.specialist },
  { ...CATEGORY_CODES.mentalHealthSpecialist },
  { ...CATEGORY_CODES.rehabilitation },
  { ...CATEGORY_CODES.pharmacy },
  { ...CATEGORY_CODES.socialWorker },
  { ...CATEGORY_CODES.vaHospital },
  { ...CATEGORY_CODES.other },
];

export const getTypeOptions = (category) => {
  switch (category) {
    case CATEGORY_CODES.hipaaAuthorized.value:
      return [
        { ...TYPE_CODES.spouse },
        { ...TYPE_CODES.son },
        { ...TYPE_CODES.daughter },
        { ...TYPE_CODES.relative },
        { ...TYPE_CODES.friend },
        { ...TYPE_CODES.caregiver },
        { ...TYPE_CODES.lifePartner },
      ];
    case CATEGORY_CODES.specialist.value:
      return [
        { ...TYPE_CODES.endocrinology },
        { ...TYPE_CODES.neurology },
        { ...TYPE_CODES.cardiology },
        { ...TYPE_CODES.oncology },
        { ...TYPE_CODES.ophthalmology },
        { ...TYPE_CODES.gastroenterology },
        { ...TYPE_CODES.urology },
        { ...TYPE_CODES.dermatology },
        { ...TYPE_CODES.orthopedics },
        { ...TYPE_CODES.gynecology },
        { ...TYPE_CODES.allergyImmunology },
        { ...TYPE_CODES.infectious },
        { ...TYPE_CODES.other },
      ];
    case CATEGORY_CODES.mentalHealthSpecialist.value:
      return [
        { ...TYPE_CODES.psychiatrist },
        { ...TYPE_CODES.psychologist },
        { ...TYPE_CODES.therapist },
        { ...TYPE_CODES.other },
      ];
    case CATEGORY_CODES.rehabilitation.value:
      return [
        { ...TYPE_CODES.physical },
        { ...TYPE_CODES.occupational },
        { ...TYPE_CODES.speech },
        { ...TYPE_CODES.respiratory },
        { ...TYPE_CODES.other },
      ];
    case CATEGORY_CODES.pharmacy.value:
      return [
        { ...TYPE_CODES.primaryPreferred },
        { ...TYPE_CODES.secondaryAlternate },
        { ...TYPE_CODES.specialtyCompounding },
        { ...TYPE_CODES.other },
      ];
    case CATEGORY_CODES.socialWorker.value:
      return [
        { ...TYPE_CODES.community },
        { ...TYPE_CODES.addiction },
        { ...TYPE_CODES.case },
        { ...TYPE_CODES.clinical },
        { ...TYPE_CODES.disability },
        { ...TYPE_CODES.geriatric },
        { ...TYPE_CODES.trauma },
        { ...TYPE_CODES.other },
      ];
    case CATEGORY_CODES.vaHospital.value:
      return [
        { ...TYPE_CODES.pcp },
        { ...TYPE_CODES.other },
      ];
    case CATEGORY_CODES.other.value:
      return [
        { ...TYPE_CODES.dietitian },
        { ...TYPE_CODES.infusion },
        { ...TYPE_CODES.other },
      ];
    default:
      return [];
  }
};
