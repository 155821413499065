export function AddRequestToProgress() {
  return {
    type: 'ADD_REQUEST_TO_PROGRESS',
  };
}

export function RemoveRequestFromProgress() {
  return {
    type: 'REMOVE_REQUEST_FROM_PROGRESS',
  };
}
