// libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Modal from 'react-modal';
// actions
import ShowNotification from '../../../actions/notification';
import { SetBillingPhysiciansList } from '../../../actions/physicians';
// constant
import {
  NOTIFICATION_TYPE,
  DIALOG_STYLES,
} from '../../../constants/constants';
// services
import { updateBillablePhysicians, reassignBillablePhysician } from '../../../services/settings';
import { getBillingPhysicians } from '../../../services/providers';
// view
import EditableInput from '../../base/EditableInput';

const CONFIRMATION_MESSAGE = 'Setting the provider to non-billable will clear this provider from all current patients for which they are the billing provider, do you want to continue?';
const ACTIVE_CONFIRMATION_MESSAGE = 'This provider is a billable provider and may have patients assigned. Are you sure you want to continue?';
const ACTIVE_FALSE_NOTIFICATION = 'This provider must be active to be billable';
const NPI_ERROR_CODE = 'NPI_ERROR';
const EHRID_ERROR_CODE = 'EHRID_ERROR';

export function prepareProvidersList(list = []) {
  return [
    ...list.map(user => ({
      ...user,
      value: user.id,
      label: `${user.firstName || ''} ${user.lastName || ''}`,
    })),
  ];
}
export class BillableProviderRow extends Component {
  refs = {};

  promises = [];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      physician: this.props.physician,
      prevPhysician: {},
      isUnassignCheck: true,
      isModalOpen: false,
      reassignPhysician: null,
    };
    this.openReassignModal = this.openReassignModal.bind(this);
    this.closeReassignModal = this.closeReassignModal.bind(this);
    this.handleToggleChangeBillableProvider = this.handleToggleChangeBillableProvider.bind(this);
    this.handleClickReassignBillableModal = this.handleClickReassignBillableModal.bind(this);
    this.handleReassignSelect = this.handleReassignSelect.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises)
      .forEach((key) => {
        this.promises[key].cancel();
      });
  }

  confirmationPopup = (isActive = false) => {
    const { physician: { billable, ehrId, npi } } = this.state;

    if (billable && npi && ehrId) {
      return window.confirm(isActive ? ACTIVE_CONFIRMATION_MESSAGE : CONFIRMATION_MESSAGE);
    }

    return true;
  };

  notificationPopup = () => alert(ACTIVE_FALSE_NOTIFICATION);

  loadBillingPhysiciansList = () => {
    const { setBillingPhysiciansList } = this.props;

    const promiseName = 'getBillingPhysicians';
    const getBillingPhysiciansRequest = getBillingPhysicians();
    const getBillingPhysiciansPromise = getBillingPhysiciansRequest.promise;
    this.promises[promiseName] = getBillingPhysiciansRequest;

    return getBillingPhysiciansPromise.then((data) => {
      delete this.promises[promiseName];

      if (data) {
        setBillingPhysiciansList(data);
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      const { showNotification } = this.props;

      showNotification({
        message: 'Could not load physicians list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  changeBillablePhysician = () => {
    const {
      loading, physician: {
        billable, ehrId, npi, locationId,
      },
    } = this.state;
    const {
      showNotification, isEhrIdRequired, isNpiRequired, isLocationRequired,
    } = this.props;

    if (loading) {
      return;
    }

    if (!this.confirmationPopup()) {
      return;
    }

    if (!billable) { // we are in the process of toggling the provider from not billable to billable
      this.validate();

      if (isNpiRequired && !npi) {
        showNotification({
          message: 'NPI is required in order to make a provider billable.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
        return;
      }

      if (isEhrIdRequired && !ehrId) {
        showNotification({
          message: 'EHR ID is required in order to make a provider billable.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });

        return;
      }

      if (isLocationRequired && (locationId === null || locationId === undefined)) {
        showNotification({
          message: 'Location is required in order to make a provider billable.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });

        return;
      }
    }

    this.setState(prevState => ({
      ...prevState,
      physician: {
        ...prevState.physician,
        billable: !prevState.physician.billable,
      },
    }), this.savePhysician);
  };

  changeActivePhysician = () => {
    if (this.state.loading) {
      return;
    }

    if (!this.confirmationPopup(true)) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      physician: {
        ...prevState.physician,
        active: !prevState.physician.active,
        billable: (!prevState.physician.billable)
        || (prevState.physician.active === false && prevState.physician.billable === true)
          ? prevState.physician.billable : !prevState.physician.billable,
      },
    }), () => { this.savePhysician(); });
  };

  doReassignBillablePhysician = () => {
    if (this.state.loading) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      physician: {
        ...prevState.physician,
        billable: !prevState.physician.billable,
      },
    }), this.saveReassignPhysician);
  };

  updateField = (field, value, isValid) => {
    this.setState(prevState => ({
      ...prevState,
      prevPhysician: { ...prevState.physician },
      physician: {
        ...prevState.physician,
        [field]: value,
      },
    }), () => { if (isValid) { this.savePhysician(); } });
  };

  savePhysician = () => {
    const {
      loading, physician: {
        id, billable, ehrId, npi, active, locationId,
      },
      prevPhysician,
    } = this.state;
    const {
      showNotification, updateCallback,
    } = this.props;

    if (loading) {
      return;
    }

    this.validate();

    this.setState({
      loading: true,
    });

    const promiseName = 'updateBillablePhysicians';
    const updateBillablePhysiciansRequest = updateBillablePhysicians(id, {
      billable, ehrId, npi, active, locationId,
    });

    const updateBillablePhysiciansPromise = updateBillablePhysiciansRequest.promise;
    this.promises[promiseName] = updateBillablePhysiciansRequest;

    updateBillablePhysiciansPromise.then(async () => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });


      await this.loadBillingPhysiciansList();

      if (updateCallback) {
        updateCallback();
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      let errorMessage = '';
      if (error.status === 400) {
        errorMessage = Object.entries(error.data.errors).join(' ').replace(/,/g, ': ');
      }

      if (error.status === 400 && error.data.errors.code === NPI_ERROR_CODE) {
        errorMessage = error.data.errors.message;
        this.setState(prevState => ({
          physician: { ...prevState.physician, npi: prevPhysician.npi },
        }));
      }

      if (error.status === 400 && error.data.errors.code === EHRID_ERROR_CODE) {
        errorMessage = error.data.errors.message;
        this.setState(prevState => ({
          physician: { ...prevState.physician, ehrId: prevPhysician.ehrId },
        }));
      }

      showNotification({
        message: errorMessage,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      this.setState({
        loading: false,
      });
    });
  };

  saveReassignPhysician = () => {
    const {
      loading, physician: { id: oldId }, reassignPhysician,
    } = this.state;
    const { updateCallback, showNotification } = this.props;

    if (loading) {
      return;
    }

    if (reassignPhysician && Object.keys(reassignPhysician).length === 0) {
      return;
    }

    this.setState({
      loading: true,
    });

    const promiseName = 'reassignBillablePhysician';
    const reassignBillablePhysicianRequest = reassignBillablePhysician(oldId, reassignPhysician.id);
    const reassignBillablePhysicianPromise = reassignBillablePhysicianRequest.promise;
    this.promises[promiseName] = reassignBillablePhysicianRequest;

    reassignBillablePhysicianPromise.then(async () => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });


      await this.loadBillingPhysiciansList();

      this.closeReassignModal();

      if (updateCallback) {
        updateCallback();
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not reassign physician.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });

      this.closeReassignModal();

      this.setState({
        loading: false,
      });
    });
  };

  validate = () => {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      result = this.refs[key].validate() && result;
    });

    return result;
  };

  saveRef = (name, ref) => {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  };

  handleReassignSelect(reassignPhysician) {
    this.setState({
      reassignPhysician,
    });
  }

  handleToggleChangeBillableProvider(isUnassignCheck) {
    this.setState({
      isUnassignCheck,
    });
  }

  openReassignModal() {
    this.setState({
      reassignPhysician: null,
      isUnassignCheck: true,
    }, () => this.setState({
      isModalOpen: true,
    }));
  }

  closeReassignModal() {
    this.setState({
      reassignPhysician: null,
      isUnassignCheck: true,
    }, () => this.setState({
      isModalOpen: false,
    }));
  }

  handleClickReassignBillableModal() {
    const { isUnassignCheck } = this.state;

    if (isUnassignCheck) {
      this.changeBillablePhysician();
    } else {
      this.doReassignBillablePhysician();
    }
  }

  renderOptionsBillableProvider() {
    const { isUnassignCheck, reassignPhysician, physician } = this.state;
    const { billingPhysiciansList } = this.props;
    let providersOptions = prepareProvidersList(billingPhysiciansList);
    providersOptions = providersOptions.filter(provider => provider.id !== physician.id);
    providersOptions = providersOptions.filter((provider) => {
      const physicianIsFqhc = physician.locationId !== null ? physician.locationFqhc : false;
      const providerIsFqhc = provider.isFqhc !== null ? provider.isFqhc : false;
      return providerIsFqhc === physicianIsFqhc;
    });

    return (
      <Fragment>
        <div
          key="option-billable-unassign"
          className={`radio-container ${!isUnassignCheck ? 'radio-container-inactive' : ''}`}
          onClick={() => this.handleToggleChangeBillableProvider(true)}
          data-test="patientActivityStatusOption"
        >
          <div
            id="activity-status-unassign"
            className={`gray-checkbox ${isUnassignCheck ? 'checked' : ''}`}
          />
          <p className="option-text">Unassign all patients for whom this provider is the billing provider</p>
        </div>
        <div
          key="option-billable-reassign"
          className={`radio-container ${isUnassignCheck ? 'radio-container-inactive' : ''}`}
          onClick={() => this.handleToggleChangeBillableProvider(false)}
          data-test="patientActivityStatusOption"
        >
          <div
            id="activity-status-reassign"
            className={`gray-checkbox ${!isUnassignCheck ? 'checked' : ''}`}
          />
          <p className="option-text">
            Reassign the patients for whom this provider is the billing provider to:
          </p>
        </div>
        { !isUnassignCheck && (
          <Select
            className="select-billable-provider"
            name="newProviderId"
            placeholder="Search a new provider"
            noResultsText="Providers not found..."
            clearable={false}
            searchable
            value={reassignPhysician}
            options={providersOptions}
            onChange={this.handleReassignSelect}
          />
        )}
      </Fragment>
    );
  }

  renderReassignBillableProviderModal() {
    const {
      isUnassignCheck, reassignPhysician,
      isModalOpen, physician: { firstName, lastName },
    } = this.state;
    const fullName = `${firstName || ''} ${lastName || ''}`.replace(/  +/g, ' ');
    return (
      <Modal
        isOpen={isModalOpen}
        style={DIALOG_STYLES}
        onRequestClose={this.closeReassignModal}
        contentLabel="reassignBillableProvider"
      >
        <div className="simple-dialog">
          <div className="dialog-title no-border">
            <p className="dialog-text-center">{`Set ${fullName} to not billable and...`}</p>
            <button
              className="close-icon i-close"
              onClick={this.closeReassignModal}
              type="button"
            />
          </div>
          <div className="simple-dialog-body">
            <div className="simple-dialog-content">
              {this.renderOptionsBillableProvider()}
            </div>
            <footer className="dialog-footer row buttons-row-no-border">
              <button
                className="button action-button"
                onClick={this.closeReassignModal}
                type="button"
              >
              CANCEL
              </button>
              <button
                className="button active-button"
                onClick={this.handleClickReassignBillableModal}
                type="button"
                disabled={!isUnassignCheck && !reassignPhysician}
              >
              SAVE
              </button>
            </footer>
          </div>
        </div>
      </Modal>
    );
  }

  renderLocation() {
    const {
      locationId, locationName,
    } = this.state.physician;
    const { locationList, isLocationEditable } = this.props;

    if (locationList && locationList.length > 0) {
      if (isLocationEditable) {
        return (
          <td className="physician-name" id="editableLocationValue">
            <Select
              className="small-select"
              name="providerLocation"
              matchProp="label"
              removeSelected={false}
              value={locationId}
              searchable
              options={locationList}
              onChange={location => this.updateField('locationId', (location ? location.value : null), true)}
            />
          </td>
        );
      }

      return (<td className="physician-name" id="staticLocationValue">{locationName}</td>);
    }

    return '';
  }

  renderNpi() {
    const { isNpiRequired } = this.props;
    const { npi } = this.state.physician;

    if (isNpiRequired) {
      return (
        <td className="physician-input" id="npiField">
          <EditableInput
            type="text"
            submitCallback={this.updateField}
            fieldKey="npi"
            initialValue={npi || ''}
            ref={this.saveRef.bind(this, 'npi')}
          />
        </td>
      );
    }

    return '';
  }

  renderEhrId() {
    const { ehrId } = this.state.physician;
    const { isEhrIdRequired } = this.props;

    if (isEhrIdRequired) {
      return (
        <td className="physician-input" id="ehrIdField">
          <EditableInput
            type="text"
            submitCallback={this.updateField}
            fieldKey="ehrId"
            initialValue={ehrId || ''}
            ref={this.saveRef.bind(this, 'enrId')}
          />
        </td>
      );
    }

    return '';
  }

  renderProviderGroup() {
    const { assignerCode } = this.state.physician;
    const { isProviderGroupEnabled } = this.props;

    if (isProviderGroupEnabled) {
      return (
        <td className="provider-group">{assignerCode || ''}</td>
      );
    }

    return '';
  }

  render() {
    const {
      firstName, lastName, billable, active,
    } = this.state.physician;

    const billableAction = billable ? this.openReassignModal : this.changeBillablePhysician;
    const onClickBillableAction = active ? billableAction : this.notificationPopup;

    return (
      <Fragment>
        {this.renderReassignBillableProviderModal()}
        <tr className="billable-providers-table-row">
          <td className="physician-name">{firstName}</td>
          <td className="physician-name">{lastName}</td>
          {this.renderProviderGroup()}
          <td className="physician-billable" onClick={onClickBillableAction}>{billable ? 'yes' : 'no'}</td>
          {this.renderNpi()}
          {this.renderEhrId()}
          {this.renderLocation()}
          <td className="physician-active" onClick={this.changeActivePhysician}>{active ? 'yes' : 'no'}</td>
        </tr>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    billingPhysiciansList: (state.physicians && state.physicians.billingPhysiciansList
      ? state.physicians.billingPhysiciansList : []),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBillingPhysiciansList: billingPhysiciansListData => dispatch(
      SetBillingPhysiciansList(billingPhysiciansListData),
    ),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillableProviderRow);
