// libraries
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
// services
import { logoutUser } from '../../../services/login';
import { logoutGlobalUser } from '../../../services/globalServices/login';

export function AccessDenied() {
  const { globalTenant } = useSelector(state => state.tenant);
  const { keycloak } = useKeycloak();
  return (
    <div className="row justify-content-center h-100">
      <div className="col-3 d-flex-center flex-column">
        <h1 className="text-ccm-lipstick display-2 mb-3 p-0">403</h1>
        <h1 className="mb-4 p-0">Access is denied</h1>
        <p className="mb-4">The page you tried to open is not available.</p>
        <Button
          variant="outline-primary"
          className="py-1 border-0"
          onClick={() => (globalTenant ? logoutGlobalUser(keycloak) : logoutUser(keycloak))}
          data-test="403-signInButton"
        >
          Go to sign in page
        </Button>
      </div>
    </div>
  );
}

export default AccessDenied;
