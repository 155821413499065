// Libraries
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
// Views
import UserActivityTable from './activity/UserActivityTable';
import Pager from '../patients/Pager';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getUserActivities, getUserActivitiesExcel, getAllUsersData } from '../../services/administration';
// validateDate
import { downloadFile, isObjectEmpty } from '../../services/helpers';
// Constants
import { NOTIFICATION_TYPE, DATE_FORMAT } from '../../constants/constants';

const USER_STATUS = {
  enabled: 'Enabled',
  all: 'All',
};

const DEFAULT_SEARCH_PARAMS = {
  currentPage: 0,
  sort: {
    key: 'DATE',
    reverse: true,
  },
};

const USER_STATE = {
  active: 'ACTIVE',
};

// exported for testing purposes
export function prepareSearchQuery(params) {
  const {
    sort, dateRange, currentPage, ...result
  } = params;

  if (sort && sort.key) {
    result.sortBy = sort.key;
    result.sortOrder = sort.reverse ? 'DESC' : 'ASC';
  }

  if (!isObjectEmpty(dateRange)) {
    if (dateRange.from) {
      result.fromDate = `${dateRange.from} 00:00`;
    }

    if (dateRange.to) {
      result.toDate = `${dateRange.to} 23:59`;
    }
  }

  result.pageNumber = currentPage;

  return result;
}

export const DAYS_COUNT = 1;
export const MAX_DATE_RANGE = 90;

export const UserActivity = () => {
  const { count: loading } = useSelector(state => state.requestsInProgress);
  const [activities, setActivities] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [params, setParams] = useState({
    dateRange: {
      from: moment.utc().subtract(DAYS_COUNT, 'd').format(DATE_FORMAT.FULL_SERVER),
      to: moment.utc().format(DATE_FORMAT.FULL_SERVER),
    },
    userId: '',
    patientId: '',
    ...DEFAULT_SEARCH_PARAMS,
  });
  const [users, setUsers] = useState({});
  const [userStatus, setUserStatus] = useState(USER_STATUS.enabled);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const getUsers = async () => {
    const getTeamMembersRequest = getAllUsersData();
    const getTeamMembersPromise = getTeamMembersRequest.promise;
    try {
      const data = await getTeamMembersPromise;
      delete getTeamMembersRequest.promise;
      const status = userStatus === USER_STATUS.enabled ? USER_STATE.active : false;
      const filteredUsersData = status
        ? data.users && data.users.filter(usr => usr.state === status) : data.users;
      const filteredData = { users: filteredUsersData };
      setUsers(filteredData);
      return filteredData;
    } catch (error) {
      delete getTeamMembersRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return {};
      }
      showNotification({
        message: 'Could not load user activity table.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
    return {};
  };

  const getUserActivityData = async () => {
    const status = (userStatus === USER_STATUS.all) ? null : userStatus;

    const getUserActivitiesRequest = getUserActivities(prepareSearchQuery({ ...params, status }));
    const getUserActivitiesPromise = getUserActivitiesRequest.promise;
    try {
      const data = await getUserActivitiesPromise;
      delete getUserActivitiesRequest.promise;
      setActivities(data.userActivitiesInfo);
      setTotalPages(data.totalPages);
    } catch (error) {
      delete getUserActivitiesRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Can\'t load user activity table.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const downloadUserActivity = async () => {
    if (loading) return;
    const status = (userStatus === USER_STATUS.all) ? null : userStatus;

    const getUserActivitiesRequest = getUserActivitiesExcel(prepareSearchQuery({
      ...params,
      status,
    }));
    const getUserActivitiesPromise = getUserActivitiesRequest.promise;
    try {
      const resp = await getUserActivitiesPromise;
      delete getUserActivitiesRequest.promise;
      downloadFile(resp.data, resp.fileName);
    } catch (error) {
      delete getUserActivitiesRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Can\'t download report.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const goToPage = (page) => {
    if (loading) return;
    setParams(prevParams => ({
      ...prevParams,
      currentPage: page,
    }));
  };

  const updateDateRange = (key, value) => {
    const { dateRange } = params;

    if (!value || (dateRange[key] && dateRange[key] === value)) {
      return;
    }

    setParams(prevParams => ({
      ...prevParams,
      currentPage: 0,
      dateRange: {
        ...params.dateRange,
        [key]: value,
        changedDate: key,
      },
    }));
  };

  const validDate = (date, property) => {
    const isDateValid = date ? moment(date).format(DATE_FORMAT.FULL_SERVER) : null;
    updateDateRange(property, isDateValid);
  };

  const debouncedHandleDate = useMemo(() => _debounce(validDate, 1000), [params]);

  const renderDateRange = () => {
    const { dateRange } = params;
    return (
      <div className="row mx-0">
        <div className="col-3 py-3 px-0 d-flex-center" data-test="userActivity_fromDate">
          <span className="text-ccm-bali-hai">From:</span>
          <DatePicker
            onChange={date => debouncedHandleDate(date, 'from')}
            maxDate={(dateRange.to && moment(dateRange.to).toDate()) || moment().toDate()}
            minDate={
              dateRange.to
                ? moment(dateRange.to).subtract(MAX_DATE_RANGE, 'd').toDate()
                : moment().subtract(MAX_DATE_RANGE, 'd').toDate()
            }
            selected={moment(dateRange.from).toDate()}
            disabled={!!loading}
            required
            data-test="userActivity_datePickerFrom"
          />
        </div>

        <div className="col-3 py-3 px-0 d-flex-center" data-test="userActivity_toDate">
          <span className="text-ccm-bali-hai">To:</span>
          <DatePicker
            onChange={date => debouncedHandleDate(date, 'to')}
            minDate={dateRange.from && moment(dateRange.from).toDate()}
            maxDate={
              dateRange.from && !moment(dateRange.from).add(MAX_DATE_RANGE, 'd')
                .isAfter(moment())
                ? moment(dateRange.from).add(MAX_DATE_RANGE, 'd').toDate()
                : moment().toDate()
            }
            selected={moment(dateRange.to).toDate()}
            disabled={!!loading}
            required
            data-test="userActivity_datePickerTo"
          />
        </div>
        <div className="col-6 align-self-center text-right">
          <Button
            variant="primary"
            onClick={downloadUserActivity}
            disabled={loading}
            data-test="userActivity_downloadReport"
          >
            Download
          </Button>
        </div>
      </div>
    );
  };

  const handleChangeParams = (event) => {
    const paramsUpdated = {
      ...params,
      currentPage: 0,
      [event.target.name]: event.target.value,
    };

    Object.keys(paramsUpdated).forEach((key) => {
      if (key === 'currentPage') return;
      if (!paramsUpdated[key]) {
        delete paramsUpdated[key];
      }
    });
    setParams(paramsUpdated);
  };
  const debouncedHandleChange = useMemo(() => _debounce(handleChangeParams, 750), []);

  const handleChange = (event) => {
    event.persist();
    debouncedHandleChange(event);
  };

  const onKeyUp = (event) => {
    event.preventDefault();
    if (event.keyCode === 13) {
      handleChange(event);
    }
  };

  const setFilterByUserStatus = (event) => {
    const { value } = event.target;

    if (loading || (params.userStatus === value)) {
      return;
    }

    setParams({
      ...params,
      ...DEFAULT_SEARCH_PARAMS,
      userId: '',
    });
    setUserStatus(value);
  };

  const handleSelect = (field, item) => {
    const paramsUpdated = {
      ...params,
      currentPage: 0,
      [field]: item ? item.value : null,
    };

    Object.keys(paramsUpdated).forEach((key) => {
      if (paramsUpdated[key] === undefined) {
        delete paramsUpdated[key];
      }
    });

    setParams(paramsUpdated);
  };

  const prepareUsersList = (list = []) => [
    ...list.filter(n => n).map(user => ({
      ...user,
      value: user.id,
      label: `${user.lastName ? `${user.lastName},` : ''} ${user.firstName ? user.firstName : ''} ${user.username ? `(${user.username})` : ''}`,
    })),
  ];

  const renderRadioButton = (status) => {
    const isRadioChecked = (userStatus === status);
    const labelClassName = `radio-container ${loading ? 'radio-container-disabled' : ''} ${!isRadioChecked
      ? 'radio-container-inactive'
      : ''}`;
    return (
      <label className={labelClassName} key={status}>
        <input
          type="radio"
          name="userStatus"
          value={status}
          onClick={loading ? () => { } : setFilterByUserStatus}
          defaultChecked={isRadioChecked}
          data-test="userActivity_RadioButton"
        />
        <div className="radio-icon" />
        {status}
      </label>
    );
  };

  const renderUsernameField = () => (
    <div className="col-4 px-0 d-flex-center" data-test="userActivity_username">
      <span className="text-ccm-bali-hai mr-2 align-self-end">Username:</span>
      <Select
        className="small-select"
        name="userId"
        placeholder="Users"
        noResultsText="Users not found..."
        value={params.userId}
        options={prepareUsersList(users ? users.users : [])}
        searchable
        onChange={ev => handleSelect('userId', ev)}
        disabled={!!loading}
        data-test="userActivity_usersSelect"
      />
    </div>
  );

  useEffect(() => {
    getUsers();
  }, [userStatus]);

  useEffect(() => {
    getUserActivityData();
  }, [params]);

  useEffect(() => () => {
    debouncedHandleDate.cancel();
    debouncedHandleChange.cancel();
  }, []);

  return (
    <div className="user-activity h-100 overflow-auto pr-3">
      <h4 className="text-uppercase text-left my-3" data-test="userActivity_pageTitle">User Activities Report</h4>
      <div className="content" data-test="userActivity_content">
        {renderDateRange()}
        <div className="row mx-0">
          {renderUsernameField()}
          <div className="col-8 pl-5 text-left d-flex">
            <span className="text-ccm-bali-hai mr-2 align-self-end">Patient ID:</span>
            <input
              className="form-control w-25"
              type="text"
              name="patientId"
              onKeyUp={onKeyUp}
              onChange={handleChange}
              disabled={loading}
              data-test="userActivity_patientId"
            />
          </div>
          <div className="col-12 text-left px-0 pt-4 d-flex" data-test="userActivity_userStatusRadio">
            <span className="text-ccm-bali-hai mr-4">User Status:</span>
            {Object.keys(USER_STATUS).map(
              status => renderRadioButton(USER_STATUS[status]),
            )}
          </div>
        </div>
        <div>
          { activities && activities.length > 0 ? <UserActivityTable data={activities} /> : 'No activities found' }
        </div>
        { (
          <Pager
            totalPages={totalPages}
            callback={goToPage}
            maxShownCount={7}
            currentPage={params.currentPage}
            data-test="userActivity_pager"
          />
        )}
      </div>
    </div>
  );
};

export default UserActivity;
