// Libraries
import React, { useState, useContext, Fragment } from 'react';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Accordion, Card, Button, Form,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdateLetterBatch } from '../../../../actions/letterBatch';
// Services
import { getLetterBatchHistory, getLetterBatchFile } from '../../../../services/patient';
import { downloadFile } from '../../../../services/helpers';
// Constants
import {
  NOTIFICATION_TYPE, DATE_FORMAT, EMPTY_STRING, BATCH_STATUS_OPTIONS, BATCH_METHODS, BATCH_STATUSES,
} from '../../../../constants/constants';
// Views
import LetterBatchModal from './LetterBatchModal';

export const BatchHistory = (props) => {
  const { tags } = props;
  const [idFilter, setIdFilter] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);

  const downloadReport = async (batchId) => {
    const { showNotification } = props;

    const getBillingExcelRequest = getLetterBatchFile(batchId);
    const getBillingExcelPromise = getBillingExcelRequest.promise;

    try {
      delete getBillingExcelPromise.promise;
      const resp = await getBillingExcelPromise;
      downloadFile(resp.data, resp.fileName);
    } catch (error) {
      delete getBillingExcelPromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Can\'t download report.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const fetchLetterBatchHistory = (batchId) => {
    const {
      letterBatch, letterBatch: { letterBatchHistory = {} }, showNotification, updateLetterBatch,
    } = props;
    if (letterBatchHistory[batchId]) return {};

    const fetchRequest = getLetterBatchHistory(batchId);
    const fetchPromise = fetchRequest.promise;

    return fetchPromise.then((data) => {
      delete fetchPromise.promise;
      updateLetterBatch({
        ...letterBatch,
        letterBatchHistory: { ...letterBatchHistory, [batchId]: data },
      });
    }).catch((error) => {
      delete fetchPromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this letter batch',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleEditBatchLetter = (batchLetter) => {
    setSelectedBatch(batchLetter);
    fetchLetterBatchHistory(batchLetter.batchId);
    setIsBatchModalOpen(true);
  };

  const filterBatchById = batchList => batchList.filter(el => el.batchId === Number(idFilter));

  const getDateString = date => moment.utc(date).format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS);

  const getBatchStatus = (status) => {
    const el = BATCH_STATUS_OPTIONS.find(({ value }) => value === status);
    if (el) return el.label;
    return status;
  };

  const getBatchQuery = (b) => {
    let q = `${b.patientStatus ? `Patient Status = ${b.patientStatus}` : ''}`;
    q += `${b.minAge ? `${q !== '' ? ' & ' : ''} Minimun Age = ${b.minAge}` : ''}`;
    q += `${b.enrolledDate ? `${q !== '' ? ' & ' : ''} Enrolled Date = ${moment.utc(b.enrolledDate).format(DATE_FORMAT.FULL)}` : ''}`;
    q += `${(b.physicians && !!b.physicians.length) ? `${q !== '' ? ' & ' : ''} Physician =${b.physicians.map(p => ` ${p.firstName ? `${p.firstName}` : ''}${p.lastName ? ` ${p.lastName}` : ''}`)}` : ''}`;
    q += `${(b.insurances && !!b.insurances.length) ? `${q !== '' ? ' & ' : ''} Insurance =${b.insurances.map(i => ` ${i}`)}` : ''}`;
    q += `${(b.languages && !!b.languages.length) ? `${q !== '' ? ' & ' : ''} Language =${b.languages.map(l => ` ${l}`)}` : ''}`;
    q += `${(b.chronicConditions && !!b.chronicConditions.length) ? `${q !== '' ? ' & ' : ''} Conditions =${b.chronicConditions.map(c => ` ${c.name}`)}` : ''}`;
    q += `${b.limit ? `${q !== '' ? ' & ' : ''} Patient Count = ${b.limit}` : ''}`;
    q += `${b.reportingAvailable ? `${q !== '' ? ' & ' : ''} Make this report available in Reports view = ${b.reportingAvailable}` : ''}`;
    q += `${b.minRafUplift ? `${q !== '' ? ' & ' : ''} Minimum RAF Score = ${b.minRafUplift}` : ''}`;
    q += `${(b.tags && !!b.tags.length && tags && !!tags.length) ? `${q !== '' ? ' & ' : ''} Patient Attributes =${b.tags.map(l => ` ${tags.find(tag => tag.id === l).displayName}`)}` : ''}`;

    return q.length ? `${q};` : EMPTY_STRING;
  };

  const renderEmptyRow = () => (
    <tr data-test="batchHistory_emptyMsg">
      <td colSpan="7" className="p-2 border-0">
        {idFilter ? `No letter batches found for ID: ${idFilter}` : 'No letter batches have been created.'}
      </td>
    </tr>);

  const renderBatchActivity = (batchId) => {
    const { letterBatch: { letterBatchHistory = {} } } = props;
    const historyRecord = letterBatchHistory[batchId];
    if (historyRecord) {
      return (
        <div className="border p-2">
          {historyRecord.map(el => (
            <div key={`batch__activity__row-${el.id}`} className="d-flex">
              <span className="py-1 batch__date" data-test="batchHistory_batchDate">{getDateString(el.statusChangeDate)}</span>
              <span className="py-1 batch__user" data-test="batchHistory_batchUser">{el.changedByUser || el.createdByUser}</span>
              <span className="py-1 batch__status" data-test="batchHistory_batchStatus">{getBatchStatus(el.status)}</span>
              <span className="py-1 batch__comment" data-test="batchHistory_batchComment">{el.comment}</span>
            </div>
          ))}
        </div>
      );
    }
    return '';
  };

  const isEditRow = batchLetter => batchLetter
    && batchLetter.batchStatus !== BATCH_STATUSES.CANCELED;

  const getFormValues = (batchLetter) => {
    const { letterBatch: { letterBatchHistory = {} }, setFormRetrivied } = props;

    let batchComment = '';
    const historyRecord = letterBatchHistory[batchLetter.batchId];
    const getPatientStatus = batchLetter.patientStatus && batchLetter.patientStatus.length > 0
      ? batchLetter.patientStatus.join(',') : null;
    const getPhysicians = batchLetter.physicians && batchLetter.physicians.length > 0
      ? batchLetter.physicians.map(physician => ({
        id: physician.id,
        value: physician.id,
        firstName: physician.firstName,
        lastName: physician.lastName,
        label: `${physician.lastName ? `${physician.lastName}, ` : ''}${physician.firstName ? physician.firstName : ''}`,
      }))
      : null;
    const getInsurances = batchLetter.insurances && batchLetter.insurances.length > 0
      ? batchLetter.insurances.map(insurance => ({ companyName: insurance })) : null;
    const getLanguages = batchLetter.languages && batchLetter.languages.length > 0
      ? batchLetter.languages.join(',') : null;
    const getChronicConditions = batchLetter.chronicConditions
      && batchLetter.chronicConditions.length > 0
      ? batchLetter.chronicConditions.map(condition => ({
        id: condition.id,
        value: condition.id,
        name: condition.name,
        label: condition.name,
      })) : null;
    const getEnrolledDate = batchLetter.enrolledDate
      ? moment(batchLetter.enrolledDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL) : '';

    if (historyRecord) {
      const item = historyRecord.find(record => record.status === BATCH_STATUSES.REQUESTED);
      batchComment = item && item.comment ? item.comment : '';
    }

    const formValues = {
      outreachType: batchLetter.outreachType || '',
      patientStatus: getPatientStatus,
      minAge: batchLetter.minAge || '',
      physicianIds: getPhysicians,
      insurances: getInsurances,
      languages: getLanguages,
      chronicConditions: getChronicConditions,
      enrolledDate: getEnrolledDate,
      reportingAvailable: batchLetter.reportingAvailable || false,
      comment: batchComment,
      limit: batchLetter.limit || '',
    };

    setFormRetrivied(formValues);
  };

  const renderBatchHistoryRows = () => {
    const { letterBatch: { letterBatchList = [] } } = props;
    const renderBatchList = idFilter ? filterBatchById(letterBatchList) : letterBatchList;

    if (renderBatchList && !!renderBatchList.length) {
      const sortedBatchListByDate = _reverse(
        _sortBy(renderBatchList, el => moment(el.createdDate)),
      );
      return sortedBatchListByDate.map((batchLetter) => {
        const getBatchMethod = method => BATCH_METHODS[method] || EMPTY_STRING;

        return (
          <tr key={`batch__row-${batchLetter.batchId}`} data-test="batchHistory_entryRow">
            <td colSpan="8" className="p-0">
              <Accordion>
                <Card className="border-0 rounded-0">
                  <Card.Header className="bg-light p-0">
                    <table className={`table mb-0${isEditRow(batchLetter) ? ' table-hover' : ''}`}>
                      <tbody>
                        <tr data-test="batchHistory_row">
                          <td className="batch__date" {...(isEditRow(batchLetter) && { onClick: () => handleEditBatchLetter(batchLetter) })} data-test="batchHistory_editBtn">
                            {batchLetter.createdDate && getDateString(batchLetter.createdDate)}
                          </td>
                          <td className="batch__recent-date" {...(isEditRow(batchLetter) && { onClick: () => handleEditBatchLetter(batchLetter) })} data-test="batchHistory_editBtn">
                            {batchLetter.updatedDate && getDateString(batchLetter.updatedDate)}
                          </td>
                          <td className="batch__type" {...(isEditRow(batchLetter) && { onClick: () => handleEditBatchLetter(batchLetter) })} data-test="batchHistory_editBtn">
                            {batchLetter.outreachType}
                          </td>
                          <td className="batch__status" {...(isEditRow(batchLetter) && { onClick: () => handleEditBatchLetter(batchLetter) })} data-test="batchHistory_editBtn">
                            {getBatchStatus(batchLetter.batchStatus)}
                          </td>
                          <td className="batch__id" {...(isEditRow(batchLetter) && { onClick: () => handleEditBatchLetter(batchLetter) })} data-test="batchHistory_editBtn">
                            {batchLetter.batchId || EMPTY_STRING}
                          </td>
                          <td className="batch__user" {...(isEditRow(batchLetter) && { onClick: () => handleEditBatchLetter(batchLetter) })} data-test="batchHistory_editBtn">
                            {batchLetter.createdByUser || EMPTY_STRING}
                          </td>
                          <td className="batch__method" {...(isEditRow(batchLetter) && { onClick: () => handleEditBatchLetter(batchLetter) })} data-test="batchHistory_editBtn">
                            {getBatchMethod(batchLetter.method)}
                          </td>
                          <td className="batch__action px-0">
                            {batchLetter.batchStatus !== BATCH_STATUSES.REQUESTED
                            && batchLetter.batchStatus !== BATCH_STATUSES.CANCELED && (
                            <Button variant="link-dark" className="mx-2 px-0" onClick={() => downloadReport(batchLetter.batchId)} data-test="batchHistory_downloadBtn">
                              <i className="bi-download" />
                            </Button>
                            )}
                            <CustomToggle eventKey="0" callback={() => fetchLetterBatchHistory(batchLetter.batchId)} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="flex-grow-1 mb-2" data-test="batchHistory_queryLanguages">
                        <span className="mr-2">Query:</span>
                        {getBatchQuery(batchLetter)}
                      </p>
                      <div className="d-flex-center-between">
                        <div>
                          <p className="flex-grow-1 mb-2" data-test="batchHistory_resultCount">
                            <span className="mr-2">Result Count:</span>
                            {batchLetter.resultCount !== null
                              ? batchLetter.resultCount : EMPTY_STRING}
                          </p>
                          <p className="flex-grow-1 mb-2">
                            Activity:
                          </p>
                        </div>
                        <Button
                          variant="success"
                          className="text-capitalize m-3"
                          onClick={() => getFormValues(batchLetter)}
                          data-test="batchHistory_cloneQueryBtn"
                        >
                          clone this query
                        </Button>
                      </div>
                      {renderBatchActivity(batchLetter.batchId)}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  return (
    <Fragment>
      <div className="patient-outreach-history card border-0">
        <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
          <span className="text-uppercase">Batch History</span>
          <Form inline className="position-absolute" style={{ right: 10 }}>
            <Form.Group>
              <Form.Label htmlFor="filterId">Filter by Batch ID:</Form.Label>
              <Form.Control
                id="filterId"
                name="filterId"
                type="number"
                aria-describedby="filterById"
                className="ml-2 bg-ccm-light-gray"
                onChange={ev => setIdFilter(ev && ev.target.value)}
                data-test="batchHistory_filterIdField"
              />
            </Form.Group>
          </Form>
        </div>
        <div className="card-body ccm-table-container px-0">
          <table className="table w-100 text-left">
            <thead>
              <tr>
                <th className="py-2 batch__date">Date</th>
                <th className="py-2 batch__recent-date">Most Recent Update</th>
                <th className="py-2 batch__type">Type</th>
                <th className="py-2 batch__status">Status</th>
                <th className="py-2 batch__id">Batch #</th>
                <th className="py-2 batch__user">User</th>
                <th className="py-2 batch__method">Method</th>
                <th className="py-2 batch__action px-0" />
              </tr>
            </thead>
            <tbody>
              {renderBatchHistoryRows()}
            </tbody>
          </table>
        </div>
      </div>
      <LetterBatchModal
        selectedBatch={selectedBatch}
        isModalOpen={isBatchModalOpen}
        setIsModalOpen={setIsBatchModalOpen}
      />
    </Fragment>
  );
};

export const CustomToggle = ({ eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey,
    () => callback && !isCurrentEventKey && callback());

  return (
    <Button variant="link-dark" className="pl-0" onClick={decoratedOnClick} data-test="batchHistory_customToggleButton">
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

function mapStateToProps(state) {
  return {
    letterBatch: state.letterBatch,
    tags: state.tenant.tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLetterBatch: batchData => dispatch(UpdateLetterBatch(batchData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchHistory);
