// Libraries
import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
// Actions
import ShowNotification from '../../../actions/notification';
// Components
import { Checkbox } from '../../base/forms/Checkbox';
// Constants
import { DIALOG_STYLES, NOTIFICATION_TYPE } from '../../../constants/constants';
import { updatePatientListTags } from '../../../services/patient';

/**
 * BulkChangeAttributeModal component, requires props:
 * isModalOpen - boolean to open modal
 * setIsModalOpen - function to change state isModalOpen variable
 * reloadData - function to update list
 * patientIds - array with patientIds
 */

export const BulkChangeAttributeModal = (props) => {
  const {
    isModalOpen, setIsModalOpen, reloadData, patientIds,
  } = props;

  const { tags } = useSelector(state => state.tenant);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const DEFAULT_VALUES = {};

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const savePatientListAttributes = (tagIds) => {
    const updatePatientTagsRequest = updatePatientListTags({ patientIds, tagIds });
    const updatePatientTagsPromise = updatePatientTagsRequest.promise;

    return updatePatientTagsPromise.then(() => {
      delete updatePatientTagsRequest.promise;
      reloadData();
    }).catch((error) => {
      delete updatePatientTagsRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to update patient attributes.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const updatePatientAttributes = (tagIds) => {
    savePatientListAttributes(tagIds);
    handleCloseModal();
  };

  const renderTags = () => tags.filter(tag => !tag.deleted).map(tag => (
    <Checkbox
      key={`service__${tag.id}_${tag.displayName}`}
      styles={{ formGroup: 'my-2 col-4 text-left', formControl: 'lipstick-checkbox' }}
      name={tag.id}
      label={tag.displayName}
      description={tag.description}
    />
  ));

  const isEmptyOrFalseEmpty = obj => Object.keys(obj).length === 0
    || Object.keys(obj).every(key => !obj[key]);

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Change Status"
      data-test="bulkChangeAttributeModal_onRequestClose"
    >
      <div className="simple-dialog big-dialog">
        <div className="dialog-title">
          Edit Patient Attributes
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="bulkChangeAttributeModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={DEFAULT_VALUES}
          onSubmit={(values) => {
            const tagIds = [];
            if (!isEmptyOrFalseEmpty(values)) {
              Object.keys(values).forEach((key) => {
                if (values[key]) tagIds.push(Number(key));
              });
            }
            updatePatientAttributes(tagIds);
          }}
          data-test="bulkChangeAttributeModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <div className="dialog-content row">
                  {renderTags()}
                </div>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="bulkChangeAttributeModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!(formik.isValid && formik.dirty) || isEmptyOrFalseEmpty(formik.values)}
                  data-test="bulkChangeAttributeModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default BulkChangeAttributeModal;
