// Libraries
import React from 'react';
import {
  Routes, Route, NavLink, Navigate,
} from 'react-router-dom';
import { connect } from 'react-redux';
// Constants
import { FLIGHT_PLAN_MENU_ITEMS, FLIGHT_PLAN_MENU_NAMES, USER_ROLES } from '../../../constants/constants';
// Views
import Reminders from '../../flightPlan/Reminders';
import RecentWork from '../../flightPlan/RecentWork';
import Alerts from '../../flightPlan/Alerts';
import Metrics from '../../flightPlan/Metrics';
import CallSchedule from '../../flightPlan/CallSchedule';
import StatusChangeReview from '../../flightPlan/StatusChangeReview';
import ProtectedRoute from './ProtectedRoute';

export function FlightPlan(props) {
  const isAdminUser = props.role === USER_ROLES.ADMIN;
  const renderMenuItems = () => FLIGHT_PLAN_MENU_ITEMS.map((item) => {
    const { role, isExternal } = props;

    const isCnUser = role === USER_ROLES.CN;
    const isPesUser = role === USER_ROLES.PES;
    const isInternalAdminUser = isAdminUser && !isExternal;

    if (item.roles) {
      const isEnabledRole = item.roles.length > 0
      && item.roles.some(enabledRole => enabledRole === role);

      if (!isEnabledRole) return null;
    }

    const LinkItem = (
      <NavLink
        data-test={`flightPlan_${item.url}-link`}
        key={`${item.url}`}
        to={item.url}
        className={({ isActive }) => `d-block text-left my-2${!item.enabled ? ' disabled' : ''}${isActive ? ' active' : ''}`}
      >
        {item.title}
      </NavLink>
    );

    if (item.title === FLIGHT_PLAN_MENU_NAMES.METRICS.title) {
      if (isInternalAdminUser || isCnUser || isPesUser) return LinkItem;
      return null;
    }

    return LinkItem;
  });

  const defaultRoute = () => {
    const { role } = props;
    return role === USER_ROLES.CN || role === USER_ROLES.PES ? 'call-schedule' : 'reminders';
  };
  return (
    <div className="ccm-flight-plan-main d-flex mt-2">
      <div className="h-100 ccm-flight-plan-panel-menu">
        <div className="ccm-flight-plan-menu-wrapper d-flex flex-column h-100">
          <div className="flight-plan-menu-items box-wrapper flex-grow-1 overflow-auto py-2" data-test="flightPlan_menu">
            {renderMenuItems()}
          </div>
        </div>
      </div>
      <div className="h-100 ccm-flight-plan-main-content box-wrapper flex-grow-1 ml-2 p-3 overflow-auto">
        <Routes>
          <Route path="alerts" element={<Alerts />} />
          <Route path="call-schedule" element={<CallSchedule />} />
          <Route path="metrics" element={<Metrics />} />
          <Route path="reminders" element={<Reminders />} />
          <Route path="recent-work" element={<RecentWork />} />
          <Route
            path="status-review"
            element={(
              <ProtectedRoute allowed={isAdminUser}>
                <StatusChangeReview />
              </ProtectedRoute>
            )}
          />
          <Route path="/" element={<Navigate to={defaultRoute()} />} />
        </Routes>
      </div>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    role: state.user.role,
    isExternal: state.user.isExternal,
  };
}

export default connect(mapStateToProps, null)(FlightPlan);
