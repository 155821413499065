// Libraries
import _debounce from 'lodash/debounce';
import _cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// Actions
import { CloseQaSearch, QaSearch } from '../../../actions/qaSearch';

export const QaPatientFilter = () => {
  const {
    qaSearch: { searchParams: stateSearchParams },
    requestsInProgress: { count: loading },
  } = useSelector(state => state);
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});
  const dispatch = useDispatch();

  const handleSearch = (params) => {
    const newParams = _cloneDeep(params);
    Object.keys(newParams).forEach((key) => {
      if (!newParams[key]) {
        delete newParams[key];
      }
    });
    dispatch(QaSearch({ ...newParams }, true));
    dispatch(CloseQaSearch());
  };

  const debouncedHandleSearch = useMemo(() => _debounce(handleSearch, 1000), []);

  const handleSearchInputChange = (event) => {
    if (loading) {
      return;
    }
    const {
      target: {
        name, value = '', type = '', checked = null,
      } = {},
    } = event || {};

    const updatedSearchParams = {
      ...searchParams,
      [name]: type === 'checkbox' ? checked : value,
    };

    Object.keys(updatedSearchParams).forEach((key) => {
      if (!updatedSearchParams[key]) {
        delete updatedSearchParams[key];
      }
    });
    setSearchParams(updatedSearchParams);
    debouncedHandleSearch(updatedSearchParams);
  };

  useEffect(() => {
    setSearchParams(stateSearchParams);
  }, [stateSearchParams]);

  useEffect(() => () => {
    debouncedHandleSearch.cancel();
  }, []);

  return (
    <Card.Body className="pr-4">
      <Form className="text-left">
        <Form.Group controlId="formPatientFirstName" className="mb-2">
          <Form.Label className="mb-0">First name</Form.Label>
          <Form.Control type="text" name="firstName" onChange={handleSearchInputChange} value={searchParams.firstName || ''} data-test="qaPatientFilter_firstName" className={`${stateSearchParams.firstName ? 'is-active' : ''}`} />
        </Form.Group>
        <Form.Group controlId="formPatientLastName" className="mb-2">
          <Form.Label className="mb-0">Last name</Form.Label>
          <Form.Control type="text" name="lastName" onChange={handleSearchInputChange} value={searchParams.lastName || ''} data-test="qaPatientFilter_lastName" className={`${stateSearchParams.lastName ? 'is-active' : ''}`} />
        </Form.Group>
        <Form.Group controlId="formPatientID" className="mb-2">
          <Form.Label className="mb-0">Patient id</Form.Label>
          <Form.Control type="text" name="externalId" onChange={handleSearchInputChange} value={searchParams.externalId || ''} data-test="qaPatientFilter_id" className={`${stateSearchParams.externalId ? 'is-active' : ''}`} />
        </Form.Group>
      </Form>
    </Card.Body>
  );
};

export default QaPatientFilter;
