// libraries
import React, { useState, useEffect, useContext } from 'react';
import _groupBy from 'lodash/groupBy';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import {
  Accordion, Card, Dropdown, Button,
} from 'react-bootstrap';
// views
import MedicalEventsModal from './MedicalEventsModal';
// services
import { getPatientMedicalEvents, deletePatientMedicalEvent } from '../../../services/patient';
import { getEventType } from '../../../services/helpers';
// constants
import {
  EMPTY_STRING, DATE_FORMAT, NOTIFICATION_TYPE, MEDICAL_EVENTS_SOURCES,
} from '../../../constants/constants';
// Actions
import ShowNotification from '../../../actions/notification';
// Hooks
import useConfirm from '../../../hooks/useConfirm';

const defaultYear = moment().format(DATE_FORMAT.YEAR);

const EVENTS_WITHOUT_EDIT = ['EHR', 'HIE', 'CLAIMS'];


export function MedicalEvents(props) {
  const { patientId, patientMedicalEvents = [] } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collapseAll, setCollapseAll] = useState(true);
  const [selectedMedicalEvent, setSelectedMedicalEvent] = useState(null);
  const MEDICAL_FILTERS = { [defaultYear]: { name: 'This Year' } };
  const [medicalEvents, setMedicalEvents] = useState(patientMedicalEvents || []);
  const upcomingMeetingsWithYear = medicalEvents && medicalEvents.map(
    event => ({ ...event, date: moment(event.startDate).format(DATE_FORMAT.YEAR) }),
  );
  const medicalEventsYearGrouped = _groupBy(upcomingMeetingsWithYear, event => event.date);
  Object.keys(medicalEventsYearGrouped).forEach((year) => {
    MEDICAL_FILTERS[year] = {
      name: `${year}`,
    };
  });
  const [medicalFilter, setMedicalEventFilter] = useState(defaultYear);
  const { isConfirmed } = useConfirm();

  const loadPatientMedicalEvents = () => {
    const { showNotification } = props;

    const getPatientMedicalEventsRequest = getPatientMedicalEvents(patientId);
    const getPatientMedicalEventsPromise = getPatientMedicalEventsRequest.promise;

    getPatientMedicalEventsPromise.then((data) => {
      setMedicalEvents(data);
    }).catch((error) => {
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not load patient medical events',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleDeleteGoal = async (medicalEvent) => {
    const { showNotification } = props;

    const confirmed = await isConfirmed('Are you sure you want to remove the selected medical event?');
    if (!confirmed) {
      return;
    }

    const deletePatientEventRequest = deletePatientMedicalEvent(patientId, medicalEvent.id);
    const deletePatientEventPromise = deletePatientEventRequest.promise;

    deletePatientEventPromise.then(() => {
      const updatedPatientEventsData = medicalEvents.filter(p => p.id !== medicalEvent.id);
      setMedicalEvents(updatedPatientEventsData);
    }).catch((error) => {
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient medical event',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    loadPatientMedicalEvents();
  }, []);

  const filterDropDownItems = Object.keys(MEDICAL_FILTERS).map((key, index) => {
    const yearName = defaultYear === key ? 'This Year' : key;
    return (
      <Dropdown.Item
        data-test={`medicalEvents_filter_${key}`}
        key={`medicalEvents_filter_${index}`}
        onClick={() => {
          setMedicalEventFilter(MEDICAL_FILTERS[key].name);
        }}
        as="button"
        className="d-flex my-1"
      >
        <div className="pr-3">{yearName}</div>
      </Dropdown.Item>
    );
  });

  const getFilter = (
    <Dropdown
      className="position-absolute"
      style={{ left: 1 }}
      data-test="medicalEvents_dateFilter"
    >
      <Dropdown.Toggle
        variant="ccm-light-gray"
        className="py-0"
      >
        {`Filter: ${defaultYear === medicalFilter ? 'This Year' : medicalFilter}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterDropDownItems}
      </Dropdown.Menu>
    </Dropdown>
  );

  function renderEmptyRow() {
    return (
      <tr data-test="medicalEvents_emptyMsg">
        <td colSpan="4" className="p-2 border-0">
        This patient has no Medical Events
        </td>
      </tr>
    );
  }

  const handleEditMedicalEvent = (medicalEvent) => {
    setSelectedMedicalEvent(medicalEvent);
    setIsModalOpen(true);
  };

  const renderMedicalsRows = (medicalEventsData) => {
    if (medicalEventsData && medicalEventsData.length) {
      const { patientUtils: { patientEventTypes } } = props;
      return medicalEventsData.map((medicalEvent, index) => {
        const patientEventType = getEventType(medicalEvent.typeId, patientEventTypes);
        const medicalEventType = patientEventType ? patientEventType.displayName : EMPTY_STRING;
        const medicalEventSource = medicalEvent.source
          && MEDICAL_EVENTS_SOURCES[medicalEvent.source];
        const isEHR = value => (value && EVENTS_WITHOUT_EDIT.some(e => e === medicalEvent.source));
        return (
          <tr key={`medicalEvent_row_${index}_${medicalEvent.id}`} data-test="medicalEvents_entryRow">
            <td colSpan="7" className="p-0">
              <Accordion>
                <Card className="border-0 rounded-0">
                  <Card.Header className="bg-light border-0 p-0">
                    <table className={`table mb-0 ${isEHR(medicalEvent) ? '' : 'table-hover'}`}>
                      <tbody>
                        <tr className="medical-event__row" data-test="medicalEvents_medical-event__row">
                          <td className="medical-events__type" onClick={() => (isEHR(medicalEvent) ? {} : handleEditMedicalEvent(medicalEvent))} data-test="medicalEvents_editBtn">
                            <div>
                              {medicalEventType || EMPTY_STRING}
                              { medicalEvent.readmission && <span className="text-ccm-red bi-arrow-repeat ml-1" />}
                            </div>
                            {medicalEvent.numVisits > 0 && (
                              <small>
                                {`${medicalEvent.numVisits} visits`}
                              </small>
                            )}
                          </td>
                          <td className="medical-events__date" onClick={() => (isEHR(medicalEvent) ? {} : handleEditMedicalEvent(medicalEvent))} data-test="medicalEvents_editBtn">
                            {medicalEvent.startDate
                              ? moment(medicalEvent.startDate).format(DATE_FORMAT.SHORT)
                              : EMPTY_STRING}
                          </td>
                          <td className="medical-events__date" onClick={() => (isEHR(medicalEvent) ? {} : handleEditMedicalEvent(medicalEvent))} data-test="medicalEvents_editBtn">
                            {medicalEvent.endDate
                              ? moment(medicalEvent.endDate).format(DATE_FORMAT.SHORT)
                              : EMPTY_STRING}
                          </td>
                          <td className="medical-events__location" onClick={() => (isEHR(medicalEvent) ? {} : handleEditMedicalEvent(medicalEvent))} data-test="medicalEvents_editBtn">
                            {medicalEvent.location || EMPTY_STRING}
                          </td>
                          <td className="medical-events__reason" onClick={() => (isEHR(medicalEvent) ? {} : handleEditMedicalEvent(medicalEvent))} data-test="medicalEvents_editBtn">
                            {medicalEvent.reason || EMPTY_STRING}
                          </td>
                          <td className="medical-events__source" onClick={() => (isEHR(medicalEvent) ? {} : handleEditMedicalEvent(medicalEvent))} data-test="medicalEvents_editBtn">
                            {medicalEventSource || EMPTY_STRING}
                          </td>
                          <td className="medical-events__action px-0 text-right">
                            {!isEHR(medicalEvent) && (
                              <Button variant="link-dark" className="pl-0 pr-3" onClick={() => handleDeleteGoal(medicalEvent)} data-test="medicalEvents_deleteBtn">
                                <i className="bi-trash" />
                              </Button>
                            )}
                            <CustomToggle eventKey="1" collapseAll={collapseAll} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body data-test="medicalEvents_cardBody">
                      <div className="d-flex">
                        <p className="mb-2" data-test="medicalEvents_readmission">
                          <span className="mr-2">Readmission:</span>
                          {medicalEvent.readmission ? 'Yes' : 'No' }
                        </p>
                        <p className="mb-2" data-test="medicalEvents_provider">
                          <span className="mr-2 ml-3">Provider:</span>
                          {medicalEvent.providerName || medicalEvent.providerName }
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mr-2">Comment:</p>
                        <div className="flex-grow-1">
                          <i data-test="medicalEvents_comment">{medicalEvent.comment ? medicalEvent.comment : EMPTY_STRING}</i>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow(medicalFilter);
  };

  const renderMedicalEvents = () => renderMedicalsRows(medicalEventsYearGrouped[medicalFilter]);

  return (
    <div className="history-medical-events card border-0 mb-4">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        {getFilter}
        <span className="text-uppercase">Other Medical Events</span>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '3rem' }}
          onClick={() => setCollapseAll(!collapseAll)}
          data-test="medicalEvents_collapseAllBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        <Button
          data-test="medicalEvents_addBtn"
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => {
            setSelectedMedicalEvent(null);
            return setIsModalOpen(true);
          }}
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
        <MedicalEventsModal
          patientId={patientId}
          initialMedicalEvent={selectedMedicalEvent}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setMedicalEvents={setMedicalEvents}
          medicalEvents={medicalEvents}
        />
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2 medical-events__type">Event Type</th>
              <th className="py-2 medical-events__date">Start Date</th>
              <th className="py-2 medical-events__date">End Date</th>
              <th className="py-2 medical-events__location">Location</th>
              <th className="py-2 medical-events__reason">Reason</th>
              <th className="py-2 medical-events__source">Source</th>
              <th className="py-2 medical-events__action px-0" />
            </tr>
          </thead>
          <tbody>
            {renderMedicalEvents()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0 pr-1" onClick={decoratedOnClick}>
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

export const mapStateToProps = state => ({
  patientUtils: state.patient && state.patient.utils,
});

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalEvents);
