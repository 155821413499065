// libraries
import React from 'react';


export function Calendar() {
  const calendarBlock = (
    <div>
      <div className="ml-1">
        March
      </div>
    </div>
  );

  return (
    <div className="content d-none">
      {calendarBlock}
    </div>
  );
}
export default Calendar;
