// libraries
import React from 'react';
// views
import PatientRow from '../patient/PatientRow';

/**
 * PatientTableContent component, requires props:
 * patients -- array of patients
 * selectPatientCallback -- callback after select patient
 * canPatientStatusBeChanged -- function to check is patient status can be changed
 * params
 */
export function PatientTableContent(props) {
  const renderPatients = () => (
    props.patients.map(patient => (
      <PatientRow
        selectCallback={props.selectPatientCallback}
        canPatientStatusBeChanged={props.canPatientStatusBeChanged}
        data={patient}
        key={`patient-item-${patient.id}`}
      />
    ))
  );

  return (
    <tbody>
      {renderPatients()}
    </tbody>
  );
}

export default PatientTableContent;
