// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getFinalizeAlerts } from '../../services/patient';
// Actions
import { UpdatePatient } from '../../actions/patient';

const useLoadFinalizeAlerts = ({ patientId }) => {
  const [finalizeAlerts, setFinalizeAlerts] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchFinalizeAlerts = useCallback(async () => {
    const getFinalizeAlertsRequest = getFinalizeAlerts(patientId);
    const getFinalizeAlertsPromise = getFinalizeAlertsRequest.promise;
    try {
      const data = await getFinalizeAlertsPromise;
      setFinalizeAlerts(data);
      dispatch(UpdatePatient({ finalizeAlerts: data }));
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patient info.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, []);

  useEffect(() => {
    fetchFinalizeAlerts();
  }, [fetchFinalizeAlerts]);

  return { finalizeAlerts, refetch: fetchFinalizeAlerts };
};

export default useLoadFinalizeAlerts;
