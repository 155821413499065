// Libraries
import React, {
  useState, useEffect, useRef, Fragment,
} from 'react';
import moment from 'moment';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import { connect } from 'react-redux';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
// Actions
import ShowNotification from '../../../actions/notification';
// Services
import { parseDraftToHtml } from '../../../services/helpers';
import { getPatientSingleCallNote } from '../../../services/patient';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../../constants/constants';

export function CallNotes(props) {
  const {
    patientId, patient: { callNotes: historyCallNotes = [] } = {},
  } = props;

  const [selectedNote, setSelectedNote] = useState(null);
  const [callNotes, setCallNotes] = useState(historyCallNotes || null);

  const prevCallNotesRef = useRef();
  useEffect(() => {
    prevCallNotesRef.current = callNotes;
  }, [callNotes]);
  const prevCallNotes = prevCallNotesRef.current;

  const loadSelectedNote = (note) => {
    const { showNotification } = props;

    const getNoteRequest = getPatientSingleCallNote(patientId, note.id);
    const getNotePromise = getNoteRequest.promise;

    return getNotePromise.then((data) => {
      delete getNoteRequest.promise;
      setSelectedNote(data);
    }).catch((error) => {
      delete getNoteRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load selected note, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    const archivedNotes = historyCallNotes.filter(el => !el.iscurrent);
    const sortedNotes = _reverse(_sortBy(archivedNotes, el => moment(el.createddt)));

    setCallNotes(sortedNotes);

    if (sortedNotes.length > 0) {
      const noteToShow = sortedNotes[0];
      const callNotesUpdated = !prevCallNotes
        || sortedNotes.length !== prevCallNotes.length;

      if (noteToShow && callNotesUpdated
        && (!selectedNote || (noteToShow.id !== selectedNote.id))) {
        loadSelectedNote(noteToShow);
      }
    }
  }, [historyCallNotes]);

  const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);
  const getFullName = a => `${a.firstName || ''} ${a.lastName || ''}`.replace(/  +/g, ' ');

  const renderCallNotesItems = () => {
    if (!callNotes || !callNotes.length) {
      return (
        <Dropdown.ItemText className="text-nowrap text-ccm-gray" data-test="callNotes_empty">
          <small>Call Notes history empty.</small>
        </Dropdown.ItemText>);
    }

    return callNotes.map(note => (
      <Dropdown.Item
        key={`callNote__${note.id}`}
        as="button"
        className="d-flex my-1 btn-sm"
        active={selectedNote && (note.id === selectedNote.id)}
        onClick={() => loadSelectedNote(note)}
        data-test={`callNotes_noteItem_${note.id}`}
      >
        <div className="pr-3">{`${getFullName(note.createdBy)} ${getDateString(note.createddt)}`}</div>
      </Dropdown.Item>
    ));
  };

  return (
    <Fragment>
      <div className="note-header d-flex-center-between mb-2">
        <span className="mr-1">Call Notes History:</span>
        <DropdownButton
          size="sm"
          as={ButtonGroup}
          variant="link"
          menuAlign="right"
          title={(
            <span data-test="callNotes_authorNameDate">
              {selectedNote
                ? `${getFullName(selectedNote.createdBy)} ${getDateString(selectedNote.createddt)}`
                : 'Select note'}
            </span>)}
          data-test="callNotes_dropdownItems"
        >
          {renderCallNotesItems()}
        </DropdownButton>
      </div>

      <div className="note-body border position-relative mb-1 p-2" data-test="callNotes_noteHistoryLogBox">
        {selectedNote ? (
          selectedNote.note && parseDraftToHtml(selectedNote.note))
          : (
            <span className="text-ccm-gray">No recent call notes.</span>
          )}
      </div>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CallNotes);
