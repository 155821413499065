// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// actions
import ShowNotification from '../../../../../actions/notification';
// services
import { getBillingHistory } from '../../../../../services/patient';
// constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../../../../constants/constants';

export class BillingHistoryTable extends Component {
  state = {
    billingHistory: [],
  };

  promises = {};

  componentDidMount() {
    this.loadBillingHistory();
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  loadBillingHistory = () => {
    const { showNotification } = this.props;

    const promiseName = 'getBillingHistory';
    const getBillingHistoryRequest = getBillingHistory(this.props.patientId);
    const getBillingHistoryPromise = getBillingHistoryRequest.promise;
    this.promises[promiseName] = getBillingHistoryRequest;

    return getBillingHistoryPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        billingHistory: [
          ...data,
        ],
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load billing history, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  renderTableData() {
    return this.state.billingHistory.map((item) => {
      const physicianName = item.displayPhysicianName;
      const onsetDate = item.dateOfService;
      const onsetDateFormatted = onsetDate
        ? moment(onsetDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.SHORT)
        : 'N/A';

      return item.codes.map((codeItem, index) => (
        <tr key={`billing-history_data_${index}`}>
          <td className="mark-column">{codeItem.code}</td>
          <td className="text-column">{codeItem.displayName}</td>
          <td className="mark-column">{item.cptCode}</td>
          <td className="date-column">{onsetDateFormatted}</td>
          <td className="mark-column">{physicianName}</td>
        </tr>
      ));
    });
  }

  renderBillingHistoryTable() {
    const { billingHistory } = this.state;

    if (!billingHistory || !billingHistory.length) {
      return <div className="empty-value">This patient has no billing history.</div>;
    }

    return (
      <div className="billing-history-table-wrapper">
        <table className="billing-history-table">
          <thead>
            <tr>
              <td className="mark-column">Code</td>
              <td className="text-column">Description</td>
              <td className="mark-column">CPT code</td>
              <td className="date-column">Date of service</td>
              <td className="mark-column">Physician</td>
            </tr>
          </thead>
          <tbody>
            {this.renderTableData()}
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <div className="patient-subsection-content">
          {this.renderBillingHistoryTable()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const physicians = state.physicians || {};

  return {
    physicians: physicians.physiciansList || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistoryTable);
