// Libraries
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { Button, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Actions
import ShowNotification from '../../../../actions/notification';
// Services
import { addPatientAdditionalInfo, updatePatientAdditionalInfo } from '../../../../services/patient';
// Constants
import { DIALOG_STYLES, NOTIFICATION_TYPE } from '../../../../constants/constants';
// Components
import { TextArea } from '../../../base/forms/TextArea';

const INITIAL_VALUES = {
  id: null,
  note: '',
};

const AdditionalInformationModal = (props) => {
  const {
    isModalOpen, setIsModalOpen,
    initialInformation, patientId, callbackAction,
  } = props;
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const [newInformation, setNewInformation] = useState(initialInformation || INITIAL_VALUES);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveInformation = (information) => {
    const isNewInformation = !information.id;
    let addInformationRequest;

    addInformationRequest = isNewInformation
      ? addInformationRequest = addPatientAdditionalInfo(patientId, information)
      : addInformationRequest = updatePatientAdditionalInfo(patientId, information, information.id);

    const addInformationPromise = addInformationRequest.promise;

    return addInformationPromise.then(() => {
      delete addInformationPromise.promise;
      if (callbackAction) callbackAction();
      handleCloseModal();
    }).catch((error) => {
      delete addInformationPromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting save information',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    if (initialInformation) {
      setNewInformation({
        id: initialInformation.id,
        note: initialInformation.note || '',
      });
    } else {
      setNewInformation(INITIAL_VALUES);
    }
  }, [initialInformation]);

  return (
    <Fragment>
      <Modal
        isOpen={isModalOpen}
        style={DIALOG_STYLES}
        onRequestClose={() => handleCloseModal()}
        contentLabel="Additional Information"
        data-test="additionalInformationModal_modal"
      >
        <div className="simple-dialog xl-dialog">
          <div className="dialog-title">
            Additional Information
            <button
              type="button"
              className="close-icon i-close"
              onClick={() => handleCloseModal()}
              data-test="additionalInformationModal_modalCloseButton"
            />
          </div>
          <Formik
            initialValues={newInformation}
            validationSchema={Yup.object({
              note: Yup.string().required('Required'),
            })}
            onSubmit={(values, { resetForm }) => {
              saveInformation(values);
              resetForm();
            }}
            data-test="additionalInformationModal_formikComponent"
          >
            {({ handleSubmit, isValid }) => (
              <Form>
                <div className="dialog-content text-left">
                  <Row className="align-items-center mb-4">
                    <div className="col-12" data-test="additionalInformationModal_category">
                      <p className="m-0">
                        Include any other information that the provider should know below.
                        Please Note: this care plan may be viewed by the patient.
                      </p>
                      <TextArea
                        name="note"
                        data-test="additionalInformationModal_note"
                        rows={10}
                      />
                    </div>
                  </Row>
                </div>
                <div className="d-flex mt-3 mb-2 justify-content-end px-4">
                  <Button variant="light" onClick={() => handleCloseModal()} data-test="additionalInformationModal_cancelBtn">Cancel</Button>
                  <Button
                    variant="primary"
                    className="ml-2"
                    onClick={() => handleSubmit()}
                    disabled={!isValid}
                    data-test="additionalInformationModal_saveBtn"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </Fragment>
  );
};
export default AdditionalInformationModal;
