// Libraries
import React, { useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import { Button, Nav } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// actions
import { Search } from '../../../actions/search';
// Custom Hooks
import usePrevious from '../../../hooks/helpers/usePrevious';
// Constants
import { SEARCH_PARAMS, USER_ROLES } from '../../../constants/constants';
import { DEFAULT_QUICK_FILTERS, QUICK_FILTERS_ADMIN_LIST, QUICK_FILTERS_NON_ADMIN_LIST } from '../../../constants/quickFilters';


export function QuickFilter(props) {
  const {
    user: { role },
    requestsInProgress: { count: loading },
    search: { searchParams: stateSearchParams },
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const { needsCnCount } = props;

  const isAdminUser = role === USER_ROLES.ADMIN;
  const isCnOrPesUser = role === USER_ROLES.CN || role === USER_ROLES.PES;

  const prevSearchParams = usePrevious(stateSearchParams);
  const [quickFilterList, setQuickFilterList] = useState([]);
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});

  const handleSearch = (params) => {
    const newParams = _cloneDeep(params);
    dispatch(Search({ ...newParams }, true));
  };

  const handleUnselect = () => {
    if (loading) return;

    const updatedParams = { ...searchParams, quickFilters: { ...DEFAULT_QUICK_FILTERS } };
    setSearchParams(updatedParams);
    handleSearch(updatedParams);
  };

  const handleSelect = (filterKey) => {
    if ((searchParams.quickFilters && searchParams.quickFilters[filterKey]) || loading) {
      return;
    }

    const quickFilters = {
      ...DEFAULT_QUICK_FILTERS,
      [filterKey]: true,
    };

    setSearchParams(prevParams => ({ ...prevParams, quickFilters }));
    handleSearch({ ...searchParams, quickFilters });
  };

  const renderItems = () => quickFilterList.map((filter, filterIndex) => {
    const active = searchParams.quickFilters && searchParams.quickFilters[filter.id];

    return (
      <Nav.Item className={`${active ? 'status-active' : ''} m-2`} key={`quick-filter-${filterIndex}`}>
        <Nav.Link className="p-0 justify-content-center" eventKey={filter.id}>
          <div className={`${active ? 'quick-filter-selected ' : 'quick-filter-unselected'} d-flex-center`}>
            <span className={`${active ? 'text-ccm-blue ' : ''} pill-label`}>{filter.name}</span>
            {filter.id === 'enrolledNoCnAssigned' && needsCnCount > 0 && (
              <span className="avatar-badge d-flex-center rounded-circle">
                {needsCnCount}
              </span>
            )}
          </div>
          {active && (
            <Button
              variant="link"
              disabled={loading}
              onClick={handleUnselect}
              className="p-0 status-remove"
              data-test="quickFilter_removeFilterIcon"
            >
              <i className="d-flex-center bi-x" />
            </Button>
          )}
        </Nav.Link>
      </Nav.Item>
    );
  });

  useEffect(() => {
    if (isAdminUser) {
      setQuickFilterList(QUICK_FILTERS_ADMIN_LIST);
    }

    if (isCnOrPesUser) {
      setQuickFilterList(QUICK_FILTERS_NON_ADMIN_LIST);
    }
  }, [role]);

  useEffect(() => {
    if (!_isEqual(searchParams, prevSearchParams)) {
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(stateSearchParams);
  }, [stateSearchParams]);

  return (
    <div className="d-flex quick-filter">
      <span className="align-self-center font-weight-bold pill-title">{`${SEARCH_PARAMS.QUICK_FILTERS.label}:`}</span>
      <Nav
        className="d-flex-center flex-nowrap w-100"
        onSelect={handleSelect}
        data-test="quickFilter_navbar"
      >
        {renderItems()}
      </Nav>
    </div>
  );
}

export default QuickFilter;
