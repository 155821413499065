// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
// Actions
import { ResetSearch } from '../../actions/search';
// Views
import Pager from './Pager';
// Constants
import { USER_ROLES } from '../../constants/constants';

export class SortingPanel extends Component {
  resetSearch = () => {
    const { resetSearch, loading } = this.props;

    if (loading) {
      return;
    }


    resetSearch();
  };

  render() {
    const {
      userRole, loading, searchParams,
      totalCount, isSearchPanelOpened, isSortingPanelOpened, totalPages, goToPage, currentPage,
    } = this.props;

    const searchParamsLength = Object.keys(searchParams).length;

    const isSearchEmpty = (searchParamsLength === 0);

    const getMaxShownCount = () => {
      if (isSearchEmpty) return 5;
      if (!isSearchPanelOpened) return 5;
      if (isSortingPanelOpened && userRole === USER_ROLES.CN) return 5;
      return 3;
    };

    return (
      <div className="d-flex-center-between w-100 h-100">
        <Pager
          totalPages={totalPages}
          callback={goToPage}
          currentPage={currentPage}
          maxShownCount={getMaxShownCount()}
          isNextPrevShown={isSortingPanelOpened ? true : (userRole === USER_ROLES.CN)}
        />
        { !isSearchEmpty && (
          <div className="sorting-results d-flex align-items-center justify-content-end">
            <span>
              {`Showing ${totalCount} filtered results`}
            </span>
            <Button
              variant="link"
              className="p-1"
              disabled={loading}
              onClick={this.resetSearch}
              data-test="sortingPanel_removeFilters"
            >
              <i className="d-flex-center bi-x" />
            </Button>
          </div>
        ) }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userRole: state.user && state.user.role,
    searchParams: state.search.searchParams,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetSearch: () => dispatch(ResetSearch()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SortingPanel);
