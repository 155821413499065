// Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Views
import LicenceAgreement from '../../../user/termsOfAgreement/LicenceAgreement';
import GlobalUserAccount from '../../user/GlobalUserAccount';
import GlobalUserPageMenu from '../../user/GlobalUserPageMenu';

export function GlobalUser() {
  return (
    <div className="ccm-admin-main d-flex mt-2">
      <div className="h-100 ccm-admin-panel-menu">
        <GlobalUserPageMenu />
      </div>
      <div className="h-100 ccm-admin-main-content box-wrapper flex-grow-1 ml-2 py-3 pl-3 overflow-auto">
        <Routes>
          <Route
            path="account"
            element={<GlobalUserAccount />}
          />
          <Route
            path="terms"
            element={<LicenceAgreement />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default GlobalUser;
