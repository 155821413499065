// Libraries
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  Button, ButtonGroup, Dropdown, DropdownButton,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Actions
import { UpdatePatient } from '../../actions/patient';
import ShowNotification, { HideNotification } from '../../actions/notification';
// Constants
import { DIAGNOSTICS_PAGE_SIZE } from '../../constants/pageSizes';
import {
  NEW_DATA_DATE_RANGE, DIAGNOSTICS_STATUS,
  NOTIFICATION_TYPE, DATE_FORMAT, MAX_LENGTH_TRUNCATE_STRING,
} from '../../constants/constants';
// Services
import { getDiagnosticsList } from '../../services/patient';
import { longStringTruncate } from '../../services/helpers';
// Views
import Pager from '../patients/Pager';
import InterventionsModal from './carePlan/interventions/InterventionModal';

export const Diagnostics = (props) => {
  const { id: patientId } = useParams();

  const { loading } = props;

  const [statusFilter, setStatusFilter] = useState(Object.keys(DIAGNOSTICS_STATUS)[1]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [initialIntervention, setInitialIntervention] = useState(null);
  const [isInterventionsModalOpen, setIsInterventionsModalOpen] = useState(false);

  const handleClickStatusFilter = (status) => {
    setCurrentPage(0);
    setStatusFilter(status);
  };

  const handleAddIntervention = (diagnostic) => {
    const testsLabel = diagnostic.tests && diagnostic.tests.map(d => ` ${d.name}`);
    setInitialIntervention({
      note: `${diagnostic.title} - Tests: ${testsLabel}`,
    });
    setIsInterventionsModalOpen(true);
  };

  const loadDiagnostics = () => {
    const { updatePatient, hideNotification, showNotification } = props;
    const onlyRecent = DIAGNOSTICS_STATUS[statusFilter].value;

    hideNotification();

    const getDiagnosticsRequest = getDiagnosticsList({
      patientId, onlyRecent, currentPage, pageSize: DIAGNOSTICS_PAGE_SIZE,
    });
    const getDiagnosticsPromise = getDiagnosticsRequest.promise;

    return getDiagnosticsPromise.then((data) => {
      delete getDiagnosticsRequest.promise;

      setTotalPages(data.totalPages);
      updatePatient({ diagnostics: data.diagnostics });
    }).catch((error) => {
      delete getDiagnosticsRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load patient diagnostics, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderDiagnostics = () => {
    const { patient: { diagnostics } } = props;
    if (!diagnostics || !diagnostics.length) {
      return (
        <span className="text-muted" data-test="diagnostics_emptyMsg">
          <i className="bi-info-circle mr-2" />
          No lab results to display
        </span>);
    }

    const getDateString = date => moment(date).format(DATE_FORMAT.SHORT);

    const checkDataRange = date => moment(date).isBetween(
      moment().subtract(NEW_DATA_DATE_RANGE, 'days'), moment(),
    );

    return diagnostics.map((diagnostic, index) => (
      <div key={`patient-diagnostic__${index}`} className="col-3 p-2" data-test="diagnostics_card">
        <div className="patient-diagnostic-card d-flex flex-column h-100 p-2">
          <div className="d-flex align-items-start justify-content-between mb-1">
            <span
              className="diagnostic-title"
              data-test="diagnostics_displayName"
              data-for={diagnostic.title && diagnostic.title.length > MAX_LENGTH_TRUNCATE_STRING && 'tooltip-patientDiagnostics'}
              data-tip={diagnostic.title}
            >
              {(diagnostic.title && longStringTruncate(diagnostic.title)) || <small className="text-danger">Diagnostic title missing</small>}
            </span>
            <Button variant="link-dark" className="p-0 d-none" disabled>
              <i className="bi-question-circle" />
            </Button>
          </div>

          {diagnostic.subTitle && (
          <small className="diagnostic-subtitle d-block mb-3">
            {diagnostic.subTitle || ''}
          </small>)}

          <div className="diagnostic-tests">
            {diagnostic.tests && <DiagnosticsTests tests={diagnostic.tests} data-test="diagnostics_cardTest" />}
          </div>

          <div className="d-flex-center-between mt-auto">
            <span className="diagnostic-date" data-test="diagnostics_cardDate">
              {diagnostic.date && getDateString(diagnostic.date)}
            </span>

            {diagnostic.date && checkDataRange(diagnostic.date) && (
            <span className="badge badge-pill badge-success ml-1 mr-auto" data-test="diagnostics_cardNewLabel">New</span>)}

            <Button variant="link-dark" className="p-0" onClick={() => handleAddIntervention(diagnostic)} data-test="diagnostics_addInterventionBtn" data-for="tooltip-patientDiagnostics" data-tip="Add Intervention">
              <i className="bi-plus-circle-fill" />
            </Button>
          </div>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    loadDiagnostics();
  }, [statusFilter, currentPage]);
  useEffect(() => {
    ReactTooltip.rebuild();
  });


  return (
    <div className={`patient-diagnostics h-100 overflow-auto pr-3 ${loading ? ' d-none' : ''}`}>
      <h4 className="text-uppercase text-left">Lab Results</h4>
      <div className="d-flex align-items-center mb-3">
        <span className="text-uppercase">Filter:</span>
        <DropdownButton
          size="sm"
          as={ButtonGroup}
          variant="link"
          title={<span className="text-uppercase">{DIAGNOSTICS_STATUS[statusFilter].label || '--'}</span>}
          data-test="diagnostics_recentFilter"
        >
          {Object.keys(DIAGNOSTICS_STATUS).map(status => (
            <Dropdown.Item
              key={`diagnostic-statusFilter__${status}`}
              active={statusFilter === status}
              onClick={() => handleClickStatusFilter(status)}
              data-test="diagnostics_recentFilter-item"
            >
              {DIAGNOSTICS_STATUS[status].label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      <div className="row no-gutters">
        {renderDiagnostics()}
      </div>
      <div className="row no-gutters">
        <Pager
          totalPages={totalPages}
          callback={page => setCurrentPage(page)}
          maxShownCount={5}
          currentPage={currentPage}
        />
      </div>

      <InterventionsModal
        patientId={patientId}
        initialIntervention={initialIntervention}
        isModalOpen={isInterventionsModalOpen}
        setIsModalOpen={setIsInterventionsModalOpen}
      />

      <ReactTooltip id="tooltip-patientDiagnostics" type="info" effect="float" place="bottom" />
    </div>
  );
};

export const DiagnosticsTests = ({ tests = [] }) => {
  const TESTS_TO_RENDER = 5;
  const showMore = tests.length > TESTS_TO_RENDER;
  const [testsToRender, setTestsToRender] = useState(TESTS_TO_RENDER);

  const renderTests = array => array.map((test, index) => (
    <p key={`diagnosticTest__${index}`} className="diagnostic-test mb-1" data-test="diagnostics_cardTestItem">
      <span>{`${test.name || ''} - ${test.currentValue || ''}`}</span>
    </p>
  ));

  return (
    <Fragment>
      {tests && renderTests(tests.slice(0, testsToRender))}
      {showMore && (
      <Button
        size="sm"
        variant="link-dark"
        className="mt-1 p-0 float-right"
        onClick={() => setTestsToRender(
          testsToRender === TESTS_TO_RENDER ? tests.length : TESTS_TO_RENDER,
        )}
        data-test="diagnostics_showMoreTestsBtn"
      >
        { testsToRender === TESTS_TO_RENDER ? 'show more...' : 'show less'}
      </Button>)}
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    patient: state.patient,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Diagnostics);
