import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
// Services
import { updateTenantTags, addTenantTags } from '../../../../services/tenants';
// Actions
import ShowNotification from '../../../../actions/notification';
import { SetTenantTags } from '../../../../actions/tenants';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES,
} from '../../../../constants/constants';
// Components
import { Checkbox } from '../../../base/forms/Checkbox';
import { TextInput } from '../../../base/forms/TextInput';
import { TextArea } from '../../../base/forms/TextArea';
// Hooks
import useConfirm from '../../../../hooks/useConfirm';

export function AttributesSettingModal(props) {
  const {
    isModalOpen, setIsModalOpen, initialAttribute,
  } = props;

  const DEFAULT_ATTRIBUTE = {
    id: '',
    displayName: '',
    description: '',
    deleted: false,
  };

  const fieldStyles = {
    formGroup: 'mb-0',
    formLabel: 'd-none',
  };

  const [newAttribute, setNewAttribute] = useState(initialAttribute || DEFAULT_ATTRIBUTE);
  const { isConfirmed } = useConfirm();

  useEffect(() => {
    if (initialAttribute) {
      setNewAttribute({
        id: initialAttribute.id || '',
        displayName: initialAttribute.displayName || '',
        description: initialAttribute.description || '',
        deleted: initialAttribute.deleted || false,
      });
    } else {
      setNewAttribute(DEFAULT_ATTRIBUTE);
    }
  }, [initialAttribute]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveAttribute = async (attribute) => {
    const {
      showNotification, setTenantTags,
      tags: attributes = [],
    } = props;

    const isNewAttribute = !attribute.id;

    let confirmed;

    if (!isNewAttribute) {
      confirmed = await isConfirmed('This change will apply to all patients for this customer, continue?');
      if (!confirmed) {
        return;
      }
    }

    const addAttributeRequest = isNewAttribute
      ? addTenantTags(attribute) : updateTenantTags(attribute.id, attribute);
    const addAttributePromise = addAttributeRequest.promise;

    addAttributePromise.then((data) => {
      delete addAttributePromise.promise;
      let updatedAttributes;
      if (isNewAttribute) {
        updatedAttributes = [...attributes, data];
      } else {
        updatedAttributes = attributes.map(r => (r.id === data.id ? data : r));
      }
      setTenantTags(updatedAttributes);

      showNotification({
        message: 'Attribute saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      handleCloseModal();
    }).catch((error) => {
      delete addAttributePromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this attribute',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Attributes Setting Modal"
      data-test="attributesSettingModal_onRequestClose"
    >
      <div className="simple-dialog small-dialog">
        <div className="dialog-title">
          {`${initialAttribute ? 'Edit' : 'Add'} Attribute`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="attributesSettingModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={newAttribute}
          validationSchema={Yup.object({
            displayName: Yup.string().required('Required').trim(),
            description: Yup.string().required('Required').trim(),
            deleted: Yup.boolean(),
          })}
          onSubmit={(values, { resetForm }) => {
            saveAttribute({
              ...values,
              displayName: values.displayName.trim(),
              description: values.description.trim(),
            });
            resetForm();
          }}
          data-test="attributesSettingModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="dialog-content text-left">
                <TextInput
                  label="Display Name"
                  name="displayName"
                  data-test="attributesSettingModal_attributeName"
                />
                <TextArea
                  label="Description"
                  name="description"
                  data-test="attributesSettingModal_attributeDescription"
                  rows={4}
                />
                { initialAttribute && (
                <Checkbox
                  label={!formik.values.deleted ? 'Active' : 'Not active'}
                  name="deleted"
                  type="switch"
                  defaultChecked={!formik.values.deleted}
                  onChange={ev => formik.setFieldValue(
                    'deleted', ev && formik.values && !formik.values.deleted,
                  )}
                  styles={fieldStyles}
                  data-test="attributesSettingModal_checkbox"
                />
                )}
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="attributesSettingModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="attributesSettingModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    tags: state.tenant.tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTenantTags: tags => dispatch(SetTenantTags(tags)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesSettingModal);
