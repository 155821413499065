// Libraries
import React from 'react';
// Views
import PromptsList from './prompts/PromptsList';

export const PromptsConfiguration = () => (
  <div className="prompts-configuration h-100 overflow-auto pr-3">
    <h4 className="text-uppercase text-left my-3" data-test="promptConfiguration_title">Prompts Configuration</h4>
    <PromptsList />
  </div>
);

export default PromptsConfiguration;
