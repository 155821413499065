import { createAction, createReducer } from '@reduxjs/toolkit';

const tenantsLoaded = createAction('TENANTS_LOADED');
const timezoneLoaded = createAction('TIMEZONE_LOADED');
const isDemoEnvLoaded = createAction('IS_DEMO_ENV_LOADED');
const setTenantTags = createAction('SET_TENANT_TAGS');
const setTenantFeatures = createAction('SET_TENANT_FEATURES');
const setGlobalTenant = createAction('SET_GLOBAL_TENANT');
const setGlobalLogin = createAction('SET_GLOBAL_LOGIN');
const setShowGlobalTopBar = createAction('SET_SHOW_GLOBAL_TOP_BAR');
const setConsentUrl = createAction('SET_CONSENT_URL');

const initialState = {};

const Tenant = createReducer(initialState, (builder) => {
  builder
    .addCase(tenantsLoaded, (state, action) => action.tenant)
    .addCase(timezoneLoaded, (state, action) => {
      state.timezone = action.timezone;
    })
    .addCase(isDemoEnvLoaded, (state, action) => {
      state.isDemoEnv = action.isDemoEnv;
    })
    .addCase(setTenantTags, (state, action) => {
      state.tags = action.tags;
    })
    .addCase(setTenantFeatures, (state, action) => {
      state.features = action.features;
    })
    .addCase(setGlobalTenant, (state, action) => {
      state.globalTenant = action.globalTenant;
    })
    .addCase(setGlobalLogin, (state, action) => {
      state.globalLogin = action.globalLogin;
    })
    .addCase(setShowGlobalTopBar, (state, action) => {
      state.showGlobalTopBar = action.showGlobalTopBar;
    })
    .addCase(setConsentUrl, (state, action) => {
      state.consentUrl = action.consentUrl;
    })
    .addDefaultCase(state => state);
});

export default Tenant;
