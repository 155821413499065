/* All this constants are the representation of FHIR Release 5 Draft Ballot */

export const ETHNIC_GROUP = [
  {
    code: '1002-5',
    display: 'American Indian or Alaska Native',
  },
  {
    code: '2028-9',
    display: 'Asian',
  },
  {
    code: '2054-5',
    display: 'Black or African American',
  },
  {
    code: '2076-8',
    display: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    code: '2106-3',
    display: 'White',
  },
  {
    code: '2131-1',
    display: 'Other Race',
  },
];

export const LANGUAGES = [
  {
    code: 'ar',
    display: 'Arabic',
  },
  {
    code: 'bn',
    display: 'Bengali',
  },
  {
    code: 'cs',
    display: 'Czech',
  },
  {
    code: 'da',
    display: 'Danish',
  },
  {
    code: 'de',
    display: 'German',
  },
  {
    code: 'de-AT',
    display: 'German (Austria)',
  },
  {
    code: 'de-CH',
    display: 'German (Switzerland)',
  },
  {
    code: 'de-DE',
    display: 'German (Germany)',
  },
  {
    code: 'el',
    display: 'Greek',
  },
  {
    code: 'en',
    display: 'English',
  },
  {
    code: 'en-AU',
    display: 'English (Australia)',
  },
  {
    code: 'en-CA',
    display: 'English (Canada)',
  },
  {
    code: 'en-GB',
    display: 'English (Great Britain)',
  },
  {
    code: 'en-IN',
    display: 'English (India)',
  },
  {
    code: 'en-NZ',
    display: 'English (New Zealand)',
  },
  {
    code: 'en-SG',
    display: 'English (Singapore)',
  },
  {
    code: 'en-US',
    display: 'English (United States)',
  },
  {
    code: 'es',
    display: 'Spanish',
  },
  {
    code: 'es-AR',
    display: 'Spanish (Argentina)',
  },
  {
    code: 'es-ES',
    display: 'Spanish (Spain)',
  },
  {
    code: 'es-UY',
    display: 'Spanish (Uruguay)',
  },
  {
    code: 'fi',
    display: 'Finnish',
  },
  {
    code: 'fr',
    display: 'French',
  },
  {
    code: 'fr-BE',
    display: 'French (Belgium)',
  },
  {
    code: 'fr-CH',
    display: 'French (Switzerland)',
  },
  {
    code: 'fr-FR',
    display: 'French (France)',
  },
  {
    code: 'fy',
    display: 'Frysian',
  },
  {
    code: 'fy-NL',
    display: 'Frysian (Netherlands)',
  },
  {
    code: 'hi',
    display: 'Hindi',
  },
  {
    code: 'hr',
    display: 'Croatian',
  },
  {
    code: 'it',
    display: 'Italian',
  },
  {
    code: 'it-CH',
    display: 'Italian (Switzerland)',
  },
  {
    code: 'it-IT',
    display: 'Italian (Italy)',
  },
  {
    code: 'ja',
    display: 'Japanese',
  },
  {
    code: 'ko',
    display: 'Korean',
  },
  {
    code: 'nl',
    display: 'Dutch',
  },
  {
    code: 'nl-BE',
    display: 'Dutch (Belgium)',
  },
  {
    code: 'nl-NL',
    display: 'Dutch (Netherlands)',
  },
  {
    code: 'no',
    display: 'Norwegian',
  },
  {
    code: 'no-NO',
    display: 'Norwegian (Norway)',
  },
  {
    code: 'pa',
    display: 'Punjabi',
  },
  {
    code: 'pl',
    display: 'Polish',
  },
  {
    code: 'pt',
    display: 'Portuguese',
  },
  {
    code: 'pt-BR',
    display: 'Portuguese (Brazil)',
  },
  {
    code: 'ru',
    display: 'Russian',
  },
  {
    code: 'ru-RU',
    display: 'Russian (Russia)',
  },
  {
    code: 'sr',
    display: 'Serbian',
  },
  {
    code: 'sr-RS',
    display: 'Serbian (Serbia)',
  },
  {
    code: 'sv',
    display: 'Swedish',
  },
  {
    code: 'sv-SE',
    display: 'Swedish (Sweden)',
  },
  {
    code: 'te',
    display: 'Telegu',
  },
  {
    code: 'zh',
    display: 'Chinese',
  },
  {
    code: 'zh-CN',
    display: 'Chinese (China)',
  },
  {
    code: 'zh-HK',
    display: 'Chinese (Hong Kong)',
  },
  {
    code: 'zh-SG',
    display: 'Chinese (Singapore)',
  },
  {
    code: 'zh-TW',
    display: 'Chinese (Taiwan)',
  },
];

export const SEXUAL_ORIENTATION = [
  {
    code: 'AS',
    display: 'Asexual',
    definition: 'Person has an Asexual orientation.',
  },
  {
    code: 'BI',
    display: 'Bisexual',
    definition: 'Person has a Bisexual orientation.',
  },
  {
    code: 'GA',
    display: 'Gay',
    definition: 'Person has a Gay sexual orientation.',
  },
  {
    code: 'LE',
    display: 'Lesbian',
    definition: 'Person has a Lesbian sexual orientation.',
  },
  {
    code: 'QU',
    display: 'Queer',
    definition: 'Person has a Queer sexual orientation.',
  },
  {
    code: 'QS',
    display: 'Questioning',
    definition: 'Person has a sexual orientation of Questioning.',
  },
  {
    code: 'ST',
    display: 'Straight',
    definition: 'Person has a Straight sexual orientation.',
  },
  {
    code: 'SE',
    display: 'Something Else',
    definition: 'Person has sexual orientation of Something Else.',
  },
  {
    code: 'MU',
    display: 'Multiple Sexual Orientations',
    definition: 'Person has Multiple sexual orientations.',
  },
  {
    code: 'DC',
    display: 'Decline to Answer',
    definition: 'Person has declined to answer about their sexual orientataion.',
  },
  {
    code: 'NI',
    display: 'No Information',
    definition: 'There is no information about the Persons sexual orientation',
  },
  {
    code: 'UN',
    display: 'Unknown',
    definition: 'Persons sexual orientation is unknown.',
  },
  {
    code: 'OT',
    display: 'Other',
    definition: 'Persons sexual orientation is Other.',
  },
];

export const GENDER_IDENTITY = [
  {
    code: '446141000124107',
    display: 'Identifies as female gender (finding)',
  },
  {
    code: ' ',
    display: 'Identifies as male gender (finding)',
  },
  {
    code: '33791000087105',
    display: 'Identifies as nonbinary gender (finding)',
  },
];

export const RACE = [
  {
    code: '1002-5',
    display: 'American Indian or Alaska Native',
  },
  {
    code: '1004-1',
    display: 'American Indian',
  },
  {
    code: '1006-6',
    display: 'Abenaki',
  },
  {
    code: '1008-2',
    display: 'Algonquian',
  },
  {
    code: '1010-8',
    display: 'Apache',
  },
  {
    code: '1011-6',
    display: 'Chiricahua',
  },
  {
    code: '1012-4',
    display: 'Fort Sill Apache',
  },
  {
    code: '1013-2',
    display: 'Jicarilla Apache',
  },
  {
    code: '1014-0',
    display: 'Lipan Apache',
  },
  {
    code: '1015-7',
    display: 'Mescalero Apache',
  },
  {
    code: '1016-5',
    display: 'Oklahoma Apache',
  },
  {
    code: '1017-3',
    display: 'Payson Apache',
  },
  {
    code: '1018-1',
    display: 'San Carlos Apache',
  },
  {
    code: '1019-9',
    display: 'White Mountain Apache',
  },
  {
    code: '1021-5',
    display: 'Arapaho',
  },
  {
    code: '1022-3',
    display: 'Northern Arapaho',
  },
  {
    code: '1023-1',
    display: 'Southern Arapaho',
  },
  {
    code: '1024-9',
    display: 'Wind River Arapaho',
  },
  {
    code: '1026-4',
    display: 'Arikara',
  },
  {
    code: '1028-0',
    display: 'Assiniboine',
  },
  {
    code: '1030-6',
    display: 'Assiniboine Sioux',
  },
  {
    code: '1031-4',
    display: 'Fort Peck Assiniboine Sioux',
  },
  {
    code: '1033-0',
    display: 'Bannock',
  },
  {
    code: '1035-5',
    display: 'Blackfeet',
  },
  {
    code: '1037-1',
    display: 'Brotherton',
  },
  {
    code: '1039-7',
    display: 'Burt Lake Band',
  },
  {
    code: '1041-3',
    display: 'Caddo',
  },
  {
    code: '1042-1',
    display: 'Oklahoma Cado',
  },
  {
    code: '1044-7',
    display: 'Cahuilla',
  },
  {
    code: '1045-4',
    display: 'Agua Caliente Cahuilla',
  },
  {
    code: '1046-2',
    display: 'Augustine',
  },
  {
    code: '1047-0',
    display: 'Cabazon',
  },
  {
    code: '1048-8',
    display: 'Los Coyotes',
  },
  {
    code: '1049-6',
    display: 'Morongo',
  },
  {
    code: '1050-4',
    display: 'Santa Rosa Cahuilla',
  },
  {
    code: '1051-2',
    display: 'Torres-Martinez',
  },
  {
    code: '1053-8',
    display: 'California Tribes',
  },
  {
    code: '1054-6',
    display: 'Cahto',
  },
  {
    code: '1055-3',
    display: 'Chimariko',
  },
  {
    code: '1056-1',
    display: 'Coast Miwok',
  },
  {
    code: '1057-9',
    display: 'Digger',
  },
  {
    code: '1058-7',
    display: 'Kawaiisu',
  },
  {
    code: '1059-5',
    display: 'Kern River',
  },
  {
    code: '1060-3',
    display: 'Mattole',
  },
  {
    code: '1061-1',
    display: 'Red Wood',
  },
  {
    code: '1062-9',
    display: 'Santa Rosa',
  },
  {
    code: '1063-7',
    display: 'Takelma',
  },
  {
    code: '1064-5',
    display: 'Wappo',
  },
  {
    code: '1065-2',
    display: 'Yana',
  },
  {
    code: '1066-0',
    display: 'Yuki',
  },
  {
    code: '1068-6',
    display: 'Canadian and Latin American Indian',
  },
  {
    code: '1069-4',
    display: 'Canadian Indian',
  },
  {
    code: '1070-2',
    display: 'Central American Indian',
  },
  {
    code: '1071-0',
    display: 'French American Indian',
  },
  {
    code: '1072-8',
    display: 'Mexican American Indian',
  },
  {
    code: '1073-6',
    display: 'South American Indian',
  },
  {
    code: '1074-4',
    display: 'Spanish American Indian',
  },
  {
    code: '1076-9',
    display: 'Catawba',
  },
  {
    code: '1741-8',
    display: 'Alatna',
  },
  {
    code: '1742-6',
    display: 'Alexander',
  },
  {
    code: '1743-4',
    display: 'Allakaket',
  },
  {
    code: '1744-2',
    display: 'Alanvik',
  },
  {
    code: '1745-9',
    display: 'Anvik',
  },
  {
    code: '1746-7',
    display: 'Arctic',
  },
  {
    code: '1747-5',
    display: 'Beaver',
  },
  {
    code: '1748-3',
    display: 'Birch Creek',
  },
  {
    code: '1749-1',
    display: 'Cantwell',
  },
  {
    code: '1750-9',
    display: 'Chalkyitsik',
  },
  {
    code: '1751-7',
    display: 'Chickaloon',
  },
  {
    code: '1752-5',
    display: 'Chistochina',
  },
  {
    code: '1753-3',
    display: 'Chitina',
  },
  {
    code: '1754-1',
    display: 'Circle',
  },
  {
    code: '1755-8',
    display: 'Cook Inlet',
  },
  {
    code: '1756-6',
    display: 'Copper Center',
  },
  {
    code: '1757-4',
    display: 'Copper River',
  },
  {
    code: '1758-2',
    display: 'Dot Lake',
  },
  {
    code: '1759-0',
    display: 'Doyon',
  },
  {
    code: '1760-8',
    display: 'Eagle',
  },
  {
    code: '1761-6',
    display: 'Eklutna',
  },
  {
    code: '1762-4',
    display: 'Evansville',
  },
  {
    code: '1763-2',
    display: 'Fort Yukon',
  },
  {
    code: '1764-0',
    display: 'Gakona',
  },
  {
    code: '1765-7',
    display: 'Galena',
  },
  {
    code: '1766-5',
    display: 'Grayling',
  },
  {
    code: '1767-3',
    display: 'Gulkana',
  },
  {
    code: '1768-1',
    display: 'Healy Lake',
  },
  {
    code: '1769-9',
    display: 'Holy Cross',
  },
  {
    code: '1770-7',
    display: 'Hughes',
  },
  {
    code: '1771-5',
    display: 'Huslia',
  },
  {
    code: '1772-3',
    display: 'Iliamna',
  },
  {
    code: '1773-1',
    display: 'Kaltag',
  },
  {
    code: '1774-9',
    display: 'Kluti Kaah',
  },
  {
    code: '1775-6',
    display: 'Knik',
  },
  {
    code: '1776-4',
    display: 'Koyukuk',
  },
  {
    code: '1777-2',
    display: 'Lake Minchumina',
  },
  {
    code: '1778-0',
    display: 'Lime',
  },
  {
    code: '1779-8',
    display: 'Mcgrath',
  },
  {
    code: '1780-6',
    display: 'Manley Hot Springs',
  },
  {
    code: '1781-4',
    display: 'Mentasta Lake',
  },
  {
    code: '1782-2',
    display: 'Minto',
  },
  {
    code: '1783-0',
    display: 'Nenana',
  },
  {
    code: '1784-8',
    display: 'Nikolai',
  },
  {
    code: '1785-5',
    display: 'Ninilchik',
  },
  {
    code: '1786-3',
    display: 'Nondalton',
  },
  {
    code: '1787-1',
    display: 'Northway',
  },
  {
    code: '1788-9',
    display: 'Nulato',
  },
  {
    code: '1789-7',
    display: 'Pedro Bay',
  },
  {
    code: '1790-5',
    display: 'Rampart',
  },
  {
    code: '1791-3',
    display: 'Ruby',
  },
  {
    code: '1792-1',
    display: 'Salamatof',
  },
  {
    code: '1793-9',
    display: 'Seldovia',
  },
  {
    code: '1794-7',
    display: 'Slana',
  },
  {
    code: '1795-4',
    display: 'Shageluk',
  },
  {
    code: '1796-2',
    display: 'Stevens',
  },
  {
    code: '1797-0',
    display: 'Stony River',
  },
  {
    code: '1798-8',
    display: 'Takotna',
  },
  {
    code: '1799-6',
    display: 'Tanacross',
  },
  {
    code: '1800-2',
    display: 'Tanaina',
  },
  {
    code: '1801-0',
    display: 'Tanana',
  },
  {
    code: '1802-8',
    display: 'Tanana Chiefs',
  },
  {
    code: '1803-6',
    display: 'Tazlina',
  },
  {
    code: '1804-4',
    display: 'Telida',
  },
  {
    code: '1805-1',
    display: 'Tetlin',
  },
  {
    code: '1806-9',
    display: 'Tok',
  },
  {
    code: '1807-7',
    display: 'Tyonek',
  },
  {
    code: '1808-5',
    display: 'Venetie',
  },
  {
    code: '1809-3',
    display: 'Wiseman',
  },
  {
    code: '1078-5',
    display: 'Cayuse',
  },
  {
    code: '1080-1',
    display: 'Chehalis',
  },
  {
    code: '1082-7',
    display: 'Chemakuan',
  },
  {
    code: '1083-5',
    display: 'Hoh',
  },
  {
    code: '1084-3',
    display: 'Quileute',
  },
  {
    code: '1086-8',
    display: 'Chemehuevi',
  },
  {
    code: '1088-4',
    display: 'Cherokee',
  },
  {
    code: '1089-2',
    display: 'Cherokee Alabama',
  },
  {
    code: '1090-0',
    display: 'Cherokees of Northeast Alabama',
  },
  {
    code: '1091-8',
    display: 'Cherokees of Southeast Alabama',
  },
  {
    code: '1092-6',
    display: 'Eastern Cherokee',
  },
  {
    code: '1093-4',
    display: 'Echota Cherokee',
  },
  {
    code: '1094-2',
    display: 'Etowah Cherokee',
  },
  {
    code: '1095-9',
    display: 'Northern Cherokee',
  },
  {
    code: '1096-7',
    display: 'Tuscola',
  },
  {
    code: '1097-5',
    display: 'United Keetowah Band of Cherokee',
  },
  {
    code: '1098-3',
    display: 'Western Cherokee',
  },
  {
    code: '1100-7',
    display: 'Cherokee Shawnee',
  },
  {
    code: '1102-3',
    display: 'Cheyenne',
  },
  {
    code: '1103-1',
    display: 'Northern Cheyenne',
  },
  {
    code: '1104-9',
    display: 'Southern Cheyenne',
  },
  {
    code: '1106-4',
    display: 'Cheyenne-Arapaho',
  },
  {
    code: '1108-0',
    display: 'Chickahominy',
  },
  {
    code: '1109-8',
    display: 'Eastern Chickahominy',
  },
  {
    code: '1110-6',
    display: 'Western Chickahominy',
  },
  {
    code: '1112-2',
    display: 'Chickasaw',
  },
  {
    code: '1114-8',
    display: 'Chinook',
  },
  {
    code: '1115-5',
    display: 'Clatsop',
  },
  {
    code: '1116-3',
    display: 'Columbia River Chinook',
  },
  {
    code: '1117-1',
    display: 'Kathlamet',
  },
  {
    code: '1118-9',
    display: 'Upper Chinook',
  },
  {
    code: '1119-7',
    display: 'Wakiakum Chinook',
  },
  {
    code: '1120-5',
    display: 'Willapa Chinook',
  },
  {
    code: '1121-3',
    display: 'Wishram',
  },
  {
    code: '1123-9',
    display: 'Chippewa',
  },
  {
    code: '1124-7',
    display: 'Bad River',
  },
  {
    code: '1125-4',
    display: 'Bay Mills Chippewa',
  },
  {
    code: '1126-2',
    display: 'Bois Forte',
  },
  {
    code: '1127-0',
    display: 'Burt Lake Chippewa',
  },
  {
    code: '1128-8',
    display: 'Fond du Lac',
  },
  {
    code: '1129-6',
    display: 'Grand Portage',
  },
  {
    code: '1130-4',
    display: 'Grand Traverse Band of Ottawa-Chippewa',
  },
  {
    code: '1131-2',
    display: 'Keweenaw',
  },
  {
    code: '1132-0',
    display: 'Lac Courte Oreilles',
  },
  {
    code: '1133-8',
    display: 'Lac du Flambeau',
  },
  {
    code: '1134-6',
    display: 'Lac Vieux Desert Chippewa',
  },
  {
    code: '1135-3',
    display: 'Lake Superior',
  },
  {
    code: '1136-1',
    display: 'Leech Lake',
  },
  {
    code: '1137-9',
    display: 'Little Shell Chippewa',
  },
  {
    code: '1138-7',
    display: 'Mille Lacs',
  },
  {
    code: '1139-5',
    display: 'Minnesota Chippewa',
  },
  {
    code: '1140-3',
    display: 'Ontonagon',
  },
  {
    code: '1141-1',
    display: 'Red Cliff Chippewa',
  },
  {
    code: '1142-9',
    display: 'Red Lake Chippewa',
  },
  {
    code: '1143-7',
    display: 'Saginaw Chippewa',
  },
  {
    code: '1144-5',
    display: 'St. Croix Chippewa',
  },
  {
    code: '1145-2',
    display: 'Sault Ste. Marie Chippewa',
  },
  {
    code: '1146-0',
    display: 'Sokoagon Chippewa',
  },
  {
    code: '1147-8',
    display: 'Turtle Mountain',
  },
  {
    code: '1148-6',
    display: 'White Earth',
  },
  {
    code: '1150-2',
    display: 'Chippewa Cree',
  },
  {
    code: '1151-0',
    display: 'Rocky Boy\'s Chippewa Cree',
  },
  {
    code: '1153-6',
    display: 'Chitimacha',
  },
  {
    code: '1155-1',
    display: 'Choctaw',
  },
  {
    code: '1156-9',
    display: 'Clifton Choctaw',
  },
  {
    code: '1157-7',
    display: 'Jena Choctaw',
  },
  {
    code: '1158-5',
    display: 'Mississippi Choctaw',
  },
  {
    code: '1159-3',
    display: 'Mowa Band of Choctaw',
  },
  {
    code: '1160-1',
    display: 'Oklahoma Choctaw',
  },
  {
    code: '1162-7',
    display: 'Chumash',
  },
  {
    code: '1163-5',
    display: 'Santa Ynez',
  },
  {
    code: '1165-0',
    display: 'Clear Lake',
  },
  {
    code: '1167-6',
    display: 'Coeur D\'Alene',
  },
  {
    code: '1169-2',
    display: 'Coharie',
  },
  {
    code: '1171-8',
    display: 'Colorado River',
  },
  {
    code: '1173-4',
    display: 'Colville',
  },
  {
    code: '1175-9',
    display: 'Comanche',
  },
  {
    code: '1176-7',
    display: 'Oklahoma Comanche',
  },
  {
    code: '1178-3',
    display: 'Coos, Lower Umpqua, Siuslaw',
  },
  {
    code: '1180-9',
    display: 'Coos',
  },
  {
    code: '1182-5',
    display: 'Coquilles',
  },
  {
    code: '1184-1',
    display: 'Costanoan',
  },
  {
    code: '1186-6',
    display: 'Coushatta',
  },
  {
    code: '1187-4',
    display: 'Alabama Coushatta',
  },
  {
    code: '1189-0',
    display: 'Cowlitz',
  },
  {
    code: '1191-6',
    display: 'Cree',
  },
  {
    code: '1193-2',
    display: 'Creek',
  },
  {
    code: '1194-0',
    display: 'Alabama Creek',
  },
  {
    code: '1195-7',
    display: 'Alabama Quassarte',
  },
  {
    code: '1196-5',
    display: 'Eastern Creek',
  },
  {
    code: '1197-3',
    display: 'Eastern Muscogee',
  },
  {
    code: '1198-1',
    display: 'Kialegee',
  },
  {
    code: '1199-9',
    display: 'Lower Muscogee',
  },
  {
    code: '1200-5',
    display: 'Machis Lower Creek Indian',
  },
  {
    code: '1201-3',
    display: 'Poarch Band',
  },
  {
    code: '1202-1',
    display: 'Principal Creek Indian Nation',
  },
  {
    code: '1203-9',
    display: 'Star Clan of Muscogee Creeks',
  },
  {
    code: '1204-7',
    display: 'Thlopthlocco',
  },
  {
    code: '1205-4',
    display: 'Tuckabachee',
  },
  {
    code: '1207-0',
    display: 'Croatan',
  },
  {
    code: '1209-6',
    display: 'Crow',
  },
  {
    code: '1211-2',
    display: 'Cupeno',
  },
  {
    code: '1212-0',
    display: 'Agua Caliente',
  },
  {
    code: '1214-6',
    display: 'Delaware',
  },
  {
    code: '1215-3',
    display: 'Eastern Delaware',
  },
  {
    code: '1216-1',
    display: 'Lenni-Lenape',
  },
  {
    code: '1217-9',
    display: 'Munsee',
  },
  {
    code: '1218-7',
    display: 'Oklahoma Delaware',
  },
  {
    code: '1219-5',
    display: 'Rampough Mountain',
  },
  {
    code: '1220-3',
    display: 'Sand Hill',
  },
  {
    code: '1222-9',
    display: 'Diegueno',
  },
  {
    code: '1223-7',
    display: 'Campo',
  },
  {
    code: '1224-5',
    display: 'Capitan Grande',
  },
  {
    code: '1225-2',
    display: 'Cuyapaipe',
  },
  {
    code: '1226-0',
    display: 'La Posta',
  },
  {
    code: '1227-8',
    display: 'Manzanita',
  },
  {
    code: '1228-6',
    display: 'Mesa Grande',
  },
  {
    code: '1229-4',
    display: 'San Pasqual',
  },
  {
    code: '1230-2',
    display: 'Santa Ysabel',
  },
  {
    code: '1231-0',
    display: 'Sycuan',
  },
  {
    code: '1233-6',
    display: 'Eastern Tribes',
  },
  {
    code: '1234-4',
    display: 'Attacapa',
  },
  {
    code: '1235-1',
    display: 'Biloxi',
  },
  {
    code: '1236-9',
    display: 'Georgetown',
  },
  {
    code: '1237-7',
    display: 'Moor',
  },
  {
    code: '1238-5',
    display: 'Nansemond',
  },
  {
    code: '1239-3',
    display: 'Natchez',
  },
  {
    code: '1240-1',
    display: 'Nausu Waiwash',
  },
  {
    code: '1241-9',
    display: 'Nipmuc',
  },
  {
    code: '1242-7',
    display: 'Paugussett',
  },
  {
    code: '1243-5',
    display: 'Pocomoke Acohonock',
  },
  {
    code: '1244-3',
    display: 'Southeastern Indians',
  },
  {
    code: '1245-0',
    display: 'Susquehanock',
  },
  {
    code: '1246-8',
    display: 'Tunica Biloxi',
  },
  {
    code: '1247-6',
    display: 'Waccamaw-Siousan',
  },
  {
    code: '1248-4',
    display: 'Wicomico',
  },
  {
    code: '1250-0',
    display: 'Esselen',
  },
  {
    code: '1252-6',
    display: 'Fort Belknap',
  },
  {
    code: '1254-2',
    display: 'Fort Berthold',
  },
  {
    code: '1256-7',
    display: 'Fort Mcdowell',
  },
  {
    code: '1258-3',
    display: 'Fort Hall',
  },
  {
    code: '1260-9',
    display: 'Gabrieleno',
  },
  {
    code: '1262-5',
    display: 'Grand Ronde',
  },
  {
    code: '1264-1',
    display: 'Gros Ventres',
  },
  {
    code: '1265-8',
    display: 'Atsina',
  },
  {
    code: '1267-4',
    display: 'Haliwa',
  },
  {
    code: '1269-0',
    display: 'Hidatsa',
  },
  {
    code: '1271-6',
    display: 'Hoopa',
  },
  {
    code: '1272-4',
    display: 'Trinity',
  },
  {
    code: '1273-2',
    display: 'Whilkut',
  },
  {
    code: '1275-7',
    display: 'Hoopa Extension',
  },
  {
    code: '1277-3',
    display: 'Houma',
  },
  {
    code: '1279-9',
    display: 'Inaja-Cosmit',
  },
  {
    code: '1281-5',
    display: 'Iowa',
  },
  {
    code: '1282-3',
    display: 'Iowa of Kansas-Nebraska',
  },
  {
    code: '1283-1',
    display: 'Iowa of Oklahoma',
  },
  {
    code: '1285-6',
    display: 'Iroquois',
  },
  {
    code: '1286-4',
    display: 'Cayuga',
  },
  {
    code: '1287-2',
    display: 'Mohawk',
  },
  {
    code: '1288-0',
    display: 'Oneida',
  },
  {
    code: '1289-8',
    display: 'Onondaga',
  },
  {
    code: '1290-6',
    display: 'Seneca',
  },
  {
    code: '1291-4',
    display: 'Seneca Nation',
  },
  {
    code: '1292-2',
    display: 'Seneca-Cayuga',
  },
  {
    code: '1293-0',
    display: 'Tonawanda Seneca',
  },
  {
    code: '1294-8',
    display: 'Tuscarora',
  },
  {
    code: '1295-5',
    display: 'Wyandotte',
  },
  {
    code: '1297-1',
    display: 'Juaneno',
  },
  {
    code: '1299-7',
    display: 'Kalispel',
  },
  {
    code: '1301-1',
    display: 'Karuk',
  },
  {
    code: '1303-7',
    display: 'Kaw',
  },
  {
    code: '1305-2',
    display: 'Kickapoo',
  },
  {
    code: '1306-0',
    display: 'Oklahoma Kickapoo',
  },
  {
    code: '1307-8',
    display: 'Texas Kickapoo',
  },
  {
    code: '1309-4',
    display: 'Kiowa',
  },
  {
    code: '1310-2',
    display: 'Oklahoma Kiowa',
  },
  {
    code: '1312-8',
    display: 'Klallam',
  },
  {
    code: '1313-6',
    display: 'Jamestown',
  },
  {
    code: '1314-4',
    display: 'Lower Elwha',
  },
  {
    code: '1315-1',
    display: 'Port Gamble Klallam',
  },
  {
    code: '1317-7',
    display: 'Klamath',
  },
  {
    code: '1319-3',
    display: 'Konkow',
  },
  {
    code: '1321-9',
    display: 'Kootenai',
  },
  {
    code: '1323-5',
    display: 'Lassik',
  },
  {
    code: '1325-0',
    display: 'Long Island',
  },
  {
    code: '1326-8',
    display: 'Matinecock',
  },
  {
    code: '1327-6',
    display: 'Montauk',
  },
  {
    code: '1328-4',
    display: 'Poospatuck',
  },
  {
    code: '1329-2',
    display: 'Setauket',
  },
  {
    code: '1331-8',
    display: 'Luiseno',
  },
  {
    code: '1332-6',
    display: 'La Jolla',
  },
  {
    code: '1333-4',
    display: 'Pala',
  },
  {
    code: '1334-2',
    display: 'Pauma',
  },
  {
    code: '1335-9',
    display: 'Pechanga',
  },
  {
    code: '1336-7',
    display: 'Soboba',
  },
  {
    code: '1337-5',
    display: 'Twenty-Nine Palms',
  },
  {
    code: '1338-3',
    display: 'Temecula',
  },
  {
    code: '1340-9',
    display: 'Lumbee',
  },
  {
    code: '1342-5',
    display: 'Lummi',
  },
  {
    code: '1344-1',
    display: 'Maidu',
  },
  {
    code: '1345-8',
    display: 'Mountain Maidu',
  },
  {
    code: '1346-6',
    display: 'Nishinam',
  },
  {
    code: '1348-2',
    display: 'Makah',
  },
  {
    code: '1350-8',
    display: 'Maliseet',
  },
  {
    code: '1352-4',
    display: 'Mandan',
  },
  {
    code: '1354-0',
    display: 'Mattaponi',
  },
  {
    code: '1356-5',
    display: 'Menominee',
  },
  {
    code: '1358-1',
    display: 'Miami',
  },
  {
    code: '1359-9',
    display: 'Illinois Miami',
  },
  {
    code: '1360-7',
    display: 'Indiana Miami',
  },
  {
    code: '1361-5',
    display: 'Oklahoma Miami',
  },
  {
    code: '1363-1',
    display: 'Miccosukee',
  },
  {
    code: '1365-6',
    display: 'Micmac',
  },
  {
    code: '1366-4',
    display: 'Aroostook',
  },
  {
    code: '1368-0',
    display: 'Mission Indians',
  },
  {
    code: '1370-6',
    display: 'Miwok',
  },
  {
    code: '1372-2',
    display: 'Modoc',
  },
  {
    code: '1374-8',
    display: 'Mohegan',
  },
  {
    code: '1376-3',
    display: 'Mono',
  },
  {
    code: '1378-9',
    display: 'Nanticoke',
  },
  {
    code: '1380-5',
    display: 'Narragansett',
  },
  {
    code: '1382-1',
    display: 'Navajo',
  },
  {
    code: '1383-9',
    display: 'Alamo Navajo',
  },
  {
    code: '1384-7',
    display: 'Canoncito Navajo',
  },
  {
    code: '1385-4',
    display: 'Ramah Navajo',
  },
  {
    code: '1387-0',
    display: 'Nez Perce',
  },
  {
    code: '1389-6',
    display: 'Nomalaki',
  },
  {
    code: '1391-2',
    display: 'Northwest Tribes',
  },
  {
    code: '1392-0',
    display: 'Alsea',
  },
  {
    code: '1393-8',
    display: 'Celilo',
  },
  {
    code: '1394-6',
    display: 'Columbia',
  },
  {
    code: '1395-3',
    display: 'Kalapuya',
  },
  {
    code: '1396-1',
    display: 'Molala',
  },
  {
    code: '1397-9',
    display: 'Talakamish',
  },
  {
    code: '1398-7',
    display: 'Tenino',
  },
  {
    code: '1399-5',
    display: 'Tillamook',
  },
  {
    code: '1400-1',
    display: 'Wenatchee',
  },
  {
    code: '1401-9',
    display: 'Yahooskin',
  },
  {
    code: '1403-5',
    display: 'Omaha',
  },
  {
    code: '1405-0',
    display: 'Oregon Athabaskan',
  },
  {
    code: '1407-6',
    display: 'Osage',
  },
  {
    code: '1409-2',
    display: 'Otoe-Missouria',
  },
  {
    code: '1411-8',
    display: 'Ottawa',
  },
  {
    code: '1412-6',
    display: 'Burt Lake Ottawa',
  },
  {
    code: '1413-4',
    display: 'Michigan Ottawa',
  },
  {
    code: '1414-2',
    display: 'Oklahoma Ottawa',
  },
  {
    code: '1416-7',
    display: 'Paiute',
  },
  {
    code: '1417-5',
    display: 'Bishop',
  },
  {
    code: '1418-3',
    display: 'Bridgeport',
  },
  {
    code: '1419-1',
    display: 'Burns Paiute',
  },
  {
    code: '1420-9',
    display: 'Cedarville',
  },
  {
    code: '1421-7',
    display: 'Fort Bidwell',
  },
  {
    code: '1422-5',
    display: 'Fort Independence',
  },
  {
    code: '1423-3',
    display: 'Kaibab',
  },
  {
    code: '1424-1',
    display: 'Las Vegas',
  },
  {
    code: '1425-8',
    display: 'Lone Pine',
  },
  {
    code: '1426-6',
    display: 'Lovelock',
  },
  {
    code: '1427-4',
    display: 'Malheur Paiute',
  },
  {
    code: '1428-2',
    display: 'Moapa',
  },
  {
    code: '1429-0',
    display: 'Northern Paiute',
  },
  {
    code: '1430-8',
    display: 'Owens Valley',
  },
  {
    code: '1431-6',
    display: 'Pyramid Lake',
  },
  {
    code: '1432-4',
    display: 'San Juan Southern Paiute',
  },
  {
    code: '1433-2',
    display: 'Southern Paiute',
  },
  {
    code: '1434-0',
    display: 'Summit Lake',
  },
  {
    code: '1435-7',
    display: 'Utu Utu Gwaitu Paiute',
  },
  {
    code: '1436-5',
    display: 'Walker River',
  },
  {
    code: '1437-3',
    display: 'Yerington Paiute',
  },
  {
    code: '1439-9',
    display: 'Pamunkey',
  },
  {
    code: '1441-5',
    display: 'Passamaquoddy',
  },
  {
    code: '1442-3',
    display: 'Indian Township',
  },
  {
    code: '1443-1',
    display: 'Pleasant Point Passamaquoddy',
  },
  {
    code: '1445-6',
    display: 'Pawnee',
  },
  {
    code: '1446-4',
    display: 'Oklahoma Pawnee',
  },
  {
    code: '1448-0',
    display: 'Penobscot',
  },
  {
    code: '1450-6',
    display: 'Peoria',
  },
  {
    code: '1451-4',
    display: 'Oklahoma Peoria',
  },
  {
    code: '1453-0',
    display: 'Pequot',
  },
  {
    code: '1454-8',
    display: 'Marshantucket Pequot',
  },
  {
    code: '1456-3',
    display: 'Pima',
  },
  {
    code: '1457-1',
    display: 'Gila River Pima-Maricopa',
  },
  {
    code: '1458-9',
    display: 'Salt River Pima-Maricopa',
  },
  {
    code: '1460-5',
    display: 'Piscataway',
  },
  {
    code: '1462-1',
    display: 'Pit River',
  },
  {
    code: '1464-7',
    display: 'Pomo',
  },
  {
    code: '1465-4',
    display: 'Central Pomo',
  },
  {
    code: '1466-2',
    display: 'Dry Creek',
  },
  {
    code: '1467-0',
    display: 'Eastern Pomo',
  },
  {
    code: '1468-8',
    display: 'Kashia',
  },
  {
    code: '1469-6',
    display: 'Northern Pomo',
  },
  {
    code: '1470-4',
    display: 'Scotts Valley',
  },
  {
    code: '1471-2',
    display: 'Stonyford',
  },
  {
    code: '1472-0',
    display: 'Sulphur Bank',
  },
  {
    code: '1474-6',
    display: 'Ponca',
  },
  {
    code: '1475-3',
    display: 'Nebraska Ponca',
  },
  {
    code: '1476-1',
    display: 'Oklahoma Ponca',
  },
  {
    code: '1478-7',
    display: 'Potawatomi',
  },
  {
    code: '1479-5',
    display: 'Citizen Band Potawatomi',
  },
  {
    code: '1480-3',
    display: 'Forest County',
  },
  {
    code: '1481-1',
    display: 'Hannahville',
  },
  {
    code: '1482-9',
    display: 'Huron Potawatomi',
  },
  {
    code: '1483-7',
    display: 'Pokagon Potawatomi',
  },
  {
    code: '1484-5',
    display: 'Prairie Band',
  },
  {
    code: '1485-2',
    display: 'Wisconsin Potawatomi',
  },
  {
    code: '1487-8',
    display: 'Powhatan',
  },
  {
    code: '1489-4',
    display: 'Pueblo',
  },
  {
    code: '1490-2',
    display: 'Acoma',
  },
  {
    code: '1491-0',
    display: 'Arizona Tewa',
  },
  {
    code: '1492-8',
    display: 'Cochiti',
  },
  {
    code: '1493-6',
    display: 'Hopi',
  },
  {
    code: '1494-4',
    display: 'Isleta',
  },
  {
    code: '1495-1',
    display: 'Jemez',
  },
  {
    code: '1496-9',
    display: 'Keres',
  },
  {
    code: '1497-7',
    display: 'Laguna',
  },
  {
    code: '1498-5',
    display: 'Nambe',
  },
  {
    code: '1499-3',
    display: 'Picuris',
  },
  {
    code: '1500-8',
    display: 'Piro',
  },
  {
    code: '1501-6',
    display: 'Pojoaque',
  },
  {
    code: '1502-4',
    display: 'San Felipe',
  },
  {
    code: '1503-2',
    display: 'San Ildefonso',
  },
  {
    code: '1504-0',
    display: 'San Juan Pueblo',
  },
  {
    code: '1505-7',
    display: 'San Juan De',
  },
  {
    code: '1506-5',
    display: 'San Juan',
  },
  {
    code: '1507-3',
    display: 'Sandia',
  },
  {
    code: '1508-1',
    display: 'Santa Ana',
  },
  {
    code: '1509-9',
    display: 'Santa Clara',
  },
  {
    code: '1510-7',
    display: 'Santo Domingo',
  },
  {
    code: '1511-5',
    display: 'Taos',
  },
  {
    code: '1512-3',
    display: 'Tesuque',
  },
  {
    code: '1513-1',
    display: 'Tewa',
  },
  {
    code: '1514-9',
    display: 'Tigua',
  },
  {
    code: '1515-6',
    display: 'Zia',
  },
  {
    code: '1516-4',
    display: 'Zuni',
  },
  {
    code: '1518-0',
    display: 'Puget Sound Salish',
  },
  {
    code: '1519-8',
    display: 'Duwamish',
  },
  {
    code: '1520-6',
    display: 'Kikiallus',
  },
  {
    code: '1521-4',
    display: 'Lower Skagit',
  },
  {
    code: '1522-2',
    display: 'Muckleshoot',
  },
  {
    code: '1523-0',
    display: 'Nisqually',
  },
  {
    code: '1524-8',
    display: 'Nooksack',
  },
  {
    code: '1525-5',
    display: 'Port Madison',
  },
  {
    code: '1526-3',
    display: 'Puyallup',
  },
  {
    code: '1527-1',
    display: 'Samish',
  },
  {
    code: '1528-9',
    display: 'Sauk-Suiattle',
  },
  {
    code: '1529-7',
    display: 'Skokomish',
  },
  {
    code: '1530-5',
    display: 'Skykomish',
  },
  {
    code: '1531-3',
    display: 'Snohomish',
  },
  {
    code: '1532-1',
    display: 'Snoqualmie',
  },
  {
    code: '1533-9',
    display: 'Squaxin Island',
  },
  {
    code: '1534-7',
    display: 'Steilacoom',
  },
  {
    code: '1535-4',
    display: 'Stillaguamish',
  },
  {
    code: '1536-2',
    display: 'Suquamish',
  },
  {
    code: '1537-0',
    display: 'Swinomish',
  },
  {
    code: '1538-8',
    display: 'Tulalip',
  },
  {
    code: '1539-6',
    display: 'Upper Skagit',
  },
  {
    code: '1541-2',
    display: 'Quapaw',
  },
  {
    code: '1543-8',
    display: 'Quinault',
  },
  {
    code: '1545-3',
    display: 'Rappahannock',
  },
  {
    code: '1547-9',
    display: 'Reno-Sparks',
  },
  {
    code: '1549-5',
    display: 'Round Valley',
  },
  {
    code: '1551-1',
    display: 'Sac and Fox',
  },
  {
    code: '1552-9',
    display: 'Iowa Sac and Fox',
  },
  {
    code: '1553-7',
    display: 'Missouri Sac and Fox',
  },
  {
    code: '1554-5',
    display: 'Oklahoma Sac and Fox',
  },
  {
    code: '1556-0',
    display: 'Salinan',
  },
  {
    code: '1558-6',
    display: 'Salish',
  },
  {
    code: '1560-2',
    display: 'Salish and Kootenai',
  },
  {
    code: '1562-8',
    display: 'Schaghticoke',
  },
  {
    code: '1564-4',
    display: 'Scott Valley',
  },
  {
    code: '1566-9',
    display: 'Seminole',
  },
  {
    code: '1567-7',
    display: 'Big Cypress',
  },
  {
    code: '1568-5',
    display: 'Brighton',
  },
  {
    code: '1569-3',
    display: 'Florida Seminole',
  },
  {
    code: '1570-1',
    display: 'Hollywood Seminole',
  },
  {
    code: '1571-9',
    display: 'Oklahoma Seminole',
  },
  {
    code: '1573-5',
    display: 'Serrano',
  },
  {
    code: '1574-3',
    display: 'San Manual',
  },
  {
    code: '1576-8',
    display: 'Shasta',
  },
  {
    code: '1578-4',
    display: 'Shawnee',
  },
  {
    code: '1579-2',
    display: 'Absentee Shawnee',
  },
  {
    code: '1580-0',
    display: 'Eastern Shawnee',
  },
  {
    code: '1582-6',
    display: 'Shinnecock',
  },
  {
    code: '1584-2',
    display: 'Shoalwater Bay',
  },
  {
    code: '1586-7',
    display: 'Shoshone',
  },
  {
    code: '1587-5',
    display: 'Battle Mountain',
  },
  {
    code: '1588-3',
    display: 'Duckwater',
  },
  {
    code: '1589-1',
    display: 'Elko',
  },
  {
    code: '1590-9',
    display: 'Ely',
  },
  {
    code: '1591-7',
    display: 'Goshute',
  },
  {
    code: '1592-5',
    display: 'Panamint',
  },
  {
    code: '1593-3',
    display: 'Ruby Valley',
  },
  {
    code: '1594-1',
    display: 'Skull Valley',
  },
  {
    code: '1595-8',
    display: 'South Fork Shoshone',
  },
  {
    code: '1596-6',
    display: 'Te-Moak Western Shoshone',
  },
  {
    code: '1597-4',
    display: 'Timbi-Sha Shoshone',
  },
  {
    code: '1598-2',
    display: 'Washakie',
  },
  {
    code: '1599-0',
    display: 'Wind River Shoshone',
  },
  {
    code: '1600-6',
    display: 'Yomba',
  },
  {
    code: '1602-2',
    display: 'Shoshone Paiute',
  },
  {
    code: '1603-0',
    display: 'Duck Valley',
  },
  {
    code: '1604-8',
    display: 'Fallon',
  },
  {
    code: '1605-5',
    display: 'Fort McDermitt',
  },
  {
    code: '1607-1',
    display: 'Siletz',
  },
  {
    code: '1609-7',
    display: 'Sioux',
  },
  {
    code: '1610-5',
    display: 'Blackfoot Sioux',
  },
  {
    code: '1611-3',
    display: 'Brule Sioux',
  },
  {
    code: '1612-1',
    display: 'Cheyenne River Sioux',
  },
  {
    code: '1613-9',
    display: 'Crow Creek Sioux',
  },
  {
    code: '1614-7',
    display: 'Dakota Sioux',
  },
  {
    code: '1615-4',
    display: 'Flandreau Santee',
  },
  {
    code: '1616-2',
    display: 'Fort Peck',
  },
  {
    code: '1617-0',
    display: 'Lake Traverse Sioux',
  },
  {
    code: '1618-8',
    display: 'Lower Brule Sioux',
  },
  {
    code: '1619-6',
    display: 'Lower Sioux',
  },
  {
    code: '1620-4',
    display: 'Mdewakanton Sioux',
  },
  {
    code: '1621-2',
    display: 'Miniconjou',
  },
  {
    code: '1622-0',
    display: 'Oglala Sioux',
  },
  {
    code: '1623-8',
    display: 'Pine Ridge Sioux',
  },
  {
    code: '1624-6',
    display: 'Pipestone Sioux',
  },
  {
    code: '1625-3',
    display: 'Prairie Island Sioux',
  },
  {
    code: '1626-1',
    display: 'Prior Lake Sioux',
  },
  {
    code: '1627-9',
    display: 'Rosebud Sioux',
  },
  {
    code: '1628-7',
    display: 'Sans Arc Sioux',
  },
  {
    code: '1629-5',
    display: 'Santee Sioux',
  },
  {
    code: '1630-3',
    display: 'Sisseton-Wahpeton',
  },
  {
    code: '1631-1',
    display: 'Sisseton Sioux',
  },
  {
    code: '1632-9',
    display: 'Spirit Lake Sioux',
  },
  {
    code: '1633-7',
    display: 'Standing Rock Sioux',
  },
  {
    code: '1634-5',
    display: 'Teton Sioux',
  },
  {
    code: '1635-2',
    display: 'Two Kettle Sioux',
  },
  {
    code: '1636-0',
    display: 'Upper Sioux',
  },
  {
    code: '1637-8',
    display: 'Wahpekute Sioux',
  },
  {
    code: '1638-6',
    display: 'Wahpeton Sioux',
  },
  {
    code: '1639-4',
    display: 'Wazhaza Sioux',
  },
  {
    code: '1640-2',
    display: 'Yankton Sioux',
  },
  {
    code: '1641-0',
    display: 'Yanktonai Sioux',
  },
  {
    code: '1643-6',
    display: 'Siuslaw',
  },
  {
    code: '1645-1',
    display: 'Spokane',
  },
  {
    code: '1647-7',
    display: 'Stewart',
  },
  {
    code: '1649-3',
    display: 'Stockbridge',
  },
  {
    code: '1651-9',
    display: 'Susanville',
  },
  {
    code: '1653-5',
    display: 'Tohono O\'Odham',
  },
  {
    code: '1654-3',
    display: 'Ak-Chin',
  },
  {
    code: '1655-0',
    display: 'Gila Bend',
  },
  {
    code: '1656-8',
    display: 'San Xavier',
  },
  {
    code: '1657-6',
    display: 'Sells',
  },
  {
    code: '1659-2',
    display: 'Tolowa',
  },
  {
    code: '1661-8',
    display: 'Tonkawa',
  },
  {
    code: '1663-4',
    display: 'Tygh',
  },
  {
    code: '1665-9',
    display: 'Umatilla',
  },
  {
    code: '1667-5',
    display: 'Umpqua',
  },
  {
    code: '1668-3',
    display: 'Cow Creek Umpqua',
  },
  {
    code: '1670-9',
    display: 'Ute',
  },
  {
    code: '1671-7',
    display: 'Allen Canyon',
  },
  {
    code: '1672-5',
    display: 'Uintah Ute',
  },
  {
    code: '1673-3',
    display: 'Ute Mountain Ute',
  },
  {
    code: '1675-8',
    display: 'Wailaki',
  },
  {
    code: '1677-4',
    display: 'Walla-Walla',
  },
  {
    code: '1679-0',
    display: 'Wampanoag',
  },
  {
    code: '1680-8',
    display: 'Gay Head Wampanoag',
  },
  {
    code: '1681-6',
    display: 'Mashpee Wampanoag',
  },
  {
    code: '1683-2',
    display: 'Warm Springs',
  },
  {
    code: '1685-7',
    display: 'Wascopum',
  },
  {
    code: '1687-3',
    display: 'Washoe',
  },
  {
    code: '1688-1',
    display: 'Alpine',
  },
  {
    code: '1689-9',
    display: 'Carson',
  },
  {
    code: '1690-7',
    display: 'Dresslerville',
  },
  {
    code: '1692-3',
    display: 'Wichita',
  },
  {
    code: '1694-9',
    display: 'Wind River',
  },
  {
    code: '1696-4',
    display: 'Winnebago',
  },
  {
    code: '1697-2',
    display: 'Ho-chunk',
  },
  {
    code: '1698-0',
    display: 'Nebraska Winnebago',
  },
  {
    code: '1700-4',
    display: 'Winnemucca',
  },
  {
    code: '1702-0',
    display: 'Wintun',
  },
  {
    code: '1704-6',
    display: 'Wiyot',
  },
  {
    code: '1705-3',
    display: 'Table Bluff',
  },
  {
    code: '1707-9',
    display: 'Yakama',
  },
  {
    code: '1709-5',
    display: 'Yakama Cowlitz',
  },
  {
    code: '1711-1',
    display: 'Yaqui',
  },
  {
    code: '1712-9',
    display: 'Barrio Libre',
  },
  {
    code: '1713-7',
    display: 'Pascua Yaqui',
  },
  {
    code: '1715-2',
    display: 'Yavapai Apache',
  },
  {
    code: '1717-8',
    display: 'Yokuts',
  },
  {
    code: '1718-6',
    display: 'Chukchansi',
  },
  {
    code: '1719-4',
    display: 'Tachi',
  },
  {
    code: '1720-2',
    display: 'Tule River',
  },
  {
    code: '1722-8',
    display: 'Yuchi',
  },
  {
    code: '1724-4',
    display: 'Yuman',
  },
  {
    code: '1725-1',
    display: 'Cocopah',
  },
  {
    code: '1726-9',
    display: 'Havasupai',
  },
  {
    code: '1727-7',
    display: 'Hualapai',
  },
  {
    code: '1728-5',
    display: 'Maricopa',
  },
  {
    code: '1729-3',
    display: 'Mohave',
  },
  {
    code: '1730-1',
    display: 'Quechan',
  },
  {
    code: '1731-9',
    display: 'Yavapai',
  },
  {
    code: '1732-7',
    display: 'Yurok',
  },
  {
    code: '1733-5',
    display: 'Coast Yurok',
  },
  {
    code: '1735-0',
    display: 'Alaska Native',
  },
  {
    code: '1737-6',
    display: 'Alaska Indian',
  },
  {
    code: '1739-2',
    display: 'Alaskan Athabascan',
  },
  {
    code: '1740-0',
    display: 'Ahtna',
  },
  {
    code: '1811-9',
    display: 'Southeast Alaska',
  },
  {
    code: '1813-5',
    display: 'Tlingit-Haida',
  },
  {
    code: '1814-3',
    display: 'Angoon',
  },
  {
    code: '1815-0',
    display: 'Central Council of Tlingit and Haida Tribes',
  },
  {
    code: '1816-8',
    display: 'Chilkat',
  },
  {
    code: '1817-6',
    display: 'Chilkoot',
  },
  {
    code: '1818-4',
    display: 'Craig',
  },
  {
    code: '1819-2',
    display: 'Douglas',
  },
  {
    code: '1820-0',
    display: 'Haida',
  },
  {
    code: '1821-8',
    display: 'Hoonah',
  },
  {
    code: '1822-6',
    display: 'Hydaburg',
  },
  {
    code: '1823-4',
    display: 'Kake',
  },
  {
    code: '1824-2',
    display: 'Kasaan',
  },
  {
    code: '1825-9',
    display: 'Kenaitze',
  },
  {
    code: '1826-7',
    display: 'Ketchikan',
  },
  {
    code: '1827-5',
    display: 'Klawock',
  },
  {
    code: '1828-3',
    display: 'Pelican',
  },
  {
    code: '1829-1',
    display: 'Petersburg',
  },
  {
    code: '1830-9',
    display: 'Saxman',
  },
  {
    code: '1831-7',
    display: 'Sitka',
  },
  {
    code: '1832-5',
    display: 'Tenakee Springs',
  },
  {
    code: '1833-3',
    display: 'Tlingit',
  },
  {
    code: '1834-1',
    display: 'Wrangell',
  },
  {
    code: '1835-8',
    display: 'Yakutat',
  },
  {
    code: '1837-4',
    display: 'Tsimshian',
  },
  {
    code: '1838-2',
    display: 'Metlakatla',
  },
  {
    code: '1840-8',
    display: 'Eskimo',
  },
  {
    code: '1842-4',
    display: 'Greenland Eskimo',
  },
  {
    code: '1844-0',
    display: 'Inupiat Eskimo',
  },
  {
    code: '1845-7',
    display: 'Ambler',
  },
  {
    code: '1846-5',
    display: 'Anaktuvuk',
  },
  {
    code: '1847-3',
    display: 'Anaktuvuk Pass',
  },
  {
    code: '1848-1',
    display: 'Arctic Slope Inupiat',
  },
  {
    code: '1849-9',
    display: 'Arctic Slope Corporation',
  },
  {
    code: '1850-7',
    display: 'Atqasuk',
  },
  {
    code: '1851-5',
    display: 'Barrow',
  },
  {
    code: '1852-3',
    display: 'Bering Straits Inupiat',
  },
  {
    code: '1853-1',
    display: 'Brevig Mission',
  },
  {
    code: '1854-9',
    display: 'Buckland',
  },
  {
    code: '1855-6',
    display: 'Chinik',
  },
  {
    code: '1856-4',
    display: 'Council',
  },
  {
    code: '1857-2',
    display: 'Deering',
  },
  {
    code: '1858-0',
    display: 'Elim',
  },
  {
    code: '1859-8',
    display: 'Golovin',
  },
  {
    code: '1860-6',
    display: 'Inalik Diomede',
  },
  {
    code: '1861-4',
    display: 'Inupiaq',
  },
  {
    code: '1862-2',
    display: 'Kaktovik',
  },
  {
    code: '1863-0',
    display: 'Kawerak',
  },
  {
    code: '1864-8',
    display: 'Kiana',
  },
  {
    code: '1865-5',
    display: 'Kivalina',
  },
  {
    code: '1866-3',
    display: 'Kobuk',
  },
  {
    code: '1867-1',
    display: 'Kotzebue',
  },
  {
    code: '1868-9',
    display: 'Koyuk',
  },
  {
    code: '1869-7',
    display: 'Kwiguk',
  },
  {
    code: '1870-5',
    display: 'Mauneluk Inupiat',
  },
  {
    code: '1871-3',
    display: 'Nana Inupiat',
  },
  {
    code: '1872-1',
    display: 'Noatak',
  },
  {
    code: '1873-9',
    display: 'Nome',
  },
  {
    code: '1874-7',
    display: 'Noorvik',
  },
  {
    code: '1875-4',
    display: 'Nuiqsut',
  },
  {
    code: '1876-2',
    display: 'Point Hope',
  },
  {
    code: '1877-0',
    display: 'Point Lay',
  },
  {
    code: '1878-8',
    display: 'Selawik',
  },
  {
    code: '1879-6',
    display: 'Shaktoolik',
  },
  {
    code: '1880-4',
    display: 'Shishmaref',
  },
  {
    code: '1881-2',
    display: 'Shungnak',
  },
  {
    code: '1882-0',
    display: 'Solomon',
  },
  {
    code: '1883-8',
    display: 'Teller',
  },
  {
    code: '1884-6',
    display: 'Unalakleet',
  },
  {
    code: '1885-3',
    display: 'Wainwright',
  },
  {
    code: '1886-1',
    display: 'Wales',
  },
  {
    code: '1887-9',
    display: 'White Mountain',
  },
  {
    code: '1888-7',
    display: 'White Mountain Inupiat',
  },
  {
    code: '1889-5',
    display: 'Mary\'s Igloo',
  },
  {
    code: '1891-1',
    display: 'Siberian Eskimo',
  },
  {
    code: '1892-9',
    display: 'Gambell',
  },
  {
    code: '1893-7',
    display: 'Savoonga',
  },
  {
    code: '1894-5',
    display: 'Siberian Yupik',
  },
  {
    code: '1896-0',
    display: 'Yupik Eskimo',
  },
  {
    code: '1897-8',
    display: 'Akiachak',
  },
  {
    code: '1898-6',
    display: 'Akiak',
  },
  {
    code: '1899-4',
    display: 'Alakanuk',
  },
  {
    code: '1900-0',
    display: 'Aleknagik',
  },
  {
    code: '1901-8',
    display: 'Andreafsky',
  },
  {
    code: '1902-6',
    display: 'Aniak',
  },
  {
    code: '1903-4',
    display: 'Atmautluak',
  },
  {
    code: '1904-2',
    display: 'Bethel',
  },
  {
    code: '1905-9',
    display: 'Bill Moore\'s Slough',
  },
  {
    code: '1906-7',
    display: 'Bristol Bay Yupik',
  },
  {
    code: '1907-5',
    display: 'Calista Yupik',
  },
  {
    code: '1908-3',
    display: 'Chefornak',
  },
  {
    code: '1909-1',
    display: 'Chevak',
  },
  {
    code: '1910-9',
    display: 'Chuathbaluk',
  },
  {
    code: '1911-7',
    display: 'Clark\'s Point',
  },
  {
    code: '1912-5',
    display: 'Crooked Creek',
  },
  {
    code: '1913-3',
    display: 'Dillingham',
  },
  {
    code: '1914-1',
    display: 'Eek',
  },
  {
    code: '1915-8',
    display: 'Ekuk',
  },
  {
    code: '1916-6',
    display: 'Ekwok',
  },
  {
    code: '1917-4',
    display: 'Emmonak',
  },
  {
    code: '1918-2',
    display: 'Goodnews Bay',
  },
  {
    code: '1919-0',
    display: 'Hooper Bay',
  },
  {
    code: '1920-8',
    display: 'Iqurmuit (Russian Mission)',
  },
  {
    code: '1921-6',
    display: 'Kalskag',
  },
  {
    code: '1922-4',
    display: 'Kasigluk',
  },
  {
    code: '1923-2',
    display: 'Kipnuk',
  },
  {
    code: '1924-0',
    display: 'Koliganek',
  },
  {
    code: '1925-7',
    display: 'Kongiganak',
  },
  {
    code: '1926-5',
    display: 'Kotlik',
  },
  {
    code: '1927-3',
    display: 'Kwethluk',
  },
  {
    code: '1928-1',
    display: 'Kwigillingok',
  },
  {
    code: '1929-9',
    display: 'Levelock',
  },
  {
    code: '1930-7',
    display: 'Lower Kalskag',
  },
  {
    code: '1931-5',
    display: 'Manokotak',
  },
  {
    code: '1932-3',
    display: 'Marshall',
  },
  {
    code: '1933-1',
    display: 'Mekoryuk',
  },
  {
    code: '1934-9',
    display: 'Mountain Village',
  },
  {
    code: '1935-6',
    display: 'Naknek',
  },
  {
    code: '1936-4',
    display: 'Napaumute',
  },
  {
    code: '1937-2',
    display: 'Napakiak',
  },
  {
    code: '1938-0',
    display: 'Napaskiak',
  },
  {
    code: '1939-8',
    display: 'Newhalen',
  },
  {
    code: '1940-6',
    display: 'New Stuyahok',
  },
  {
    code: '1941-4',
    display: 'Newtok',
  },
  {
    code: '1942-2',
    display: 'Nightmute',
  },
  {
    code: '1943-0',
    display: 'Nunapitchukv',
  },
  {
    code: '1944-8',
    display: 'Oscarville',
  },
  {
    code: '1945-5',
    display: 'Pilot Station',
  },
  {
    code: '1946-3',
    display: 'Pitkas Point',
  },
  {
    code: '1947-1',
    display: 'Platinum',
  },
  {
    code: '1948-9',
    display: 'Portage Creek',
  },
  {
    code: '1949-7',
    display: 'Quinhagak',
  },
  {
    code: '1950-5',
    display: 'Red Devil',
  },
  {
    code: '1951-3',
    display: 'St. Michael',
  },
  {
    code: '1952-1',
    display: 'Scammon Bay',
  },
  {
    code: '1953-9',
    display: 'Sheldon\'s Point',
  },
  {
    code: '1954-7',
    display: 'Sleetmute',
  },
  {
    code: '1955-4',
    display: 'Stebbins',
  },
  {
    code: '1956-2',
    display: 'Togiak',
  },
  {
    code: '1957-0',
    display: 'Toksook',
  },
  {
    code: '1958-8',
    display: 'Tulukskak',
  },
  {
    code: '1959-6',
    display: 'Tuntutuliak',
  },
  {
    code: '1960-4',
    display: 'Tununak',
  },
  {
    code: '1961-2',
    display: 'Twin Hills',
  },
  {
    code: '1962-0',
    display: 'Georgetown',
  },
  {
    code: '1963-8',
    display: 'St. Mary\'s',
  },
  {
    code: '1964-6',
    display: 'Umkumiate',
  },
  {
    code: '1966-1',
    display: 'Aleut',
  },
  {
    code: '1968-7',
    display: 'Alutiiq Aleut',
  },
  {
    code: '1969-5',
    display: 'Tatitlek',
  },
  {
    code: '1970-3',
    display: 'Ugashik',
  },
  {
    code: '1972-9',
    display: 'Bristol Bay Aleut',
  },
  {
    code: '1973-7',
    display: 'Chignik',
  },
  {
    code: '1974-5',
    display: 'Chignik Lake',
  },
  {
    code: '1975-2',
    display: 'Egegik',
  },
  {
    code: '1976-0',
    display: 'Igiugig',
  },
  {
    code: '1977-8',
    display: 'Ivanof Bay',
  },
  {
    code: '1978-6',
    display: 'King Salmon',
  },
  {
    code: '1979-4',
    display: 'Kokhanok',
  },
  {
    code: '1980-2',
    display: 'Perryville',
  },
  {
    code: '1981-0',
    display: 'Pilot Point',
  },
  {
    code: '1982-8',
    display: 'Port Heiden',
  },
  {
    code: '1984-4',
    display: 'Chugach Aleut',
  },
  {
    code: '1985-1',
    display: 'Chenega',
  },
  {
    code: '1986-9',
    display: 'Chugach Corporation',
  },
  {
    code: '1987-7',
    display: 'English Bay',
  },
  {
    code: '1988-5',
    display: 'Port Graham',
  },
  {
    code: '1990-1',
    display: 'Eyak',
  },
  {
    code: '1992-7',
    display: 'Koniag Aleut',
  },
  {
    code: '1993-5',
    display: 'Akhiok',
  },
  {
    code: '1994-3',
    display: 'Agdaagux',
  },
  {
    code: '1995-0',
    display: 'Karluk',
  },
  {
    code: '1996-8',
    display: 'Kodiak',
  },
  {
    code: '1997-6',
    display: 'Larsen Bay',
  },
  {
    code: '1998-4',
    display: 'Old Harbor',
  },
  {
    code: '1999-2',
    display: 'Ouzinkie',
  },
  {
    code: '2000-8',
    display: 'Port Lions',
  },
  {
    code: '2002-4',
    display: 'Sugpiaq',
  },
  {
    code: '2004-0',
    display: 'Suqpigaq',
  },
  {
    code: '2006-5',
    display: 'Unangan Aleut',
  },
  {
    code: '2007-3',
    display: 'Akutan',
  },
  {
    code: '2008-1',
    display: 'Aleut Corporation',
  },
  {
    code: '2009-9',
    display: 'Aleutian',
  },
  {
    code: '2010-7',
    display: 'Aleutian Islander',
  },
  {
    code: '2011-5',
    display: 'Atka',
  },
  {
    code: '2012-3',
    display: 'Belkofski',
  },
  {
    code: '2013-1',
    display: 'Chignik Lagoon',
  },
  {
    code: '2014-9',
    display: 'King Cove',
  },
  {
    code: '2015-6',
    display: 'False Pass',
  },
  {
    code: '2016-4',
    display: 'Nelson Lagoon',
  },
  {
    code: '2017-2',
    display: 'Nikolski',
  },
  {
    code: '2018-0',
    display: 'Pauloff Harbor',
  },
  {
    code: '2019-8',
    display: 'Qagan Toyagungin',
  },
  {
    code: '2020-6',
    display: 'Qawalangin',
  },
  {
    code: '2021-4',
    display: 'St. George',
  },
  {
    code: '2022-2',
    display: 'St. Paul',
  },
  {
    code: '2023-0',
    display: 'Sand Point',
  },
  {
    code: '2024-8',
    display: 'South Naknek',
  },
  {
    code: '2025-5',
    display: 'Unalaska',
  },
  {
    code: '2026-3',
    display: 'Unga',
  },
  {
    code: '2028-9',
    display: 'Asian',
  },
  {
    code: '2029-7',
    display: 'Asian Indian',
  },
  {
    code: '2030-5',
    display: 'Bangladeshi',
  },
  {
    code: '2031-3',
    display: 'Bhutanese',
  },
  {
    code: '2032-1',
    display: 'Burmese',
  },
  {
    code: '2033-9',
    display: 'Cambodian',
  },
  {
    code: '2034-7',
    display: 'Chinese',
  },
  {
    code: '2035-4',
    display: 'Taiwanese',
  },
  {
    code: '2036-2',
    display: 'Filipino',
  },
  {
    code: '2037-0',
    display: 'Hmong',
  },
  {
    code: '2038-8',
    display: 'Indonesian',
  },
  {
    code: '2039-6',
    display: 'Japanese',
  },
  {
    code: '2040-4',
    display: 'Korean',
  },
  {
    code: '2041-2',
    display: 'Laotian',
  },
  {
    code: '2042-0',
    display: 'Malaysian',
  },
  {
    code: '2043-8',
    display: 'Okinawan',
  },
  {
    code: '2044-6',
    display: 'Pakistani',
  },
  {
    code: '2045-3',
    display: 'Sri Lankan',
  },
  {
    code: '2046-1',
    display: 'Thai',
  },
  {
    code: '2047-9',
    display: 'Vietnamese',
  },
  {
    code: '2048-7',
    display: 'Iwo Jiman',
  },
  {
    code: '2049-5',
    display: 'Maldivian',
  },
  {
    code: '2050-3',
    display: 'Nepalese',
  },
  {
    code: '2051-1',
    display: 'Singaporean',
  },
  {
    code: '2052-9',
    display: 'Madagascar',
  },
  {
    code: '2054-5',
    display: 'Black or African American',
  },
  {
    code: '2056-0',
    display: 'Black',
  },
  {
    code: '2058-6',
    display: 'African American',
  },
  {
    code: '2060-2',
    display: 'African',
  },
  {
    code: '2061-0',
    display: 'Botswanan',
  },
  {
    code: '2062-8',
    display: 'Ethiopian',
  },
  {
    code: '2063-6',
    display: 'Liberian',
  },
  {
    code: '2064-4',
    display: 'Namibian',
  },
  {
    code: '2065-1',
    display: 'Nigerian',
  },
  {
    code: '2066-9',
    display: 'Zairean',
  },
  {
    code: '2067-7',
    display: 'Bahamian',
  },
  {
    code: '2068-5',
    display: 'Barbadian',
  },
  {
    code: '2069-3',
    display: 'Dominican',
  },
  {
    code: '2070-1',
    display: 'Dominica Islander',
  },
  {
    code: '2071-9',
    display: 'Haitian',
  },
  {
    code: '2072-7',
    display: 'Jamaican',
  },
  {
    code: '2073-5',
    display: 'Tobagoan',
  },
  {
    code: '2074-3',
    display: 'Trinidadian',
  },
  {
    code: '2075-0',
    display: 'West Indian',
  },
  {
    code: '2076-8',
    display: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    code: '2078-4',
    display: 'Polynesian',
  },
  {
    code: '2079-2',
    display: 'Native Hawaiian',
  },
  {
    code: '2080-0',
    display: 'Samoan',
  },
  {
    code: '2081-8',
    display: 'Tahitian',
  },
  {
    code: '2082-6',
    display: 'Tongan',
  },
  {
    code: '2083-4',
    display: 'Tokelauan',
  },
  {
    code: '2085-9',
    display: 'Micronesian',
  },
  {
    code: '2086-7',
    display: 'Guamanian or Chamorro',
  },
  {
    code: '2087-5',
    display: 'Guamanian',
  },
  {
    code: '2088-3',
    display: 'Chamorro',
  },
  {
    code: '2089-1',
    display: 'Mariana Islander',
  },
  {
    code: '2090-9',
    display: 'Marshallese',
  },
  {
    code: '2091-7',
    display: 'Palauan',
  },
  {
    code: '2092-5',
    display: 'Carolinian',
  },
  {
    code: '2093-3',
    display: 'Kosraean',
  },
  {
    code: '2094-1',
    display: 'Pohnpeian',
  },
  {
    code: '2095-8',
    display: 'Saipanese',
  },
  {
    code: '2096-6',
    display: 'Kiribati',
  },
  {
    code: '2097-4',
    display: 'Chuukese',
  },
  {
    code: '2098-2',
    display: 'Yapese',
  },
  {
    code: '2100-6',
    display: 'Melanesian',
  },
  {
    code: '2101-4',
    display: 'Fijian',
  },
  {
    code: '2102-2',
    display: 'Papua New Guinean',
  },
  {
    code: '2103-0',
    display: 'Solomon Islander',
  },
  {
    code: '2104-8',
    display: 'New Hebrides',
  },
  {
    code: '2500-7',
    display: 'Other Pacific Islander',
  },
  {
    code: '2108-9',
    display: 'European',
  },
  {
    code: '2109-7',
    display: 'Armenian',
  },
  {
    code: '2110-5',
    display: 'English',
  },
  {
    code: '2111-3',
    display: 'French',
  },
  {
    code: '2112-1',
    display: 'German',
  },
  {
    code: '2113-9',
    display: 'Irish',
  },
  {
    code: '2114-7',
    display: 'Italian',
  },
  {
    code: '2115-4',
    display: 'Polish',
  },
  {
    code: '2116-2',
    display: 'Scottish',
  },
  {
    code: '2118-8',
    display: 'Middle Eastern or North African',
  },
  {
    code: '2119-6',
    display: 'Assyrian',
  },
  {
    code: '2120-4',
    display: 'Egyptian',
  },
  {
    code: '2121-2',
    display: 'Iranian',
  },
  {
    code: '2122-0',
    display: 'Iraqi',
  },
  {
    code: '2123-8',
    display: 'Lebanese',
  },
  {
    code: '2124-6',
    display: 'Palestinian',
  },
  {
    code: '2125-3',
    display: 'Syrian',
  },
  {
    code: '2126-1',
    display: 'Afghanistani',
  },
  {
    code: '2127-9',
    display: 'Israeili',
  },
  {
    code: '2129-5',
    display: 'Arab',
  },
  {
    code: '2106-3',
    display: 'White',
  },
  {
    code: '2131-1',
    display: 'Other Race',
  },
];
