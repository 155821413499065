// Libraries
import React, {
  Fragment, useEffect, useState, lazy, Suspense,
} from 'react';
import { useSelector } from 'react-redux';
// Constants
import { UNASSIGNED_ID, PATIENT_CONTROL_TYPE, PES_PATIENT_CONTROL_TYPE } from '../../../constants/constants';
// Components
import ButtonAssignment from '../../patient/assignPatient/ButtonAssignment';
import UserAssignment from '../../patient/assignPatient/UserAssignment';
import UserAssignmentPES from '../../patient/assignPatient/UserAssignmentPES';
// Lazy
const AssignPatientsModal = lazy(() => import('./AssignPatientsModal'));
// Local Constants
const CONTROL_TYPES_ENABLED = [PATIENT_CONTROL_TYPE, PES_PATIENT_CONTROL_TYPE];

export const AssignPatients = (props) => {
  const { selectedPatientsIds, controlType, reloadData } = props;
  const {
    patient: { assignedCareNavigator },
    search: { searchParams: { careNavigatorId } },
  } = useSelector(state => state);

  const [modalOpened, setModalOpened] = useState(false);
  const [cnId, setCnId] = useState(UNASSIGNED_ID);

  useEffect(() => setCnId(careNavigatorId), [careNavigatorId]);

  const openDialog = () => {
    if (!selectedPatientsIds || !selectedPatientsIds.size) {
      return;
    }

    let initCnId = careNavigatorId || UNASSIGNED_ID;

    if (CONTROL_TYPES_ENABLED.includes(controlType)) {
      initCnId = (assignedCareNavigator && assignedCareNavigator.id) || UNASSIGNED_ID;
    }

    setModalOpened(true);
    setCnId(initCnId);
  };

  const getView = () => {
    switch (controlType) {
      case PATIENT_CONTROL_TYPE:
        return <UserAssignment openDialog={openDialog} />;
      case PES_PATIENT_CONTROL_TYPE:
        return <UserAssignmentPES openDialog={openDialog} />;
      default:
        return (
          <ButtonAssignment
            openDialog={openDialog}
            selectedPatientsIds={selectedPatientsIds}
          />
        );
    }
  };

  return (
    <Fragment>
      {getView()}
      <Suspense fallback={null}>
        {modalOpened && (
          <AssignPatientsModal
            isModalOpen={modalOpened}
            setIsModalOpen={setModalOpened}
            selectedPatientsIds={selectedPatientsIds}
            cnId={cnId}
            setCnId={setCnId}
            controlType={controlType}
            reloadData={reloadData}
          />
        )}
      </Suspense>
    </Fragment>
  );
};

export default AssignPatients;
