// Libraries
import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
// Components
import ResendReport from './other/ResendReport';
import GenerateProblemReport from './other/GenerateProblemReport';
import AutomatedPatientSurveys from './other/AutomatedPatientSurveys';


export const Other = () => (
  <div className="overflow-auto container-fluid h-100 px-4">
    <h4 className="text-uppercase text-left my-3">Other</h4>
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header className="text-left">
          <Accordion.Toggle as={Button} variant="link" size="lg" className="text-capitalize" eventKey="0">
            resend report
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <ResendReport />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header className="text-left">
          <Accordion.Toggle as={Button} variant="link" size="lg" className="text-capitalize" eventKey="1">
            generate problem report
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            <GenerateProblemReport />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header className="text-left">
          <Accordion.Toggle as={Button} variant="link" size="lg" className="text-capitalize" eventKey="2">
            automated patient surveys
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            <AutomatedPatientSurveys />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  </div>
);

export default Other;
