// Libraries
import {
  object, string, date, ref, number, array, boolean,
} from 'yup';
// Constants
import {
  DATE_TYPE_MONTHS, DATE_TYPE_RANGE, DEFAULT_CONF_VALUES, PROMPT_CATEGORY,
  PROMPT_CONFIRMATION, PROMPT_ONLY, PROMPT_TENURE_OPTS, PROMPT_GENDER_OPTS,
} from '../../administration';
import { formatPhone } from '../../../services/helpers';

export const PROMPT_MODAL = () => object().shape({
  name: string()
    .trim('The prompt name cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The prompt name needs to be at least 1 char')
    .max(255, 'The prompt name cannot exceed 256 char')
    .required('The prompt name is required'),
  category: string()
    .oneOf(PROMPT_CATEGORY.map(el => el.value))
    .typeError('Please select a value from the list')
    .required('Required'),
  promptText: string()
    .min(1, 'The prompt text needs to be at least 1 char')
    .max(1024, 'The prompt text cannot exceed 256 char')
    .required('The prompt text is required'),
  guidanceText: string()
    .min(1, 'The prompt guidance needs to be at least 1 char')
    .max(1024, 'The prompt guidance cannot exceed 256 char')
    .required('The prompt guidance is required'),
  confirmationType: string()
    .oneOf(PROMPT_CONFIRMATION.map(el => el.value))
    .typeError('Please select a value from the list')
    .required('Required'),
  interventionCategory: string()
    .typeError('Please select a value from the list')
    .when('confirmationType', (confirmationType, schema) => (confirmationType && confirmationType.includes(DEFAULT_CONF_VALUES.INTERVENTION) ? schema.required('Required') : schema.nullable(true))),
  interventionType: string()
    .typeError('Please select a value from the list')
    .when('confirmationType', (confirmationType, schema) => (confirmationType && confirmationType.includes(DEFAULT_CONF_VALUES.INTERVENTION) ? schema.required('Required') : schema.nullable(true))),
  interventionNote: string()
    .trim('The prompt text cannot include leading and trailing spaces')
    .max(255, 'The prompt text cannot exceed 256 char'),
  criteriaType: string().test(
    'criteriaType',
    'Please check at least one of the following fields',
    (criteriaType, { parent }) => {
      if (criteriaType === PROMPT_ONLY) {
        const {
          dateRuleEnabled, conditionRuleEnabled, sdohRuleEnabled, tagRuleEnabled, surveyRuleEnabled,
          tenureRuleEnabled, icd10RuleEnabled, zipCodeRuleEnabled, apptRuleEnabled,
          genderRuleEnabled, ageRuleEnabled, telehealthProviderRuleEnabled,
        } = parent;
        const result = [
          dateRuleEnabled, conditionRuleEnabled, sdohRuleEnabled, tagRuleEnabled, surveyRuleEnabled,
          tenureRuleEnabled, icd10RuleEnabled, zipCodeRuleEnabled, apptRuleEnabled,
          genderRuleEnabled, ageRuleEnabled, telehealthProviderRuleEnabled,
        ].some(rule => rule);
        return result;
      }
      return true;
    },
  ),
  dateRuleMonths: array()
    .when(['dateType', 'dateRuleEnabled'], (dateType, dateRuleEnabled, schema) => (dateRuleEnabled && dateType === DATE_TYPE_MONTHS ? schema.required('Required').min(1, 'At least one selection is required') : schema.nullable(true))),
  dateRuleStart: date().typeError('Must be a valid date')
    .when(['dateType', 'dateRuleEnabled'], (dateType, dateRuleEnabled, schema) => (dateRuleEnabled && dateType === DATE_TYPE_RANGE ? schema.required('Required') : schema.nullable(true))),
  dateRuleEnd: date().typeError('Must be a valid date')
    .when(['dateType', 'dateRuleEnabled'], (dateType, dateRuleEnabled, schema) => (dateRuleEnabled && dateType === DATE_TYPE_RANGE ? schema.required('Required') : schema.nullable(true)))
    .min(
      ref('dateRuleStart'),
      'End date cannot be before the Start Date',
    ),
  conditions: array()
    .when('conditionRuleEnabled', (conditionRuleEnabled, schema) => (conditionRuleEnabled ? schema.required('Required').min(1, 'At least one selection is required') : schema.nullable(true))),
  factors: array()
    .when('sdohRuleEnabled', (sdohRuleEnabled, schema) => (sdohRuleEnabled ? schema.required('Required').min(1, 'At least one selection is required') : schema.nullable(true))),
  tag: string()
    .typeError('Please select a value from the list')
    .when('tagRuleEnabled', (tagRuleEnabled, schema) => (tagRuleEnabled ? schema.required('Required') : schema.nullable(true))),
  tenureRule: string()
    .typeError('Please select a value from the list')
    .when('tenureRuleEnabled', (tenureRuleEnabled, schema) => (tenureRuleEnabled ? schema.required('Required').oneOf(PROMPT_TENURE_OPTS.map(el => el.value), 'Please select a value from the list') : schema.nullable(true))),
  icd10RuleValues: string()
    .when('icd10RuleEnabled', (icd10RuleEnabled, schema) => (icd10RuleEnabled ? schema.required('Required').matches(/^(([a-tA-TUu]|[v-zV-Z])\d{2}(\.[a-zA-Z0-9]{1,4})?, ?)*([a-tA-TUu]|[v-zV-Z])\d{2}(\.[a-zA-Z0-9]{1,4})?$/, 'Invalid ICD10 code format') : schema.nullable(true))),
  zipCodeRuleValues: string()
    .when('zipCodeRuleEnabled', (zipCodeRuleEnabled, schema) => (zipCodeRuleEnabled ? schema.required('Required').matches(/^([0-9]{5}(, ?[0-9]{5})*)$/, 'Invalid zip code format') : schema.nullable(true))),
  apptRuleEnabled: boolean(),
  apptRuleType: string()
    .when('apptRuleEnabled', {
      is: true,
      then: string()
        .strict(true)
        .trim('The Type cannot include leading or trailing spaces.'),
      otherwise: string().notRequired(),
    }),
  apptRuleTypeExact: boolean(),
  apptRulePurpose: string()
    .when('apptRuleEnabled', {
      is: true,
      then: string()
        .strict(true)
        .trim('The Purpose cannot include leading or trailing spaces.'),
      otherwise: string().notRequired(),
    }),
  apptRulePurposeExact: boolean(),
  apptRuleDate: string()
    .typeError('Please select a value from the list')
    .when('apptRuleEnabled', (apptRuleEnabled, schema) => (apptRuleEnabled ? schema.required('Required') : schema.nullable(true))),
  surveyRuleEnabled: boolean(),
  genderRuleEnabled: boolean(),
  genderRule: string()
    .typeError('Please select a value from the list')
    .when('genderRuleEnabled', (genderRuleEnabled, schema) => (genderRuleEnabled ? schema.required('Required').oneOf(PROMPT_GENDER_OPTS.map(el => el.value), 'Please select a value from the list') : schema.nullable(true))),
  ageRuleEnabled: boolean(),
  ageRuleLessThan: number().nullable(),
  ageRuleGreaterThan: number().nullable(),
  telehealthProviderRuleEnabled: boolean(),
  telehealthProviderRule: array()
    .when('telehealthProviderRuleEnabled', (telehealthProviderRuleEnabled, schema) => (telehealthProviderRuleEnabled ? schema.required('Required').min(1, 'At least one selection is required') : schema.nullable(true))),
});

export const ADMIN_HF_SCHEMA = healthFactors => object().shape({
  displayName: string()
    .required('Required')
    .test('Unique', 'Health factor name need to be unique', (value) => {
      if (value && healthFactors
        && healthFactors.some(str => str.displayName.toLowerCase().trim()
          === value.toLowerCase().trim())) {
        return false;
      }
      return true;
    }),
  zcode: string()
    .matches(/^([a-tA-TUu]|[v-zV-Z])\d{2}(\.[a-zA-Z0-9]{1,4})?$/, 'Invalid ICD10 code format')
    .nullable(true),
});

export const ADMIN_HFC_SCHEMA = categories => object().shape({
  displayName: string()
    .required('Required').trim()
    .test('Unique', 'Category need to be unique', (value) => {
      if (value && categories
        && categories.some(str => str.toLowerCase().trim() === value.toLowerCase().trim())) {
        return false;
      }
      return true;
    }),
});

export const ADMIN_HF_RESOURCE_SCHEMA = () => object().shape({
  name: string()
    .required('Required').trim(),
  phone: string()
    .test('Length', 'must contain 10 digits', (value) => {
      const valueFormatted = formatPhone(value);
      return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
    }),
  address: string(),
  url: string().url().nullable(true),
  notes: string(),
  interventionCategory: string()
    .typeError('Please select a value from the list')
    .nullable(true),
  interventionType: string()
    .typeError('Please select a value from the list')
    .nullable(true),
  interventionNote: string()
    .trim('The prompt text cannot include leading and trailing spaces')
    .nullable(true)
    .max(255, 'The prompt text cannot exceed 256 char'),
});
