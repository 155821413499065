import { makeAuthorizedRequest } from './requests';

export function getReports(limit) {
  const queryString = `?limit=${limit}`;

  return makeAuthorizedRequest('GET', `/reports/enrollment${queryString}`);
}

export function downloadReport(reportId) {
  return makeAuthorizedRequest('GET', `/reports/${reportId}/download`);
}

export function downloadEscalationsReport(escalations) {
  return makeAuthorizedRequest('POST', '/patients/escalations/download', escalations);
}

export function getPatientsProvider() {
  return makeAuthorizedRequest('GET', '/reports/eligible/by-provider');
}

export function getEnrolledPatientsProvider(physician) {
  return makeAuthorizedRequest('POST', '/reports/enrollment/by-provider/excel', physician);
}
