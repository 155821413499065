// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// Actions
import { UpdateLetterBatch } from '../../actions/letterBatch';
import ShowNotification from '../../actions/notification';
// Services
import { getLetterBatchList } from '../../services/patient';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Views
import LetterBatch from './administration/patientOutreach/LetterBatch';
import BatchHistory from './administration/patientOutreach/BatchHistory';


export const PatientOutreach = (props) => {
  const [formRetrivied, setFormRetrivied] = useState(null);

  const getLetterBatches = () => {
    const { updateLetterBatch, showNotification } = props;

    const getLetterBatchListRequest = getLetterBatchList();
    const getLetterBatchListPomise = getLetterBatchListRequest.promise;

    return getLetterBatchListPomise.then((data) => {
      delete getLetterBatchListRequest.promise;

      updateLetterBatch({
        letterBatchList: data,
      });
    }).catch((error) => {
      delete getLetterBatchListRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error has occurred while attempting to load the letter batch list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    getLetterBatches();
  }, []);

  return (
    <div className="patient-outreach h-100 overflow-auto pr-3">
      <h4 className="text-uppercase text-left my-3" data-test="patientOutreach_title">Patient Outreach</h4>
      <LetterBatch formRetrivied={formRetrivied} />
      <BatchHistory setFormRetrivied={setFormRetrivied} />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    updateLetterBatch: batchData => dispatch(UpdateLetterBatch(batchData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(PatientOutreach);
