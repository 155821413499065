// Libraries
import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Select from 'react-select';
import AsyncPaginate from 'react-select-async-paginate';
// Actions
import ShowNotification from '../../actions/notification';
import { UpdatePatient } from '../../actions/patient';
import { UpdateUserGoals } from '../../actions/user';
// Services
import {
  enrollPatient, getPatientProfile, savePatientProfile, updatePatientProfile,
} from '../../services/patient';
import { getGoals } from '../../services/goals';
import { isObjectsEqual, prepareCareNavigatorsList } from '../../services/helpers';
import { updateUserInfoInStorage } from '../../services/userStorage';
// Components
import { withRouter } from '../shared/WithRouter';
// Views
import Checkbox from '../base/Checkbox';
import StatusNoteModal from './auditing/StatusNoteModal';
import { getAsyncList } from '../base/AsyncDropdown';
// Constants
import {
  NOTIFICATION_TYPE, DATE_FORMAT, USER_ROLES,
  ASSIGNED_USERS_STATUS, PATIENT_OPT_IN_ENGOODEN_TEXT_OPTIONS,
} from '../../constants/constants';

const UNASSIGNED_ID = 'not_assigned';

export class PatientEnrollment extends Component {
  constructor(props) {
    super(props);

    const { patient: { profile: { textMessageConsent: profileTextMessageConsent } = {} } } = props;

    this.state = {
      loading: false,
      confirmation: {},
      cnId: UNASSIGNED_ID,
      textMessageConsent: profileTextMessageConsent || 'NONE',
      isAuditingModalOpen: false,
      uncheckedBoxes: true,
    };

    this.promises = {};

    this.questions = [
      'The patient/caregiver has given verbal consent to participate in CCM',
      'The patient/caregiver has confirmed that the patient is not currently receiving CCM services',
      'The patient/caregiver understands that the patient may stop at any time (effective with the last day of that calendar month)',
      'The patient/caregiver understands the potential cost share',
      'The patient/caregiver understands that all phone calls will be recorded for quality assurance and general record keeping purposes',
    ];

    this.sendStatusUpdateRequest = this.sendStatusUpdateRequest.bind(this);
    this.checkboxClicked = this.checkboxClicked.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.goHistoryBack = this.goHistoryBack.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { patient: { assignedCareNavigator } } = this.props;
    this.setState({
      cnId: (assignedCareNavigator && assignedCareNavigator.id) || UNASSIGNED_ID,
    });
    this.loadPatientProfile();
  }

  componentDidUpdate(prevProps) {
    const {
      patient: {
        assignedCareNavigator,
        profile: { textMessageConsent: profileTextMessageConsent } = {},
      },
    } = this.props;
    const {
      patient: {
        assignedCareNavigator: oldAssignedCareNavigator,
        profile: { textMessageConsent: prevProfileTextMessageConsent } = {},
      },
    } = prevProps;

    if (!isObjectsEqual(assignedCareNavigator, oldAssignedCareNavigator)) {
      this.setState({
        cnId: (assignedCareNavigator && assignedCareNavigator.id) || UNASSIGNED_ID,
      });
    }

    if (profileTextMessageConsent !== prevProfileTextMessageConsent) {
      this.setState(({ textMessageConsent: profileTextMessageConsent }));
    }
  }

  componentWillUnmount() {
    Object.entries(this.promises).forEach(
      ([key, promise]) => {
        if (promise && promise.cancel && key !== 'enrollPatient') {
          promise.cancel();
        }
      },
    );
  }

  loadPatientProfile = () => {
    const { params: { id: patientId }, updatePatient, showNotification } = this.props;

    const getProfileRequest = getPatientProfile(patientId);
    const getProfilePromise = getProfileRequest.promise;

    getProfilePromise.then((data) => {
      delete getProfileRequest.promise;

      updatePatient({ profile: data || {} });
    }).catch((error) => {
      delete getProfileRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load patient profile information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  handleSelect = (cn = {}) => {
    this.setState({
      cnId: cn ? cn.value : UNASSIGNED_ID,
    });
  };

  toogleConfirmButtons = () => {
    const { uncheckedBoxes } = this.state;
    this.setState({
      uncheckedBoxes: !uncheckedBoxes,
    });
  }

  confirmUnconfirmAll = (value) => {
    this.toogleConfirmButtons();
    this.questions.every((item, index) => {
      const checkboxName = `questionCheckbox${index}`;
      this.checkboxClicked(checkboxName, value);

      return true;
    });
  }

  getAssignParams = () => {
    const { cnId } = this.state;

    return {
      cnId: cnId === UNASSIGNED_ID ? null : cnId,
    };
  };

  getGoalsData = () => {
    const {
      user: { progressMap }, params: { tenant: tenantUrl },
      showNotification, updateGoals,
    } = this.props;

    const promiseName = 'getGoals';
    const getGoalsRequest = getGoals();
    const getGoalsPromise = getGoalsRequest.promise;

    this.promises[promiseName] = getGoalsPromise;

    getGoalsPromise.then((data) => {
      delete this.promises[promiseName];

      if (!isObjectsEqual(progressMap, data)) {
        updateGoals(data);
        updateUserInfoInStorage({ progressMap: data }, tenantUrl);
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load goals data',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  saveTextConsentPreference = () => {
    const { params: { id: patientId } } = this.props;

    const { showNotification, updatePatient, patient: { profile } } = this.props;
    const { textMessageConsent } = this.state;

    const defaultProfileData = {
      homePhoneInvalid: false,
      cellPhoneInvalid: false,
      workPhoneInvalid: false,
      emergencyContactHipaaRelease: false,
      monthCallEarly: false,
      preferredCallTime: null,
      textMessageConsent,
    };

    const newProfileData = profile && profile.createdAt
      ? { ...profile, textMessageConsent } : defaultProfileData;


    const updateProfileRequest = profile && profile.createdAt
      ? updatePatientProfile(patientId, newProfileData)
      : savePatientProfile(patientId, newProfileData);
    const updateProfilePromise = updateProfileRequest.promise;

    return updateProfilePromise.then((data) => {
      delete updateProfileRequest.promise;

      updatePatient({ profile: data });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete updateProfileRequest.promise;

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error occurred while attempting to save this patient\'s text preferences',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };


  handleSubmit() {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    const isAllCheckboxClicked = this.questions.every((item, index) => {
      const { hasOwnProperty } = Object.prototype;
      const { confirmation } = this.state;
      const checkboxName = `questionCheckbox${index}`;

      return hasOwnProperty.call(confirmation, checkboxName) && confirmation[checkboxName];
    });

    if (!isAllCheckboxClicked) {
      const { showNotification } = this.props;
      showNotification({
        message: 'To enroll patient you need to confirm all procedure steps',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return;
    }

    this.setState({
      isAuditingModalOpen: true,
    });
  }

  sendStatusUpdateRequest({ activityNote, activityDuration, activityDirectContact } = {}) {
    const { user: { role }, showNotification, updatePatient } = this.props;
    const { navigate, params: { id: patientId, tenant: tenantUrl } } = this.props;

    const assignParams = this.getAssignParams();
    const data = {
      ...assignParams,
      ...(activityNote && { activityNote }),
      ...(activityDuration && { activityDuration }),
      ...(typeof activityDirectContact === 'boolean' && { activityDirectContact }),
    };

    const promiseName = 'enrollPatient';
    const enrollPatientRequest = enrollPatient(patientId, data);
    const enrollPatientPromise = enrollPatientRequest.promise;
    this.promises[promiseName] = enrollPatientRequest;

    enrollPatientPromise.then(async (patientInfo) => {
      delete this.promises[promiseName];

      updatePatient({
        status: patientInfo.status,
        permissions: patientInfo.permissions,
        billing: patientInfo.billing,
      });

      if (role !== USER_ROLES.ADMIN) {
        await this.getGoalsData();
      }

      navigate({
        pathname: `/${tenantUrl}/cn/patient/${patientId}/summary`,
        state: {
          notification: {
            message: 'Patient has been successfully enrolled.',
            autoHide: true,
            notificationType: NOTIFICATION_TYPE.SUCCESS,
          },
        },
      });

      this.saveTextConsentPreference();
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Error occurred during request, please try again later.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  checkboxClicked(field, value) {
    const { confirmation } = this.state;
    confirmation[field] = value;
    this.setState({
      confirmation,
    });
  }

  goHistoryBack() {
    const { navigate, params: { tenant: tenantUrl, id: patientId } } = this.props;

    navigate(`/${tenantUrl}/cn/patient/${patientId}/summary`);
  }

  renderAssignmentBlock() {
    const {
      cnList, pesList, user, patient: { assignedCareNavigator },
    } = this.props;
    const { cnId } = this.state;

    const selectList = [...cnList];
    let usersStatus = ASSIGNED_USERS_STATUS.CN;

    if (user.role === USER_ROLES.ADMIN || user.role === USER_ROLES.CN
      || user.role === USER_ROLES.PES) {
      usersStatus = ASSIGNED_USERS_STATUS.OWNER;
      selectList.push(...pesList);
    }

    if (user.role !== USER_ROLES.ADMIN && assignedCareNavigator) {
      selectList.push(assignedCareNavigator);
    }

    const finalSelectList = prepareCareNavigatorsList(selectList);

    const handleAsyncList = (input, prevOptions) => getAsyncList(
      input, prevOptions, finalSelectList,
    );

    const getSelectedCN = cnValue => finalSelectList.find(cn => cn.value === cnValue) || { label: '' };

    return (
      <div data-test="enrollment_assigned" className="d-flex-center w-100 p-3 text-left border border-top-0">
        <span className="text-nowrap mr-2 text-ccm-bali-hai">ASSIGNED CN:</span>
        <AsyncPaginate
          className="Select-menu-up"
          name="tenants-select"
          placeholder={usersStatus}
          noResultsText={`${usersStatus} not found...`}
          clearable={false}
          value={cnId && getSelectedCN(cnId)}
          searchable
          loadOptions={handleAsyncList}
          onChange={this.handleSelect}
        />
      </div>
    );
  }

  render() {
    const {
      patient: { chronicConditions }, timezone,
    } = this.props;
    const { textMessageConsent, isAuditingModalOpen, uncheckedBoxes } = this.state;

    const chronicConditionsCount = chronicConditions ? chronicConditions.length : 0;
    const questionRows = this.questions.map((item, index) => (
      <tr className="question-row" key={`enrollment_question_${index}`}>
        <td>
          <span className="enrollment-list-number">{`${index + 1}.`}</span>
          <span data-test="enrollment_questions">{item}</span>
        </td>
        <td data-test="enrollment_checkBox">
          <Checkbox
            label=""
            fieldKey={`questionCheckbox${index}`}
            submitCallback={this.checkboxClicked}
            initialValue={this.state.confirmation[`questionCheckbox${index}`]}
          />
        </td>
      </tr>
    ));

    return (
      <div className="patient-section my-1 mr-3 h-100 overflow-auto">
        <h5 className="text-uppercase text-left">
          <b className="text-ccm-blue">{chronicConditionsCount}</b>
          {' CCM Eligible Conditions Detected '}
        </h5>
        <div className="section-content flex-container">
          <div className="box box-full patient-enrollment" data-test="enrollment_date">
            <table>
              <tbody>
                <tr>
                  <th>
                    <div className="input-label">
                      {`Patient enrollment date: ${moment.tz(timezone).format(DATE_FORMAT.FULL)}`}
                    </div>
                  </th>
                  <th>Confirmed</th>
                  <th rowSpan={this.questions.length + 1}>
                    <div className="form-button">
                      <button
                        type="button"
                        className="button select-unselect-button"
                        onClick={() => this.confirmUnconfirmAll(uncheckedBoxes)}
                        data-test="confirmAllButton"
                      >
                        { uncheckedBoxes ? 'Select all' : 'Unselect all' }
                      </button>
                      <button
                        type="button"
                        className="button accept-button"
                        onClick={this.handleSubmit}
                        data-test="completeEnrollmentButton"
                      >
                        Enroll
                      </button>
                      <button
                        type="button"
                        className="button active-button"
                        data-test="enrollment_backButton"
                        onClick={this.goHistoryBack}
                      >
                        Back
                      </button>
                    </div>
                  </th>
                </tr>
                <tr>
                  <td>
                    <span>I attest that:</span>
                  </td>
                </tr>
                {questionRows}
              </tbody>
            </table>
          </div>
          <div className="box box-full patient-enrollment" data-test="enrollment_textConsentHeader">
            <Form className="text-left">
              <Form.Group controlId="formTextConsent" className="my-2">
                <span className="d-block mb-2">
                  The patient/caregiver agrees to receive communication through text message
                </span>
                <Select
                  name="textConsentSwitch"
                  labelKey="name"
                  clearable={false}
                  value={textMessageConsent}
                  options={PATIENT_OPT_IN_ENGOODEN_TEXT_OPTIONS}
                  onChange={ev => this.setState({ textMessageConsent: ev && ev.value ? ev.value : 'NONE' })}
                  data-test="enrollment_textConsentDropdown"
                />
              </Form.Group>
            </Form>
          </div>
          {this.renderAssignmentBlock()}
        </div>
        <StatusNoteModal
          isModalOpen={isAuditingModalOpen}
          setIsModalOpen={isOpened => this.setState({
            isAuditingModalOpen: isOpened,
          })}
          submitCallback={statusNote => this.sendStatusUpdateRequest({ ...statusNote })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
    tenant: state.tenant,
    timezone: state.tenant && state.tenant.timezone,
    user: state.user,
    cnList: state.cnList,
    pesList: state.pesList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updateGoals: value => dispatch(UpdateUserGoals(value)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientEnrollment));
