// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// actions
import ShowNotification, { HideNotification } from '../../actions/notification';
import AddTenants from '../../actions/tenants';
// services
import { getCurrentTenant } from '../../services/tenants';
import Login from '../../services/login';
import ErrorBuilder from '../../services/errorMessageBuilder';
import {
  formatPhoneForRequest,
  validatePassword,
  validatePhoneRequired,
  validateRequired,
} from '../../services/helpers';
// Components
import { withRouter } from '../shared/WithRouter';
// views
import LabeledInput from '../base/LabeledInput';
import { NOTIFICATION_TYPE } from '../../constants/constants';

function getUser(state, tenantSystemName, token) {
  return {
    firstName: state.given_name,
    middleName: state.middle_name,
    lastName: state.family_name,
    dateOfBirth: state.birthdate,
    phoneNumber: state.phone_number,
    password: state.password,
    email: state.email,
    tenantSystemName,
    signUpToken: token,
    username: state.username.toLowerCase(),
  };
}

export class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      username: '',
      email: '',
      given_name: '',
      middle_name: '',
      family_name: '',
      birthdate: null,
      phone_number: '',
      password: '',
      confirmPassword: '',
      token: '',
    };

    this.refs = {};
    this.promises = {};

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveRef = this.saveRef.bind(this);
    this.validate = this.validate.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.checkSignUpAvailability = this.checkSignUpAvailability.bind(this);
  }

  componentDidMount() {
    this.checkSignUpAvailability();
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  saveRef(name, ref) {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  }

  checkSignUpAvailability() {
    const { tenant } = this.props;

    if (!tenant || !tenant.signUpEnabled) {
      const { navigate, params: { tenant: tenantUrl } } = this.props;

      navigate(`/${tenantUrl}/404`);
    }
  }

  validate() {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      result = this.refs[key].validate() && result;
    });

    return result;
  }

  validateConfirmPassword() {
    if (this.state.password !== this.state.confirmPassword) {
      return 'do not match';
    }

    return validateRequired(this.state.confirmPassword);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.loading || !this.validate()) {
      return false;
    }

    const { addTenants, hideNotification, showNotification } = this.props;
    const { navigate, params: { tenant: tenantUrl } } = this.props;
    const { tenant } = this.props;

    if (!tenant) {
      showNotification({
        message: 'Please select existing tenant',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return false;
    }

    if (!tenant.signUpEnabled) {
      showNotification({
        message: 'Please, ask your administrator to add you in the system',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return false;
    }

    hideNotification();

    this.setState({
      loading: true,
    });

    const promiseNames = ['signUp', 'getCurrentTenant'];

    const userData = Object.assign({}, this.state);
    userData.phone_number = formatPhoneForRequest(this.state.phone_number);

    const signUpRequest = Login.signUp(getUser(userData, tenantUrl, this.state.token));
    const signUpPromise = signUpRequest.promise;
    this.promises[promiseNames[1]] = signUpRequest;

    return signUpPromise.then(() => {
      delete this.promises[promiseNames[0]];

      const getCurrentTenantRequest = getCurrentTenant(tenantUrl);
      const getCurrentTenantPromise = getCurrentTenantRequest.promise;
      this.promises[promiseNames[1]] = getCurrentTenantRequest;

      return getCurrentTenantPromise;
    }).then((result) => {
      delete this.promises[promiseNames[1]];

      addTenants({ ...result });

      this.setState({
        loading: false,
      });
      showNotification({
        message: 'You have been successfully registered.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      navigate(`/${tenantUrl}`);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseNames[0]];
      delete this.promises[promiseNames[1]];

      if (error.status === 401 || error.status === 403) {
        return;
      }


      this.setState({
        loading: false,
      });
      showNotification({
        message: ErrorBuilder(error.data),
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const { params: { tenant: tenantUrl } } = this.props;

    return (
      <form
        className="form-container login-form"
        onSubmit={this.handleSubmit}
        noValidate
      >
        <div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'username')}
              label="User ID"
              validateCallback={validateRequired}
              type="text"
              name="username"
              value={this.state.username}
              maxLength="45"
              handleChange={this.handleChange}
              required
            />
          </div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'email')}
              label="Email"
              type="text"
              name="email"
              value={this.state.email}
              maxLength="45"
              handleChange={this.handleChange}
            />
          </div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'given_name')}
              label="First name"
              validateCallback={validateRequired}
              type="text"
              name="given_name"
              value={this.state.given_name}
              maxLength="45"
              handleChange={this.handleChange}
              required
            />
          </div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'middle_name')}
              label="Middle name"
              type="text"
              name="middle_name"
              value={this.state.middle_name}
              maxLength="45"
              handleChange={this.handleChange}
            />
          </div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'family_name')}
              label="Last name"
              validateCallback={validateRequired}
              type="text"
              name="family_name"
              value={this.state.family_name}
              maxLength="45"
              handleChange={this.handleChange}
              required
            />
          </div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'phone_number')}
              label="Phone number"
              validateCallback={validatePhoneRequired}
              type="text"
              name="phone_number"
              formatType="phone"
              value={this.state.phone_number}
              handleChange={this.handleChange}
              required
            />
          </div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'password')}
              label="Password"
              validateCallback={validatePassword}
              type="password"
              name="password"
              value={this.state.password}
              handleChange={this.handleChange}
              required
            />
          </div>
          <div className="form-row">
            <LabeledInput
              ref={this.saveRef.bind(this, 'confirmPassword')}
              label="Confirm password"
              validateCallback={this.validateConfirmPassword}
              type="password"
              name="confirmPassword"
              value={this.state.confirmPassword}
              handleChange={this.handleChange}
              required
            />
          </div>
        </div>
        <div className="form-row">
          <button
            type="submit"
            className="button-active"
          >
            Sign up
          </button>
        </div>

        <div className="form-row links several-links">
          <Link to={`/${tenantUrl}`}>Sign in</Link>
          <Link to={`/${tenantUrl}/reset`}>Reset password</Link>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    tenant: state.tenant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addTenants: tenantData => dispatch(AddTenants(tenantData)),
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));
