import patientImportData1 from './patient.json';
import patientImportData2 from './template1_patient.json';
import patientImportData3 from './template2_patient.json';
import patientImportData4 from './template3_patient.json';
import patientImportData5 from './template4_patient.json';

export const PATIENT_LIST = [
  patientImportData1,
  patientImportData2,
  patientImportData3,
  patientImportData4,
  patientImportData5,
];
