// Libraries
import React, { useState } from 'react';
import _groupBy from 'lodash/groupBy';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
// Views
import { Button, Dropdown } from 'react-bootstrap';
import QaNotesModal from '../patientNotes/QaNotesModal';
// Services
import { parseDraftToHtml } from '../../../services/helpers';
// Constants
import {
  EMPTY_STRING, QA_STATUSES, DATE_FORMAT, USER_ROLES, QA_STATUS_EDIT,
} from '../../../constants/constants';

const defaultYear = moment().format(DATE_FORMAT.YEAR);

export function QaNotesHistory(props) {
  const { patientHistory: { qaNotes = [] } = {}, patientId, user = {} } = props;
  const [qaFilter, setQAFilter] = useState(defaultYear);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialQaNote, setInitialQaNote] = useState(null);
  const isAdminUser = (user.role && user.role === USER_ROLES.ADMIN);

  const QA_FILTERS = { [defaultYear]: { name: 'This Year' } };

  const qaNotesWithYear = qaNotes && qaNotes.map(
    note => ({ ...note, createdAtYear: moment(note.createdAt).format(DATE_FORMAT.YEAR) }),
  );
  const qaNotesYearGrouped = _groupBy(qaNotesWithYear, note => note.createdAtYear);
  Object.keys(qaNotesYearGrouped).forEach((year) => {
    QA_FILTERS[year] = {
      name: `${year}`,
    };
  });

  const filterDropDownItems = Object.keys(QA_FILTERS).map((key, index) => {
    const yearName = defaultYear === key ? 'This Year' : key;
    return (
      <Dropdown.Item
        data-test={`qaNotesHistory_filter_${key}`}
        key={`qas_filter_${index}`}
        onClick={() => {
          setQAFilter(QA_FILTERS[key].name);
        }}
        as="button"
        className="d-flex my-1"
      >
        <div className="pr-3">{yearName}</div>
      </Dropdown.Item>
    );
  });

  const getFilter = (
    <Dropdown
      className="position-absolute"
      style={{ left: 1 }}
      data-test="qaNotes_dateFilter"
    >
      <Dropdown.Toggle
        variant="ccm-light-gray"
        className="py-0"
      >
        {`Filter: ${defaultYear === qaFilter ? 'This Year' : qaFilter}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterDropDownItems}
      </Dropdown.Menu>
    </Dropdown>
  );

  function renderEmptyRow() {
    return (
      <tr data-test="qaNotesHistory_noQaNotes">
        <td colSpan="5" className="p-2 border-0">
          This patient has no QA Notes.
        </td>
      </tr>
    );
  }

  const renderQAReviewsRows = () => {
    if (qaNotesYearGrouped[qaFilter] && qaNotesYearGrouped[qaFilter].length) {
      const sortedData = _reverse(
        _sortBy(qaNotesYearGrouped[qaFilter], el => moment(el.createdAt)),
      );

      return sortedData.map((qaNote, index) => {
        const getDateString = date => moment(date).format(DATE_FORMAT.SHORT);

        const getFullName = createdBy => `${createdBy.firstName || ''} ${createdBy.lastName || ''}`
          .replace(/  +/g, ' ');

        const getQaStatusLabel = (type) => {
          const statusKey = Object.keys(QA_STATUSES).find(key => QA_STATUSES[key].type === type);
          return QA_STATUSES[statusKey].labelA;
        };

        const isNoteOwner = qaNote && (qaNote.createdBy.id === user.id);

        const isEnabledToEdit = isNoteOwner && QA_STATUS_EDIT.includes(qaNote.status);

        const handleEditQaNote = () => {
          setInitialQaNote(qaNote);
          setIsModalOpen(true);
        };

        return (
          <tr
            key={`qa_row_${index}_${qaNote.date}-${qaNote.name}`}
            className={`${isEnabledToEdit ? ' qa-edit-note' : ''}`}
            data-test="qaNotesHistory_qaNoteRow"
          >
            <td {...(isEnabledToEdit && { onClick: () => handleEditQaNote() })} data-test="qaNotesHistory_editQaNote">
              {qaNote.createdAt && getDateString(qaNote.createdAt)}
            </td>
            <td {...(isEnabledToEdit && { onClick: () => handleEditQaNote() })} data-test="qaNotesHistory_editQaNote">
              {qaNote.createdBy ? getFullName(qaNote.createdBy) : EMPTY_STRING}
            </td>
            <td
              {...(isEnabledToEdit && { onClick: () => handleEditQaNote() })}
              className={`qa__status qa-${qaNote && qaNote.status.toLowerCase()}`}
              data-test="qaNotesHistory_editQaNote"
            >
              {getQaStatusLabel(qaNote.status)}
            </td>
            <td
              {...(isEnabledToEdit && { onClick: () => handleEditQaNote() })}
              className="qa__comments"
              data-test="qaNotesHistory_editQaNote"
            >
              {qaNote.text && parseDraftToHtml(qaNote.text)}
            </td>
            <td className="qa__actions">
              {isEnabledToEdit && (
              <Button
                size="md"
                variant="link"
                className="d-flex-center p-0"
                onClick={() => handleEditQaNote()}
                data-test="qaNotesHistory_editButton"
              >
                <span className="d-flex-center bi-pencil-square" />
              </Button>)}
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  return (
    <div className="history-qaNotes card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        {getFilter}
        <span className="text-uppercase">QA Reviews</span>
        {isAdminUser && (
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => {
            setInitialQaNote(null);
            setIsModalOpen(true);
          }}
          data-test="qaNotesHistory_plusButton"
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>)}
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2">Date</th>
              <th className="py-2">Reviewer</th>
              <th className="py-2">Status</th>
              <th className="py-2">Comments</th>
              <th className="py-2" />
            </tr>
          </thead>
          <tbody>
            {renderQAReviewsRows()}
          </tbody>
        </table>
      </div>

      <QaNotesModal
        patientId={patientId}
        initialQaNote={initialQaNote}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    user: state.user,
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

export default connect(mapStateToProps)(QaNotesHistory);
