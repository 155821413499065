// Libraries
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
// Actions
import { UpdatePatient } from '../../actions/patient';
import ShowNotification from '../../actions/notification';
// Services
import { getPatientReminders, updatePatientReminder } from '../../services/patient';
import { getCallTimeLabel } from '../../services/helpers';
// Constants
import {
  NOTIFICATION_TYPE, DATE_FORMAT,
} from '../../constants/constants';
// Views
import RemindersModal from './reminders/RemindersModal';

export const Reminders = (props) => {
  const { patientId, isPanelOpened } = props;

  const [selectedReminder, setSelectedReminder] = useState(null);
  const [isRemindersModalOpen, setIsRemindersModalOpen] = useState(false);

  const handleAddEditReminder = (reminder) => {
    setSelectedReminder(reminder);
    setIsRemindersModalOpen(true);
  };

  const getRemindersCount = () => {
    const { updatePatient, showNotification } = props;

    const getRemindersRequest = getPatientReminders(patientId);
    const getRemindersPromise = getRemindersRequest.promise;

    return getRemindersPromise.then((data) => {
      delete getRemindersRequest.promise;

      updatePatient({
        hasReminders: data.filter(x => x.status === 'CREATED').length,
      });
    }).catch((error) => {
      delete getRemindersRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load patient reminders, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleReminderStatus = (reminder, status) => {
    const {
      showNotification, updatePatient, patient: { reminders = [] } = {},
    } = props;

    const formattedDate = reminder.reminderDate
      ? moment(reminder.reminderDate, DATE_FORMAT.FULL).format(DATE_FORMAT.FULL)
      : reminder.reminderDate;

    const updatedReminder = { ...reminder, reminderDate: formattedDate, status };

    const addReminderRequest = updatePatientReminder(patientId, updatedReminder);
    const addReminderPromise = addReminderRequest.promise;

    return addReminderPromise.then((data) => {
      delete addReminderPromise.promise;

      let updatedReminders;
      if (status === 'DELETED') {
        updatedReminders = reminders.filter(r => r.id !== data.id);
      } else {
        updatedReminders = reminders.map(r => (r.id === data.id ? data : r));
      }
      updatePatient({
        reminders: updatedReminders,
        hasReminders: updatedReminders.filter(x => x.status === 'CREATED').length,
      });
    }).catch((error) => {
      delete addReminderPromise.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to modify this reminder',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderReminders = () => {
    const { patient: { reminders = [] } } = props;

    if (!reminders.length) {
      return <small className="d-block text-ccm-gray mb-2" data-test="reminders_emptyMsg">Empty reminders</small>;
    }

    const isRedText = reminder => (
      (reminder.status !== 'CHECKED' && moment.utc(reminder.reminderDate, DATE_FORMAT.FULL).isBefore(moment.utc(), 'day'))
        ? 'text-ccm-red' : ''
    );

    return (
      <Form className="reminders-list pr-2" data-test="reminders_formList">
        {reminders.map(reminder => (
          <div
            key={`patientReminder__${reminder.id}`}
            className="d-flex align-items-center mb-3"
            data-test="reminders_entryRow"
          >
            <Form.Check type="checkbox">
              <Form.Check.Input
                type="checkbox"
                defaultChecked={reminder.status === 'CHECKED'}
                onClick={() => handleReminderStatus(
                  reminder,
                  reminder.status === 'CHECKED' ? 'CREATED' : 'CHECKED',
                )}
                data-test="reminders_checkReminderBtn"
              />
              <div
                className="reminder__content text-break"
                onClick={() => handleAddEditReminder(reminder)}
                data-test="reminders_handleEditReminder"
              >
                <Form.Check.Label>{reminder.text}</Form.Check.Label>
                {(reminder.reminderDate || reminder.reminderTime) && (
                <span
                  className={`d-block mt-2 ${isRedText(reminder)}`}
                  data-test="reminders_reminderTime"
                >
                  {`${reminder.reminderDate ? moment(reminder.reminderDate, DATE_FORMAT.FULL).format(DATE_FORMAT.FULL) : ''} `}
                  {`${reminder.reminderTime !== 'NONE' ? `(${getCallTimeLabel(reminder.reminderTime)})` : ''}`}
                </span>)}
              </div>
            </Form.Check>
            <Button
              variant="link-dark"
              className="ml-auto p-0"
              onClick={() => handleReminderStatus(reminder, 'DELETED')}
              data-test="reminders_removeReminderBtn"
            >
              <i className="d-flex-center bi-x" />
            </Button>
          </div>
        ))}
      </Form>
    );
  };

  useEffect(() => {
    getRemindersCount();
  }, []);

  return (
    <div className="ccm-patient-reminders w-100">
      <div className="d-flex-center mb-1">
        <i className="d-flex-center bi-journal-check mr-1" data-for="tooltip-patientPanel" data-tip="Reminders" />
        {isPanelOpened && (
          <Fragment>
            <span className="mr-auto">Reminders</span>
            <Button
              size="sm"
              variant="link-dark"
              className="ml-auto"
              onClick={() => handleAddEditReminder(null)}
              data-test="reminders_addReminderBtn"
            >
              <i className="d-flex-center bi-plus-square" />
            </Button>
          </Fragment>
        )}
      </div>
      {isPanelOpened && (
      <div className="border rounded pt-2 pl-2">
        {renderReminders()}
      </div>)}

      <RemindersModal
        patientId={patientId}
        initialReminder={selectedReminder}
        isModalOpen={isRemindersModalOpen}
        setIsModalOpen={setIsRemindersModalOpen}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);
