// Libraries
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _groupBy from 'lodash/groupBy';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';
import _values from 'lodash/values';
import _flattenDeep from 'lodash/flattenDeep';
import { Button, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
// Actions
import ShowNotification from '../../../actions/notification';
// Services
import { getNodPatientsByDate } from '../../../services/patient';
import { isWeekendOrHoliday } from '../../../services/helpers';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE, USER_ROLES } from '../../../constants/constants';
// Custom Hooks
import usePatientsBirthdays from '../../../hooks/services/usePatientsBirthdays';
import useLoadHolidays from '../../../hooks/services/useLoadHolidays';

export const MyCallSchedule = () => {
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));
  const { user: { id: userId, role } } = useSelector(state => state);
  const navigate = useNavigate();
  const { tenant: tenantUrl } = useParams();

  const [nodPatients, setNodPatients] = useState({});
  const [dateSelected, setDateSelected] = useState(moment.utc());
  const { patientsBirthdays } = usePatientsBirthdays();
  const { holidays } = useLoadHolidays();

  const isPesUser = role === USER_ROLES.PES;

  const loadNodPatients = (selectedDate) => {
    const getNodPatientsRequest = getNodPatientsByDate(userId, selectedDate);
    const getNodPatientsPromise = getNodPatientsRequest.promise;

    return getNodPatientsPromise.then((data) => {
      setNodPatients(data);
    }).catch((error) => {
      delete getNodPatientsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load Next Outreach Date patients, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    loadNodPatients(moment(dateSelected).format(DATE_FORMAT.FULL_SERVER));
  }, [dateSelected]);

  const renderEmptyRow = text => (
    <p className="call-schedule__empty ml-3 mt-3 text-left" data-test="callSchedule_emptyCallSchedule">
      {text}
    </p>);

  const getOtherPropertiesByCategory = (time) => {
    switch (time) {
      case 'Morning':
        return { order: 2, range: '8-12' };
      case 'Late morning':
        return { order: 3, range: '10-12' };
      case 'Early morning':
        return { order: 1, range: '8-10' };
      case 'Afternoon':
        return { order: 2, range: '12-5' };
      case 'Late afternoon':
        return { order: 3, range: '2-5' };
      case 'Early afternoon':
        return { order: 1, range: '12-2' };
      case 'None Specified':
      default:
        return { order: 1, range: '' };
    }
  };

  const getUrl = (patientId) => {
    if (isPesUser) {
      return navigate(`/${tenantUrl}/cn/summary-patient/${patientId}/overview`);
    }
    return navigate(`/${tenantUrl}/cn/patient/${patientId}/summary`);
  };

  const renderPatientsRows = (patients, isMultipleCategory = true) => {
    const orderedPatients = patients.sort((objA, objB) => objA.order - objB.order);
    const groupedNodPatientsByTime = _groupBy(orderedPatients, el => el.time);
    const anytimeCategories = ['Afternoon', 'Morning'];
    return Object.entries(groupedNodPatientsByTime).map(([subcategory, rows]) => (
      <div
        className={`p-2 text-left ${isMultipleCategory ? 'col-6 border-right border-ccm-gray' : 'col-12'}`}
        key={`callSchedule_${_camelCase(subcategory)}`}
        data-test={`callSchedule_callSchedule${_camelCase(subcategory)}`}
      >
        {isMultipleCategory && (
          <p className="call-schedule__subcategory-name ml-3 mb-1 text-ccm-gray">
            {`${_startCase(subcategory)} ${anytimeCategories.some(c => c === subcategory) ? 'Anytime' : ''} (${getOtherPropertiesByCategory(subcategory).range})`}
          </p>)}
        {rows.map(el => (
          <Button
            key={`callSchedule_${_camelCase(el.patientId)}`}
            variant="link-dark"
            className="p-0 call-schedule__patient-name ml-3 mb-1"
            onClick={() => getUrl(el.patientId)}
            data-test="callSchedule_callSchedulePatient"
          >
            <u>{`${el.name}`}</u>
          </Button>))}
      </div>));
  };

  const getTimeCategory = (time) => {
    switch (time) {
      case 'Morning':
      case 'Late morning':
      case 'Early morning':
        return 'Morning';
      case 'Afternoon':
      case 'Late afternoon':
      case 'Early afternoon':
        return 'Afternoon';
      case 'None Specified':
        return 'NoneSpecified';
      default:
        return 'Anytime';
    }
  };

  const getNodPatientsByTime = (categories) => {
    const nodPatientsWithTime = [];
    let groupedNodPatientsByCat = null;
    Object.entries(nodPatients).map(([time, patients]) => patients.map(
      patient => nodPatientsWithTime.push(
        {
          name: patient.fullName,
          patientId: patient.id,
          category: getTimeCategory(time),
          time,
          ...getOtherPropertiesByCategory(time),
        },
      ),
    ));
    if (nodPatientsWithTime.length > 0) {
      const nodPatientsWithTimeFiltered = categories ? nodPatientsWithTime
        .filter(el => categories.some(category => category === el.category))
        : nodPatientsWithTime;
      groupedNodPatientsByCat = _groupBy(nodPatientsWithTimeFiltered, el => el.category);
    }
    return groupedNodPatientsByCat;
  };

  const renderNodPatientsTableLeft = () => {
    const categories = ['Afternoon', 'Morning'];
    const groupedNodPatientsByCat = getNodPatientsByTime(categories);
    return (
      <Fragment>
        <div
          key="callSchedule_Morning"
          className="call-schedule-category col-12 border border-ccm-gray"
          data-test="callSchedule_callScheduleMorning"
        >
          <div className="call-schedule__category-name bg-ccm-blue px-2 py-1">
            Morning
          </div>
          <div className="row mx-0">
            {groupedNodPatientsByCat && groupedNodPatientsByCat.Morning ? renderPatientsRows(groupedNodPatientsByCat.Morning) : renderEmptyRow('There are no patients scheduled for the morning')}
          </div>
        </div>
        <div
          key="callSchedule_Afternoon"
          className="call-schedule-category col-12 border border-ccm-gray"
          data-test="callSchedule_callScheduleAfternoon"
        >
          <div className="call-schedule__category-name bg-ccm-blue px-2 py-1">
            Afternoon
          </div>
          <div className="row mx-0">
            {groupedNodPatientsByCat && groupedNodPatientsByCat.Afternoon ? renderPatientsRows(groupedNodPatientsByCat.Afternoon) : renderEmptyRow('There are no patients scheduled for the afternoon')}
          </div>
        </div>
      </Fragment>);
  };

  const renderNodPatientsTableRight = () => {
    const categories = ['NoneSpecified', 'Anytime'];
    const groupedNodPatientsByCat = getNodPatientsByTime(categories);
    return (
      <Fragment>
        <div
          key="callSchedule_Anytime"
          className="call-schedule-category col-12 border border-ccm-gray"
          data-test="callSchedule_callScheduleAnytime"
        >
          <div className="call-schedule__category-name bg-ccm-blue px-2 py-1">
            Anytime
          </div>
          <div className="row mx-0">
            {groupedNodPatientsByCat && groupedNodPatientsByCat.Anytime ? renderPatientsRows(groupedNodPatientsByCat.Anytime, false) : renderEmptyRow('There are no patients scheduled for the anytime')}
          </div>
        </div>
        <div
          key="callSchedule_NoneSpecified"
          className="call-schedule-category col-12 border border-ccm-gray"
          data-test="callSchedule_callScheduleNoneSpecified"
        >
          <div className="call-schedule__category-name bg-ccm-blue px-2 py-1">
            None Specified
          </div>
          <div className="row mx-0">
            {groupedNodPatientsByCat && groupedNodPatientsByCat.NoneSpecified ? renderPatientsRows(groupedNodPatientsByCat.NoneSpecified, false) : renderEmptyRow('There are no patients scheduled for the none specified')}
          </div>
        </div>
      </Fragment>);
  };

  const numberPatientsScheduled = _flattenDeep(_values(nodPatients));

  const isOffDay = (date, isCss = true) => isWeekendOrHoliday(date, isCss, holidays);
  const showDaysAlertCss = { dayClassName: isOffDay };
  const dateSelectedMessage = isOffDay(new Date(dateSelected), false);

  return (
    <Fragment>
      <div className="d-flex align-items-center my-4 ml-4">
        <h5 className="text-left title mb-0">
          My Call Schedule
        </h5>
      </div>
      <div className="call-schedule-content">
        <div className="row mb-3">
          <div className="col-8">
            <div className="schedule-content-header d-flex-center">
              <Button
                size="lg"
                variant="link"
                className="p-0"
                onClick={() => { setDateSelected(moment(dateSelected).subtract(1, 'days')); }}
                disabled={dateSelected.isSame(moment.utc(), 'day')}
                data-test="callSchedule_leftArrow"
              >
                <i className="bi bi-chevron-left" />
              </Button>
              <div className="mx-3 d-flex flex-column">
                <h5 data-test="myCallSchedule_date">{moment.utc(dateSelected).format(DATE_FORMAT.FULL_WITH_STING_MONTH)}</h5>
                <span className="text-uppercase text-ccm-gray">
                  {`${numberPatientsScheduled.length} Patients Scheduled`}
                </span>
              </div>
              <Button
                size="lg"
                variant="link"
                className="p-0"
                onClick={() => { setDateSelected(moment(dateSelected).add(1, 'days')); }}
                data-test="callSchedule_rightArrow"
              >
                <i className="bi bi-chevron-right" />
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-7 offset-1">
            <div className="schedule-content">
              <div className="row">
                <div className="col-8">
                  <Row className="no-gutters call-schedule">
                    <Row data-test="callSchedule_callScheduleBody" className="call-schedule-content overflow-auto no-gutters w-100">
                      {renderNodPatientsTableLeft()}
                    </Row>
                  </Row>
                </div>
                <div className="col-4">
                  <Row className="no-gutters call-schedule">
                    <Row data-test="callSchedule_callScheduleBody" className="call-schedule-content overflow-auto no-gutters w-100">
                      {renderNodPatientsTableRight()}
                    </Row>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 offset-1 text-left">
            <div>
              <DatePicker
                name="date"
                showYearDropdown
                minDate={new Date()}
                inline
                todayButton="Today"
                selected={(dateSelected && new Date(dateSelected)) || null}
                onChange={date => setDateSelected(moment.utc(date))}
                data-test="callSchedule_calendarPicker"
                {...showDaysAlertCss}
              />
              {dateSelectedMessage && (
                <div className="medium-text text-ccm-red font-weight-bold">
                  {dateSelectedMessage}
                </div>)
              }
              <div className="mt-4">
                <span className="text-uppercase text-ccm-gray d-block mb-3">
                  {`${moment().format(DATE_FORMAT.MONTH_FULL_NAME)} Birthdays`}
                </span>
                {
                  patientsBirthdays && patientsBirthdays.length > 0 && patientsBirthdays.map(pb => (
                    <div key={`birthday_${pb.patientId}`} className="d-flex flex-align-center mb-1">
                      <Button
                        variant="link-dark"
                        className="p-0"
                        onClick={() => getUrl(pb.patientId)}
                        data-test="callSchedule_birthdayPatientName"
                      >
                        <u>{`${pb.firstName} ${pb.lastName}`}</u>
                      </Button>
                      <span>
                        &nbsp;
                        {`- ${moment.utc(pb.dateOfBirth).format(DATE_FORMAT.MONTH_WITH_DAY)}`}
                      </span>
                    </div>))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyCallSchedule;
