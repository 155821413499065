import errorStrings from '../constants/messages';

export default function buildErrorString(data = {}) {
  let result = data.message;
  let check = false;

  if (data && data.errors) {
    Object.keys(data.errors).forEach((key) => {
      result += check ? ',' : ':';

      if (errorStrings[data.errors[key]]) {
        result += (` ${errorStrings[data.errors[key]]}`);
      } else {
        result += (` ${data.errors[key]}`);
      }
      check = true;
    });
  }

  return result;
}
