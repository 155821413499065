// Libraries
import React from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AsyncPaginate from 'react-select-async-paginate';
// Helpers
import { getAsyncList } from '../../base/AsyncDropdown';


export const UserAssignment = (props) => {
  const { selectedValue, handleOnChange } = props;

  const { cnList, pesList } = useSelector(state => state);

  const buildList = () => {
    const fullList = [{ value: '', label: 'Any User' }];
    const rawList = [...cnList, ...pesList];

    rawList.forEach((user) => {
      fullList.push({
        value: user.id,
        label: `${user.lastName ? `${user.lastName}, ` : ''}${user.firstName ? user.firstName : ''}`,
      });
    });

    return fullList;
  };

  const usersList = buildList();
  const getValue = customValue => usersList.find(item => item.value === customValue) || { label: '' };
  const handleOwnerAsyncList = (input, prevOptions) => getAsyncList(
    input, prevOptions, usersList,
  );

  return (
    <Form.Group controlId="formAssignedUser" className="d-flex mb-0 align-items-center text-left assigned-filter" data-test="UserAssignment_assignedUser">
      <Form.Label className="mb-0 mr-2 font-weight-bold align-self-center title-filter">STATUS CHANGED BY:</Form.Label>
      <AsyncPaginate
        name="assignedUser"
        className="w-25"
        noResultsText="User not found..."
        value={getValue(selectedValue)}
        loadOptions={handleOwnerAsyncList}
        resetValue={usersList[0]}
        onChange={ev => handleOnChange(prevState => ({ ...prevState, userId: ev && ev.value ? ev.value : '' }))}
        data-test="UserAssignment_assignedFilter"
      />
    </Form.Group>
  );
};

export default UserAssignment;
