// libraries
import React from 'react';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import _find from 'lodash/find';
// constants
import {
  QA_STATUSES, USER_BILLING_STATUSES,
  QA_STATUS_COLOR_ENABLED, BILLING_STATUSES, DATE_FORMAT,
} from '../../constants/constants';
// views
import PatientAttributesRow from '../shared/PatientAttributesRow';
import PatientInfo from '../patients/patient/PatientInfo';


/**
 * QaRow component, requires props:
 * patients -- array of patients
 * params
 */
export const QaRow = (props) => {
  const navigate = useNavigate();
  const { tenant: tenantUrl } = useParams();

  const { data: patient } = props;

  const billing = patient && patient.billing ? patient.billing : {};
  const billingStatus = billing.sendingStatus || '';

  function openPatient() {
    const patientId = patient.id;

    navigate(`/${tenantUrl}/cn/patient/${patientId}/summary`);
  }

  const renderCnData = () => {
    if (patient && patient.assignedCareNavigator) {
      return `${patient.assignedCareNavigator.firstName || ''} ${patient.assignedCareNavigator.lastName || ''}`;
    }
    return 'N/A';
  };

  const getBillingStatusColor = () => {
    if (patient && patient.status && USER_BILLING_STATUSES.includes(patient.status)) {
      if (billingStatus && BILLING_STATUSES[billingStatus]) {
        return BILLING_STATUSES[billingStatus].colour;
      }
    }
    return 'no-colour';
  };

  const getSatSurveyDateIcon = () => {
    const surveyType = patient && patient.lastSatisfactionSurveyType;
    switch (surveyType) {
      case 'PHONE':
        return 'telephone-fill';
      case 'CHAT':
        return 'chat-right-dots';
      default:
        return '';
    }
  };

  const patientQaNote = patient && patient.lastQaNote;
  const patientQaStatus = patientQaNote
    && _find(QA_STATUSES, qa => qa.type === patientQaNote.status);

  const getQaNoteColor = (note) => {
    if (note && QA_STATUS_COLOR_ENABLED.includes(note.status)) {
      const statusKey = Object.keys(QA_STATUSES).find(key => QA_STATUSES[key].type === note.status);
      return `text-ccm-${QA_STATUSES[statusKey].color}`;
    }
    return '';
  };

  return (
    <tr
      className="qaRow__wrapper"
      onClick={openPatient}
      data-test="qaRow_qaTableRow"
    >
      <td className={`patientCol__status bg-ccm-${getBillingStatusColor()} p-0`} />
      <PatientInfo patient={{ ...patient }} />
      <td className="patientCol__conditions text-left px-1">
        <PatientAttributesRow patient={patient} />
      </td>
      <td className="patientCol__enrollmentDate px-2">
        {patient && patient.enrollmentDate ? patient.enrollmentDate : 'N/A'}
      </td>
      <td className="patientCol__assignedCn px-1">
        <p>{renderCnData()}</p>
        {patient && patient.tempTakeoverUser && (
          <p className="takeover__name mr-2 text-ccm-red" data-test="patientRow_takeoverName">
            {`TT: ${patient.tempTakeoverUser.firstName || ''} ${patient.tempTakeoverUser.lastName || ''}`}
          </p>
        )}
      </td>
      <td className="patientCol__satSurvey px-2">
        {patient && patient.lastSatisfactionSurveyDate ? patient.lastSatisfactionSurveyDate : 'None'}
        <span className={`bi-${getSatSurveyDateIcon()} ml-2`} />
      </td>
      <td className="patientCol__lastQa px-2">
        {patientQaNote && patientQaNote.status !== QA_STATUSES.none.type ? moment.utc(patientQaNote.createdAt).format(DATE_FORMAT.SHORT) : 'None'}
      </td>
      <td className={`patientCol__qaStatus pl-1 pr-3 ${getQaNoteColor(patientQaNote)}`}>
        {patientQaStatus ? patientQaStatus.labelA : QA_STATUSES.none.labelA}
      </td>
      <td className="patientCol__qalastBilled px-2">
        {patient && patient.lastBilledDate ? patient.lastBilledDate : 'N/A'}
      </td>
    </tr>
  );
};

export default QaRow;
