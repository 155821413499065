// Libraries
import React, { useContext, useState, useEffect } from 'react';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Accordion, Card, Button, Dropdown,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdatePatient, UpdatePatientCarePlan } from '../../../../actions/patient';
// Services
import { deleteIntervention } from '../../../../services/patient';
import { getPlainValueFromDraft, getInterventionType } from '../../../../services/helpers';
// Constants
import { INTERVENTION_PRIORITY_VALUES } from '../../../../constants/interventions';
import { NOTIFICATION_TYPE, EMPTY_STRING, DATE_FORMAT } from '../../../../constants/constants';
// Views
import InterventionsModal from './InterventionModal';
// Local Constants
const DEFAULT_YEAR = moment.utc().format(DATE_FORMAT.YEAR);

export function Interventions(props) {
  const {
    patientId, carePlan: { interventions = [] } = {},
  } = props;

  const DEFAULT_SORTER = {
    createdDate: null,
    createdBy: null,
    type: null,
    title: null,
    priority: null,
  };
  const INTERVENTIONS_FILTERS = { [DEFAULT_YEAR]: { name: DEFAULT_YEAR } };

  if (interventions.length > 0) {
    interventions.forEach((intervention) => {
      const year = intervention.createdDate.split('-')[0];
      INTERVENTIONS_FILTERS[year] = { name: year };
    });
  }

  const [collapseAll, setCollapseAll] = useState(false);
  const [interventionFilter, setInterventionFilter] = useState(DEFAULT_YEAR);
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const [isInterventionsModalOpen, setIsInterventionsModalOpen] = useState(false);
  const [interventionList, setInterventionList] = useState(interventions);
  const [sorter, setSorter] = useState(DEFAULT_SORTER);

  const handleAddEditIntervention = (intervention) => {
    setSelectedIntervention(intervention);
    setIsInterventionsModalOpen(true);
  };

  const handleDeleteIntervention = (intervention) => {
    const {
      carePlan, updatePatient, updatePatientCarePlan, showNotification,
    } = props;

    if (!window.confirm('Are you sure you want to remove the selected intervention?')) {
      return;
    }

    const deleteInterventionRequest = deleteIntervention(intervention.id);
    const deleteInterventionPromise = deleteInterventionRequest.promise;

    deleteInterventionPromise.then(() => {
      delete deleteInterventionRequest.promise;

      const updatedInterventions = interventions.filter(i => i.id !== intervention.id);
      updatePatient({ hasInterventions: updatedInterventions.length });
      updatePatientCarePlan({
        ...carePlan,
        interventions: updatedInterventions,
      });
    }).catch((error) => {
      delete deleteInterventionRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient intervention, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderDropDownItems = Object.keys(INTERVENTIONS_FILTERS).map((key, index) => {
    const yearName = DEFAULT_YEAR === key ? 'This Year' : key;
    return (
      <Dropdown.Item
        key={`interventions__filter-${index}`}
        as="button"
        className="d-flex my-1"
        onClick={() => setInterventionFilter(INTERVENTIONS_FILTERS[key].name)}
        data-test="interventions_filterItem"
      >
        <div className="pr-3">{yearName}</div>
      </Dropdown.Item>
    );
  });

  function renderEmptyRow() {
    return (
      <tr data-test="interventions_emptyMsg">
        <td colSpan="7" className="p-2 border-0">
          No Interventions have been defined for this patient.
        </td>
      </tr>
    );
  }

  const renderInterventionsRows = () => {
    const renderInterventions = interventionList.filter((intervention) => {
      const year = intervention.createdDate.split('-')[0];
      return year === interventionFilter;
    });

    if (renderInterventions && !!renderInterventions.length) {
      return renderInterventions.map((intervention) => {
        const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);

        const getInterventionPriorityLabel = (priority) => {
          if (INTERVENTION_PRIORITY_VALUES[priority]) {
            return INTERVENTION_PRIORITY_VALUES[priority].name;
          }
          return INTERVENTION_PRIORITY_VALUES.NORMAL.name;
        };

        return (
          <tr key={`intervention__row-${intervention.id}`} data-test="interventions_entryRow">
            <td colSpan="7" className="p-0">
              <Accordion defaultActiveKey="0">
                <Card className="border-0 rounded-0">
                  <Card.Header className="bg-light border-0 p-0">
                    <table className="table mb-0 table-hover">
                      <tbody>
                        <tr>
                          <td className="intervention__createdDate" onClick={() => handleAddEditIntervention(intervention)} data-test="interventions_editBtn">
                            {intervention.createdDate && getDateString(intervention.createdDate)}
                          </td>
                          <td className="intervention__createdBy" onClick={() => handleAddEditIntervention(intervention)} data-test="interventions_editBtn">
                            {intervention.createdBy || EMPTY_STRING}
                          </td>
                          <td className="intervention__type" onClick={() => handleAddEditIntervention(intervention)} data-test="interventions_editBtn">
                            {getInterventionType(intervention.type)}
                          </td>
                          <td className="intervention__title" onClick={() => handleAddEditIntervention(intervention)} data-test="interventions_editBtn">
                            {intervention.title || EMPTY_STRING}
                          </td>
                          <td className={`intervention__priority text-${intervention.priority && INTERVENTION_PRIORITY_VALUES[intervention.priority] && INTERVENTION_PRIORITY_VALUES[intervention.priority].variant}`} onClick={() => handleAddEditIntervention(intervention)} data-test="interventions_editBtn">
                            {getInterventionPriorityLabel(intervention.priority)}
                          </td>
                          <td className="intervention__solve px-0">
                            <Button variant="link-dark" className="ml-2 px-0" onClick={() => handleDeleteIntervention(intervention)} data-test="interventions_deleteBtn">
                              <i className="bi-trash" />
                            </Button>
                          </td>
                          <td className="intervention__action px-0">
                            <CustomToggle eventKey="0" collapseAll={collapseAll} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="d-flex-center-between">
                        <p className="flex-grow-1 mb-2">
                          <span className="mr-2">Escalated via:</span>
                          {intervention.escalationType || EMPTY_STRING}
                        </p>
                        <p className="flex-grow-1 mb-2">
                          <span className="mr-2">On:</span>
                          {intervention.escalationDate || EMPTY_STRING}
                        </p>
                        <p className="flex-grow-1 mb-2">
                          <span className="mr-2">To:</span>
                          {intervention.escalationContact || EMPTY_STRING}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mr-2">Description:</p>
                        <div className="intervention__description flex-grow-1">
                          {getPlainValueFromDraft(intervention.note, EMPTY_STRING)}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  const handleSorter = (sort) => {
    setSorter(prevSorter => ({
      ...DEFAULT_SORTER,
      [sort]: prevSorter[sort] !== null ? !prevSorter[sort] : true,
    }));
  };

  const sortByFilter = (filter) => {
    const sortedList = _orderBy(
      interventionList,
      (el) => {
        if (filter === 'createdDate') return moment(el[filter]);
        return el[filter];
      },
      sorter[filter] ? 'asc' : 'desc',
    );

    setInterventionList(sortedList);
  };

  useEffect(() => {
    if (sorter.createdDate !== null) sortByFilter('createdDate');
    if (sorter.createdBy !== null) sortByFilter('createdBy');
    if (sorter.type !== null) sortByFilter('type');
    if (sorter.title !== null) sortByFilter('title');
    if (sorter.priority !== null) sortByFilter('priority');
  }, [sorter.createdDate, sorter.createdBy, sorter.type, sorter.title, sorter.priority]);

  useEffect(() => setInterventionList(interventions), [interventions]);

  useEffect(() => setSorter(DEFAULT_SORTER), [interventionFilter]);

  const renderTableHeader = (attribute, columnName) => (
    <th className={`py-2 intervention__${attribute}`} onClick={() => handleSorter(attribute)} data-test={`interventions_${attribute}Order`}>
      {columnName}
      {sorter[attribute] !== null ? (
        <i className={`bi-chevron-${sorter[attribute] ? 'down' : 'up'} ml-1`} />
      ) : (
        <i className="bi bi-chevron-expand ml-1" />
      )}
    </th>
  );

  return (
    <div className="care-plan-interventions card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <Dropdown
          className="position-absolute"
          style={{ left: 1 }}
          data-test="interventions_filter"
        >
          <Dropdown.Toggle
            variant="ccm-light-gray"
            className="py-0"
          >
            {`Filter: ${DEFAULT_YEAR === interventionFilter ? 'This Year' : interventionFilter}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {renderDropDownItems}
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-uppercase">Interventions</span>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '3rem' }}
          onClick={() => setCollapseAll(!collapseAll)}
          data-test="interventions_collapseAllBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => handleAddEditIntervention(null)}
          data-test="interventions_addBtn"
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              {renderTableHeader('createdDate', 'Date')}
              {renderTableHeader('createdBy', 'Created By')}
              {renderTableHeader('type', 'Category')}
              {renderTableHeader('title', 'Type')}
              {renderTableHeader('priority', 'Priority')}
              <th className="py-2 intervention__solve px-0" />
              <th className="py-2 intervention__action px-0" />
            </tr>
          </thead>
          <tbody>
            {renderInterventionsRows()}
          </tbody>
        </table>
      </div>
      {isInterventionsModalOpen && (
        <InterventionsModal
          patientId={patientId}
          initialIntervention={selectedIntervention}
          isModalOpen={isInterventionsModalOpen}
          setIsModalOpen={setIsInterventionsModalOpen}
          resetSort={() => setSorter(DEFAULT_SORTER)}
        />
      )}
    </div>
  );
}

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0" onClick={decoratedOnClick}>
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

function mapStateToProps(state) {
  return {
    carePlan: state.patient && state.patient.carePlan,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updatePatientCarePlan: patientData => dispatch(UpdatePatientCarePlan(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Interventions);
