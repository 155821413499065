// libraries
import React, { Fragment, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// components
import BillingHistoryModal from './BillingHistoryModal';
// constants
import { USER_ROLES, BILLING_STATUSES } from '../../../constants/constants';

const BillingHistoryItem = (props) => {
  const { billingItem, shouldDisplayPatientData } = props;
  const [opened, setOpened] = useState(false);

  const { user: { role: userRole } } = useSelector(state => state);
  const { tenant: tenantUrl } = useParams();

  const dateFormatted = billingItem.dateOfService ? moment(billingItem.dateOfService, 'YYYY-MM-DD').format('MM/DD/YY') : 'N/A';

  return (
    <Fragment>
      <tr onClick={() => setOpened(!opened)} data-test="billingHistoryItem_row">
        <td data-test="billingHistoryItem_collapseArrow">
          <div className={`arrow ${opened ? 'opened' : 'closed'}`} />
        </td>
        <td className="sortable-value">{dateFormatted}</td>
        {shouldDisplayPatientData && (
          <Fragment>
            <td>{billingItem.patientEhrId}</td>
            <td>
              {userRole === USER_ROLES.ADMIN ? (
                <Link to={`/${tenantUrl}/cn/patient/${billingItem.patientId}/summary`} data-test="billingHistoryItem_patientLink">
                  {`${billingItem.patientFirstName} ${billingItem.patientLastName}`}
                </Link>
              ) : (
                <span data-test="billingHistoryItem_patientName">{`${billingItem.patientFirstName} ${billingItem.patientLastName}`}</span>
              )}
            </td>
          </Fragment>
        )}
        <td>{billingItem.totalMinutes}</td>
        <td>
          { BILLING_STATUSES[billingItem.status]
          && BILLING_STATUSES[billingItem.status].name }
        </td>
        <td>{billingItem.icd10Codes}</td>
        <td>{billingItem.cptCode}</td>
        <td onClick={event => event.stopPropagation()} data-test="billingHistoryItem_view">
          <BillingHistoryModal
            reportId={billingItem.reportId}
            billingItemName={`Report #${billingItem.reportId}`}
          />
        </td>
      </tr>
      {opened && (
        <tr>
          {/* TODO: make columns count in this table more flexible */}
          { !shouldDisplayPatientData && <td /> }
          <td colSpan={shouldDisplayPatientData ? 3 : 2}>
            <b data-test="billingHistoryItem_physicianName">Physician Name:</b>
            <br />
            {`${billingItem.physicianFirstName} ${billingItem.physicianLastName ? billingItem.physicianLastName : ''}`}
          </td>
          <td colSpan={shouldDisplayPatientData ? 3 : 2}>
            <b>Physician billing ID:</b>
            <br />
            {billingItem.physicianId ? billingItem.physicianId : '—'}
          </td>
          <td colSpan={shouldDisplayPatientData ? 3 : 2}>
            <b>Location ID:</b>
            <br />
            {billingItem.physicianLocationId ? billingItem.physicianLocationId : '—'}
          </td>
        </tr>
      ) }
    </Fragment>
  );
};

export default BillingHistoryItem;
