// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
// actions
import ShowNotification, { HideNotification } from '../../actions/notification';
import { UpdatePatientHistory } from '../../actions/patient';
// constants
import {
  NOTIFICATION_TYPE, USER_ROLES,
} from '../../constants/constants';
// services
import { getPatientHistory } from '../../services/patient';
// views
import MedicalEvents from './history/MedicalEvents';
import Encounters from './history/Encounters';
import { EnrollmentHistory } from './history/EnrollmentHistory';
import Immunizations from './history/Immunizations';
import Procedures from './history/Procedures';
import QaNotesHistory from './history/QaNotesHistory';
import SatisfactionSurveys from './history/survey/SatisfactionSurveys';
// Components
import { withRouter } from '../shared/WithRouter';

export class PatientHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
    this.loadPatientHistory = this.loadPatientHistory.bind(this);
  }

  componentDidMount() {
    this.loadPatientHistory();
  }

  loadPatientHistory() {
    const {
      hideNotification, showNotification, updatePatientHistory,
      navigate, params: { tenant: tenantUrl, id: patientId },
    } = this.props;

    hideNotification();

    const getPatientHistoryRequest = getPatientHistory(patientId);
    const getPatientHistoryPromise = getPatientHistoryRequest.promise;

    getPatientHistoryPromise.then((data) => {
      this.setState({
        loading: false,
      });
      updatePatientHistory(data);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load patient history',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });

      navigate(`/${tenantUrl}/cn/list`);
    });
  }

  render() {
    const { loading } = this.state;
    const {
      user, patient: { assignedCareNavigator }, params: { id: patientId },
    } = this.props;
    const isCnUser = user.role === USER_ROLES.CN;
    const isAdminUser = user.role === USER_ROLES.ADMIN;
    const isPesUser = user.role === USER_ROLES.PES;
    const isCnOwner = (assignedCareNavigator && assignedCareNavigator.id === user.id);

    return (
      <div className={`patient-history h-100 overflow-auto pr-3 ${loading ? ' d-none' : ''}`}>
        <h4 className="text-uppercase text-left my-3">History</h4>
        { (isAdminUser || (isCnUser && isCnOwner)) && <QaNotesHistory patientId={patientId} /> }
        <Encounters />
        <MedicalEvents patientId={patientId} />
        <Immunizations patientId={patientId} />
        <Procedures />
        <EnrollmentHistory patientId={patientId} />
        { (isAdminUser || ((isCnUser || isPesUser) && !user.isExternal)) && <SatisfactionSurveys />}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
    timezone: state.tenant && state.tenant.timezone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatientHistory: patientHistoryData => dispatch(UpdatePatientHistory(patientHistoryData)),
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientHistory));
