// Libraries
import React from 'react';
// Actions
// Views
import QaPager from './QaPager';

export const QaSortingPanel = (props) => {
  const {
    isSearchPanelOpened, totalPages, goToPage, currentPage,
  } = props;


  const getMaxShownCount = () => {
    if (!isSearchPanelOpened) return 5;
    return 3;
  };

  return (
    <div className="d-flex flex-grow-1 position-relative h-100">
      <QaPager
        isNextPrevShown
        totalPages={totalPages}
        callback={goToPage}
        currentPage={currentPage}
        maxShownCount={getMaxShownCount()}
        data-test="qaSortingPanel_pager"
      />
    </div>
  );
};

export default QaSortingPanel;
