function PatientAction(patient) {
  return {
    type: 'PATIENT',
    patient: patient || {},
  };
}

export function UpdatePatient(fields) {
  return {
    type: 'UPDATE_PATIENT',
    newData: fields,
  };
}

export function UpdatePatientHistory(data) {
  return {
    type: 'UPDATE_PATIENT_HISTORY',
    data,
  };
}

export function UpdatePatientCarePlan(data) {
  return {
    type: 'UPDATE_PATIENT_CARE_PLAN',
    data,
  };
}

export function UpdatePatientUtils(data) {
  return {
    type: 'UPDATE_PATIENT_UTILS',
    data,
  };
}

export function UpdatePatientDemographics(data) {
  return {
    type: 'UPDATE_PATIENT_DEMOGRAPHICS',
    data,
  };
}

export default PatientAction;
