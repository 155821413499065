import React from 'react';
import Modal from 'react-modal';
import ReactSelect from 'react-select';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Row } from 'react-bootstrap';
// Actions
import ShowNotification from '../../../actions/notification';
// Constants
import { DIALOG_STYLES } from '../../../constants/constants';
import { TIME_TRACKING_NOTES } from '../../../constants/templates';
// Components
import { TextArea } from '../../base/forms/TextArea';
import { Checkbox } from '../../base/forms/Checkbox';
import { TextInput } from '../../base/forms/TextInput';

export function StatusNoteModal(props) {
  const {
    isModalOpen, setIsModalOpen, newStatus,
  } = props;

  const INITITAL_STATUS = {
    newStatus,
    activityNote: '',
    duration: 0,
    directContactIsMade: false,
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (statusNote) => {
    const { submitCallback } = props;
    if (submitCallback) {
      submitCallback({
        askConfirm: false,
        status: newStatus,
        activityNote: statusNote.activityNote,
        activityDuration: statusNote.duration,
        activityDirectContact: statusNote.directContactIsMade,
      });
      handleCloseModal();
    }
  };

  const fieldStyles = {
    formGroup: 'mb-0',
    formLabel: 'd-none',
  };

  const durationStyles = {
    formControl: 'text-right border-bottom-0',
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Auditing Activities Modal"
      data-test="statusNoteModal_modal"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {"Are you sure you want to change Patient's status?"}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="statusNoteModal_modalCloseButton"
          />
        </div>
        <Formik
          initialValues={INITITAL_STATUS}
          validationSchema={Yup.object({
            activityNote: Yup.string(),
            duration: Yup.number()
              .required('Required')
              .min(0, 'Must be greater than 0 minutes')
              .max(60, 'Must be less than 60 minutes'),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
          data-test="statusNoteModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <Row className="align-items-center">
                  <div className="col text-left">
                    <Checkbox
                      label={formik.values && formik.values.directContactIsMade ? 'Direct' : 'Not direct'}
                      name="directContactIsMade"
                      type="switch"
                      styles={fieldStyles}
                      data-test="statusNoteModal_directContact"
                    />
                  </div>
                  <div className="col text-right">
                    <div className="d-flex align-items-center">
                      <span className="w-100">Time spent:</span>
                      <TextInput
                        type="number"
                        min="1"
                        name="duration"
                        styles={{ ...fieldStyles, ...durationStyles }}
                        data-test="statusNoteModal_durationField"
                      />
                      <span>min</span>
                    </div>
                  </div>
                </Row>
                <Row className="align-items-center mt-3">
                  <div className="col pt-1">
                    <TextArea
                      label="Add time tracking note"
                      name="activityNote"
                      rows={10}
                      data-test="statusNoteModal_addNote"
                    />
                    <div
                      className="position-absolute"
                      style={{ top: 0, right: 15, width: 200 }}
                    >
                      <ReactSelect
                        name="template"
                        className="border-bottom-0"
                        placeholder="Add a custom template"
                        searchable
                        clearable={false}
                        options={TIME_TRACKING_NOTES}
                        onChange={ev => formik.setFieldValue(
                          'activityNote', ev && `${formik.values && formik.values.activityNote.length ? `${formik.values.activityNote}\n\n` : ''}${ev.value}`,
                        )}
                        data-test="statusNoteModal_selectTemplate"
                      />
                    </div>
                    <Button
                      size="sm"
                      variant="link-dark"
                      className="position-absolute"
                      style={{ bottom: 20, right: 15 }}
                      onClick={() => formik.setFieldValue('activityNote', '')}
                      disabled={formik.values && !formik.values.activityNote.length}
                      data-test="statusNoteModal_trashBtn"
                    >
                      <i className="d-flex-center bi-trash" />
                    </Button>
                  </div>
                </Row>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="statusNoteModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="statusNoteModal_saveBtn"
                >
                  Ok
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(StatusNoteModal);
