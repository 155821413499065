// libraries
import React from 'react';
// constants
import { ROLE_NAMES } from '../../../../constants/constants';

export function UserRole(props) {
  const { role } = props;
  return (
    <div className="team-member-cell">
      {ROLE_NAMES[role.name]}
    </div>
  );
}

export default UserRole;
