import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// Components
import { withRouter } from '../shared/WithRouter';

import {
  USER_MENU_ITEMS, DEMO_ITEM, ROLE_FULL_NAMES, USER_ROLES, PATIENT_ATTRIBUTES_ITEM,
} from '../../constants/constants';

export class UserPageMenu extends Component {
  constructor(props) {
    super(props);

    this.renderMenuItems = this.renderMenuItems.bind(this);
  }

  renderMenuItems() {
    const {
      params: { tenant: tenantUrl }, location: { hash },
      user: { role: userRole, isExternal }, isDemoEnv = false,
    } = this.props;

    return USER_MENU_ITEMS.map((item) => {
      if ((item.url === DEMO_ITEM.url && isExternal)
        || (item.url === DEMO_ITEM.url && !isDemoEnv)
      ) {
        return null;
      }

      if (item.url === PATIENT_ATTRIBUTES_ITEM.url && isExternal) {
        return null;
      }

      if (item.roles && item.roles.length
          && !item.roles.some(role => role === userRole)) {
        return null;
      }

      const menuItem = [];

      menuItem.push(
        <NavLink
          key={`admin_menuItem-${item.url}`}
          to={item.url}
          className={({ isActive }) => `d-block text-left my-2${isActive ? ' active' : ''}`}
          data-test={`userPageMenu_${item.url}-link`}
        >
          {item.title}
        </NavLink>,
      );

      if (item.subsections && item.subsections.length) {
        item.subsections.forEach((subItem) => {
          const isSubItemActive = hash.match(`#${subItem.id}`);
          menuItem.push(
            <HashLink
              smooth
              key={`admin_menuItem-${item.url}-${subItem.id}`}
              to={{ pathname: `/${tenantUrl}/cn/${item.url}`, hash: `#${subItem.id}` }}
              className={`subLink d-block text-left${isSubItemActive ? ' active ' : ''}`}
              data-test={`userPageMenu_${subItem.id}Link`}
            >
              {subItem.title}
            </HashLink>,
          );
        });
      }

      return menuItem;
    });
  }

  render() {
    const {
      tenantName,
      user: {
        firstName = '', lastName = '', role = '',
      } = {},
    } = this.props;

    return (
      <div className="ccm-admin-menu-wrapper d-flex flex-column h-100">
        <div className="admin-general-info box-wrapper d-flex p-3">
          <span
            title={`${firstName.charAt(0)}${lastName.charAt(0)}`}
            className="avatar-icon bg-ccm-yellow text-white rounded-circle flex-shrink-0"
          >
            {`${firstName.charAt(0)}${lastName.charAt(0)}`}
          </span>
          <div className="d-flex justify-content-center flex-column text-left ml-3">
            <div className="admin__name">{`${firstName} ${lastName}`}</div>
            <div className="admin_tenant">{tenantName}</div>
            <div className="admin__role mt-1">
              {role === USER_ROLES.ADMIN ? 'Administrator' : ROLE_FULL_NAMES[role]}
            </div>
          </div>
        </div>
        <div className="admin-menu-items box-wrapper flex-grow-1 overflow-auto mt-2 py-2" data-test="userPageMenu_adminMenu">
          {this.renderMenuItems()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    tenantName: state.tenant && state.tenant.name,
    isDemoEnv: state.tenant && state.tenant.isDemoEnv,
  };
}

export default withRouter(connect(mapStateToProps)(UserPageMenu));
