// libraries
import React, { Component } from 'react';
// views
import BillableProviders from './billableProviders/BillableProviders';

export class Settings extends Component {
  pageTop = React.createRef();

  render() {
    return (
      <div ref={this.pageTop}>
        <h4 className="text-uppercase text-left mt-2 mb-4">Settings</h4>
        <BillableProviders pageTop={this.pageTop} />
      </div>
    );
  }
}

export default Settings;
