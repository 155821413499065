// libraries
import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// Images
import logo from '../../images/brand-logo.svg';

export function Logo({ loading }) {
  const { tenant: tenantUrl } = useParams();

  return (
    <Link
      to={`/${tenantUrl}/cn/list`}
      data-test="patientListLink"
    >
      <img
        src={logo}
        alt="SelectPatient Management logo"
        data-test="topBar_engoodenLogo"
        className={`logo ${loading ? 'd-none' : 'd-block'}`}
      />
    </Link>
  );
}

export function mapStateToProps(state) {
  return {
    loading: state.requestsInProgress.count > 0,
  };
}

export default connect(mapStateToProps)(Logo);
