// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { Link, useParams, useNavigate } from 'react-router-dom';
// Actions
import ShowNotification from '../../../actions/notification';
import SetUser from '../../../actions/user';
// Constants
import {
  NOTIFICATION_TYPE, SIGN_IN_ERROR_CODES, AUTH_PROVIDERS, USER_ROLES,
} from '../../../constants/constants';
import { ROLES_WITH_SNOOK_ACCESS, SUPER_USER_ROLES } from '../../../constants/globalAdminUi';
// Hooks
import useVersion from '../../../hooks/useVersion';
// Services
import { getGlobalUser } from '../../../services/globalServices/login';
import { saveUserInStorage, getUserFromStorage } from '../../../services/userStorage';
// Views
import Loading from '../../base/Loading';
// images
import logo from '../../../images/brand-logo.svg';

export function GlobalSignIn() {
  const { tenant: tenantUrl } = useParams();
  const { keycloak, initialized } = useKeycloak();
  const { tenant, requestsInProgress: { count: loading } } = useSelector(state => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showNotification = notificationData => dispatch(ShowNotification(notificationData));
  const setUserInRedux = userData => dispatch(SetUser(userData));

  const { isLatestVersion } = useVersion();
  const [validVersion, setValidVersion] = useState(true);

  const checkVersion = async () => {
    setValidVersion(await isLatestVersion());
  };

  const redirectUserToApp = (user) => {
    const { info: { roleName, tenants } } = user;

    if (tenants && tenants.length === 1 && !ROLES_WITH_SNOOK_ACCESS.includes(roleName)) {
      navigate(`/${tenantUrl}/snook/redirect`);
    } else {
      switch (roleName) {
        case SUPER_USER_ROLES.SUPER_ADMIN:
        case USER_ROLES.ADMIN:
          navigate(`/${tenantUrl}/snook/list`);
          break;
        default:
          navigate(`/${tenantUrl}/401`);
          break;
      }
    }
  };

  const showErrorMessage = (cognitoError) => {
    let errorMessage = '';
    let notificationType = NOTIFICATION_TYPE.ERROR;

    switch (cognitoError.code) {
      case SIGN_IN_ERROR_CODES.INVALID_CREDENTIALS:
      case SIGN_IN_ERROR_CODES.INVALID_TOTP:
        errorMessage = cognitoError.message;
        break;
      case SIGN_IN_ERROR_CODES.CANCELED_TOTP:
        notificationType = NOTIFICATION_TYPE.WARNING;
        errorMessage = cognitoError.message;
        break;
      case SIGN_IN_ERROR_CODES.NEW_PASSWORD:
        errorMessage = cognitoError.message;
        navigate(`/${tenantUrl}/new-pw`);
        break;
      case SIGN_IN_ERROR_CODES.TOTP_SETUP_REQUIRED:
        notificationType = NOTIFICATION_TYPE.WARNING;
        errorMessage = (
          <span className="d-flex-center">
            <i className="bi bi-shield-lock-fill h3 mr-2 mb-0" />
            {cognitoError.message}
          </span>);
        navigate(`/${tenantUrl}/setup-mfa`);
        break;
      default:
        if (cognitoError.message) {
          errorMessage = 'Incorrect username or password.';
          break;
        }
        if (cognitoError.data
          && cognitoError.data.message === SIGN_IN_ERROR_CODES.PASSWORD_EXPIRED) {
          errorMessage = 'You need to change the password, please provide a new one.';
          navigate(`/${tenantUrl}/change-pw`);
          break;
        }
        if (cognitoError.status && cognitoError.status === 401) {
          errorMessage = 'User is not authorized to perform this action.';
          break;
        }
        errorMessage = 'Server error occurred, please try again later.';
    }

    showNotification({
      message: errorMessage,
      autoHide: true,
      notificationType,
    });
  };

  const handleKeycloakSignIn = () => {
    let userData = {
      tenant,
      authData: {
        idToken: {
          jwtToken: keycloak.idToken,
          exp: keycloak.idTokenParsed.exp,
        },
        accessToken: {
          jwtToken: keycloak.token,
          exp: keycloak.tokenParsed.exp,
        },
        refreshToken: {
          jwtToken: keycloak.refreshToken,
        },
        loggedInWith: AUTH_PROVIDERS.KEYCLOAK,
      },
    };
    setUserInRedux(userData);

    const getUserRequest = getGlobalUser();
    const getUserPromise = getUserRequest.promise;

    return getUserPromise.then((userInfo) => {
      delete getUserRequest.promise;

      userData = {
        ...userData,
        info: userInfo,
      };
      setUserInRedux(userData);
      saveUserInStorage(userData, tenantUrl);
      redirectUserToApp(userData);
    }).catch((authError) => {
      if (authError.isCanceled) return;
      delete getUserRequest.promise;
      showErrorMessage(authError);
    });
  };

  useEffect(() => {
    const user = getUserFromStorage(tenantUrl);
    if (user && tenantUrl) redirectUserToApp(user);
    checkVersion();
  }, []);

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      handleKeycloakSignIn();
    }
  }, [initialized]);

  const renderSignIn = () => (
    <div className="col-3 d-flex-center">
      <div className="wrapper-login flex-grow-1 p-4">
        <Loading />
        <img
          src={logo}
          alt="SelectPatient Management logo"
          data-test="login_engoodenLogo"
          className={`logo ${loading ? 'd-none' : 'd-block'}`}
        />
        {validVersion ? (
          <Form data-test="signIn_loginFormikForm">
            <Form.Group controlId="formGroup-keycloak">
              {tenant && tenant.ssoEnabled && !keycloak.authenticated && (
                <Button
                  data-test="globalSignIn_ssoLoginButton"
                  size="sm"
                  variant="outline-ccm-lipstick"
                  className="mt-0 px-4 rounded-pill"
                  onClick={() => keycloak.login({ idpHint: tenant.ssoIdpHint })}
                >
                  Login with SSO
                </Button>)}
              {!!keycloak.authenticated && (
                <Button
                  size="sm"
                  variant="outline-ccm-lipstick"
                  className="mt-0 px-4 rounded-pill"
                  disabled={keycloak.authenticated}
                >
                  Logged In (
                  {keycloak.tokenParsed.name}
                  )
                </Button>)}
            </Form.Group>
            <Form.Group controlId="formGroupLinks">
              <div className="d-flex-center mt-5">
                <Link to={`/${tenantUrl}/reset`}>Reset password</Link>
              </div>
            </Form.Group>
          </Form>
        ) : (
          <div className="mt-5" data-test="signIn_outdatedVersion">
            You are using an outdated version of the application, please refresh the
            page in your browser and try again. If the issue continues, please contact &nbsp;
            <a href="mailto:support@cohort.ai">support@cohort.ai.</a>
            <Button
              variant="ccm-lipstick"
              onClick={() => window.location.reload()}
              className="d-block my-4 mx-auto px-5 rounded-pill"
            >
              Refresh page
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="ccm-form-signin row justify-content-center h-100">
      {renderSignIn()}
    </div>
  );
}

export default GlobalSignIn;
