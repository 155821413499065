// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
// Services
import { updatePatientTags } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import { NOTIFICATION_TYPE, DIALOG_STYLES } from '../../../constants/constants';


export function PatientAttributesModal(props) {
  const {
    patientId, showNotification, isModalOpen, setIsModalOpen,
    patient: { tagIds: patientTags } = {}, tags = [],
  } = props;

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [newTagsIds, setNewTagsIds] = useState(patientTags || []);

  useEffect(() => {
    const orderedNewTagsIds = _sortBy(newTagsIds);
    const orderedPatientTags = _sortBy(patientTags);
    if (_isEqual(orderedNewTagsIds, orderedPatientTags)) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [newTagsIds]);

  useEffect(() => {
    setNewTagsIds(patientTags || []);
  }, [patientTags]);

  const savePatientAttributes = () => {
    const { updatePatient } = props;


    const updatePatientTagsRequest = updatePatientTags(patientId, newTagsIds);
    const updatePatientTagsPromise = updatePatientTagsRequest.promise;

    return updatePatientTagsPromise.then(() => {
      delete updatePatientTagsRequest.promise;
      updatePatient({ tagIds: newTagsIds });
    }).catch((error) => {
      delete updatePatientTagsRequest.promise;

      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to update patient attributes.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleCloseModal = () => {
    setNewTagsIds(patientTags);
    setIsModalOpen(false);
  };

  const handleUpdatePatientAttributes = () => {
    savePatientAttributes();
    handleCloseModal();
  };

  const handleCheckAttributes = (event) => {
    const { value } = event.target;
    const updatedTagId = Number(value);
    let tagsIdsUpdated = newTagsIds;
    if (tagsIdsUpdated.includes(updatedTagId)) {
      tagsIdsUpdated = tagsIdsUpdated.filter(t => t !== updatedTagId);
    } else {
      tagsIdsUpdated = [...tagsIdsUpdated, updatedTagId];
    }
    setNewTagsIds(tagsIdsUpdated);
  };

  const renderTags = () => tags.filter(tag => !tag.deleted).map(tag => (
    <Form.Group key={`service__${tag.id}_${tag.displayName}`} controlId={`formCheck_${tag.id}`} className="my-2 col-4 text-left" data-test="patientAttributesModal_individualAttribute">
      <Form.Check
        type="checkbox"
        className="lipstick-checkbox"
        name={tag.id}
        value={tag.id}
        label={tag.displayName}
        defaultChecked={newTagsIds.includes(tag.id) || false}
        onClick={handleCheckAttributes}
        data-test={`patientAttributesModal_checkbox${tag.id}`}
      />
      {tag.description && <Form.Text muted className="ml-3 pl-1 mt-0">{tag.description}</Form.Text>}
    </Form.Group>
  ));

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Patient Attributes Modal"
      data-test="patientAttributesModal_modal"
    >
      <div className="simple-dialog big-dialog">
        <div className="dialog-title">
          Edit Patient Attributes
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="patientAttributesModal_modalCloseButton"
          />
        </div>
        <div className="dialog-content row">
          {renderTags()}
        </div>
        <div className="d-flex justify-content-end my-4 mx-3">
          <Button variant="light" onClick={() => handleCloseModal()} data-test="patientAttributesModal_cancelBtn">Cancel</Button>
          <Button
            variant="primary"
            className="ml-2"
            disabled={saveDisabled}
            onClick={() => handleUpdatePatientAttributes()}
            data-test="patientAttributesModal_saveBtn"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
    tags: state.tenant.tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAttributesModal);
