// Libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import { TextInput } from '../../base/forms/TextInput';
// Constants
import { DIALOG_STYLES } from '../../../constants/constants';

const DEFAULT_PREFERRED_NAME = { preferredName: '' };
const PREFERRED_NAME_SCHEMA = () => Yup.object({
  preferredName: Yup.string()
    .max(100, 'The limit is 100 characters'),
});

export default function PreferredNameModal(props) {
  const {
    preferredName, isModalOpen,
    setIsModalOpen, updateProfileData,
  } = props;

  const [initialValue, setInitialValue] = useState({ preferredName } || DEFAULT_PREFERRED_NAME);

  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    if (preferredName) setInitialValue({ preferredName });
    else setInitialValue(DEFAULT_PREFERRED_NAME);
  }, [preferredName]);

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Edit Preferred Name"
      data-test="preferredNameModal_onRequestClose"
    >
      <div className="simple-dialog big-dialog patient-health-factors">
        <div className="dialog-title">
          Edit Preferred Name
          <button
            data-test="preferredNameModal_closeIconButton"
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
          />
        </div>
        <Formik
          validationSchema={PREFERRED_NAME_SCHEMA()}
          initialValues={initialValue}
          onSubmit={(values) => {
            updateProfileData('preferredName', values.preferredName);
            handleCloseModal();
          }}
          data-test="preferredNameModal_formikComponent"
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="p-4">
                <div className="mt-4">
                  <TextInput
                    autoComplete="off"
                    name="preferredName"
                    label="Preferred Name"
                    data-test="preferredNameModal_inputText"
                    styles={{ formLabel: 'float-left' }}
                  />
                </div>
              </div>
              <div className="mb-4 mr-5 float-right">
                <Button
                  variant="light"
                  onClick={() => handleCloseModal()}
                  data-test="preferredNameModal_cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  variant="primary"
                  disabled={!formik.isValid || formik.values.preferredName.trim().length <= 0}
                  onClick={() => formik.handleSubmit()}
                  data-test="preferredNameModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
