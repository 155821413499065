// libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Services
import { getAdministrationFeatures, updateAdminFeatureByName } from '../../../services/administration';
// Actions
import ShowNotification from '../../../actions/notification';
import { SetTenantFeatures } from '../../../actions/tenants';
// Constants
import { NOTIFICATION_TYPE, EMPTY_STRING } from '../../../constants/constants';
// Views
import Checkbox from '../../base/Checkbox';

const selectFeatures = state => state.tenant.features;

const Features = () => {
  const dispatch = useDispatch();
  const allFeatures = useSelector(selectFeatures);

  const fetchAdministrationFeatures = () => {
    const getAdministrationFeaturesRequest = getAdministrationFeatures();
    const getAdministrationFeaturesPromise = getAdministrationFeaturesRequest.promise;

    return getAdministrationFeaturesPromise.then((data) => {
      delete getAdministrationFeaturesRequest.promise;
      dispatch(SetTenantFeatures(data));
    }).catch((error) => {
      delete getAdministrationFeaturesRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      dispatch(ShowNotification({
        message: 'An error has occurred while attempting to load administration features.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const updateAdministrationFeatures = (featureName) => {
    const updateAdminFeatureByNameRequest = updateAdminFeatureByName(featureName);
    const updateAdminFeatureByNamePromise = updateAdminFeatureByNameRequest.promise;

    return updateAdminFeatureByNamePromise.then((data) => {
      delete updateAdminFeatureByNameRequest.promise;
      const allFeaturesUpdated = allFeatures.map((f) => {
        if (f.id === data.id) {
          return { ...f, enabled: data.enabled };
        }
        return f;
      });
      dispatch(SetTenantFeatures(allFeaturesUpdated));
      dispatch(ShowNotification({
        message: 'Feature updated successfully',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      }));
    }).catch((error) => {
      delete updateAdminFeatureByNameRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      dispatch(ShowNotification({
        message: 'An error has occurred while attempting to update the feature.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const renderFeaturesRow = allFeatures && allFeatures.length && allFeatures.map(feature => (
    <div className="d-flex flex-column text-left px-2 mb-3" key={`feature_${feature.id}`}>
      <div className="d-flex align-items-center mb-2">
        <p className="w-25 m-0 font-weight-bold" data-test={`features_${feature.featureName}`}>{feature.featureDisplayName}</p>
        <div>
          <Checkbox
            fieldKey={`checkbox_${feature.featureName}`}
            initialValue={feature.enabled}
            submitCallback={() => updateAdministrationFeatures(feature.featureName)}
            data-test={`features_checkbox_${feature.featureName}`}
          />
        </div>
      </div>
      <div className="d-flex">
        <p className="mr-2">Description:</p>
        <div className="flex-grow-1">
          <i>{feature.featureDescription || EMPTY_STRING}</i>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    fetchAdministrationFeatures();
  }, []);

  return (
    <div
      className="admin-section my-4"
      id="features"
    >
      <h5 className="text-uppercase my-3">Features</h5>
      <div className="d-flex flex-column administration-features px-4">
        {renderFeaturesRow}
      </div>
    </div>
  );
};

export default Features;
