// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getRemindersAvailability } from '../../services/patient';

const usePatientsAvailability = () => {
  const [patientsAvailability, setPatientsAvailability] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPatientsAvailability = useCallback(async () => {
    const getRemindersAvailabilityRequest = getRemindersAvailability();
    const getRemindersAvailabilityPromise = getRemindersAvailabilityRequest.promise;
    try {
      const requestedData = await getRemindersAvailabilityPromise;
      setPatientsAvailability(requestedData.patients);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patients availability.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, []);

  useEffect(() => {
    fetchPatientsAvailability();
  }, [fetchPatientsAvailability]);

  return { patientsAvailability, refetch: fetchPatientsAvailability };
};

export default usePatientsAvailability;
