import { createAction, createReducer } from '@reduxjs/toolkit';

const showNotification = createAction('SHOW_NOTIFICATION');
const hideNotification = createAction('HIDE_NOTIFICATION');

const initialState = {
  message: '',
  shown: false,
  autoHide: true,
  notificationType: '',
};

const Notification = createReducer(initialState, (builder) => {
  builder
    .addCase(showNotification, (state, action) => {
      state.message = action.message;
      state.shown = true;
      state.autoHide = action.autoHide;
      state.notificationType = action.notificationType;
    })
    .addCase(hideNotification, () => ({ ...initialState }))
    .addDefaultCase(state => state);
});

export default Notification;
