// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
// constants
import {
  USER_ROLES, BILLING_STATUSES, ENROLLED_STATUSES,
} from '../../../constants/constants';
// Services
import { getQaNoteInfo } from '../../../services/helpers';
// views
import ProgressBar from '../../base/CompoundProgressBar';

/**
 * TotalCCM component, requires props:
 * billingStatus -- string, name of billing status
 * daysLeft -- number, days left in current billing period
 * value -- number, minutes
 * maxCcmMtd -- number, max time for one progress bar
 * lastQaNote -- {text: '', status: 'oneOfType QA_STATUSES'}
 * assignedCareNavigator -- user object
 */
class TotalCCM extends Component {
  constructor(props) {
    super(props);

    this.renderBillingInfo = this.renderBillingInfo.bind(this);
  }

  renderBillingInfo() {
    const {
      billingStatus: billingStatusName,
      billingCanNotBeSubmittedReason, billingIssues = [],
    } = this.props;
    let billingInfo;
    let billingInfoText = '';

    billingIssues.forEach((issue) => {
      if (ENROLLED_STATUSES[issue]) {
        billingInfoText += `${ENROLLED_STATUSES[issue].name} \n`;
      }
    });

    if (billingInfoText) {
      billingInfo = (
        <span className="ccm-info-label mb-2">
          {billingInfoText}
        </span>
      );
    } else if (billingStatusName && BILLING_STATUSES[billingStatusName]) {
      const billingStatus = BILLING_STATUSES[billingStatusName];

      let tooltip = billingStatus.defaultTooltip || '';

      const statusTooltips = billingStatus.tooltips || {};
      if (billingCanNotBeSubmittedReason) {
        const customTooltip = statusTooltips[billingCanNotBeSubmittedReason];
        tooltip = customTooltip || tooltip;
      }

      billingInfo = (
        <span
          className="ccm-info-label mb-1"
          data-tip={tooltip}
          data-for="tooltip-billingStatus"
        >
          {billingStatus.name}
        </span>
      );
    }

    return billingInfo;
  }

  render() {
    const {
      user, maxCcmMtd, value, isPending, lastQaNote, assignedCareNavigator,
    } = this.props;

    const isCnUser = user.role === USER_ROLES.CN;
    const isAdminUser = user.role === USER_ROLES.ADMIN;
    const isCnOwner = (assignedCareNavigator && assignedCareNavigator.id === user.id);

    return (
      <div className="d-flex-center">
        <div className="flex-grow-1">
          {this.renderBillingInfo()}
          <div className="d-flex-center">
            <i className="bi-clock text-primary mr-2" />
            <span className="ccm-info-time text-uppercase">
              {`${value || 0} min`}
            </span>
          </div>
        </div>
        <div className="ml-3">
          {lastQaNote && (isAdminUser || (isCnUser && isCnOwner)) && (
            <div className={`qa-note-info qa-${lastQaNote && lastQaNote.status.toLowerCase()} d-flex-center mb-1`}>
              {getQaNoteInfo(lastQaNote.status)}
            </div>)}
          <ProgressBar
            duration={value}
            timePeriod={maxCcmMtd}
            isPending={isPending}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, null)(TotalCCM);
