// Libraries
import React from 'react';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
// Constants
import { DIALOG_STYLES } from '../../constants/constants';


const PromptModal = (props) => {
  const {
    isOpen = false, promptMsg = '', promptTitle = '',
    proceedAction, cancelAction,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      style={{ ...DIALOG_STYLES, overlay: { ...DIALOG_STYLES.overlay, zIndex: 115 } }}
      onRequestClose={cancelAction}
      contentLabel="Prompt Modal"
      data-test="customPrompt_modal"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {promptTitle}
          <button
            type="button"
            className="close-icon i-close"
            onClick={cancelAction}
          />
        </div>
        <div className="dialog-content text-left">
          {promptMsg}
        </div>
        <div className="dialog-buttons justify-content-end px-4">
          <Button variant="light" onClick={cancelAction} data-test="customPrompt_cancelBtn">Cancel</Button>
          <Button
            variant="primary"
            className="ml-2"
            onClick={proceedAction}
            data-test="customPrompt_proceedBtn"
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default PromptModal;
