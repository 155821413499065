// Libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
// Services
import { saveGeneralInfo } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT, DIALOG_DEFAULTS,
} from '../../../constants/constants';
// Components
import { Datepicker } from '../../base/forms/Datepicker';
import { TextInput } from '../../base/forms/TextInput';
import { INSURANCE_SCHEMA } from '../../../constants/forms/schemas/modalsSchemas';

export function FutureInsuranceViewModal(props) {
  const { id: patientId } = useParams();
  const {
    isEditModalOpen, setIsEditModalOpen, isClearModalOpen, setIsClearModalOpen, initialInsurance,
  } = props;

  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const FUTURE_TYPE = 'FUTURE';

  const DEFAULT_INSURANCE = {
    patientId: patientId || null,
    type: FUTURE_TYPE,
    companyName: '',
    planId: '',
    effectiveDate: null,
  };

  const processInsuranceUpdate = (insurance) => {
    if (insurance) {
      const effectiveDate = insurance.effectiveDate ? new Date(insurance.effectiveDate) : null;
      return ({
        ...insurance,
        effectiveDate: effectiveDate
          ? moment(effectiveDate).utc().format(DATE_FORMAT.SHORT) : null,
      });
    }
    return null;
  };

  const getInitialInsurance = () => {
    if (initialInsurance) {
      return processInsuranceUpdate(initialInsurance);
    }
    return DEFAULT_INSURANCE;
  };

  const [insurance, setInsurance] = useState(getInitialInsurance());

  useEffect(() => {
    if (initialInsurance) {
      const newData = processInsuranceUpdate({
        patientId: initialInsurance.patientId || patientId,
        type: initialInsurance.type || FUTURE_TYPE,
        companyName: initialInsurance.companyName || '',
        planId: initialInsurance.planId || '',
        effectiveDate: initialInsurance.effectiveDate || '',
      });
      setInsurance(newData);
    } else {
      setInsurance(DEFAULT_INSURANCE);
    }
  }, [initialInsurance]);

  useEffect(() => {
    if (patientId) setInsurance({ ...insurance, patientId });
  }, [patientId]);

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    setIsClearModalOpen(false);
  };

  const saveFutureInsurance = (futureInsurance) => {
    const { updatePatient } = props;

    const saveInsurancePromise = saveGeneralInfo({ futureInsurance }, patientId).promise;

    saveInsurancePromise
      .then((data) => {
        delete saveInsurancePromise.promise;

        updatePatient({ futureInsurance: processInsuranceUpdate(data.futureInsurance) });

        handleCloseModal();
      })
      .catch((error) => {
        delete saveInsurancePromise.promise;

        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }

        showNotification({
          message: 'An error occurred while attempting to save the Future Insurance',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
  };

  return (
    <div>
      <Modal
        isOpen={isEditModalOpen}
        style={DIALOG_STYLES}
        onRequestClose={() => handleCloseModal()}
        contentLabel="Future Insurance Modal"
        data-test="futureInsuranceModal"
      >
        <div className="simple-dialog small-dialog">
          <div className="dialog-title">
            {`${initialInsurance ? 'Edit' : 'Add'} Future Insurance`}
            <button
              type="button"
              className="close-icon i-close"
              onClick={() => handleCloseModal()}
              data-test="futureInsuranceModal_closeBtn"
            />
          </div>
          <Formik
            initialValues={insurance}
            validationSchema={INSURANCE_SCHEMA}
            onSubmit={(values, { resetForm }) => {
              const formattedDate = values.effectiveDate
                ? moment(new Date(values.effectiveDate)).format(DATE_FORMAT.FULL_SERVER) : null;
              const updatedValues = { ...values, effectiveDate: formattedDate };

              saveFutureInsurance(updatedValues);
              resetForm();
            }}
            data-test="futureInsuranceModal_formikComponent"
          >
            {formik => (
              <Form>
                <div className="dialog-content text-left" data-test="futureInsuranceModal_carrierTypeInput">
                  <TextInput
                    autoComplete="off"
                    name="companyName"
                    label="Carrier Type"
                    data-test="carrierTypeModal_inputText"
                    styles={{ formLabel: 'float-left' }}
                  />
                </div>
                <div className="dialog-content text-left" data-test="futureInsuranceModal_memberIdInput">
                  <TextInput
                    autoComplete="off"
                    name="planId"
                    label="Member ID"
                    data-test="memberIdModal_inputText"
                    styles={{ formLabel: 'float-left' }}
                  />
                </div>
                <div className="dialog-content text-left" data-test="futureInsuranceModal_dateInput">
                  <Datepicker
                    label="Effective Date"
                    name="effectiveDate"
                    dateFormat="MM/dd/yy"
                    autoComplete="off"
                    data-test="effectiveDateModal_datePicker"
                  />
                </div>
                <div className="dialog-buttons justify-content-end px-4">
                  <Button
                    variant="light"
                    onClick={() => handleCloseModal()}
                    data-test="futureInsuranceModal_cancelBtn"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    className="ml-2"
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid}
                    data-test="futureInsuranceModal_saveBtn"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>

      <Modal
        isOpen={isClearModalOpen}
        style={DIALOG_DEFAULTS}
        onRequestClose={() => setIsClearModalOpen(false)}
        contentLabel="Clear Future Insurance"
        data-test="clearFutureInsuranceModal"
      >
        <div className="simple-dialog medium-dialog">
          <div className="dialog-title">
            Clear Future Insurance
            <button
              type="button"
              className="close-icon i-close"
              onClick={() => setIsClearModalOpen(false)}
              data-test="clearFutureInsuranceModal_closeBtn"
            />
          </div>
          <div className="dialog-content">
            <p className="text-left mt-3">
              Are you sure you want to remove the future insurance?
            </p>
          </div>
          <div className="dialog-buttons justify-content-end px-4">
            <Button
              variant="light"
              onClick={() => setIsClearModalOpen(false)}
              data-test="clearFutureInsuranceModal_cancelBtn"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ml-2"
              onClick={() => saveFutureInsurance(null)}
              data-test="clearFutureInsuranceModal_okBtn"
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FutureInsuranceViewModal);
