// Libraries
import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
// Services
import { updatePatientsBulkStatus } from '../../../services/patientList';
// Actions
import ShowNotification from '../../../actions/notification';
// Constants
import {
  DIALOG_STYLES, NOTIFICATION_TYPE,
  BULK_CHANGE_STATUSES_WITH_EXTRA_BLOCK as EXTRA_BLOCK_STATUSES,
  INELIGIBLE_REASONS,
  DECLINE_BY_CARE_PROVIDER_REASONS,
  DECLINE_BY_PATIENT_REASONS,
  DISENROLLMENT_REASONS,
  E_STATUS_REASONS,
} from '../../../constants/constants';
import { ALL_BULK_STATUSES, STATUSES, SUSPEND_STATUSES } from '../../../constants/statuses';
// Components
import { Select } from '../../base/forms/Select';
import { TextArea } from '../../base/forms/TextArea';


export const patientStatusSchema = () => Yup.object({
  newStatus: Yup.string().required('Required'),
  reason: Yup.string().typeError('Please select a value from the list')
    .when('newStatus', (value, schema) => (EXTRA_BLOCK_STATUSES.includes(value) ? schema.required('Required') : schema.nullable(true))),
  bulkReasonStatusChange: Yup.string()
    .max(128)
    .required('Required'),
});

/**
 * ChangeStatusModal component, requires props:
 * selectedPatients -- set of patients
 * selectedPatientsIds -- set of patients' ids
 * reloadData -- callback after changing status
 */

export const BulkChangePatientStatusModal = (props) => {
  const {
    isModalOpen, setIsModalOpen, selectedPatientsIds, reloadData, textAreaLabel,
  } = props;

  const {
    requestsInProgress: { count: loading },
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const DEFAULT_VALUES = {
    newStatus: '',
    bulkReasonStatusChange: '',
  };

  const finalLabel = textAreaLabel || 'Reason for bulk status change';

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveNewStatus = (values) => {
    if (loading || !selectedPatientsIds || !selectedPatientsIds.size) {
      return;
    }
    let bulkReason = values.bulkReasonStatusChange;
    const title = values.newStatus.charAt(0) === 'X' ? `Disenrollment: ${values.reason}` : '';
    const note = EXTRA_BLOCK_STATUSES.includes(values.newStatus) ? values.bulkReasonStatusChange : '';
    let newStatusName = '';

    bulkReason = title || bulkReason;
    const finalReason = values.reason || bulkReason;

    if (STATUSES[values.newStatus.charAt(0)]) {
      const subStatuses = STATUSES[values.newStatus.charAt(0)].subStatuses || {};
      newStatusName = subStatuses[values.newStatus]
        ? subStatuses[values.newStatus].name : STATUSES[values.newStatus.charAt(0)].name;
    }

    const newStatusString = newStatusName;
    const confirmPrefix = `Set status to '${newStatusString}' for`;
    let confirmMessage = `${confirmPrefix} selected patient?`;

    if (selectedPatientsIds && selectedPatientsIds.size > 1) {
      confirmMessage = `${confirmPrefix} ${selectedPatientsIds.size} selected patients?`;
    }

    if (!window.confirm(confirmMessage)) return;

    const ids = Array.from(selectedPatientsIds);

    const bulkStatusUpdateRequest = updatePatientsBulkStatus(
      ids, values.newStatus, finalReason, note,
    );
    const bulkUpdatePromise = bulkStatusUpdateRequest.promise;

    bulkUpdatePromise.then(() => {
      delete bulkStatusUpdateRequest.promise;
      showNotification({
        message: `${ids.length > 1 ? 'Bulk' : 'Patient'} change status was updated successfully.`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      if (reloadData) reloadData();
      handleCloseModal();
    }).catch((error) => {
      delete bulkStatusUpdateRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to update bulk change status.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const prepareStatusList = () => [
    ...ALL_BULK_STATUSES.map((status) => {
      let label;
      let color;
      if (STATUSES[status.charAt(0)]) {
        const subStatuses = STATUSES[status.charAt(0)].subStatuses || {};
        label = subStatuses[status]
          ? subStatuses[status].name : STATUSES[status.charAt(0)].name;
        color = STATUSES[status.charAt(0)] && STATUSES[status.charAt(0)].color;
      }
      return {
        status,
        value: status,
        label: (
          <div>
            <span className={`status-icon ${color}`}>{status}</span>
            {label}
          </div>
        ),
        color,
      };
    }),
  ];

  const prepareReasonList = (newStatus) => {
    let reasons;

    if (EXTRA_BLOCK_STATUSES.includes(newStatus)) {
      switch (newStatus) {
        case 'PS':
        case 'CS':
          return Object.keys(SUSPEND_STATUSES.suspendReasons)
            .map(key => ({
              value: SUSPEND_STATUSES.suspendReasons[key],
              label: SUSPEND_STATUSES.suspendReasons[key],
            }))
            .sort((a, b) => ((a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : -1));
        case 'I':
          return INELIGIBLE_REASONS;
        case 'DC':
          reasons = DECLINE_BY_CARE_PROVIDER_REASONS;
          break;
        case 'DP':
          reasons = DECLINE_BY_PATIENT_REASONS;
          break;
        case 'X':
          reasons = DISENROLLMENT_REASONS.map(reason => reason.title);
          break;
        case 'E':
          reasons = E_STATUS_REASONS;
          break;
        default:
          reasons = [];
      }
    }

    if (!reasons || reasons.length === 0) {
      return [];
    }
    return [
      ...reasons.map(reason => ({
        reason,
        value: reason,
        label: reason,
      })),
    ];
  };
  const statusForOptions = prepareStatusList();

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Change Status"
      data-test="bulkChangePatientStatusModal_onRequestClose"
    >
      <div className="simple-dialog">
        <div className="dialog-title">
          Change Status
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="bulkChangePatientStatusModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={DEFAULT_VALUES}
          validationSchema={patientStatusSchema()}
          onSubmit={(values) => {
            const updatedValues = { ...values };
            saveNewStatus(updatedValues);
          }}
          data-test="bulkChangePatientStatusModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <div className="col" data-test="bulkChangePatientStatusModal_selectStatus">
                  <Select
                    label="Next Status"
                    name="newStatus"
                    placeholder="Select status"
                    options={statusForOptions}
                    data-test="bulkChangePatientStatusModal_newStatus"
                  />
                  {formik.values && EXTRA_BLOCK_STATUSES.includes(formik.values.newStatus) && (
                    <Select
                      label="Reason"
                      name="reason"
                      placeholder="Select reason"
                      options={prepareReasonList(formik.values.newStatus)}
                      data-test="bulkChangePatientStatusModal_reason"
                    />
                  )}
                </div>
                <div className="col" data-test="bulkChangePatientStatusModal_dateInput">
                  <TextArea
                    label={finalLabel}
                    name="bulkReasonStatusChange"
                    data-test="attributesSettingModal_attributeDescription"
                    rows={5}
                    maxLength={128}
                  />
                </div>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="bulkChangePatientStatusModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="bulkChangePatientStatusModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default BulkChangePatientStatusModal;
