// Libraries
import React, { useState } from 'react';
import { connect } from 'react-redux';
// Views
import {
  Form, Button,
} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import PhysicianAsyncList from './PhysicianAsyncList';
import DiagnosisElement from './DiagnosisElement';
import DiagnosisModal from './DiagnosisModal';
// Constants
import { USER_ROLES, MAX_DIAGNOSIS } from '../../../../constants/constants';
// Actions
import { UpdatePatient } from '../../../../actions/patient';

export const BillingDiagnoses = (props) => {
  const {
    user: { role: userRole } = {},
    patientId, editMode, patient: {
      billing, billing: { billingInfo = {}, permissions = {} } = {},
    } = {},
    updatePatient,
  } = props;

  const isAdminUser = userRole && userRole === USER_ROLES.ADMIN;
  const canAddDiagnosis = billingInfo.problems && billingInfo.problems.length < MAX_DIAGNOSIS;
  const [isDiagnosisModalOpen, setIsDiagnosisModalOpen] = useState(false);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
  const { canComplexCcmBeEnabled } = permissions;

  const handleAddEditDiagnosis = (diagnosis, isEdit) => {
    const newDiagnosis = { ...diagnosis, isEdit };
    setSelectedDiagnosis(newDiagnosis);
    setIsDiagnosisModalOpen(true);
  };

  const handleDeleteDiagnosis = (diagnosis) => {
    const updatedProblems = billingInfo.problems.filter((problem) => {
      if (problem.id && diagnosis.id) {
        return !(problem.id === diagnosis.id);
      }

      if (problem.order) {
        return !(problem.order === diagnosis.order);
      }

      return true;
    });

    const updatedBillingFormProblems = updatedProblems.map((problem, index) => ({
      ...problem,
      order: index + 1,
    }));
    updatePatient({
      billing: {
        ...billing, billingInfo: { ...billingInfo, problems: [...updatedBillingFormProblems] },
      },
    });
  };

  const handleChangeComplexCCM = (event) => {
    const { target: { checked } = {} } = event || {};
    const updatedBillingInfo = {
      ...billingInfo,
      complexCcmStatus: {
        ...billingInfo.complexCcmStatus,
        enabled: checked,
      },
    };

    updatePatient({
      billing: {
        ...billing, billingInfo: updatedBillingInfo,
      },
    });
  };

  const renderEmptyRow = () => (
    <tr>
      <td colSpan="4" className="p-2 border-0">
        This patient has no CCM BILLING DIAGNOSES.
      </td>
    </tr>
  );

  const renderDiagnosesRows = (billingData) => {
    if (billingData && billingData.problems && billingData.problems.length) {
      const sortedData = [...billingData.problems]
        .sort((pA, pB) => parseFloat(pA.order) - parseFloat(pB.order));
      return sortedData.map((problem, index) => (
        <DiagnosisElement
          key={`DiagnoseProblem__${problem.name}__${index}`}
          problem={problem}
          handleEdit={handleAddEditDiagnosis}
          handleDelete={handleDeleteDiagnosis}
          editMode={editMode}
          data-test={`billingDiagnoses_deleteButton_${index}`}
        />
      ));
    }
    return renderEmptyRow();
  };

  const renderComplexCCMAddButton = () => {
    const { complexCcmStatus: { enabled = false } = {} } = billingInfo;
    return (
      <div style={{ right: '0.5rem' }} className="position-absolute">
        {canComplexCcmBeEnabled && (
          <Form.Check
            inline
            id="isBillingComplexCCM"
          >
            <Form.Check.Label className="mr-2">Complex CCM:</Form.Check.Label>
            <Form.Check.Input
              data-test="billingDiagnoses_complexCCMCheckbox"
              type="checkbox"
              name="isComplexCCM"
              isValid
              disabled={!editMode}
              checked={enabled}
              onChange={handleChangeComplexCCM}
            />
          </Form.Check>
        )}
        {isAdminUser && canAddDiagnosis && editMode && (
          <Button
            size="sm"
            variant="link"
            className="text-dark"
            onClick={() => handleAddEditDiagnosis(null)}
            data-for="tooltip-billingDiagnosis"
            data-tip="Add Diagnosis"
            data-test="billingDiagnoses_addDiagnosisButton"
          >
            <i className="d-flex-center bi-plus-lg" data-test="billingDiagnoses_addButton" />
          </Button>
        )
        }
      </div>
    );
  };

  return (
    <div className="card border-0">
      <div className="card-header d-flex-center position-relative bg-ccm-light-gray border text-ccm-bismark rounded-0 py-0 px-2">
        <div style={{ left: '0.5rem' }} className="d-flex-center position-absolute text-left" data-test="billingDiagnoses_physician">
          <small className="text-nowrap mr-1">Billing Physician: </small>
          {
            editMode ? (
              <PhysicianAsyncList
                patientId={patientId}
              />
            ) : (
              <small className="text-truncate">{billingInfo && billingInfo.physicianDisplayName ? billingInfo.physicianDisplayName : ''}</small>
            )
          }
        </div>
        <span className="text-uppercase px-2 bg-ccm-light-gray" data-test="billingDiagnoses_billingDiagnosisHeader" style={{ zIndex: 1 }}>CCM Billing Diagnoses</span>
        {renderComplexCCMAddButton()}
      </div>
      <div className="card-body ccm-table-container py-2 px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th />
              <th className="py-2">Source</th>
              <th className="py-2">Problem</th>
              <th className="py-2">CCM</th>
              <th className="py-2">Code</th>
              <th className="py-2">Onset</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {renderDiagnosesRows(billingInfo)}
          </tbody>
        </table>
        <ReactTooltip id="tooltip-billingDiagnosis" type="info" effect="float" place="top" />
        <DiagnosisModal
          patientId={patientId}
          isModalOpen={isDiagnosisModalOpen}
          setIsModalOpen={setIsDiagnosisModalOpen}
          initialDiagnosis={selectedDiagnosis}
        />
      </div>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    patient: state.patient,
    physiciansList: (state.physicians && state.physicians.physiciansList
      ? state.physicians.physiciansList : []),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDiagnoses);
