// Libraries
import React from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// Actions
import { UpdatePatient } from '../../../actions/patient';
import ShowNotification from '../../../actions/notification';
// Services
import { changePatientStatus } from '../../../services/patient';
// Constants
import { NOTIFICATION_TYPE } from '../../../constants/constants';


export const Reinstate = () => {
  const { id: patientId } = useParams();

  const dispatch = useDispatch();
  const updatePatient = data => dispatch(UpdatePatient(data));
  const showNotification = data => dispatch(ShowNotification(data));

  const changePatientStatusButtons = () => {
    if (!window.confirm('Are you sure you want to change Patient\'s status?')) {
      return;
    }

    const data = { newStatus: 'CN' };
    const changePatientStatusRequest = changePatientStatus(patientId, data);
    const changePatientStatusPromise = changePatientStatusRequest.promise;

    changePatientStatusPromise.then((patientInfo) => {
      delete changePatientStatusRequest.promise;

      updatePatient({
        status: patientInfo.status,
        permissions: patientInfo.permissions,
        billing: patientInfo.billing,
        assignedCareNavigator: patientInfo.assignedCareNavigator,
        callAttempts: patientInfo.callAttempts,
        recentDirectContact: patientInfo.recentDirectContact,
        recentCallAttempt: patientInfo.recentCallAttempt,
      });
      showNotification({
        message: 'Patient\'s status was successfully changed.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete changePatientStatusRequest.promise;
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Error occurred during request, please try again later.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Button variant="success" data-test="reinstate_buttonToReinstate" onClick={() => changePatientStatusButtons()}>Reinstate</Button>
  );
};

export default Reinstate;
