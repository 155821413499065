// Libraries
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// Actions
import { SetTenantTags } from '../../actions/tenants';
import ShowNotification from '../../actions/notification';
// Services
import { getTenantTags } from '../../services/tenants';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Views
import PatientAttributesList from './administration/patientAttributes/PatientAttributesList';


export const PatientAttributesSettings = (props) => {
  const fetchTenantTags = () => {
    const { setTenantTags, showNotification } = props;
    const getTenantTagsRequest = getTenantTags();
    const getTenantTagsPromise = getTenantTagsRequest.promise;

    return getTenantTagsPromise.then((data) => {
      delete getTenantTagsRequest.promise;

      setTenantTags(data);
    }).catch((error) => {
      delete getTenantTagsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error has occurred while attempting to load tenants tags.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    const { tags } = props;

    if (tags && !tags.length) fetchTenantTags();
  }, []);

  return (
    <div className="patient-attributes h-100 overflow-auto pr-3">
      <h4 className="text-uppercase text-left my-3" data-test="patientOutreach_title">Patient Attributes</h4>
      <PatientAttributesList />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    tags: state.tenant.tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTenantTags: tags => dispatch(SetTenantTags(tags)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAttributesSettings);
