// Libraries
import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
// Services
import { isWeekendOrHoliday } from '../../../services/helpers';
// Custom Hooks
import useLoadHolidays from '../../../hooks/services/useLoadHolidays';

export const Datepicker = ({
  label, subtext, styles = {}, showDaysAlert, ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  const { holidays } = useLoadHolidays();

  const isOffDay = (date, isCss = true) => isWeekendOrHoliday(date, isCss, holidays);
  const showDaysAlertCss = showDaysAlert ? { dayClassName: isOffDay } : {};

  const onChange = props.onChange
    ? props.onChange : date => setFieldValue(field.name, date);

  return (
    <Form.Group className={styles.formGroup}>
      <Form.Label
        htmlFor={props.id || props.name}
        className={styles.formLabel}
      >
        {label || ''}
      </Form.Label>
      <DatePicker
        className="form-control"
        aria-describedby={`${props.name} Date Picker`}
        {...showDaysAlertCss}
        {...field}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={onChange}
      />
      {subtext && <Form.Text muted>{subtext}</Form.Text>}
      {field.value && showDaysAlert && (
        <Form.Text className="medium-text text-ccm-red font-weight-bold">
          {isOffDay(new Date(field.value), false)}
        </Form.Text>)
      }
      {meta.error && (
        <Form.Text className="text-danger" data-test="datepicker_errorMsg">{meta.error}</Form.Text>
      )}
    </Form.Group>
  );
};
