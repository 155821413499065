// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getPatientsCurrentMonthBirthdays } from '../../services/patient';

const usePatientsBirthdays = () => {
  const [patientsBirthdays, setPatientsBirthdays] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPatientsBirthdays = useCallback(async () => {
    const getPatientsCurrentMonthBirthdaysRequest = getPatientsCurrentMonthBirthdays();
    const getPatientsCurrentMonthBirthdaysPromise = getPatientsCurrentMonthBirthdaysRequest.promise;
    try {
      const requestedData = await getPatientsCurrentMonthBirthdaysPromise;
      setPatientsBirthdays(requestedData.patients);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patients birthdays.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, []);

  useEffect(() => {
    fetchPatientsBirthdays();
  }, [fetchPatientsBirthdays]);

  return { patientsBirthdays, refetch: fetchPatientsBirthdays };
};

export default usePatientsBirthdays;
