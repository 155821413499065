// Libraries
import React, { Fragment, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
// Constants
import { USER_ROLES } from '../../../constants/constants';
// Actions
import { QaPaginate, QaSort } from '../../../actions/qaSearch';
// Views
import QaStatusLegend from '../../qa/QaStatusLegend';
import QaSortingPanel from '../../qa/QaSortingPanel';
import PatientsScheduler from '../../patients/PatientsScheduler';
import TableHeader from '../../qa/table/TableHeader';
import TableContent from '../../qa/table/TableContent';
import QaSearch from '../../qa/QaSearch';
// Custom Hooks
import useQaPatients from '../../../hooks/services/useQaPatients';

export const QaList = (props) => {
  const {
    qaSearch: { searchParams: stateSearchParams, pageNumber: statePageNumber = 0 },
    user: { role: userRole }, requestsInProgress: { count: loading },
  } = useSelector(state => state);

  const { pageSize } = props;
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});

  const [isSearchPanelOpened, setIsSearchPanelOpened] = useState(false);
  const [sort, setSort] = useState({ key: '', reverse: false });
  const [currentPage, setCurrentPage] = useState(statePageNumber);
  const dispatch = useDispatch();
  const { patientsList: { totalCount, totalPages, patients = [] } } = useQaPatients({
    pageSize, searchParams, sort, currentPage,
  });


  const goToPage = (pageNumber) => {
    if (loading) {
      return;
    }

    dispatch(QaPaginate(pageNumber, pageSize));
    setCurrentPage(pageNumber);
  };

  const sortBy = (params) => {
    const newSortState = {
      key: params.key,
      reverse: params.reverse,
    };
    dispatch(QaSort(newSortState));
    setSort(newSortState);
  };

  useEffect(() => {
    if (statePageNumber !== undefined || statePageNumber !== null) setCurrentPage(statePageNumber);
    if (stateSearchParams) setSearchParams(stateSearchParams);
  }, [stateSearchParams, statePageNumber]);

  let emptyBlock;
  if (!patients || !patients.length) {
    emptyBlock = <div className="empty-value" data-test="patientList_emptyValue">No patients found.</div>;
  }

  return (
    <Fragment>
      <QaStatusLegend />
      <div className="ccm-patients-list d-flex mt-2">
        <div className={`h-100 ccm-patient-panel-search box-wrapper ${isSearchPanelOpened ? 'is-opened' : ''}`}>
          <QaSearch
            isOpened={isSearchPanelOpened}
            handlePanelOpened={() => setIsSearchPanelOpened(!isSearchPanelOpened)}
            data-test="qaList_qaSearch"
          />
        </div>
        <div className="h-100 ccm-patient-list-content flex-grow-1 ml-2">
          <div className="d-flex flex-column h-100">
            <div className="row no-gutters">
              <div className={`col ccm-sorting-container box-wrapper d-flex p-2 is-closed ${userRole === USER_ROLES.CN ? 'is-cn-role' : ''}`}>
                <QaSortingPanel
                  totalCount={totalCount}
                  isSearchPanelOpened={isSearchPanelOpened}
                  totalPages={totalPages}
                  goToPage={goToPage}
                  currentPage={currentPage}
                  data-test="qaList_qaSortingPanel"
                />
              </div>
            </div>
            <div className="row no-gutters overflow-hidden flex-grow-1 mt-2">
              <div className="col ccm-table-container h-100 box-wrapper px-3">
                <table className="table table-striped table-hover w-100" data-test="patientList_listColumns">
                  <TableHeader
                    sortPatients={sortBy}
                    data-test="qaList_tableHeader"
                  />
                  <TableContent
                    patients={patients}
                  />
                </table>
                {emptyBlock}
                <ReactTooltip id="tooltip-qaTable" type="info" place="bottom" effect="float" />
              </div>
              <div className="col-3 ccm-schedule-container h-100 box-wrapper overflow-auto ml-2 py-2 d-none">
                <PatientsScheduler />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default QaList;
