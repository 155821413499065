export const BlockRouteTransitions = (elementName, value) => ({
  type: 'BLOCK_ROUTE_TRANSITIONS',
  elementName,
  value,
});

export const UnBlockRouteTransitions = elementName => ({
  type: 'UNBLOCK_ROUTE_TRANSITIONS',
  elementName,
});
