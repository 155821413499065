// libraries
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
// reducers
import reducer from '../reducers';

export function configureAppStore() {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const logger = createLogger({
    collapsed: true,
  });
  const store = configureStore({
    reducer,
    ...(isDevelopment && { devTools: true }),
    ...(isDevelopment
      && {
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
      }),
  });

  return store;
}

export const store = configureAppStore();
