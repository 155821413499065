// Libraries
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getFlightPlanReminders, updatePatientReminder } from '../../services/patient';
import { getCallTimeLabel } from '../../services/helpers';
// Components
import Pager from '../patients/Pager';
import { PatientInfo } from '../menu/PatientInfo';
// Constants
import {
  NOTIFICATION_TYPE, DATE_FORMAT, USER_ROLES,
} from '../../constants/constants';
import { DEFAULT_PAGE_SIZE } from '../../constants/pageSizes';

export const Reminders = (props) => {
  const { user, showNotification } = props;

  const { tenant: tenantUrl } = useParams();

  const isPesUser = user.role === USER_ROLES.PES;
  const isAdminUser = user.role === USER_ROLES.ADMIN;

  const [patients, setPatients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [collapseAll, setCollapseAll] = useState(false);

  const getUrl = (patientId) => {
    if (isPesUser) {
      return `/${tenantUrl}/cn/summary-patient/${patientId}/overview`;
    }
    return `/${tenantUrl}/cn/patient/${patientId}/summary`;
  };

  const getPatientsReminders = () => {
    const getRemindersRequest = getFlightPlanReminders({
      pageSize: DEFAULT_PAGE_SIZE, pageNumber: currentPage,
    });
    const getRemindersPromise = getRemindersRequest.promise;

    return getRemindersPromise.then((data) => {
      delete getRemindersRequest.promise;

      setPatients(
        data.patientReminders.map(el => ({ ...el, hasReminders: el.activeReminders.length })),
      );
      setTotalPages(data.totalPages);
      setTotalCount(data.totalCount);
    }).catch((error) => {
      delete getRemindersRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load Flight Plan Reminders, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleReminderStatus = (patient, reminder, status) => {
    const {
      id: patientId, firstName, lastName, activeReminders: reminders,
    } = patient;

    const updatedReminder = { ...reminder, status };

    const addReminderRequest = updatePatientReminder(patientId, updatedReminder);
    const addReminderPromise = addReminderRequest.promise;

    return addReminderPromise.then((data) => {
      delete addReminderPromise.promise;

      const updatedReminders = reminders.filter(r => r.id !== data.id);
      const updatedPatient = {
        ...patient,
        activeReminders: updatedReminders,
        hasReminders: updatedReminders.filter(x => x.status === 'CREATED').length,
      };

      let updatedPatients;
      if (updatedReminders.length === 0) {
        setTotalCount(totalCount - 1);
        updatedPatients = patients.filter(el => el.id !== patientId);
      } else {
        updatedPatients = patients.map(el => (el.id === patientId ? updatedPatient : el));
      }
      setPatients(updatedPatients);
      showNotification({
        message: `Updated Reminder for ${firstName} ${lastName}`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete addReminderPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to modify this reminder',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderPatientReminders = (patient) => {
    const { activeReminders: reminders } = patient;

    if (!reminders.length) {
      return <small className="d-block text-ccm-gray mb-2">Empty reminders</small>;
    }
    const activeReminders = reminders.filter(x => x.status === 'CREATED');

    const getReminderDateTimeClass = (reminderDate) => {
      if (!reminderDate) return 'text-ccm-gray';
      if (moment.utc(reminderDate).isBefore(moment.utc(), 'day')) return 'text-ccm-red';
      return 'text-ccm-bismark';
    };

    return (
      <Form className="reminders-list pr-2 mr-3" data-test="reminders_list">
        {activeReminders.map(reminder => (
          <div key={`patientReminder__${reminder.id}`} className="d-flex align-items-center mb-2">
            <Form.Check type="checkbox" className="d-flex flex-grow-1 mr-2">
              <Form.Check.Input
                type="checkbox"
                defaultChecked={reminder.status === 'CHECKED'}
                onClick={() => handleReminderStatus(patient, reminder, 'CHECKED')}
              />
              <Form.Check.Label className="d-flex align-items-center flex-grow-1">
                <span className="mr-1">{reminder.text}</span>

                {(reminder.reminderDate || reminder.reminderTime) && (
                <span
                  className={`reminders__dateTime ml-auto ${getReminderDateTimeClass(reminder.reminderDate)}`}
                >
                  {`${reminder.reminderDate ? moment(reminder.reminderDate).format(DATE_FORMAT.FULL) : ''} `}
                  {`${reminder.reminderTime ? `(${getCallTimeLabel(reminder.reminderTime)})` : ''}`}
                </span>)}
              </Form.Check.Label>
            </Form.Check>
            <Button
              data-test="reminders_delete_btn"
              variant="link-dark"
              className="ml-auto p-0"
              onClick={() => handleReminderStatus(patient, reminder, 'DELETED')}
            >
              <i className="d-flex-center bi-x" />
            </Button>
          </div>
        ))}
      </Form>
    );
  };

  const showingResults = () => {
    const tempCount = (currentPage + 1) === totalPages
      ? totalCount : DEFAULT_PAGE_SIZE * (currentPage + 1);
    return `${(currentPage * DEFAULT_PAGE_SIZE) + 1} - ${tempCount} of ${totalCount}`;
  };

  useEffect(() => {
    getPatientsReminders();
  }, [currentPage]);

  return (
    <div className="flight-plan-reminders h-100 overflow-auto pr-3">
      <div className="d-flex align-items-center">
        <h4 className="text-left my-3" data-test="reminders_headingText">Reminders</h4>
        <div className="ml-auto d-flex-center">
          <span data-test="reminders_pageRresults">{`Results: ${showingResults()}`}</span>
          <Button
            data-test="reminders_btnCollapseAll"
            size="sm"
            variant="link-dark"
            className="d-flex-center ml-2"
            onClick={() => setCollapseAll(!collapseAll)}
          >
            <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
            <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
          </Button>
        </div>
      </div>
      <div className="row no-gutters reminders-content">
        {patients.map(patient => (
          <ReminderRow
            key={`flight-plan-patient__${patient.id}`}
            patient={patient}
            renderPatientReminders={renderPatientReminders}
            isAdminUser={isAdminUser}
            collapseAll={collapseAll}
            toUrl={getUrl(patient.id)}
            refreshCallback={getPatientsReminders}
          />
        ))}
      </div>
      <div className="row reminders-pager no-gutters d-flex-center my-3">
        <Pager
          totalPages={totalPages}
          maxShownCount={5}
          isNextPrevShown
          currentPage={currentPage}
          callback={page => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

const ReminderRow = (props) => {
  const {
    patient, patient: { assignedCareNavigator }, toUrl,
    isAdminUser, renderPatientReminders, collapseAll, refreshCallback,
  } = props;
  const [opened, setOpened] = useState(true);
  useEffect(() => setOpened(!collapseAll), [collapseAll]);

  return (
    <div className={`row no-gutters w-100 mb-2 reminderRow-${opened ? 'opened' : 'closed'}`}>
      <div className={`col-4 patient-info border border-right-0 patientInfo-${opened ? 'opened' : 'closed'}`}>
        <PatientInfo
          patient={patient}
          fullInfo={opened}
          refreshCallback={refreshCallback}
          toUrl={toUrl}
        />
      </div>
      <div className="col reminders-info border border-left-0 text-left p-3">
        {isAdminUser && (
        <p className={`reminders__assignedCn ${opened ? 'mb-2' : 'mb-0'}`} data-test="reminders_assignedCn">
          {`Assigned CN: ${assignedCareNavigator ? `${assignedCareNavigator.firstName || ''} ${assignedCareNavigator.lastName || ''}` : 'N.A'}`}
        </p>)}
        {opened && renderPatientReminders(patient)}
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ top: '1rem', right: '0' }}
          onClick={() => setOpened(!opened)}
        >
          <i className={`d-flex-center bi-caret-${opened ? 'down' : 'up'}-fill ml-1`} />
        </Button>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reminders);
