// libraries
import React, { Fragment } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const MAX_SHOWN_CONDITIONS = 4;
const MIN_CONDITIONS_IN_COLUMN = 15;
/**
 * CCMCondition component, requires props:
 * condition -- array, conditions
 * patientName -- string, patient name
 * limit -- number, optional, count of conditions that will be shown
 * maxColumnsCount
 */
export function CCMCategories(props) {
  const { condition: conditions } = props;
  const maxShownConditions = props.limit || MAX_SHOWN_CONDITIONS;
  let shownConditionsNumber;
  let conditionsString = 'N/A';
  let moreConditionsBlock;

  function renderConditionsTable() {
    const maxColumnsCount = props.maxColumnsCount || 2;
    const maxConditionsInColumn = Math.max(MIN_CONDITIONS_IN_COLUMN - 1,
      Math.ceil(conditions.length / maxColumnsCount));
    let maxIndexInColumn = maxConditionsInColumn;
    const conditionColumns = [[]];
    let currentColumnIndex = 0;
    let currentColumn = conditionColumns[currentColumnIndex];

    conditions.forEach((condition, index) => {
      const conditionBlock = <div key={`ccm-condition-${index}`} className="mb-3" data-test="ccmCategories_ccmConditions">{condition}</div>;

      if (index > maxIndexInColumn) {
        conditionColumns.push([]);
        currentColumnIndex = conditionColumns.length - 1;
        currentColumn = conditionColumns[currentColumnIndex];
        maxIndexInColumn = maxConditionsInColumn * (currentColumnIndex + 1);
      }

      currentColumn.push(conditionBlock);
    });

    const conditionsTable = conditionColumns.map((conditionColumn, index) => (
      <div
        key={`ccm-condition-column-${index}`}
      >
        {conditionColumn}
      </div>
    ));

    return <div className="text-ccm-bluewood">{conditionsTable}</div>;
  }

  if (conditions && conditions.length) {
    conditionsString = '';
    shownConditionsNumber = (maxShownConditions > conditions.length)
      ? conditions.length
      : maxShownConditions;

    for (let i = 0; i < shownConditionsNumber; i++) {
      conditionsString += `${conditions[i]}, `;
    }

    if (conditions.length > maxShownConditions) {
      const morePopover = (
        <Popover>
          <Popover.Title>
            <strong>{props.patientName}</strong>
            <small className="d-block">CCM Categories</small>
          </Popover.Title>
          <Popover.Content>
            {renderConditionsTable()}
          </Popover.Content>
        </Popover>
      );

      moreConditionsBlock = (
        <OverlayTrigger
          trigger={['hover', 'click']}
          placement="auto"
          overlay={morePopover}
          rootClose
          className="link-more"
        >
          <b>
            {conditions.length - maxShownConditions}
            {' more'}
          </b>
        </OverlayTrigger>
      );
    } else {
      conditionsString = conditionsString.substring(0, conditionsString.length - 2);
    }
  }

  return (
    <Fragment>
      {conditionsString}
      {moreConditionsBlock}
    </Fragment>
  );
}

export default CCMCategories;
