// libraries
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
// Actions
import { SetShowGlobalTopBar } from '../../../actions/tenants';
// Images
import logo from '../../../images/brand-logo.svg';
// Constants
import { CONFIG_NAME, INIT_HASHTAG } from '../../../constants/globalAdminUi';

export function GlobalLogo() {
  const { tenant: tenantUrl } = useParams();
  const { count: loading } = useSelector(state => state.requestsInProgress);
  const dispatch = useDispatch();

  const redirectUrl = tenantUrl === CONFIG_NAME ? INIT_HASHTAG : tenantUrl;

  return (
    <Link
      to={`/${redirectUrl}/snook/list`}
      onClick={() => dispatch(SetShowGlobalTopBar(false))}
      data-test="globalLogo_link"
    >
      <img
        src={logo}
        alt="SelectPatient Management logo"
        data-test="globalTopBar_engoodenLogo"
        className={`logo ${loading > 0 ? 'd-none' : 'd-block'}`}
      />
    </Link>
  );
}

export default GlobalLogo;
