import { CLINICAL_CONDITIONS_TITLES, NON_CLINICAL_CONDITIONS_TITLES } from './conditions';

export const TIME_TRACKING_NOTES_CODES = {
  enrollmentCall: {
    label: 'Enrollment Call',
    value: '- Spoke directly with patient. \n- Patient educated on the value of CCM services.\n- Patient was determined to be eligible for CCM service.\n- Patient is not receiving CCM services from another provider.\n- Patient is not currently staying in an rehab/long-term nursing facility or receiving hospice care.\n- Patient acknowledges potential cost share of CCM and that they may stop service at any time.',
  },
  carePlanCompleted: {
    label: 'Care Plan Completed',
    value: '- Reviewed chart.\n- Does not have a scheduled office visit or telehealth appt with PCP today.\n- CN set new goals.\n- Assessments and interventions completed \n- See care plan.\n- Will follow up with patient monthly and as needed.',
  },
  monthlyOutreachCall: {
    label: 'Monthly Outreach Call',
    value: '- Reviewed chart and past care plan notes.\n- Does not have a scheduled office visit or telehealth appt with PCP today.\n- Assessments and interventions completed \n- See care plan.\n- Will follow up with patient monthly and as needed.',
  },
  noVoicemailEnrollmentCall: {
    label: 'No Voicemail –Enrollment Call',
    value: '- Reviewed chart\n- Attempted enrollment call.\n- No answer.\n- Unable to LVM.',
  },
  noVoicemailMonthlyOutreachCall: {
    label: 'No Voicemail –MonthlyOutreachCall',
    value: '- Reviewed chart and past care plan notes.\n- Attempted to call patient.\n- No answer.\n- Unable to LVM.',
  },
  voicemailMonthlyOutreachCall: {
    label: 'Voicemail –Monthly Outreach Call',
    value: '- Reviewed chart and past care plan notes.\n- Attempted to call patient.\n- No answer.\n- LVM.',
  },
  voicemailEnrollmentCall: {
    label: 'Voicemail –Enrollment Call',
    value: '- Reviewed chart\n- Attempted enrollment call.\n- No answer.\n- LVM.',
  },
  disenrollmentCall: {
    label: 'Disenrollment Call',
    value: '- Spoke directly with patient to discuss disenrollment request.\n- Completed Exiting Survey.',
  },
  noAnswerDisenrollmentCall: {
    label: 'No answer –Disenrollment Call',
    value: '- Attempted to contact patient to discuss disenrollment request.\n- No answer.\n- LVM.',
  },
};

export const TIME_TRACKING_NOTES = [
  { ...TIME_TRACKING_NOTES_CODES.enrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.carePlanCompleted },
  { ...TIME_TRACKING_NOTES_CODES.monthlyOutreachCall },
  { ...TIME_TRACKING_NOTES_CODES.noVoicemailEnrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.noVoicemailMonthlyOutreachCall },
  { ...TIME_TRACKING_NOTES_CODES.voicemailMonthlyOutreachCall },
  { ...TIME_TRACKING_NOTES_CODES.voicemailEnrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.disenrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.noAnswerDisenrollmentCall },
];

export const TIME_TRACKING_NOTES_PES_FLOW = [
  { ...TIME_TRACKING_NOTES_CODES.enrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.noVoicemailEnrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.voicemailEnrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.disenrollmentCall },
  { ...TIME_TRACKING_NOTES_CODES.noAnswerDisenrollmentCall },
];

export const interventionTemplates = (category, type) => {
  switch (true) {
    case category === 'MEDICATION' && type === 'Compliance/Adherence':
      return [{
        label: 'Medication Compliance',
        value: 'Patient reports taking all medications as prescribed. Patient denies any need for refills at this time.',
      }];
    case category === 'CN_SCREENING' && type === 'DM Type I':
    case category === 'CN_SCREENING' && type === 'DM Type II':
      return [{
        label: 'Blood Glucose',
        value: 'Patient reports checking blood glucose [INSERT VALUE] times per day and keeping a log. Patient reports taking all medications as prescribed and denies the need for any medication or supply refills. Patient reports no new or concerning symptoms.',
      }];
    case category === 'CN_SCREENING' && type === 'HTN':
      return [{
        label: 'Blood Pressure',
        value: 'Patient reports checking blood pressure [INSERT VALUE] times per day and keeping a log. Patient reports taking all medications as prescribed and denies the need for any medication refills. Patient reports no new or concerning symptoms.',
      }];
    case category === 'CN_SCREENING' && type === 'COPD':
      return [{
        label: 'COPD',
        value: 'Patient reports SpO2 readings are within normal limits. Patient reports taking all medications as prescribed and denies the need for any medication refills. Patient reports no new or concerning symptoms.',
      }];
    case category === 'CN_SCREENING' && type === 'CHF':
      return [{
        label: 'CHF',
        value: 'Patient reports keeping a daily weight log. Patient takes weight reading first thing in the morning after urinating, and wears approximately the same amount of clothing with each reading. Patient reports taking all medications as prescribed and denies the need for any medication or supply refills. Patient reports no new or concerning symptoms.',
      }];
    case category === 'CN_SCREENING' && type === 'Depression':
    case category === 'CN_SCREENING' && type === 'Stress/Anxiety':
      return [{
        label: 'Depression/Anxiety',
        value: 'Encouraged patient to engage in self care activities such as deep breathing, exercise, relaxation. Patient reports that depression and anxiety are well controlled at this time. Patient reports taking all medications as prescribed and denies the need for any medication or supply refills.',
      }];
    case category === 'CN_SCREENING' && type === 'Pain':
      return [{
        label: 'Pain Screening',
        value: '1.Rate your pain on a scale of 0-10. ‘0’ is pain free, ‘10’ is pain so bad that you cannot care for yourself or others or you are unable to converse. [INSERT VALUE]\n\n2.Does pain/aching ever keep you from sleeping at night? If yes, how many nights/week (1-7) [INSERT VALUE]\n\n3.How often per week does your pain/aching keep you from participating in activities/doing things you enjoy? (1-7) [INSERT VALUE].\n\n4.Does your pain affect your ability to care for yourself independently on a regular basis? IF yes, how many times per week? (1-7) [INSERT VALUE]\n\n5.What works best right now for you to manage your pain? (looking for medications, therapy, activity such as exercise or meditation, etc) [INSERT VALUE]',
      }];
    case category === 'EDUCATION' && type === 'Activity/Exercise':
      return [{
        label: 'Exercise',
        value: 'Discussed the importance of daily physical activity. Patient encouraged to engage in daily activity as tolerated.',
      }];
    case category === 'EDUCATION' && type === 'Diet':
      return [{
        label: 'Obesity',
        value: 'Discussed the importance of daily physical activity and a healthy diet. Patient encouraged to engage in daily activity as tolerated. Patient encouraged to follow a heart healthy diet by increasing fresh fruits and vegetables and limiting foods high in fat and sugar.',
      }];
    case category === 'EDUCATION' && type === 'Hydration':
      return [{
        label: 'Hydration',
        value: 'Encouraged patients to increase daily water intake and limit sugary and caffeinated drinks.',
      }];
    case category === 'EDUCATION' && type === 'Tobacco Cessation':
      return [{
        label: 'Tobacco Cessation',
        value: 'Discussed extensive health benefits of smoking cessation and encouraged patient to consider engaging in a smoking cessation plan.',
      }];
    case category === 'EDUCATION' && type === 'Patient Verbally Accepts':
      return [{
        label: 'Patient Verbally Accepts',
        value: 'Patient verbally consents to remain enrolled in the Chronic Care Management Program. Verbal consent obtained by [INSERT CN NAME]',
      }];
    case category === 'EDUCATION' && type === 'Provider Transition - Patient Verbally Consents':
      return [{
        label: 'Patient Verbally Consents Accepts',
        value: 'Patient verbally consents to enroll in the CCM program under [INSERT NEW PROVIDER NAME] Consent obtained by [INSERT CN NAME]',
      }];
    default:
      return [];
  }
};

export const goalTemplates = (title) => {
  switch (title) {
    case CLINICAL_CONDITIONS_TITLES.ACUTE_MYOCARDIAL_INFARCTION.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.ANXIETY_DISORDERS.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.ATRIAL_FIBRILLATION.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.BIPOLAR_DISORDER.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION_DISORDERS.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.GLAUCOMA.titles[5]:
    case CLINICAL_CONDITIONS_TITLES.MIGRAINE_CHRONIC_HEADACHE.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.SCHIZOPHRENIA.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.SCHIZOPHRENIA_AND_OTHER_PSYCHOTIC_DISORDERS.titles[2]:
      return [{
        label: 'Coping strategies',
        value: 'Patient to participate in an activity they enjoy such as reading, writing, or talking to a friend when feeling depressed or anxious.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ACUTE_MYOCARDIAL_INFARCTION.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.ACUTE_MYOCARDIAL_INFARCTION.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.ATRIAL_FIBRILLATION.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[11]:
      return [{
        label: 'Blood Pressure',
        value: 'Monitor blood pressure [INSERT VALUE] times per day and record reading in log. Reach out to CN with any concerning readings or symptoms. Report readings at the next monthly outreach call.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE_DISORDERS.titles[0]:
      return [{
        label: 'Eliminate alcohol consumption',
        value: 'Patient will swap to no alcohol alternatives. Patient will remove alcohol that is kept in the house. Patient will keep a track of days/weeks that they do not consume alcohol, and celebrate milestones accordingly.\nPatient will find a support group, if needed.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE_DISORDERS.titles[1]:
      return [{
        label: 'Alcohol triggers',
        value: 'Patient will identify the triggers that tempt them to drink more and create a plan to reduce them or be mindful not to drink in those situations.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE_DISORDERS.titles[3]:
      return [{
        label: 'Alcohol elimination challenge - 30 day',
        value: 'Patient will identify a month in which they agree not to drink (for example "dry January") and share the goal with their CN.\nIf completed, patient will identify ways to continue with reduced or no alcohol consumption.\nIf not completed, patient will work to identify and reduce triggers and set a more manageable goal. Patient understands that it sometimes takes several attempts to successfully reduce or eliminate alcohol consumption.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE_DISORDERS.titles[4]:
      return [{
        label: 'Reduce alcohol consumption',
        value: 'Patient will set a drink limit and count their drinks.\nPatient will swap to low or no alcohol alternatives.\nPatient will limit how much alcohol is kept in the house.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ANXIETY_DISORDERS.titles[0]:
      return [{
        label: 'Find a buddy',
        value: 'Patient will find a buddy and exercise with them [INSERT VALUE] times per week.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ANXIETY_DISORDERS.titles[4]:
      return [{
        label: 'Activity Challenge 30 day',
        value: 'Patient will set a goal to slowly increase steps or activity over the course of a month.\nPatient will keep track of progress and note any challenges and obstacles along the way.\nPatient to discuss with CN during next MOC.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ANXIETY_DISORDERS.titles[9]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION.titles[8]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION_DISORDERS.titles[8]:
      return [{
        label: 'Medication Challenge - 30 day',
        value: 'Patient will meditate for at least 10 minutes a day for 30 days.\nPatient will keep track of progress and share with CN during MOCs.',
      }];
    case CLINICAL_CONDITIONS_TITLES.ANXIETY_DISORDERS.titles[14]:
      return [{
        label: 'Fitness Tracker',
        value: 'Patient will use a fitness tracker to record steps. Plan to very gradually increase step count over time.',
      }];
    case CLINICAL_CONDITIONS_TITLES.BENIGN_PROSTATIC_HYPERPLASIA.titles[0]:
      return [{
        label: 'Caffeine free challenge 30 day',
        value: 'Patient will slowly decrease caffeine consumption over one month. Eliminate or reduce caffeine by 1-2 cups or beverages a day.\nSwitch to decaffeinated options.\nKeep track of progress - celebrate victory!',
      }];
    case CLINICAL_CONDITIONS_TITLES.CANCER_ENDOMETRIAL.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.CANCER_BREAST.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.CANCER_COLORECTAL.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.CANCER_LUNG.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.CANCER_PROSTATE.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.CANCER_UROLOGIC.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.FIBROMYALGIA.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.LEUKEMIAS_LYMPHOMAS.titles[2]:
      return [{
        label: 'Pain Relief',
        value: 'Encouraged complementary therapies for pain relief, such as acupuncture, yoga, aromatherapy or hypnotherapy if not contraindicated.\nEncouraged the patient to use nonpharmacologic pain relief interventions, such as massage, medication, heat and other diversional activities to promote relaxation and pain relief, if not contraindicated.',
      }];
    case CLINICAL_CONDITIONS_TITLES.CHRONIC_KIDNEY_DISEASE.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[3]:
      return [{
        label: 'Blood Glucose',
        value: 'Monitor blood glucose levels [INSERT VALUE] times per day and record reading in log. Reach out to CN with any supply or equipment concerns. Report readings at the next monthly outreach call.',
      }];
    case CLINICAL_CONDITIONS_TITLES.CHRONIC_KIDNEY_DISEASE.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.CHRONIC_KIDNEY_DISEASE.titles[4]:
      return [{
        label: 'Blood Pressure',
        value: 'Monitor blood pressure [INSERT VALUE] times per day and record reading in log. Reach out to CN with any concerning readings or symptoms. Report readings at the next monthly outreach call.',
      }];
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION_DISORDERS.titles[1]:
      return [{
        label: 'Reduce Alcohol Consumption',
        value: 'Patient will set a drink limit and count their drinks.\nPatient will swap to low or no alcohol alternatives.\nPatient will limit how much alcohol is kept in the house.',
      }, {
        label: 'Eliminate Alcohol Consumption',
        value: 'Patient will swap to no alcohol alternatives. Patient will remove alcohol that is kept in the house.\nPatient will keep a track of days/weeks that they do not consume alcohol, and celebrate milestones accordingly.\nPatient will find a support group, if needed.',
      }];
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION.titles[6]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION_DISORDERS.titles[6]:
      return [{
        label: 'Mind Stimulation',
        value: 'Patient will engage in stimulating activities such as physical activity, puzzles, reading, and listening to music daily.',
      }];
    case CLINICAL_CONDITIONS_TITLES.HEART_FAILURE.titles[2]:
      return [{
        label: 'Daily Weights',
        value: 'Record daily weights each morning. Take reading first thing in the morning after urination. Wear approximately the same amount of clothing with each reading. Report readings at the next monthly outreach call.',
      }];
    case CLINICAL_CONDITIONS_TITLES.HEART_FAILURE.titles[4]:
      return [{
        label: 'Reduce lower extremity edema',
        value: 'Patient encouraged to wear compression stockings, if ordered. Patient will elevate feet when possible. If swelling continues or is accompanied by other symptoms such as shortness of breath, patient will notify provider.',
      }];

    case NON_CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE.titles[0]:
      return [{
        label: 'Eliminate alcohol consumption',
        value: 'Patient will swap to no alcohol alternatives.\nPatient will remove alcohol that is kept in the house.\nPatient will keep a track of days/weeks that they do not consume alcohol, and celebrate milestones accordingly.\nPatient will find a support group, if needed.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE.titles[1]:
      return [{
        label: 'Reduce alcohol consumption',
        value: 'Patient will set a drink limit and count their drinks.\nPatient will swap to low or no alcohol alternatives.\nPatient will limit how much alcohol is kept in the house.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE.titles[2]:
      return [{
        label: 'Alcohol elimination challenge - 30 day',
        value: 'Patient will identify a month in which they agree not to drink (for example ""dry January"") and share the goal with their CN.\nIf completed, patient will identify ways to continue with reduced or no alcohol consumption.\nIf not completed, patient will work to identify and reduce triggers and set a more manageable goal. Patient understands that it sometimes takes several attempts to successfully reduce or eliminate alcohol consumption.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.ALCOHOL_USE.titles[3]:
      return [{
        label: 'Alcohol triggers',
        value: 'Patient will identify the triggers that tempt them to drink more and create a plan to reduce them or be mindful not to drink in those situations.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTH_COMPLIANCE.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.PERSONALITY_DISORDERS.titles[1]:
      return [{
        label: 'Appointments',
        value: 'Encourage patient to prepare for upcoming appointments by bringing a written list of questions for the provider, as it\'s easy to forget to ask them in the heat of the moment.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTH_COMPLIANCE.titles[1]:
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTH_COMPLIANCE.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.ATRIAL_FIBRILLATION.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION.titles[12]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION.titles[14]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION_DISORDERS.titles[12]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION_DISORDERS.titles[14]:
    case CLINICAL_CONDITIONS_TITLES.EPILEPSY.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.GLAUCOMA.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.HIV_AIDS.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[10]:
    case CLINICAL_CONDITIONS_TITLES.HYPOTHYROIDISM.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.OSTEOPOROSIS.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.PERSONALITY_DISORDERS.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.SCHIZOPHRENIA.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.SCHIZOPHRENIA_AND_OTHER_PSYCHOTIC_DISORDERS.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.TRAUMATIC_BRAIN_INJURY.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.VIRAL_HEPATITIS.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.PARKINSON_DISEASE.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.PNEUMONIA.titles[3]:
      return [{
        label: 'Medication Compliance',
        value: 'Patient educated that using pill keepers and taking medications at the same time each day (according to the instructions) helps not only with absorption but also remembering to take them.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTH_COMPLIANCE.titles[3]:
      return [{
        label: 'Vaccinations',
        value: 'Patient understands the importance of staying up-to-date on vaccinations and will make appointments (with the help of their CN) as needed.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTH_COMPLIANCE.titles[4]:
      return [{
        label: 'Medication Refills',
        value: 'Patient agrees to request refills before running out of medications.\nPatient to have at least a 14-30 day supply of all prescribed medications.\nPatient understands how to request refills (telephone, on-line, automatic) and is using the method that works best for them.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTHY_EATING.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[0]:
      return [{
        label: 'Fruits and Vegetables',
        value: 'Aim to consume plenty of fruits and vegetables per day, with each meal.\nExperiment with new or in season fruits and vegetables that you are not familiar with.\nTry grilling, roasting or air frying vegetables vs cooking with oil or butter.\nTry fresh fruit as an option for dessert.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTHY_EATING.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[10]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[8]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[14]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[15]:
      return [{
        label: 'Fast Food Eating',
        value: 'Limit eating at fast food restaurants if possible.\nWhen eating at fast food restaurants, opt for grilled options without sauces or add-ons.\nGet the small size of fries, and share them with a friend.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTHY_EATING.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[11]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[9]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[15]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[16]:
      return [{
        label: 'Lean Proteins',
        value: 'Patient will experiment with leaner protein options. Examples are: boneless, skinless chicken or turkey breast, thigh, drumstick, and wings;\nLean steaks, such as the eye of round, top sirloin, and filet mignon;\nLean or extra-lean ground beef or pork;\nPatient will try using an air fryer, grill or broiling to prepare lean meats.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTHY_EATING.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[6]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[5]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[8]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[10]:
      return [{
        label: 'Eating Out',
        value: 'Understand that portion sizes when eating out are often double or more - ask for a doggy bag for leftovers.\nAvoid fried or sauteed food options.\nLimit appetizers and desserts - sharing is a great option!',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTHY_EATING.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[7]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[6]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[9]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[11]:
      return [{
        label: 'Healthy Food Choices',
        value: 'Read food labels and nutrition information to make healthy choices.\nPay attention to serving sizes.\nLimit processed foods.\nShop the perimeter of the grocery store to avoid most processed foods.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTHY_EATING.titles[5]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[14]:
      return [{
        label: 'Prevent Choking',
        value: 'Patient will eat in a relaxed atmosphere and allow plenty of time for meals.\nPatient will take small bites and chew each bite thoroughly.\nWhen eating, patient will sit upright and maintain good posture.\nAvoid talking while eating.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTH_COMPLIANCE.titles[5]:
    case NON_CLINICAL_CONDITIONS_TITLES.HEALTHY_EATING.titles[6]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[5]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[7]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[9]:
      return [{
        label: 'Healthy Weight',
        value: 'Limit foods high in fat and sugar. Increase fresh fruits, fresh vegetables, and lean meats.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HYDRATION.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[7]:
      return [{
        label: 'Hydration',
        value: 'Patient will focus on regular water intake throughout the day, increasing with activity and warm weather. Aim for pale yellow urine.  Patient will limit caffeinated, sugary beverages that increase dehydration.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.HYDRATION.titles[1]:
      return [{
        label: 'Caffeine free challenge 30 day',
        value: 'Patient will slowly decrease caffeine consumption over one month.\nEliminate or reduce caffeine by 1-2 cups or beverages a day.\nSwitch to decaffeinated options.\nKeep track of progress - celebrate victory!',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.MINDSET.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[12]:
      return [{
        label: 'Meditation Challenge - 30 day',
        value: 'Patient will meditate for at least 10 minutes a day for 30 days.\nPatient will keep track of progress and share with CN during MOCs.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.MINDSET.titles[3]:
      return [{
        label: 'Coping Strategies',
        value: 'Patient to participate in an activity they enjoy such as reading, writing, or talking to a friend when feeling depressed or anxious.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.MINDSET.titles[8]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION.titles[11]:
    case CLINICAL_CONDITIONS_TITLES.DEPRESSION_DISORDERS.titles[11]:
      return [{
        label: 'Positive Thought',
        value: 'Patient to share a positive thought with their care navigator, friends or family members to bring positivity into their mind.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.MINDSET.titles[9]:
      return [{
        label: 'Mind stimulation',
        value: 'Patient will engage in stimulating activities such as physical activity, puzzles, reading, and listening to music daily.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.MINDSET.titles[10]:
      return [{
        label: 'Migraine reduction',
        value: 'Avoid triggers such as bright lights, strong perfumes/chemicals. Follow up with provider as directed.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.PHYSICAL_ACTIVITY.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.ANXIETY_DISORDERS.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.HYPERLIPIDEMIA.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[3]:
      return [{
        label: 'Exercise',
        value: 'Exercise for [INSERT VALUE] minutes [INSERT VALUE] times per week. Adjust level of exertion as needed in order to always maintain safety.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.PHYSICAL_ACTIVITY.titles[1]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[1]:
      return [{
        label: 'Accountability Partner',
        value: 'Patient will find a buddy and exercise with them [INSERT VALUE] times per week.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.PHYSICAL_ACTIVITY.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[16]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[17]:
      return [{
        label: 'Fitness Tracker',
        value: 'Patient will use a fitness tracker to record steps. Plan to very gradually increase step count over time',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.PHYSICAL_ACTIVITY.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.HYPERTENSION.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[4]:
      return [{
        label: 'Activity challenge 30 day',
        value: 'Patient will set a goal to slowly increase steps or activity over the course of a month.\nPatient will keep track of progress and note any challenges and obstacles along the way.\nPatient to discuss with CN during next MOC.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.SAFETY.titles[3]:
      return [{
        label: 'Environmental Exposure',
        value: 'Wear a mask when exposed to dust, mold, or chemicals in or outside of the home.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.SAFETY.titles[4]:
      return [{
        label: 'Sun Safety',
        value: 'Patient to wear skin protection (clothing, hat, sunscreen) when outside during daytime hours.\nPatient to avoid sun exposure during peak hours 10am-2pm.\nPatient will reapply sunscreen frequently throughout the day, especially after exercise or swimming.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.SAFETY.titles[5]:
      return [{
        label: 'Prevent Choking',
        value: 'Patient will eat in a relaxed atmosphere and allow plenty of time for meals.\nPatient will take small bites and chew each bite thoroughly.\nWhen eating, patient will sit upright and maintain good posture.\nAvoid talking while eating.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.SAFETY.titles[6]:
    case NON_CLINICAL_CONDITIONS_TITLES.SAFETY.titles[7]:
      return [{
        label: 'DME',
        value: 'Patient verbalizes understanding of importance of using equipment and how to use it. Patient will ask for help as needed.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.SLEEP_PATTERNS.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.ANXIETY_DISORDERS.titles[6]:
    case CLINICAL_CONDITIONS_TITLES.BENIGN_PROSTATIC_HYPERPLASIA.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.BIPOLAR_DISORDER.titles[3]:
    case CLINICAL_CONDITIONS_TITLES.DIABETES.titles[4]:
    case CLINICAL_CONDITIONS_TITLES.OBESITY.titles[8]:
      return [{
        label: 'Sleep',
        value: 'Patient will track hours of sleep per night and share with CN during MOCs.\nPatient will turn off blue light electronic devices 1 hour prior to bedtime.\nPatient will keep a consistent sleep schedule.\nPatient will keep their bedroom free of distractions such as noise, bright lights, or TV.\nPatient will relax before bed by taking a bath, reading, or listening to music.\nPatient will not consume caffeine starting early in the afternoon.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.SLEEP_PATTERNS.titles[1]:
      return [{
        label: 'Sleep challenge 30 day',
        value: 'Patient will set goal to increase the amount and/or quality of sleep over the course of one month.\nPatient will work on specific issues that are keeping them from getting adequate or quality sleep, such as getting into bed earlier and reducing screen time before bed.\nPatient will keep a daily record of progress, noting when things did not go as planned and adjusting where needed.\nShare progress/outcome with CN on next MOC call.',
      }];
    case NON_CLINICAL_CONDITIONS_TITLES.TOBACCO_USE.titles[0]:
    case NON_CLINICAL_CONDITIONS_TITLES.TOBACCO_USE.titles[1]:
    case NON_CLINICAL_CONDITIONS_TITLES.TOBACCO_USE.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.TOBACCO_USE_CLINICAL.titles[0]:
    case CLINICAL_CONDITIONS_TITLES.TOBACCO_USE_CLINICAL.titles[2]:
    case CLINICAL_CONDITIONS_TITLES.TOBACCO_USE_CLINICAL.titles[3]:
      return [{
        label: 'Tobacco Cessation',
        value: 'Patient will  come up with a plan to decrease their tobacco or vaping consumption daily and set a quit date.\nQuit date [INSERT VALUE]Patient will identify and avoid triggers (e.g. coffee or alcohol, certain social gatherings).\nIf directed by the provider, patient will utilize tobacco cessation medications.\nUnderstand that most people have several attempts before finally reaching their goal to quit. You can do it!',
      }];
    default:
      return [];
  }
};
