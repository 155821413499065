// libraries
import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
// constants
import { DATE_FORMAT, TIME_FORMAT } from '../../../../constants/constants';

const MILLIS_IN_DAY = 86400000;

export function LastVisit(props) {
  const now = moment.tz(props.timezone);
  const lastVisit = moment(props.data, DATE_FORMAT.FULL_SERVER_WITH_TIME);
  const millisInToday = (now.hours() * 60 * 60 * 1000) + (now.minutes() * 60 * 1000)
                          + (now.seconds() * 1000);
  const diff = now.diff(lastVisit);
  let result;
  if (lastVisit.isValid()) {
    if (diff < millisInToday) {
      result = `Today ${lastVisit.format(TIME_FORMAT.SHORT_TIME)}`;
    } else if (diff < (MILLIS_IN_DAY + millisInToday)) {
      result = `Yesterday ${lastVisit.format(TIME_FORMAT.SHORT_TIME)}`;
    } else {
      result = lastVisit.format(DATE_FORMAT.FULL_WITH_TIME);
    }
  } else {
    result = 'N/A';
  }

  return (
    <div className="last-login">
      {result}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    timezone: state.tenant && state.tenant.timezone,
  };
}

export default connect(mapStateToProps)(LastVisit);
