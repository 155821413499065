// libraries
import React from 'react';
import { connect } from 'react-redux';

export function Loading(props) {
  const { loading, forceLoading } = props;

  return (
    <div
      role="status"
      data-test="loading_spinner"
      className={`spinner-border text-primary ${loading || forceLoading ? 'd-block' : 'd-none'}`}
    >
      <span className="sr-only">Loading ...</span>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    loading: state.requestsInProgress.count > 0,
  };
}

export default connect(mapStateToProps)(Loading);
