// Libraries
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const ProtectedRoute = ({ children, allowed }) => {
  const { tenant: tenantUrl } = useParams();
  return allowed ? children : <Navigate to={`/${tenantUrl}/403`} />;
};

export default ProtectedRoute;
