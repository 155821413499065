// Libraries
import React, {
  useEffect, useState, Fragment, lazy, Suspense,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
// Views
import AssignPatients from '../patients/fixedListHeader/AssignPatients';
import PatientAlerts from './summary/PatientAlerts';
import PatientSummaryInfo from './summary/PatientSummaryInfo';
// Hooks
import useLoadPatientInfo from '../../hooks/services/useLoadPatientInfo';
import useLoadPatientSummary from '../../hooks/services/useLoadPatientSummary';
import useLoadAlerts from '../../hooks/services/useLoadAlerts';
// Constants
import { DEFAULT_PAGE_SIZE } from '../../constants/pageSizes';
import { PATIENT_CONTROL_TYPE, USER_ROLES } from '../../constants/constants';
// Lazy
const ChangeStatus = lazy(() => import('./summary/patientStatus/ChangeStatus'));


export const PatientSummary = () => {
  const { id: patientId } = useParams();
  const {
    user: { role: userRole }, patient: { status, assignedCareNavigator, lastStatusHistory = {} },
  } = useSelector(state => state);

  const [tabIndex, setTabIndex] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [changeStatusView, setChangeStatusView] = useState(false);

  const { refetch } = useLoadPatientInfo({ patientId, loadBillingInfo: true });
  const { refetch: refetchPatientSummary } = useLoadPatientSummary({ patientId });
  const { alertsFlightPlan } = useLoadAlerts({
    patientId, pageSize: DEFAULT_PAGE_SIZE,
  });

  const isPesUser = (userRole === USER_ROLES.PES);
  const isAdminUser = (userRole === USER_ROLES.ADMIN);
  const isCnUser = (userRole === USER_ROLES.CN);

  let assignedCareNavigatorName = 'N/A';

  if (assignedCareNavigator) {
    assignedCareNavigatorName = `${assignedCareNavigator.firstName || ''} ${assignedCareNavigator.lastName || ''}`;
  }

  const assignedCNBlock = (
    <div className="d-flex align-items-center">
      <span className="text-uppercase">Assigned CN:&nbsp;</span>
      {(isAdminUser || isPesUser || isCnUser) ? (
        <AssignPatients
          controlType={PATIENT_CONTROL_TYPE}
          selectedPatientsIds={new Set([+patientId])}
          reloadData={refetch}
        />
      ) : <span className="pl-2">{assignedCareNavigatorName}</span>}
    </div>
  );

  const patientAlerts = alertsFlightPlan.patientAlertsInfo
    && alertsFlightPlan.patientAlertsInfo.length > 0 && alertsFlightPlan.patientAlertsInfo[0]
    && alertsFlightPlan.patientAlertsInfo[0].alerts;

  useEffect(() => {
    if (alerts.length === 0 && patientAlerts && patientAlerts.length > 0) {
      setAlerts(patientAlerts);
    }
  }, [alertsFlightPlan]);

  useEffect(() => {
    if (lastStatusHistory && lastStatusHistory.newStatus) {
      if (status !== lastStatusHistory.newStatus) {
        refetchPatientSummary();
      }
    }
  }, [lastStatusHistory, status]);

  const getAlertNumber = () => (alerts.length ? `(${alerts.length})` : '');

  const mainView = (
    <Fragment>
      <div className="d-flex-center-between my-3">
        <h4 className="text-uppercase text-left mb-0" data-test="summary_headingText">Summary</h4>
        {assignedCNBlock}
      </div>
      <Tabs activeKey={tabIndex} onSelect={index => setTabIndex(index)} unmountOnExit data-test="summary_tabs">
        <Tab eventKey={0} title="Patient Summary" tabClassName="p-1 mr-2">
          <PatientSummaryInfo
            reloadPatientInfo={refetch}
            setChangeStatusView={setChangeStatusView}
          />
        </Tab>
        <Tab eventKey={1} title={`Patient Alerts ${getAlertNumber()}`} tabClassName="p-1 mr-2">
          <PatientAlerts alerts={alerts} setAlerts={setAlerts} />
        </Tab>
      </Tabs>
    </Fragment>
  );

  return (
    <div className="patient-summary h-100 overflow-auto pr-3">
      {!changeStatusView ? mainView : (
        <Suspense fallback={null}>
          <ChangeStatus setChangeStatusView={setChangeStatusView} />
        </Suspense>
      )}
    </div>
  );
};

export default PatientSummary;
