import { useField } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

export const Checkbox = ({
  label, styles = {}, validate = false, ...props
}) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: props.type || 'checkbox' });
  return (
    <Form.Group className={styles.formGroup}>
      <Form.Check
        id={props.id || props.name}
        label={label}
        name={props.name}
        onChange={props.onChange}
        className={styles.formControl}
        {...(validate && { isValid: meta.value && (meta.touched && !meta.error) })}
        {...(validate && { isInvalid: meta.error })}
        {...field}
        {...props}
        {...(meta.error && { feedback: meta.error })}
      />
      {props.description && <Form.Text muted className="ml-3 pl-1 mt-0">{props.description}</Form.Text>}
    </Form.Group>
  );
};
