import './polyfill/console';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import Main from './Main';
import './polyfill/date';
import './index.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-select/dist/react-select.css';

export default ReactDOM.render(
  <Main />,
  document.getElementById('root') || document.createElement('div'),
);
