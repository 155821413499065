import React from 'react';
import { Link, useParams } from 'react-router-dom';
// Images
import logo from '../../images/brand-logo.svg';

export function ResetPassword() {
  const { tenant: tenantUrl } = useParams();

  return (
    <div className="ccm-form-signin row justify-content-center h-100">
      <div className="col-3 d-flex-center">
        <div className="wrapper-login w-100 p-4">
          <img
            src={logo}
            alt="SelectPatient Management logo"
            data-test="login_engoodenLogo"
            className="logo d-block"
          />
          <p className="mb-4">
            To reset password please contact your organization administrator.
          </p>
          <Link to={`/${tenantUrl}`}>Sign in</Link>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
