// Libraries
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import React, { useEffect, useMemo, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import AsyncPaginate from 'react-select-async-paginate';
import Select from 'react-select';
// Services
import { fetchData, prepareCnList } from '../../../services/helpers';
import { getCcmConditions } from '../../../services/providers';
// Actions
import { CloseQaSearch, QaSearch } from '../../../actions/qaSearch';
// Constants
import {
  BILLING_STATUSES, DATE_RANGE_OPTIONS, QA_STATUSES, SEARCH_PARAMS,
} from '../../../constants/constants';
// Functions
import { getAsyncList } from '../../base/AsyncDropdown';
// Local Constants
const NONE_OPTION = { value: 'NONE', label: 'none' };

export const QaCcmFilter = () => {
  const {
    cnList, pesList, user, tenant: { tags = [] }, requestsInProgress: { count: loading },
    qaSearch: { searchParams: stateSearchParams },
  } = useSelector(state => state);
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});
  const [tagIdsList, setTagIdsList] = useState([]);
  const [ccmConditionsList, setCcmConditionsList] = useState([]);
  const dispatch = useDispatch();

  const handleSearch = (params) => {
    const newParams = _cloneDeep(params);
    Object.keys(newParams).forEach((key) => {
      if (!newParams[key]) {
        delete newParams[key];
      }
    });
    dispatch(QaSearch({ ...newParams }, true));
    dispatch(CloseQaSearch());
  };

  const debouncedHandleSearch = useMemo(() => _debounce(handleSearch, 1000), []);

  const handleSearchInputChange = (event) => {
    if (loading) {
      return;
    }

    const {
      target: {
        name, value = '', type = '', checked = null,
      } = {},
    } = event || {};

    const updatedSearchParams = {
      ...searchParams,
      [name]: type === 'checkbox' ? checked : value,
    };

    Object.keys(updatedSearchParams).forEach((key) => {
      if (!updatedSearchParams[key]) {
        delete updatedSearchParams[key];
      } else if (
        key === SEARCH_PARAMS.BILLING_REPORT_STATUS.key
        && !updatedSearchParams.billingReportStatus.trim()
      ) {
        delete updatedSearchParams.billingReportStatus;
      }
    });
    setSearchParams(updatedSearchParams);
    debouncedHandleSearch(updatedSearchParams);
  };

  const ownerList = prepareCnList([...cnList, ...pesList], user);
  const getSelectedOwner = ownerValue => ownerList.find(ph => ph.value === ownerValue) || { label: '' };

  const handleOwnerAsyncList = (input, prevOptions) => getAsyncList(
    input, prevOptions, ownerList,
  );

  const prepareBillingStatusList = () => {
    const newStatusList = [{
      value: '',
      label: 'All',
    }];

    const statuses = Object.keys(BILLING_STATUSES).sort();
    statuses.forEach((status) => {
      newStatusList.push({
        value: status,
        label: BILLING_STATUSES[status].name,
      });
    });

    return newStatusList;
  };

  const handleTagIdsAsyncList = () => {
    if (tagIdsList.length === 0) {
      const activeTags = tags.filter(el => !el.deleted);
      setTagIdsList(activeTags);
      return activeTags;
    }
    return tagIdsList;
  };

  const handleCcmAsyncList = async () => {
    if (ccmConditionsList.length === 0) {
      const ccmData = await fetchData(getCcmConditions());
      setCcmConditionsList(ccmData);
      return ccmData;
    }
    return ccmConditionsList;
  };

  const qaStatusOption = _map(QA_STATUSES, qa => ({ value: qa.type, label: qa.labelA }));

  useEffect(() => {
    handleTagIdsAsyncList();
  }, [tags]);

  useEffect(() => {
    setSearchParams(stateSearchParams);
  }, [stateSearchParams]);

  useEffect(() => {
    handleCcmAsyncList();
    return () => {
      debouncedHandleSearch.cancel();
    };
  }, []);

  return (
    <Card.Body className="pr-4">
      <Form className="text-left">
        <Form.Group controlId="formCCMAssignedCN" className="mb-2" data-test="qaCcmFilter_assignedCN">
          <Form.Label className="mb-0">Assigned CN</Form.Label>
          <AsyncPaginate
            name="assignedCn"
            className={`patientSearch_assignedCn w-100 ${stateSearchParams.careNavigatorId ? 'is-active' : ''}`}
            placeholder="CN"
            noResultsText="CN not found..."
            value={searchParams.careNavigatorId
              && getSelectedOwner(searchParams.careNavigatorId)}
            loadOptions={handleOwnerAsyncList}
            resetValue={ownerList[0]}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.value, name: 'careNavigatorId' } })}
            data-test="qaCcmFilter_assignedFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMBilling" className="mb-2" data-test="qaCcmFilter_billingStatus">
          <Form.Label className="mb-0">Billing status</Form.Label>
          <Select
            name="billingReportStatus"
            className={`patientSearch_billingStatus w-100 ${stateSearchParams.billingReportStatus ? 'is-active' : ''}`}
            placeholder="All"
            noResultsText="Status not found..."
            options={prepareBillingStatusList()}
            value={searchParams.billingReportStatus}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.value, name: 'billingReportStatus' } })}
            data-test="qaCcmFilter_billingStatusFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMCondition" className="mb-2" data-test="qaCcmFilter_tagId">
          <Form.Label className="mb-0">CCM Conditions</Form.Label>
          <Select
            multi
            simpleValue
            name="chronicConditionIds"
            className={`patientSearch_ccmCondition w-100 ${stateSearchParams.chronicConditionIds ? 'is-active' : ''}`}
            placeholder="Select..."
            valueKey="id"
            labelKey="name"
            noResultsText="CCM Condition not found..."
            options={ccmConditionsList}
            onFocus={() => handleCcmAsyncList()}
            value={searchParams.chronicConditionIds}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.split`,`.map(x => +x), name: 'chronicConditionIds' } })}
            isLoading={!!loading}
            data-test="qaCcmFilter_chronicConditionIdsFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMCondition" className="mb-2" data-test="qaCcmFilter_tagId">
          <Form.Label className="mb-0" data-test="patientSearch_attributeField">Patient Attributes</Form.Label>
          <Select
            multi
            simpleValue
            name="tagIds"
            className={`patientSearch_tagIds w-100 ${stateSearchParams.tagIds ? 'is-active' : ''}`}
            placeholder="Select..."
            valueKey="id"
            labelKey="displayName"
            noResultsText="Patient Attribute not found..."
            options={tagIdsList}
            value={searchParams.tagIds}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.split`,`.map(x => +x), name: 'tagIds' } })}
            isLoading={!!loading}
            data-test="qaCcmFilter_tagIdsFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMAssignedCN" className="mb-2" data-test="qaCcmFilter_enrollmentDate">
          <Form.Label className="mb-0">Enrollment Date</Form.Label>
          <Select
            name="enrollmentDateRange"
            className={`patientSearch_nextActionDate w-100 ${stateSearchParams.enrollmentDateRange ? 'is-active' : ''}`}
            value={searchParams.enrollmentDateRange || ''}
            options={[...DATE_RANGE_OPTIONS, NONE_OPTION]}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.value, name: 'enrollmentDateRange' } })}
            data-test="qaCcmFilter_enrollmentDateFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMAssignedCN" className="mb-2" data-test="qaCcmFilter_lastSurveyDate">
          <Form.Label className="mb-0">Last Pt Sat Survey</Form.Label>
          <Select
            name="surveyDateRange"
            className={`patientSearch_nextActionDate w-100 ${stateSearchParams.surveyDateRange ? 'is-active' : ''}`}
            value={searchParams.surveyDateRange || ''}
            options={[...DATE_RANGE_OPTIONS, NONE_OPTION]}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.value, name: 'surveyDateRange' } })}
            data-test="qaCcmFilter_lastPtFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMAssignedCN" className="mb-2" data-test="qaCcmFilter_lastQA">
          <Form.Label className="mb-0">Last QA</Form.Label>
          <Select
            name="qaDateRange"
            className={`patientSearch_nextActionDate w-100 ${stateSearchParams.qaDateRange ? 'is-active' : ''}`}
            value={searchParams.qaDateRange || ''}
            options={[...DATE_RANGE_OPTIONS, NONE_OPTION]}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.value, name: 'qaDateRange' } })}
            data-test="qaCcmFilter_lastQaFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMAssignedCN" className="mb-2" data-test="qaCcmFilter_qaStatus">
          <Form.Label className="mb-0">QA Status</Form.Label>
          <Select
            name="lastQaStatus"
            className={`patientSearch_nextActionDate w-100 ${stateSearchParams.lastQaStatus ? 'is-active' : ''}`}
            value={searchParams.lastQaStatus || ''}
            options={qaStatusOption}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.value, name: 'lastQaStatus' } })}
            data-test="qaCcmFilter_qaStatusFilter"
          />
        </Form.Group>
        <Form.Group controlId="formCCMAssignedCN" className="mb-2" data-test="qaCcmFilter_lastBilledDate">
          <Form.Label>Last Billed Date</Form.Label>
          <Select
            name="lastBilledDateRange"
            className={`patientSearch_nextActionDate w-100 ${stateSearchParams.lastBilledDateRange ? 'is-active' : ''}`}
            value={searchParams.lastBilledDateRange || ''}
            options={[...DATE_RANGE_OPTIONS, NONE_OPTION]}
            onChange={ev => handleSearchInputChange({ target: { value: ev && ev.value, name: 'lastBilledDateRange' } })}
            data-test="qaCcmFilter_lastBilledDateRange"
          />
        </Form.Group>
      </Form>
    </Card.Body>
  );
};

export default QaCcmFilter;
