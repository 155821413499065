// Helpers
import { getTenant } from '../helpers';
// Config
import { store } from '../../config/store';
import { History } from '../../config/history';
// Services
import { checkUserInStorage, clearUserFromStorage } from '../userStorage';
import { makeGlobalAuthorizedRequest } from './requests';
// Actions
import { Logout as clearUserFromRedux } from '../../actions/user';
// Constants
import { CONFIG_NAME, INIT_HASHTAG } from '../../constants/globalAdminUi';

export function backendGlobalLogout() {
  return makeGlobalAuthorizedRequest('PUT', '/me/logout');
}

export function backendSignIn() {
  return makeGlobalAuthorizedRequest('PUT', '/me/signin');
}

export function getGlobalUser() {
  return makeGlobalAuthorizedRequest('GET', '/globalusers/me/info');
}

export function logoutGlobalUser(keycloak = null, redirectToRoot = true) {
  let tenantUrl = getTenant();
  tenantUrl = tenantUrl === CONFIG_NAME ? INIT_HASHTAG : tenantUrl;

  const logoutUserFromBackAndApp = () => new Promise((resolve) => {
    if (checkUserInStorage(tenantUrl)) {
      clearUserFromStorage(tenantUrl);
      store.dispatch(clearUserFromRedux());
      return resolve();
    }

    return resolve();
  });

  logoutUserFromBackAndApp().then(() => {
    if (keycloak && keycloak.authenticated) {
      keycloak.logout({
        ...(redirectToRoot && { redirectUri: `${window.location.origin}/#/${tenantUrl}` }),
      });
    } else if (redirectToRoot) History.push(`/${tenantUrl}`);
  }).catch(() => {
  });
}
