// Libraries
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { downloadFile } from '../../services/helpers';
import ErrorBuilder from '../../services/errorMessageBuilder';
import { getReports, downloadReport } from '../../services/reports';
// Constants
import { NOTIFICATION_TYPE, DATE_FORMAT } from '../../constants/constants';

const REPORTS_LIMIT = 15;

function downloadReportFile(reportId, showNotification) {
  const downloadReportRequest = downloadReport(reportId);
  const downloadReportPromise = downloadReportRequest.promise;

  return downloadReportPromise.then((report) => {
    downloadFile(report.data, report.fileName);
  }).catch((error) => {
    if (error.isCanceled || error.status === 401 || error.status === 403) {
      return;
    }

    showNotification({
      message: ErrorBuilder(error.data),
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
  }).finally(() => delete downloadReportRequest.promise);
}

export const Enrollment = (props) => {
  const { userPermissions, showNotification } = props;

  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (userPermissions.canViewReports) {
      const getReportsRequest = getReports(REPORTS_LIMIT);
      const getReportsPromise = getReportsRequest.promise;

      getReportsPromise.then((data) => {
        setReports(data);
      }).catch((error) => {
        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }

        showNotification({
          message: ErrorBuilder(error.data),
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      }).finally(() => delete getReportsRequest.promise);
    }
  }, []);

  if (!userPermissions.canViewReports) {
    return null;
  }

  const renderReportsRows = () => {
    if (reports.length > 0) {
      return reports.map(report => (
        <tr key={`report_${report.id}`} data-test="enrollment_reportRow">
          <td
            {...(userPermissions.canDownloadReports
                && { onClick: () => downloadReportFile(report.id, showNotification) })}
            data-test="enrollment_downloadReport"
          >
            {report.fileName}
          </td>
          <td
            {...(userPermissions.canDownloadReports
                && { onClick: () => downloadReportFile(report.id, showNotification) })}
            data-test="enrollment_downloadReport"
          >
            {moment(report.generatedAt, DATE_FORMAT.FULL_SERVER)
              .format(DATE_FORMAT.FULL)}
          </td>
          <td
            {...(userPermissions.canDownloadReports
                && { onClick: () => downloadReportFile(report.id, showNotification) })}
            data-test="enrollment_downloadReport"
          >
            <i className="bi-download mr-2" />
          </td>
        </tr>
      ));
    }
    return (
      <tr data-test="enrollment_emptyMsg">
        <td colSpan="3" className="p-2 border-0">No enrollment reports</td>
      </tr>
    );
  };

  return (
    <div className="reports-enrollment h-100 overflow-auto pr-3">
      <div className="reports-header d-flex align-items-center">
        <h4
          className="text-left my-3"
          data-test="enrollment_headingText"
        >
          Enrollment Reports
        </h4>
        <span
          className="ml-auto"
          data-test="enrollment_resultsInfo"
        >
          {`Results: ${reports.length}`}
        </span>
      </div>
      <div className="reports-content ccm-table-container px-0">
        <table className="table table-hover text-left">
          <thead>
            <tr>
              <th className="py-2">Report name</th>
              <th className="py-2">Date</th>
              <th className="py-2" />
            </tr>
          </thead>
          <tbody>
            {renderReportsRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userPermissions: state.user.permissions || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Enrollment);
