// Libraries
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
// Constants
import {
  USER_ROLES, SORTING_OPTIONS, TENANT_FEATURES_NAMES,
} from '../../../constants/constants';
// Views
import PatientsSelect from './PatientsSelect';

export const PatientTableHeader = (props) => {
  const {
    sort, loading, user, user: { isExternal: isExternalUser = true } = {},
    tenant: { features },
  } = props;
  const isFirstRender = useRef(true);

  const [sortParams, setSortParams] = useState({
    key: sort ? sort.key : null,
    reverse: sort ? !!sort.reverse : false,
  });

  const handleSortKey = (key) => {
    if (key === sortParams.key) {
      if (!sortParams.reverse) {
        setSortParams({
          ...sortParams,
          reverse: true,
        });
      } else {
        setSortParams({
          key: null,
          reverse: false,
        });
      }
    } else {
      setSortParams({
        key,
        reverse: false,
      });
    }
  };

  const handleSortPatients = () => {
    const { sortPatients } = props;


    sortPatients(sortParams);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSortPatients();
  }, [sortParams]);

  useEffect(() => {
    if (sort && sort.key !== sortParams.key) {
      setSortParams({
        ...sortParams,
        key: sort.key,
      });
    } else if (sort && sort.reverse !== sortParams.reverse) {
      setSortParams({
        ...sortParams,
        reverse: sort.reverse,
      });
    }
  }, [sort]);

  const renderActionSelect = () => {
    const isAdminUser = (user.role && user.role === USER_ROLES.ADMIN);

    if (isAdminUser) {
      const selectedPatientsCount = props.selectedPatientsIds ? props.selectedPatientsIds.size : 0;
      return (
        <th className="patientCol__actionSelect px-2">
          <PatientsSelect
            selectAllOnPageCallback={props.selectAllOnPageCallback}
            selectPatientsCallback={props.selectPatientsCallback}
            unSelectCallback={props.unSelectCallback}
            selectedPatientsCount={selectedPatientsCount}
          />
        </th>
      );
    }
    return null;
  };

  const showRafScores = features
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.SHOW_RAF_SCORES.name).enabled;
  const isAdminUser = user.role === USER_ROLES.ADMIN;

  const caretSort = <i className={`d-flex-center bi-caret-${sortParams.reverse ? 'up' : 'down'}-fill ml-1`} />;
  const patientConditionHeaderName = isExternalUser ? 'CCM Categories' : 'Patient Attributes';

  return (
    <thead>
      <tr>
        <th className="patientCol__status p-0" />
        <th className="patientCol__info">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.FULL_NAME)}
            data-test="patientTableHeader_fullNameSort"
          >
            Name
            {sortParams.key === SORTING_OPTIONS.FULL_NAME && caretSort}
          </Button>
        </th>
        <th className="patientCol__conditions" data-test="patient_attributeTableHeader">{patientConditionHeaderName}</th>
        <th className="patientCol__assignedCn">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.CN_FULL_NAME)}
            data-test="patientTableHeader_cnFullNameSort"
          >
            Assigned
            {sortParams.key === SORTING_OPTIONS.CN_FULL_NAME && caretSort}
          </Button>
        </th>
        <th className="hidden patientCol__calls">Calls</th>
        <th className="patientCol__lastPcp">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.NEXT_ACTION_DATE)}
            data-test="patientTableHeader_nextActionDateSort"
          >
            Next Outreach Date
            {sortParams.key === SORTING_OPTIONS.NEXT_ACTION_DATE && caretSort}
          </Button>
        </th>
        {showRafScores && isAdminUser && (
          <th className="patientCol__rafUplift">
            <Button
              variant="link"
              disabled={loading}
              className="d-flex-center mx-auto"
              onClick={() => handleSortKey(SORTING_OPTIONS.POTENTIAL_RAF_UPLIFT)}
              data-test="patientTableHeader_potentialRafUplift"
            >
              Potential Raf Uplift
              {sortParams.key === SORTING_OPTIONS.POTENTIAL_RAF_UPLIFT && caretSort}
            </Button>
          </th>
        )}
        <th className="patientCol__monthlyCalls">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.CALL_ATTEMPTS)}
            data-test="patientTableHeader_monthlyCalls"
          >
            Monthly Calls
            {sortParams.key === SORTING_OPTIONS.CALL_ATTEMPTS && caretSort}
          </Button>
        </th>
        <th className="patientCol__totalCcm">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.TOTAL_CCM_MTD)}
            data-test="patientTableHeader_totalCCMSort"
          >
            Total CCM MTD
            {sortParams.key === SORTING_OPTIONS.TOTAL_CCM_MTD && caretSort}
          </Button>
        </th>
        {renderActionSelect()}
      </tr>
    </thead>
  );
};

function mapStateToProps(state) {
  return {
    tenant: state.tenant,
    user: state.user,
    sort: state.search.sortParams,
    loading: state.requestsInProgress.count > 0,
  };
}

export default connect(mapStateToProps)(PatientTableHeader);
