// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import { UpdatePatient } from '../../actions/patient';
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getPatientSummary } from '../../services/patient';

const useLoadPatientSummary = ({ patientId }) => {
  const [patientSummary, setPatientSummary] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPatientSummary = useCallback(async () => {
    const getPatientSummaryRequest = getPatientSummary(patientId);
    const getPatientSummaryPromise = getPatientSummaryRequest.promise;
    try {
      const data = await getPatientSummaryPromise;
      setPatientSummary(data);
      dispatch(UpdatePatient(data));
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patient summary.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [patientId]);

  useEffect(() => {
    fetchPatientSummary();
  }, [fetchPatientSummary]);

  return { patientSummary, refetch: fetchPatientSummary };
};

export default useLoadPatientSummary;
