// libraries
import React, {
  Component, useContext, useEffect, Fragment,
} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Select, { Async } from 'react-select';
import _debounce from 'lodash/debounce';
import DatePicker from 'react-datepicker';
import AsyncPaginate from 'react-select-async-paginate';
import {
  Accordion, Card, Form, Button, OverlayTrigger, Popover,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';
// actions
import { CloseSearch, Search, ResetSideBarSearch } from '../../actions/search';
// constants
import { QUICK_FILTERS } from '../../constants/quickFilters';
import {
  DATE_FORMAT, SEARCH_PARAMS, BILLING_STATUSES, NEXT_ACTION_OPTIONS, PCP_VISIT_MONTH_OPTIONS,
  PATIENT_CALL_TIME_OPTIONS, USER_ROLES, OUTREACH_TYPE_OPTIONS, DIGITAL_PHONE_LENGTH,
  INELIGIBLE_REASONS, DECLINE_BY_CARE_PROVIDER_REASONS, DECLINE_BY_PATIENT_REASONS,
  DISENROLLMENT_REASONS, E_STATUS_REASONS, INACTIVE_REASONS, MONTHLY_CALL_OPTIONS,
  TENANT_FEATURES_NAMES,
} from '../../constants/constants';
// services
import {
  formatPhone, formatPhoneForRequest, formatPhoneOnlyDigits,
  isObjectsEqual, validatePhone, fetchData,
} from '../../services/helpers';
import {
  getPhysician, getInsurancesTypeahead, getPhysicianTypeahead,
  getLocations, getCcmConditions, getTelehealthProviders,
} from '../../services/providers';
import { getLanguageCodes } from '../../services/patientList';
// functions
import { getAsyncList } from '../base/AsyncDropdown';
import { SUSPEND_STATUSES } from '../../constants/statuses';

const PHONE_LENGTH = 15;


export class PatientSearch extends Component {
  constructor(props) {
    super(props);

    const { searchParams: { phoneNumber = '' } = {}, searchParams = {} } = props;

    this.state = {
      searchParams,
      inputFocusPosition: 0,
      phoneNumber: formatPhone(phoneNumber),
      physicianValue: null,
      primaryInsuranceValue: searchParams.primaryInsurance
        ? { value: searchParams.primaryInsurance, label: searchParams.primaryInsurance } : null,
      secondaryInsuranceValue: searchParams.secondaryInsurance
        ? { value: searchParams.secondaryInsurance, label: searchParams.secondaryInsurance } : null,
      languagesList: null,
      pcpLocationsList: null,
      ccmConditionsList: null,
      noResultsText: 'No results found...',
      tagIdsList: null,
    };

    this.promises = {};

    this.handleSubmitDebounce = _debounce(this.handleSubmit, 500);
  }

  componentDidMount() {
    const { searchParams = {} } = this.props;
    if (searchParams.languages && !!searchParams.languages.length) {
      this.handleLanguagesAsyncList();
    }
    if (searchParams.chronicConditionIds && !!searchParams.chronicConditionIds.length) {
      this.handleCcmAsyncList();
    }
    if (searchParams.primaryPhysicianLocationId) {
      this.handleLocationsAsyncList();
    }
    if (searchParams.physicianId) {
      this.loadPhysicianData(searchParams.physicianId);
    }
    if (searchParams.tagIds && !!searchParams.tagIds.length) {
      this.handleTagIdsAsyncList();
    }
    if (searchParams.telehealthProviders) {
      this.handleTelehealthProvidersAsyncList();
    }
  }

  componentDidUpdate(prevProps) {
    const { searchParams, tags } = this.props;
    const { tagIdsList } = this.state;
    const searchParamsCloned = { ...searchParams };
    if (!isObjectsEqual(searchParamsCloned, prevProps.searchParams)) {
      let newSearchParams;
      if (searchParamsCloned.status !== 'CS' && searchParamsCloned.status !== 'PS') {
        delete searchParamsCloned.suspendReason;
      }
      if (this.props.isSearchParamsOverwritten) {
        newSearchParams = { ...searchParamsCloned };
      } else {
        newSearchParams = {
          ...searchParamsCloned,
          status: searchParamsCloned.status,
        };
      }

      this.setState({
        searchParams: newSearchParams,
        phoneNumber: formatPhone(searchParamsCloned.phoneNumber),
        ...(!searchParamsCloned.physicianId && { physicianValue: null }),
        ...(!searchParamsCloned.primaryInsurance && { primaryInsuranceValue: null }),
        ...(!searchParamsCloned.secondaryInsurance && { secondaryInsuranceValue: null }),
      });
    }

    if (tagIdsList && tagIdsList.length === 0 && tags && tags.length > 0) {
      this.handleTagIdsAsyncList();
    }
  }

  componentWillUnmount() {
    this.handleSubmitDebounce.cancel();
  }

  closeSearch = () => {
    const { closeSearch } = this.props;
    closeSearch();
  }

  resetSideBarSearch = () => {
    const { resetSideBarSearch, loading } = this.props;
    if (loading) {
      return;
    }
    resetSideBarSearch();
  };

  loadPhysicianData = async (physicianId) => {
    const physicianData = await fetchData(getPhysician(physicianId));
    this.setState({
      physicianValue: {
        value: physicianData.id,
        label: `${physicianData.lastName ? `${physicianData.lastName}, ` : ''}${physicianData.firstName ? physicianData.firstName : ''}`,
      },
    });
  };

  onFocusPhoneNumber = (event) => {
    let { inputFocusPosition } = this.state;
    const { phoneNumber } = this.state;
    const input = event.target;
    const inputValue = input.value;

    if (!event || !event.target || !phoneNumber || !inputValue) {
      return;
    }

    const isCursorShifted = (phoneNumber !== inputValue)
      && (phoneNumber.length <= inputValue.length);

    if (isCursorShifted) {
      // Compare the phone number in state and the phone number in input to the focus position
      for (let i = 0, j = 0; i < inputFocusPosition; i++, j++) {
        const phoneNumberDigit = phoneNumber.charAt(i);

        // If the characters are not equal and the character is not a number
        if (phoneNumberDigit !== inputValue.charAt(j)
          && !Number.isNaN(+phoneNumberDigit)) {
          // Decrease 'i' to compare with the next character
          i -= 1;
          // Shift cursor position
          inputFocusPosition++;
        }
      }
    }

    // Set cursor position
    input.setSelectionRange(inputFocusPosition, inputFocusPosition);
    // Set the number of pixels scrolled
    input.scrollLeft = input.scrollWidth;
  };

  changePhoneNumber = (event) => {
    let valid = false;
    let { value } = event.target;
    const { selectionStart } = event.target;

    // Spaces and (+1) indicator are removed
    value = value.replace(/\s/g, '');
    value = value.replace('+1', '');

    if (value.length >= PHONE_LENGTH) {
      value = value.slice(0, -1);
    }

    if (value !== '*') {
      valid = validatePhone(value);
    }

    const digitalValue = formatPhoneOnlyDigits(value);
    const searchParams = {
      ...this.state.searchParams,
      phoneNumber: digitalValue && digitalValue.length === DIGITAL_PHONE_LENGTH
        ? formatPhoneForRequest(value)
        : value,
      phoneNumberValidate: value.length !== PHONE_LENGTH ? valid : false,
    };

    Object.keys(searchParams).forEach((key) => {
      if (!searchParams[key]) {
        delete searchParams[key];
      }
    });

    this.setState({
      searchParams,
      inputFocusPosition: selectionStart,
      phoneNumber: digitalValue,
    }, this.handleSubmitDebounce);
  };

  handleSearchInputChange = (event) => {
    const {
      target: {
        name, value = '', type = '', checked = null,
      } = {},
    } = event || {};

    const searchParams = {
      ...this.state.searchParams,
      [name]: type === 'checkbox' ? checked : value,
    };

    Object.keys(searchParams).forEach((key) => {
      if (!searchParams[key]) {
        delete searchParams[key];
      } else if (searchParams.statusReason
        && searchParams.statusReason.length === 0) {
        delete searchParams.statusReason;
      } else if (searchParams.billingReportStatus
        && searchParams.billingReportStatus.length === 0) {
        delete searchParams.billingReportStatus;
      }
    });

    this.setState({
      searchParams,
    }, this.handleSubmitDebounce);
  }

  handleSubmit = () => {
    const { searchParams } = this.state;
    if (this.props.loading || searchParams.phoneNumberValidate) {
      return;
    }


    const { search } = this.props;

    Object.keys(searchParams).forEach((key) => {
      if (!searchParams[key]) {
        delete searchParams[key];
      }
    });

    search({ ...searchParams }, true);
    this.closeSearch();
  }

  prepareCnList = (cnList = []) => {
    const { user } = this.props;

    const newCnList = [];
    newCnList.push({
      value: '',
      label: 'All patients',
    });

    if (user.role !== USER_ROLES.ADMIN) {
      newCnList.push({
        value: user.id,
        label: 'My patients',
      });
    }

    newCnList.push({
      value: '0',
      label: 'Unassigned',
    });

    newCnList.push({
      value: -1,
      label: 'Assigned to Disabled User',
    });

    cnList.forEach((cn) => {
      if (user.id === cn.id) {
        return;
      }
      newCnList.push({
        ...cn,
        value: cn.id,
        label: `${cn.lastName ? `${cn.lastName}, ` : ''}${cn.firstName ? cn.firstName : ''}`,
      });
    });

    return newCnList;
  }

  prepareInsurancesList = (insuranceData = []) => {
    const insuranceList = [];

    insuranceData.forEach((el) => {
      insuranceList.push({
        ...el,
        value: el.planId,
        label: el.companyName,
      });
    });

    insuranceList.sort((a, b) => a.label.localeCompare(b.label));

    return insuranceList;
  }

  preparePhysiciansList = (physiciansData = []) => {
    const physiciansList = [];

    physiciansData.forEach((el) => {
      physiciansList.push({
        ...el,
        value: el.id,
        label: `${el.lastName ? `${el.lastName}, ` : ''}${el.firstName ? el.firstName : ''}`,
      });
    });

    physiciansList.sort((a, b) => a.label.localeCompare(b.label));

    return physiciansList;
  }

  prepareBillingStatusList = () => {
    const newStatusList = [{
      value: '',
      label: 'All',
    }];

    const statuses = Object.keys(BILLING_STATUSES).sort();
    statuses.forEach((status) => {
      newStatusList.push({
        value: status,
        label: BILLING_STATUSES[status].name,
      });
    });

    return newStatusList;
  }

  prepareStatusReasonsList = () => {
    const { searchParams } = this.state;
    let newReasonsList = [];
    const suspendReasonMapped = Object.keys(SUSPEND_STATUSES.suspendReasons)
      .map(key => SUSPEND_STATUSES.suspendReasons[key]);
    const ineligibleReasonsMapped = INELIGIBLE_REASONS.map(reason => reason.label);
    const inactiveReasonsMapped = INACTIVE_REASONS.map(reason => reason.label);

    switch (searchParams.status) {
      case 'PS':
      case 'CS':
        newReasonsList = suspendReasonMapped;
        break;
      case 'I':
        newReasonsList = [...ineligibleReasonsMapped, ...inactiveReasonsMapped, 'Deceased'];
        break;
      case 'DC':
        newReasonsList = DECLINE_BY_CARE_PROVIDER_REASONS;
        break;
      case 'DP':
        newReasonsList = DECLINE_BY_PATIENT_REASONS;
        break;
      case 'X':
        newReasonsList = DISENROLLMENT_REASONS.map(reason => reason.title);
        break;
      case 'E':
        newReasonsList = E_STATUS_REASONS;
        break;
      default: {
        const defaultList = [
          ...E_STATUS_REASONS,
          ...DISENROLLMENT_REASONS.map(reason => reason.title),
          ...DECLINE_BY_PATIENT_REASONS,
          ...DECLINE_BY_CARE_PROVIDER_REASONS,
          ...ineligibleReasonsMapped,
          ...inactiveReasonsMapped,
          ...suspendReasonMapped,
        ];
        newReasonsList = defaultList.filter(
          (reason, index) => defaultList.indexOf(reason) === index,
        );
      }
    }

    newReasonsList = newReasonsList.map(reason => ({ value: reason, label: reason }));

    newReasonsList.push({
      value: '',
      label: 'All',
    });

    return newReasonsList
      .sort((a, b) => ((a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : -1));
  }

  handlePhysiciansAsyncList = async (input) => {
    if (input && input.length > 1) {
      const physiciansData = await fetchData(getPhysicianTypeahead(
        { filter: input },
      ));
      return { options: this.preparePhysiciansList(physiciansData) };
    }
    return { options: [] };
  };

  handleInsuranceAsyncList = async (input, type = 'PRIMARY') => {
    if (input && input.length > 1) {
      const insuranceData = await fetchData(getInsurancesTypeahead(input, type));
      return { options: this.prepareInsurancesList(insuranceData) };
    }
    return { options: [] };
  };

  handleCcmAsyncList = async () => {
    const { ccmConditionsList } = this.state;
    if (!ccmConditionsList) {
      this.setState({ ccmConditionsLoading: true });
      const ccmData = await fetchData(getCcmConditions());
      this.setState({ ccmConditionsList: ccmData, ccmConditionsLoading: false });
      return ccmData;
    }
    return ccmConditionsList;
  };

  handleLanguagesAsyncList = async () => {
    const { languagesList } = this.state;
    if (!languagesList) {
      this.setState({ languageLoading: true });
      const languagesData = await fetchData(getLanguageCodes());
      this.setState({ languagesList: languagesData, languageLoading: false });
      return languagesData;
    }
    return languagesList;
  };

  handleLocationsAsyncList = async () => {
    const { pcpLocationsList } = this.state;
    if (!pcpLocationsList) {
      this.setState({ locationLoading: true });
      const locationsData = await fetchData(getLocations());
      this.setState({ pcpLocationsList: locationsData, locationLoading: false });
      return locationsData;
    }
    return pcpLocationsList;
  };

  handleTagIdsAsyncList = () => {
    const { tagIdsList } = this.state;
    const { tags } = this.props;
    if (!tagIdsList || (tagIdsList && tagIdsList.length === 0)) {
      this.setState({ tagIdsLoading: true });
      const activeTags = tags ? tags.filter(el => !el.deleted) : [];
      this.setState({ tagIdsList: activeTags, tagIdsLoading: false });
      return activeTags;
    }
    return tagIdsList;
  };

  handleTelehealthProvidersAsyncList = async () => {
    const { telehealthProvidersList } = this.state;
    if (!telehealthProvidersList) {
      this.setState({ telehealthProvidersLoading: true });
      const telehealthProviderData = await fetchData(getTelehealthProviders());
      this.setState({
        telehealthProvidersList: telehealthProviderData,
        telehealthProvidersLoading: false,
      });
      return telehealthProviderData;
    }
    return telehealthProvidersList;
  };

  renderEnabledFiltersInfo(getSelectedOwner) {
    const { searchParams: { ...enabledFilters } } = this.props;
    const {
      ccmConditionsList, tagIdsList, physicianValue,
      pcpLocationsList, telehealthProvidersList,
    } = this.state;

    const updatedFilters = Object.assign({}, enabledFilters);
    if (updatedFilters.quickFilters
        && Object.values(updatedFilters.quickFilters).every(item => !item)) {
      delete updatedFilters.quickFilters;
    }

    const parseKey = key => _startCase(key).replace(/\s+/g, '_').toUpperCase();

    const renderFiltersCount = count => <span className="filters-badge d-flex-center rounded-circle">{count}</span>;

    const getFiltersLabel = Object.entries(updatedFilters).map(([key, value]) => {
      let valueToShow = '';

      switch (key) {
        case SEARCH_PARAMS.CARE_NAVIGATOR_ID.key:
          valueToShow = getSelectedOwner(value).label;
          break;
        case SEARCH_PARAMS.CHRONIC_CONDITION_IDS.key:
          if (ccmConditionsList) {
            const conditions = [];
            value.forEach((val) => {
              conditions.push(
                ccmConditionsList.find(ccmCondition => ccmCondition.id === val).name,
              );
            });
            valueToShow = conditions.join(', ');
          }
          break;
        case SEARCH_PARAMS.TAG_IDS.key:
          if (tagIdsList && tagIdsList.length > 0) {
            const conditions = [];
            value.forEach((val) => {
              conditions.push(
                tagIdsList.find(tag => tag.id === val).displayName,
              );
            });
            valueToShow = conditions.join(', ');
          }
          break;
        case SEARCH_PARAMS.PHYSICIAN_ID.key:
          if (physicianValue) {
            valueToShow = physicianValue.label;
          }
          break;
        case SEARCH_PARAMS.PRIMARY_PHYSICIAN_LOCATION_ID.key:
          if (pcpLocationsList) {
            valueToShow = pcpLocationsList.find(location => location.id === value).name;
          }
          break;
        case SEARCH_PARAMS.QUICK_FILTERS.key:
          if (Object.values(value).some(item => item)) {
            const filter = Object.keys(value).filter(item => value[item])[0];
            valueToShow = QUICK_FILTERS[filter].name;
          }
          break;
        case SEARCH_PARAMS.EHR_IDS.key:
        case SEARCH_PARAMS.PATIENT_IDS.key:
          valueToShow = '';
          break;
        case SEARCH_PARAMS.TELEHEALTH_PROVIDERS.key:
          if (telehealthProvidersList) {
            const telehealthProviders = [];
            value.forEach((val) => {
              telehealthProviders.push(
                telehealthProvidersList.find(provider => provider.code === val).code,
              );
            });
            valueToShow = telehealthProviders.join(', ');
          }
          break;
        default:
          valueToShow = `${_capitalize(_startCase(value))}`;
      }

      return (
        <p key={`filter__${key}`} className="mb-1">
          {`${SEARCH_PARAMS[parseKey(key)] ? SEARCH_PARAMS[parseKey(key)].label : key}${valueToShow ? `: ${valueToShow}` : ''}`}
        </p>);
    });

    const getFiltersPopover = (
      <Popover>
        <Popover.Title><b>Enabled filters</b></Popover.Title>
        <Popover.Content>
          {!Object.keys(updatedFilters).length ? 'No filters enabled' : getFiltersLabel}
        </Popover.Content>
      </Popover>);

    const cleanFiltersPopover = (
      <Popover>
        <Popover.Content>Clear sidebar filters</Popover.Content>
      </Popover>);

    return (
      <Fragment>
        <OverlayTrigger trigger={['hover', 'click']} placement="top" overlay={cleanFiltersPopover}>
          <Button
            variant="link"
            className={`position-relative p-1 ${!Object.keys(updatedFilters).length ? 'inactive' : ''}`}
            onClick={this.resetSideBarSearch}
            disabled={!(Object.keys(updatedFilters).filter(filter => filter !== 'status').length > 0)}
          >
            <i className={`bi-funnel${!Object.keys(updatedFilters).length ? '' : '-fill'}`} />
            <i className={`clean-filters-badge d-flex-center bi bi-x-circle-fill ${Object.keys(updatedFilters).filter(filter => filter !== 'status').length > 0 ? 'text-ccm-red' : 'text-ccm-gray'}`} />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger trigger={['hover', 'click']} placement="right" overlay={getFiltersPopover}>
          <Button
            variant="link"
            className={`position-relative p-1 ${!Object.keys(updatedFilters).length ? 'inactive' : ''}`}
          >
            <i data-test="patientSearch_sidebarFiltersBtn" className={`bi-funnel${!Object.keys(updatedFilters).length ? '' : '-fill'}`} />
            {renderFiltersCount(
              !Object.keys(updatedFilters).length ? 0 : Object.keys(updatedFilters).length,
            )}
          </Button>
        </OverlayTrigger>
      </Fragment>);
  }

  render() {
    const {
      searchParams,
      searchParams: { phoneNumberValidate },
      noResultsText,
      languagesList,
      pcpLocationsList,
      ccmConditionsList,
      physicianValue,
      primaryInsuranceValue,
      secondaryInsuranceValue,
      tagIdsList,
      telehealthProvidersList,
      languageLoading,
      locationLoading,
      telehealthProvidersLoading,
      ccmConditionsLoading,
      tagIdsLoading,
    } = this.state;

    const {
      cnList, pesList, searchParams: initialSearchParams = {}, handlePanelOpened, isOpened, loading,
      user: { isExternal: isExternalUser = true }, isTelehealthGroupEnabled,
    } = this.props;

    const ownerList = this.prepareCnList([...cnList, ...pesList]);
    const handleOwnerAsyncList = (input, prevOptions) => getAsyncList(
      input, prevOptions, ownerList,
    );

    const handleCallTimeOptions = () => {
      const callTimeOptions = [];
      PATIENT_CALL_TIME_OPTIONS.forEach((opt) => {
        callTimeOptions.push({
          value: opt.value,
          label: `${opt.name} ${opt.extraLabel}`,
        });
      });
      return callTimeOptions;
    };

    const getSelectedOwner = ownerValue => ownerList.find(ph => ph.value === ownerValue) || { label: '' };

    const phoneValidationLabel = phoneNumberValidate
      && <small id="phoneNumberFeedback" className="form-text text-danger">{phoneNumberValidate}</small>;

    return (
      <div className="ccm-search-wrapper d-flex flex-column h-100">
        <Button
          size="lg"
          variant="link"
          className="mb-0"
          onClick={handlePanelOpened}
          data-test="patientSearch_searchMenu"
        >
          <i className="bi-list ml-1" />
        </Button>
        {isOpened
          && (<Button
            size="sm"
            variant="link"
            data-for="tooltip-patientSearch"
            data-tip='Use "*" as a wildcard in the text fields to see all patients with any value in the corresponding parameter'
          >
            <i className="bi-info-circle-fill" />
          </Button>
          )
        }
        <Accordion className="w-100 flex-grow-1 overflow-auto">
          <Card className="border-0">
            <ContextAccordionHeader eventKey="0" isOpened={isOpened} openPanel={handlePanelOpened}>
              <i className="card-header-icon bi-person-fill mr-2" data-test="patientSearch_patientCard" />
              {isOpened && <span className="card-header-title" data-test="patientSearch_patientMenu">Patient</span>}
            </ContextAccordionHeader>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="pr-4">
                <Form className="text-left">
                  <Form.Group controlId="formPatientFirstName" className="mb-2">
                    <Form.Label className="mb-0">First name</Form.Label>
                    <Form.Control type="text" name="firstName" onChange={this.handleSearchInputChange} value={searchParams.firstName || ''} data-test="firstname-field" className={`${initialSearchParams.firstName ? 'is-active' : ''}`} />
                  </Form.Group>
                  <Form.Group controlId="formPatientLastName" className="mb-2">
                    <Form.Label className="mb-0">Last name</Form.Label>
                    <Form.Control type="text" name="lastName" onChange={this.handleSearchInputChange} value={searchParams.lastName || ''} data-test="lastname-field" className={`${initialSearchParams.lastName ? 'is-active' : ''}`} />
                  </Form.Group>
                  <Form.Group controlId="formPatientID" className="mb-2">
                    <Form.Label className="mb-0">Patient id</Form.Label>
                    <Form.Control type="text" name="externalId" onChange={this.handleSearchInputChange} value={searchParams.externalId || ''} data-test="id-field" className={`${initialSearchParams.externalId ? 'is-active' : ''}`} />
                  </Form.Group>
                  <Form.Group controlId="formPatientDOB" className="mb-2">
                    <Form.Label className="mb-0">Date of birth</Form.Label>
                    <DatePicker
                      name="dateOfBirth"
                      className="form-control"
                      selected={(searchParams && searchParams.dateOfBirth
                          && new Date(searchParams.dateOfBirth)) || null}
                      onChange={date => this.handleSearchInputChange({ target: { value: moment(date).isValid() && moment(date).format(DATE_FORMAT.FULL), name: 'dateOfBirth' } })}
                      data-test="dob-field"
                    />
                  </Form.Group>
                  <Form.Group controlId="formPatientPhoneNumber" className="mb-2">
                    <Form.Label className="mb-0" aria-describedby="phoneNumberFeedback">Phone number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      onChange={this.changePhoneNumber}
                      onFocus={this.onFocusPhoneNumber}
                      value={(searchParams.phoneNumber && formatPhone(searchParams.phoneNumber, true)) || ''}
                      isInvalid={searchParams.phoneNumberValidate}
                      className={`${initialSearchParams.phoneNumber ? 'is-active' : ''}`}
                      data-test="phone-field"
                    />
                    {phoneValidationLabel}
                  </Form.Group>
                  <Form.Group controlId="formPatientPrimaryInsurance" className="mb-2">
                    <Form.Label className="mb-0">Primary insurance</Form.Label>
                    <Async
                      name="primaryInsurance"
                      className={`patientSearch_primaryInsurance w-100 ${initialSearchParams.primaryInsurance ? 'is-active' : ''}`}
                      placeholder=""
                      noResultsText={noResultsText}
                      onInputChange={input => (input.length < 2 ? this.setState({ noResultsText: 'Type 2 characters min' }) : this.setState({ noResultsText: 'No results found...' }))}
                      loadOptions={input => this.handleInsuranceAsyncList(input, 'PRIMARY')}
                      value={primaryInsuranceValue}
                      onChange={(ev) => {
                        this.setState({ primaryInsuranceValue: ev });
                        this.handleSearchInputChange({ target: { value: ev && ev.label, name: 'primaryInsurance' } });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formPatientSecondaryInsurance" className="mb-2">
                    <Form.Label className="mb-0">Secondary insurance</Form.Label>
                    <Async
                      name="secondaryInsurance"
                      className={`patientSearch_secondaryInsurance w-100 ${initialSearchParams.secondaryInsurance ? 'is-active' : ''}`}
                      placeholder=""
                      noResultsText={noResultsText}
                      onInputChange={input => (input.length < 2 ? this.setState({ noResultsText: 'Type 2 characters min' }) : this.setState({ noResultsText: 'No results found...' }))}
                      loadOptions={input => this.handleInsuranceAsyncList(input, 'SECONDARY')}
                      value={secondaryInsuranceValue}
                      onChange={(ev) => {
                        this.setState({ secondaryInsuranceValue: ev });
                        this.handleSearchInputChange({ target: { value: ev && ev.label, name: 'secondaryInsurance' } });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formLanguage" className="mb-2">
                    <Form.Label className="mb-0">Language</Form.Label>
                    <Select
                      multi
                      simpleValue
                      name="languages"
                      className={`patientSearch_language w-100 ${initialSearchParams.languages ? 'is-active' : ''}`}
                      placeholder=""
                      valueKey="code"
                      labelKey="displayName"
                      noResultsText="Language not found..."
                      options={languagesList}
                      onFocus={this.handleLanguagesAsyncList}
                      value={searchParams.languages}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.split`,`, name: 'languages' } })}
                      isLoading={!!languageLoading}
                    />
                  </Form.Group>
                  <Form.Group controlId="formLanguage" className="mb-2">
                    <Form.Label className="mb-0">Outreach type</Form.Label>
                    <Select
                      name="outreachType"
                      className={`patientSearch_outreachType w-100 ${initialSearchParams.outreachType ? 'is-active' : ''}`}
                      placeholder=""
                      valueKey="value"
                      labelKey="label"
                      value={searchParams.outreachType}
                      options={OUTREACH_TYPE_OPTIONS.filter(el => !el.disabled)}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'outreachType' } })}
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="border-0">
            <ContextAccordionHeader eventKey="1" isOpened={isOpened} openPanel={handlePanelOpened}>
              <i className="card-header-icon bi-building mr-2" data-test="patientSearch_pcpCard" />
              {isOpened && <span className="card-header-title" data-test="patientSearch_pcpMenu">PCP</span>}
            </ContextAccordionHeader>
            <Accordion.Collapse eventKey="1">
              <Card.Body className="pr-4">
                <Form className="text-left">
                  <Form.Group controlId="formPCPhysicianName" className="mb-2">
                    <Form.Label className="mb-0">PCP name</Form.Label>
                    <Async
                      name="physicianId"
                      className={`patientSearch_pcpName w-100 ${initialSearchParams.physicianId ? 'is-active' : ''}`}
                      placeholder=""
                      noResultsText={noResultsText}
                      onInputChange={input => (input.length < 2 ? this.setState({ noResultsText: 'Type 2 characters min' }) : this.setState({ noResultsText: 'No results found...' }))}
                      loadOptions={this.handlePhysiciansAsyncList}
                      value={physicianValue}
                      onChange={(ev) => {
                        this.setState({ physicianValue: ev });
                        this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'physicianId' } });
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formPCPLocation" className="mb-2">
                    <Form.Label className="mb-0">PCP location</Form.Label>
                    <Select
                      name="primaryPhysicianLocationId"
                      className={`patientSearch_pcpLocation w-100 ${initialSearchParams.primaryPhysicianLocationId ? 'is-active' : ''}`}
                      placeholder=""
                      valueKey="id"
                      labelKey="name"
                      noResultsText="Location not found..."
                      options={pcpLocationsList}
                      onFocus={this.handleLocationsAsyncList}
                      value={searchParams.primaryPhysicianLocationId}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.id, name: 'primaryPhysicianLocationId' } })}
                      isLoading={!!locationLoading}
                    />
                  </Form.Group>
                  <Form.Group controlId="formPCPLastVisitDate" className="mb-2">
                    <Form.Label className="mb-0">PCP last visit date</Form.Label>
                    <Select
                      name="lastPcpVisit"
                      className={`patientSearch_pcpLastVisit w-100 ${initialSearchParams.lastPcpVisit ? 'is-active' : ''}`}
                      placeholder="All"
                      noResultsText="Filter not found..."
                      options={PCP_VISIT_MONTH_OPTIONS}
                      value={searchParams.lastPcpVisit}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'lastPcpVisit' } })}
                    />
                  </Form.Group>
                  { isTelehealthGroupEnabled && (
                    <Form.Group controlId="formTelehealthGroup" className="mb-2">
                      <Form.Label className="mb-0">Telehealth Group</Form.Label>
                      <Select
                        multi
                        simpleValue
                        name="telehealthGroup"
                        className={`patientSearch_pcpTelehealthGroup w-100 ${initialSearchParams.telehealthProviders ? 'is-active' : ''}`}
                        data-test="patientSearch_pcpTelehealthGroupSelect"
                        placeholder=""
                        valueKey="code"
                        labelKey="code"
                        noResultsText="Telehealth Groups not found..."
                        onFocus={this.handleTelehealthProvidersAsyncList}
                        options={telehealthProvidersList}
                        value={searchParams.telehealthProviders}
                        onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.split(','), name: 'telehealthProviders' } })}
                        isLoading={!!telehealthProvidersLoading}
                      />
                    </Form.Group>
                  )}
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="border-0">
            <ContextAccordionHeader eventKey="2" isOpened={isOpened} openPanel={handlePanelOpened}>
              <i className="card-header-icon bi-journal-text mr-2" data-test="patientSearch_ccmCard" />
              {isOpened && <span className="card-header-title" data-test="patientSearch_ccmMenu">CCM</span>}
            </ContextAccordionHeader>
            <Accordion.Collapse eventKey="2">
              <Card.Body className="pr-4">
                <Form className="text-left">
                  <Form.Group controlId="formRemiders" className="mb-2">
                    <Form.Check
                      type="switch"
                      name="hasReminders"
                      label="Reminders"
                      checked={initialSearchParams.hasReminders || false}
                      onChange={this.handleSearchInputChange}
                      disabled={loading}
                    />
                  </Form.Group>
                  <Form.Group controlId="formCCMAssignedCN" className="mb-2">
                    <Form.Label className="mb-0">Assigned CN</Form.Label>
                    <AsyncPaginate
                      name="assignedCn"
                      className={`patientSearch_assignedCn w-100 ${initialSearchParams.careNavigatorId ? 'is-active' : ''}`}
                      placeholder="CN"
                      noResultsText="CN not found..."
                      value={searchParams.careNavigatorId
                        && getSelectedOwner(searchParams.careNavigatorId)}
                      loadOptions={handleOwnerAsyncList}
                      resetValue={ownerList[0]}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'careNavigatorId' } })}
                    />
                  </Form.Group>
                  <Form.Group controlId="formCCMBilling" className="mb-2">
                    <Form.Label className="mb-0">Billing status</Form.Label>
                    <Select
                      name="billingReportStatus"
                      className={`patientSearch_billingStatus w-100 ${initialSearchParams.billingReportStatus ? 'is-active' : ''}`}
                      placeholder="All"
                      noResultsText="Status not found..."
                      options={this.prepareBillingStatusList()}
                      value={searchParams.billingReportStatus}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'billingReportStatus' } })}
                    />
                  </Form.Group>
                  <Form.Group controlId="formCCMSuspendReason" className="mb-2">
                    <Form.Label className="mb-0">Status Reason</Form.Label>
                    <Select
                      name="statusReason"
                      className={`w-100 ${initialSearchParams.statusReason ? 'is-active' : ''}`}
                      placeholder="All"
                      noResultsText="Reasons not found..."
                      options={this.prepareStatusReasonsList()}
                      value={searchParams.statusReason}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'statusReason' } })}
                      data-test="statusReason-field"
                    />
                  </Form.Group>
                  <Form.Group controlId="formCCMCondition" className="mb-2">
                    <Form.Label className="mb-0">CCM conditions</Form.Label>
                    <Select
                      multi
                      simpleValue
                      name="chronicConditionIds"
                      className={`patientSearch_ccmCondition w-100 ${initialSearchParams.chronicConditionIds ? 'is-active' : ''}`}
                      placeholder="Select..."
                      valueKey="id"
                      labelKey="name"
                      noResultsText="CCM Condition not found..."
                      options={ccmConditionsList}
                      onFocus={this.handleCcmAsyncList}
                      value={searchParams.chronicConditionIds}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.split`,`.map(x => +x), name: 'chronicConditionIds' } })}
                      isLoading={!!ccmConditionsLoading}
                      data-test="chronicConditionIds-field"
                    />
                  </Form.Group>
                  <Form.Group controlId="formCCMNextActionDate" className="mb-2">
                    <Form.Label className="mb-0">Next Outreach Date</Form.Label>
                    <Select
                      name="nextActionDateRange"
                      className={`patientSearch_nextActionDate w-100 ${initialSearchParams.nextActionDateRange ? 'is-active' : ''}`}
                      clearable={false}
                      value={searchParams.nextActionDateRange || ''}
                      options={NEXT_ACTION_OPTIONS}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'nextActionDateRange' } })}
                      resetValue={{ value: 'all', label: 'All' }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formPreferredCallTime" className="mb-2">
                    <Form.Label className="mb-0">Preferred call time</Form.Label>
                    <Select
                      name="preferredCallTimeSelect"
                      className={`patientSearch_preferredCallTime w-100 ${initialSearchParams.preferredCallTime ? 'is-active' : ''}`}
                      clearable={false}
                      value={searchParams.preferredCallTime || ''}
                      options={handleCallTimeOptions()}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'preferredCallTime' } })}
                      resetValue={{ value: 'NONE', label: 'None Specified' }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formScheduleSensitive" className="mb-2">
                    <Form.Check
                      type="switch"
                      name="callEarlyMonth"
                      label="Schedule Sensitive"
                      checked={initialSearchParams.callEarlyMonth || false}
                      onChange={this.handleSearchInputChange}
                      disabled={loading}
                    />
                  </Form.Group>
                  { !isExternalUser && (
                    <Form.Group controlId="formCCMCondition" className="mb-2">
                      <Form.Label className="mb-0" data-test="patientSearch_attributeField">Patient Attributes</Form.Label>
                      <Select
                        multi
                        simpleValue
                        name="tagIds"
                        className={`patientSearch_tagIds w-100 ${initialSearchParams.tagIds ? 'is-active' : ''}`}
                        placeholder="Select..."
                        valueKey="id"
                        labelKey="displayName"
                        noResultsText="Patient Attribute not found..."
                        options={tagIdsList}
                        onFocus={this.handleTagIdsAsyncList}
                        value={searchParams.tagIds}
                        onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.split`,`.map(x => +x), name: 'tagIds' } })}
                        isLoading={!!tagIdsLoading}
                        data-test="tagIds-field"
                      />
                    </Form.Group>)
                  }
                  <Form.Group controlId="formMonthlyCalls" className="mb-2">
                    <Form.Label className="mb-0" data-test="patientSearch_monthlyCalls">Monthly Calls</Form.Label>
                    <Select
                      name="monthlyCallsTimeSelect"
                      className={`w-100 ${initialSearchParams.callAttempts ? 'is-active' : ''}`}
                      clearable={false}
                      value={searchParams.callAttempts || ''}
                      options={MONTHLY_CALL_OPTIONS}
                      onChange={ev => this.handleSearchInputChange({ target: { value: ev && ev.value, name: 'callAttempts' } })}
                      resetValue={{ value: '', label: 'Any' }}
                      data-test="patientSearch_monthlyCallsSelect"
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>

        <div className="enabled-filters mt-auto pt-2" data-test="patientSearch_enabledFilters">{this.renderEnabledFiltersInfo(getSelectedOwner)}</div>

        <ReactTooltip id="tooltip-patientSearch" type="info" effect="float" place="right" />
      </div>
    );
  }
}

function ContextAccordionHeader({
  children, eventKey, callback, openPanel, isOpened,
}) {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const handleClick = () => {
    if (!isOpened) {
      openPanel();
    }
    decoratedOnClick();
  };

  useEffect(() => {
    if (!isOpened && isCurrentEventKey) {
      decoratedOnClick();
    }
  }, [isOpened]);

  return (
    <Card.Header className={`${isCurrentEventKey ? 'is-active' : ''} py-3`}>
      <Button
        variant="link"
        onClick={handleClick}
        className="d-flex align-items-center w-100 p-0"
      >
        {children}
      </Button>
    </Card.Header>
  );
}

function mapStateToProps(state) {
  const tenant = state.tenant ? state.tenant : {};
  const isTelehealthGroupEnabled = (
    tenant.features && tenant.features.length > 0
      ? tenant.features : []
  ).find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_MULTI_TELEHEALTH.name)
    ?.enabled ?? false;
  return {
    searchParams: state.search.searchParams,
    isSearchParamsOverwritten: state.search.isSearchParamsOverwritten,
    user: state.user,
    cnList: state.cnList,
    pesList: state.pesList,
    loading: state.requestsInProgress.count,
    tags: tenant.tags,
    isTelehealthGroupEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeSearch: () => dispatch(CloseSearch()),
    resetSideBarSearch: () => dispatch(ResetSideBarSearch()),
    search: (searchData, isSearchParamsOverwritten) => dispatch(
      Search(searchData, isSearchParamsOverwritten),
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearch);
