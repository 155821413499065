// Libraries
import React from 'react';
import {
  Routes, Route, NavLink, Navigate,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
// Constants
import { REPORTS_MENU_ITEMS, REPORTS_MENU_PROPS, USER_ROLES } from '../../../constants/constants';
// Views
import Enrollment from '../../reports/Enrollment';
import BillingHistory from '../../administrator/BillingHistory';
import PatientEscalations from '../../reports/PatientEscalations';
import EligiblePatientsProvider from '../../reports/EligiblePatientsProvider';
import EnrolledPatientsProvider from '../../reports/EnrolledPatientsProvider';

const REPORTS_MENU_COMPONENTS = [
  { path: REPORTS_MENU_PROPS.ENROLLMENT.url, element: <Enrollment /> },
  { path: REPORTS_MENU_PROPS.CHARGES.url, element: <BillingHistory /> },
  { path: REPORTS_MENU_PROPS.ESCALATIONS.url, element: <PatientEscalations /> },
  { path: REPORTS_MENU_PROPS.ELIGIBLE_PROVIDER.url, element: <EligiblePatientsProvider /> },
  { path: REPORTS_MENU_PROPS.ENROLLED_PROVIDER.url, element: <EnrolledPatientsProvider /> },
];

export function Reports() {
  const { isExternal: isExternalUser = true, role: userRole } = useSelector(state => state.user);

  const userIsReporter = userRole === USER_ROLES.CUSTOMER;
  const rootPath = isExternalUser && userIsReporter
    ? REPORTS_MENU_PROPS.CHARGES.url
    : REPORTS_MENU_PROPS.ENROLLMENT.url;

  const renderMenuItems = () => REPORTS_MENU_ITEMS.map((item) => {
    if (isExternalUser && userIsReporter && REPORTS_MENU_PROPS.ENROLLMENT.url === item.url) {
      return null;
    }

    return (
      <NavLink
        key={`${item.url}`}
        to={item.url}
        className={({ isActive }) => `d-block text-left my-2${isActive ? ' active' : ''}`}
        data-test={`reports_${item.url}-link`}
      >
        {item.title}
      </NavLink>
    );
  });

  const renderComponents = () => REPORTS_MENU_COMPONENTS.map((item) => {
    if (isExternalUser && userIsReporter && REPORTS_MENU_PROPS.ENROLLMENT.url === item.path) {
      return null;
    }

    return <Route key={item.path} path={item.path} element={item.element} />;
  });

  return (
    <div className="ccm-reports-main d-flex mt-2">
      <div className="h-100 ccm-reports-panel-menu">
        <div className="ccm-reports-menu-wrapper d-flex flex-column h-100">
          <div className="reports-menu-items box-wrapper flex-grow-1 overflow-auto py-2" data-test="reports_menu">
            {renderMenuItems()}
          </div>
        </div>
      </div>
      <div className="h-100 ccm-reports-main-content box-wrapper flex-grow-1 ml-2 p-3 overflow-auto">
        <Routes>
          {renderComponents()}
          <Route path="/" element={<Navigate to={rootPath} />} />
        </Routes>
      </div>
    </div>
  );
}

export default Reports;
