import React, { Fragment, useState } from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { DATE_FORMAT, NONE_SPECIFIED_STRING, TENANT_FEATURES_NAMES } from '../../../constants/constants';
import InsuranceModal from './FutureInsuranceModal';

export function Insurance() {
  const {
    patient: {
      primaryInsurance, secondaryInsurance, futureInsurance,
    }, tenant: { features },
  } = useSelector(state => state);

  const [isFutureInsuranceEditModalOpened, setIsFutureInsuranceEditModalOpened] = useState(false);
  const [isFutureInsuranceClearModalOpened, setIsFutureInsuranceClearModalOpened] = useState(false);

  const showFutureInsurance = features
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_FUTURE_INSURANCE.name)
      ?.enabled;

  const getEffectiveDateColor = (date) => {
    const currentDate = moment().startOf('day');
    const effectiveDate = new Date(date);
    const pastEffectiveDate = moment(effectiveDate).isBefore(currentDate);
    return pastEffectiveDate ? 'text-ccm-red' : 'text-ccm-dark';
  };

  return (
    <Fragment>
      <div className="box-wrapper patient-ins-info my-2 p-2">
        <div className="d-flex justify-content-between">
          <div>
            <span className="mr-2">Primary Insurance:</span>
            <span className="patient__insuranceValue" data-test="summary_patientPrimaryInsuranceValue">
              {primaryInsurance ? `${primaryInsurance.companyName || ''} ${primaryInsurance.planId ? `(Id: ${primaryInsurance.planId})` : ''}` : 'Unknown'}
            </span>
          </div>
        </div>
        <div>
          <span className="mr-2">Secondary Insurance:</span>
          <span className="patient__insuranceValue" data-test="summary_patientSecondaryInsuranceValue">
            {secondaryInsurance ? `${secondaryInsurance.companyName || ''} ${secondaryInsurance.planId ? `(Id: ${secondaryInsurance.planId})` : ''}` : 'Unknown'}
          </span>
        </div>
      </div>
      {showFutureInsurance
        && (
        <div className="box-wrapper patient-fut-ins-info my-2 p-2">
          <div className="d-flex justify-content-between">
            <div>
              <span className="mr-2">Future Insurance:</span>
              <span
                className="future-insurance patient__insuranceValue"
                data-test="summary_patientFutureInsuranceValue"
                onClick={() => setIsFutureInsuranceEditModalOpened(true)}
              >
                <span data-test="summary_patientFutureInsuranceCompanyPlanId">
                  {futureInsurance ? `${futureInsurance.companyName || ''} ${futureInsurance.planId ? `(Id: ${futureInsurance.planId})` : ''} ` : NONE_SPECIFIED_STRING}
                </span>
                <span
                  data-test="summary_patientFutureInsuranceEffectiveDate"
                  className={`mr-2 ${futureInsurance && getEffectiveDateColor(futureInsurance.effectiveDate)}`}
                >
                  {futureInsurance?.effectiveDate ? moment(futureInsurance.effectiveDate).utc().format(DATE_FORMAT.SHORT) : ''}
                </span>
              </span>
              {futureInsurance && (
                <Button
                  size="xs"
                  variant="primary"
                  data-test="summary_patientFutureInsurance_clearEntryBtn"
                  onClick={() => setIsFutureInsuranceClearModalOpened(true)}
                >
                  Clear Entry
                  <i className="bi-check-circle ml-2" />
                </Button>
              )}
            </div>
            <div>
              <Button
                size="lg"
                variant="link"
                className="p-0 m-0"
                onClick={() => setIsFutureInsuranceEditModalOpened(true)}
                data-test="summary_setFutureInsuranceButton"
              >
                <span className="d-flex-center bi-pencil-square" />
              </Button>
            </div>
          </div>
        </div>
        )
      }

      <InsuranceModal
        initialInsurance={futureInsurance}
        isEditModalOpen={isFutureInsuranceEditModalOpened}
        setIsEditModalOpen={setIsFutureInsuranceEditModalOpened}
        isClearModalOpen={isFutureInsuranceClearModalOpened}
        setIsClearModalOpen={setIsFutureInsuranceClearModalOpened}
        data-test="summary_insuranceModal"
      />
    </Fragment>
  );
}

export default Insurance;
