// Libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatientDemographics } from '../../../actions/patient';
// Components
import { Select } from '../../base/forms/Select';
import { Checkbox } from '../../base/forms/Checkbox';
// Services
import { updatePatientDemographicsRequest } from '../../../services/patient';
// Constants
import { DIALOG_STYLES, NOT_SPECIFIED, NOTIFICATION_TYPE } from '../../../constants/constants';
import {
  RACE, LANGUAGES, GENDER_IDENTITY, ETHNIC_GROUP, SEXUAL_ORIENTATION,
} from '../../../constants/demographics';

const selectDemographics = state => state.patient.patientDemographics;

const DEFAULT_PATIENT_DEMOGRAPHICS = {
  raceReported: NOT_SPECIFIED,
  ethnicityReported: NOT_SPECIFIED,
  languageInfoReported: NOT_SPECIFIED,
  genderIdentityReported: NOT_SPECIFIED,
  sexualOrientationReported: NOT_SPECIFIED,
  raceSelect: '',
  ethnicitySelect: '',
  languageInfoSelect: '',
  genderIdentitySelect: '',
  sexualOrientationSelect: '',
};

const REPORTED_NO = 'no';

export const patientDemographicSchema = () => Yup.object({
  raceSelect: Yup.string().typeError('Please select a value from the list')
    .when('raceReported', (value, schema) => (value === REPORTED_NO ? schema.required('Required') : schema.nullable(true))),
  ethnicitySelect: Yup.string().typeError('Please select a value from the list')
    .when('ethnicityReported', (value, schema) => (value === REPORTED_NO ? schema.required('Required') : schema.nullable(true))),
  languageInfoSelect: Yup.string().typeError('Please select a value from the list')
    .when('languageInfoReported', (value, schema) => (value === REPORTED_NO ? schema.required('Required') : schema.nullable(true))),
  genderIdentitySelect: Yup.string().typeError('Please select a value from the list')
    .when('genderIdentityReported', (value, schema) => (value === REPORTED_NO ? schema.required('Required') : schema.nullable(true))),
  sexualOrientationSelect: Yup.string().typeError('Please select a value from the list')
    .when('sexualOrientationReported', (value, schema) => (value === REPORTED_NO ? schema.required('Required') : schema.nullable(true))),
});

export const PatientDemographicsModal = (props) => {
  const {
    isModalOpen, setIsModalOpen,
  } = props;
  const { id: patientId } = useParams();
  const pd = useSelector(selectDemographics);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const mapToOptions = array => array.map(o => ({ label: o.display, value: o.display }));

  const isReportedConfirmed = pd && (pd.ethnicityReported != null
    || pd.genderIdentityReported != null || pd.sexualOrientationReported != null
    || pd.languageInfoReported != null || pd.raceReported != null);

  const handleUpdateFactor = (newFactors) => {
    const patientDemographicsRequest = updatePatientDemographicsRequest(patientId, newFactors);
    const patientDemographicsPromise = patientDemographicsRequest.promise;

    return patientDemographicsPromise.then(() => {
      delete patientDemographicsRequest.promise;
      dispatch(UpdatePatientDemographics({
        ...pd,
        ...newFactors,
        languageInfoReported: newFactors.languageReported,
      }));
      dispatch(ShowNotification({
        message: 'Patient demographics saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      }));
      handleCloseModal();
    }).catch((error) => {
      delete patientDemographicsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      dispatch(ShowNotification({
        message: 'An error occurred while attempting to update patient demographics',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const [patientDemographics, setPatientDemographics] = useState(DEFAULT_PATIENT_DEMOGRAPHICS);

  const getFormInitialValue = (ehrFactor, factorReported) => {
    if (!factorReported) {
      return ehrFactor || NOT_SPECIFIED;
    }
    if (factorReported === ehrFactor) {
      return ehrFactor;
    }
    return factorReported === NOT_SPECIFIED ? NOT_SPECIFIED : REPORTED_NO;
  };

  const getFieldInitialValue = (ehrFactor, factorReported) => {
    if (factorReported === NOT_SPECIFIED) {
      return NOT_SPECIFIED;
    }
    return ehrFactor || NOT_SPECIFIED;
  };

  const getInitialSelectValue = (ehrFactor, factorReported) => factorReported
    && factorReported !== NOT_SPECIFIED && factorReported !== ehrFactor;

  useEffect(() => {
    if (pd) {
      const initialFormValues = {
        raceReported: getFormInitialValue(pd.race, pd.raceReported),
        ethnicityReported: getFormInitialValue(pd.ethnicity, pd.ethnicityReported),
        languageInfoReported: getFormInitialValue(
          pd.languageInfo && pd.languageInfo.displayName, pd.languageInfoReported,
        ),
        genderIdentityReported: getFormInitialValue(
          pd.genderIdentity, pd.genderIdentityReported,
        ),
        sexualOrientationReported: getFormInitialValue(
          pd.sexualOrientation, pd.sexualOrientationReported,
        ),
        raceSelect: getInitialSelectValue(pd.race, pd.raceReported) ? pd.raceReported : '',
        ethnicitySelect: getInitialSelectValue(pd.ethnicity, pd.ethnicityReported) ? pd.ethnicityReported : '',
        languageInfoSelect: getInitialSelectValue(pd.languageInfo, pd.languageInfoReported) ? pd.languageInfoReported : '',
        genderIdentitySelect: getInitialSelectValue(pd.genderIdentity, pd.genderIdentityReported) ? pd.genderIdentityReported : '',
        sexualOrientationSelect: getInitialSelectValue(pd.sexualOrientation, pd.sexualOrientationReported) ? pd.sexualOrientationReported : '',
      };
      setPatientDemographics(initialFormValues);
    } else {
      setPatientDemographics(DEFAULT_PATIENT_DEMOGRAPHICS);
    }
  }, [pd]);

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Edit Factors"
      data-test="patientDemographicsModal_onRequestClose"
    >
      <div className="simple-dialog big-dialog patient-health-factors">
        <div className="dialog-title">
          Review Demographics
          <button
            data-test="patientDemographicsModal_closeIconButton"
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
          />
        </div>
        <Formik
          enableReinitialize
          initialValues={patientDemographics}
          validationSchema={patientDemographicSchema()}
          onSubmit={(values, { resetForm }) => {
            const raceUpdated = values.raceReported === REPORTED_NO
              ? values.raceSelect : values.raceReported;
            const ethnicityUpdated = values.ethnicityReported === REPORTED_NO
              ? values.ethnicitySelect : values.ethnicityReported;
            const languageInfoUpdated = values.languageInfoReported === REPORTED_NO
              ? values.languageInfoSelect : values.languageInfoReported;
            const genderIdentityUpdated = values.genderIdentityReported === REPORTED_NO
              ? values.genderIdentitySelect : values.genderIdentityReported;
            const sexualOrientationUpdated = values.sexualOrientationReported === REPORTED_NO
              ? values.sexualOrientationSelect : values.sexualOrientationReported;
            const valuesUpdated = {
              raceReported: raceUpdated,
              ethnicityReported: ethnicityUpdated,
              languageReported: languageInfoUpdated,
              genderIdentityReported: genderIdentityUpdated,
              sexualOrientationReported: sexualOrientationUpdated,
            };
            handleUpdateFactor(valuesUpdated);
            resetForm();
          }}
          data-test="patientDemographicsModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="p-4">
                { !isReportedConfirmed && (
                  <div className="alert alert-danger text-left mb-4">
                    <span className="bi-exclamation-triangle text-danger mr-2" />
                    <span data-test="patientDemographicsModal_confirmText">Confirm patient reported demographics below</span>
                  </div>)
                }
                <div className="row no-gutters">
                  <div className="d-flex flex-column col-4 text-left">
                    <p>EHR Race</p>
                    <p className="text-ccm-gray">{pd && pd.race ? pd.race : NOT_SPECIFIED}</p>
                  </div>
                  <div className="d-flex flex-column">
                    <p>Is this correct?</p>
                    <div className="d-flex flex-row">
                      <Checkbox
                        inline
                        label="Yes"
                        name="raceReported"
                        type="radio"
                        data-test="patientDemographicsModal_raceReportedYes"
                        value={pd && getFieldInitialValue(pd.race, pd.raceReported)}
                      />
                      <Checkbox
                        inline
                        label="No"
                        name="raceReported"
                        type="radio"
                        data-test="patientDemographicsModal_raceReportedNo"
                        value={REPORTED_NO}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-6 px-4" data-test="patientDemographicsModal_raceReportedValue">
                    {formik.values.raceReported === REPORTED_NO && (
                      <Select
                        label="Patient Reported Race"
                        name="raceSelect"
                        placeholder="Please select a option"
                        options={mapToOptions(RACE)}
                      />
                    )}
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="d-flex flex-column col-4 text-left">
                    <p>EHR Ethnicity</p>
                    <p className="text-ccm-gray">{pd && pd.ethnicity ? pd.ethnicity : NOT_SPECIFIED}</p>
                  </div>
                  <div className="d-flex flex-column">
                    <p>Is this correct?</p>
                    <div className="d-flex flex-row">
                      <Checkbox
                        inline
                        label="Yes"
                        name="ethnicityReported"
                        type="radio"
                        data-test="patientDemographicsModal_ethnicityReportedYes"
                        value={pd && getFieldInitialValue(pd.ethnicity, pd.ethnicityReported)}
                      />
                      <Checkbox
                        inline
                        label="No"
                        name="ethnicityReported"
                        type="radio"
                        data-test="patientDemographicsModal_ethnicityReportedNo"
                        value={REPORTED_NO}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-6 px-4" data-test="patientDemographicsModal_ethnicityReportedValue">
                    {formik.values.ethnicityReported === REPORTED_NO && (
                      <Select
                        label="Patient Reported Ethnicity"
                        name="ethnicitySelect"
                        placeholder="Please select a option"
                        options={mapToOptions(ETHNIC_GROUP)}
                      />
                    )}
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="d-flex flex-column col-4 text-left">
                    <p>EHR Language</p>
                    <p className="text-ccm-gray">{pd && pd.languageInfo ? pd.languageInfo.displayName : NOT_SPECIFIED}</p>
                  </div>
                  <div className="d-flex flex-column">
                    <p>Is this correct?</p>
                    <div className="d-flex flex-row">
                      <Checkbox
                        inline
                        label="Yes"
                        name="languageInfoReported"
                        type="radio"
                        data-test="patientDemographicsModal_languageInfoReportedYes"
                        value={pd && getFieldInitialValue(
                          pd.languageInfo && pd.languageInfo.displayName,
                          pd.languageInfoReported,
                        )}
                      />
                      <Checkbox
                        inline
                        label="No"
                        name="languageInfoReported"
                        type="radio"
                        data-test="patientDemographicsModal_languageInfoReportedNo"
                        value={REPORTED_NO}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-6 px-4" data-test="patientDemographicsModal_languageInfoReportedValue">
                    {formik.values.languageInfoReported === REPORTED_NO && (
                      <Select
                        label="Patient Reported Language"
                        name="languageInfoSelect"
                        placeholder="Please select a option"
                        options={mapToOptions(LANGUAGES)}
                      />
                    )}
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="d-flex flex-column col-4 text-left">
                    <p>EHR Gender Identity</p>
                    <p className="text-ccm-gray">{pd && pd.genderIdentity ? pd.genderIdentity : NOT_SPECIFIED}</p>
                  </div>
                  <div className="d-flex flex-column">
                    <p>Is this correct?</p>
                    <div className="d-flex flex-row">
                      <Checkbox
                        inline
                        label="Yes"
                        name="genderIdentityReported"
                        type="radio"
                        data-test="patientDemographicsModal_genderIdentityReportedYes"
                        value={pd
                          && getFieldInitialValue(pd.genderIdentity, pd.genderIdentityReported)
                        }
                      />
                      <Checkbox
                        inline
                        label="No"
                        name="genderIdentityReported"
                        type="radio"
                        data-test="patientDemographicsModal_genderIdentityReportedNo"
                        value={REPORTED_NO}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-6 px-4" data-test="patientDemographicsModal_genderReportedValue">
                    {formik.values.genderIdentityReported === REPORTED_NO && (
                      <Select
                        label="Patient Reported Gender Identity"
                        name="genderIdentitySelect"
                        placeholder="Please select a option"
                        options={mapToOptions(GENDER_IDENTITY)}
                      />
                    )}
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="d-flex flex-column col-4 text-left">
                    <p>EHR Sexual Orientation</p>
                    <p className="text-ccm-gray">{pd && pd.sexualOrientation ? pd.sexualOrientation : NOT_SPECIFIED}</p>
                  </div>
                  <div className="d-flex flex-column">
                    <p>Is this correct?</p>
                    <div className="d-flex flex-row">
                      <Checkbox
                        inline
                        label="Yes"
                        name="sexualOrientationReported"
                        type="radio"
                        data-test="patientDemographicsModal_sexualOrientationReportedYes"
                        value={pd && getFieldInitialValue(
                          pd.sexualOrientation, pd.sexualOrientationReported,
                        )}
                      />
                      <Checkbox
                        inline
                        label="No"
                        name="sexualOrientationReported"
                        type="radio"
                        data-test="patientDemographicsModal_sexualOrientationReportedNo"
                        value={REPORTED_NO}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column col-6 px-4" data-test="patientDemographicsModal_sexualOrientationReportedValue">
                    {formik.values.sexualOrientationReported === REPORTED_NO && (
                      <Select
                        label="Patient Reported Sexual Orientation"
                        name="sexualOrientationSelect"
                        placeholder="Please select a option"
                        options={mapToOptions(SEXUAL_ORIENTATION)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-4 mr-5 float-right">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="patientDemographicsModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="patientDemographicsModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default PatientDemographicsModal;
