// Libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Constants
import { NOTIFICATION_TYPE, NOT_SPECIFIED } from '../../../constants/constants';
// Actions
import { UpdatePatientDemographics } from '../../../actions/patient';
import ShowNotification from '../../../actions/notification';
// Views
import PatientDemographicsModal from './PatientDemographicsModal';
// Services
import { getPatientDemographics } from '../../../services/patient';

const selectDemographics = state => state.patient.patientDemographics;

const PatientDemographics = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id: patientId } = useParams();
  const patientDemographics = useSelector(selectDemographics);
  const dispatch = useDispatch();
  const pt = patientDemographics;
  const isReportedConfirmed = pt && (pt.ethnicityReported != null
    || pt.genderIdentityReported != null || pt.sexualOrientationReported != null
    || pt.languageInfoReported != null || pt.raceReported != null);

  const fetchPatientDemographics = () => {
    const getPatientDemographicsRequest = getPatientDemographics(patientId);
    const getPatientDemographicsPromise = getPatientDemographicsRequest.promise;

    return getPatientDemographicsPromise.then((data) => {
      delete getPatientDemographicsRequest.promise;
      dispatch(UpdatePatientDemographics(data));
    }).catch((error) => {
      delete getPatientDemographicsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      dispatch(ShowNotification({
        message: 'An error has occurred while attempting to load patient demographics.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const renderEmptyRow = () => (
    <tr data-test="patientDemographics_emptyMsg">
      <td colSpan="6" className="p-2 border-0">
      This patient has no reported demographics factors
      </td>
    </tr>
  );

  const renderPatientDemographicsRowButton = () => (
    <tr data-test="patientDemographics_button">
      <td colSpan="6" className="text-center p-0">
        <Button
          variant="link"
          className="text-ccm-red py-3 w-100"
          onClick={() => setIsModalOpen(true)}
          data-test="patientDemographics_rowButton"
        >
          Click here to review patient demographics
        </Button>
      </td>
    </tr>
  );

  const renderPatientDemographicsEhrRows = () => {
    if (patientDemographics) {
      return (
        <tr key={`patientDemographic_row_${patientDemographics.id}`} data-test="patientDemographics_entryRow">
          <td>
            EHR
          </td>
          <td>
            {patientDemographics.race || NOT_SPECIFIED}
          </td>
          <td>
            {patientDemographics.ethnicity || NOT_SPECIFIED}
          </td>
          <td>
            {patientDemographics.languageInfo
              ? patientDemographics.languageInfo.displayName : NOT_SPECIFIED}
          </td>
          <td>
            {patientDemographics.genderIdentity || NOT_SPECIFIED}
          </td>
          <td>
            {patientDemographics.sexualOrientation || NOT_SPECIFIED}
          </td>
        </tr>
      );
    }
    return renderEmptyRow();
  };

  const renderPatientDemographicsRows = () => {
    if (isReportedConfirmed) {
      const areEqualsFactor = (factorReported, ehrFactor) => {
        const factorReportedToCompare = factorReported === NOT_SPECIFIED ? null : factorReported;
        const ehrFactorToCompare = ehrFactor || null;
        return factorReportedToCompare === ehrFactorToCompare;
      };
      const ehrLanguage = pt.languageInfo ? pt.languageInfo.displayName : pt.languageInfo;
      return (
        <tr key={`patientDemographic_row_${patientDemographics.id}_reported`} data-test="patientDemographics_entryRow_patientReported">
          <td>
            Patient
          </td>
          <td className={`${areEqualsFactor(pt.raceReported, pt.race) ? '' : 'font-weight-bold'}`}>
            {patientDemographics.raceReported || NOT_SPECIFIED}
          </td>
          <td className={`${areEqualsFactor(pt.ethnicityReported, pt.ethnicity) ? '' : 'font-weight-bold'}`}>
            {patientDemographics.ethnicityReported || NOT_SPECIFIED}
          </td>
          <td className={`${areEqualsFactor(pt.languageInfoReported, ehrLanguage) ? '' : 'font-weight-bold'}`}>
            {patientDemographics.languageInfoReported || NOT_SPECIFIED}
          </td>
          <td className={`${areEqualsFactor(pt.genderIdentityReported, pt.genderIdentity) ? '' : 'font-weight-bold'}`}>
            {patientDemographics.genderIdentityReported || NOT_SPECIFIED}
          </td>
          <td className={`${areEqualsFactor(pt.sexualOrientationReported, pt.sexualOrientation) ? '' : 'font-weight-bold'}`}>
            {patientDemographics.sexualOrientationReported || NOT_SPECIFIED}
          </td>
        </tr>
      );
    }
    return renderPatientDemographicsRowButton();
  };

  useEffect(() => {
    fetchPatientDemographics();
  }, []);

  return (
    <div className="patient-demographics card border-0 mb-4">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <p className="text-uppercase mb-0">Patient Demographics</p>
        {isReportedConfirmed && (
          <Button
            size="sm"
            variant="link-dark"
            className="position-absolute"
            style={{ right: 10 }}
            onClick={() => setIsModalOpen(true)}
            data-test="patientDemographics_editButton"
          >
            <i className="d-flex-center bi-pencil-square" />
          </Button>)
        }
        <PatientDemographicsModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2">Source</th>
              <th className="py-2">Race</th>
              <th className="py-2">Ethnicity</th>
              <th className="py-2">Language</th>
              <th className="py-2">Gender Identity</th>
              <th className="py-2">Sexual Orientation</th>
            </tr>
          </thead>
          <tbody>
            {renderPatientDemographicsEhrRows()}
            {renderPatientDemographicsRows()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PatientDemographics;
