// Libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'react-bootstrap';
// Services
import { parseDraftToHtml } from '../../../services/helpers';
// Views
import QaNotesModal from './QaNotesModal';
// Constants
import {
  QA_STATUSES, USER_ROLES, DATE_FORMAT, QA_STATUS_EDIT,
} from '../../../constants/constants';

export class QaNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialQaNote: null,
      isQaModalOpen: false,
    };
  }

  setIsQaModalOpen = (isOpen) => {
    this.setState({
      isQaModalOpen: isOpen,
    });
  }

  render() {
    const { isQaModalOpen, initialQaNote } = this.state;
    const {
      patientId, user = {}, patient: { lastQaNote, assignedCareNavigator } = {},
    } = this.props;

    const isCnUser = (user.role && user.role === USER_ROLES.CN);
    const isAdminUser = (user.role && user.role === USER_ROLES.ADMIN);
    const isNoteOwner = lastQaNote && (lastQaNote.createdBy.id === user.id);
    const isCnOwner = (assignedCareNavigator && assignedCareNavigator.id === user.id);

    const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);

    const getFullName = a => `${a.firstName || ''} ${a.lastName || ''}`.replace(/  +/g, ' ');

    const getQaStatusLabel = (type) => {
      const statusKey = Object.keys(QA_STATUSES).find(key => QA_STATUSES[key].type === type);
      return QA_STATUSES[statusKey].type !== QA_STATUSES.feedbackAcknowledged.type
        ? QA_STATUSES[statusKey].labelA : null;
    };

    const getQaStatusColor = (type) => {
      const statusKey = Object.keys(QA_STATUSES).find(key => QA_STATUSES[key].type === type);
      return QA_STATUSES[statusKey].color;
    };

    const getQaStatusIcon = (status) => {
      switch (status) {
        case QA_STATUSES.resubmitted.type:
          return <span className="bi-arrow-repeat mr-1" />;
        case QA_STATUSES.requested.type:
          return <span className="bi-exclamation-triangle mr-1" />;
        case QA_STATUSES.acceptFeedback.type:
          return <span className="bi-info-circle mr-1" />;
        default:
          return null;
      }
    };

    const isEnabledToEdit = isNoteOwner && QA_STATUS_EDIT.includes(lastQaNote.status);

    return (
      (isAdminUser || (isCnUser && isCnOwner)) ? (
        <Fragment>
          <div className="note-header mb-2">
            {lastQaNote ? (
              <Fragment>
                <span className="mr-1">QA Status:</span>
                <span className={`qa__status text-ccm-${getQaStatusColor(lastQaNote.status)}`} data-test="qaNotes_status">
                  {getQaStatusIcon(lastQaNote.status)}
                  {getQaStatusLabel(lastQaNote.status)}
                </span>
              </Fragment>) : <span className="mr-1">QA Notes:</span>}
          </div>

          <div className="note-body position-relative border mb-1 p-2">
            {lastQaNote ? (
              <Fragment>
                <span className="qa__comments" data-test="qaNotes_savedNote">
                  {lastQaNote.text && parseDraftToHtml(lastQaNote.text)}
                </span>
                {isEnabledToEdit && (
                  <Button
                    size="lg"
                    variant="link"
                    style={{ top: '5px', right: '5px' }}
                    className="d-flex-center position-absolute p-0"
                    onClick={() => this.setState({
                      initialQaNote: lastQaNote,
                    }, this.setIsQaModalOpen(true))}
                    data-test="qaNotes_editBtn"
                  >
                    <span className="d-flex-center bi-pencil-square" />
                  </Button>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <span className="text-ccm-gray" data-test="qaNotes_emptyAlertMsg">This patient has no QA Notes</span>
                {isAdminUser && (
                  <Button
                    size="lg"
                    variant="link"
                    style={{ top: '5px', right: '5px' }}
                    className="d-flex-center position-absolute p-0"
                    onClick={() => this.setState({
                      initialQaNote: null,
                    }, this.setIsQaModalOpen(true))}
                    data-test="qaNotes_plusReviewBtn"
                  >
                    <span className="d-flex-center bi-plus-square" />
                  </Button>
                )}
              </Fragment>
            )}
          </div>

          {lastQaNote && (
          <div className="note-footer d-flex justify-content-between">
            <div>
              <small className="text-ccm-gray mr-1" data-test="qaNotesCreator">
                {lastQaNote.createdBy ? getFullName(lastQaNote.createdBy) : '--'}
              </small>
              <small className="text-ccm-gray" data-test="qaNotesCreationDate">
                {lastQaNote.createdAt && getDateString(lastQaNote.createdAt)}
              </small>
            </div>

            {isAdminUser && (
            <Button
              size="xs"
              variant="primary"
              className="d-flex-center"
              onClick={() => this.setState({
                initialQaNote: null,
              }, this.setIsQaModalOpen(true))}
              data-test="qaNotes_addReviewBtn"
            >
              <span className="d-flex-center bi-plus mr-2" />
              Add Review
            </Button>)}
            {isCnUser && lastQaNote && lastQaNote.status === QA_STATUSES.requested.type && (
            <Button
              size="xs"
              variant="success"
              className="d-flex-center"
              onClick={() => {
                this.setState({
                  initialQaNote: { status: QA_STATUSES.resubmitted.type },
                }, this.setIsQaModalOpen(true));
              }}
              data-test="qaNotes_resubmitForReviewBtn"
            >
              <span className="d-flex-center bi-arrow-repeat mr-2" />
              Resubmit for Review
            </Button>)}
            {isCnUser && lastQaNote && lastQaNote.status === QA_STATUSES.acceptFeedback.type && (
            <Button
              size="xs"
              variant="success"
              className="d-flex-center"
              onClick={() => {
                this.setState({
                  initialQaNote: { status: QA_STATUSES.feedbackAcknowledged.type },
                }, this.setIsQaModalOpen(true));
              }}
              data-test="qaNotes_acknowledgeBtn"
            >
              Acknowledge
            </Button>)}
          </div>)}

          <QaNotesModal
            patientId={patientId}
            initialQaNote={initialQaNote}
            isModalOpen={isQaModalOpen}
            setIsModalOpen={this.setIsQaModalOpen}
          />
        </Fragment>) : <span className="text-ccm-gray">Not enabled to set QA Notes</span>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(QaNotes);
