// Libraries
import React from 'react';
// Views
import HealthFactors from './sdoh/HealthFactors';
import Resources from './sdoh/Resources';

export const SDoH = () => (
  <div className="sdoh h-100 overflow-auto pr-3">
    <HealthFactors />
    <Resources />
  </div>
);

export default SDoH;
