export const ENABLE_PATIENT_PROMPTS = 'ENABLE_PATIENT_PROMPTS';

export const PROMPTS_SUCCESS_MESSAGES = {
  creation: 'Prompt actions recorded successfully. Auto-generated interventions can be found on the Care Plan page.',
  edition: 'Updating your responses will not remove interventions previously created. Please review the interventions on the Care Plan screen and edit them accordingly before finalizing.',
};

export const PROMPT_CODES = {
  engooden: { id: 'SELECTPATIENT_MANAGEMENT', name: 'SelectPatient Management Prompts', htmlId: 'engooden-prompts-script-hint' },
  patient: { id: 'PATIENT', name: 'Personalized Patient Prompts', htmlId: 'patient-prompts-script-hint' },
  partner: { id: 'PARTNER', name: 'Partner Prompts', htmlId: 'partner-prompts-script-hint' },
  seasonal: { id: 'SEASONAL', name: 'Seasonal Prompts', htmlId: 'seasonal-prompts-script-hint' },
};

export const DEFAULT_PROMPTS = [
  { ...PROMPT_CODES.engooden },
  { ...PROMPT_CODES.seasonal },
  { ...PROMPT_CODES.partner },
  { ...PROMPT_CODES.patient },
];

const CONFIRMATION_PROMPTS = {
  discussionOnly: 'DISCUSSION_ONLY',
  discussionIntervention: 'DISCUSSION_INTERVENTION',
};

const PROMPT_OPTION_CODES = {
  discussedPatient: { id: 'DISCUSSED_NO_ACTION_DEFINED', label: 'I discussed this with the patient' },
  notDiscussedPatient: { id: 'DID_NOT_DISCUSS', label: 'I did not discuss this with the patient' },
  tookAction: { id: 'DISCUSSED_AND_TOOK_ACTION', label: 'Discussed and took action' },
  notTakeAction: { id: 'DISCUSSED_AND_DID_NOT_TAKE_ACTION', label: 'Discussed but did not take action' },
  didNotDiscuss: { id: 'DID_NOT_DISCUSS', label: 'Did not discuss' },
};

export const PROMPT_OPTION_LABEL = {
  [PROMPT_OPTION_CODES.discussedPatient.id]: PROMPT_OPTION_CODES.discussedPatient.label,
  [PROMPT_OPTION_CODES.notDiscussedPatient.id]: PROMPT_OPTION_CODES.notDiscussedPatient.label,
  [PROMPT_OPTION_CODES.tookAction.id]: PROMPT_OPTION_CODES.tookAction.label,
  [PROMPT_OPTION_CODES.notTakeAction.id]: PROMPT_OPTION_CODES.notTakeAction.label,
};

export const getPromptOptions = (type, required) => {
  switch (true) {
    case type === CONFIRMATION_PROMPTS.discussionOnly && required:
      return [
        { ...PROMPT_OPTION_CODES.discussedPatient },
      ];
    case type === CONFIRMATION_PROMPTS.discussionOnly && !required:
      return [
        { ...PROMPT_OPTION_CODES.discussedPatient },
        { ...PROMPT_OPTION_CODES.notDiscussedPatient },
      ];
    case type === CONFIRMATION_PROMPTS.discussionIntervention && required:
      return [
        { ...PROMPT_OPTION_CODES.tookAction },
        { ...PROMPT_OPTION_CODES.notTakeAction },
      ];
    case type === CONFIRMATION_PROMPTS.discussionIntervention && !required:
      return [
        { ...PROMPT_OPTION_CODES.tookAction },
        { ...PROMPT_OPTION_CODES.notTakeAction },
        { ...PROMPT_OPTION_CODES.didNotDiscuss },
      ];
    default:
      return [];
  }
};
