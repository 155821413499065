// Libraries
import React, {
  useState, lazy, Suspense, useEffect,
} from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  Routes, Route, useNavigate, useParams, useLocation,
} from 'react-router-dom';
// Components
import CustomPrompt from '../base/CustomPrompt';
// Constants
import { PATIENT_ENROLLMENT_OPTIONS, ENROLLMENT_CODES } from '../../constants/constants';
import { NON_QUALIFIED_PATIENTS_MESSAGES, QUALIFIED_PATIENT_STATUS, PATIENT_STATUS_SPECIFIC_OPTIONS } from '../../constants/patientEnrollment';
// Views
const Enrollment = lazy(() => import('./contents/enrollment/Enrollment'));
const FollowUpCall = lazy(() => import('./contents/followUpCall/FollowUpCall'));
const OtherActions = lazy(() => import('./contents/otherActions/OtherActions'));
const DeclinePermanent = lazy(() => import('./contents/documentDecline/DeclinePermanent'));
const DeclineFutureCall = lazy(() => import('./contents/documentDecline/DeclineFutureCall'));
const PatientHistoryMain = lazy(() => import('./contents/patientHistory/PatientHistoryMain'));


export const MainContent = (props) => {
  const { addOptions = false } = props;
  const { status = '' } = useSelector(state => state.patient);

  const navigate = useNavigate();
  const { pathname = '' } = useLocation();
  const { id: patientId, tenant: tenantUrl } = useParams();

  const [content, setContent] = useState('');
  const [enrollmentOptions, setEnrollmentOptions] = useState(PATIENT_ENROLLMENT_OPTIONS);

  const qualifiedPatient = QUALIFIED_PATIENT_STATUS.includes(status);
  const canViewSpecificOptions = PATIENT_STATUS_SPECIFIC_OPTIONS.includes(status);

  const getView = (view, message) => {
    if (qualifiedPatient) return view;
    return <span className="font-weight-bold">{message}</span>;
  };

  const getOptions = () => {
    if (canViewSpecificOptions) {
      setEnrollmentOptions([{ ...ENROLLMENT_CODES.patientHistory }]);
    }
    if (addOptions && !canViewSpecificOptions) {
      setEnrollmentOptions(PATIENT_ENROLLMENT_OPTIONS.concat(ENROLLMENT_CODES.patientHistory));
    }
  };

  useEffect(() => { getOptions(); }, [status]);

  useEffect(() => {
    const patshList = pathname.split('/');
    const final = patshList[patshList.length - 1];
    setContent(final);
  }, [pathname]);

  return (
    <div className="mb-3 mt-3">
      <h5 className="mb-4">I want to</h5>
      <div>
        {enrollmentOptions.map(option => (
          <Form.Check
            inline
            type="radio"
            id={option.id}
            key={option.id}
            label={option.label}
            name="enrollmentOptions"
            className="mb-3 mr-3"
            checked={option.id === content}
            onChange={() => navigate(`/${tenantUrl}/cn/summary-patient/${patientId}/overview/${option.id}`)}
            data-test={`mainContent_radioBtn-${option.id}`}
          />))
        }
      </div>
      <div className="sub-content">
        <Suspense fallback={null}>
          <Routes>
            <Route
              path={`overview/${ENROLLMENT_CODES.enrollPatient.id}`}
              element={getView(<Enrollment />, NON_QUALIFIED_PATIENTS_MESSAGES.enroll)}
            />
            <Route
              path={`overview/${ENROLLMENT_CODES.scheduleCall.id}`}
              element={<FollowUpCall />}
            />
            <Route
              path={`overview/${ENROLLMENT_CODES.declineFutureCall.id}`}
              element={getView(
                <DeclineFutureCall />, NON_QUALIFIED_PATIENTS_MESSAGES.documentDecline,
              )}
            />
            <Route
              path={`overview/${ENROLLMENT_CODES.declinePermanent.id}`}
              element={getView(
                <DeclinePermanent />, NON_QUALIFIED_PATIENTS_MESSAGES.documentDecline,
              )}
            />
            <Route
              path={`overview/${ENROLLMENT_CODES.otherActions.id}`}
              element={getView(<OtherActions />, NON_QUALIFIED_PATIENTS_MESSAGES.otherActions)}
            />
            <Route
              path={`overview/${ENROLLMENT_CODES.patientHistory.id}`}
              element={<PatientHistoryMain />}
            />
            <Route
              path="overview/"
              element={<span className="font-weight-bold">Please select an option to continue</span>}
            />
          </Routes>
        </Suspense>
      </div>
      <CustomPrompt />
    </div>
  );
};

export default MainContent;
