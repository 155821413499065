// Libraries
import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
// Services
import { getPrimaryStatus } from '../../../services/helpers';
import { setActivityStatus } from '../../../services/patient';

// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Components
import ChangePatientStatusBlock from './ChangePatientStatusBlock';
import ActiveStatusModal from './ActiveStatusModal';
// Constants
import {
  DATE_FORMAT, NOTIFICATION_TYPE, PATIENT_ACTIVE_STATUS, USER_ROLES,
} from '../../../constants/constants';
import { STATUSES } from '../../../constants/statuses';

export const SummaryStatusInfo = (props) => {
  const { setChangeStatusView, reloadPatientInfo } = props;
  const { id: patientId, tenant: tenantUrl } = useParams();
  const navigate = useNavigate();
  const {
    user: { role: userRole }, patient,
    patient: {
      activityStatusInfo, status, callAttempts, recentDirectContact, recentCallAttempt,
    },
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newActivityStatusInfo, setNewActivityStatusInfo] = useState();
  const isAdminUser = (userRole === USER_ROLES.ADMIN);
  let patientStatus;
  let patientEnrollmentDateBlock;
  const directContactLabel = recentDirectContact
    ? moment(recentDirectContact, DATE_FORMAT.FULL_WITH_TIME)
      .format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)
    : '---';
  const callAttemptLabel = recentCallAttempt
    ? moment(recentCallAttempt, DATE_FORMAT.FULL_WITH_TIME)
      .format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS)
    : '---';

  const primaryStatus = getPrimaryStatus(patient);
  const isPatientEnrolled = primaryStatus === 'C';
  if (STATUSES[primaryStatus]) {
    const subStatuses = STATUSES[primaryStatus].subStatuses || {};
    patientStatus = subStatuses[status] ? subStatuses[status].name : STATUSES[primaryStatus].name;
  }

  if (isPatientEnrolled && patient && patient.enrollmentDate) {
    const dateDiff = moment().diff(patient.enrollmentDate, 'months', true);
    let dateLength = '';
    if (dateDiff > 12) {
      dateLength = ` (Year ${Math.ceil(Math.round(dateDiff / 12) * 10) / 10})`;
    } else {
      const month = Math.ceil(dateDiff) <= 1 ? 1 : Math.ceil(dateDiff);
      dateLength = ` (Month ${month})`;
    }
    patientEnrollmentDateBlock = (
      <div className="text-left">
        <span className="text-uppercase mr-2">Enrolled:</span>
        <span
          className="text-uppercase font-weight-bold"
          data-test="patientSummaryStatusValue"
        >
          {moment(patient.enrollmentDate).format(DATE_FORMAT.SHORT)}
        </span>
        <span className="font-weight-normal" data-test="patientSumaryEnrollmentLength">
          {dateLength}
        </span>
      </div>
    );
  }

  const updateActivityStatusInfo = ({ values = {}, newStatus }) => {
    const newStatusData = {
      ...values,
      status: newStatus || newActivityStatusInfo,
      newStatus: newStatus || newActivityStatusInfo,
    };

    const setActivityStatusRequest = setActivityStatus(
      patientId, newStatusData,
    );
    const setActivityStatusPromise = setActivityStatusRequest.promise;

    setActivityStatusPromise.then((patientInfo) => {
      delete setActivityStatusRequest.promise;

      dispatch(UpdatePatient({
        status: patientInfo.status,
        permissions: patientInfo.permissions,
        billing: patientInfo.billing,
        assignedCareNavigator: patientInfo.assignedCareNavigator,
        activityStatusInfo: patientInfo.activityStatusInfo,
      }));

      showNotification({
        message: 'Changes were successfully saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      if (userRole !== USER_ROLES.ADMIN) {
        navigate(`/${tenantUrl}`);
      }
    }).catch((error) => {
      delete setActivityStatusRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Error occurred during request, please try again later.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleClickStatusButton = (activeStatus, newStatus) => {
    if (activeStatus === newStatus) {
      return;
    }
    if (newStatus === 'ACTIVE') {
      updateActivityStatusInfo({ newStatus });
      return;
    }
    setNewActivityStatusInfo(newStatus);
    setIsModalOpen(true);
  };

  const renderDeactivationReasonBlock = () => {
    const activeStatus = PATIENT_ACTIVE_STATUS[activityStatusInfo.status];
    const { deactivationReason, deceasedDate, deceasedNote } = activityStatusInfo;

    let deactivationReasonBlock;

    switch (activeStatus) {
      case PATIENT_ACTIVE_STATUS.INACTIVE:
        deactivationReasonBlock = deactivationReason && (
          <div data-test="summaryStatusInfo_deactivationReason">
            <strong>Deactivation Reason: </strong>
            {deactivationReason}
          </div>);
        break;
      case PATIENT_ACTIVE_STATUS.DECEASED:
        deactivationReasonBlock = (
          <div className={`${deceasedDate || deceasedNote ? '' : 'd-none'}`}>
            {
              deceasedDate && (
                <Fragment>
                  <strong>Deceased Date: </strong>
                  <span className="mr-3">{moment(deceasedDate).format(DATE_FORMAT.FULL)}</span>
                </Fragment>)
            }
            {
              deceasedNote && (
                <Fragment>
                  <strong>Note: </strong>
                  <span className="mr-3">{deceasedNote}</span>
                </Fragment>)
            }
          </div>);
        break;
      case PATIENT_ACTIVE_STATUS.INELIGIBLE:
        deactivationReasonBlock = deactivationReason && (
          <div>
            <strong>Reason Ineligible: </strong>
            {deactivationReason}
          </div>);
        break;
      default:
    }

    return deactivationReasonBlock;
  };

  const renderActiveStatusBlock = () => {
    if (!activityStatusInfo) {
      return null;
    }
    const activeStatus = activityStatusInfo.status;
    const renderActiveStatusItems = Object.keys(PATIENT_ACTIVE_STATUS).map((value, index) => (
      <Dropdown.Item
        id={`activity-status-${value}`}
        key={`activity-status-${index}`}
        active={activeStatus === value}
        onClick={() => handleClickStatusButton(activeStatus, value)}
        data-test="summaryStatusInfo_patientActivityStatusOption"
      >
        {PATIENT_ACTIVE_STATUS[value]}
      </Dropdown.Item>
    ));

    return (
      <Fragment>
        <div className="d-flex align-items-center">
          <span className="text-uppercase mr-2">Activity status:</span>
          {isAdminUser ? (
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                className="px-0"
                data-test="summaryStatusInfo_activityStatus"
              >
                <span className="text-uppercase">{PATIENT_ACTIVE_STATUS[activeStatus] || '--'}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {renderActiveStatusItems}
              </Dropdown.Menu>
            </Dropdown>) : (
              <span className="text-uppercase">{PATIENT_ACTIVE_STATUS[activeStatus] || '--'}</span>
          )}
        </div>
        {renderDeactivationReasonBlock()}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="summary-status-info">
        <div className="text-left">
          <span className="text-uppercase mr-2">Current status:</span>
          <span
            className="text-uppercase"
            data-test="patientSummaryStatusValue"
          >
            {patientStatus}
          </span>
        </div>
        <div className="text-left">
          {renderActiveStatusBlock()}
        </div>
        {patientEnrollmentDateBlock}
        <div className="text-left mt-2 call-attempts">
          <div>
            <span className="text-uppercase mr-2">call attempts:</span>
            <span data-test="summaryStatusInfo_callAttempts" className="text-uppercase mr-2">{callAttempts}</span>
          </div>
          <div>
            <span className="text-uppercase mr-2">last direct:</span>
            <span data-test="summaryStatusInfo_lastDirect" className="text-uppercase mr-2">{directContactLabel}</span>
          </div>
          <div>
            <span className="text-uppercase mr-2">last attempt:</span>
            <span data-test="summaryStatusInfo_lastAttempt" className="text-uppercase mr-2">{callAttemptLabel}</span>
          </div>
        </div>
      </div>
      <ChangePatientStatusBlock
        setChangeStatusView={setChangeStatusView}
        reloadPatientInfo={reloadPatientInfo}
      />
      <ActiveStatusModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        newActivityStatusInfo={newActivityStatusInfo}
        submitCallback={updateActivityStatusInfo}
        data-test="summaryStatusInfo_activeStatusModal"
      />
    </Fragment>
  );
};

export default SummaryStatusInfo;
