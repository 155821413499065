// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
// Views
import GlobalLogo from './GlobalLogo';
import GlobalUser from './GlobalUser';
import Loading from '../../base/Loading';
import { CONFIG_NAME, INIT_HASHTAG } from '../../../constants/globalAdminUi';

export function GlobalTopBar() {
  const { tenant: tenantUrl } = useParams();
  const { showGlobalTopBar, globalTenant } = useSelector(state => state.tenant);
  const renderTopBarControls = () => {
    const topBarControls = [];

    /* The code below is a guide on how to add new icons to the snook project bar. */
    /* Also remember to add the new route and re-direct to that new route. */

    if (globalTenant) {
      const tenantUrlUpdated = tenantUrl === CONFIG_NAME ? INIT_HASHTAG : tenantUrl;
      topBarControls.push(
        <span className="snook d-flex-center mr-5" key="example_1">
          <NavLink
            to={`/${tenantUrlUpdated}/snook/list`}
            className={({ isActive }) => `d-flex-center${isActive ? ' active' : ''}`}
            data-test="globalTopBar_linkExample1"
          >
            <i className="snook-icon bi bi-person mr-1" />
            <span>Users</span>
          </NavLink>
        </span>,
      );
    }

    if (tenantUrl === INIT_HASHTAG) {
      const tenantUrlUpdated = tenantUrl === CONFIG_NAME ? INIT_HASHTAG : tenantUrl;
      topBarControls.push(
        <span className="snook d-flex-center mr-5" key="example_2">
          <NavLink
            to={`/${tenantUrlUpdated}/snook/list`}
            className={({ isActive }) => `d-flex-center${isActive ? ' active' : ''}`}
            data-test="globalTopBar_linkExample2"
          >
            <i className="snook-icon bi bi-people mr-1" />
            <span>Teams</span>
          </NavLink>
        </span>,
      );
    }

    /* it should be noted that the best practice would be to make
    a new component for each section with correct path. */

    return topBarControls;
  };

  return (
    <nav className="navbar navbar-expand-lg top-bar mt-2">
      {showGlobalTopBar && renderTopBarControls()}

      <span className="navbar-brand">
        <Loading />
        <GlobalLogo />
      </span>
      <GlobalUser />
    </nav>
  );
}

export default GlobalTopBar;
