// libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PromptModal from '../shared/PromptModal';
// Hooks
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt';
// constants
import messages from '../../constants/messages';

export const CustomPrompt = (props) => {
  const { isBlocked } = props;

  const [showDialog, setShowDialog] = useState(isBlocked);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  useEffect(() => {
    if (isBlocked !== showDialog) setShowDialog(isBlocked);
  }, [isBlocked]);

  const getPromptMessage = () => {
    const {
      isActivity, isBilling, isEditingCallNotes, isExistingActivity, isWorkingOnPrompts,
    } = props;

    // user tries to exit 'Time Tracking' page
    if (isActivity || isExistingActivity) return messages.leaveUnSavedTimeTracking;

    // user tries to exit 'Billing' page
    if (isBilling) return messages.leaveUnSavedBilling;

    // user tries to exit patient page, when is editing a call note
    if (isEditingCallNotes) return messages.leaveUnSavedData;

    // user tries to exit patient page, when is editing prompts
    if (isWorkingOnPrompts) return messages.leaveUnSavedPromptsData;

    return '';
  };

  return (
    <PromptModal
      isOpen={showPrompt}
      promptMsg={getPromptMessage()}
      promptTitle="Alert"
      cancelAction={cancelNavigation}
      proceedAction={confirmNavigation}
    />
  );
};

function mapStateToProps(state) {
  return {
    isBlocked: state.router.blocked,
    isActivity: state.router.elements && state.router.elements.isActivity,
    isExistingActivity: state.router.elements && state.router.elements.isExistingActivity,
    isBilling: state.router.elements && state.router.elements.isBilling,
    isEditingCallNotes: state.router.elements && state.router.elements.isEditingCallNotes,
    isWorkingOnPrompts: state.router.elements && state.router.elements.isWorkingOnPrompts,
  };
}

export default connect(mapStateToProps)(CustomPrompt);
