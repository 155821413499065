// libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
// views
import { Button } from 'react-bootstrap';
import BillingSection from './patientBilling/BillingSection';
import MedicalDiagnosisTable from './patientBilling/MedicalDiagnosisTable';
// constants
import {
  USER_BILLING_STATUSES, NOTIFICATION_TYPE, ELIGIBILITY_ISSUES,
} from '../../constants/constants';
// Actions
import ShowNotification from '../../actions/notification';
import { UpdatePatient } from '../../actions/patient';
// services
import { getBillingInfo, resendBillingReport } from '../../services/patient';
// Local Constants
const PCP_MESSAGE = 'If patient has a new PCP, you must also gain consent to continue with the CCM program. Follow the policy for PCP change.';


export function PatientBilling(props) {
  const { id: patientId } = useParams();
  const {
    patient = {}, patient: { billing } = {}, updatePatient,
  } = props;

  const permissions = billing && billing.permissions;
  const canNotBeSubmittedReason = billing && billing.canNotBeSubmittedReason;
  const billingReportCanBeResent = permissions && permissions.billingReportCanBeResent;

  const isShowBillingSection = (billing && billing.issues
    && USER_BILLING_STATUSES.includes(patient.status));

  const [displayPcpMessage, setDisplayPcpMessage] = useState(false);
  const [prevPhysicianName, setPrevPhysicianName] = useState('');

  const renderWarningBlock = () => {
    const isPatientBilled = USER_BILLING_STATUSES.includes(patient.status);

    if (patient.billing && patient.billing.issues && isPatientBilled) {
      const billingIssueBlocks = patient.billing.issues.map((issue, index) => {
        if (ELIGIBILITY_ISSUES[issue] && ELIGIBILITY_ISSUES[issue].menuTab === 'billing') {
          return (
            <div key={`billing-issues-${index}`}>
              {ELIGIBILITY_ISSUES[issue].name}
            </div>
          );
        }
        return null;
      });

      const isBillingWarningActive = billingIssueBlocks.filter(elem => !!elem);
      return !!isBillingWarningActive.length && (
        <div className="d-flex-center text-ccm-red">
          <span className="i-alert-triangle text-danger mr-1" />
          {billingIssueBlocks}
        </div>
      );
    }
    return null;
  };

  const renderPcpMessage = () => {
    if (displayPcpMessage) {
      return (
        <div className="d-flex-center text-ccm-red">
          <span className="i-alert-triangle mr-1" />
          <span>{PCP_MESSAGE}</span>
        </div>
      );
    }
    return null;
  };

  const resendBillingReportAction = () => {
    const { showNotification } = props;

    const resendBillingReportRequest = resendBillingReport(patientId);
    const resendBillingReportPromise = resendBillingReportRequest.promise;

    resendBillingReportPromise.then(() => {
      delete resendBillingReportRequest.promise;
      updatePatient(
        {
          billing:
            { ...billing, permissions: { ...permissions, billingReportCanBeResent: false } },
        },
      );

      showNotification({
        message: 'Billing report was re-sent.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete resendBillingReportRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not resend billing report, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderResendDftBlock = () => {
    let resendDftBlock;

    if (billingReportCanBeResent) {
      let billingResendText = 'Billing report was not submitted for technical reason. Please resend report.';
      if (canNotBeSubmittedReason === 'TCM_ENROLLMENT') {
        billingResendText = 'Billing report was not submitted because of patient is enrolled to TCM program. Please resend report.';
      }

      resendDftBlock = (
        <div className="py-3 text-center border mb-2">
          <div className="text-ccm-blue mb-2">
            {billingResendText}
          </div>
          <div className="d-flex-center">
            <Button
              data-test="patientBilling_resendBillingReportButton"
              size="sm"
              variant="link"
              className="text-dark"
              onClick={resendBillingReportAction}
            >
              Resend Billing Report
            </Button>
          </div>
        </div>
      );
    }
    return resendDftBlock;
  };

  useEffect(() => {
    const { showNotification } = props;
    const getBillingInfoRequest = getBillingInfo(patientId);
    const getBillingInfoPromise = getBillingInfoRequest.promise;

    getBillingInfoPromise.then((data) => {
      delete getBillingInfoRequest.promise;

      let problems = [];
      if (data.problems && data.problems.length) {
        problems = data.problems.map(problem => ({
          ...problem,
          newIndex: `pc${problem.id}`,
        }));
      }

      const billingInfo = {
        ...data,
        problems,
      };
      updatePatient({ billing: { ...billing, billingInfo } });
    }).catch((error) => {
      delete getBillingInfoRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not load billing information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }, []);

  return (
    <div className="billing-section h-100 pr-2 overflow-auto">
      <h4 className="text-uppercase text-left">Billing</h4>
      {renderResendDftBlock()}
      {renderWarningBlock()}
      {renderPcpMessage()}
      {isShowBillingSection && (
        <BillingSection
          patientId={patientId}
          prevPhysicianName={prevPhysicianName}
          setPrevPhysicianName={setPrevPhysicianName}
          setDisplayPcpMessage={setDisplayPcpMessage}
        />
      )}
      <MedicalDiagnosisTable patientId={patientId} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientBilling);
