// libraries
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

export function ReportsControl() {
  const { tenant: tenantUrl } = useParams();
  return (
    <span className="reports-control d-flex-center mr-5">
      <NavLink
        to={`/${tenantUrl}/cn/reports`}
        className={({ isActive }) => `d-flex-center${isActive ? ' active' : ''}`}
        data-test="reportsControl_link"
      >
        <i className="reports-control-icon bi bi-clipboard-data mr-1" />
        <span>Reports</span>
      </NavLink>
    </span>
  );
}

export default ReportsControl;
