import { createAction, createReducer } from '@reduxjs/toolkit';

const patientsGenerated = createAction('PATIENTS_GENERATED');

const initialState = [];

const PatientsGenerated = createReducer(initialState, (builder) => {
  builder
    .addCase(patientsGenerated, (state, action) => action.patientsGenerated)
    .addDefaultCase(state => state);
});

export default PatientsGenerated;
