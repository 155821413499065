// Constants
import {
  CALL_TIME_OPTIONS, ENGOODEN_TEXT_OPTIONS, INACTIVE_PES_REASONS,
  INELIGIBLE_PES_REASONS, SCHEDULE_SENSITIVE_OPTIONS,
} from './constants';


export const PATIENT_MESSAGE = {
  successfullyEnrolled: 'Patient enrolled successfully.',
  finalizedSuccessfully: 'Patient finalized successfully.',
};

export const PATIENT_STATUS_SPECIFIC_OPTIONS = ['CN', 'CS', 'CC', 'I', 'X', 'DC', 'DP'];

export const QUALIFIED_PATIENT_STATUS = ['N', 'P', 'P2', 'P3'];

export const NON_QUALIFIED_PATIENTS_MESSAGES = {
  enroll: 'In order to enroll, this patient first needs to be moved to a pending enrollment status. Contact your manager to get them placed into the appropriate status.',
  documentDecline: 'In order to document a decline, this patient first needs to be moved to a pending enrollment status. Contact your manager to get them placed into the appropriate status.',
  otherActions: 'In order to continue, this patient first needs to be moved to a pending enrollment status. Contact your manager to get them placed into the appropriate status.',
};

export const PATIENT_STATUS = {
  declineByPatient: 'DP',
  patientSuspended: 'PS',
};

export const ENROLL_OPTIONS = [
  {
    id: 'ccmValue',
    title: '1. CCM Value',
    detail: 'Detail to the patient the ways in which CCM can provide them with care outside of their doctor’s office.',
    checkboxLabel: 'I have explained the value of CCM to the patient',
    checked: false,
    addButton: false,
    htmlId: 'ccm-value-script-hint',
  }, {
    id: 'costShare',
    title: '2. Cost Share',
    detail: 'Explain that there may be a co-pay associated with the CCM program. Point out that services can be terminated at any time.',
    checkboxLabel: 'I have explained cost share and the right to terminate services to the patient',
    checked: false,
    addButton: false,
    htmlId: 'cost-share-script-hint',
  }, {
    id: 'eligibilityQuestions',
    title: '3. Eligibility Questions',
    detail: 'Ask the patient the appropriate questions to ensure they are eligible for the CCM program.',
    checkboxLabel: 'I have asked the appropriate eligibility questions to the patient',
    checked: false,
    addButton: false,
    htmlId: 'eligibility-script-hint',
  }, {
    id: 'patientPreferences',
    title: '4. Specify Patient Preferences',
    detail: 'Use the modal provided to specify the patients call schedule and phone preferences.',
    checkboxLabel: 'I have asked the patient about their preferences and documented them',
    checked: false,
    addButton: true,
    htmlId: 'preferences-script-hint',
  }, {
    id: 'nextSteps',
    title: '5. Next Steps',
    detail: 'Inform the patient that their assigned Care Navigator will be reaching out in the next two days.',
    checkboxLabel: 'I have informed the patient about the next steps in the enrollment process',
    checked: false,
    addButton: false,
    htmlId: 'next-steps-script-hint',
  },
];

export const PREFERENCES_CALL_TIME_OPTIONS = [
  {
    value: CALL_TIME_OPTIONS.earlyMorning.value,
    label: `${CALL_TIME_OPTIONS.earlyMorning.name} ${CALL_TIME_OPTIONS.earlyMorning.extraLabel}`,
  }, {
    value: CALL_TIME_OPTIONS.morning.value,
    label: `${CALL_TIME_OPTIONS.morning.name} ${CALL_TIME_OPTIONS.morning.extraLabel}`,
  }, {
    value: CALL_TIME_OPTIONS.lateMorning.value,
    label: `${CALL_TIME_OPTIONS.lateMorning.name} ${CALL_TIME_OPTIONS.lateMorning.extraLabel}`,
  }, {
    value: CALL_TIME_OPTIONS.earlyAfternoon.value,
    label: `${CALL_TIME_OPTIONS.earlyAfternoon.name} ${CALL_TIME_OPTIONS.earlyAfternoon.extraLabel}`,
  }, {
    value: CALL_TIME_OPTIONS.afternoon.value,
    label: `${CALL_TIME_OPTIONS.afternoon.name} ${CALL_TIME_OPTIONS.afternoon.extraLabel}`,
  }, {
    value: CALL_TIME_OPTIONS.lateAfternoon.value,
    label: `${CALL_TIME_OPTIONS.lateAfternoon.name} ${CALL_TIME_OPTIONS.lateAfternoon.extraLabel}`,
  }, {
    value: CALL_TIME_OPTIONS.anyTime.value,
    label: `${CALL_TIME_OPTIONS.anyTime.name} ${CALL_TIME_OPTIONS.anyTime.extraLabel}`,
  },
  { value: CALL_TIME_OPTIONS.noneSpecified.value, label: CALL_TIME_OPTIONS.noneSpecified.name },
];

export const PREFERENCES_SCHEDULE_SENSITIVE_OPTIONS = [
  { value: SCHEDULE_SENSITIVE_OPTIONS.yes.value, label: SCHEDULE_SENSITIVE_OPTIONS.yes.name },
  { value: SCHEDULE_SENSITIVE_OPTIONS.no.value, label: SCHEDULE_SENSITIVE_OPTIONS.no.name },
  { value: 'NONE', label: SCHEDULE_SENSITIVE_OPTIONS.none.name },
];

export const PREFERENCES_ENGOODEN_TEXT_OPTIONS = [
  { value: ENGOODEN_TEXT_OPTIONS.allTexts.value, label: ENGOODEN_TEXT_OPTIONS.allTexts.name },
  { value: ENGOODEN_TEXT_OPTIONS.noTexts.value, label: ENGOODEN_TEXT_OPTIONS.noTexts.name },
  { value: ENGOODEN_TEXT_OPTIONS.noCellPhone.value, label: ENGOODEN_TEXT_OPTIONS.noCellPhone.name },
  { value: ENGOODEN_TEXT_OPTIONS.none.value, label: ENGOODEN_TEXT_OPTIONS.none.name },
];

export const FIELD_STYLES = {
  formGroup: 'd-flex',
  formLabel: 'w-75 mb-3',
  formInvalid: 'position-absolute fixed-bottom pl-3',
};

export const TIME_TRACKING_INPUT_STYLES = {
  formGroup: 'd-flex mb-1',
  formLabel: 'w-50 mb-4',
  formControl: 'w-50',
  formInvalid: 'position-absolute fixed-bottom text-nowrap pl-3',
};

export const TIME_TRACKING_SELECT_STYLES = {
  formGroup: 'd-flex mb-4',
  formInvalid: 'position-absolute fixed-bottom pl-3',
};

export const DEFAULT_PATIENT_PREFERENCES = {
  preferredName: '',
  preferredPhone: '',
  customPhone: '',
  callTime: 'NONE',
  scheduleSensitive: 'NONE',
  engoodenTexts: 'NONE',
};

export const CHECKLIST_TYPES = {
  VERIFIED: { id: 'VERIFIED', label: 'I attest that I have verified:' },
  INFORMED: { id: 'INFORMED', label: 'I attest that the patient has been informed the following:' },
  ANSWERD_NO: { id: 'ANSWERD_NO', label: 'I attest that the patient has answered NO to the following:' },
  ANSWERD_YES: { id: 'ANSWERD_YES', label: 'I attest that the patient has answered YES to:' },
};

export const CHECKLIST_OPTIONS = [
  {
    id: 'patientName',
    checked: false,
    label: 'Patient Name',
    type: CHECKLIST_TYPES.VERIFIED.id,
  }, {
    id: 'dateBirth',
    checked: false,
    label: 'Date of Birth',
    type: CHECKLIST_TYPES.VERIFIED.id,
  }, {
    id: 'insuranceInformation',
    checked: false,
    label: 'Patient Insurance Information',
    type: CHECKLIST_TYPES.VERIFIED.id,
  }, {
    id: 'preferredPhone',
    checked: false,
    label: 'Patient preferred phone number',
    type: CHECKLIST_TYPES.VERIFIED.id,
  }, {
    id: 'textingPreference',
    checked: false,
    label: 'Patient texting preference',
    type: CHECKLIST_TYPES.VERIFIED.id,
  }, {
    id: 'phonecalls',
    checked: false,
    label: 'All phone calls will be recorded for quality assurance and general record keeping purposes',
    type: CHECKLIST_TYPES.INFORMED.id,
  }, {
    id: 'costSharing',
    checked: false,
    label: 'You will still be responsible for any cost-sharing associated with your medicare [or insurance] plan for this program',
    type: CHECKLIST_TYPES.INFORMED.id,
  }, {
    id: 'planCoverage',
    checked: false,
    label: 'Depending on your insurance and individual plan coverage, you may receive a bill or co-pay for this service, similar to most other healthcare services',
    type: CHECKLIST_TYPES.INFORMED.id,
  }, {
    id: 'annualDeductible',
    checked: false,
    label: 'The cost-share amount may depend on where you are in your annual deductible, so you may receive a bill early in the year, but once your deductible is met you may not receive a bill for the program',
    type: CHECKLIST_TYPES.INFORMED.id,
  }, {
    id: 'stopServices',
    checked: false,
    label: 'You can stop these services at any time',
    type: CHECKLIST_TYPES.INFORMED.id,
  }, {
    id: 'oneProvider',
    checked: false,
    label: 'Only one provider can bill monthly for this program, are you receiving services like this with any other provider?',
    type: CHECKLIST_TYPES.ANSWERD_NO.id,
  }, {
    id: 'rehab',
    checked: false,
    label: 'Are you currently living in a rehab or long-term nursing home?',
    type: CHECKLIST_TYPES.ANSWERD_NO.id,
  }, {
    id: 'hospiceCare',
    checked: false,
    label: 'Are you receiving hospice care?',
    type: CHECKLIST_TYPES.ANSWERD_NO.id,
  }, {
    id: 'enrollProgram',
    checked: false,
    label: 'Do you consent to enroll in the Chronic Care Management program provider by [Partner Name]?',
    type: CHECKLIST_TYPES.ANSWERD_YES.id,
  },
];

const STATUS_LABELS = {
  pendingEnrollment: 'Pending Enrollment',
  eligibleNotEnrolled: 'Eligible Not Enrolled',
  needsVisit: 'Needs Office Visit',
  pendingSuspended: 'Pending Suspended',
  currentlyEnrolled: 'Currently Enrolled',
  enrolledSuspended: 'Enrolled Suspended',
  inactiveIneligible: 'Inactive/Ineligible',
  declinedPatient: 'Declined by Patient',
  declinedCareProvider: 'Declined by Care Provider',
  disenrolled: 'Dis-enrolled',
  unabletoReach: 'Unable to Reach',
};

export const PATIENT_ENROLLMENT_STATUS_LABELS = {
  P: STATUS_LABELS.pendingEnrollment,
  P2: STATUS_LABELS.pendingEnrollment,
  P3: STATUS_LABELS.pendingEnrollment,
  N: STATUS_LABELS.eligibleNotEnrolled,
  PN: STATUS_LABELS.needsVisit,
  PS: STATUS_LABELS.pendingSuspended,
  CC: STATUS_LABELS.currentlyEnrolled,
  CN: STATUS_LABELS.currentlyEnrolled,
  CS: STATUS_LABELS.enrolledSuspended,
  I: STATUS_LABELS.inactiveIneligible,
  DP: STATUS_LABELS.declinedPatient,
  DC: STATUS_LABELS.declinedCareProvider,
  X: STATUS_LABELS.disenrolled,
  E: STATUS_LABELS.unabletoReach,
};

export const DECLINE_DETAILS_DATE_OPTIONS = [
  { id: 'threeMonths', label: '3 months', value: '3' },
  { id: 'sixMonths', label: '6 months', value: '6' },
  { id: 'nineMonths', label: '9 months', value: '9' },
];

export const OTHER_ACTIONS_CODES = {
  patientLeftPractice: { id: 'patientLeftPractice', label: 'Patient has left the practice' },
  invalidPhone: { id: 'invalidPhone', label: 'Invalid Phone Number' },
  languageGap: { id: 'languageGap', label: 'Language Gap' },
  patientDeceased: { id: 'patientDeceased', label: 'Patient is deceased' },
  patientInsuranceChange: { id: 'patientInsuranceChange', label: 'Patient’s insurance has changed & new insurance does not cover CCM' },
  patientIneligible: { id: 'patientIneligible', label: 'Patient is ineligible to receive care due to services received elsewhere' },
  patientNotReachable: { id: 'patientNotReachable', label: 'Patient not reachable after 6 call attempts' },
};

export const OTHER_ACTIONS_OPTIONS = [
  { ...OTHER_ACTIONS_CODES.patientLeftPractice },
  { ...OTHER_ACTIONS_CODES.invalidPhone },
  { ...OTHER_ACTIONS_CODES.languageGap },
  { ...OTHER_ACTIONS_CODES.patientDeceased },
  { ...OTHER_ACTIONS_CODES.patientInsuranceChange },
  { ...OTHER_ACTIONS_CODES.patientIneligible },
  { ...OTHER_ACTIONS_CODES.patientNotReachable },
];

export const SHOW_OTHER_ACTIONS_REASON = [
  OTHER_ACTIONS_CODES.patientIneligible.id,
  OTHER_ACTIONS_CODES.patientLeftPractice.id,
  OTHER_ACTIONS_CODES.patientInsuranceChange.id,
];

export const SELECT_COMPONENT = {
  [OTHER_ACTIONS_CODES.patientLeftPractice.id]: {
    label: 'Deactivation Reason:',
    options: INACTIVE_PES_REASONS,
    name: 'deactivationReason',
  },
  [OTHER_ACTIONS_CODES.patientIneligible.id]: {
    label: 'Ineligibility Reason:',
    options: INELIGIBLE_PES_REASONS,
    name: 'ineligibilityReason',
  },
  [OTHER_ACTIONS_CODES.patientInsuranceChange.id]: {
    label: 'Ineligibility Reason:',
    options: INELIGIBLE_PES_REASONS,
    name: 'insuranceNoLongerReason',
  },
};

export const PLACEHOLDER_MESSAGES = {
  declineFutureCall: 'Make sure to note any concerns that the patient has around enrolling. For example, if they are worried about cost, or if they might not need the program. This will help when we reach out again for our next enrollment call.',
};

export const ENROLL_ERROR_MESSAGES = {
  blankNod: 'NOD must be set before you can finalize',
  pastNod: 'Next Outreach Date cannot be in the past',
  hippaConsent: 'HIPAA Consent must be selected before you can finalize',
};

export const CAN_GO_HOMEPAGE = [
  OTHER_ACTIONS_CODES.patientLeftPractice.id,
  OTHER_ACTIONS_CODES.patientDeceased.id,
  OTHER_ACTIONS_CODES.patientIneligible.id,
  OTHER_ACTIONS_CODES.patientInsuranceChange.id,
];
export const CAN_GO_HOMEPAGE_PES_FLOW = [
  OTHER_ACTIONS_CODES.patientLeftPractice.id,
  OTHER_ACTIONS_CODES.patientDeceased.id,
  OTHER_ACTIONS_CODES.patientIneligible.id,
  OTHER_ACTIONS_CODES.patientInsuranceChange.id,
];
