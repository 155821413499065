// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getHolidays } from '../../services/holiday';

const useLoadHolidays = () => {
  const [holidays, setHolidays] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchHolidays = useCallback(async () => {
    const getHolidaysRequest = getHolidays();
    const getHolidaysPromise = getHolidaysRequest.promise;
    try {
      const fetchedHolidays = await getHolidaysPromise;

      setHolidays(fetchedHolidays);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load holidays.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, []);

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  return { holidays };
};

export default useLoadHolidays;
