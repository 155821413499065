import _isNaN from 'lodash/isNaN';
import { makeAuthorizedRequest } from './requests';
import { formQueryString } from './helpers';
import { SEARCH_PARAMS } from '../constants/constants';

const sessionLoaded = 'isSessionLoaded';

export function searchPatients(pageSize, params) {
  const allParams = [...params];
  const stringParams = { pageSize };
  const data = {};

  allParams.forEach((param) => {
    if ([
      SEARCH_PARAMS.SORT_BY.key,
      SEARCH_PARAMS.SORT_ORDER.key,
      SEARCH_PARAMS.PAGE_NUMBER.key,
    ].some(item => param.name === item)
    ) {
      stringParams[param.name] = param.value;
      return;
    }

    if (param.name !== sessionLoaded && param.name !== SEARCH_PARAMS.SERVICES.key) {
      if (param.name === SEARCH_PARAMS.CARE_NAVIGATOR_ID.key) {
        data[param.name] = +param.value;
      } else if (typeof param.value.trim === 'function') {
        data[param.name] = param.value.trim();
      } else {
        data[param.name] = param.value;
      }
    }
  });

  const queryString = formQueryString(stringParams);

  return makeAuthorizedRequest('POST', `/patients${queryString}`, data);
}

export function getPatientsStatistic(parameters = {}) {
  let data = {};

  const isSearchEmpty = !Object.keys(parameters).length
    || (Object.keys(parameters).length === 1 && parameters.empty);

  if (!isSearchEmpty) {
    data = { ...parameters };
  }

  return makeAuthorizedRequest('POST', '/patients/statistic', data);
}

export function selectPatients(pageSize = 0, params, count = 0) {
  const allParams = [...params];
  const stringParams = { pageSize };
  const data = {};
  let pageNumber = 0;

  allParams.forEach(({ name, value }) => {
    if (
      [SEARCH_PARAMS.SORT_BY.key, SEARCH_PARAMS.SORT_ORDER.key].some(item => name === item)
    ) {
      stringParams[name] = value;
      return;
    }

    switch (name) {
      case SEARCH_PARAMS.CARE_NAVIGATOR_ID.key: {
        data[name] = +value;
        break;
      }
      case SEARCH_PARAMS.SERVICES.key: {
        data[name] = value;
        break;
      }
      case SEARCH_PARAMS.PAGE_NUMBER.key: {
        pageNumber = +value;
        break;
      }
      case SEARCH_PARAMS.LANGUAGES.key: {
        data[name] = value;
        break;
      }
      default: {
        data[name] = _isNaN(value) ? value.trim() : value;
      }
    }
  });

  if (count) {
    stringParams.limit = count;
    stringParams.offset = stringParams.pageSize * pageNumber;
  }

  const queryString = formQueryString(stringParams);

  return makeAuthorizedRequest('POST', `/patients/select${queryString}`, data);
}

export function updatePatientsStatus(patientsIds, status, reason, bulkReason, title, comment) {
  return makeAuthorizedRequest('PUT', '/patients/status', {
    patientsIds, newStatus: status, reason, bulkReason, title, comment,
  });
}

export function updatePatientsStatusV2(patientsIds, newStatus, reason, note) {
  return makeAuthorizedRequest('PUT', '/patients/status/V2', {
    patientsIds, newStatus, reason, note,
  });
}

export function updatePatientsBulkStatus(patientsIds, newStatus, reason, note) {
  return makeAuthorizedRequest('PUT', '/patients/status/bulk', {
    patientsIds, newStatus, reason, note,
  });
}

export function getLanguageCodes() {
  return makeAuthorizedRequest('GET', '/languages');
}

export function getSuspendReasons() {
  return makeAuthorizedRequest('GET', '/patients/suspend/reasons');
}

export function bulkUpdateNextActionDate(date, patientsIds) {
  return makeAuthorizedRequest('PUT', '/patients/nextactiondate', { date, patientsIds });
}

export function searchQaPatients(pageSize, params) {
  const allParams = [...params];
  const stringParams = { pageSize };
  const data = {};

  allParams.forEach((param) => {
    if ([
      SEARCH_PARAMS.SORT_BY.key,
      SEARCH_PARAMS.SORT_ORDER.key,
      SEARCH_PARAMS.PAGE_NUMBER.key,
    ].some(item => param.name === item)
    ) {
      stringParams[param.name] = param.value;
      return;
    }

    if (param.name !== sessionLoaded) {
      if (param.name === SEARCH_PARAMS.CARE_NAVIGATOR_ID.key) {
        data[param.name] = +param.value;
      } else if (
        param.name !== SEARCH_PARAMS.SERVICES.key
        && param.name !== SEARCH_PARAMS.LANGUAGES.key
        && param.name !== SEARCH_PARAMS.PHYSICIAN_ID.key
        && param.name !== SEARCH_PARAMS.HAS_REMINDERS.key
        && param.name !== SEARCH_PARAMS.CALL_EARLY_MONTH.key
        && param.name !== SEARCH_PARAMS.CHRONIC_CONDITION_IDS.key
        && param.name !== SEARCH_PARAMS.BILLING_REPORT_STATUS.key
        && param.name !== SEARCH_PARAMS.PRIMARY_PHYSICIAN_LOCATION_ID.key
        && param.name !== SEARCH_PARAMS.TAG_IDS.key) {
        data[param.name] = param.value.trim();
      } else {
        data[param.name] = param.value;
      }
    }
  });

  const queryString = formQueryString(stringParams);

  return makeAuthorizedRequest('POST', `/patients/qa${queryString}`, data);
}
