// actions
import { AddRequestToProgress, RemoveRequestFromProgress } from '../actions/requestsInProgress';
// redux
import { store } from '../config/store';

/**
 * This  method can be used to add cancel to promise
 * @param promise -- promise
 * @param cancelledCallback -- callback after cancel promise
 */
export function makeCancelablePromise(promise, cancelledCallback) {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    store.dispatch(AddRequestToProgress());

    promise.then((val) => {
      if (hasCanceled) {
        reject({ isCanceled: true });
      } else {
        store.dispatch(RemoveRequestFromProgress());
        resolve(val);
      }
    }).catch((error) => {
      if (hasCanceled) {
        reject({ isCanceled: true });
      } else {
        store.dispatch(RemoveRequestFromProgress());
        reject(error);
      }
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;

      store.dispatch(RemoveRequestFromProgress());

      if (cancelledCallback && (typeof cancelledCallback === 'function')) {
        cancelledCallback();
      }
    },
  };
}
