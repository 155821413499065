// libraries
import React, {
  useContext, useEffect, useState, Fragment,
} from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import moment from 'moment';
import { Accordion, Card, Button } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
// Services
import { deleteSurveyAttempt, deleteSurveyCompletion } from '../../../../services/patient';
// Actions
import ShowNotification from '../../../../actions/notification';
// Constants
import {
  NOTIFICATION_TYPE, DATE_FORMAT, SATISFACTION_SURVEYS_QUESTIONS, USER_ROLES,
} from '../../../../constants/constants';
// Hooks
import useConfirm from '../../../../hooks/useConfirm';

const SatisfactionSurveysRow = (props) => {
  const { surveyList, handleGetSurveys, handleEditSurvey } = props;
  const dispatch = useDispatch();
  const { isConfirmed } = useConfirm();
  const { id: patientId } = useParams();
  const [collapseAllParent] = useState(true);
  const {
    user: {
      firstName, lastName, role,
    },
  } = useSelector(state => state);

  const isAdminUser = role === USER_ROLES.ADMIN;
  const userName = `${firstName} ${lastName}`;

  const removeSurveyAttempt = (idItem) => {
    const deleteSurveyAttemptRequest = deleteSurveyAttempt(patientId, idItem);
    const deleteSurveyAttemptPromise = deleteSurveyAttemptRequest.promise;

    return deleteSurveyAttemptPromise.then(() => {
      delete deleteSurveyAttemptRequest.promise;
      handleGetSurveys();
    }).catch((error) => {
      delete deleteSurveyAttemptRequest.promise;

      if (error.status === 401 || error.status === 403) {
        return;
      }

      dispatch(ShowNotification({
        message: 'An error occurred while getting the surveys',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const removeSurveyCompletion = (idItem) => {
    const deleteSurveyCompletionRequest = deleteSurveyCompletion(patientId, idItem);
    const deleteSurveyCompletionPromise = deleteSurveyCompletionRequest.promise;

    return deleteSurveyCompletionPromise.then(() => {
      delete deleteSurveyCompletionRequest.promise;
      handleGetSurveys();
    }).catch((error) => {
      delete deleteSurveyCompletionRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      dispatch(ShowNotification({
        message: 'An error occurred while getting the surveys',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const getType = (user, type) => {
    if (user) {
      switch (type) {
        case 'CALL':
          return 'Attempted Call';
        case 'TEXT':
          return 'Text Sent';
        case 'PT_SAT_REMINDER':
          return 'Reminder Text Sent';
        default:
          return 'Call Completed';
      }
    }
    return 'Text Response';
  };

  const handleDeleteSurvey = async (user, type, idItem) => {
    const confirmed = await isConfirmed('Are you sure you want to remove the selected survey?');

    if (!confirmed) return;

    const surveyType = getType(user, type);
    if (surveyType === 'Attempted Call') {
      removeSurveyAttempt(idItem);
    } else {
      removeSurveyCompletion(idItem);
    }
  };

  const canHandleAction = (user, type) => {
    const surveyType = getType(user, type);
    return surveyType === 'Attempted Call' || surveyType === 'Call Completed';
  };

  const handleEditClick = (survey) => {
    handleEditSurvey(survey);
  };

  const questionRow = (question, answer, size) => (
    <div className={`col-${size} d-flex`}>
      <p>{question}</p>
      <p>
        &nbsp;
        {answer}
      </p>
    </div>
  );


  if (surveyList.length) {
    const surveyListByDate = _reverse(_sortBy(surveyList, el => moment(el.date)));
    return surveyListByDate.map((survey, index) => (
      <tr key={`survey_row_${index}-${survey.id}}`} data-test="survey_entryRow">
        <td colSpan="4" className="p-0">
          <Accordion>
            <Card className="border-0 rounded-0">
              <Card.Header className="bg-light border-0 p-0">
                <table className="table mb-0">
                  <tbody>
                    <tr data-test="survey_entryRowDetail">
                      <td className="w-25">
                        {moment(survey.date).format(DATE_FORMAT.FULL)}
                      </td>
                      <td className="w-25">
                        {getType(survey.surveyedBy, survey.type)}
                      </td>
                      <td className="w-25">
                        {survey.surveyedBy || 'System'}
                      </td>
                      <td className="text-right w-25">
                        {userName === survey.surveyedBy
                          && canHandleAction(survey.surveyedBy, survey.type) && (
                          <Button
                            variant="link-dark"
                            className="p-0"
                            data-test="survey_deleteBtn"
                            onClick={
                              () => handleDeleteSurvey(survey.surveyedBy, survey.type, survey.id)
                            }
                          >
                            <i className="bi-trash" />
                          </Button>
                        )}
                        {userName === survey.surveyedBy && survey.note
                          && canHandleAction(survey.surveyedBy, survey.type) && (
                          <Button
                            variant="link-dark"
                            className="py-1"
                            data-test="survey_editBtn"
                            onClick={() => handleEditClick(survey)}
                          >
                            <i className="bi-pencil" />
                          </Button>
                        )}
                        {(userName === survey.surveyedBy || isAdminUser)
                          && canHandleAction(survey.surveyedBy, survey.type) && (
                          <CustomToggle eventKey="1" collapseAll={collapseAllParent} />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="px-3 pt-1" data-test="satisfactionSurveyRow_collapseView">
                  {survey.note ? (
                    <div className="row py-1 align-items-center">
                      {survey.note && questionRow(
                        SATISFACTION_SURVEYS_QUESTIONS.questionFive.row, survey.note, 11,
                      )}
                    </div>
                  ) : (<Fragment>
                    <div className="row py-1">
                      {survey.question1 && questionRow(
                        SATISFACTION_SURVEYS_QUESTIONS.questionOne.row, survey.question1, 5,
                      )}
                      {survey.question2 && questionRow(
                        SATISFACTION_SURVEYS_QUESTIONS.questionTwo.row, survey.question2, 7,
                      )}
                    </div>
                    <div className="row py-1">
                      {survey.question3 && questionRow(
                        SATISFACTION_SURVEYS_QUESTIONS.questionThree.row, survey.question3, 5,
                      )}
                      {survey.question4 && questionRow(
                        SATISFACTION_SURVEYS_QUESTIONS.questionFour.row, survey.question4, 7,
                      )}
                    </div>
                    <div className="row py-1">
                      {survey.question5 && questionRow(
                        SATISFACTION_SURVEYS_QUESTIONS.questionFive.row, survey.question5, 12,
                      )}
                    </div>
                  </Fragment>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </td>
      </tr>
    ));
  }

  return <tr><td colSpan="4" className="border-0">This patient has no known sat survey responses</td></tr>;
};

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0 pl-3" onClick={decoratedOnClick} data-test="survey_toggleBtn">
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

export default SatisfactionSurveysRow;
