// Libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Actions
import { SetGlobalLogin, SetGlobalTenant } from '../../../../actions/tenants';
import { Loading } from '../../../base/Loading';


export const GlobalRedirectUser = () => {
  const navigate = useNavigate();
  const { user: { tenants } } = useSelector(state => state);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(3);

  const navigateToTenant = () => {
    dispatch(SetGlobalLogin(true));
    dispatch(SetGlobalTenant(false));
    navigate(`/${tenants[0].tenantSystemName}`);
  };

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    if (counter === 0) {
      navigateToTenant();
    }
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className="ccm-snook-list d-flex-center h-100">
      <div className="col-7 d-flex-center">
        <div className="wrapper-list flex-grow-1 p-4">
          <div className="py-5">
            <Loading forceLoading={counter} />
            <div className="pt-2">
              <span>Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalRedirectUser;
