// Libraries
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from 'react-modal';
import _clone from 'lodash/clone';
// Components
import { Button, Form } from 'react-bootstrap';
import { Select } from '../../base/forms/Select';
import { TextArea } from '../../base/forms/TextArea';
// Constants
import { DIALOG_STYLES, SUSPEND_BILLING_CATEGORIES } from '../../../constants/constants';
// Local Constants
export const DEFAULT_SUSPEND = {
  category: null,
  reason: '',
};
const SUSPEND_SCHEMA = () => Yup.object({
  category: Yup.string()
    .typeError('Select a valid category')
    .required('Required'),
  reason: Yup.string()
    .typeError('Select a valid category'),
});

export const SuspendBillingModal = (props) => {
  const {
    isModalOpen, setIsModalOpen, onHoldStatusInfo, saveSuspendBilling,
  } = props;

  const [newSuspend, setNewSuspend] = useState(DEFAULT_SUSPEND);

  useEffect(() => {
    const { onHold } = onHoldStatusInfo;
    if (onHold) {
      const newOnHold = _clone(onHoldStatusInfo);
      newOnHold.reason = newOnHold.reason || '';
      setNewSuspend(newOnHold);
    } else {
      setNewSuspend(DEFAULT_SUSPEND);
    }
  }, [onHoldStatusInfo]);

  const handleCloseModal = () => setIsModalOpen(false);

  const createObject = ({ category, reason }) => ({
    onHold: true,
    category,
    reason,
  });

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Suspend Billing"
      data-test="suspendBilling_onRequestClose"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          Suspend Billing
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="suspendBilling_closeBtn"
          />
        </div>
        <Formik
          initialValues={newSuspend}
          validationSchema={SUSPEND_SCHEMA()}
          onSubmit={(values) => { saveSuspendBilling(createObject(values)); }}
          data-test="suspendBilling_formikComponent"
        >
          {formik => (
            <Form>
              <div className="dialog-content px-4 text-left">
                <div className="alert alert-warning text-left px-2">
                  <span className="bi bi-exclamation-triangle-fill text-ccm-orange mr-2" />
                  <span className="text-dark warning-note">
                    This action will prevent bills from being sent
                    for this patient until the setting is turned off
                  </span>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Select
                      label="Category"
                      name="category"
                      options={SUSPEND_BILLING_CATEGORIES.filter(item => item.active)}
                    />
                  </div>
                  <div className="col-12">
                    <TextArea
                      label="Reason"
                      rows={12}
                      name="reason"
                      data-test="suspendBillingModal_reasonTextBox"
                    />
                  </div>
                </div>
              </div>
              <div className="dialog-buttons justify-content-end mt-0 mb-4 px-4">
                <Button
                  variant="light"
                  onClick={() => handleCloseModal()}
                  data-test="suspendBilling_cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="suspendBilling_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default SuspendBillingModal;
