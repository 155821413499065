// Libraries
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { useParams, useNavigate } from 'react-router-dom';
// Constants
import { SUPER_USER_ROLES, SUPER_ADMIN_TOP_MENU_ITEMS } from '../../../constants/globalAdminUi';
// Services
import { logoutGlobalUser } from '../../../services/globalServices/login';

export const GlobalTopMenu = () => {
  const { user: { roleName } } = useSelector(state => state);
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();
  const { tenant: tenantUrl } = useParams();

  const handleLogout = () => {
    logoutGlobalUser(keycloak);
  };

  const goTo = (url) => {
    navigate(url);
  };

  const pushMenuItems = items => items.map((item, index) => {
    const url = `/${tenantUrl}/snook/${item.url}`;

    return (
      <Dropdown.Item
        key={`top-menu-item-${index}`}
        onClick={() => goTo(url, item.title.toLowerCase())}
        data-test={`globalTopMenu_${item.url}_menuItem`}
      >
        {item.title}
      </Dropdown.Item>
    );
  });

  const renderMenuItems = () => {
    const menuItems = [];

    // Push Super Admin Items
    if (roleName === SUPER_USER_ROLES.SUPER_ADMIN) {
      menuItems.push(pushMenuItems(SUPER_ADMIN_TOP_MENU_ITEMS));
    }

    menuItems.push(<Fragment key="fragment">
      <Dropdown.Divider key="divider" />
      <Dropdown.Item
        key="logout"
        onClick={() => handleLogout()}
        data-test="globalTopMenu_logoutMenuItem"
      >
        Logout
      </Dropdown.Item>
    </Fragment>);

    return menuItems;
  };

  return (
    <Dropdown className="user-menu">
      <Dropdown.Toggle variant="link" data-test="user-menu-toggle" />
      <Dropdown.Menu>
        {renderMenuItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GlobalTopMenu;
