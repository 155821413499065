import { makeAuthorizedRequest } from './requests';

export function updateUserGoals(patientId, data) {
  return makeAuthorizedRequest('PUT', `/users/${patientId}/goals`, data);
}

export function updateUserGoal(patientId, data) {
  return makeAuthorizedRequest('PUT', `/users/${patientId}/goal`, data);
}

export function deleteGoal(patientId, data) {
  return makeAuthorizedRequest('DELETE', `/users/${patientId}/goals`, data);
}

export function getGoals() {
  return makeAuthorizedRequest('GET', '/me/progress');
}
