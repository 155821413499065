// libraries
import React from 'react';
import moment from 'moment';
// constants
import { DATE_FORMAT } from '../../constants/constants';

export function Footer() {
  let buildVersion;
  const currentYear = moment().format(DATE_FORMAT.YEAR);

  if (process.env.BUILD_VERSION) {
    buildVersion = `v${process.env.BUILD_VERSION}`;
  }

  return (
    <div className="footer d-flex-center-between py-1 px-2">
      <div className="build-version">{buildVersion}</div>
      <div className="corporate-info">
        <div data-test="footer_companyDetails">
          {`© ${currentYear} SelectPatient Management. All rights reserved. | `}
          <a href="https://www.engooden.com/privacy-policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
