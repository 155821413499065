// Libraries
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const UserAssignmentPES = (props) => {
  const { openDialog } = props;
  const { assignedCareNavigator, tempTakeoverUser } = useSelector(state => state.patient);

  let assignedCareNavigatorName = 'N/A';
  let tempTakeoverUserName;

  if (assignedCareNavigator) {
    assignedCareNavigatorName = `${assignedCareNavigator.firstName || ''} ${assignedCareNavigator.lastName || ''}`;
  }

  if (tempTakeoverUser) {
    tempTakeoverUserName = `${tempTakeoverUser.firstName || ''} ${tempTakeoverUser.lastName || ''}`;
  }

  return (
    <div className="mb-4">
      <p className="mb-0" data-test="userAssignmentPes_userName_label">
        {`Assigned: ${assignedCareNavigatorName}`}
      </p>
      {tempTakeoverUser && (
        <p className="text-ccm-red mb-0" data-test="userAssignmentPes_temporaryTakeoverUserName_label">
          {`TT: ${tempTakeoverUserName}`}
        </p>
      )}
      <Button
        size="sm"
        className="mt-2"
        variant="primary"
        onClick={() => openDialog()}
        data-test="userAssignmentPes_assigne_button"
      >
        Assign
      </Button>
    </div>
  );
};

export default UserAssignmentPES;
