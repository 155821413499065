export const PROMPT_CONFIRMATION_TYPES = isRequiredTopic => ({
  DISCUSSION_ONLY: { name: 'DISCUSSION_ONLY', value: `Discussion, ${isRequiredTopic ? 'Required' : 'Not Required'}` },
  DISCUSSION_INTERVENTION: { name: 'DISCUSSION_INTERVENTION', value: `Actionable, ${isRequiredTopic ? 'Required' : 'Not Required'}` },
});

export const PROMPT_FILTER_NAMES = {
  CATEGORY: 'CATEGORY',
  STATUS: 'STATUS',
};

export const PROMPT_FILTERS = {
  [PROMPT_FILTER_NAMES.CATEGORY]: {
    ALL: { name: 'All' },
    SELECTPATIENT_MANAGEMENT: { name: 'SelectPatient Management' },
    PATIENT: { name: 'Patient' },
    PARTNER: { name: 'Partner' },
    SEASONAL: { name: 'Seasonal' },
  },
  [PROMPT_FILTER_NAMES.STATUS]: {
    ENABLED: { name: 'Enabled' },
    DISABLED: { name: 'Disabled' },
    DELETED: { name: 'Deleted' },
  },
};

export const PROMPT_CATEGORY = [
  { value: 'SELECTPATIENT_MANAGEMENT', label: 'SelectPatient Management' },
  { value: 'PATIENT', label: 'Patient Specific' },
  { value: 'PARTNER', label: 'Partner Specific' },
  { value: 'SEASONAL', label: 'Seasonal' },
];

export const DEFAULT_CONF_VALUES = {
  INTERVENTION: 'DISCUSSION_INTERVENTION',
  ONLY: 'DISCUSSION_ONLY',
};

export const CONFIRMATION_VALUES = {
  INTERVENTION: 'DISCUSSION_INTERVENTION_TRUE',
  INTERVENTION_FALSE: 'DISCUSSION_INTERVENTION_FALSE',
  ONLY: 'DISCUSSION_ONLY_TRUE',
  ONLY_FALSE: 'DISCUSSION_ONLY_FALSE',
};

export const PROMPT_CONFIRMATION = [
  { value: CONFIRMATION_VALUES.INTERVENTION, label: 'Actionable, Required', isRequiredTopic: true },
  { value: CONFIRMATION_VALUES.INTERVENTION_FALSE, label: 'Actionable, Not Required', isRequiredTopic: false },
  { value: CONFIRMATION_VALUES.ONLY, label: 'Discussion, Required', isRequiredTopic: true },
  { value: CONFIRMATION_VALUES.ONLY_FALSE, label: 'Discussion, Not Required', isRequiredTopic: false },
];

export const PROMPT_MONTHS = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const DATE_TYPE_MONTHS = 'MONTHS';
export const DATE_TYPE_RANGE = 'RANGE';

export const PROMPT_TENURE_OPTS = [
  { value: 'MONTH_1', label: 'Month 1' },
  { value: 'MONTH_2', label: 'Month 2' },
  { value: 'MONTH_3', label: 'Month 3' },
  { value: 'MONTH_4', label: 'Month 4' },
  { value: 'MONTH_5', label: 'Month 5' },
  { value: 'MONTH_6', label: 'Month 6' },
  { value: 'MONTH_13', label: 'Month 13' },
  { value: 'MONTH_25', label: 'Month 25' },
  { value: 'MONTH_37', label: 'Month 37' },
  { value: 'FIRST_6_MONTHS', label: 'First 6 months' },
  { value: 'YEAR_1', label: 'Year 1' },
  { value: 'YEAR_2', label: 'Year 2' },
  { value: 'MORE_THAN_2_YEARS', label: 'More than 2 years' },
];

export const PROMPT_EVERY = 'ALWAYS';
export const PROMPT_ONLY = 'RULE_BASED';

export const HF_REPEATED = ['Additional Information', 'Other'];

export const PROMPT_APPT_DATE_CODES = {
  past30Days: { value: 'PAST_30_DAYS', label: 'Within the past 30 days' },
  today: { value: 'TODAY', label: 'Today' },
  thisWeek: { value: 'THIS_WEEK', label: 'This week' },
  nextWeek: { value: 'NEXT_WEEK', label: 'Next week' },
  thisMonth: { value: 'THIS_MONTH', label: 'This month' },
  nextMonth: { value: 'NEXT_MONTH', label: 'Next month' },
  previousMonth: { value: 'PREV_MONTH', label: 'Previous month' },
  next14Days: { value: 'NEXT_14_DAYS', label: 'Within the next 14 days' },
  next30Days: { value: 'NEXT_30_DAYS', label: 'Within the next 30 days' },
  anyDate: { value: 'ANY', label: 'Any date' },
};

export const PROMPT_APPT_DATE = [
  PROMPT_APPT_DATE_CODES.past30Days,
  PROMPT_APPT_DATE_CODES.today,
  PROMPT_APPT_DATE_CODES.thisWeek,
  PROMPT_APPT_DATE_CODES.nextWeek,
  PROMPT_APPT_DATE_CODES.thisMonth,
  PROMPT_APPT_DATE_CODES.nextMonth,
  PROMPT_APPT_DATE_CODES.previousMonth,
  PROMPT_APPT_DATE_CODES.next14Days,
  PROMPT_APPT_DATE_CODES.next30Days,
  PROMPT_APPT_DATE_CODES.anyDate,
];

export const PROMPT_GENDER_OPTS = [
  { value: 'M', label: 'Male' },
  { value: 'F', label: 'Female' },
];
