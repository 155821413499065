// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Row } from 'react-bootstrap';
// Services
import { formatPhone } from '../../../services/helpers';

export const GlobalUserAccount = () => {
  const { user } = useSelector(state => state);

  return (
    <div className="h-100 overflow-auto pr-3">
      <h4 className="text-uppercase text-left my-3" data-test="userAccount_title">User Account</h4>
      <div className="card border-0">
        <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
          <span className="text-uppercase">Summary</span>
        </div>
        <div className="card-body">
          <Row className="align-items-center">
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="userId"
                >
                  User ID
                </Form.Label>
                <Form.Control
                  aria-describedby="userId Text Input"
                  readOnly
                  value={(user && user.username) || ''}
                />
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="startDate"
                >
                  Start date
                </Form.Label>
                <Form.Control
                  aria-describedby="startDate Text Input"
                  readOnly
                  value={(user && user.startDate) || ''}
                />
              </Form.Group>
            </div>
          </Row>
          <Row className="align-items-center">
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="firstName"
                >
                  First Name
                </Form.Label>
                <Form.Control
                  aria-describedby="firstName Text Input"
                  readOnly
                  value={(user && user.firstName) || ''}
                />
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="lastName"
                >
                  Last Name
                </Form.Label>
                <Form.Control
                  aria-describedby="lastName Text Input"
                  readOnly
                  value={(user && user.lastName) || ''}
                />
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="title"
                >
                  Title
                </Form.Label>
                <Form.Control
                  aria-describedby="title Text Input"
                  readOnly
                  value={(user && user.title) || ''}
                />
              </Form.Group>
            </div>
          </Row>
          <Row className="align-items-center">
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="email"
                >
                  Email
                </Form.Label>
                <Form.Control
                  aria-describedby="email Text Input"
                  readOnly
                  value={(user && user.email) || ''}
                />
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="mobile"
                >
                  Cell Phone
                </Form.Label>
                <Form.Control
                  aria-describedby="mobile Text Input"
                  readOnly
                  maxLength="14"
                  value={(
                    user && user.mobile
                    && formatPhone(user.mobile)
                  ) || ''}
                />
              </Form.Group>
            </div>
          </Row>
          <Row className="align-items-center">
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="ringCentralPhone"
                >
                  Ring Central Phone Number
                </Form.Label>
                <Form.Control
                  aria-describedby="ringCentralPhone Text Input"
                  readOnly
                  maxLength="14"
                  value={(
                    user && user.ringCentralPhone
                    && formatPhone(user.ringCentralPhone)
                  ) || ''}
                />
              </Form.Group>
            </div>
            <div className="col-4">
              <Form.Group>
                <Form.Label
                  htmlFor="zoomPhone"
                >
                  Zoom Phone Number
                </Form.Label>
                <Form.Control
                  aria-describedby="zoomPhone Text Input"
                  readOnly
                  maxLength="14"
                  value={(
                    user && user.zoomPhone
                    && formatPhone(user.zoomPhone)
                  ) || ''}
                />
              </Form.Group>
            </div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default GlobalUserAccount;
