import { makeAuthorizedRequest } from './requests';
import { formQueryString, formQueryStringWithoutSign } from './helpers';

export function getWorkloadData() {
  return makeAuthorizedRequest('POST', '/patients/statistic');
}

export function getTeamMembers(pageSize, pageNumber, status, sortParams, filterParams) {
  const sortQueryParams = sortParams ? `${formQueryString({ ...sortParams })}&` : '?';
  const filterQueryParams = formQueryStringWithoutSign({ ...filterParams });
  const query = `${sortQueryParams}pageSize=${pageSize}&pageNumber=${pageNumber}${status ? `&status=${status}` : ''}${filterQueryParams}`;
  return makeAuthorizedRequest('GET', `/users${query}`);
}

export function getActiveUsers(status, notIncludeProgressMap = false) {
  return makeAuthorizedRequest('GET', `/users?pageSize=10000${status ? `&status=${status}` : ''}${notIncludeProgressMap ? '&includeProgressMap=false' : ''}`);
}

export function getManagementData() {
  return makeAuthorizedRequest('GET', '/users/statistic');
}

export function getOfficeHours() {
  return makeAuthorizedRequest('GET', '/settings/officehours');
}

export function updateOfficeHours(data) {
  return makeAuthorizedRequest('PUT', '/settings/officehours', data);
}

export function getCNTitle() {
  return makeAuthorizedRequest('GET', '/settings/carenavigatortitle');
}

export function updateCNTitle(data) {
  return makeAuthorizedRequest('PUT', '/settings/carenavigatortitle', data);
}

export function getProviderTitle() {
  return makeAuthorizedRequest('GET', '/settings/providertitle');
}

export function updateProviderTitle(data) {
  return makeAuthorizedRequest('PUT', '/settings/providertitle', data);
}

export function getProgramName() {
  return makeAuthorizedRequest('GET', '/settings/programname');
}

export function updateProgramName(data) {
  return makeAuthorizedRequest('PUT', '/settings/programname', data);
}

export function inviteUser(userData) {
  const formData = { ...userData };
  if (formData.username && formData.username.length) {
    formData.username = formData.username.toLowerCase();
  }
  return makeAuthorizedRequest('POST', '/users', formData);
}

export function suspendUser(userId, reassignData) {
  return makeAuthorizedRequest('PUT', `/users/${userId}/suspend`, reassignData);
}

export function enableUser(userId) {
  return makeAuthorizedRequest('PUT', `/users/${userId}/enable`);
}

export function resetUserTotp(userId) {
  return makeAuthorizedRequest('PUT', `/users/${userId}/resettotp`);
}

export function updateUser(userId, userData) {
  return makeAuthorizedRequest('PUT', `/users/${userId}/edit`, userData);
}

export function changeRole(userId, userRole) {
  return makeAuthorizedRequest('PUT', `/users/${userId}/role`, userRole);
}

export function updateUserPassword(userId, userData) {
  return makeAuthorizedRequest('PUT', `/users/${userId}/password`, userData);
}

export function getBillingHistoryReport(params) {
  return makeAuthorizedRequest('GET', `/billing-reports${formQueryString(params)}`);
}

export function getBillingHistoryExcelReport(params) {
  return makeAuthorizedRequest('GET', `/billing-reports/excel${formQueryString(params)}`);
}

export function getBillingReportJSON(reportId) {
  return makeAuthorizedRequest('GET', `/billing-reports/${reportId}/json`);
}

export function getUserActivities(params = {}) {
  return makeAuthorizedRequest('POST', '/user-activities', params);
}

export function getUserActivitiesExcel(params = {}) {
  return makeAuthorizedRequest('POST', '/user-activities/excel', params);
}

export function getEligibilityRules() {
  return makeAuthorizedRequest('GET', '/settings/eligibility-rules');
}

export function updateEligibilityRules(rulesData) {
  return makeAuthorizedRequest('PUT', '/settings/eligibility-rules', rulesData);
}

export function updateRequireReviewValue(userId, requireReviewValue) {
  return makeAuthorizedRequest('PUT', `/users/${userId}/review/${requireReviewValue}`);
}

export function getBillablePhysiciansConfig() {
  return makeAuthorizedRequest('GET', '/settings/physicians-info');
}

export function getTestPatientsEnabled() {
  return makeAuthorizedRequest('GET', '/settings/is-test-patients-enabled');
}

export function saveTestPatient(patient) {
  return makeAuthorizedRequest('POST', '/testpatients', patient);
}

export function updateActivityToCarePlanPdf(data) {
  return makeAuthorizedRequest('PUT', '/settings/careplanactivity', data);
}

export function getActivityToCarePlanPdf() {
  return makeAuthorizedRequest('GET', '/settings/careplanactivity');
}

export function getDailyEnrollmentGoal() {
  return makeAuthorizedRequest('GET', '/settings/dailyenrollmentgoal');
}

export function updateDailyEnrollmentGoal(data) {
  return makeAuthorizedRequest('PUT', '/settings/dailyenrollmentgoal', data);
}

export function getMonthlyEnrollmentGoal() {
  return makeAuthorizedRequest('GET', '/settings/monthlyenrollmentgoal');
}

export function updateMonthlyEnrollmentGoal(data) {
  return makeAuthorizedRequest('PUT', '/settings/monthlyenrollmentgoal', data);
}

export function getAdministrationFeatures() {
  return makeAuthorizedRequest('GET', '/features');
}

export function getAdminFeatureByName(featureName) {
  return makeAuthorizedRequest('GET', `/feature/${featureName}`);
}

export function updateAdminFeatureByName(featureName) {
  return makeAuthorizedRequest('POST', `/feature/${featureName}/turn`);
}

export function getShortUsers(role = null, status = null) {
  if (role || status) {
    const userFilter = {};
    if (role) userFilter.role = role;
    if (status) userFilter.status = status;
    return makeAuthorizedRequest('GET', '/users/short', userFilter);
  }
  return makeAuthorizedRequest('GET', '/users/short');
}

export function getAllUsersData() {
  return makeAuthorizedRequest('GET', '/users/admin/short');
}

export function getPrompts(category, status, type) {
  return makeAuthorizedRequest('GET', `/prompts${formQueryString({ category, status, type })}`);
}

export function createPrompt(prompt) {
  return makeAuthorizedRequest('POST', '/prompts', prompt);
}

export function updatePrompt(prompt) {
  return makeAuthorizedRequest('PUT', `/prompts/${prompt.id}`, prompt);
}

export function deletePrompt(promptId) {
  return makeAuthorizedRequest('DELETE', `/prompts/${promptId}`);
}

export function getFactors() {
  return makeAuthorizedRequest('GET', '/factors');
}

export function resendReport(data) {
  return makeAuthorizedRequest('POST', '/billing/resendreport', data);
}

export function sendAutomatedPatientSurveys(data) {
  return makeAuthorizedRequest('PUT', '/sendAutomatedPatientSurveys', data);
}

export function generateProblemReport(data) {
  return makeAuthorizedRequest('POST', '/problem-report/generate', data);
}

export function getResources() {
  return makeAuthorizedRequest('GET', '/healthfactorcategoryresources');
}

export function createResource(resource) {
  return makeAuthorizedRequest('POST', '/healthfactorcategoryresources', resource);
}

export function updateResource(resource) {
  return makeAuthorizedRequest('PUT', `/healthfactorcategoryresources/${resource.id}`, resource);
}

export function deleteResource(resource) {
  return makeAuthorizedRequest('DELETE', `/healthfactorcategoryresources/${resource.id}`);
}

export function getAdminHealthFactors() {
  return makeAuthorizedRequest('GET', '/healthfactors');
}

export function createHealthFactor(factor) {
  return makeAuthorizedRequest('POST', '/healthfactors', factor);
}

export function updateHealthFactor(factor) {
  return makeAuthorizedRequest('PUT', `/healthfactors/${factor.id}`, factor);
}

export function createHealthFactorCategory(category) {
  return makeAuthorizedRequest('POST', '/healthfactorcategories', category);
}

export function getConsentUrl() {
  return makeAuthorizedRequest('GET', '/settings/consenturl');
}
