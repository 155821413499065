// Libraries
import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
// Views
import Reminders from '../patient/Reminders';
import PatientAttributes from '../patient/summary/PatientAttributes';
import EnrollmentNotes from '../patient/patientNotes/EnrollmentNotes';

export const BasicActions = () => {
  const { id: patientId } = useParams();

  return (
    <Fragment>
      <div className="row no-gutters box-wrapper mb-4 p-2">
        <PatientAttributes patientId={patientId} />
      </div>
      <div className="row no-gutters box-wrapper text-left mb-4 p-3">
        <Reminders patientId={patientId} isPanelOpened />
      </div>
      <div className="row no-gutters box-wrapper mb-4 p-3">
        <div className="w-100">
          <EnrollmentNotes patientId={patientId} />
        </div>
      </div>
    </Fragment>
  );
};

export default BasicActions;
