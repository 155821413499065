// Libraries
import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Services
import { fetchData, getCallTimeLabel, getContactMethodIcon } from '../../../../services/helpers';
import { updateNextActionDate } from '../../../../services/patient';
// Views
import NextOutreachDateModal from '../../../patient/nextOutreachDate/NextOutreachDateModal';
// Constants
import { DATE_FORMAT, NEXT_OUTREACH_DATE_BLANK, NOTIFICATION_TYPE } from '../../../../constants/constants';
// Actions
import { UpdatePatient } from '../../../../actions/patient';
import ShowNotification from '../../../../actions/notification';

export const NextOutreachDate = () => {
  const { id: patientId } = useParams();
  const { patient: { nextActionDateReminderInfo } } = useSelector(state => state);
  const dispatch = useDispatch();
  const updatePatient = data => dispatch(UpdatePatient(data));
  const showNotification = data => dispatch(ShowNotification(data));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { reminderDate = '', reminderTime = '', contactMethod = '' } = nextActionDateReminderInfo || {};


  const saveNextOutreachDate = async (updatedNextOutreachInfo) => {
    try {
      await fetchData(updateNextActionDate(patientId, updatedNextOutreachInfo));
      updatePatient({
        nextActionDateReminderInfo: {
          id: updatedNextOutreachInfo.id,
          reminderDate: updatedNextOutreachInfo.date,
          contactMethod: updatedNextOutreachInfo.contactMethod,
          reminderTime: updatedNextOutreachInfo.preferredCallTime,
        },
      });
      showNotification({
        message: 'Next outreach info saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    } catch (error) {
      showNotification({
        message: 'An error has occurred while attempting to save next outreach',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }
  };

  return (
    <Fragment>
      <div className="d-flex" data-test="nextOutreachDate_NextActionDateRow">
        <div>
          <p className="text-content mb-0" data-test="nextOutreachDate_refInfoContactPreferred">
            {`Next Outreach: ${reminderDate ? moment(reminderDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL) : 'Click to Edit'} ${(reminderDate || (reminderTime && reminderTime !== 'NONE')) ? `(${getCallTimeLabel(reminderTime)})` : ''}`}
            {(reminderDate || (reminderTime && reminderTime !== 'NONE')) && <span className={`bi-${getContactMethodIcon(contactMethod)} ml-2`} />}
            {(reminderDate || (reminderTime && reminderTime !== 'NONE')) && (
              <Button
                variant="link-dark"
                className="py-0"
                onClick={() => saveNextOutreachDate(NEXT_OUTREACH_DATE_BLANK)}
                data-test="nextPatientOutreach_saveBtn"
              >
                <span className="bi-x" />
              </Button>
            )}
          </p>
        </div>
        <Button
          size="lg"
          variant="link"
          className="d-flex-center p-0"
          onClick={() => setIsModalOpen(true)}
          data-test="nextOutreachDate_editNod"
        >
          <span className="d-flex-center bi-pencil-square ml-1" />
        </Button>
      </div>
      <NextOutreachDateModal
        patientId={patientId}
        initialNextOutreachDate={nextActionDateReminderInfo}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data-test="nextOutreachDate_nodModal"
      />
    </Fragment>
  );
};

export default NextOutreachDate;
