// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getPatientConsents } from '../../services/patient';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';


const useLoadPatientConsent = ({ patientId }) => {
  const [consents, setConsents] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchConsents = useCallback(async () => {
    const getPatientConsentsRequest = getPatientConsents(patientId);
    const getPatientConsentsPromise = getPatientConsentsRequest.promise;
    try {
      const data = await getPatientConsentsPromise;
      setConsents(data);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to load consents.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [patientId]);

  useEffect(() => { fetchConsents(); }, [fetchConsents]);

  return { consents, refetch: fetchConsents };
};

export default useLoadPatientConsent;
