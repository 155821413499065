// Libraries
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
// Components
import { Datepicker } from '../base/forms/Datepicker';
import { Select } from '../base/forms/Select';
// Services
import { downloadFile, fetchData } from '../../services/helpers';
import { getPhysicianTypeahead } from '../../services/providers';
import { downloadEscalationsReport } from '../../services/reports';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { DATE_FORMAT, NOTIFICATION_TYPE } from '../../constants/constants';
// Local Constants
const FIELD_STYLES = {
  formGroup: 'd-flex flex-wrap reports-form-group',
  formLabel: 'text-left mr-3 mb-0 w-50',
};
const DEFAULT_PATIENT_ESCALATIONS = {
  fromDate: null,
  toDate: null,
  physicians: null,
};
const PATIENT_ESCALATIONS_SCHEMA = () => Yup.object({
  fromDate: Yup.date('Must be a valid date')
    .typeError('Must be a valid date').nullable(false).required('Required'),
  toDate: Yup.date('Must be a valid date')
    .typeError('Must be a valid date').nullable(true)
    .min(
      Yup.ref('fromDate'),
      () => 'To date must be after From date',
    ),
});

export const PatientEscalations = () => {
  const [noResultsText, setNoResultsText] = useState('No results found...');
  const dispatch = useDispatch();

  const getReport = (request) => {
    const downloadReportRequest = downloadEscalationsReport(request);
    const downloadReportPromise = downloadReportRequest.promise;

    return downloadReportPromise.then((report) => {
      delete downloadReportRequest.promise;

      downloadFile(report.data, report.fileName);
    }).catch((error) => {
      delete downloadReportRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      dispatch(ShowNotification({
        message: 'Could not download escalations report, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const preparePhysiciansList = (physiciansData = []) => {
    const physiciansList = [];

    physiciansData.forEach((el) => {
      physiciansList.push({
        ...el,
        value: el.id,
        label: `${el.lastName ? `${el.lastName}, ` : ''}${el.firstName ? el.firstName : ''}`,
      });
    });

    physiciansList.sort((a, b) => a.label.localeCompare(b.label));

    return physiciansList;
  };

  const handlePhysicianAsyncList = async (input) => {
    if (input && input.length > 1) {
      const physicianData = await fetchData(getPhysicianTypeahead({ filter: input }));
      return { options: preparePhysiciansList(physicianData) };
    }
    return { options: [] };
  };

  const transformValues = (values) => {
    const formattedFromDate = values.fromDate
      ? moment(values.fromDate).format(DATE_FORMAT.FULL_SERVER) : values.fromDate;
    const formattedToDate = values.toDate
      ? moment(values.toDate).format(DATE_FORMAT.FULL_SERVER) : values.toDate;
    const formattedPhysicians = values.physicians
      && !!values.physicians.length
      ? values.physicians.map(el => el.id) : null;

    return {
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      physicianIds: formattedPhysicians,
    };
  };

  return (
    <div className="reports-enrollment h-100 overflow-auto pr-3">
      <div className="reports-header d-flex align-items-center">
        <h4 className="text-left my-3 text-capitalize" data-test="patientEscalations_headingText">patient escalations</h4>
      </div>
      <div className="container-fluid px-4 py-2">
        <Formik
          validationSchema={PATIENT_ESCALATIONS_SCHEMA()}
          initialValues={DEFAULT_PATIENT_ESCALATIONS}
          onSubmit={(values) => {
            const formattedValues = transformValues(values);
            getReport(formattedValues);
          }}
          data-test="patientEscalations_formikComponent"
        >
          {formik => (
            <Form>
              <div className="row w-100">
                <Datepicker
                  label="From:"
                  name="fromDate"
                  autoComplete="off"
                  styles={FIELD_STYLES}
                  className="form-control w-100"
                  selectsStarts
                  startDate={moment(formik.values.fromDate).toDate()}
                  endDate={moment(formik.values.toDate).toDate()}
                  maxDate={moment().toDate()}
                />
              </div>
              <div className="row w-100">
                <Datepicker
                  label="To:"
                  name="toDate"
                  autoComplete="off"
                  styles={FIELD_STYLES}
                  className="form-control w-100"
                  selectsEnd
                  startDate={formik.values.fromDate
                    ? moment(formik.values.fromDate).toDate() : null}
                  endDate={moment(formik.values.toDate).toDate()}
                  minDate={moment(formik.values.fromDate).toDate()}
                />
              </div>
              <div className="row w-100" data-test="patientEscalations_physiciansDropdown">
                <Select
                  async
                  multi
                  id="physiciansId"
                  label="Physicians:"
                  name="physicians"
                  noResultsText={noResultsText}
                  onInputChange={input => (input.length < 2 ? setNoResultsText('Type 2 characters min') : setNoResultsText('No results found...'))}
                  loadOptions={handlePhysicianAsyncList}
                  styles={FIELD_STYLES}
                  data-test="patientEscalations_physicianSelect"
                />
              </div>
              <div className="row">
                <Button
                  variant="primary"
                  className="text-capitalize mt-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="patientEscalations_downloadBtn"
                >
                  download report
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PatientEscalations;
