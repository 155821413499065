// Libraries
import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
// Services
// Actions
import ShowNotification from '../../../actions/notification';
// Constants
import { DIALOG_STYLES, NOTIFICATION_TYPE } from '../../../constants/constants';
// Components
import { TextArea } from '../../base/forms/TextArea';
import { revertPatientStatus } from '../../../services/patient';


export const patientStatusSchema = () => Yup.object({
  note: Yup.string().required('Required'),
});

/**
 * StatusChangeUndoModal component, requires props:
 * isModalOpen - boolean to open modal
 * setIsModalOpen - function to change state isModalOpen variable
 * patient - patient history information
 * reloadData - service to refresh data
 */

export const StatusChangeUndoModal = (props) => {
  const {
    isModalOpen, setIsModalOpen, patient, reloadData,
  } = props;

  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const DEFAULT_VALUES = {
    note: '',
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const undoPatientStatus = ({ note }) => {
    const body = { note, patientId: patient.patientInfo.id, patientStatusHistoryId: patient.id };
    const updatePatientTagsRequest = revertPatientStatus(body);
    const updatePatientTagsPromise = updatePatientTagsRequest.promise;

    return updatePatientTagsPromise.then(() => {
      delete updatePatientTagsRequest.promise;
      reloadData();
    }).catch((error) => {
      delete updatePatientTagsRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to update patient attributes.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const undoStatus = (values) => {
    undoPatientStatus(values);
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Change Status"
      data-test="statusChangeUndoModal_onRequestClose"
    >
      <div className="simple-dialog" data-test="statusChangeUndoModal_modal">
        <div className="dialog-title">
          Undo This Change
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="statusChangeUndoModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={DEFAULT_VALUES}
          validationSchema={patientStatusSchema()}
          onSubmit={(values) => {
            undoStatus(values);
          }}
          data-test="statusChangeUndoModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <div className="col" data-test="statusChangeUndoModal_dateInput">
                  <TextArea
                    label="Note"
                    placeholder="Please specify why you are denying this status change"
                    name="note"
                    data-test="statusChangeUndoModal_note"
                    rows={10}
                  />
                </div>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="statusChangeUndoModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!(formik.isValid && formik.dirty)}
                  data-test="statusChangeUndoModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default StatusChangeUndoModal;
