export const LEGEND_STATUS_CATEGORY = {
  none: '',
  visited: 'Visited in the last 12 months',
  notVisited: 'Not visited in the last 12 months',
};

export const STATUSES = {
  N: {
    name: 'Eligible not enrolled',
    color: 'orange',
  },
  P: {
    name: 'Pending enrollment',
    color: 'yellow',
    subStatuses: {
      P: {
        name: 'Contact initiated',
        shortName: 'Contact initiated',
        category: LEGEND_STATUS_CATEGORY.visited,
        canBeChangedToThisStatus: true,
      },
      P2: {
        name: '2nd Attempt Required',
        category: LEGEND_STATUS_CATEGORY.visited,
        canBeChangedToThisStatus: true,
      },
      P3: {
        name: '3rd Attempt Required',
        shortName: '3rd Attempt Required',
        category: LEGEND_STATUS_CATEGORY.visited,
        canBeChangedToThisStatus: true,
      },
      PN: {
        name: 'Needs Office Visit',
        shortName: 'Needs Office Visit',
        category: LEGEND_STATUS_CATEGORY.notVisited,
      },
      PS: {
        name: 'Pending Suspended',
      },
    },
  },
  C: {
    name: 'Currently Enrolled',
    color: 'green',
    subStatuses: {
      CC: {
        name: 'Care plan completed',
      },
      CN: {
        name: 'Enrolled',
      },
      CS: {
        name: 'Suspended',
      },
    },
  },
  I: {
    name: 'Ineligible',
    color: 'oslo',
    permissions: ['canViewIneligiblePatients'],
  },
  D: {
    name: 'Declined',
    color: 'red',
    subStatuses: {
      DP: {
        name: 'Declined by patient',
        shortName: 'By Patient',
      },
      DC: {
        name: 'Declined by Care Provider',
        shortName: 'By Care Provider',
      },
    },
  },
  X: {
    name: 'Dis-enrolled',
    color: 'bismark',
  },
  E: {
    name: 'Unable to Reach',
    color: 'navy',
  },
};

export const ALL_BULK_STATUSES = ['N', 'P', 'P2', 'P3', 'PN', 'PS', 'CS', 'I', 'DP', 'DC', 'X', 'E'];

export const SUSPEND_STATUSES = {
  suspendReasons: {
    OTHER: 'Other',
    PENDING_MANAGEMENT_REVIEW: 'Pending management review',
    CALL_IN_FUTURE: 'Patient would like call in future',
    BAD_NUMBER: 'Unable to reach: bad number',
    NOT_SEEN_BY_PCP: 'Patient has not been seen by PCP in > 1 year',
    HAS_NOT_YET_PCP: 'Has not yet established new PCP',
    INSURANCE_CHANGE: 'Insurance eligibility change',
    LANGUAGE_GAP: 'Language gap',
    NO_ANSWER: 'Unable to reach: no answer',
    NO_SHOW: 'Patient was a no-show for their telehealth visit',
  },
};

export const ENROLLED_STATUSES = ['CC', 'CS', 'CN'];

export const LEGEND_STATUSES = {
  ANY: {
    id: '*',
    color: 'gray',
    name: 'All Patients (any status)',
  },
  N: {
    id: 'N',
    color: 'orange',
    name: 'Eligible not enrolled',
  },
  P: {
    id: 'P',
    color: 'yellow',
    name: 'Contact initiated',
  },
  P2: {
    id: 'P2',
    color: 'yellow',
    name: '2nd Attempt Required',
  },
  P3: {
    id: 'P3',
    color: 'yellow',
    name: '3rd Attempt Required',
  },
  PN: {
    id: 'PN',
    color: 'yellow',
    name: 'Needs Office Visit',
  },
  PS: {
    id: 'PS',
    color: 'yellow',
    name: 'Pending Suspended',
  },
  CC: {
    id: 'CC',
    color: 'green',
    name: 'Care plan completed',
  },
  CN: {
    id: 'CN',
    color: 'green',
    name: 'Enrolled',
  },
  CS: {
    id: 'CS',
    color: 'green',
    name: 'Suspended',
  },
  DP: {
    id: 'DP',
    color: 'red',
    name: 'Declined by patient',
  },
  DC: {
    id: 'DC',
    color: 'red',
    name: 'Declined by Care Provider',
  },
  I: {
    id: 'I',
    color: 'oslo',
    name: 'Ineligible',
    permissions: ['canViewIneligiblePatients'],
  },
  E: {
    id: 'E',
    color: 'navy',
    name: 'Unable to Reach',
  },
  X: {
    id: 'X',
    color: 'bismark',
    name: 'Dis-enrolled',
  },
};

export const LEGEND_STATUS_BY_CATEGORY = {
  none: [
    { ...LEGEND_STATUSES.ANY },
    { ...LEGEND_STATUSES.N },
  ],
  visited: [
    { ...LEGEND_STATUSES.P },
    { ...LEGEND_STATUSES.P2 },
    { ...LEGEND_STATUSES.P3 },
  ],
  notVisited: [
    { ...LEGEND_STATUSES.PN },
    { ...LEGEND_STATUSES.PS },
    { ...LEGEND_STATUSES.CC },
    { ...LEGEND_STATUSES.CN },
    { ...LEGEND_STATUSES.CS },
    { ...LEGEND_STATUSES.DP },
    { ...LEGEND_STATUSES.DC },
    { ...LEGEND_STATUSES.I },
    { ...LEGEND_STATUSES.E },
    { ...LEGEND_STATUSES.X },
  ],
};
