// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// actions
import ShowNotification from '../../actions/notification';
// constants
import { CHANGE_PASSWORD_ERRORS, NOTIFICATION_TYPE } from '../../constants/constants';
// services
import { validatePassword } from '../../services/helpers';
import { changePassword } from '../../services/amazon-cognito';
import { setLastPasswordUpdate } from '../../services/login';
import { makeCancelablePromise } from '../../services/cancelablePromise';
// views
import LabeledInput from '../base/LabeledInput';
// Components
import { withRouter } from '../shared/WithRouter';

export class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      password: '',
      currentPassword: '',
      confirmPassword: '',
    };

    this.refs = {};
    this.promises = {};

    this.saveRef = this.saveRef.bind(this);
    this.validate = this.validate.bind(this);
    this.validateNewPassword = this.validateNewPassword.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  saveRef(name, ref) {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  validate() {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      result = this.refs[key].validate() && result;
    });

    return result;
  }

  validateNewPassword() {
    const { password, currentPassword } = this.state;

    const passwordValidationError = validatePassword(password);

    if (passwordValidationError) {
      return passwordValidationError;
    }

    if (password === currentPassword) {
      return 'must be different from the old one';
    }

    return false;
  }

  validateConfirmPassword() {
    if (this.state.password !== this.state.confirmPassword) {
      return 'do not match';
    }

    return false;
  }

  isUserAuthorized() {
    const { user, tenant } = this.props;

    if ((user && user.authData && tenant && tenant.clientId)) {
      return true;
    }

    const { navigate, params: { tenant: tenantUrl }, showNotification } = this.props;

    showNotification({
      message: 'Error occurred during request, please try again later.',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });

    navigate(`/${tenantUrl}`);

    return false;
  }

  handleSubmit(event) {
    event.preventDefault();

    const { loading } = this.state;

    if (loading || !this.validate() || !this.isUserAuthorized()) {
      return;
    }

    this.handlePasswordChange();
  }

  handlePasswordChange() {
    const { showNotification } = this.props;

    this.setState({
      loading: true,
    });

    const promiseName = 'changePassword';
    const changePasswordRequest = makeCancelablePromise(
      changePassword(this.state.currentPassword, this.state.password),
    );
    const changePasswordPromise = changePasswordRequest.promise;
    this.promises[promiseName] = changePasswordRequest;

    return changePasswordPromise.then(() => {
      delete this.promises[promiseName];

      showNotification({
        message: 'Your password has been successfully changed.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });

      this.handleLastPasswordUpdate();
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: CHANGE_PASSWORD_ERRORS[error.code] || CHANGE_PASSWORD_ERRORS.defaultError,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });

      this.setState({
        loading: false,
      });
    });
  }

  handleLastPasswordUpdate() {
    const { showNotification } = this.props;
    const { navigate, params: { tenant: tenantUrl } } = this.props;

    const promiseName = 'setLastPasswordUpdate';
    const setLastPasswordUpdateRequest = setLastPasswordUpdate();
    const setLastPasswordUpdatePromise = setLastPasswordUpdateRequest.promise;
    this.promises[promiseName] = setLastPasswordUpdateRequest;

    return setLastPasswordUpdatePromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });

      navigate(`/${tenantUrl}`);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: error.message,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const { params: { tenant: tenantUrl } } = this.props;

    return (
      <form
        className="form-container login-form"
        onSubmit={this.handleSubmit}
        noValidate
      >
        <div className="form-row">
          <LabeledInput
            ref={this.saveRef.bind(this, 'currentPassword')}
            label="Current Password"
            validateCallback={validatePassword}
            type="password"
            name="currentPassword"
            value={this.state.currentPassword}
            handleChange={this.handleChange}
            required
          />
        </div>

        <div className="form-row">
          <LabeledInput
            ref={this.saveRef.bind(this, 'password')}
            label="New Password"
            validateCallback={this.validateNewPassword}
            type="password"
            name="password"
            value={this.state.password}
            handleChange={this.handleChange}
            required
          />
        </div>

        <div className="form-row">
          <LabeledInput
            ref={this.saveRef.bind(this, 'confirmPassword')}
            label="Confirm Password"
            validateCallback={this.validateConfirmPassword}
            type="password"
            name="confirmPassword"
            value={this.state.confirmPassword}
            handleChange={this.handleChange}
            required
          />
        </div>

        <div className="form-row">
          <button
            type="submit"
            className="button-active"
          >
            Send
          </button>
        </div>

        <div className="form-row links">
          <Link to={`/${tenantUrl}`}>Sign in</Link>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    tenant: state.tenant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
