// Libraries
import React from 'react';
import Chart from 'react-apexcharts';
import _snakeCase from 'lodash/snakeCase';
// Images
import anitaSmile from '../../../images/anita-smile.svg';
// Constans
const COLOR_MERCURY = '#E5E5E5';
const COLOR_LIPSTICK = '#C6017D';
const COLOR_HAVELOCK_BLUE = '#74A3E1';

export const MetricsCard = (props) => {
  const {
    title = '', content: { daily, monthly }, summary, reverseStyle = '', billedBonusValue = null,
  } = props;

  const zeroPercentage = (percentage, color) => (percentage === 0 ? COLOR_MERCURY : color);

  const series = [monthly.percentage, daily.percentage];
  const options = {
    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: -23,
        hollow: { size: '35%' },
        track: {
          show: false,
          margin: 0,
        },
        dataLabels: { show: false },
      },
    },
    stroke: { lineCap: 'round' },
    colors: [
      zeroPercentage(monthly.percentage, COLOR_LIPSTICK),
      zeroPercentage(daily.percentage, COLOR_HAVELOCK_BLUE),
    ],
  };

  const percentageSection = (titleSection, color, margin, metric) => (
    <div className={`d-flex flex-column mt-${margin}`}>
      <span>{`${titleSection} GOAL`}</span>
      <span className={`percentage-text text-ccm-${color}`} data-test={`metricsCard_${_snakeCase(titleSection)}_${_snakeCase(title)}_percentageText`}>
        {metric.goal === 0
          ? `${metric.done}`
          : `${metric.percentage}%`
        }
      </span>
      {metric.goal !== 0
        && <span className={`text-ccm-${color}`} data-test={`metricsCard_${_snakeCase(titleSection)}_${_snakeCase(title)}_doneVsGoal`}>{`${metric.done} / ${metric.goal} Patients`}</span>
      }
    </div>
  );

  const bonusSection = () => {
    if (billedBonusValue !== null) {
      return (
        <div className="d-flex flex-column mt-4">
          <span className="text-uppercase">bonus patients billed</span>
          <span className="percentage-text text-ccm-lipstick">{billedBonusValue}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="card" data-test={`metricsCard_${_snakeCase(title)}`}>
      <div className="card-body pb-0 px-4">
        <div className={`d-flex flex-row${reverseStyle}`}>
          <div className="col-6 d-flex flex-column" data-test={`metricsCard_dailyMonthly_${_snakeCase(title)}_info`}>
            {percentageSection('DAILY', 'blue', '1', daily)}
            {percentageSection('MONTHLY', 'lipstick', '4', monthly)}
            {bonusSection()}
          </div>
          <div className="col-6 d-flex flex-column">
            <span className="align-self-center text-nowrap chart-text" data-test={`metricsCard_${_snakeCase(title)}_text`}>{`TOTAL ${title}`}</span>
            {monthly.percentage === 0 && daily.percentage === 0
              ? <img src={anitaSmile} alt="Anita's smile logo" className="mb-3" data-test="metricsCard_smileLogo" />
              : (
                <div className="card-chart">
                  <Chart options={options} series={series} type="radialBar" height="300px" width="100%" />
                </div>
              )
            }
          </div>
        </div>
      </div>
      <div className="card-footer py-4 mx-4 mt-3">
        <span data-test="metricsCard_summaryText">{summary}</span>
      </div>
    </div>
  );
};

export default MetricsCard;
