// Libraries
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import _Find from 'lodash/find';
import QRCode from 'qrcode.react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { setupTotp, verifySoftwareToken } from '../../services/amazon-cognito';
import { makeCancelablePromise } from '../../services/cancelablePromise';
// Constants
import { NOTIFICATION_TYPE, SIGN_IN_ERROR_CODES } from '../../constants/constants';
// Components
import { TextInput } from '../base/forms/TextInput';
// Views
import Loading from '../base/Loading';
// Images
import logo from '../../images/brand-logo.svg';

export function SetupMFA(props) {
  const { tenant: tenantUrl } = useParams();
  const navigate = useNavigate();

  const {
    loading, tenant: { mfaEnabled = false }, showNotification,
  } = props;

  const [qrValue, setQrValue] = useState();
  const [showQr, setShowQr] = useState(true);

  const getName = (attrs) => {
    const userName = _Find(attrs, ['Name', 'username']).Value;
    return `${userName}`;
  };

  const getSecretCode = async () => {
    const getSecretCodeRequest = makeCancelablePromise(setupTotp());
    const getSecretCodePromise = getSecretCodeRequest.promise;

    return getSecretCodePromise.then(({ secretCode, attrs }) => {
      delete getSecretCodeRequest.promise;
      const authCode = `otpauth://totp/Anita-Engooden:${tenantUrl}-${getName(attrs)}?secret=${secretCode}&issuer=Engooden`;
      setQrValue(authCode);
    }).catch((error) => {
      delete getSecretCodeRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
      } else if (error.code === SIGN_IN_ERROR_CODES.NO_AUTH) {
        navigate(`/${tenantUrl}`);
        showNotification({
          message: error.message,
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      } else if (error.code === SIGN_IN_ERROR_CODES.HAS_TOTP_SETUP) {
        navigate(`/${tenantUrl}/cn/account`);
        showNotification({
          message: (<span className="d-flex-center">
            <i className="bi bi-shield-lock-fill h3 mr-2 mb-0" />
            {error.message}
          </span>),
          notificationType: NOTIFICATION_TYPE.WARNING,
        });
      } else {
        showNotification({
          message: 'Could not render QR to enable MFA, please try again later',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      }
    });
  };

  const handleTokenSubmit = (values) => {
    const verificationCodeRequest = makeCancelablePromise(
      verifySoftwareToken(values.code),
    );
    const verificationCodePromise = verificationCodeRequest.promise;

    return verificationCodePromise.then(() => {
      delete verificationCodeRequest.promise;
      navigate(`/${tenantUrl}`);
      showNotification({
        message: (
          <span className="d-flex-center">
            <i className="bi-check-circle-fill h3 mr-2 mb-0" />
            Two-factor authentication was successfully registered.
          </span>),
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete verificationCodeRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: (
          <span className="d-flex-center">
            <i className="bi-exclamation-circle-fill h3 mr-2 mb-0" />
            Invalid code, please try again.
          </span>),
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderQR = () => (
    <div>
      <h5 className="mt-5 mb-3">Secure Your Account</h5>
      <p className="mb-4">
        Help us keep your account secure by enabling two-factor
        authentication. Scan this QR code with your authenticator app to get started.
      </p>
      {qrValue && <QRCode value={qrValue} data-test="setupMfa-qrCode" />}
      <p className="mt-3">
        If you have questions, please contact your administrator.
      </p>
      <Button
        variant="ccm-lipstick"
        className="mt-2 px-5 rounded-pill"
        onClick={() => setShowQr(false)}
        data-test="setupMfa-nextCode"
        disabled={!qrValue}
      >
        Next
      </Button>
    </div>
  );

  const renderCodeForm = () => {
    const fieldStyles = {
      formGroup: 'mt-4',
      formControl: 'rounded-pill',
      formLabel: 'float-left text-ccm-bismark mb-2',
    };
    return (
      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={Yup.object({
          code: Yup.string()
            .required('Code is required'),
        })}
        onSubmit={(values, { resetForm }) => {
          handleTokenSubmit(values);
          resetForm();
        }}
        data-test="setupMfa_qrFormikComponent"
      >
        {formik => (
          <Form onSubmit={formik.handleSubmit}>
            <h5 className="mt-5 mb-3">Almost there!</h5>
            <p className="mb-4">
              Enter the code displayed on your authenticator app.
            </p>
            <TextInput
              label="Code"
              name="code"
              styles={fieldStyles}
              data-test="totp-field"
            />
            <p className="small text-left mb-4">
              <span className="bi-info-circle mr-1" />
              If you have questions, please contact your administrator.
            </p>
            <Button
              variant="ccm-lipstick"
              className="mt-2 px-5 rounded-pill"
              onClick={() => formik.handleSubmit()}
              data-test="setupMfa-submitButton"
              disabled={!formik.isValid}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    );
  };

  useEffect(() => {
    if (mfaEnabled) getSecretCode();
    else {
      navigate(`/${tenantUrl}`);
      showNotification({
        message: (
          <span className="d-flex-center">
            <i className="bi-exclamation-circle-fill h3 mr-2 mb-0" />
            The tenant does not have MFA enabled. Please contact your administrator.
          </span>),
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.WARNING,
      });
    }
  }, []);

  return (
    <div className="ccm-form-signin row justify-content-center h-100">
      <div className="col-4 d-flex-center">
        <div className="wrapper-login flex-grow-1 p-4">
          <Loading />
          <img
            src={logo}
            alt="SelectPatient Management logo"
            data-test="login_engoodenLogo"
            className={`logo ${loading ? 'd-none' : 'd-block'}`}
          />
          {showQr ? renderQR() : renderCodeForm()}
          <div className="d-flex-center mt-5">
            <Link to={`/${tenantUrl}`}>
              Back to Sign In
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    tenant: state.tenant,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetupMFA);
