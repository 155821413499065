// libraries
import React, { Component } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

/**
 * Pager component, requires props:
 * totalPages -- number of pages
 * currentPage -- number of current page
 * callback -- callback after changing page
 * maxShownCount -- optional, number of max shown pages, default is 5
 * isNextPrevShown -- optional, if it necessary to show next/prev buttons, default is false
 */
class Pager extends Component {
  constructor(props) {
    super(props);

    this.moveToPage = this.moveToPage.bind(this);
  }

  buildPager = () => {
    const {
      totalPages, currentPage, maxShownCount = 5, isNextPrevShown,
    } = this.props;
    const result = [];
    const firstShownCount = (maxShownCount - 1) / 2;
    const lastShownCount = (maxShownCount + 1) / 2;

    const pageIndexes = {
      current: currentPage,
      first: 0,
      last: totalPages ? totalPages - 1 : 0,
      start: currentPage > firstShownCount ? currentPage - firstShownCount : 0,
      end: currentPage + firstShownCount < totalPages ? currentPage + lastShownCount : totalPages,
      shownCount: Math.min(totalPages, maxShownCount),
    };

    if (!pageIndexes.last) {
      return <div />;
    }

    if ((pageIndexes.end - pageIndexes.start < pageIndexes.shownCount)) {
      if (!pageIndexes.start) {
        pageIndexes.end = pageIndexes.shownCount;
      } else {
        pageIndexes.start = pageIndexes.end - pageIndexes.shownCount;
      }
    }

    result.push(
      <Button
        data-test="pager_firstPage"
        key="first"
        variant="secondary"
        disabled={pageIndexes.current < 1}
        onClick={this.createCallback(pageIndexes.first)}
      >
        First Page
      </Button>,
    );

    if (isNextPrevShown) {
      result.push(
        <Button
          key="prev"
          variant="secondary"
          disabled={pageIndexes.current < 1}
          onClick={this.createCallback(pageIndexes.current - 1)}
        >
          <i className="bi-chevron-left" />
        </Button>,
      );
    }

    for (let i = pageIndexes.start; i < pageIndexes.end; i++) {
      result.push(
        <Button
          key={i}
          variant="secondary"
          className={pageIndexes.current === i ? 'active' : ''}
          onClick={this.createCallback(i)}
        >
          {i + 1}
        </Button>,
      );
    }

    if (isNextPrevShown) {
      result.push(
        <Button
          key="next"
          variant="secondary"
          disabled={pageIndexes.current >= pageIndexes.last}
          onClick={this.createCallback(pageIndexes.current + 1)}
        >
          <i className="bi-chevron-right" />
        </Button>,
      );
    }

    result.push(
      <Button
        data-test="pager_lastPage"
        key="last"
        variant="secondary"
        disabled={pageIndexes.current >= pageIndexes.last}
        onClick={this.createCallback(pageIndexes.last)}
      >
        Last Page
      </Button>,
    );

    return result;
  }

  createCallback(pageNumber) {
    return event => this.moveToPage(event, pageNumber);
  }

  moveToPage(event, pageNumber) {
    event.preventDefault();

    if (pageNumber < 0 || pageNumber >= this.props.totalPages) {
      return null;
    }

    this.props.callback(pageNumber);

    return true;
  }

  render() {
    return (
      <div className="ccm-table-pager">
        <ButtonGroup aria-label="Table Pager">
          {this.buildPager()}
        </ButtonGroup>
      </div>
    );
  }
}

export default Pager;
