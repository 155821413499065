// Libraries
import React, { useState, Suspense, lazy } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Components
import CareTeamRow from './CareTeamRow';
// Custom Hooks
import useLoadCareTeam from '../../../../hooks/services/useLoadCareTeam';
// Lazy
const CareTeamModal = lazy(() => import('./CareTeamModal'));

export const CareTeam = () => {
  const { id: patientId } = useParams();
  const [collapseAll, setCollapseAll] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [careTeamItem, setCareTeamItem] = useState(null);

  const { careTeam: careTeamList, refetch } = useLoadCareTeam({ patientId });

  const handleCareTeam = (item) => {
    setCareTeamItem(item);
    setIsModalOpen(true);
  };

  return (
    <div className="card border-0 mb-4 care-team-section">
      <div className="card-header d-flex-center rounded-0 border bg-ccm-light-gray text-ccm-bismark py-1">
        <p className="text-uppercase mb-0 ml-auto">care team</p>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '4rem' }}
          onClick={() => setCollapseAll(!collapseAll)}
          data-test="careTeam_expandBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="ml-auto"
          data-test="careTeam_openModalBtn"
          onClick={() => handleCareTeam(null)}
        >
          <i className="d-flex-center bi-plus-lg" data-test="careTeam_plusIcon" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2" data-test="careTeam_name">name</th>
              <th className="py-2" data-test="careTeam_category">category</th>
              <th className="py-2" data-test="careTeam_relationship">type/relationship</th>
              <th className="py-2" data-test="careTeam_phone">phone</th>
              <th className="py-2" />
            </tr>
          </thead>
          <tbody>
            <CareTeamRow
              refetch={refetch}
              collapseAll={collapseAll}
              careTeamList={careTeamList}
              handleCareTeam={handleCareTeam}
            />
          </tbody>
        </table>
      </div>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CareTeamModal
            careTeam={careTeamItem}
            refetch={refetch}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </Suspense>
    </div>
  );
};

export default CareTeam;
