// Libraries
import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button, Form, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// Services
import { addPatientReminder, createIntervention, updateIntervention } from '../../../../services/patient';
import {
  draftToString, isRichEditorText, showFormattedText, getCategories, getSubcategories,
} from '../../../../services/helpers';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdatePatient, UpdatePatientCarePlan } from '../../../../actions/patient';
// Constants
import {
  DATE_FORMAT, NOTIFICATION_TYPE,
  DIALOG_STYLES, PATIENT_CALL_TIME_OPTIONS,
} from '../../../../constants/constants';
import {
  INTERVENTIONS_TYPES,
  INTERVENTION_SUBCATEGORIES, INTERVENTION_PRIORITY_VALUES,
  INTERVENTION_ESCALATION_TYPES, DEPRECATED_INTERVENTIONS_TYPES,
} from '../../../../constants/interventions';
import { interventionTemplates } from '../../../../constants/templates';
// Local Constants
const DEFAULT_INTERVENTION = {
  id: null,
  note: '',
  title: '',
  type: 'APPOINTMENT',
  resolvedDate: '',
  escalationType: '',
  escalationDate: '',
  escalationContact: '',
  priority: INTERVENTION_PRIORITY_VALUES.NORMAL.name,
};
const DEFAULT_REMINDER = {
  id: '',
  text: '',
  reminderDate: '',
  reminderTime: '',
  status: 'CREATED',
  addReminder: false,
};

const saveIntervention = ({
  patientId, carePlan = { interventions: [] }, resetSort,
  newIntervention, showNotification, updatePatient, updatePatientCarePlan,
}) => {
  const intervention = newIntervention;
  const isNewIntervention = !intervention.id;

  const saveInterventionRequest = isNewIntervention
    ? createIntervention(patientId, intervention) : updateIntervention(intervention);
  const saveInterventionPromise = saveInterventionRequest.promise;

  return saveInterventionPromise.then((data) => {
    delete saveInterventionPromise.promise;

    const newInterventions = [
      ...carePlan.interventions.filter(i => i.id !== data.id),
      data,
    ];
    const sortedInterventions = newInterventions.sort((a, b) => b.id - a.id);
    if (resetSort) resetSort();

    updatePatient({ hasInterventions: sortedInterventions.length });
    updatePatientCarePlan({
      ...carePlan,
      interventions: sortedInterventions,
    });

    showNotification({
      message: 'Intervention saved.',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.SUCCESS,
    });
  }).catch((error) => {
    delete saveInterventionPromise.promise;

    if (error.status === 401 || error.status === 403 || error.isCanceled) {
      return;
    }
    showNotification({
      message: `An error occurred while attempting to ${isNewIntervention ? 'add' : 'modify'} this intervention.`,
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
  });
};

const saveReminder = ({
  patientId, showNotification,
  updatePatient, newReminder, reminders = [],
}) => {
  const addReminderRequest = addPatientReminder(patientId, newReminder);
  const addReminderPromise = addReminderRequest.promise;

  return addReminderPromise.then((data) => {
    delete addReminderPromise.promise;

    const updatedReminders = [...reminders, data];
    updatePatient({
      reminders: updatedReminders,
      hasReminders: updatedReminders.filter(x => x.status === 'CREATED').length,
    });

    showNotification({
      message: 'Reminder saved.',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.SUCCESS,
    });
  }).catch((error) => {
    delete addReminderPromise.promise;

    if (error.status === 401 || error.status === 403 || error.isCanceled) {
      return;
    }
    showNotification({
      message: 'An error occurred while attempting to save this reminder',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
  });
};

export const InterventionsModal = (props) => {
  const {
    initialIntervention, isModalOpen, setIsModalOpen, modalCallback, modalTitle, resetSort,
  } = props;

  const [newIntervention, setNewIntervention] = useState(
    initialIntervention || DEFAULT_INTERVENTION,
  );
  const [newReminder, setNewReminder] = useState(DEFAULT_REMINDER);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [interventionTemplate, setInterventionTemplate] = useState('');

  useEffect(() => {
    if (initialIntervention) {
      setNewIntervention({
        id: initialIntervention.id || null,
        note: showFormattedText(initialIntervention.note),
        title: initialIntervention.title || '',
        type: initialIntervention.type || 'APPOINTMENT',
        resolvedDate: initialIntervention.resolvedDate || '',
        escalationType: initialIntervention.escalationType || '',
        escalationDate: initialIntervention.escalationDate || '',
        escalationContact: initialIntervention.escalationContact || '',
        priority: initialIntervention.priority || INTERVENTION_PRIORITY_VALUES.NORMAL.name,
      });
    } else {
      setNewIntervention(DEFAULT_INTERVENTION);
    }
  }, [initialIntervention]);

  useEffect(() => {
    const reminderTextRequired = newReminder.text.trim().length > 0;
    const reminderRequired = newReminder.addReminder && reminderTextRequired;
    if (newIntervention && (!newReminder.addReminder || reminderRequired)) {
      setSaveDisabled(false);
    } else { setSaveDisabled(true); }
  }, [newIntervention, newReminder]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNewReminder(DEFAULT_REMINDER);
    setNewIntervention(DEFAULT_INTERVENTION);
  };

  const handleSaveIntervention = () => {
    if (modalCallback) {
      modalCallback(newIntervention);
    } else {
      saveIntervention({ ...props, newIntervention, resetSort });
      setNewIntervention(DEFAULT_INTERVENTION);
      handleCloseModal();
    }
  };

  const handleInputChange = (event) => {
    const {
      target: {
        name, value = '', type = '', checked = null,
      } = {},
    } = event || {};

    setNewIntervention({
      ...newIntervention,
      [name]: type === 'checkbox' ? checked : value,
      /* Reset Intervention 'Type' when 'Category' changes */
      ...(name === 'type' && { title: '' }),
    });

    /* Reset Intervention 'Custom Template' when 'Category' or 'Type' changes */
    if (name === 'title' || name === 'type') setInterventionTemplate('');
  };

  const handleReminderChange = (event) => {
    const {
      target: {
        name, value = '', type = '', checked = null,
      } = {},
    } = event || {};

    setNewReminder({
      ...newReminder,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleCustomTemplate = (event) => {
    const noteValue = newIntervention.note
      && newIntervention.note.length
      ? `${newIntervention.note}\n\n` : '';

    setInterventionTemplate(event.value);
    handleInputChange({ target: { value: event && `${noteValue}${event.value}`, name: 'note' } });
  };

  const getCustomTemplates = () => interventionTemplates(
    newIntervention.type, newIntervention.title,
  );

  const getTimeOptions = () => {
    const callTimeOptions = [];
    PATIENT_CALL_TIME_OPTIONS.forEach((item) => {
      callTimeOptions.push({
        value: item.value,
        label: item.name,
      });
    });
    return callTimeOptions;
  };

  const renderCategory = () => {
    let categoryLabel;
    if (newIntervention.type && !INTERVENTIONS_TYPES[newIntervention.type]) {
      if (DEPRECATED_INTERVENTIONS_TYPES[newIntervention.type]) {
        categoryLabel = `Current: ${DEPRECATED_INTERVENTIONS_TYPES[newIntervention.type].title}`;
      } else {
        categoryLabel = `Current: ${newIntervention.type}`;
      }
    }
    return (
      <Fragment>
        <Select
          name="type"
          placeholder="Category"
          searchable
          clearable={false}
          options={getCategories()}
          value={INTERVENTIONS_TYPES[newIntervention.type] ? newIntervention.type : ''}
          onChange={ev => handleInputChange({ target: { value: ev && ev.value, name: 'type' } })}
          data-test="interventionModal_selectCategory"
        />
        <Form.Text className="text-muted">
          {categoryLabel}
        </Form.Text>
      </Fragment>);
  };

  const renderSubcategory = () => {
    let subcategoryLabel;

    if (newIntervention.title && !!newIntervention.title.length
      && INTERVENTION_SUBCATEGORIES[newIntervention.type]
      && !INTERVENTION_SUBCATEGORIES[newIntervention.type].includes(newIntervention.title)) {
      subcategoryLabel = `Current: ${newIntervention.title}`;
    }
    return (
      <Fragment>
        <Select
          name="title"
          searchable
          clearable={false}
          value={newIntervention.title}
          disabled={!INTERVENTION_SUBCATEGORIES[newIntervention.type]}
          options={INTERVENTION_SUBCATEGORIES[newIntervention.type]
            ? getSubcategories(INTERVENTION_SUBCATEGORIES[newIntervention.type]) : []}
          onChange={ev => handleInputChange({ target: { value: ev && ev.value, name: 'title' } })}
          data-test="interventionModal_selectTitle"
          required
        />
        <Form.Text className="text-muted">
          {subcategoryLabel}
        </Form.Text>
      </Fragment>);
  };

  const renderPriority = () => (
    <Dropdown
      onSelect={eventKey => handleInputChange({ target: { value: eventKey, name: 'priority' } })}
      data-test="interventionModal_dropdownPriority"
    >
      <Dropdown.Toggle
        size="sm"
        className="px-4 border-ccm-gray border-left-0 border-right-0 border-top-0 dropdown-items dropdown-items-text"
        variant="light"
      >
        {INTERVENTION_PRIORITY_VALUES[newIntervention.priority].name || '--'}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0">
        {Object.keys(INTERVENTION_PRIORITY_VALUES).map(priorityValue => (
          <Dropdown.Item
            eventKey={priorityValue}
            key={`interventionPriority__${priorityValue}`}
            active={newIntervention.priority === priorityValue}
            className="py-2 dropdown-items-text"
            data-test="interventionModal_priorityOptions"
          >
            {INTERVENTION_PRIORITY_VALUES[priorityValue].name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  const renderEscalationDate = () => {
    const { escalationDate, createdDate } = newIntervention;

    const today = moment.utc().toDate();

    const formattedCreatedDate = (createdDate && moment(createdDate).isValid())
      ? moment(createdDate).toDate() : today;

    const formattedEscalationDate = (escalationDate && moment(escalationDate).isValid())
      ? moment(escalationDate).toDate()
      : null;

    const getDateValue = (value) => {
      const formatDate = moment(value).format(DATE_FORMAT.FULL_SERVER);
      handleInputChange({ target: { value: formatDate, name: 'escalationDate' } });
    };

    return (
      <DatePicker
        maxDate={today}
        minDate={formattedCreatedDate}
        className="form-control"
        aria-describedby="escalationDate Date Picker"
        selected={formattedEscalationDate || null}
        onChange={ev => getDateValue(ev)}
        data-test="interventionModal_escalationDateField"
      />
    );
  };

  const renderTextArea = () => {
    let noteValue = newIntervention.note;

    if (isRichEditorText(newIntervention.note)) {
      noteValue = draftToString(JSON.parse(newIntervention.note).blocks);
    }

    return (
      <Form.Control
        as="textarea"
        rows={4}
        value={noteValue}
        onChange={ev => handleInputChange({ target: { value: ev.target.value, name: 'note' } })}
        data-test="noteTextArea"
      />
    );
  };

  const renderReminderDate = () => {
    const { reminderDate } = newReminder;

    const formattedReminderDate = (reminderDate && moment(reminderDate).isValid())
      ? moment(reminderDate).toDate()
      : null;

    const getDateValue = (value) => {
      const formatDate = moment(value).format(DATE_FORMAT.FULL);
      handleReminderChange({ target: { value: formatDate, name: 'reminderDate' } });
    };

    return (
      <DatePicker
        minDate={new Date()}
        todayButton="Today"
        className="form-control"
        aria-describedby="Reminder Date Picker"
        selected={formattedReminderDate || null}
        onChange={ev => getDateValue(ev)}
        data-test="interventionModal_reminderDate"
      />
    );
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Interventions Modal"
      shouldCloseOnOverlayClick={false}
      data-test="interventionModal_modal"
    >
      <div className="simple-dialog big-dialog intervention-modal">
        <div className="dialog-title">
          {modalTitle || `${initialIntervention ? 'Edit' : 'Add'} Intervention`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="interventionModal_modalCloseButton"
          />
        </div>
        <div className="dialog-content text-left">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (newReminder.addReminder) {
                saveReminder({ ...props, newReminder });
              }
              handleSaveIntervention();
            }}
            data-test="interventionModal_formComponent"
          >
            <div className="row">
              <div className="col-3">
                <Form.Group controlId="formInterventionType" className="mb-2" data-test="interventionModal_category">
                  <Form.Label>Category</Form.Label>
                  {renderCategory()}
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="formInterventionTitle" className="mb-2" data-test="interventionModal_type">
                  <Form.Label>Type</Form.Label>
                  {renderSubcategory()}
                </Form.Group>
              </div>
              <div className="col-3">
                <Form.Group controlId="formInterventionPriority" className="mb-2">
                  <Form.Label>Priority</Form.Label>
                  {renderPriority()}
                </Form.Group>
              </div>
              {getCustomTemplates().length > 0 && (
                <div className="col-3" data-test="interventionModal_customTemplate_Label">
                  <Form.Group controlId="formInterventionTemplate" className="mb-2">
                    <Form.Label>Custom Template</Form.Label>
                    <Select
                      name="interventionTemplate"
                      placeholder="Select..."
                      searchable
                      clearable={false}
                      options={getCustomTemplates()}
                      value={interventionTemplate}
                      onChange={ev => handleCustomTemplate(ev)}
                      data-test="interventionModal_customTemplate"
                    />
                  </Form.Group>
                </div>
              )}
            </div>
            {newIntervention.priority !== INTERVENTION_PRIORITY_VALUES.NORMAL.name && (
              <div className="row mt-3">
                <div className="col-3">
                  <Form.Group controlId="formInterventionEscalationType" className="mb-2" data-test="interventionModal_escalationTypeDropDown">
                    <Form.Label>Escalation Type</Form.Label>
                    <Select
                      name="escalationType"
                      searchable
                      clearable={false}
                      value={newIntervention.escalationType}
                      options={INTERVENTION_ESCALATION_TYPES}
                      onChange={ev => handleInputChange({ target: { value: ev && ev.value, name: 'escalationType' } })}
                      data-test="interventionModal_escalationTypeSelect"
                    />
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group controlId="formInterventionEscalationType" className="mb-2" data-test="interventionModal_escalationDate">
                    <Form.Label className="label-space">Escalation Date</Form.Label>
                    {renderEscalationDate()}
                  </Form.Group>
                </div>
                <div className="col-3">
                  <Form.Group controlId="formInterventionEscalationContact" className="mb-2">
                    <Form.Label className="label-space">Escalation Contact</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength="128"
                      name="escalationContact"
                      value={newIntervention.escalationContact}
                      placeholder="no contact specified"
                      onChange={handleInputChange}
                      data-test="escalationContactInput"
                    />
                  </Form.Group>
                </div>
              </div>
            )}
            <div className="row mt-3">
              <div className="col">
                <Form.Group controlId="formInterventionNote" className="mb-2" data-test="interventionModal_noteTextBox">
                  <Form.Label>Note</Form.Label>
                  {renderTextArea()}
                </Form.Group>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <Form.Group controlId="formInterventionReminder">
                  <Form.Check
                    label="Add a reminder"
                    name="addReminder"
                    checked={newReminder.addReminder}
                    onChange={ev => handleReminderChange(ev)}
                    data-test="interventionModal_checkboxAddReminder"
                  />
                </Form.Group>
              </div>
            </div>
            {newReminder.addReminder && (
              <Fragment>
                <div className="row">
                  <div className="col">
                    <Form.Group controlId="formInterventionReminderDescription">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        name="text"
                        isInvalid={newReminder.text.trim().length === 0}
                        data-test="interventionModal_textareaReminder"
                        aria-describedby="Reminder Description Text Area"
                        onChange={ev => handleReminderChange(ev)}
                      />
                      <Form.Control.Feedback type="invalid" muted>Required</Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <Form.Group controlId="formInterventionReminderDate" data-test="interventionModal_reminderDateLabel">
                      <Form.Label className="label-space">Date</Form.Label>
                      {renderReminderDate()}
                    </Form.Group>
                  </div>
                  <div className="col-3">
                    <Form.Group controlId="formInterventionReminderTime" className="mb-2" data-test="interventionModal_reminderTimeLabel">
                      <Form.Label>Time</Form.Label>
                      <Select
                        name="reminderTime"
                        placeholder="Select..."
                        searchable
                        clearable={false}
                        options={getTimeOptions()}
                        value={newReminder.reminderTime}
                        onChange={ev => handleReminderChange({ target: { value: ev && ev.value, name: 'reminderTime' } })}
                        data-test="interventionModal_selectReminderTime"
                      />
                    </Form.Group>
                  </div>
                </div>
              </Fragment>
            )}
            <div className="dialog-buttons justify-content-end mt-3 mb-1">
              <Button variant="light" onClick={() => handleCloseModal()} data-test="interventionModal_cancelBtn">Cancel</Button>
              <Button
                type="submit"
                variant="primary"
                className="ml-2"
                disabled={saveDisabled}
                data-test="saveNewInterventionButton"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export function mapStateToProps(state) {
  return {
    reminders: state.patient && state.patient.reminders,
    carePlan: state.patient && state.patient.carePlan,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updatePatientCarePlan: carePlanData => dispatch(UpdatePatientCarePlan(carePlanData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InterventionsModal);
