// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
import { PATIENTS_LIST_PAGE_SIZE } from '../../constants/pageSizes';
// Services
import { getPatientsHistoryStatus } from '../../services/patient';

const useLoadHistoryStatus = ({
  pageSize = PATIENTS_LIST_PAGE_SIZE, pageNumber = 0, optionalFilter,
}) => {
  const [historyStatuses, setHistoryStatuses] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPatientsHistoryStatus = useCallback(async () => {
    const alertsParams = {
      pageSize,
      pageNumber,
      optionalFilter,
    };
    const getPatientsHistoryStatusRequest = getPatientsHistoryStatus(alertsParams);
    const getPatientsHistoryStatusPromise = getPatientsHistoryStatusRequest.promise;
    try {
      const data = await getPatientsHistoryStatusPromise;
      setHistoryStatuses(data);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patients history statuses.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [pageSize, pageNumber, optionalFilter]);

  useEffect(() => {
    fetchPatientsHistoryStatus();
  }, [fetchPatientsHistoryStatus]);

  return { historyStatuses, refetch: fetchPatientsHistoryStatus };
};

export default useLoadHistoryStatus;
