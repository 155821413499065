import { createAction, createReducer } from '@reduxjs/toolkit';

const physiciansList = createAction('PHYSICIANS_LIST');
const billingPhysiciansList = createAction('BILLING_PHYSICIANS_LIST');

const initialState = {
  physiciansList: {
    Active: {
      data: [],
      totalPages: 0,
      loadedPages: [],
    },
  },
};

const Physicians = createReducer(initialState, (builder) => {
  builder
    .addCase(physiciansList, (state, action) => {
      state.physiciansList = action.data.physiciansList || state.physiciansList;
    })
    .addCase(billingPhysiciansList, (state, action) => {
      state.billingPhysiciansList = action.billingPhysiciansList;
    })
    .addDefaultCase(state => state);
});

export default Physicians;
