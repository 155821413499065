export function SetHealthFactorList(data) {
  return {
    type: 'SET_HF_LIST',
    healthFactors: data || [],
  };
}

export function SetAdminHF(data) {
  return {
    type: 'SET_ADMIN_HF',
    adminHealthFactors: data || {},
  };
}

export function SetHFResources(data) {
  return {
    type: 'SET_HF_RESOURCES',
    resources: data || {},
  };
}
