// Libraries
import React, { Fragment, useEffect, useState } from 'react';
import _isNaN from 'lodash/isNaN';
// Components
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// Views
import MetricsTitle from './MetricsTitle';

export const MetricsBreakdown = (props) => {
  const { metricsData, billMetricBreakdown, isTeamMetrics = false } = props;

  const [tabIndex, setTabIndex] = useState(0);
  const [monthlyBilling, setMonthlyBilling] = useState([]);
  const [monthlyEnrollment, setMonthlyEnrollment] = useState([]);

  const validateObject = (metric, attribute) => (
    metric.progress && metric.progress[attribute]
      ? metric.progress[attribute]
      : { done: 0, goal: 0 }
  );

  const getPercentage = (perSchedule) => {
    const done = perSchedule && perSchedule.done ? perSchedule.done : 0;
    const goal = perSchedule && perSchedule.goal ? perSchedule.goal : 0;
    const validPercentage = (done * 100) / goal;

    return _isNaN(validPercentage) ? 0 : Math.round(validPercentage);
  };

  const getList = attribute => metricsData.map((user) => {
    const perSchedule = validateObject(user, attribute);
    return {
      name: `${user.firstName} ${user.lastName}`,
      totalCount: perSchedule,
      percentage: getPercentage(perSchedule),
    };
  });

  const getBillList = () => billMetricBreakdown.map((user) => {
    const totalGoal = user.metrics.assignedCount - user.metrics.doNotBillCount;
    const validPercentage = (user.metrics.billedThisMonthCount * 100) / totalGoal;
    return {
      name: `${user.firstName} ${user.lastName}`,
      totalCount: { done: user.metrics.billedThisMonthCount, goal: totalGoal },
      percentage: _isNaN(validPercentage) ? 0 : Math.round(validPercentage),
    };
  });

  useEffect(() => {
    setMonthlyBilling(getBillList());
    setMonthlyEnrollment(getList('ENROLLED_PATIENTS_PER_MONTH'));
  }, [metricsData]);

  const renderTotalCount = (totalCount) => {
    const validGoal = totalCount.goal === 0 ? '---' : totalCount.goal;
    return `${totalCount.done} / ${validGoal} patients`;
  };

  const renderPercentage = (percentage) => {
    const validPercentage = percentage === 0 ? '--' : percentage;
    return `${validPercentage}%`;
  };

  const renderTable = list => (
    <div className="text-left w-75 mt-3 ml-5">
      <Table borderless size="sm">
        <tbody>
          {list && list.length > 0 ? (
            list.map((item, index) => (
              <tr key={`user__${index}`}>
                <td>{item.name}</td>
                <td>{renderTotalCount(item.totalCount)}</td>
                <td>{renderPercentage(item.percentage)}</td>
              </tr>
            ))
          ) : (
            <tr><td colSpan="3">There are no users</td></tr>
          )}
        </tbody>
      </Table>
    </div>
  );

  return (
    <Fragment>
      <MetricsTitle isTeamMetrics={isTeamMetrics} />
      <div className="container-fluid px-5">
        <div className="card w-75">
          <Tabs activeKey={tabIndex} onSelect={index => setTabIndex(index)} unmountOnExit className="mx-4 mt-4" data-test="metricsBreakdown_tabs">
            <Tab eventKey={0} title="Monthly Billing" tabClassName="p-1 mr-2">
              {renderTable(monthlyBilling)}
            </Tab>
            <Tab eventKey={1} title="Monthly Enrollment" tabClassName="p-1 mr-2">
              {renderTable(monthlyEnrollment)}
            </Tab>
          </Tabs>
        </div>
      </div>
    </Fragment>
  );
};

export default MetricsBreakdown;
