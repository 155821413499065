import React from 'react';
import { formatPhone } from '../../../services/helpers';

const PCPNumber = ({ phoneNumber }) => {
  const formattedPhoneNumber = formatPhone(phoneNumber);

  return (
    <div>
      <label htmlFor="pcpPhone">PCP Phone: </label>
      <span
        id="pcpPhone"
        className="mr-2"
        data-test="primaryCarePhysician_pcpPhone"
        style={{
          marginLeft: '10px', border: 'none', background: 'none',
        }}
      >
        {formattedPhoneNumber.length > 0 ? formattedPhoneNumber : '---'}
      </span>
    </div>
  );
};

export default PCPNumber;
