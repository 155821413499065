// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
// actions
import ShowNotification from '../../../../actions/notification';
// constants
import { NOTIFICATION_TYPE } from '../../../../constants/constants';
// services
import { updateRequireReviewValue } from '../../../../services/administration';
import ErrorBuilder from '../../../../services/errorMessageBuilder';

export class RequireReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewRequired: props.user.reviewRequired,
    };

    this.promises = {};

    this.requireReview = this.requireReview.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  requireReview(reviewRequiredValue) {
    const { showNotification } = this.props;
    const { submitCallback } = this.props;

    const promiseName = 'updateRequireReviewValue';

    const updateRequireReviewValueRequest = updateRequireReviewValue(this.props.user.id,
      reviewRequiredValue);

    const updateRequireReviewPromise = updateRequireReviewValueRequest.promise;
    this.promises[promiseName] = updateRequireReviewValueRequest;


    updateRequireReviewPromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        reviewRequired: reviewRequiredValue,
      });

      if (submitCallback) {
        const requireReviewData = { reviewRequired: { reviewRequiredValue } };
        submitCallback(requireReviewData);
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: ErrorBuilder(error.data),
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  render() {
    const { reviewRequired } = this.state;

    return (
      <div>
        <div className="require-review-control">
          <div className="require-review-toggle" onClick={() => this.requireReview(!reviewRequired)}>
            <div className={`direct-contact-toggle ${reviewRequired ? 'active' : ''}`} />
            Require Review
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(RequireReview);
