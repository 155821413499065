// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getCarePlan } from '../../services/patient';
// Actions
import { UpdatePatient, UpdatePatientCarePlan } from '../../actions/patient';

const useLoadPatientCarePlan = ({ patientId, shouldPatientBeUpdated }) => {
  const [patientCarePlan, setPatientCarePlan] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPatientCarePlan = useCallback(async () => {
    const getCarePlanRequest = getCarePlan(patientId);
    const getCarePlanPromise = getCarePlanRequest.promise;
    try {
      const {
        carePlan, patient,
        carePlan: { resolvedInterventions = [], unresolvedInterventions = [] },
      } = await getCarePlanPromise;
      let newPatientInfo = {
        hasGoals: carePlan.goals && carePlan.goals.length,
        hasInterventions: resolvedInterventions.length || unresolvedInterventions.length,
      };

      if (shouldPatientBeUpdated && patient) {
        newPatientInfo = { ...patient, ...newPatientInfo };
      }

      const newCarePlan = {
        ...carePlan,
        interventions: [
          ...resolvedInterventions,
          ...unresolvedInterventions,
        ],
      };
      delete newCarePlan.resolvedInterventions;
      delete newCarePlan.unresolvedInterventions;

      setPatientCarePlan(newCarePlan);
      dispatch(UpdatePatient(newPatientInfo));
      dispatch(UpdatePatientCarePlan(newCarePlan));
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patient care plan.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, []);

  useEffect(() => {
    fetchPatientCarePlan();
  }, [fetchPatientCarePlan]);

  return { patientCarePlan, refetch: fetchPatientCarePlan };
};

export default useLoadPatientCarePlan;
