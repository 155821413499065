// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import _debounce from 'lodash/debounce';
import { Button, Form } from 'react-bootstrap';
// actions
import ShowNotification from '../../../actions/notification';
import { SetPhysiciansList } from '../../../actions/physicians';
// constants
import { TEAM_MEMBERS_PAGE_SIZE } from '../../../constants/pageSizes';
import { DIALOG_DEFAULTS, NOTIFICATION_TYPE } from '../../../constants/constants';
// services
import { getPhysicians, getPhysicianTypeahead } from '../../../services/providers';
import { getEligibilityRules, updateEligibilityRules } from '../../../services/administration';
import { validateDigitsRequired, validateRequired, validateCleanString } from '../../../services/helpers';
// views
import EditableInput from '../../base/EditableInput';
import Checkbox from '../../base/Checkbox';
import LoadingBlock from '../../menu/LoadingBlock';

const PHYSICIANS_BILLABLE = {
  all: 'All',
  billable: 'Billable',
  notBillable: 'Not Billable',
};

const PHYSICIANS_ACTIVE = {
  all: 'All',
  active: 'Active',
  inactive: 'Inactive',
};

const SELECTION_TYPES = {
  ALL: 'ALL',
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
};

const DEFAULT_MINIMUM_AGE = 50;

export class EligibilityRules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      savingInProgress: false,
      selectedProviders: [],
      providerSelectionType: SELECTION_TYPES.ALL,
      requireMinimumAge: false,
      providerFilter: '',
      filteredProviders: props.physiciansList[PHYSICIANS_ACTIVE.active].data || [],
      rules: {
        minAge: null,
        planNameSelectionType: SELECTION_TYPES.ALL,
        planNames: null,
        planIdSelectionType: SELECTION_TYPES.ALL,
        planIds: null,
        providerSelectionType: SELECTION_TYPES.ALL,
        providerNames: null,
      },
      searchProviders: [],
      providersModalOpened: false,
      phPage: 0,
      phActiveStatus: PHYSICIANS_ACTIVE.active,
      phBillableStatus: PHYSICIANS_BILLABLE.billable,
      loading: false,
    };

    this.promises = {};
    this.getRules = this.getRules.bind(this);
    this.setMinimumAge = this.setMinimumAge.bind(this);
    this.updateRules = this.updateRules.bind(this);
    this.openProvidersDialog = this.openProvidersDialog.bind(this);
    this.handleDebounceFilter = _debounce(this.filterProviders, 500);
    this.closeProvidersDialogWithoutSaving = this.closeProvidersDialogWithoutSaving.bind(this);
  }

  componentDidMount() {
    const { physiciansList } = this.props;
    const { phActiveStatus } = this.state;

    this.getRules();
    this.setState({
      phPage: (physiciansList[phActiveStatus]
        && physiciansList[phActiveStatus].loadedPages.length
        && physiciansList[phActiveStatus].loadedPages.indexOf(false) > 0)
        ? physiciansList[phActiveStatus].loadedPages.indexOf(false) - 1 : 0,
    });
    this.getPhysicianList();
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
    this.handleDebounceFilter.cancel();
  }

  getRules() {
    const { showNotification } = this.props;

    const promiseName = 'getEligibilityRules';
    const getEligibilityRulesRequest = getEligibilityRules();
    const getEligibilityRulesPromise = getEligibilityRulesRequest.promise;
    this.promises[promiseName] = getEligibilityRulesRequest;

    getEligibilityRulesPromise.then((data) => {
      delete this.promises[promiseName];

      const result = { ...data };

      let selectedProviders = [];
      if (result.providerNames && result.providerNames.length > 0) {
        selectedProviders = result.providerNames.slice();
      }

      if (result.planIds) {
        result.planIds = data.planIds.join(', ');
      }
      if (result.planNames) {
        result.planNames = data.planNames.join(', ');
      }

      this.setState({
        requireMinimumAge: (result.minAge !== null),
        selectedProviders,
        rules: result,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error occurred while trying to load eligibility rules.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getPhysicianList = async () => {
    const { phPage, phActiveStatus, loading } = this.state;
    const { physiciansList, setPhysiciansList, showNotification } = this.props;

    if (physiciansList[phActiveStatus]
      && physiciansList[phActiveStatus].loadedPages[phPage]) {
      return;
    }

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const activePhysicians = phActiveStatus === PHYSICIANS_ACTIVE.active;

    const promiseName = 'getBillingPhysicians';
    const getPhysiciansRequest = getPhysicians(
      {
        pageSize: TEAM_MEMBERS_PAGE_SIZE,
        sortBy: 'firstName',
        pageNumber: phPage,
        activeStatus: activePhysicians,
      },
    );
    const getPhysiciansPromise = getPhysiciansRequest.promise;
    this.promises[promiseName] = getPhysiciansRequest;

    getPhysiciansPromise.then((data) => {
      delete this.promises[promiseName];

      let physiciansListData = physiciansList[phActiveStatus]
        ? [...physiciansList[phActiveStatus].data] : [];
      if (physiciansListData.length === 0) {
        physiciansListData = new Array(data.totalPages * TEAM_MEMBERS_PAGE_SIZE).fill(null);
      }

      let physiciansListPages = physiciansList[phActiveStatus]
        ? [...physiciansList[phActiveStatus].loadedPages] : [];
      if (physiciansListPages.length === 0) {
        physiciansListPages = new Array(data.totalPages).fill(false);
      }
      physiciansListPages[phPage] = true;

      const splicedPhysiciansList = physiciansListData;
      splicedPhysiciansList.splice(
        phPage * TEAM_MEMBERS_PAGE_SIZE,
        TEAM_MEMBERS_PAGE_SIZE,
        ...data.physicians,
      );

      const physiciansListLoaded = { ...physiciansList };

      physiciansListLoaded[phActiveStatus] = {
        ...physiciansList[phActiveStatus],
        data: splicedPhysiciansList,
        totalPages: data.totalPages,
        loadedPages: physiciansListPages,
      };

      this.setState(prevState => ({
        ...prevState,
        filteredProviders: splicedPhysiciansList,
        loading: false,
      }));

      setPhysiciansList({
        physiciansList: physiciansListLoaded,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });
      showNotification({
        message: 'Could not load physicians list',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  getSpecificPhysicianInput = async (filter) => {
    const { showNotification } = this.props;
    const getPhysicianTypeaheadRequest = await getPhysicianTypeahead({ filter });

    try {
      const response = await getPhysicianTypeaheadRequest.promise;
      const ordering = this.processPhysiciansList(response).sort(
        (a, b) => a.label.localeCompare(b.label),
      );
      const data = ordering.filter(
        provider => provider && provider.label.toLowerCase().includes(filter),
      );

      delete getPhysicianTypeaheadRequest.promise;
      return data;
    } catch (error) {
      delete getPhysicianTypeaheadRequest.promise;

      if (error.status === 401 || error.status === 403 || error.isCanceled) return [];

      showNotification({
        message: 'Could not load providers, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });

      return [];
    }
  };

  setMinimumAge(label, value) {
    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      requireMinimumAge: true,
      rules: {
        ...prevState.rules,
        minAge: value,
      },
    }), () => {
      this.updateRules();
    });
  }

  setProviderFilter = (filter) => {
    this.setState(prevState => ({
      ...prevState,
      providerFilter: filter,
    }), () => {
      this.handleDebounceFilter();
    });
  }

  processPhysiciansList = (list = []) => [
    ...list.filter(n => n).map(physician => ({
      ...physician,
      value: physician.id,
      label: (`${physician.firstName || ''} ${physician.middleName || ''} ${physician.lastName || ''}`).trim().replace(/  +/g, ' '),
    })),
  ];

  clearProviderFilter() {
    this.setState(prevState => ({
      ...prevState,
      providerFilter: '',
    }), () => {
      this.filterProviders();
    });
  }

  updateRules() {
    const { showNotification } = this.props;
    const { rules } = this.state;

    let planNames = null;
    if (rules.planNames) {
      planNames = rules.planNames.split(',')
        .map(item => item.trim())
        .filter(item => item);
    }

    let planIds = null;
    if (rules.planIds) {
      planIds = rules.planIds.split(',')
        .map(item => item.trim())
        .filter(item => item);
    }

    const updatedRules = {
      minAge: rules.minAge,
      planNameSelectionType: rules.planNameSelectionType,
      planNames,
      planIdSelectionType: rules.planIdSelectionType,
      planIds,
      providerSelectionType: rules.providerSelectionType,
      providerNames: rules.providerNames,
    };

    const promiseName = 'updateEligibilityRules';
    const updateEligibilityRulesRequest = updateEligibilityRules(updatedRules);
    const updateEligibilityRulesPromise = updateEligibilityRulesRequest.promise;
    this.promises[promiseName] = updateEligibilityRulesRequest;

    updateEligibilityRulesPromise.then(() => {
      delete this.promises[promiseName];
      this.setState({
        savingInProgress: false,
        providersModalOpened: false,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        savingInProgress: false,
        providersModalOpened: false,
      });

      showNotification({
        message: 'An error occurred while attempting to update the eligibility rules.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  async filterProviders() {
    const { providerFilter } = this.state;

    this.setState(prevState => ({
      ...prevState,
      loading: true,
    }), async () => {
      const filterString = providerFilter.toLowerCase();

      let searchProviders = [];

      if (filterString && filterString.length > 1) {
        searchProviders = await this.getSpecificPhysicianInput(filterString);
      } else {
        searchProviders = [];
      }

      this.setState(prevState => ({
        ...prevState,
        searchProviders,
        loading: false,
      }));
    });
  }

  selectAllPatients() {
    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      requireMinimumAge: false,
      rules: {
        ...prevState.rules,
        minAge: null,
      },
    }), () => {
      this.updateRules();
    });
  }

  selectCustomPatients() {
    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      requireMinimumAge: true,
      rules: {
        ...prevState.rules,
        minAge: (prevState.rules.minAge !== null ? prevState.rules.minAge : DEFAULT_MINIMUM_AGE),
      },
    }), () => {
      this.updateRules();
    });
  }

  selectAllProviders() {
    const { selectedProviders } = this.state;

    if (selectedProviders && selectedProviders.length > 1) {
      const confirmMessage = `Are you sure you want to remove ${selectedProviders.length} selected providers?`;
      if (!window.confirm(confirmMessage)) {
        return;
      }
    }

    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      selectedProviders: [],
      rules: {
        ...prevState.rules,
        providerNames: null,
        providerSelectionType: SELECTION_TYPES.ALL,
      },
    }), () => {
      this.updateRules();
    });
  }

  selectCustomProviders(selectionType) {
    this.setState(prevState => ({
      ...prevState,
      rules: {
        ...prevState.rules,
        providerSelectionType: selectionType,
      },
      providersModalOpened: true,
    }));
  }

  checkboxClicked(field, value) {
    this.updateSelectedProviders(field, !value);
  }

  removeProvider(provider) {
    this.updateSelectedProviders(provider, true);
  }

  updateSelectedProviders(providerName, isRemove) {
    const { selectedProviders } = this.state;

    if (isRemove) {
      const index = selectedProviders.indexOf(providerName);
      selectedProviders.splice(index, 1);
    } else {
      selectedProviders.push(providerName);
    }

    this.setState(prevState => ({
      ...prevState,
      selectedProviders,
      rules: {
        ...prevState.rules,
      },
    }));
  }

  saveSelectedProviders() {
    const { selectedProviders } = this.state;

    this.setState(prevState => ({
      ...prevState,
      rules: {
        ...prevState.rules,
        providerNames: selectedProviders.slice(),
      },
    }), () => {
      this.updateRules();
    });
  }

  selectAllInsurancePlans() {
    const { rules } = this.state;

    if (rules && rules.planIds && rules.planIds.length > 1) {
      const confirmMessage = 'Are you sure you want to remove selected insurance plan IDs?';
      if (!window.confirm(confirmMessage)) {
        return;
      }
    }

    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      rules: {
        ...prevState.rules,
        planIdSelectionType: SELECTION_TYPES.ALL,
        planIds: null,
      },
    }), () => {
      this.updateRules();
    });
  }

  selectCustomInsurancePlans(selectionType) {
    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      rules: {
        ...prevState.rules,
        planIdSelectionType: selectionType,
      },
    }));
  }

  selectAllInsuranceNames() {
    const { rules } = this.state;

    if (rules && rules.planNames && rules.planNames.length > 1) {
      const confirmMessage = 'Are you sure you want to remove selected insurance plan names?';
      if (!window.confirm(confirmMessage)) {
        return;
      }
    }
    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      rules: {
        ...prevState.rules,
        planNameSelectionType: SELECTION_TYPES.ALL,
        planNames: null,
      },
    }), () => {
      this.updateRules();
    });
  }

  selectCustomInsuranceNames(selectionType) {
    this.setState(prevState => ({
      ...prevState,
      savingInProgress: true,
      rules: {
        ...prevState.rules,
        planNameSelectionType: selectionType,
      },
    }));
  }

  updateInsuranceValues(fieldName, value, valid) {
    if (valid) {
      const fieldValue = value;

      this.setState(prevState => ({
        ...prevState,
        savingInProgress: true,
        rules: {
          ...prevState.rules,
          [fieldName]: fieldValue,
        },
      }), () => {
        this.updateRules(this.state.rules);
      });
    }
  }

  openProvidersDialog() {
    this.setState({
      providersModalOpened: true,
    });
  }

  closeProvidersDialogWithoutSaving() {
    this.setState(prevState => ({
      providersModalOpened: false,
      selectedProviders:
        (prevState.rules && prevState.rules.providerNames
          ? prevState.rules.providerNames.slice() : []),
    }));
  }

  buildRadio(label, isSelected, isDisabled, onClickFunction) {
    return (
      <div
        className={`radio-option radio-container ${!isSelected ? 'radio-container-inactive' : ''}
        ${(isDisabled) ? 'radio-container-disabled' : ''}`}
        onClick={onClickFunction}
      >
        <div className={`gray-checkbox ${isSelected ? 'checked' : ''}`} />
        {label}
      </div>
    );
  }

  renderPatientSection() {
    const {
      rules, loading, requireMinimumAge,
    } = this.state;

    return (
      <div>
        <div className="eligibility-section-label">
          Patient Inclusion
        </div>
        <div className="eligibility-rule-container" data-test="eligibilityRules_containerAge">
          <div className="radio-group">
            {
              this.buildRadio('All patients', !requireMinimumAge, loading, this.selectAllPatients.bind(this))
            }
            <div
              className={`radio-option radio-container ${!requireMinimumAge
                ? 'radio-container-inactive'
                : ''} ${(loading) ? 'radio-container-disabled' : ''}`}
              onClick={this.selectCustomPatients.bind(this)}
            >
              <div className={`gray-checkbox ${requireMinimumAge ? 'checked' : ''}`} />
              Only patients aged
              <EditableInput
                type="text"
                submitCallback={this.setMinimumAge}
                validationCallback={validateDigitsRequired}
                fieldKey="minAge"
                initialValue={rules.minAge}
                maxLength="10"
                wrapperClassName="custom-patients-age-input"
                alwaysEditing
              />
              years and older
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderProviderSection() {
    const {
      loading, rules: { providerSelectionType },
    } = this.state;

    return (
      <div>
        <div className="eligibility-section-label">
          Provider Inclusion
        </div>
        <div className="eligibility-rule-container">
          <div className="radio-group">
            {
              this.buildRadio('All providers', (providerSelectionType === SELECTION_TYPES.ALL),
                loading, this.selectAllProviders.bind(this))
            }
            {
              this.buildRadio('Only specified providers', (providerSelectionType === SELECTION_TYPES.INCLUDE),
                loading, this.selectCustomProviders.bind(this, SELECTION_TYPES.INCLUDE))
            }
            {
              this.buildRadio('All providers except',
                (providerSelectionType === SELECTION_TYPES.EXCLUDE),
                loading, this.selectCustomProviders.bind(this, SELECTION_TYPES.EXCLUDE))
            }
          </div>
          <div className="provider-list" data-test="eligibilityRules_selectedProvider">
            {
              (providerSelectionType !== SELECTION_TYPES.ALL)
              && this.renderStaticProviderList()
            }
          </div>
        </div>
      </div>
    );
  }

  renderStaticProviderList() {
    const { rules } = this.state;

    const editButton = (
      <button
        type="button"
        className="edit-providers-link"
        onClick={this.openProvidersDialog}
        data-test="editProvidersLink"
      >
        Edit Providers
      </button>
    );

    let providerSummary = (
      <div className="selection-count-label invalid-value">
        No providers selected
      </div>
    );

    let providerList = '';
    if (rules && rules.providerNames && rules.providerNames.length > 0) {
      providerSummary = (
        <div className="selection-count-label">
          {`${rules.providerNames.length} provider(s) selected:`}
        </div>
      );

      providerList = rules.providerNames.sort((a, b) => a.localeCompare(b)).map(provider => (
        <div key={provider}>
          {provider}
        </div>
      ));
    }

    return (
      <div>
        {providerSummary}
        {providerList}
        {editButton}
      </div>
    );
  }

  renderProviderSelectionModal() {
    const {
      phPage, phActiveStatus, providersModalOpened, searchProviders,
      selectedProviders, providerFilter, filteredProviders, loading,
    } = this.state;

    const { physiciansList } = this.props;

    const physiciansData = this.processPhysiciansList(filteredProviders)
      .sort((a, b) => a.label.localeCompare(b.label));

    const scrollHasMore = providerFilter.length === 0
      ? physiciansList[phActiveStatus].totalPages > (phPage + 1) : false;

    const handleProvidersList = (providersList) => {
      if (providersList && providersList.length > 0) {
        return providersList.map(provider => (
          <Checkbox
            key={provider.id}
            label={provider.label}
            fieldKey={provider.label}
            initialValue={selectedProviders.includes(provider.label)}
            submitCallback={this.checkboxClicked.bind(this)}
          />
        ));
      }
      return <div>No providers found.</div>;
    };

    const handleAsyncList = async () => {
      const hasMorePhysicians = physiciansList[phActiveStatus].totalPages > (phPage + 1);

      if (hasMorePhysicians) {
        this.setState({
          phPage: phPage + 1,
        });
        await this.getPhysicianList();
      }
    };

    const addedProviders = selectedProviders.sort((a, b) => a.localeCompare(b)).map(provider => (
      <div className="selected-provider" key={provider}>
        <div>{provider}</div>
        <div
          className="i-close remove-provider-icon"
          onClick={this.removeProvider.bind(this, provider)}
        />
      </div>
    ));

    const infiniteList = (
      <div id="provider-search-list-ph" className="provider-search-list">
        {loading && <LoadingBlock />}
        <InfiniteScroll
          dataLength={physiciansData.length}
          next={handleAsyncList}
          hasMore={scrollHasMore}
          loader={<h4>Loading...</h4>}
          scrollThreshold="0px"
          scrollableTarget="provider-search-list-ph"
        >
          {handleProvidersList(physiciansData)}
        </InfiniteScroll>
      </div>
    );
    const filterList = (
      <div id="provider-search-list-ph" className="provider-search-list">
        {loading && <LoadingBlock />}
        {handleProvidersList(searchProviders)}
      </div>
    );

    const renderProviders = searchProviders.length > 0 ? filterList : infiniteList;

    return (
      <Modal
        isOpen={providersModalOpened}
        style={DIALOG_DEFAULTS}
        onRequestClose={this.closeProvidersDialogWithoutSaving}
      >
        <div className="simple-dialog big-dialog">
          <div className="dialog-title">
            Select Provider(s)
            <button
              className="close-icon i-close"
              onClick={this.closeProvidersDialogWithoutSaving}
              type="button"
            />
          </div>
          <div className="eligibility-provider-modal">
            <div className="provider-search-container">
              <div className="d-flex-center">
                <div className="i-search m-2" />
                <Form.Control
                  type="text"
                  className="my-2"
                  value={providerFilter}
                  data-test="eligibilityRules_searchInput"
                  onChange={e => this.setProviderFilter(e.target.value)}
                />
                <Button
                  size="sm"
                  variant="primary"
                  className="ml-1"
                  data-test="eligibilityRules_searchBtn"
                  onClick={async () => { await this.filterProviders.bind(this); }}
                >
                  Search
                </Button>
                <Button
                  size="sm"
                  variant="light"
                  className="ml-1 text-nowrap "
                  data-test="eligibilityRules_clearSearchBtn"
                  onClick={this.clearProviderFilter.bind(this)}
                >
                  Clear Search
                </Button>
              </div>
              {renderProviders}
            </div>
            <div className="provider-selection-container">
              {addedProviders}
            </div>
          </div>
          <div className="dialog-buttons justify-content-end px-4">
            <Button
              variant="light"
              onClick={this.closeProvidersDialogWithoutSaving}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ml-2"
              onClick={this.saveSelectedProviders.bind(this)}
              disabled={!selectedProviders || selectedProviders.length === 0}
              data-test="saveProviderSelectionButton"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  renderInsuranceSection() {
    const {
      loading, rules,
      rules: { planIdSelectionType, planNameSelectionType },
    } = this.state;

    return (
      <div>
        <div className="eligibility-section-label">
          Insurance Inclusion
        </div>
        <div className="eligibility-rule-container">
          <div className="elibility-section-subtitle">By name:</div>
          <div className="radio-group">
            {
              this.buildRadio('All names', (planNameSelectionType === SELECTION_TYPES.ALL),
                loading, this.selectAllInsuranceNames.bind(this))
            }
            {
              this.buildRadio('Only specified insurance names', (planNameSelectionType === SELECTION_TYPES.INCLUDE),
                loading, this.selectCustomInsuranceNames.bind(this, SELECTION_TYPES.INCLUDE))
            }
            {
              this.buildRadio('All insurance names except', (planNameSelectionType === SELECTION_TYPES.EXCLUDE),
                loading, this.selectCustomInsuranceNames.bind(this, SELECTION_TYPES.EXCLUDE))
            }
          </div>
          { (planNameSelectionType !== SELECTION_TYPES.ALL) && (
            <div data-test="eligibilityRules_selectedInsurance">
              <EditableInput
                type="text"
                submitCallback={this.updateInsuranceValues.bind(this)}
                validationCallback={validateCleanString}
                fieldKey="planNames"
                initialValue={rules.planNames}
                hidden={planNameSelectionType === SELECTION_TYPES.ALL}
                wrapperClassName="insurance-input-wrapper"
                alwaysEditing
              />
              <div className="administration-care-plan-cn-title-tip">
                Enter comma-separated plan names list.
              </div>
            </div>
          )}
        </div>
        <div className="eligibility-rule-container">
          <div className="elibility-section-subtitle">By plan ID:</div>
          <div className="radio-group">
            {
              this.buildRadio('All plan IDs', (planIdSelectionType === SELECTION_TYPES.ALL),
                loading, this.selectAllInsurancePlans.bind(this))
            }
            {
              this.buildRadio('Only specified plan IDs', (planIdSelectionType === SELECTION_TYPES.INCLUDE),
                loading, this.selectCustomInsurancePlans.bind(this, SELECTION_TYPES.INCLUDE))
            }
            {
              this.buildRadio('All plan IDs except', (planIdSelectionType === SELECTION_TYPES.EXCLUDE),
                loading, this.selectCustomInsurancePlans.bind(this, SELECTION_TYPES.EXCLUDE))
            }
          </div>
          { (planIdSelectionType !== SELECTION_TYPES.ALL) && (
            (
              <div data-test="eligibilityRules_selectedPlanId">
                <EditableInput
                  type="text"
                  submitCallback={this.updateInsuranceValues.bind(this)}
                  validationCallback={validateRequired}
                  fieldKey="planIds"
                  initialValue={rules.planIds}
                  hidden={planIdSelectionType === SELECTION_TYPES.ALL}
                  wrapperClassName="insurance-input-wrapper"
                  alwaysEditing
                />
                <div className="administration-care-plan-cn-title-tip">
                  Enter comma-separated plan IDs list.
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className="admin-section my-4"
        id="eligibilityRules"
      >
        <h5 className="text-uppercase my-3">Eligibility Rules</h5>
        <div className="content administration-care-plan">
          {this.renderPatientSection()}
          {this.renderProviderSection()}
          {this.renderProviderSelectionModal()}
          {this.renderInsuranceSection()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    physiciansList: (state.physicians && state.physicians.physiciansList
      ? state.physicians.physiciansList : []),
  };
}

export const mapDispatchToProps = dispatch => ({
  showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  setPhysiciansList: physiciansListData => dispatch(SetPhysiciansList(physiciansListData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityRules);
