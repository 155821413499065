// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
// Views
import NoteEditor from '../../shared/NoteEditor';
// Services
import { parseStringToDraft } from '../../../services/helpers';
import { addPatientQaNote, updatePatientQaNote } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient, UpdatePatientHistory } from '../../../actions/patient';
// Constants
import {
  QA_STATUSES, USER_ROLES, NOTIFICATION_TYPE, DIALOG_STYLES,
} from '../../../constants/constants';
// Local Constants
const DEFAULT_QA_NOTE = {
  id: null,
  text: '',
  status: QA_STATUSES.none.type,
};


export function QaNotesModal(props) {
  const {
    patientId, initialQaNote, isModalOpen, setIsModalOpen, showNotification, user: { role } = {},
  } = props;

  const [newNote, setNewNote] = useState(initialQaNote || DEFAULT_QA_NOTE);
  const isNewQaNote = !newNote.id;
  const isAdminUser = (role && role === USER_ROLES.ADMIN);

  useEffect(() => {
    if (initialQaNote) {
      setNewNote({
        id: initialQaNote.id || null,
        text: initialQaNote.text || '',
        status: initialQaNote.status || QA_STATUSES.none.type,
      });
    } else setNewNote(DEFAULT_QA_NOTE);
  }, [isModalOpen, initialQaNote]);


  const handleCloseModal = () => setIsModalOpen(false);

  const savePatientQaNote = () => {
    const {
      updatePatient, updatePatientHistory,
      patientHistory = {}, patientHistory: { qaNotes = [] } = {},
    } = props;

    const addQaNoteRequest = isNewQaNote
      ? addPatientQaNote(patientId, newNote) : updatePatientQaNote(patientId, newNote);
    const addQaNotePromise = addQaNoteRequest.promise;

    return addQaNotePromise.then((data) => {
      delete addQaNoteRequest.promise;
      let updatedQaNotes;
      if (isNewQaNote) updatedQaNotes = [...qaNotes, data];
      else updatedQaNotes = qaNotes.map(p => (p.id === data.id ? data : p));

      updatePatientHistory({
        ...patientHistory,
        qaNotes: updatedQaNotes,
      });
      updatePatient({ lastQaNote: data });
    }).catch((error) => {
      delete addQaNoteRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not create QA Note',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleSubmitQaNote = () => {
    savePatientQaNote();
    handleCloseModal();
  };

  const handlQaNoteText = (noteText) => {
    setNewNote({ ...newNote, text: noteText });
  };

  const handleQaNoteStatus = (event) => {
    const { status } = newNote;
    const { value } = event.target;

    if (status === value) return;

    setNewNote({ ...newNote, status: value });
  };

  const renderRadioBtn = (value, label) => {
    const { status } = newNote;

    return (
      <label
        key={value}
        className={`radio-container ${status !== value
          ? 'radio-container-inactive' : ''}`}
      >
        <input
          type="radio"
          name="qaStatus"
          value={value}
          checked={status === value}
          onClick={handleQaNoteStatus}
          onChange={handleQaNoteStatus}
          data-test={`qaNotesModal_${value}RadioBtn`}
        />
        <div className="radio-icon" />
        {label}
      </label>
    );
  };

  const disabledBtn = () => {
    if (newNote.status === QA_STATUSES.acceptFeedback.type) {
      const draftText = JSON.parse(parseStringToDraft(newNote.text));
      const plainText = draftText.blocks.map(item => item.text).join('');
      return !plainText.trim();
    }
    return false;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="QA Notes Modal"
      data-test="qaNotesModal_modal"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {`${isNewQaNote ? 'Add' : 'Edit'} Review`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="qaNotesModal_modalCloseButton"
          />
        </div>
        <div className="dialog-content">
          {isAdminUser && isNewQaNote && (
          <div className="qa-note-radios text-left">
            {renderRadioBtn(QA_STATUSES.accepted.type, QA_STATUSES.accepted.labelB)}
            {renderRadioBtn(QA_STATUSES.acceptFeedback.type, QA_STATUSES.acceptFeedback.labelB)}
            {renderRadioBtn(QA_STATUSES.requested.type, QA_STATUSES.requested.labelB)}
          </div>)}
          <div className="border rounded mt-4" style={{ height: 250, maxHeight: 300 }}>
            <NoteEditor
              note={newNote}
              handleChangeQaNote={noteText => handlQaNoteText(noteText)}
              data-test="qaNotesModal_noteEditor"
            />
          </div>
          {disabledBtn() && <Form.Text className="text-danger text-left">Comments are required when accepting with feedback</Form.Text>}
        </div>
        <div className="dialog-buttons justify-content-end px-4">
          <Button variant="light" onClick={() => handleCloseModal()} data-test="qaNotesModal_cancelBtn">Cancel</Button>
          <Button
            variant="primary"
            className="ml-2"
            onClick={() => handleSubmitQaNote()}
            data-test="qaNotesModal_saveBtn"
            disabled={disabledBtn()}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updatePatientHistory: patientHistoryData => dispatch(UpdatePatientHistory(patientHistoryData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QaNotesModal);
