// Libraries
import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
// services
import { getDaysRemaining } from '../../services/helpers';
// Custom Hooks
import useLoadHolidays from '../../hooks/services/useLoadHolidays';

const PatientListMetrics = (props) => {
  const { progressMap } = props;

  const billedMetrics = progressMap.BILLED_PATIENTS_PER_MONTH;
  let billedPercentageString = 'Billed: ?';
  let billedPercentValue = 0;
  let billedTooltip = 'No billing metrics available';

  const { holidays } = useLoadHolidays();

  if (billedMetrics && billedMetrics.done !== undefined) {
    if (billedMetrics.goal !== undefined && billedMetrics.goal > 0) {
      billedPercentValue = Math.round(
        Math.min(billedMetrics.done / billedMetrics.goal, 1) * 100,
      );
      billedPercentageString = `Billed: ${billedPercentValue}%`;
      billedTooltip = `Billed ${billedMetrics.done}/${billedMetrics.goal}`;
    } else {
      billedPercentageString = `Billed: ${billedMetrics.done}`;
      billedTooltip = `Billed ${billedMetrics.done} of unspecified total`;
    }
  }

  const enrolledMetrics = progressMap.ENROLLED_PATIENTS_PER_MONTH;
  let enrolledPercentageString = 'Enrolled: ?';
  let enrolledPercentValue = 0;
  let enrolledTooltip = 'No enrolled metrics available';

  if (enrolledMetrics && enrolledMetrics.done !== undefined) {
    if (enrolledMetrics.goal !== undefined && enrolledMetrics.goal > 0) {
      enrolledPercentValue = Math.round(
        Math.min(enrolledMetrics.done / enrolledMetrics.goal, 1) * 100,
      );
      enrolledPercentageString = `Enrolled: ${enrolledPercentValue}%`;
      enrolledTooltip = `Enrolled ${enrolledMetrics.done}/${enrolledMetrics.goal}`;
    } else {
      enrolledPercentageString = `Enrolled: ${enrolledMetrics.done}%`;
      enrolledTooltip = `Enrolled ${enrolledMetrics.done} of unspecified total`;
    }
  }

  return (
    <div className="patient-progress-bars d-flex justify-content-around align-items-center w-100 h-100">
      <div className="h-100 d-flex-center border rounded p-2 mr-1">
        <span className="metric__title text-nowrap text-uppercase mb-0">Days remaining:</span>
        <h6 className="mb-0 ml-1">{getDaysRemaining(holidays)}</h6>
      </div>
      <div className="h-100 d-flex-center flex-grow-1 border rounded p-2 mx-1">
        <span className="metric__title text-nowrap text-uppercase mb-0">This month:</span>
        <div className="d-flex-center w-100 ml-2">
          <span className="mr-2">{billedPercentageString}</span>
          <ProgressBar variant="success" now={billedPercentValue} style={{ width: '50px', height: '6px' }} data-for="tooltip-cnProgressBars" data-tip={billedTooltip} />
        </div>
        <div className="d-flex-center w-100 ml-2">
          <span className="mr-2">{enrolledPercentageString}</span>
          <ProgressBar variant="success" now={enrolledPercentValue} style={{ width: '50px', height: '6px' }} data-for="tooltip-cnProgressBars" data-tip={enrolledTooltip} />
        </div>
      </div>
      <ReactTooltip id="tooltip-cnProgressBars" type="info" place="bottom" effect="float" />
    </div>
  );
};

const mapStateToProps = state => ({
  progressMap: state.user && state.user.progressMap,
});

export default connect(mapStateToProps)(PatientListMetrics);
