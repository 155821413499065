// Libraries
import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import _camelCase from 'lodash/camelCase';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import ShowNotification from '../../../actions/notification';
// Components
import LoadingBlock from '../../menu/LoadingBlock';
// Services
import { sendAppointmentReminders } from '../../../services/patient';
import { isLessThanXHours } from '../../../services/helpers';
// Hooks
import usePatientsAvailability from '../../../hooks/services/usePatientsAvailability';
// Constants
import {
  DATE_FORMAT, NOTIFICATION_TYPE, TIME_FORMAT, EMPTY_STRING, USER_ROLES,
} from '../../../constants/constants';


export const PatientsAvailability = () => {
  const { patientsAvailability, refetch } = usePatientsAvailability();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tenant: tenantUrl } = useParams();
  const { requestsInProgress: { count: loading }, user: { role } } = useSelector(state => state);
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const isPesUser = role === USER_ROLES.PES;

  const renderEmptyRow = () => (
    <tr>
      <td colSpan="5" className="p-2 border-0 text-center">
        No patient availability info for today
      </td>
    </tr>
  );

  const renderLoadingRow = () => (
    <td colSpan="2" className="p-2">
      <LoadingBlock />
    </td>
  );

  const sendText = (patientId) => {
    const fetchRequest = sendAppointmentReminders(patientId);
    const fetchPromise = fetchRequest.promise;

    return fetchPromise.then(() => {
      delete fetchPromise.promise;
      refetch();
    }).catch((error) => {
      delete fetchPromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to send text',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getLastSent = (lastSentDate) => {
    let result = '';
    const formattedDate = moment.utc(lastSentDate);
    const isSameDay = moment(moment.utc()).isSame(formattedDate, 'day');
    if (formattedDate.isValid()) {
      if (isSameDay) {
        result = `${moment(formattedDate).local().calendar()}`;
      } else {
        result = `${formattedDate.format(DATE_FORMAT.SHORT)} at ${moment(formattedDate).local().format(TIME_FORMAT.SHORT_TIME_UPPERCASE)}`;
      }
    }
    return result;
  };

  const renderAvailabilityRows = () => {
    if (patientsAvailability && patientsAvailability.length > 0) {
      const filteredAvailabilityList = patientsAvailability
        .filter(pa => pa.enableSendingApptReminder);
      if (filteredAvailabilityList.length === 0) return renderEmptyRow();
      return filteredAvailabilityList.map((availability, index) => {
        const handleNavigate = (id) => {
          if (isPesUser) {
            return navigate(`/${tenantUrl}/cn/summary-patient/${id}/overview`);
          }
          return navigate(`/${tenantUrl}/cn/patient/${id}/summary`);
        };
        return (
          <tr
            key={`availability_row_${index}_${availability.date}-${availability.name}`}
            className="qa-edit-note"
            data-test="patientAvailability_availabilityRow"
          >
            <td className="pa__name" data-test="patientAvailability_name">
              <Button
                key={`callSchedule_${_camelCase(availability.fullName)}`}
                variant="link-dark"
                className="p-0 call-schedule__patient-name ml-3 mb-1"
                onClick={() => handleNavigate(availability.patientId)}
                data-test="callSchedule_callSchedulePatient"
              >
                <u>{availability.fullName}</u>
              </Button>
            </td>
            <td className="pa__column" data-test="patientAvailability_nextOutReachDate">
              {availability.nextOutreachDate
                ? moment.utc(availability.nextOutreachDate).format(DATE_FORMAT.SHORT)
                : EMPTY_STRING}
            </td>
            { loading ? renderLoadingRow() : (
              <td className="pa__sent" data-test="patientAvailability_lastSentDate">
                {availability.lastSent ? getLastSent(availability.lastSent) : EMPTY_STRING}
              </td>
            )}
            { loading ? null : (
              <td className="pa__response" data-test="patientAvailability_response">
                { availability.response || EMPTY_STRING }
              </td>
            )}
            <td className="pa__actions text-right">
              <Button
                size="md"
                variant="primary"
                onClick={() => sendText(availability.patientId)}
                data-test="patientAvailability_sendTextButton"
                disabled={!!loading || (isLessThanXHours(availability.lastSent, 4))}
              >
                Send Text
              </Button>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center my-4 ml-4 justify-content-between">
        <h5 className="text-left title mb-0">
          Patient Availability Check
        </h5>
        <Button
          size="md"
          variant="primary"
          className="ml-2"
          onClick={refetch}
          disabled={!!loading}
          data-test="patientAvailability_refreshBtn"
        >
          <i className="bi bi-arrow-repeat mr-2" />
          Refresh
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2 pa__name">Name</th>
              <th className="py-2 pa__column">Next Outreach Date</th>
              <th className="py-2 pa__sent">Last Sent</th>
              <th className="py-2 pa__response">Response</th>
              <th className="py-2 pa__actions" />
            </tr>
          </thead>
          <tbody>
            {renderAvailabilityRows()}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default PatientsAvailability;
