// Libraries
import React, {
  useState, useEffect, Suspense, lazy,
} from 'react';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import { Accordion, Card, Button } from 'react-bootstrap';
// Constants
import {
  DATE_FORMAT, EMPTY_STRING,
} from '../../../constants/constants';
// Views
import { Loading } from '../../base/Loading';
// Lazy
const HealthFactorElementModal = lazy(() => import('./HealthFactorElementModal'));

export function HealthFactorElement(props) {
  const { factors, factorsTitle, categoryId } = props;

  const [selectedFactor, setSelectedFactor] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalsOpened, setModalsOpened] = useState([]);

  const handleAddEdit = (factor) => {
    setSelectedFactor({ ...factor, category: factorsTitle, categoryId });
    setIsModalOpen(true);
  };

  function renderEmptyRow() {
    return (
      <tr data-test="healthFactorElement_emptyMsg">
        <td colSpan="6" className="p-2 border-0">
          No factor have been defined for this health factor.
        </td>
      </tr>
    );
  }

  useEffect(() => {
    if (isModalOpen && !modalsOpened.includes('isModalOpen')) {
      setModalsOpened([...modalsOpened, 'isModalOpen']);
    }
  }, [isModalOpen]);

  const renderRows = () => {
    if (factors && factors.length) {
      const sortedByDate = _orderBy(factors, [el => moment(el.createdAt), 'id'], ['desc', 'desc']);
      return sortedByDate.map((factor) => {
        const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);

        return (
          <tr key={`factor__row-${factor.id}`} data-test="healthFactorElement_entryRow">
            <td colSpan="6" className="p-0">
              <Accordion defaultActiveKey="0">
                <Card className="border-0 rounded-0">
                  <Card.Header className="bg-light border-0 p-0">
                    <table className="table mb-0 table-hover">
                      <tbody>
                        <tr>
                          <td className="factor__display-name" onClick={() => handleAddEdit(factor)} data-test="healthFactorElement_editBtn">
                            {factor.displayName || EMPTY_STRING}
                          </td>
                          <td className="factor__z-code" onClick={() => handleAddEdit(factor)} data-test="healthFactorElement_editBtn">
                            {factor.zcode || EMPTY_STRING}
                          </td>
                          <td className="factor__added" onClick={() => handleAddEdit(factor)} data-test="healthFactorElement_editBtn">
                            {factor.changedby || 'System'}
                          </td>
                          <td className="factor__date" onClick={() => handleAddEdit(factor)} data-test="healthFactorElement_editBtn">
                            {(factor.updatedAt && getDateString(factor.updatedAt))
                            || (factor.createdAt && getDateString(factor.createdAt))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Header>
                </Card>
              </Accordion>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  return (
    <div className="factors card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <span className="text-uppercase">{factorsTitle}</span>
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => handleAddEdit(null)}
          data-test="healthFactorElement_addBtn"
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2 factor__display-name">Display Name</th>
              <th className="py-2 factor__z-code">Z-Code</th>
              <th className="py-2 factor__added">Added By</th>
              <th className="py-2 factor__date">Date</th>
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>
      </div>
      { modalsOpened.includes('isModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          <HealthFactorElementModal
            initialFactor={selectedFactor}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </Suspense>)
      }
    </div>
  );
}

export default HealthFactorElement;
