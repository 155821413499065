export const INTERVENTIONS_TYPES = {
  APPOINTMENT: {
    tabName: 'Appointments',
    title: 'Appointment',
  },
  AWV: {
    tabName: 'Annual Wellness Visit',
    title: 'Annual Wellness Visit',
  },
  CN_SCREENING: {
    tabName: 'CN screening',
    title: 'CN screening',
  },
  EDUCATION: {
    tabName: 'Education',
    title: 'Education',
  },
  EMR: {
    tabName: 'EMR Review',
    title: 'EMR Review',
  },
  MEDICATION: {
    tabName: 'Medication',
    title: 'Medication',
  },
  PATIENT_RECOVERY: {
    tabName: 'Patient Satisfaction/Retention',
    title: 'Patient Satisfaction/Retention',
  },
  PRACTICE_ESCALATION: {
    tabName: 'Practice Escalation',
    title: 'Practice Escalation',
  },
  REFERRAL: {
    tabName: 'Referral',
    title: 'Referral',
  },
  RPM: {
    tabName: 'RPM',
    title: 'RPM',
  },
  SOCIAL_HOME_SERVICE: {
    tabName: 'Social/Home Services',
    title: 'Social Determinants of Health (SDoH)',
  },
  VACCINATION: {
    tabName: 'Vaccination',
    title: 'Vaccination',
  },
};

export const DEPRECATED_INTERVENTIONS_TYPES = {
  SELF_MANAGEMENT: {
    tabName: 'Self-Management',
    title: 'Self-Management',
  },
  INSIGHTS: {
    tabName: 'Insights Follow-up',
    title: 'Insights Follow-up',
  },
};

export const INTERVENTION_SUBCATEGORIES = {
  APPOINTMENT: [
    'Annual Exam',
    'Appointment Follow-Up',
    'Colonoscopy',
    'Imaging',
    'Immunization Visit',
    'Infusion',
    'Lab Work',
    'Mammogram',
    'Other',
    'Pap - Female',
    'Physical Therapy',
    'Physician Visit - Behavioral Health',
    'Physician Visit - PCP',
    'Physician Visit - Specialist',
    'PSA - Male',
    'Surgical',

  ],
  AWV: [
    'Complete Pre-visit Screening',
    'Educated, but not eligible - AWV',
    'Educated and declined - AWV',
    'Educated and escalated to practice - AWV',
    'Educated and scheduled - AWV',
  ],
  CN_SCREENING: [
    'Allergy',
    'Anemia',
    'Asthma',
    'Atrial Fibrillation (A-fib)',
    'Autoimmune',
    'Bipolar',
    'BPH',
    'Cancer',
    'CHF',
    'CKD/Kidney Disease',
    'COPD',
    'COVID - Exposure',
    'COVID - Resources Needed',
    'COVID - Symptoms',
    'COVID - Vaccination Status',
    'Dementia',
    'Dental',
    'Depression',
    'DM Type I',
    'DM Type II',
    'Epilepsy/Seizure',
    'Exercise/Activity',
    'Fall/Slip',
    'Gastrointestinal status (GI)',
    'Genitourinary (GU)',
    'Grief/Loss',
    'Hearing',
    'Heart Health - Other',
    'Hepatitis',
    'HIPAA Information',
    'HIV/AIDS',
    'Home Health (HH)',
    'HTN',
    'Hydration',
    'Hyperlipidemia',
    'Hypotension',
    'Hypothyroidism',
    'Meals/Diet',
    'Mental Health - Other',
    'Migraines',
    'Multiple Sclerosis ',
    'Musculoskeletal',
    'Neurological',
    'New Symptom',
    'Osteoarthritis (OA)',
    'Osteoporosis',
    'Other',
    'Pain',
    'Parkinson\'s',
    'Pop Health',
    'PRAPARE Lite',
    'Recovery from Illness',
    'Recovery from Surgery/Procedure',
    'Respiratory - Other',
    'Rheumatoid Arthritis (RA)',
    'Safety',
    'Skin/Wound',
    'Sleep',
    'Stress/Anxiety',
    'Stroke/TIA',
    'Substance Use',
    'Tobacco Cessation',
    'Transitional Care Mgmt. (TCM)',
    'Transportation to/from Appointments',
    'Vascular',
    'Vision',
    'Weight Management',
  ],
  EDUCATION: [
    'Activity/Exercise',
    'Air Quality Advisory',
    'Alcohol Use',
    'Appointments',
    'Blood Glucose Monitoring',
    'Blood Pressure Monitoring',
    'CCM Program',
    'CN Transition',
    'Cost Share/Deductible Discussion',
    'COVID',
    'Dementia',
    'Diabetes/Prediabetes',
    'Diet',
    'Disease Process',
    'Goal Setting',
    'Grief/Loss',
    'Healthy Mindset',
    'Home Safety Review',
    'Hydration',
    'Medical Equipment',
    'Mental/Behavioral Health - Anger',
    'Mental/Behavioral Health - Bipolar',
    'Mental/Behavioral Health - Depression',
    'Mental/Behavioral Health - Stress, Anxiety',
    'Monthly Breast Self-exams',
    'Non-Compliance',
    'O2 Monitoring (Pulse Ox)',
    'Other',
    'Pain Management',
    'Patient Verbally Accepts',
    'Patient Verbally Declines',
    'Procedure/Surgery',
    'Provider Transition - Patient Verbally Consents',
    'Provider Transition - Patient Verbally Declines',
    'Review Lab Results',
    'Review New/Changing Symptom(s)',
    'Review Recent Vitals',
    'Routine Health Screenings - Annual Eye Exam',
    'Routine Health Screenings - Other',
    'Safety - Fall Prevention',
    'Safety - Neglect, Abuse, Domestic Violence',
    'Skin Care - Diabetic Foot Care',
    'Skin Care - General',
    'Sleep Hygiene',
    'Sun Safety',
    'Symptom Management',
    'Tobacco Cessation',
    'Toileting',
    'Vaccines',
    'Vitamin D Importance',
    'Weather Related Preparation',
    'Weight Management',
  ],
  EMR: [
    'Imaging Results',
    'Insurance update',
    'Medication change',
    'New clinical note',
    'New lab/vital sign value',
  ],
  INSIGHTS: [
    'BMI',
    'Cholesterol',
    'Fall Risk',
    'HbA1c',
    'Hypertension',
    'Hypotension',
    'Medication Change - Change in Dose',
    'Medication Change - Discontinued',
    'Medication Change - New Med',
    'New Potential Diagnosis',
    'Other',
    'Vaccinations',
  ],
  MEDICATION: [
    'Access/Pick-up',
    'Adverse Reaction',
    'Allergy',
    'Compliance/Adherence',
    'Confirmed adequate supply',
    'Coordination w/Pharmacy',
    'Coordination w/Provider Office',
    'Cost Concerns',
    'Diabetic Supplies',
    'DME',
    'Dose Change',
    'Education/Coaching',
    'Epidural Injection',
    'New Medication',
    'OTC',
    'Refill Request',
    'Self-Monitoring Device',
    'Side Effects',
  ],
  PATIENT_RECOVERY: [
    'De-escalation/Remedied Frustration w/Practice',
    'De-escalation/Remedied Frustration w/Provider',
    'Other',
    'Reported concern to Practice',
  ],
  PRACTICE_ESCALATION: [
    'Clinical Concern',
    'General Communication',
    'HIPAA consent received',
    'Other',
    'Prescription Refill',
    'Referral Status',

  ],
  REFERRAL: [
    'Existing Referral - Status Check',
    'New Referral - Specialist',
    'New Referral - Test/Procedure',
    'Community Health Worker (CHW) ',
  ],
  RPM: [
    'RPM Assessment',
    'RPM EMR Review',
    'RPM Referral',
  ],
  SOCIAL_HOME_SERVICE: [
    'Billing',
    'Disability',
    'Financial - Other',
    'Health Factor Review',
    'Housing',
    'Language Line Interpreter',
    'Medical Equipment',
    'Medication',
    'Nutrition/Food Insecurity',
    'Other',
    'Senior Care/Support Groups',
    'Social Work',
    'Substance Use',
    'Transportation',
    'Utilities',
  ],
  VACCINATION: [
    'Covid-19 1st Vaccine Facilitation',
    'Covid-19 2nd Vaccine Facilitation',
    'Covid-19 Booster Vaccine Facilitation',
    'Influenza (Flu) Vaccine Facilitation',
    'Pneumococcal (Pneumonia) Vaccine Facilitation',
    'Shingles Vaccine Facilitation',
    'Tdap/Tetanus',
  ],
};

export const INTERVENTION_PRIORITY_VALUES = {
  NORMAL: {
    name: 'NORMAL',
    variant: 'success',
    className: 'normal',
  },
  INFORMATIONAL: {
    name: 'INFORMATIONAL',
    variant: 'warning',
    className: 'info',
  },
  URGENT: {
    name: 'URGENT',
    variant: 'ccm-orange',
    className: 'urgent',
  },
  EMERGENT: {
    name: 'EMERGENT',
    variant: 'danger',
    className: 'emergent',
  },
};

export const INTERVENTION_ESCALATION_TYPES = [
  { value: 'PHONE', label: 'Phone Call' },
  { value: 'EMR', label: 'EMR Notification' },
  { value: 'EMAIL', label: 'Email' },
  { value: 'OTHER', label: 'Other' },
];
