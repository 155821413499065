import { makeAuthorizedRequest } from './requests';

export function getPhysicians(
  {
    pageSize,
    pageNumber,
    sortBy = null,
    sortOrder = 'ASC',
    billingStatus = null,
    activeStatus = null,
  },
) {
  const query = `?pageSize=${pageSize}&pageNumber=${pageNumber}&sortOrder=${sortOrder}${sortBy !== null ? `&sortBy=${sortBy}` : ''}${activeStatus !== null ? `&active=${activeStatus}` : ''}${billingStatus !== null ? `&billable=${billingStatus}` : ''}`;
  return makeAuthorizedRequest('GET', `/physicians${query}`);
}

export function getPhysician(physicianId) {
  return makeAuthorizedRequest('GET', `/physicians/${physicianId}`);
}

export function getPhysicianTypeahead({ filter = '', billingStatus = null }) {
  return makeAuthorizedRequest('GET', `/physicians/typeahead?filter=${filter}${billingStatus !== null ? `&billable=${billingStatus}` : ''}`);
}

export function getBillingPhysicians() {
  return makeAuthorizedRequest('GET', '/billing-physicians');
}

export function saveBillingProvider(patientId, providerId) {
  return makeAuthorizedRequest('PUT', `/patients/${patientId}/billingprovider`, { value: providerId });
}

export function getLocations() {
  return makeAuthorizedRequest('GET', '/location');
}

export function getInsurancesTypeahead(filter = '', type = 'PRIMARY') {
  return makeAuthorizedRequest('GET', `/insurances/typeahead?filter=${filter}&type=${type}`);
}

export function getCcmConditions() {
  return makeAuthorizedRequest('GET', '/chronic-conditions');
}

export function getTelehealthProviders() {
  return makeAuthorizedRequest('GET', '/assigners');
}

export function getPhysiciansShort() {
  return makeAuthorizedRequest('GET', '/physicians/names');
}
