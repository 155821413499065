// libraries
import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Dropdown, Button, ButtonGroup } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
// Components
import { withRouter } from '../../shared/WithRouter';
// actions
import { UpdatePatient } from '../../../actions/patient';
import ShowNotification from '../../../actions/notification';
// services
import {
  copyToClipboard, formatPhone, isObjectsEqual, validatePhoneRequired,
} from '../../../services/helpers';
import { saveGeneralInfo } from '../../../services/patient';
// constants
import { DIALOG_DEFAULTS, NOTIFICATION_TYPE } from '../../../constants/constants';
// views
import EditableInput from '../../base/EditableInput';

const EDITABLE_PHONE_NAME = 'Preferred phone';

export class PhoneNumberDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true,
      isModalOpened: false,
      customPhone: {},
      newPreferredPhone: {},
      patient: {},
    };

    this.customPhone = React.createRef();
    this.promises = {};
  }

  componentDidMount() {
    this.getPhoneNumbers();
  }

  componentDidUpdate(prevProps) {
    const { patient: { contactInfo, guardianContact } = {} } = this.props;

    if (!isObjectsEqual(contactInfo, prevProps.patient.contactInfo)
      || !isObjectsEqual(guardianContact, prevProps.patient.guardianContact)) {
      this.getPhoneNumbers();
    }
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  getPhoneNumbers = () => {
    const { patient, patient: { contactInfo, guardianContact } } = this.props;
    let activePhone = '';

    let phones = [
      {
        name: 'Home phone',
        value: contactInfo.homePhone,
        profileName: 'homePhoneInvalid',
      }, {
        name: 'Mobile phone',
        value: contactInfo.mobilePhone,
        profileName: 'cellPhoneInvalid',
      }, {
        name: 'Work phone',
        value: contactInfo.workPhone,
        profileName: 'workPhoneInvalid',
      }, {
        name: 'Guardian phone',
        value: guardianContact.phoneNumber,
      },
    ];

    phones = phones.filter(phone => !!phone.value);

    phones.unshift({
      name: EDITABLE_PHONE_NAME,
      value: contactInfo.preferredPhone,
    });

    phones.forEach((phone) => {
      if (phone.value && phone.value === contactInfo.preferredPhone) {
        activePhone = phone.name;
      }
    });

    this.setState({
      phones,
      patient,
      newPreferredPhone: {
        name: activePhone,
        value: contactInfo.preferredPhone,
      },
      customPhone: {
        name: EDITABLE_PHONE_NAME,
        value: activePhone && (activePhone !== EDITABLE_PHONE_NAME) ? '' : phones[0].value,
      },
    });
  };

  openDialog = () => {
    this.setState({
      isModalOpened: true,
    });
  };

  closeDialog = () => {
    this.setState({
      isModalOpened: false,
    }, this.getPhoneNumbers);
  };

  setCustomPhone = (field, value, valid) => {
    if (!valid) {
      this.setState({
        isValid: false,
      });
      return;
    }

    const customPhone = {
      name: this.state.customPhone.name,
      value,
    };

    this.setState({
      customPhone,
      newPreferredPhone: { ...customPhone },
    });
  };

  setPreferredPhone = (phone, isFocused) => {
    const customPhone = this.customPhone.current;

    if (customPhone && customPhone.validate && customPhone.clearValidation) {
      if (isFocused) {
        customPhone.validate();
      } else {
        customPhone.clearValidation();
      }
    }

    this.setState({
      isValid: true,
      newPreferredPhone: {
        ...phone,
      },
    });
  };

  setContactInfo = () => {
    const {
      newPreferredPhone: { value: phoneNumber }, isValid,
    } = this.state;

    if (!isValid || !phoneNumber) {
      return;
    }

    this.setState(state => ({
      patient: {
        ...state.patient,
        contactInfo: {
          ...state.patient.contactInfo,
          preferredPhone: state.newPreferredPhone.value,
        },
      },
    }), this.updateContactInfo);
  };

  updateContactInfo = () => {
    const { updatePatient, params: { id: patientId }, refreshCallback } = this.props;
    const { patient } = this.state;

    const contactPatientId = patientId || patient.id;

    const promiseName = 'saveGeneralInfo';
    const saveGeneralInfoRequest = saveGeneralInfo(patient, contactPatientId);
    const saveGeneralInfoPromise = saveGeneralInfoRequest.promise;
    this.promises[promiseName] = saveGeneralInfoRequest;

    return saveGeneralInfoPromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        isModalOpened: false,
      });
      if (refreshCallback) {
        refreshCallback();
      }
      updatePatient({
        contactInfo: patient.contactInfo,
        guardianContact: patient.guardianContact,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      const { showNotification } = this.props;

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not update phone number.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  renderRadioBtn = (phone, index) => {
    const { customPhone, newPreferredPhone } = this.state;
    let isActiveValue = newPreferredPhone.name === customPhone.name;

    if (phone.name === EDITABLE_PHONE_NAME) {
      return (
        <label
          key={index}
          className={`radio-container custom-radio-container ${isActiveValue
            ? ''
            : 'radio-container-inactive'}`}
        >
          <input
            type="radio"
            name="phone-number"
            value={customPhone}
            onClick={() => this.setPreferredPhone(customPhone, true)}
            checked={isActiveValue}
          />
          <div className="radio-icon" />
          Custom:
          <span onClick={() => this.setPreferredPhone(customPhone, true)}>
            <EditableInput
              ref={this.customPhone}
              type="text"
              submitCallback={this.setCustomPhone}
              fieldKey="preferredPhone"
              formatType="phone"
              validationCallback={validatePhoneRequired}
              initialValue={customPhone.value || ''}
            />
          </span>
        </label>
      );
    }

    isActiveValue = newPreferredPhone.value && (newPreferredPhone.name === phone.name);

    return (
      <label
        key={index}
        className={`radio-container ${isActiveValue
          ? ''
          : 'radio-container-inactive'}`}
      >
        <input
          type="radio"
          name="phone-number"
          value={phone}
          onClick={() => this.setPreferredPhone(phone)}
          defaultChecked={isActiveValue}
        />
        <div className="radio-icon" />
        {`${phone.name}: ${formatPhone(phone.value)}`}
      </label>
    );
  };

  renderPhoneNumberModal = () => {
    const { isModalOpened, phones } = this.state;
    const { patient: { profile = {} } = {} } = this.props;
    // eslint-disable-next-line no-prototype-builtins
    const validPhones = phones && phones.filter(phone => (phone.hasOwnProperty('profileName') ? !profile[phone.profileName] : true));
    return (
      <Modal
        isOpen={isModalOpened}
        style={DIALOG_DEFAULTS}
        onRequestClose={this.closeDialog}
      >
        <div className="simple-dialog small-dialog">
          <div className="dialog-title">
            Edit preferred phone
            <button
              className="close-icon i-close"
              onClick={this.closeDialog}
              type="button"
            />
          </div>
          <div className="dialog-content dialog-edit-phone">
            {
              validPhones && validPhones.map((phone, index) => this.renderRadioBtn(phone, index))
            }
          </div>
          <div className="dialog-buttons justify-content-end px-4">
            <Button
              variant="light"
              onClick={this.closeDialog}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ml-2"
              onClick={this.setContactInfo}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  renderDropdownHeader = () => {
    const { phones } = this.state;

    if (!phones || !phones.length) {
      return 'Edit Phone';
    }

    return (
      <div className="d-flex-center">
        <span className="bi-telephone-fill mr-2" />
        {phones[0].value
          ? (
            <span className="phone-number">
              <a href={`tel:${phones[0].value}`} className="text-white">
                {formatPhone(phones[0].value)}
              </a>
            </span>
          ) : (
            <span className="phone-number" onClick={this.openDialog}>
              Edit Phone
            </span>
          )}
      </div>
    );
  }

  renderDropDownList = () => {
    const { phones } = this.state;
    const { patient: { profile = {} } = {} } = this.props;

    if (!phones || !phones.length) {
      return null;
    }

    const copyPrimaryPhoneNumber = (e, phone) => {
      e.preventDefault();
      e.stopPropagation();
      copyToClipboard(phone);
    };

    const phoneList = [(
      <Fragment key="phone__fragment-item">
        <Dropdown.Item key="phone__preferred-item" eventKey="1" onClick={this.openDialog}>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <span>{phones[0].name}</span>
              <span>
                <a href={`tel:${phones[0].value || '-'}`}>
                  {formatPhone(phones[0].value) || '-'}
                </a>
              </span>
            </div>
            <div className="ml-2 d-flex align-items-center">
              <Button
                size="xs"
                className="ml-1 d-flex align-items-center justify-content-center"
                style={{ width: '15%', height: '25px' }}
                variant="outline-primary"
                onClick={(e) => {
                  copyPrimaryPhoneNumber(e, formatPhone(phones[0].value));
                }}
                data-test="patientInfo_copyPhoneNumber"
                data-for="tooltip-phoneNumber"
                data-tip="Copy"
              >
                <i className="bi-copy" />
              </Button>
              <ReactTooltip id="tooltip-phoneNumber" type="info" effect="float" place="right" />
            </div>
            <span className="bi-pencil-square ml-3" />
          </div>
        </Dropdown.Item>
        <Dropdown.Divider key="phone__divider-item" />
      </Fragment>
    )];

    phoneList.push(phones.filter(phone => phone.name !== EDITABLE_PHONE_NAME)
      .map((phone, index) => (
        <Dropdown.ItemText key={index}>
          <div className="d-flex">
            <div className="d-flex flex-column">
              <span>{phone.name}</span>
              <span>
                <a href={`tel:${phone.value || '-'}`}>
                  {formatPhone(phone.value) || '-'}
                </a>
                {phone.profileName && profile[phone.profileName] && <i className="bi bi-exclamation-lg text-ccm-red" />}
              </span>
            </div>
            <div className="ml-3 d-flex align-items-center">
              <Button
                size="xs"
                className="d-flex align-items-center justify-content-center"
                style={{ width: '15%', height: '25px' }}
                variant="outline-primary"
                onClick={() => {
                  copyToClipboard(formatPhone(phone.value));
                }}
                data-test="patientInfo_copyPhoneNumber"
                data-for="tooltip-phoneNumber"
                data-tip="Copy"
              >
                <i className="bi-copy" />
              </Button>
              <ReactTooltip id="tooltip-phoneNumber" type="info" effect="float" place="right" />
            </div>
          </div>
        </Dropdown.ItemText>
      )));

    return phoneList;
  };

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <Dropdown as={ButtonGroup}>
          <Button
            size="sm"
            variant="primary"
            className="py-0"
          >
            {this.renderDropdownHeader()}
          </Button>
          <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" className="py-0" />
          <Dropdown.Menu>
            {this.renderDropDownList()}
          </Dropdown.Menu>
        </Dropdown>
        {this.renderPhoneNumberModal()}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(null, mapDispatchToProps)(PhoneNumberDropdown));
