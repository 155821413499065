import React from 'react';

export function LoadingBlock() {
  return (
    <div
      role="status"
      className="spinner-border text-primary d-block"
    >
      <span className="sr-only">Loading ...</span>
    </div>
  );
}

export default LoadingBlock;
