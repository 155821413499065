// Libraries
import React from 'react';
import { connect } from 'react-redux';
// Constants

export function Allergies(props) {
  const { patient: { drugAllergies, otherAllergies } = {} } = props;

  let renderAllergiesItems = (
    <div className="col-6 p-0 text-left ccm-gray">
        No allergies to display.
    </div>
  );

  renderAllergiesItems = drugAllergies
    && drugAllergies.length ? drugAllergies.map((allergy, index) => (
      <div className="col-6 d-flex flex-column mb-3" key={`allergy_${allergy.title}_${index}`}>
        <span>{allergy.title}</span>
        <small>{allergy.reaction || 'Not know reaction'}</small>
      </div>
    )) : renderAllergiesItems;

  return (
    <div className="row no-gutters text-left">
      {renderAllergiesItems}
      {otherAllergies && (
        <div className="col-6 d-flex flex-column mb-3">
          <span>Other Allergies</span>
          <small data-test="allergies_items">{otherAllergies}</small>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(Allergies);
