// libraries
import React, { Fragment } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getTagData } from '../../services/helpers';

const MAX_SHOWN_ATTRIBUTES = 7;
const MIN_ATTRIBUTES_IN_COLUMN = 15;
/**
 * Patient Attribute component, requires props:
 * patient: tagsIds -- array, attributes
 *          firstName -- string, patient first name
 *          lastName -- string, patient last name
 * limit -- number, optional, count of attributes that will be shown
 * maxColumnsCount
 */

export function PatientAttributesRow(props) {
  const { patient: { tagIds: attributes, firstName, lastName } = {}, tags } = props;
  const maxShownAttributes = props.limit || MAX_SHOWN_ATTRIBUTES;
  const minAttributesInColumn = props.minLimit || MIN_ATTRIBUTES_IN_COLUMN;
  let shownAttributesNumber;
  let attributesString = 'N/A';
  let moreAttributesBlock;

  function renderAttributesTable() {
    const maxColumnsCount = props.maxColumnsCount || 2;
    const maxAttributesInColumn = Math.max(minAttributesInColumn - 1,
      Math.ceil(attributes.length / maxColumnsCount));
    let maxIndexInColumn = maxAttributesInColumn;
    const attributeColumns = [[]];
    let currentColumnIndex = 0;
    let currentColumn = attributeColumns[currentColumnIndex];

    attributes.forEach((attribute, index) => {
      const attributeBlock = <div key={`ccm-attribute-${index}`} className="mb-3">{getTagData(attribute, tags).displayName}</div>;
      if (index > maxIndexInColumn) {
        attributeColumns.push([]);
        currentColumnIndex = attributeColumns.length - 1;
        currentColumn = attributeColumns[currentColumnIndex];
        maxIndexInColumn = maxAttributesInColumn * (currentColumnIndex + 1);
      }

      currentColumn.push(attributeBlock);
    });

    const attributesTable = attributeColumns.map((attributeColumn, index) => (
      <div
        key={`ccm-attribute-column-${index}`}
      >
        {attributeColumn}
      </div>
    ));

    return <div className="text-ccm-bluewood">{attributesTable}</div>;
  }

  if (attributes && attributes.length) {
    attributesString = '';
    shownAttributesNumber = (maxShownAttributes > attributes.length)
      ? attributes.length
      : maxShownAttributes;

    for (let i = 0; i < shownAttributesNumber; i++) {
      attributesString += `${getTagData(attributes[i], tags).displayName}, `;
    }

    if (attributes.length > maxShownAttributes) {
      const morePopover = (
        <Popover>
          <Popover.Title>
            <strong>{`${firstName} ${lastName}`}</strong>
            <small className="d-block">Patient Attributes</small>
          </Popover.Title>
          <Popover.Content>
            {renderAttributesTable()}
          </Popover.Content>
        </Popover>
      );

      moreAttributesBlock = (
        <OverlayTrigger
          trigger={['hover', 'click']}
          placement="auto"
          overlay={morePopover}
          rootClose
          className="link-more"
        >
          <b>
            {attributes.length - maxShownAttributes}
            {' more'}
          </b>
        </OverlayTrigger>
      );
    } else {
      attributesString = attributesString.substring(0, attributesString.length - 2);
    }
  }

  return (
    <Fragment>
      {attributesString}
      {moreAttributesBlock}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    tags: state.tenant.tags,
  };
}

export default connect(mapStateToProps)(PatientAttributesRow);
