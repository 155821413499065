// Libraries
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';
import _camelCase from 'lodash/camelCase';
import { useParams } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getAlerts, dismissAlert, dismissAllAlerts } from '../../services/alerts';
// Components
import Pager from '../patients/Pager';
import { PatientInfo } from '../menu/PatientInfo';
import Filter from './filter/Filter';
// Hooks
import usePrevious from '../../hooks/helpers/usePrevious';
// Constants
import { DEFAULT_PAGE_SIZE } from '../../constants/pageSizes';
import {
  NOTIFICATION_TYPE, DATE_FORMAT, FLIGHT_PLAN_ALERT_FILTER, USER_ROLES,
} from '../../constants/constants';

export const Alerts = (props) => {
  const { showNotification, userRole } = props;

  const { tenant: tenantUrl } = useParams();

  const isPesUser = userRole === USER_ROLES.PES;
  const DEFUALT_FILTERS = {
    filter: 'status',
    status: 'CC',
    inputId: '',
    alertType: '',
  };

  const [patients, setPatients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [collapseAll, setCollapseAll] = useState(false);
  const [alertFilters, setAlertFilters] = useState(DEFUALT_FILTERS);
  const prevAlertFilters = usePrevious(alertFilters);

  const getPatientsAlerts = () => {
    const patientid = alertFilters.filter === 'engoodenId' ? alertFilters.inputId : '';
    const ehrid = alertFilters.filter === 'ehrId' ? alertFilters.inputId : '';

    const reqAlerts = {
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: currentPage,
      status: alertFilters.status,
      type: alertFilters.alertType,
      patientid,
      ehrid,
    };

    const getAlertsRequest = getAlerts(reqAlerts);
    const getAlertsPromise = getAlertsRequest.promise;

    getAlertsPromise.then((data) => {
      delete getAlertsRequest.promise;

      setPatients(
        data.patientAlertsInfo.map(patient => ({
          ...patient,
          patientInfo: {
            ...patient.patientInfo,
          },
        })),
      );
      setTotalPages(data.totalPages);
      setTotalCount(data.totalCount);
    }).catch((error) => {
      delete getAlertsRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load Flight Plan Alerts, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const removeAllAlerts = () => {
    const dismissAllAlertsRequest = dismissAllAlerts();
    const dismissAllAlertsPromise = dismissAllAlertsRequest.promise;

    dismissAllAlertsPromise.then(() => {
      delete dismissAllAlertsPromise.promise;

      setTotalCount(0);
      setTotalPages(0);
      setPatients([]);
    }).catch((error) => {
      delete dismissAllAlertsPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not remove All Flight Plan Alerts, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const removeAlert = (id) => {
    const dismissAlertRequest = dismissAlert(id);
    const dismissAlertPromise = dismissAlertRequest.promise;

    dismissAlertPromise.then(() => {
      delete dismissAlertRequest.promise;
    }).catch((error) => {
      delete dismissAlertRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not remove Flight Plan Alert, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const removeAlertsPatient = (patient) => {
    const { alerts } = patient;
    const updatedPatients = patients.filter(el => el.patientInfo.id !== patient.patientInfo.id);

    alerts.forEach(alert => removeAlert(alert.patientAlertid));
    setTotalCount(totalCount - 1);
    setPatients(updatedPatients);
  };

  const renderPatientAlerts = (alerts) => {
    if (!alerts.length) {
      return <small className="d-block text-ccm-gray mb-2" data-test="alerts_emptyAlert">Empty alerts</small>;
    }

    return alerts.map((alert, i) => (
      <div key={`patientAlert__${i}${_camelCase(alert.createdAt)}`} className="mb-3" data-test="alerts_alertItem">
        <p className="alert__alertDate mb-2" data-test="alerts_alertDate">{moment(alert.createdAt).format(DATE_FORMAT.FULL)}</p>
        <ul className="mb-0">
          <li key={`alertInfo__${i}${_camelCase(alert.description)}`} data-test="alerts_InfoItem">
            {alert.description}
            <br />
            {alert.linkUrl && (
              <a href={alert.linkUrl} rel="noopener noreferrer" target="_blank">
                {alert.linkDisplayText ? alert.linkDisplayText : 'Link to Pre-Visit Screening Form'}
              </a>
            )}
          </li>
        </ul>
      </div>
    ));
  };

  const renderDropDownItems = () => FLIGHT_PLAN_ALERT_FILTER.map((element, index) => (
    <Dropdown.Item
      key={`flight_plan_filter_${index}`}
      onClick={() => setAlertFilters({ ...alertFilters, alertType: element.value })}
      as="button"
      className="d-flex my-1 border-0 text-ccm-blue px-3 item"
      data-test="alerts_filterItem"
    >
      <div className="pr-3">{element.label}</div>
    </Dropdown.Item>
  ));

  const getUrl = (patientId) => {
    if (isPesUser) {
      return `/${tenantUrl}/cn/summary-patient/${patientId}/overview`;
    }
    return `/${tenantUrl}/cn/patient/${patientId}/summary`;
  };

  const renderNoResults = () => {
    const noResultMsg = alertFilters.filter === 'status' ? 'No alerts found' : 'No patient found';
    return (
      <div className="align-content w-100 mt-5">
        <span>{noResultMsg}</span>
      </div>
    );
  };

  const showingResults = () => {
    const tempCount = (currentPage + 1) === totalPages
      ? totalCount : DEFAULT_PAGE_SIZE * (currentPage + 1);
    return `${(currentPage * DEFAULT_PAGE_SIZE) + 1} - ${tempCount} of ${totalCount}`;
  };

  useEffect(() => {
    if (!_isEqual(alertFilters, prevAlertFilters)) {
      setCurrentPage(0);
    }
    getPatientsAlerts();
  }, [currentPage, alertFilters.status, alertFilters.inputId, alertFilters.alertType]);

  return (
    <div className="flight-plan-alerts h-100 overflow-auto pr-3">
      <div className="d-flex my-3"><h4 className="text-left">Alerts</h4></div>
      <div className="d-flex filter align-items-center mt-0 mb-3">
        <Filter initialFilters={alertFilters} setFilters={setAlertFilters} />
        <h6 className="text-left text-uppercase mb-0 mr-2">Type:</h6>
        <Dropdown data-test="alerts_filter" className="select-filter mr-1">
          <Dropdown.Toggle
            variant="light"
            className="toggle border-0 text-ccm-blue p-1"
            data-test="alerts_filterDropDown"
          >
            {FLIGHT_PLAN_ALERT_FILTER.find(el => el.value === alertFilters.alertType || '').label}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {renderDropDownItems()}
          </Dropdown.Menu>
        </Dropdown>
        <div className="ml-auto d-flex-center">
          <Button
            size="sm"
            variant="light"
            className="d-flex-center mr-2"
            onClick={() => removeAllAlerts()}
            data-test="alerts_removeAllAlertsButton"
          >
            <span>Dismiss All Alerts</span>
          </Button>
          <span data-test="alerts_resultsInfo">{`Results: ${showingResults()}`}</span>
          <Button
            size="sm"
            variant="link-dark"
            className="d-flex-center ml-2"
            onClick={() => setCollapseAll(!collapseAll)}
            data-test="alerts_collapseAllButton"
          >
            <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
            <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
          </Button>
        </div>
      </div>
      <div className="row no-gutters alert-content">
        {patients.map(patient => (
          <AlertsRow
            key={`flight-plan-patient__${patient.patientInfo.id}`}
            patient={patient}
            renderPatientAlerts={renderPatientAlerts}
            collapseAll={collapseAll}
            removeAlertsPatient={removeAlertsPatient}
            toUrl={getUrl(patient.patientInfo.id)}
            refreshCallback={getPatientsAlerts}
            data-test="alerts_content"
          />
        ))}
        {!patients.length && renderNoResults()}
      </div>
      <div className="row recent-work-pager no-gutters d-flex-center my-3">
        <Pager
          totalPages={totalPages}
          maxShownCount={5}
          isNextPrevShown
          currentPage={currentPage}
          callback={page => setCurrentPage(page)}
          data-test="alerts_pager"
        />
      </div>
    </div>
  );
};

export const AlertsRow = (props) => {
  const {
    patient: { patientInfo, alerts }, toUrl,
    renderPatientAlerts, collapseAll, refreshCallback, removeAlertsPatient, patient,
  } = props;

  const [opened, setOpened] = useState(true);

  const mostRecentAlert = () => {
    const dates = alerts.map(alert => moment(alert.createdAt));
    return moment.max(dates).format(DATE_FORMAT.FULL);
  };

  useEffect(() => {
    setOpened(!collapseAll);
  }, [collapseAll]);

  return (
    <div className={`row no-gutters w-100 mb-2 recentWorkRow-${opened ? 'opened' : 'closed'}`}>
      <div className={`col-4 patient-info border border-right-0 patientInfo-${opened ? 'opened' : 'closed'}`}>
        <PatientInfo
          patient={patientInfo}
          fullInfo={opened}
          toUrl={toUrl}
          refreshCallback={refreshCallback}
        />
      </div>
      <div className="col alert-list-info border border-left-0 text-left p-3">
        {!opened && (
        <p className="mb-0">
          Most recent alert on:&nbsp;
          <span className="recentWork_activityDate">{mostRecentAlert()}</span>
        </p>
        )}
        {opened && renderPatientAlerts(alerts)}
        <Button
          size="md"
          variant="link-dark"
          className="d-flex-center position-absolute p-1"
          style={{ top: '1rem', right: '3rem' }}
          onClick={() => removeAlertsPatient(patient)}
          data-test="alerts_removeRowButton"
        >
          <i className="d-flex-center bi bi-x-lg" />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ top: '1rem', right: '1rem' }}
          onClick={() => setOpened(!opened)}
          data-test="alerts_collapseRowButton"
        >
          <i className={`d-flex-center bi-caret-${opened ? 'down' : 'up'}-fill ml-1`} />
        </Button>
      </div>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    userRole: state.user.role,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
