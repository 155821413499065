import { createAction, createReducer } from '@reduxjs/toolkit';

const updateLetterBatch = createAction('UPDATE_LETTER_BATCH');

const initialState = {};

const LetterBatch = createReducer(initialState, (builder) => {
  builder
    .addCase(updateLetterBatch, (state, action) => ({ ...state, ...action.newData }))
    .addDefaultCase(state => state);
});

export default LetterBatch;
