// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getAdminHealthFactors } from '../../services/administration';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
import { SetAdminHF } from '../../actions/administration';


const useLoadHealthFactors = (saveInRedux) => {
  const [healthFactors, setHealthFactors] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchHealthFactors = useCallback(async () => {
    const getAdminHealthFactorsRequest = getAdminHealthFactors();
    const getAdminHealthFactorsPromise = getAdminHealthFactorsRequest.promise;
    try {
      const data = await getAdminHealthFactorsPromise;
      setHealthFactors(data);
      if (saveInRedux) {
        dispatch(SetAdminHF(data));
      }
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to load health factors.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, []);

  useEffect(() => { fetchHealthFactors(); }, [fetchHealthFactors]);

  return { healthFactors, refetch: fetchHealthFactors };
};

export default useLoadHealthFactors;
