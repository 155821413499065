// Libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';
// Actions
import ShowNotification from '../../actions/notification';
import { Paginate, Sort } from '../../actions/search';
// Services
import { isObjectsEqual } from '../../services/helpers';
import { searchPatients, selectPatients } from '../../services/patientList';
import { getNotifications } from '../../services/notifications';
// Views
import SortingPanel from './SortingPanel';
import PatientSearch from './PatientSearch';
import PatientsScheduler from './PatientsScheduler';
import AnimatedModal from '../shared/AnimatedModal';
import PatientListMetrics from './PatientListMetrics';
import PatientListActions from './PatientListActions';
import HeaderLegend from './headerLegend/HeaderLegend';
import CnPatientListMetrics from './CnPatientListMetrics';
import PatientTableHeader from './patientTable/PatientTableHeader';
import PatientTableContent from './patientTable/PatientTableContent';
// Constants
import { STATUSES } from '../../constants/statuses';
import { PATIENTS_LIST_PAGE_SIZE } from '../../constants/pageSizes';
import { USER_ROLES, NOTIFICATION_TYPE } from '../../constants/constants';

// exported for testing purposes
export function prepareSearchQuery(searchParams, sortParams, pageNumber) {
  const result = [];
  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key]) {
      result.push({
        name: key,
        value: searchParams[key],
      });
    }
  });

  if (sortParams.key) {
    result.push({
      name: 'sortBy',
      value: sortParams.key,
    });
    result.push({
      name: 'sortOrder',
      value: sortParams.reverse ? 'DESC' : 'ASC',
    });
  }

  result.push({
    name: 'pageNumber',
    value: pageNumber,
  });

  return result;
}

export class PatientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      patients: [],
      totalPages: 0,
      totalCount: 0,
      needsCnCount: 0,
      loading: false,
      notificationList: [],
      isAnimatedModalOpen: false,
      selectedPatients: new Set(),
      selectedPatientsIds: new Set(),
      canNotBePendPatientsIds: new Set(),
      searchParams: props.searchParams || {},
      sort: {
        key: '',
        reverse: false,
      },
      currentPage: props.pageNumber || 0,
      pageSize: props.pageSize || PATIENTS_LIST_PAGE_SIZE,
      isSearchPanelOpened: false,
      isSortingPanelOpened: false,
    };

    this.promises = {};

    this.unSelectAll = this.unSelectAll.bind(this);
    this.canPatientStatusBeChanged = this.canPatientStatusBeChanged.bind(this);
    this.selectPatient = this.selectPatient.bind(this);
    this.updateSelectedPatients = this.updateSelectedPatients.bind(this);
    this.goToPage = this.goToPage.bind(this);
    this.updatePatientsList = this.updatePatientsList.bind(this);
    this.loadPatients = this.loadPatients.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }

  componentDidMount() {
    const { isExternal } = this.props;

    this.updatePatientsList();
    this.props.getUsersByRole();
    if (!isExternal) this.checkNotifications();
  }

  componentDidUpdate(prevProps) {
    ReactTooltip.rebuild();

    if (!isObjectsEqual(this.props, prevProps)) {
      this.updatePatientsList();
    }
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  checkNotifications = () => {
    const { showNotification, anniversary } = this.props;

    const getNotificationsRequest = getNotifications();
    const getNotificationsPromise = getNotificationsRequest.promise;

    getNotificationsPromise.then((data) => {
      const list = data || [];
      delete getNotificationsRequest.promise;

      if (anniversary) list.push({ type: 'ANNIVERSARY' });
      if (list && list.length > 0) {
        this.setState(prev => ({
          ...prev,
          notificationList: data,
          isAnimatedModalOpen: true,
        }));
      }
    }).catch((error) => {
      delete getNotificationsRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to get notifications.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  handleOpenSearchPanel = () => {
    this.setState(prev => ({ isSearchPanelOpened: !prev.isSearchPanelOpened }));
  }

  handleOpenSortingPanel = () => {
    this.setState(prev => ({ isSortingPanelOpened: !prev.isSortingPanelOpened }));
  }

  checkSeveralPatients = (count) => {
    if (this.state.loading) {
      return null;
    }

    this.setState({
      loading: true,
    });

    const { showNotification } = this.props;

    const promiseName = 'selectPatients';
    const selectPatientsRequest = selectPatients(this.state.pageSize,
      prepareSearchQuery(this.state.searchParams, this.state.sort, this.state.currentPage), count);
    const selectPatientsPromise = selectPatientsRequest.promise;
    this.promises[promiseName] = selectPatientsRequest;

    return selectPatientsPromise.then((data) => {
      delete this.promises[promiseName];

      let selectedPatientsIds;
      let canNotBePendPatientsIds;
      let selectedPatients;

      if (data) {
        selectedPatientsIds = new Set([...data.selectedIds]);
        canNotBePendPatientsIds = new Set(
          [...data.canNotBePendIds],
        );
        selectedPatients = new Set([...data.selectedPatients]);
      }

      this.setState({
        loading: false,
      });

      this.updateSelectedPatients(selectedPatientsIds, canNotBePendPatientsIds, selectedPatients);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });
      showNotification({
        message: 'Could not select patients.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  checkAllPatientsOnPage = () => {
    const { patients } = this.state;

    const selectedPatientsIds = new Set();
    const canNotBePendPatientsIds = new Set();
    const selectedPatients = new Set();
    patients.forEach((patient) => {
      selectedPatientsIds.add(patient.id);
      selectedPatients.add(patient);
      if (!patient.permissions
        || !this.canPatientStatusBeChanged(patient.permissions.canBeChangedTo)) {
        canNotBePendPatientsIds.add(patient.id);
      }
    });

    this.updateSelectedPatients(selectedPatientsIds, canNotBePendPatientsIds, selectedPatients);
  }

  canPatientStatusBeChanged(canBeChangedTo) {
    if (!canBeChangedTo || !canBeChangedTo.length) {
      return false;
    }

    const allowedStatuses = [];

    Object.keys(STATUSES).forEach((key) => {
      const status = STATUSES[key];

      if (status.subStatuses) {
        Object.keys(status.subStatuses).forEach((subKey) => {
          const subStatus = status.subStatuses[subKey];
          if (subStatus.canBeChangedToThisStatus) {
            allowedStatuses.push(subKey);
          }
        });
      } else if (status.canBeChangedToThisStatus) {
        allowedStatuses.push(key);
      }
    });

    return allowedStatuses.some(allowedStatus => canBeChangedTo.includes(allowedStatus));
  }

  unSelectAll() {
    this.updateSelectedPatients(new Set(), new Set(), new Set());
  }

  selectPatient(id, canBePend, patientData) {
    const { selectedPatientsIds, canNotBePendPatientsIds, selectedPatients } = this.state;

    if (selectedPatientsIds.has(id)) {
      selectedPatientsIds.delete(id);
      const patientDataToDelete = [...selectedPatients].find(patient => patient.id === id);
      selectedPatients.delete(patientDataToDelete);

      if (canNotBePendPatientsIds.has(id)) {
        canNotBePendPatientsIds.delete(id);
      }
    } else {
      selectedPatientsIds.add(id);
      selectedPatients.add(patientData);

      if (!canBePend) {
        canNotBePendPatientsIds.add(id);
      }
    }

    this.updateSelectedPatients(selectedPatientsIds, canNotBePendPatientsIds);
  }

  updateSelectedPatients(selectedPatientsIds, canNotBePendPatientsIds, selectedPatients) {
    const newCanNotBePendPatientsIds = canNotBePendPatientsIds
      || this.state.canNotBePendPatientsIds;
    let newSelectedPatientsIds = selectedPatientsIds || this.state.selectedPatientsIds;

    // To re-render child component FixedListHeader
    newSelectedPatientsIds = new Set(newSelectedPatientsIds);

    let newSelectedPatients = selectedPatients || this.state.selectedPatients;
    // To re-render child component FixedListHeader
    newSelectedPatients = new Set(newSelectedPatients);

    this.setState(state => ({
      selectedPatients: newSelectedPatients,
      selectedPatientsIds: newSelectedPatientsIds,
      canNotBePendPatientsIds: newCanNotBePendPatientsIds,
      patients: state.patients.map(p => ({
        ...p,
        selected: newSelectedPatientsIds.has(p.id),
      })),
    }));
  }

  updatePatientsList() {
    if (!this.props.isSessionLoaded) {
      return;
    }

    const emptySort = {
      key: '',
      reverse: false,
    };

    this.setState({
      currentPage: this.props.pageNumber || 0,
      pageSize: this.props.pageSize || PATIENTS_LIST_PAGE_SIZE,
      searchParams: this.props.searchParams || {},
      sort: this.props.sortParams || emptySort,
    }, this.loadPatients);
  }

  loadPatients() {
    if (this.state.loading) return null;

    this.setState({ loading: true });

    const { showNotification } = this.props;

    const promiseName = 'searchPatients';
    const searchPatientsRequest = searchPatients(this.state.pageSize,
      prepareSearchQuery(this.state.searchParams, this.state.sort, this.state.currentPage));
    const searchPatientsPromise = searchPatientsRequest.promise;
    this.promises[promiseName] = searchPatientsRequest;

    return searchPatientsPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
        totalPages: data.totalPages,
        totalCount: data.totalCount,
        needsCnCount: data.needsCnCount,
        patients: data.patients,
      }, this.updateSelectedPatients);
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403 || error.isCanceled) return;

      this.setState({ loading: false });
      showNotification({
        message: 'An unexpected error has occurred while trying to load the patient list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  goToPage(pageNumber) {
    const { paginate } = this.props;
    const { loading } = this.state;

    if (loading) {
      return;
    }

    paginate(pageNumber, this.state.pageSize);

    this.setState({
      currentPage: pageNumber,
    });
  }

  sortBy(params) {
    const { sort } = this.props;
    const newSortState = {
      key: params.key,
      reverse: params.reverse,
    };

    sort(newSortState);

    this.setState({
      sort: newSortState,
    });
  }

  render() {
    const {
      patients, totalCount, needsCnCount, isSearchPanelOpened, isSortingPanelOpened,
    } = this.state;
    const { userRole } = this.props;

    const isCnUser = (userRole === USER_ROLES.CN);
    const isPesUser = (userRole === USER_ROLES.PES);
    const isAdminUser = (userRole === USER_ROLES.ADMIN);

    let emptyBlock;
    if (!patients || !patients.length) {
      emptyBlock = <div className="empty-value" data-test="patientList_emptyValue">No patients found.</div>;
    }

    const getSortingColWidth = () => {
      if (isAdminUser || isCnUser) return '5';
      return isSearchPanelOpened ? '6' : '7';
    };

    return (
      <Fragment>
        <HeaderLegend
          needsCnCount={needsCnCount}
        />
        <div
          className="ccm-patients-list d-flex mt-2"
        >
          <div className={`h-100 ccm-patient-panel-search box-wrapper ${isSearchPanelOpened ? 'is-opened' : ''}`}>
            <PatientSearch
              isOpened={isSearchPanelOpened}
              handlePanelOpened={this.handleOpenSearchPanel}
            />
          </div>
          <div className="h-100 ccm-patient-list-content flex-grow-1 ml-2">
            <div className="d-flex flex-column h-100">
              <div className="row no-gutters">
                <div className={`col-${getSortingColWidth()} ccm-sorting-container box-wrapper d-flex p-2 ${isSortingPanelOpened ? 'is-opened' : 'is-closed'} ${isCnUser ? 'is-cn-role' : ''}`}>
                  <Button
                    variant="link"
                    className="mr-2 p-0 border-0"
                    onClick={this.handleOpenSortingPanel}
                  >
                    <i className={`bi-chevron-${isSortingPanelOpened ? 'up' : 'down'}`} />
                  </Button>
                  <SortingPanel
                    totalCount={totalCount}
                    isSearchPanelOpened={isSearchPanelOpened}
                    isSortingPanelOpened={isSortingPanelOpened}
                    totalPages={this.state.totalPages}
                    goToPage={this.goToPage}
                    currentPage={this.state.currentPage}
                  />
                </div>
                <div className={`col ccm-actions-container box-wrapper ml-2 p-2 ${isSortingPanelOpened ? 'is-opened' : 'is-closed'} ${isCnUser ? 'is-cn-role' : ''}`}>
                  {isAdminUser && (
                    <PatientListActions
                      loadPatients={this.loadPatients}
                      unSelectCallback={this.unSelectAll}
                      selectedPatientsIds={this.state.selectedPatientsIds}
                      isOpened={isSortingPanelOpened}
                    />
                  )}
                  {isPesUser && <PatientListMetrics />}
                  {isCnUser && <CnPatientListMetrics />}
                </div>
              </div>
              <div className="row no-gutters overflow-hidden flex-grow-1 mt-2">
                <div className="col ccm-table-container h-100 box-wrapper px-3">
                  <table className="table table-striped table-hover w-100" data-test="patientList_listColumns">
                    <PatientTableHeader
                      sortPatients={this.sortBy}
                      selectPatientsCallback={this.checkSeveralPatients}
                      selectAllOnPageCallback={this.checkAllPatientsOnPage}
                      unSelectCallback={this.unSelectAll}
                      selectedPatientsIds={this.state.selectedPatientsIds}
                    />
                    <PatientTableContent
                      selectPatientCallback={this.selectPatient}
                      canPatientStatusBeChanged={this.canPatientStatusBeChanged}
                      patients={patients}
                    />
                  </table>
                  {emptyBlock}
                  <ReactTooltip id="tooltip-patientTable" type="info" place="bottom" effect="float" />
                  <ReactTooltip id="tooltip-billingStatus" type="info" place="bottom" effect="float" />
                </div>
                <div className="col-3 ccm-schedule-container h-100 box-wrapper overflow-auto ml-2 py-2 d-none">
                  <PatientsScheduler />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AnimatedModal
          notificationList={this.state.notificationList}
          isModalOpen={this.state.isAnimatedModalOpen}
          setIsModalOpen={isOpened => this.setState({ isAnimatedModalOpen: isOpened })}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    anniversary: state.user && state.user.isAnniversary,
    isExternal: state.user && state.user.isExternal,
    userRole: state.user && state.user.role,
    searchParams: state.search.searchParams,
    pageNumber: state.search.pageNumber,
    pageSize: state.search.pageSize,
    sortParams: state.search.sortParams,
    isSessionLoaded: state.search.isSessionLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    paginate: paginationData => dispatch(Paginate(paginationData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    sort: sortingData => dispatch(Sort(sortingData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
