import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
// Services
import { updateLetterBatchStatus } from '../../../../services/patient';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdateLetterBatch } from '../../../../actions/letterBatch';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, BATCH_STATUS_OPTIONS, BATCH_STATUSES,
} from '../../../../constants/constants';
// Components
import { Select } from '../../../base/forms/Select';
import { TextArea } from '../../../base/forms/TextArea';

export function LetterBatchModal(props) {
  const {
    isModalOpen, setIsModalOpen, selectedBatch,
  } = props;

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveBatch = (batchData) => {
    const {
      showNotification, updateLetterBatch,
      letterBatch, letterBatch: { letterBatchList = [], letterBatchHistory = {} },
    } = props;

    const addBatchStatusRequest = updateLetterBatchStatus(selectedBatch.batchId, batchData);
    const addBatchStatusPromise = addBatchStatusRequest.promise;

    return addBatchStatusPromise.then((data) => {
      delete addBatchStatusPromise.promise;

      const updatedStatus = (data && data.status) || 'UPDATED';
      const updatedList = letterBatchList
        .map(b => (b.batchId === selectedBatch.batchId ? { ...b, batchStatus: updatedStatus } : b));

      const historyBatch = letterBatchHistory[selectedBatch.batchId] || {};
      const updatedHistory = [data, ...historyBatch];

      updateLetterBatch({
        ...letterBatch,
        letterBatchList: updatedList,
        letterBatchHistory: { ...letterBatchHistory, [selectedBatch.batchId]: updatedHistory },
      });

      handleCloseModal();
    }).catch((error) => {
      delete addBatchStatusPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error has occurred while attempting to save the letter batch status.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getNewStatusOptions = () => {
    if (selectedBatch.batchStatus === BATCH_STATUSES.REQUESTED) {
      return BATCH_STATUS_OPTIONS.filter(el => el.value === BATCH_STATUSES.CANCELED);
    }
    return BATCH_STATUS_OPTIONS.filter(el => !el.disabled && el.value !== BATCH_STATUSES.CANCELED);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Letter Batch Modal"
      data-test="letterBatchModal_onRequestClose"
    >
      <div className="simple-dialog small-dialog">
        <div className="dialog-title">
          Update Batch Status
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="letterBatchModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={{
            comment: '',
            newStatus: null,
          }}
          validationSchema={Yup.object({
            newStatus: Yup.string().typeError('Select a valid status')
              .required('Required'),
          })}
          onSubmit={(values, { resetForm }) => {
            saveBatch(values);
            resetForm();
          }}
          data-test="letterBatchModal_formikComponent"
        >
          {formik => (
            <Form data-test="letterBatchModal_updateStatus">
              <div className="dialog-content text-left">
                <Select
                  label="New Status"
                  name="newStatus"
                  options={getNewStatusOptions()}
                  data-test="letterBatchModal_newStatusSelect"
                />
                <TextArea
                  label="Comment"
                  name="comment"
                  rows={5}
                />
              </div>
              <div className="dialog-buttons">
                <Button variant="light" data-test="letterBatchModal_cancelButton" onClick={() => handleCloseModal()}>Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  data-test="letterBatchModal_saveButton"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    letterBatch: state.letterBatch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLetterBatch: batchData => dispatch(UpdateLetterBatch(batchData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LetterBatchModal);
