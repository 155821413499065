// Libraries
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Actions
import SetUser from '../../actions/user';
import ShowNotification, { HideNotification } from '../../actions/notification';
// Services
import ErrorBuilder from '../../services/errorMessageBuilder';
import { getManagementData } from '../../services/administration';
import { updateUser } from '../../services/login';
import { saveUserInStorage } from '../../services/userStorage';

import {
  formatPhoneForRequest, formatPhone, formatPhoneOnlyDigits,
} from '../../services/helpers';
// Constants
import {
  NOTIFICATION_TYPE, USER_ROLES, DIGITAL_PHONE_LENGTH, DEFAULT_FIELDS_LENGTH,
} from '../../constants/constants';
// Components
import { TextInput } from '../base/forms/TextInput';
// Views
import ChangePassword from './ChangePassword';


export const accountSchema = () => Yup.object({
  firstName: Yup.string().required('Required').max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH)
    .strict(true)
    .trim('The First Name cannot include leading or trailing spaces.'),
  lastName: Yup.string().required('Required').max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH)
    .strict(true)
    .trim('The Last Name cannot include leading or trailing spaces.'),
  email: Yup.string().required('Required').email('Invalid email').max(DEFAULT_FIELDS_LENGTH.DEFAULT_SECOND_LENGTH),
  title: Yup.string(),
  mobile: Yup.string().test('Length',
    'must contain 10 digits',
    (value) => {
      const valueFormatted = formatPhone(value);
      return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
    }),
  ringCentralPhone: Yup.string().test('Length',
    'must contain 10 digits',
    (value) => {
      const valueFormatted = formatPhone(value);
      return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
    }),
  zoomPhone: Yup.string().test('Length',
    'must contain 10 digits',
    (value) => {
      const valueFormatted = formatPhone(value);
      return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
    }),
});

export const UserAccount = (props) => {
  const { tenant: tenantUrl } = useParams();

  const { user = {}, user: { role: userRole } = {} } = props;
  const [cnUnderManagement, setCnUnderManagement] = useState(null);
  const fetchManagementData = () => {
    const { user: { role } = {}, showNotification } = props;
    if (role !== USER_ROLES.ADMIN) {
      return;
    }

    const getManagementDataRequest = getManagementData();
    const getManagementDataPromise = getManagementDataRequest.promise;

    getManagementDataPromise.then((data) => {
      delete getManagementDataRequest.promise;
      setCnUnderManagement(data.numberOfCareNavigators);
    }).catch((error) => {
      delete getManagementDataRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'An error has occurred while attempting to load the letter batch list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    fetchManagementData();
  }, []);

  const sendUpdateUserRequest = (changedUser) => {
    const { loading } = props;
    if (loading) {
      return;
    }

    const { setUser, showNotification, hideNotification } = props;
    const data = {
      firstName: changedUser.firstName,
      lastName: changedUser.lastName,
      title: changedUser.title,
      email: changedUser.email,
      mobile: changedUser.mobile === '' ? null : changedUser.mobile,
      ringCentralPhone: changedUser.ringCentralPhone === '' ? null : changedUser.ringCentralPhone,
      zoomPhone: changedUser.zoomPhone === '' ? null : changedUser.zoomPhone,
    };
    const updateUserRequest = updateUser(data);
    const updateUserPromise = updateUserRequest.promise;

    hideNotification();

    updateUserPromise.then(() => {
      delete updateUserRequest.promise;

      const newUserData = {
        ...user,
        info: {
          ...user.info,
          ...data,
        },
      };
      setUser(newUserData);
      saveUserInStorage(newUserData, tenantUrl);
      showNotification({
        message: 'Successfully updated user account information.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }
      delete updateUserRequest.promise;
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: ErrorBuilder(error.data),
        autoHide: true,
        notificationType: 'ERROR',
      });
    });
  };

  const haveChanges = (values) => {
    const {
      firstName, lastName, email, title, mobile, ringCentralPhone, zoomPhone,
    } = values;
    const mobileDigits = formatPhoneOnlyDigits(mobile);
    const ringCentralDigits = formatPhoneOnlyDigits(ringCentralPhone);
    const zoomPhoneDigits = formatPhoneOnlyDigits(zoomPhone);

    const mobileFormatted = mobileDigits && mobileDigits.length === DIGITAL_PHONE_LENGTH
      ? formatPhoneForRequest(values.mobile) : values.mobile;
    const ringCentralFormatted = ringCentralDigits
      && ringCentralDigits.length === DIGITAL_PHONE_LENGTH
      ? formatPhoneForRequest(values.ringCentralPhone) : values.ringCentralPhone;
    const zoomFormatted = zoomPhoneDigits && zoomPhoneDigits.length === DIGITAL_PHONE_LENGTH
      ? formatPhoneForRequest(values.zoomPhone) : values.zoomPhone;
    return firstName !== user.firstName || lastName !== user.lastName
      || email !== user.email || title !== user.title || mobileFormatted !== user.mobile
      || ringCentralFormatted !== user.ringCentralPhone || zoomFormatted !== user.zoomPhone;
  };

  const saveProfileInfo = (values) => {
    if (haveChanges(values)) {
      sendUpdateUserRequest(values);
    }
  };

  return (
    <div className="h-100 overflow-auto pr-3">
      <h4 className="text-uppercase text-left my-3" data-test="userAccount_title">User Account</h4>
      <div className="card border-0">
        <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
          <span className="text-uppercase">Summary</span>
        </div>
        <div className="card-body">
          <Formik
            enableReinitialize
            initialValues={{
              userId: user.username || '',
              startDate: user.startDate || '',
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              email: user.email || '',
              mobile: user.mobile || '',
              title: user.title || '',
              cnUnderManagement: cnUnderManagement || '',
              ringCentralPhone: user.ringCentralPhone || '',
              zoomPhone: user.zoomPhone || '',
            }}
            validationSchema={accountSchema()}
            onSubmit={(values, { resetForm }) => {
              const mobileDigits = formatPhoneOnlyDigits(values.mobile);
              const ringCentralDigits = formatPhoneOnlyDigits(values.ringCentralPhone);
              const zoomPhoneDigits = formatPhoneOnlyDigits(values.zoomPhone);
              const mobileValue = mobileDigits && mobileDigits.length === DIGITAL_PHONE_LENGTH
                ? formatPhoneForRequest(values.mobile) : values.mobile;
              const ringCentralPhoneValue = ringCentralDigits
                && ringCentralDigits.length === DIGITAL_PHONE_LENGTH
                ? formatPhoneForRequest(values.ringCentralPhone) : values.ringCentralPhone;
              const zoomPhoneValue = zoomPhoneDigits
                && zoomPhoneDigits.length === DIGITAL_PHONE_LENGTH
                ? formatPhoneForRequest(values.zoomPhone) : values.zoomPhone;
              saveProfileInfo({
                ...values,
                mobile: mobileValue,
                ringCentralPhone: ringCentralPhoneValue,
                zoomPhone: zoomPhoneValue,
              });
              resetForm();
            }}
            data-test="account_formikComponent"
          >
            {formik => (
              <Form className="w-100 text-left">
                <Row className="align-items-center">
                  <div className="col-4">
                    <TextInput
                      label="User ID"
                      name="userId"
                      disabled
                    />
                  </div>
                  <div className="col-4">
                    <TextInput
                      label="Start date"
                      name="startDate"
                      disabled
                    />
                  </div>
                  {(userRole === USER_ROLES.ADMIN) ? (
                    <div className="col-4">
                      <TextInput
                        label="Care Navigators under management"
                        name="cnUnderManagement"
                        value={cnUnderManagement || ''}
                        disabled
                      />
                    </div>
                  ) : ''}
                </Row>
                <Row className="align-items-center">
                  <div className="col-4">
                    <TextInput
                      data-test="userAccount_firstName"
                      label="First Name"
                      name="firstName"
                    />
                  </div>
                  <div className="col-4">
                    <TextInput
                      label="Last Name"
                      name="lastName"
                    />
                  </div>
                  <div className="col-4">
                    <TextInput
                      label="Title"
                      name="title"
                    />
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col-4">
                    <TextInput
                      label="Email"
                      name="email"
                    />
                  </div>
                  <div className="col-4">
                    <TextInput
                      label="Cell Phone"
                      name="mobile"
                      maxLength="14"
                      value={(
                        formik.values && formik.values.mobile
                        && formatPhone(formik.values.mobile)
                      )}
                    />
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col-4">
                    <TextInput
                      label="Ring Central Phone Number"
                      data-test="userAccount_ringCentralPhone"
                      name="ringCentralPhone"
                      maxLength="14"
                      value={(
                        formik.values && formik.values.ringCentralPhone
                        && formatPhone(formik.values.ringCentralPhone)
                      )}
                      readOnly
                    />
                  </div>
                  <div className="col-4">
                    <TextInput
                      label="Zoom Phone Number"
                      data-test="userAccount_accountZoomPhone"
                      name="zoomPhone"
                      maxLength="14"
                      value={(
                        formik.values && formik.values.zoomPhone
                        && formatPhone(formik.values.zoomPhone)
                      )}
                      readOnly
                    />
                  </div>
                </Row>
                <Row className="align-items-center justify-content-end mt-2">
                  <div className="d-flex text-right">
                    <ChangePassword />
                    <Button
                      variant="primary"
                      className="ml-3"
                      onClick={() => formik.handleSubmit()}
                      disabled={(!formik.isValid || !haveChanges(formik.values))}
                      data-test="account_saveBtn"
                    >
                      <span>Save Changes</span>
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => dispatch(HideNotification()),
    setUser: userData => dispatch(SetUser(userData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAccount);
