// Libraries
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _camelCase from 'lodash/camelCase';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getFlightPlanRecentWork } from '../../services/patient';
// Components
import Pager from '../patients/Pager';
import { PatientInfo } from '../menu/PatientInfo';
// Constants
import { NOTIFICATION_TYPE, DATE_FORMAT, USER_ROLES } from '../../constants/constants';
import { DEFAULT_PAGE_SIZE } from '../../constants/pageSizes';

export const RecentWork = (props) => {
  const { showNotification, userRole } = props;

  const { tenant: tenantUrl } = useParams();

  const isPesUser = userRole === USER_ROLES.PES;

  const [patients, setPatients] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [collapseAll, setCollapseAll] = useState(false);

  const getPatientsRecentWork = () => {
    const getRecentWorkRequest = getFlightPlanRecentWork({
      pageSize: DEFAULT_PAGE_SIZE, pageNumber: currentPage,
    });
    const getRecentWorkPromise = getRecentWorkRequest.promise;

    return getRecentWorkPromise.then((data) => {
      delete getRecentWorkRequest.promise;

      setPatients(
        data.patients.map(patient => ({
          ...patient,
          patientInfo: {
            ...patient.patientInfo,
            hasReminders: patient.patientInfo.activeReminders.length,
          },
        })),
      );
      setTotalPages(data.totalPages);
      setTotalCount(data.totalCount);
    }).catch((error) => {
      delete getRecentWorkRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load Flight Plan Recent Work, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderWorkActivityInfo = (work) => {
    const { activityInfo } = work;

    if (!activityInfo.length) {
      return <small className="d-block text-ccm-gray mb-2" data-test="recentWork-emptyActivityInfo">Empty activity work</small>;
    }

    return activityInfo.map((activity, i) => (
      <li key={`workActivityInfo__${i}${_camelCase(activity.name)}`} className="mb-1" data-test="recentWork-activityInfoItem">
        {`${activity.name} ${activity.count > 1 ? `(${activity.count})` : ''}`}
      </li>
    ));
  };

  const renderPatientRecentWork = (recentWork) => {
    if (!recentWork.length) {
      return <small className="d-block text-ccm-gray mb-2" data-test="recentWork-emptyWork">Empty recent work</small>;
    }

    return recentWork.map((work, i) => (
      <div key={`patientRecentWork__${i}${_camelCase(work.workDate)}`} className="mb-3" data-test="recentWork-workItem">
        <p className="recentWork__workDate mb-2" data-test="recentWork_workDate">{moment(work.workDate).format(DATE_FORMAT.FULL)}</p>
        <ul className="mb-0">{renderWorkActivityInfo(work)}</ul>
      </div>
    ));
  };

  const getUrl = (patientId) => {
    if (isPesUser) {
      return `/${tenantUrl}/cn/summary-patient/${patientId}/overview`;
    }
    return `/${tenantUrl}/cn/patient/${patientId}/summary`;
  };

  const showingResults = () => {
    const tempCount = (currentPage + 1) === totalPages
      ? totalCount : DEFAULT_PAGE_SIZE * (currentPage + 1);
    return `${(currentPage * DEFAULT_PAGE_SIZE) + 1} - ${tempCount} of ${totalCount}`;
  };

  useEffect(() => {
    getPatientsRecentWork();
  }, [currentPage]);

  return (
    <div className="flight-plan-recent-work h-100 overflow-auto pr-3">
      <div className="d-flex align-items-center">
        <h4 className="text-left my-3" data-test="recentWork_headingText">Recent Work</h4>
        <div className="ml-auto d-flex-center">
          <span data-test="recentWork-resultsInfo">{`Results: ${showingResults()}`}</span>
          <Button
            size="sm"
            variant="link-dark"
            className="d-flex-center ml-2"
            onClick={() => setCollapseAll(!collapseAll)}
            data-test="recentWork-collapseAllButton"
          >
            <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
            <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
          </Button>
        </div>
      </div>
      <div className="row no-gutters recent-work-content">
        {patients.map(patient => (
          <RecentWorkRow
            key={`flight-plan-patient__${patient.patientInfo.id}`}
            patient={patient}
            renderPatientRecentWork={renderPatientRecentWork}
            collapseAll={collapseAll}
            toUrl={getUrl(patient.patientInfo.id)}
            refreshCallback={getPatientsRecentWork}
            data-test="recentWork-content"
          />
        ))}
      </div>
      <div className="row recent-work-pager no-gutters d-flex-center my-3">
        <Pager
          totalPages={totalPages}
          maxShownCount={5}
          isNextPrevShown
          currentPage={currentPage}
          callback={page => setCurrentPage(page)}
          data-test="recentWork-pager"
        />
      </div>
    </div>
  );
};

export const RecentWorkRow = (props) => {
  const {
    patient: {
      patientInfo, recentWork, recentActivityDate,
    },
    renderPatientRecentWork, collapseAll, refreshCallback, toUrl,
  } = props;

  const [opened, setOpened] = useState(true);

  useEffect(() => {
    setOpened(!collapseAll);
  }, [collapseAll]);

  return (
    <div className={`row no-gutters w-100 mb-2 recentWorkRow-${opened ? 'opened' : 'closed'}`}>
      <div className={`col-4 patient-info border border-right-0 patientInfo-${opened ? 'opened' : 'closed'}`}>
        <PatientInfo
          patient={patientInfo}
          fullInfo={opened}
          toUrl={toUrl}
          refreshCallback={refreshCallback}
        />
      </div>
      <div className="col recent-work-info border border-left-0 text-left p-3">
        {!opened && (
        <p className="mb-0">
          Most recent activity on:&nbsp;
          <span className="recentWork__activityDate">{recentActivityDate}</span>
        </p>
        )}
        {opened && renderPatientRecentWork(recentWork)}
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ top: '1rem', right: '1rem' }}
          onClick={() => setOpened(!opened)}
          data-test="recentWork-collapseRowButton"
        >
          <i className={`d-flex-center bi-caret-${opened ? 'down' : 'up'}-fill ml-1`} />
        </Button>
      </div>
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    userRole: state.user.role,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentWork);
