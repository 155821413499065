import { createAction, createReducer } from '@reduxjs/toolkit';

const userLoaded = createAction('USER_LOADED');
const userAuth = createAction('USER_AUTH');
const updateUserGoals = createAction('UPDATE_USER_GOALS');
const updateAnniversary = createAction('UPDATE_ANNIVERSARY');
const updateUserStatus = createAction('UPDATE_USER_STATUS');
const logout = createAction('LOGOUT');
const updateUserBillingMetrics = createAction('UPDATE_USER_BILLING_METRICS');

const initialState = {
  firstName: '',
  lastName: '',
  authData: null,
  cognito: {
    email_verified: false,
    phone_number: '',
    phone_number_verified: false,
  },
  permissions: {},
};

const User = createReducer(initialState, (builder) => {
  builder
    .addCase(userLoaded, (state, action) => {
      state.cognito = action.cognito || state.cognito || { permissions: {} };
      if (action.authData) {
        state.authData = action.authData;
      }

      if (action.attrs) {
        for (let i = 0; i < action.attrs.length; i++) {
          state.cognito[action.attrs[i].Name] = action.attrs[i].Value;
        }
      }

      if (action.permissions) {
        state.cognito.permissions = action.permissions;
      }

      if (action.info) {
        const infoKeys = Object.keys(action.info);
        for (let i = 0; i < infoKeys.length; i++) {
          state[infoKeys[i]] = action.info[infoKeys[i]];
        }
        state.info = action.info;
      }
    })
    .addCase(userAuth, (state, action) => {
      state.authData = action.authData;
    })
    .addCase(updateUserGoals, (state, action) => {
      state.progressMap = action.progressMap;
    })
    .addCase(updateAnniversary, (state, action) => {
      state.isAnniversary = action.isAnniversary;
    })
    .addCase(updateUserStatus, (state, action) => {
      state.external = action.external;
    })
    .addCase(updateUserBillingMetrics, (state, action) => {
      state.billingMetrics = action.billingMetrics;
    })
    .addCase(logout, () => initialState)
    .addDefaultCase(state => state);
});

export default User;
