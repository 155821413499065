import { createAction, createReducer } from '@reduxjs/toolkit';

const env = createAction('ENV');

const initialState = {};

const Env = createReducer(initialState, (builder) => {
  builder
    .addCase(env, (state, action) => action.env)
    .addDefaultCase(state => state);
});

export default Env;
