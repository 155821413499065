export function SetDefaultQaSearchParams(searchParams) {
  return {
    type: 'SET_DEFAULT_QA_SEARCH_PARAMS',
    searchParams,
  };
}

export function QaSearch(searchParams, isSearchParamsOverwritten) {
  return {
    type: 'QA_SEARCH',
    searchParams,
    isSearchParamsOverwritten: !!isSearchParamsOverwritten,
  };
}

export function ResetQaSearch() {
  return {
    type: 'RESET_QA_SEARCH',
  };
}

export function ResetSideBarQaSearch() {
  return {
    type: 'RESET_SIDEBAR_QA_SEARCH',
  };
}

export function CloseQaSearch() {
  return {
    type: 'CLOSE_QA_SEARCH',
  };
}

export function QaPaginate(pageNumber, pageSize) {
  return {
    type: 'QA_PAGINATE',
    pageNumber,
    pageSize,
  };
}

export function QaSort(sortParams) {
  return {
    type: 'QA_SORT',
    sortParams,
  };
}

export function QaSetFilterNames(filterNames) {
  return {
    type: 'QA_SET_FILTER_NAMES',
    ...('pcpPhysicianName' in filterNames && { pcpPhysicianName: filterNames.pcpPhysicianName }),
    ...('pcpLocationName' in filterNames && { pcpLocationName: filterNames.pcpLocationName }),
  };
}
