// Libraries
import React, { useState, Fragment } from 'react';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
// Actions
import ShowNotification from '../../../../actions/notification';
import { SetTenantTags } from '../../../../actions/tenants';
// Services
import { deleteTenantTags, updateTenantTags } from '../../../../services/tenants';
// Constants
import { DATE_FORMAT, EMPTY_STRING, NOTIFICATION_TYPE } from '../../../../constants/constants';
// Views
import AttributesSettingModal from './AttributesSettingModal';
// Hooks
import useConfirm from '../../../../hooks/useConfirm';

export const PatientAttributesList = (props) => {
  const { tags = [] } = props;
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isConfirmed } = useConfirm();

  const handleEditPatientAttribute = (patientAttribute) => {
    setSelectedAttribute(patientAttribute);
    setIsModalOpen(true);
  };

  const getDateString = date => moment.utc(date).format(DATE_FORMAT.FULL);

  const handleDeletePatientAttribute = async (attribute) => {
    const { showNotification, setTenantTags } = props;
    const confirmed = await isConfirmed('Deactivating this attribute will hide it from the records of all patients associated with this customer, continue?');

    if (!confirmed) {
      return;
    }


    const deleteTenantTagsRequest = deleteTenantTags(attribute.id);
    const deleteTenantTagsPromise = deleteTenantTagsRequest.promise;

    deleteTenantTagsPromise.then(() => {
      delete deleteTenantTagsRequest.promise;
      const updatedTags = tags.map(t => (
        t.id === attribute.id ? { ...t, deleted: true, updatedAt: moment() } : t
      ));
      setTenantTags(updatedTags);
    }).catch((error) => {
      delete deleteTenantTagsRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient attribute, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleUnDeletePatientAttribute = async (attribute) => {
    const { showNotification, setTenantTags } = props;
    const confirmed = await isConfirmed('Activating this value will display it on the records of all patients who this attribute were previously activated, continue?');
    if (!confirmed) {
      return;
    }

    const addAttributeRequest = updateTenantTags(
      attribute.id, { ...attribute, deleted: !attribute.deleted },
    );
    const addAttributePromise = addAttributeRequest.promise;

    addAttributePromise.then((data) => {
      delete addAttributePromise.promise;
      const updatedAttributes = tags.map(r => (r.id === data.id ? data : r));
      setTenantTags(updatedAttributes);

      showNotification({
        message: 'Attribute activated.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete addAttributePromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to activate this attribute',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderEmptyRow = () => (
    <tr data-test="patientAttributesList_emptyMsg">
      <td colSpan="7" className="p-2 border-0">
        No patient attributes found
      </td>
    </tr>);

  const renderPatientAttributesRows = () => {
    const renderTagList = tags;

    if (renderTagList && !!renderTagList.length) {
      const sortedTagListByDate = _reverse(
        _sortBy(renderTagList, el => moment(el.createdDate)),
      );
      return sortedTagListByDate.map(tag => (
        <tr key={`attribute_${tag.id}`} data-test="patientAttributesList_entryRow">
          <td className="attribute__name" data-test="patientAttributesList_attributeName">
            {tag.displayName}
          </td>
          <td className="attribute__description">
            {tag.description ? tag.description : EMPTY_STRING}
          </td>
          <td className="attribute__deleted">
            {tag.deleted ? 'No' : 'Yes'}
          </td>
          <td className="attribute__date" data-test="patientAttributesList_dateRow">
            {tag.updatedAt && getDateString(tag.updatedAt)}
          </td>
          <td className="attribute__action text-left px-0">
            <Button variant="link-dark" className="px-0 ml-4" onClick={() => handleEditPatientAttribute(tag)} data-test="patientAttributesList_editBtn">
              <i className="bi-pencil" />
            </Button>
            { !tag.deleted ? (
              <Button
                variant="link-dark"
                className="mr-3 px-0 float-right"
                onClick={() => handleDeletePatientAttribute(tag)}
                data-test="patientAttributesList_deactivateButton"
              >
                <i className="bi-dash-circle text-ccm-red" />
              </Button>
            ) : (
              <Button
                variant="link-dark"
                className="mr-3 px-0 float-right"
                onClick={() => handleUnDeletePatientAttribute(tag)}
                data-test="patientAttributesList_activateButton"
              >
                <i className="bi-arrow-counterclockwise text-ccm-green" />
              </Button>
            )}
          </td>
        </tr>
      ));
    }
    return renderEmptyRow();
  };

  return (
    <Fragment>
      <div className="patient-attributes-list card border-0">
        <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
          <span className="text-uppercase">Patient Attributes</span>
          <Form inline className="position-absolute" style={{ right: 10 }}>
            <Form.Group>
              <Button
                size="sm"
                variant="link-dark"
                className="position-absolute"
                style={{ right: 10 }}
                onClick={() => handleEditPatientAttribute()}
                data-test="patientAttributesList_addBtn"
              >
                <i className="d-flex-center bi-plus-lg" />
              </Button>
            </Form.Group>
          </Form>
        </div>
        <div className="card-body ccm-table-container px-0">
          <table className="table w-100 text-left">
            <thead>
              <tr>
                <th className="py-2 attribute__name">Display Name</th>
                <th className="py-2 attribute__description">Description</th>
                <th className="py-2 attribute__deleted">Active</th>
                <th className="py-2 attribute__date">Last Updated</th>
                <th className="py-2 attribute__action px-0" />
              </tr>
            </thead>
            <tbody>
              {renderPatientAttributesRows()}
            </tbody>
          </table>
        </div>
      </div>
      <AttributesSettingModal
        initialAttribute={selectedAttribute}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    tags: state.tenant.tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTenantTags: tags => dispatch(SetTenantTags(tags)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAttributesList);
