// Libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-modal';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
// Actions
import { OpenSearch, Search } from '../../../../actions/search';
import SetUser from '../../../../actions/user';
import ShowNotification from '../../../../actions/notification';
// Constants
import {
  DIALOG_DEFAULTS, NOTIFICATION_TYPE, USER_ROLES, USER_STATUS,
} from '../../../../constants/constants';
// Services
import { saveUserInStorage } from '../../../../services/userStorage';
import {
  suspendUser, enableUser, resetUserTotp,
} from '../../../../services/administration';
import ErrorBuilder from '../../../../services/errorMessageBuilder';
import { isObjectsEqual } from '../../../../services/helpers';
// Components
import { withRouter } from '../../../shared/WithRouter';
// Views
import LastVisit from './LastVisit';
import UserRole from './UserRole';
import EditUser from './EditUser';
import ChangePassword from './ChangePassword';
import RequireReview from './RequireReview';
import UpdateGoals from './UpdateGoals';
import LoadingBlock from '../../../menu/LoadingBlock';

export class TeamMember extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      isUpdateGoalsModalOpen: false,
      user: props.teamMember,
      isUserCurrent: props.currentUser.id === props.teamMember.id,
      disableDialogOpen: false,
      selectedOption: 'keepPatients',
      reassignPatientsToUser: '',
    };

    this.promises = {};

    this.toggle = this.toggle.bind(this);
    this.assignPatients = this.assignPatients.bind(this);
    this.openDisableDialog = this.openDisableDialog.bind(this);
    this.disableOAUser = this.disableOAUser.bind(this);
    this.disableUser = this.disableUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.handleCloseDisableDialog = this.handleCloseDisableDialog.bind(this);
    this.cancelDisableUser = this.cancelDisableUser.bind(this);
    this.setDisableAccount = this.setDisableAccount.bind(this);
    this.setUserToReassignPatientsTo = this.setUserToReassignPatientsTo.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { teamMember } = this.props;

    if (!isObjectsEqual(teamMember, prevProps.teamMember)) {
      this.setState({
        user: teamMember,
      });
    }
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  setDisableAccount(event) {
    this.setState({
      selectedOption: event.target.value,
      reassignPatientsToUser: '',
    });
  }

  setUserToReassignPatientsTo(user) {
    this.setState({
      reassignPatientsToUser: user,
    });
  }

  disableTotp = () => {
    const { id: userId, firstName, lastName } = this.state.user;
    const { showNotification } = this.props;
    const getSecretCodeRequest = resetUserTotp(userId);
    const getSecretCodePromise = getSecretCodeRequest.promise;

    return getSecretCodePromise.then(() => {
      delete getSecretCodeRequest.promise;
      showNotification({
        message: `Successfully reset MFA Token for ${firstName || ''} ${lastName || ''}`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete getSecretCodeRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
      }
      showNotification({
        message: 'An error occurred while attempting to reset the MFA Token.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  enableUser = () => {
    if (window.confirm('Are you sure you want to enable this user?')) {
      this.sendUserStatusRequest(true);
    }
  };

  sendUserStatusRequest = (isEnable = false, unassignPatients = false, reassignUserId) => {
    const { id } = this.state.user;

    if (this.state.loading) return;

    this.setState({ loading: true });

    const reassignData = { reassignUserId: null };
    if (!isEnable && reassignUserId) {
      reassignData.reassignUserId = reassignUserId;
    }
    if (unassignPatients) { reassignData.unassignPatients = true; }

    const { showNotification } = this.props;
    const promiseName = isEnable ? 'enableUser' : 'suspendUser';
    const enableUserRequest = isEnable ? enableUser(id) : suspendUser(id, reassignData);
    const enableUserPromise = enableUserRequest.promise;
    this.promises[promiseName] = enableUserRequest;

    enableUserPromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });

      const { changeCallback } = this.props;
      if (changeCallback) {
        changeCallback();
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: ErrorBuilder(error.data),
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });

      this.setState({
        loading: false,
      });
    });
  };

  getTeamMemberIcon = () => {
    const { user } = this.state;
    let tooltipId = `tooltip-problemInternal-${user.id}`;
    let tooltipText = 'Internal user';
    let iconClassName = '';

    if (user.external || user.external === null) {
      tooltipId = `tooltip-problemExternal-${user.id}`;
      iconClassName = 'external';
      tooltipText = 'External user';
    }

    return (
      <div className="team-member-icon-cell">
        <div
          className={`team-member-icon ${iconClassName}`}
          data-tip={tooltipText}
          data-for={tooltipId}
        />
        <ReactTooltip id={tooltipId} type="info" place="bottom" effect="float" />
      </div>
    );
  };

  toggle() {
    this.setState(state => ({
      opened: !state.opened,
    }));
  }

  assignPatients() {
    const { openSearch, search } = this.props;
    const { navigate, params: { tenant: tenantUrl } } = this.props;

    openSearch();
    search({
      careNavigatorId: this.state.user.id,
    });

    navigate(`/${tenantUrl}/cn/list`);
  }

  updateUser(userData) {
    if (this.state.isUserCurrent) {
      const { setUser, currentUser, params: { tenant: tenantUrl } } = this.props;

      const newUserData = {
        ...currentUser,
        info: {
          ...currentUser.info,
          ...userData,
        },
      };

      setUser(newUserData);
      saveUserInStorage(newUserData, tenantUrl);
    }

    let isRoleChanged = false;
    if (userData.role && userData.role.name !== this.state.user.role.name) {
      isRoleChanged = true;
    }

    let isRequireReviewChanged = false;
    if (userData.reviewRequired !== this.state.user.reviewRequired) {
      isRequireReviewChanged = true;
    }

    const { changeCallback } = this.props;
    if ((isRoleChanged || isRequireReviewChanged) && changeCallback) {
      changeCallback();
    } else {
      this.setState(state => ({
        user: {
          ...state.user,
          ...userData,
        },
      }));
    }
  }

  openDisableDialog() {
    this.setState({
      disableDialogOpen: true,
    });
  }

  disableUser() {
    const { reassignPatientsToUser, selectedOption } = this.state;
    const { showNotification } = this.props;

    switch (selectedOption) {
      case 'keepPatients':
        this.sendUserStatusRequest(false, false);
        this.setState({ disableDialogOpen: false });
        break;
      case 'unassignPatients':
        this.sendUserStatusRequest(false, true);
        this.setState({ disableDialogOpen: false });
        break;
      case 'reassignPatients':
        if (reassignPatientsToUser) {
          this.sendUserStatusRequest(false, false, reassignPatientsToUser.id);
          this.setState({ disableDialogOpen: false });
        } else {
          showNotification({
            message: 'Please select a valid care navigator for patient reassignment',
            autoHide: true,
            notificationType: NOTIFICATION_TYPE.ERROR,
          });
        }
        break;
      default:
        break;
    }
  }

  disableOAUser() {
    if (window.confirm('Are you sure you want to disable this user?')) {
      this.sendUserStatusRequest();
    }
  }

  cancelDisableUser() {
    this.handleCloseDisableDialog();
  }

  handleCloseDisableDialog() {
    this.setState({
      disableDialogOpen: false,
    });
  }

  prepareOwnerList(cnList = []) {
    const { user } = this.state;

    const newCnList = [];

    cnList.forEach((cn) => {
      if (user.id === cn.id) {
        return;
      }

      newCnList.push({
        ...cn,
        value: cn.id,
        label: `${cn.lastName || ''}, ${cn.firstName || ''}`,
      });
    });

    newCnList.sort((a, b) => ((a.label.toUpperCase() > b.label.toUpperCase()) ? 1 : -1));

    return newCnList;
  }

  renderBtnsInactive = () => {
    const {
      currentUser: { permissions }, tenant: { mfaEnabled = false }, refreshList, activityStatus,
    } = this.props;
    const { user, isUserCurrent, isUpdateGoalsModalOpen } = this.state;
    const userIsAdmin = user.role.name === USER_ROLES.ADMIN;
    const userIsReporter = user.role.name === USER_ROLES.CUSTOMER;
    const userIsNotAdmin = user.role.name !== USER_ROLES.ADMIN;
    const userIsNotReporter = user.role.name !== USER_ROLES.CUSTOMER;

    return (
      <div className="d-flex-center py-3">
        {(userIsNotAdmin && userIsNotReporter) && (
          <Button variant="light" className="mr-2" onClick={this.assignPatients}>
            Patients Assigned
          </Button>)}

        {!isUserCurrent && (
          <Button
            data-test="teamMember_disableButton"
            variant="light"
            className="mr-2"
            onClick={userIsAdmin || userIsReporter
              ? this.disableOAUser : this.openDisableDialog}
          >
            Disable
          </Button>)}

        <EditUser user={user} submitCallback={this.updateUser} activityStatus={activityStatus} />

        {permissions && permissions.canChangePasswords && (
          <ChangePassword user={user} />
        )}

        {mfaEnabled && !isUserCurrent && (
          <Button
            variant="light"
            className="ml-1"
            onClick={() => this.disableTotp()}
          >
            Reset MFA Token
          </Button>)}

        {(userIsNotAdmin && userIsNotReporter) && permissions && permissions.canUpdateUserGoals && (
          <Fragment>
            <Button
              data-test="teamMember_updateGoalsButton"
              variant="light"
              className="mr-2"
              onClick={() => this.setState({ isUpdateGoalsModalOpen: true })}
            >
              Update goals
            </Button>
            {isUpdateGoalsModalOpen && (
              <UpdateGoals
                selectedUser={user}
                isModalOpen={isUpdateGoalsModalOpen}
                refreshList={refreshList}
                setIsModalOpen={isOpened => this.setState({
                  isUpdateGoalsModalOpen: isOpened,
                })}
              />
            )}
          </Fragment>
        )}

        {(userIsNotAdmin && userIsNotReporter) && (
          // TODO: add a new right to specify that an admin can set this value?
          <RequireReview
            user={user}
            submitCallback={this.updateUser}
          />
        )}
      </div>
    );
  };

  renderBtnsActive = () => (
    <div className="d-flex-center py-3">
      <Button data-test="teamMember_enableButton" variant="light" className="mr-2" onClick={this.enableUser}>Enable</Button>
    </div>);

  renderDisableDialog() {
    const {
      user, loading, disableDialogOpen, selectedOption, reassignPatientsToUser,
    } = this.state;
    const { cnList, pesList } = this.props;
    const owners = [...cnList, ...pesList];
    const ownerList = this.prepareOwnerList(owners);

    let loadingBlock;

    if (this.state.loading) loadingBlock = <LoadingBlock />;

    const confirmMessage = `Disable account for: ${user.firstName || ''} ${user.lastName || ''}`;

    const radioClassName = (option) => {
      const isDisabled = `radio-container ${loading ? 'radio-container-disabled' : ''}`;
      const isInactive = `${selectedOption !== option ? 'radio-container-inactive' : ''}`;
      return `${isDisabled} ${isInactive}`;
    };

    return (
      <Modal
        isOpen={disableDialogOpen}
        style={DIALOG_DEFAULTS}
        onRequestClose={this.cancelDisableUser}
      >
        <div className="simple-dialog">
          <div className="dialog-title">
            {confirmMessage}
            <div
              className="close-icon i-close"
              onClick={this.cancelDisableUser}
            />
          </div>
          <div className="dialog-content">
            {loadingBlock}
            <div>
              <div className="reassign-option">
                <label className={radioClassName('keepPatients')}>
                  <input
                    type="radio"
                    name="reassignPatients"
                    value="keepPatients"
                    onChange={this.setDisableAccount}
                    checked={selectedOption === 'keepPatients'}
                  />
                  <div className="radio-icon" />
                  Keep patients assigned to this user
                </label>
              </div>
              <div className="reassign-option">
                <label className={radioClassName('unassignPatients')}>
                  <input
                    type="radio"
                    name="reassignPatients"
                    value="unassignPatients"
                    onChange={this.setDisableAccount}
                    checked={selectedOption === 'unassignPatients'}
                  />
                  <div className="radio-icon" />
                  Unassign patients
                </label>
              </div>
              <div className="reassign-option">
                <label className={radioClassName('reassignPatients')}>
                  <input
                    type="radio"
                    name="reassignPatients"
                    value="reassignPatients"
                    onChange={this.setDisableAccount}
                    checked={selectedOption === 'reassignPatients'}
                  />
                  <div className="radio-icon" />
                  Reassign patients to:
                </label>
              </div>
              <div className="reassign-select-container">
                <Select
                  className="small-select"
                  name="reassignSelection"
                  noResultsText="CN not found..."
                  disabled={selectedOption !== 'reassignPatients'}
                  value={reassignPatientsToUser}
                  searchable
                  options={ownerList}
                  onChange={this.setUserToReassignPatientsTo.bind(this)}
                  resetValue={ownerList[0]}
                />
              </div>
            </div>
          </div>
          <div className="dialog-buttons justify-content-end px-4">
            <Button
              variant="light"
              onClick={this.cancelDisableUser}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ml-2"
              onClick={this.disableUser}
            >
              Disable User
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  render() {
    const { user, opened } = this.state;
    const { currentUser: { isExternal: isExternalUser = true, role } } = this.props;

    const isInternalAdmin = !isExternalUser
      && (isExternalUser !== null) && (role === USER_ROLES.ADMIN);

    let controls = <div />;
    if (opened) {
      if (user.state === USER_STATUS.ACTIVE) {
        controls = this.renderBtnsInactive();
      } else {
        controls = this.renderBtnsActive();
      }
    }

    return (
      <div className="team-member">
        <div
          className="team-member-data py-2"
          onClick={this.toggle}
        >
          <div className={`arrow ${opened ? 'opened' : 'closed'}`} />
          {isInternalAdmin && this.getTeamMemberIcon()}
          <div className="team-member-name" data-test="teamMember_name">
            <div>
              {`${user.firstName || ''} ${user.lastName || ''}`}
            </div>
            <div className="team-member-username" data-test="teamMember_userName">{user.username || ''}</div>
            <div className="team-member-email" data-test="teamMember_email">{user.email}</div>
          </div>
          <UserRole role={user.role} />
          <LastVisit data={user.statistic.lastActivityAt} />
          <div className="team-member-cell">{user.statistic.numberOfManagedPatients}</div>
          <div className="team-member-status">{user.state}</div>
          <div className="team-member-cell">{user.reviewRequired ? 'yes' : 'no'}</div>
        </div>
        {controls}
        {this.renderDisableDialog()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user,
    cnList: state.cnList,
    pesList: state.pesList,
    tenant: state.tenant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    openSearch: () => dispatch(OpenSearch()),
    search: searchData => dispatch(Search(searchData)),
    setUser: userData => dispatch(SetUser(userData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamMember));
