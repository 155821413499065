import requestsInProgress from './requestsInProgress';
import notification from './notification';
import tenant from './tenants';
import user from './user';
import users from './users';
import patient from './patient';
import env from './env';
import search from './search';
import cnList from './careNavigators';
import pesList from './patientEnrollmentSpecialist';
import physicians from './physicians';
import roles from './roles';
import router from './router';
import letterBatch from './letterBatch';
import patientsGenerated from './patientsGenerated';
import qaSearch from './qaSearch';
import flightPlan from './flightPlan';
import administration from './administration';

const ccmApp = {
  requestsInProgress,
  notification,
  tenant,
  user,
  users,
  patient,
  env,
  search,
  cnList,
  physicians,
  roles,
  router,
  pesList,
  letterBatch,
  patientsGenerated,
  qaSearch,
  flightPlan,
  administration,
};

export default ccmApp;
