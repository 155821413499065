// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { getPatientScreenings } from '../../services/patient';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';


const useLoadPatientScreening = ({ patientId }) => {
  const [screenings, setScreenings] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchScreenings = useCallback(async () => {
    const getPatientScreeningsRequest = getPatientScreenings(patientId);
    const getPatientScreeningsPromise = getPatientScreeningsRequest.promise;
    try {
      const data = await getPatientScreeningsPromise;
      setScreenings(data);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to load screenings.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [patientId]);

  useEffect(() => { fetchScreenings(); }, [fetchScreenings]);

  return { screenings, refetch: fetchScreenings };
};

export default useLoadPatientScreening;
