// Libraries
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import AccessDenied from '../errors/403';
import Loading from '../Loading';
// Constants
import { ROLES_WITH_SNOOK_ACCESS } from '../../../constants/globalAdminUi';

const PrivateRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const redirectRoute = useMatch('/:tenant/snook/redirect');
  const location = useLocation();
  const { tenants, roleName } = useSelector(state => state.user);

  const isLoggedIn = keycloak.authenticated;

  if (!initialized) {
    return <Loading forceLoading={!initialized} />;
  }

  if (isLoggedIn) {
    if (location && redirectRoute
      && location.pathname !== redirectRoute.pathname
      && tenants && tenants.length === 1
      && !ROLES_WITH_SNOOK_ACCESS.includes(roleName)
    ) {
      return <Navigate to="redirect" />;
    }
    return children;
  }
  return <AccessDenied />;
};

export default PrivateRoute;
