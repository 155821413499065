// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
// actions
import SetUser from '../../../actions/user';
// services
import { getUserFromStorage } from '../../../services/userStorage';
import { getCurrentCognitoUser } from '../../../services/amazon-cognito';
// Config
import { withRouter } from '../../shared/WithRouter';
// CN view
import CareNavigator from './CareNavigator';
// Login views
import SignUp from '../../login/SignUp';
import SignIn from '../../login/SignIn';
import SetupMFA from '../../login/SetupMfa';
import NewPassword from '../../login/NewPassword';
import ChangePassword from '../../login/ChangePassword';
import ResetPassword from '../../login/ResetPassword';
// Error views
import NotFound from '../errors/404';
import AccessDenied from '../errors/403';
import Unauthorized from '../errors/401';
import SessionExpired from '../errors/SessionExpired';
// Constants
import { AUTH_PROVIDERS } from '../../../constants/constants';

export class Tenant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTenantSaved: false,
    };

    this.setUser = this.setUser.bind(this);
  }

  componentDidMount() {
    this.setUser();
  }

  componentDidUpdate(prevProps) {
    const { params: { tenant: tenantUrl } } = this.props;
    const { params: { tenant: prevTenantUrl } } = prevProps;

    if (tenantUrl !== prevTenantUrl) {
      window.location.reload();
    }
  }

  setUser() {
    const { setUserInRedux } = this.props;
    const { params: { tenant: tenantUrl } } = this.props;

    const user = getUserFromStorage(tenantUrl);
    if (user) {
      setUserInRedux(user);
      if (user.authData.loggedInWith === AUTH_PROVIDERS.COGNITO) {
        getCurrentCognitoUser();
      }
    }
    this.setState({
      isTenantSaved: true,
    });
  }

  render() {
    if (!this.state.isTenantSaved) {
      return null;
    }

    return (
      <Routes>
        <Route
          path="/"
          element={<SignIn />}
        />
        <Route
          path="setup-mfa"
          element={<SetupMFA />}
        />
        <Route
          path="sign-up"
          element={<SignUp />}
        />
        <Route
          path="new-pw"
          element={<NewPassword />}
        />
        <Route
          path="change-pw"
          element={<ChangePassword />}
        />
        <Route
          path="reset"
          element={<ResetPassword />}
        />
        <Route
          path="cn/*"
          element={<CareNavigator />}
        />
        <Route
          path="403"
          element={<AccessDenied />}
        />
        <Route
          path="401"
          element={<Unauthorized />}
        />
        <Route
          path="expired"
          element={<SessionExpired />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserInRedux: userData => dispatch(SetUser(userData)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Tenant),
);
