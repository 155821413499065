// Libraries
import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
// Views
import PatientAttributesModal from './PatientAttributesModal';
// Services
import { getTagData } from '../../../services/helpers';

export function PatientAttributes(props) {
  const { patientId, patient: { tagIds: patientTags } = {}, tags } = props;
  const [isPatientAttributesModalOpen, setIsPatientAttributesModalOpen] = useState(false);
  let renderPatientAttributes;
  if (patientTags && patientTags.length) {
    renderPatientAttributes = () => patientTags.map(
      id => (
        <small
          data-test="patient_appliedAttributes"
          key={`patient_attribute_${id}`}
          className="px-2 py-1 mr-2 mb-2 rounded-pill bg-ccm-lipstick text-light"
        >
          {getTagData(id, tags).displayName}
        </small>
      ),
    );
  }

  return (
    <Fragment>
      <div className="d-flex-center-between w-100 p-2" data-test="patient_attributeHeader">
        <span className="patient-summary-title text-uppercase">Patient Attributes</span>
        <Button
          size="lg"
          variant="link"
          className="d-flex-center p-0"
          onClick={() => setIsPatientAttributesModalOpen(true)}
          data-test="patientAttributes_patientAttributesEditBtn"
        >
          <span className="d-flex-center bi-pencil-square" />
        </Button>
      </div>
      {patientTags && !!patientTags.length && (
        <div className="d-flex flex-wrap p-2">
          {renderPatientAttributes()}
        </div>
      )}
      <PatientAttributesModal
        patientId={patientId}
        isModalOpen={isPatientAttributesModalOpen}
        setIsModalOpen={setIsPatientAttributesModalOpen}
      />
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
    tags: state.tenant.tags,
  };
}

export default connect(mapStateToProps)(PatientAttributes);
