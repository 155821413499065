// Libraries
import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
// Views
import BulkNextActionModal from './BulkNextActionModal';

export const BulkUpdateNextActionDate = (props) => {
  const { selectedPatientsIds, reloadData } = props;
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <Fragment>
      <Button
        variant="light"
        className="d-flex-center mr-1"
        onClick={() => setModalOpened(true)}
        disabled={selectedPatientsIds && selectedPatientsIds.size === 0}
        data-test="bulkUpdateNextActionDate_changeNextActionDateButton"
      >
        <i className="bi-calendar-day-fill mr-2" />
        <span className="mb-0">
          <span>NOD</span>
        </span>
      </Button>
      <BulkNextActionModal
        selectedPatientsIds={selectedPatientsIds}
        reloadData={reloadData}
        setIsModalOpen={setModalOpened}
        isModalOpen={modalOpened}
      />
    </Fragment>
  );
};

export default BulkUpdateNextActionDate;
