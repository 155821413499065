import { makeAuthorizedRequest, makeRequest } from './requests';

export function getCurrentTenant(tenant) {
  if (!tenant) {
    return Promise.reject();
  }

  return makeRequest('GET', `/config/${tenant}`);
}

export function getTenantTimezone() {
  return makeAuthorizedRequest('GET', '/timezone');
}

export function getTenantTags(includeDeleted = true) {
  return makeAuthorizedRequest('GET', `/tags?includeDeleted=${includeDeleted}`);
}

export function deleteTenantTags(tagId) {
  return makeAuthorizedRequest('DELETE', `/tags/${tagId}`);
}

export function updateTenantTags(tagId, tag) {
  return makeAuthorizedRequest('PUT', `/tags/${tagId}`, tag);
}

export function addTenantTags(tag) {
  return makeAuthorizedRequest('POST', '/tags', tag);
}
