// Libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _clone from 'lodash/clone';
// Components
import { TextInput } from '../../../base/forms/TextInput';
// Actions
import ShowNotification from '../../../../actions/notification';
// services
import { updateUserGoals } from '../../../../services/goals';
// Constants
import { NOTIFICATION_TYPE, DIALOG_STYLES } from '../../../../constants/constants';
// Local Constants
export const DEFAULT_UPDATE_GOALS = {
  dailyEnrollmentGoal: 0,
  monthlyEnrollmentGoal: 0,
};
const UPDATE_GOALS_SCHEMA = () => Yup.object({
  dailyEnrollmentGoal: Yup.number()
    .required('Required')
    .integer('The goal must be an integer')
    .min(0, 'The minimum value must be zero')
    .max(100000, 'The maximum value allowed is 100000'),
  monthlyEnrollmentGoal: Yup.number()
    .required('Required')
    .integer('The goal must be an integer')
    .min(0, 'The minimum value must be zero')
    .max(100000, 'The maximum value allowed is 100000'),
});
const RELOAD_DATA = true;

export function UpdateGoals(props) {
  const {
    showNotification, isModalOpen, setIsModalOpen, selectedUser,
  } = props;

  const [goals, setGoals] = useState(DEFAULT_UPDATE_GOALS);

  const handleCloseModal = () => setIsModalOpen(false);

  const mapPreviousValues = (progressMap) => {
    if (progressMap) {
      const {
        ENROLLED_PATIENTS_PER_DAY = {}, ENROLLED_PATIENTS_PER_MONTH = {},
      } = progressMap;
      const newDefaultValues = _clone(DEFAULT_UPDATE_GOALS);

      if (ENROLLED_PATIENTS_PER_DAY.goal) {
        newDefaultValues.dailyEnrollmentGoal = ENROLLED_PATIENTS_PER_DAY.goal;
      }
      if (ENROLLED_PATIENTS_PER_MONTH.goal) {
        newDefaultValues.monthlyEnrollmentGoal = ENROLLED_PATIENTS_PER_MONTH.goal;
      }
      setGoals(newDefaultValues);
    }
  };

  const handleUpdateGoal = (newGoals) => {
    const { refreshList } = props;
    const updateUserGoalsRequest = updateUserGoals(selectedUser.id, newGoals);
    const updateUserGoalsPromise = updateUserGoalsRequest.promise;

    return updateUserGoalsPromise.then(() => {
      delete updateUserGoalsRequest.promise;

      refreshList(RELOAD_DATA);
      handleCloseModal();
    }).catch((error) => {
      delete updateUserGoalsRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to update the goals',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    mapPreviousValues(selectedUser.progressMap);
  }, []);

  const transformValues = (values) => {
    const userPesValues = [
      {
        goalTypeName: 'ENROLLED_PATIENTS_PER_DAY',
        count: values.dailyEnrollmentGoal,
      },
      {
        goalTypeName: 'ENROLLED_PATIENTS_PER_MONTH',
        count: values.monthlyEnrollmentGoal,
      },
    ];
    return userPesValues;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Update User Goals"
      data-test="UpdateGoals_onRequestClose"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          Update goals
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="UpdateGoals_closeBtn"
          />
        </div>
        <Formik
          validationSchema={UPDATE_GOALS_SCHEMA()}
          initialValues={goals}
          onSubmit={(values) => {
            handleUpdateGoal(transformValues(values));
          }}
          data-test="UpdateGoals_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <div className="row">
                  <div className="col-6">
                    <TextInput
                      label="Daily Enrollment Goal:"
                      name="dailyEnrollmentGoal"
                      type="number"
                      data-test="updateGoals_dailyEnrollmentGoalTextBox"
                    />
                  </div>
                  <div className="col-6">
                    <TextInput
                      label="Monthly Enrollment Goal:"
                      name="monthlyEnrollmentGoal"
                      type="number"
                      data-test="updateGoals_monthlyEnrollmentGoalTextBox"
                    />
                  </div>
                </div>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button
                  variant="light"
                  onClick={() => handleCloseModal()}
                  data-test="UpdateGoals_cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="UpdateGoals_saveBtn"
                >
                  Update goals
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(UpdateGoals);
