// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
import { PATIENTS_LIST_PAGE_SIZE } from '../../constants/pageSizes';
// Services
import { getAlerts } from '../../services/alerts';

const useLoadAlerts = ({
  pageSize = PATIENTS_LIST_PAGE_SIZE, pageNumber = 0, type, status, patientId, ehrid,
}) => {
  const [alertsFlightPlan, setAlertsFlightPlan] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchAlerts = useCallback(async () => {
    const alertsParams = {
      pageSize,
      pageNumber,
      status,
      type,
      patientid: patientId,
      ehrid,
    };
    const getAlertsRequest = getAlerts(alertsParams);
    const getAlertsPromise = getAlertsRequest.promise;
    try {
      const data = await getAlertsPromise;
      setAlertsFlightPlan(data);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load alerts.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [pageSize, pageNumber, type, status, patientId, ehrid]);

  useEffect(() => {
    fetchAlerts();
  }, [fetchAlerts]);

  return { alertsFlightPlan, refetch: fetchAlerts };
};

export default useLoadAlerts;
