// libraries
import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
// constants
import { DATE_FORMAT } from '../../constants/constants';

const defaultDaysInMonth = 31;
const leapYear = 2000;
const defaultMaxYearsRange = 10;
const defaultMinYearsRange = 120;

export class DatePicker extends Component {
  constructor(props) {
    super(props);

    const date = moment(props.value, DATE_FORMAT.FULL_SERVER);
    if (props.value && date.isValid()) {
      this.state = {
        values: {
          month: date.month(),
          date: date.date(),
          year: date.year(),
        },
      };
    } else {
      this.state = {
        values: {
          month: null,
          date: null,
          year: null,
        },
      };
    }

    this.getDays = this.getDays.bind(this);
    this.getYears = this.getYears.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleOuterClick = this.handleOuterClick.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { alwaysEditing } = this.props;

    if (!alwaysEditing) {
      document.addEventListener('click', this.handleOuterClick);
    }
  }

  componentWillUnmount() {
    const { alwaysEditing } = this.props;

    if (!alwaysEditing) {
      document.removeEventListener('click', this.handleOuterClick);
    }
  }

  getMonths() {
    const months = [];

    for (let i = 0; i <= 11; i++) {
      const month = {
        value: i,
        label: moment().month(i).format(DATE_FORMAT.MONTH),
      };
      months.push(month);
    }

    return months;
  }

  getDays() {
    const { values } = this.state;
    const days = [];

    let daysCount = defaultDaysInMonth;

    if (values.month && !Number.isNaN(Number(values.month))) {
      daysCount = moment([values.year || leapYear, values.month]).daysInMonth();
    }

    for (let i = 1; i <= daysCount; i++) {
      const day = {
        value: i,
        label: i > 9 ? i : `0${i}`,
      };
      days.push(day);
    }
    return days;
  }

  getYears() {
    const { maxDate, minDate, isSortingYearAsc } = this.props;
    const years = [];

    let maxYear = moment().year() + defaultMaxYearsRange;
    let minYear = moment().year() - defaultMinYearsRange;

    if (maxDate) {
      maxYear = moment(maxDate).year();
    }
    if (minDate) {
      minYear = moment(minDate).year();
    }

    for (let i = maxYear; i >= minYear; i--) {
      const year = {
        value: i,
        label: i,
      };
      years.push(year);
    }

    return isSortingYearAsc ? years.reverse() : years;
  }

  handleSelect(field, item) {
    const { alwaysEditing, clearValidationCallback } = this.props;
    const { values: { year, month, date } } = this.state;

    this.setState(state => ({
      values: {
        ...state.values,
        [field]: item ? item.value : null,
      },
    }), () => {
      if (clearValidationCallback) {
        clearValidationCallback();
      }

      const isEmptyYear = (!year && year !== 0);
      const isEmptyMonth = (!month && month !== 0);
      const isEmptyDay = (!date && date !== 0);
      const isEmptyDate = (isEmptyMonth && isEmptyDay && isEmptyYear);

      if (alwaysEditing || !isEmptyDate) {
        this.submit();
      }
    });
  }

  handleOuterClick(event) {
    if (this.container && !this.container.contains(event.target)) {
      this.submit();
    }
  }

  submit() {
    const { changeCallback } = this.props;
    const { values } = this.state;

    const isEmptyYear = (!values.year && values.year !== 0);
    const isEmptyMonth = (!values.month && values.month !== 0);
    const isEmptyDay = (!values.date && values.date !== 0);
    const isEmptyDate = (isEmptyMonth && isEmptyDay && isEmptyYear);

    if ((isEmptyMonth || isEmptyDay || isEmptyYear) && !isEmptyDate) {
      return;
    }
    if (changeCallback) {
      const newDate = !isEmptyDate ? moment(values).format(DATE_FORMAT.FULL_SERVER) : '';
      changeCallback(newDate);
    }
  }

  render() {
    const { values: { month, date, year } } = this.state;

    return (
      <div
        className="flex-container date-picker"
        ref={(container) => {
          this.container = container;
        }}
      >
        <Select
          name="month"
          placeholder="Month"
          clearable={false}
          searchable={false}
          value={month}
          options={this.getMonths()}
          onChange={this.handleSelect.bind(this, 'month')}
        />
        <Select
          name="date"
          placeholder="Day"
          clearable={false}
          searchable={false}
          value={date}
          options={this.getDays()}
          onChange={this.handleSelect.bind(this, 'date')}
        />
        <Select
          name="year"
          placeholder="Year"
          clearable={false}
          searchable={false}
          value={year}
          options={this.getYears()}
          onChange={this.handleSelect.bind(this, 'year')}
        />
      </div>
    );
  }
}

export default DatePicker;
