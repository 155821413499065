// Libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Button, Form } from 'react-bootstrap';
// Constants
import {
  DIALOG_STYLES, DATE_FORMAT, PATIENT_ACTIVE_STATUS,
  INACTIVE_REASONS, USER_ROLES, INELIGIBLE_REASONS,
} from '../../../constants/constants';
// Components
import { Select } from '../../base/forms/Select';
import { TextInput } from '../../base/forms/TextInput';
import { Datepicker } from '../../base/forms/Datepicker';

export const activeStatusSchema = () => Yup.object({
  deactivationReason: Yup.string().typeError('Please select a value from the list').required('Required'),
});

export const activeStatusSchemaForDeceased = () => Yup.object({
  deceasedDate: Yup.date().max(moment(new Date()).format(DATE_FORMAT.FULL), 'Must not be in the future'),
  deceasedNote: Yup.string().max(256),
});

const fieldStyles = {
  formGroup: 'mb-0',
  formLabel: 'd-none',
};

export const ActiveStatusModal = (props) => {
  const {
    isModalOpen, setIsModalOpen, newActivityStatusInfo, submitCallback,
  } = props;
  const { user: { role: userRole } } = useSelector(state => state);
  const [validationSchema, setValidationSchema] = useState(activeStatusSchema);

  const isAdminUser = (userRole === USER_ROLES.ADMIN);
  const extraHeightOption = PATIENT_ACTIVE_STATUS[newActivityStatusInfo] === PATIENT_ACTIVE_STATUS.DECEASED ? 'extralarge-height' : 'large-height';

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderActiveStatusInfoBlock = () => {
    if (!newActivityStatusInfo) {
      return null;
    }

    const activeStatus = PATIENT_ACTIVE_STATUS[newActivityStatusInfo];

    let activeStatusInfoBlock;

    switch (activeStatus) {
      case PATIENT_ACTIVE_STATUS.INACTIVE:
        activeStatusInfoBlock = (
          <div>
            <div className="label">Deactivation Reason:</div>
            <div className="inactive-reason-select" data-test="activeStatusModal_deactivationReason">
              <Select
                name="deactivationReason"
                options={INACTIVE_REASONS}
                styles={fieldStyles}
                placeholder="Select reason"
                clearable={false}
                required
              />
            </div>
          </div>
        );
        break;
      case PATIENT_ACTIVE_STATUS.DECEASED:
        activeStatusInfoBlock = (
          <div>
            <div>
              <div className="label">Deceased Date:</div>
              <Datepicker
                label="Date"
                name="deceasedDate"
                maxDate={moment(new Date())}
                todayButton="Today"
                data-test="activeStatusModal_deceasedDate"
              />
            </div>
            <div>
              <div className="label">Note:</div>
              <TextInput
                name="deceasedNote"
                maxLength="256"
                type="text"
                styles={fieldStyles}
                data-test="activeStatusModal_deceasedNote"
              />
            </div>
          </div>
        );
        break;
      case PATIENT_ACTIVE_STATUS.INELIGIBLE:
        activeStatusInfoBlock = (
          <div>
            <div className="label">Ineligibility Reason:</div>
            <div className="ineligible-reason-select" data-test="activeStatusModal_ineligibleReason">
              <Select
                name="deactivationReason"
                placeholder="Select reason"
                clearable={false}
                options={INELIGIBLE_REASONS}
                required
              />
            </div>
          </div>
        );
        break;
      default:
    }

    return activeStatusInfoBlock;
  };

  useEffect(() => {
    setValidationSchema(newActivityStatusInfo !== PATIENT_ACTIVE_STATUS.DECEASED.toLocaleUpperCase()
      ? activeStatusSchema : activeStatusSchemaForDeceased);
  }, [newActivityStatusInfo]);

  const updateActivityStatus = (values) => {
    if (submitCallback) submitCallback({ values });
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={handleCloseModal}
      data-test="activeStatusModal_onRequestClose"
    >
      <div className={`simple-dialog small-dialog ${extraHeightOption} `}>
        <div className="dialog-title">
          {`Set status ${PATIENT_ACTIVE_STATUS[newActivityStatusInfo]}`}
          <button
            className="close-icon i-close"
            onClick={handleCloseModal}
            type="button"
            data-test="activeStatusModal_modalCloseButton"
          />
        </div>
        <Formik
          initialValues={{}}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            const formattedDate = values.deceasedDate
              ? moment(values.deceasedDate).format(DATE_FORMAT.FULL_SERVER) : null;
            const updatedValues = {
              ...(values.deactivationReason && { deactivationReason: values.deactivationReason }),
              ...(values.deceasedNote && { deceasedNote: values.deceasedNote }),
              ...(formattedDate && { deceasedDate: formattedDate }),
            };
            updateActivityStatus(updatedValues);
            resetForm();
          }}
          data-test="activeStatusModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                {
                  !isAdminUser
                    ? <div>Once the status will be set, this patient will be inaccessible</div>
                    : ''
                }
                {renderActiveStatusInfoBlock()}
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="activeStatusModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="activeStatusModal_saveBtn"
                >
                  Set status
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default ActiveStatusModal;
