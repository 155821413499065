// actions
import ShowNotification from '../actions/notification';
// constants
import { NOTIFICATION_TYPE } from '../constants/constants';
// history
import { History } from '../config/history';
// redux
import { store } from '../config/store';
// services
import { makeCancelablePromise } from './cancelablePromise';
// Helpers
import { getTenant } from './helpers';

/**
 * method for formatting params into query string
 * @param data -- data object without complex values
 */
export function formatParams(data) {
  const startParamsString = '?';

  return Object.keys(data).reduce((paramsString, key, index) => {
    let currentParamsString = index ? '&' : '';
    currentParamsString += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;

    return paramsString + currentParamsString;
  }, startParamsString);
}

/**
 * This function exported for unit test purposes
 * @param request -- XMLHttpRequest object
 * @param resolve -- resolve method from Promise
 * @param reject -- reject method from Promise
 */
export function readyStateChangeHandler(request, resolve, reject) {
  try {
    if (request.readyState === 4 && request.status) {
      if (request.status < 300) {
        if (!request.responseText) {
          resolve();
        } else {
          resolve(JSON.parse(request.responseText));
        }
      } else {
        const reason = {
          status: request.status,
          data: JSON.parse(request.responseText),
        };
        reject(reason);
      }
    }
  } catch (e) {
    reject({ message: 'Error: Incorrect response format.' });
  }
}

/**
 * Method for building XHR requests
 * @param method -- POST, GET, PUT, DELETE
 * @param url -- relative url
 * @param data -- optional, data to be sent
 * @param customHeaders -- optional, list of headers in format [{name: 'header-name', value:
 *   'header-value'}]
 * @param customRestAPIBase -- optional, custom base url
 * @returns {Promise}
 */
export function makeRequest(method, url, data, customHeaders, customRestAPIBase) {
  const request = new XMLHttpRequest();

  const requestPromise = new Promise(((resolve, reject) => {
    const headers = [
      {
        name: 'Content-Type',
        value: 'application/json',
      },
    ];

    if (customHeaders && customHeaders.length) {
      headers.push(...customHeaders);
    }

    request.onreadystatechange = () => {
      readyStateChangeHandler(request, resolve, reject);
    };

    let resultUrl = (customRestAPIBase || store.getState().env.API_URL) + url;
    if (method === 'GET' && data) {
      resultUrl += formatParams(data);
    }

    request.open(method, resultUrl, true);
    if (headers && headers.length) {
      headers.forEach((header) => {
        request.setRequestHeader(header.name, header.value);
      });
    }

    let params = null;
    if (data && (method !== 'GET')) {
      try {
        params = JSON.stringify(data);
      } catch (e) {
        reject('Error: incorrect send data format.');
      }
    }

    request.send(params);
  }));

  return makeCancelablePromise(requestPromise);
}

export function makeAuthorizedRequest(method, url, data, customHeaders) {
  let authHeader;

  if (customHeaders && customHeaders.length) {
    authHeader = customHeaders.find(elem => elem.name === 'Authorization');
  }

  let headers = [];

  if (!authHeader) {
    const tenant = getTenant();
    try {
      headers.push({
        name: 'Authorization',
        value: `Bearer ${store.getState().user.authData.accessToken.jwtToken}`,
      }, {
        name: 'tenantid',
        value: tenant,
      });
    } catch (e) {
      return Promise.reject({
        status: 401,
      });
    }
  }

  if (customHeaders && customHeaders.length) {
    headers = headers.concat(customHeaders);
  }

  const request = makeRequest(method, url, data, headers);
  const requestPromise = request.promise;

  requestPromise.catch((error) => {
    const tenantUrl = getTenant();
    if (error.status === 401) {
      switch (error.data.reason) {
        case 'TOKEN_EXPIRED':
          History.push(`/${tenantUrl}/expired`);
          return Promise.reject(error);
        case 'ANOTHER_DEVICE': {
          const locationHash = window.location.hash;
          if (locationHash.match(/^#\/$/) || locationHash.match(/^#\/[a-zA-Z0-9-_]+$/)) {
            return Promise.reject(error);
          }

          store.dispatch(ShowNotification({
            message: 'Your account have been logged in on another device.',
            autoHide: true,
            notificationType: NOTIFICATION_TYPE.ERROR,
          }));

          History.push(`/${tenantUrl}/401`);
          return Promise.reject(error);
        }
        case 'OTHER':
        default:
          store.dispatch(ShowNotification({
            message: error && error.data && error.data.message ? error.data.message : 'An error has ocurred.',
            autoHide: true,
            notificationType: NOTIFICATION_TYPE.ERROR,
          }));
          History.push(`/${tenantUrl}/401`);
          return Promise.reject(error);
      }
    } else if (error.status === 403) {
      History.push(`/${tenantUrl}/403`);
      return Promise.reject(error);
    } else if (error.status === 500) {
      store.dispatch(ShowNotification({
        message: `Internal server error has occured. Error details: ${error.data.details}`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
      return Promise.reject(error);
    }

    return Promise.reject(error);
  });

  return makeCancelablePromise(requestPromise);
}
