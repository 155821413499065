import React from 'react';

import { useKeycloak } from '@react-keycloak/web';

export const withKeycloak = (Component) => {
  const ComponentWithKeycloakProp = (props) => {
    const { keycloak, initialized } = useKeycloak();
    return (
      <Component
        {...props}
        keycloak={keycloak}
        keycloakInitialized={initialized}
      />);
  };
  return ComponentWithKeycloakProp;
};
