// libraries
import React, { Component } from 'react';
import InputMask from 'react-input-mask';
// services
import { formatPhone, formatPhoneOnlyDigits } from '../../services/helpers';
// views
import OneFieldDatePicker from './OneFieldDatePicker';
// consants
import { PHONE_MASK } from '../../constants/constants';

const phoneType = 'phone';

export class LabeledInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      validation: {
        valid: true,
        validationMessage: '',
        infoShown: false,
      },
    };

    this.toggleInfo = this.toggleInfo.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value: initialValue, formatType } = this.props;
    if (initialValue !== prevProps.value) {
      let value = (initialValue || initialValue === 0) ? initialValue : '';

      if (formatType === phoneType) {
        value = formatPhone(value);
      }

      this.setState({
        value,
      });
    }
  }

  onKeyDown = (event) => {
    const { type } = this.props;
    const invalidChars = ['-', '+', 'e'];

    if (type === 'number' && invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  onBlur() {
    const { formatType, handleChange, name } = this.props;
    const { value } = this.state;

    let formattedValue;

    if (formatType === phoneType) {
      formattedValue = formatPhoneOnlyDigits(value);
    }

    if (handleChange) {
      handleChange({
        target: {
          name,
          value: formattedValue || value,
        },
      });
    }

    this.validate();
  }

  onDateChange(field, value) {
    this.setState({
      value,
    }, () => {
      const { handleChange, name } = this.props;

      handleChange({
        target: {
          name,
          value,
        },
      });
      this.validate();
    });
  }

  setValidation(validationResult) {
    this.setState({
      validation: {
        valid: !validationResult,
        validationMessage: validationResult,
      },
    });
  }

  validate() {
    const { validateCallback, type } = this.props;
    if (validateCallback) {
      const { value } = this.state;
      let validationResult;
      if (type === 'date') {
        const {
          maxDate, minDate, maxDateName, minDateName, dateName,
        } = this.props;
        validationResult = validateCallback(value,
          maxDate || null,
          minDate || null,
          maxDateName || '',
          minDateName || '',
          dateName || '');
      } else {
        validationResult = validateCallback(value);
      }
      this.setValidation(validationResult);
      return !validationResult;
    }

    return true;
  }

  toggleInfo(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState(state => ({
      infoShown: !state.infoShown,
    }));
  }

  handleChange(event) {
    const { handleChange, name, formatType } = this.props;
    let { value } = event.target;

    if (value !== null && value !== undefined && value.trim && !value.trim()) {
      value = value.trim();
    }

    this.setState({
      value,
      validation: {
        valid: true,
        validationMessage: '',
      },
    }, () => {
      if (formatType !== phoneType) {
        handleChange({
          target: {
            name,
            value,
          },
        });
      }
    });
  }

  render() {
    const {
      hint, type, name, maxLength, required, label, validateCallback, formatType, dataTest,
    } = this.props;
    const { infoShown, value, validation } = this.state;
    let input;
    let hintIcon;
    let hintText;

    if (hint) {
      hintIcon = (
        <span
          className="info-icon i-info"
          onClick={this.toggleInfo}
        />
      );
      if (infoShown) {
        hintText = <div className="input-hint">{hint}</div>;
      }
    }

    if (type === 'date') {
      input = (
        <OneFieldDatePicker
          submitCallback={this.onDateChange}
          fieldKey={name}
          initialValue={value}
          validationCallback={validateCallback}
          {...(dataTest && { 'data-test': dataTest })}
        />
      );
    } else if (formatType === phoneType) {
      input = (
        <InputMask
          type={type}
          name={name}
          maxLength={maxLength}
          value={value}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          required={required}
          mask={PHONE_MASK}
          maskChar=" "
        />
      );
    } else {
      input = (
        <input
          {...this.props}
          type={type}
          name={name}
          maxLength={maxLength}
          value={value}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          required={required}
        />
      );
    }

    return (
      <label className={!validation.valid ? 'invalid' : ''}>
        <div className="input-label">
          {label}
          &nbsp;
          {validation.validationMessage}
          &nbsp;
          {hintIcon}
        </div>
        {hintText}
        {input}
      </label>
    );
  }
}

export default LabeledInput;
