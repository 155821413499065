export const QUICK_FILTERS = {
  recentlyEnrolled: {
    id: 'recentlyEnrolled',
    name: 'Recently Enrolled',
  },
  enrolledNoCnAssigned: {
    id: 'enrolledNoCnAssigned',
    name: 'Needs CN',
  },
  noDirectContact: {
    id: 'noDirectContact',
    name: 'No Direct Contact',
  },
  notCallAttempts: {
    id: 'notCallAttempts',
    name: 'No Call Attempts',
  },
  nodToday: {
    id: 'nodToday',
    name: 'NOD Today',
  },
  qaFeedback: {
    id: 'qaFeedback',
    name: 'Has QA Feedback',
  },
  notYetBilled: {
    id: 'notYetBilled',
    name: 'Not Yet Billed',
  },
  needsBillingReview: {
    id: 'needsBillingReview',
    name: 'Needs Billing Review',
  },
};

export const QUICK_FILTERS_ADMIN_LIST = [
  { ...QUICK_FILTERS.recentlyEnrolled },
  { ...QUICK_FILTERS.enrolledNoCnAssigned },
  { ...QUICK_FILTERS.notCallAttempts },
  { ...QUICK_FILTERS.noDirectContact },
  { ...QUICK_FILTERS.notYetBilled },
  { ...QUICK_FILTERS.needsBillingReview },
];

export const QUICK_FILTERS_NON_ADMIN_LIST = [
  { ...QUICK_FILTERS.nodToday },
  { ...QUICK_FILTERS.recentlyEnrolled },
  { ...QUICK_FILTERS.notCallAttempts },
  { ...QUICK_FILTERS.noDirectContact },
  { ...QUICK_FILTERS.notYetBilled },
  { ...QUICK_FILTERS.qaFeedback },
];

export const DEFAULT_QUICK_FILTERS = {
  recentlyEnrolled: false,
  enrolledNoCnAssigned: false,
  noDirectContact: false,
  notCallAttempts: false,
  nodToday: false,
  qaFeedback: false,
  notYetBilled: false,
  needsBillingReview: false,
};
