// libraries
import React from 'react';
import ReactTooltip from 'react-tooltip';
// constants
import { STATUSES } from '../../../constants/statuses';

function PatientInfo(props) {
  const {
    patient,
    patient: {
      profile = {},
    },
  } = props;
  const status = patient.status || 'N';
  const primaryStatusName = status.charAt(0);
  const primaryStatus = STATUSES[primaryStatusName];
  const statusColor = primaryStatus ? primaryStatus.color : 'yellow';
  const engoodenText = profile && profile.textMessageConsent && profile.textMessageConsent === 'ALLTEXTS';

  let statusTitle = '';
  if (primaryStatus && primaryStatus.subStatuses && primaryStatus.subStatuses[status]) {
    statusTitle = primaryStatus.subStatuses[status].name || '';
  }

  let { gender } = patient;
  const age = patient.age || '';
  if (gender !== 'M' && gender !== 'F') {
    gender = '';
  }

  const getGenderName = (genderInitial) => {
    switch (genderInitial) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      default:
        return '';
    }
  };

  const scheduleIcon = profile && profile.monthCallEarly
    ? <i className="bi-alarm my-1 d-flex-center" data-for="tooltip-colInfo" data-tip="This patient should be called early in the month" /> : '';
  const fullName = `${patient.lastName}, ${patient.firstName} ${profile && profile.preferredName ? `(${profile.preferredName})` : ''}`;

  return (
    <td className="patientCol__info d-flex align-items-center justify-content-start pl-3">
      <span
        title={statusTitle}
        className={`avatar-icon bg-ccm-${statusColor} text-white rounded-circle flex-shrink-0 mr-1`}
        data-test="patientInfo_patientStatus"
      >
        {status}
        {patient.uncheckedRemindersCount > 0 && (
        <span className="avatar-badge d-flex-center rounded-circle" data-for="tooltip-colInfo" data-tip={`The patient has ${patient.uncheckedRemindersCount} open reminders`}>
          {patient.uncheckedRemindersCount}
        </span>
        )}
      </span>
      <div className="info-icons d-flex flex-column align-items-center ml-2 mr-3">
        {scheduleIcon}
        {engoodenText && <i className="bi-chat-right-dots my-1 d-flex-center" data-for="tooltip-colInfo" data-tip="The patient/caregiver agrees to receive communication through text message" />}
      </div>
      <ReactTooltip id="tooltip-colInfo" type="info" effect="float" place="right" />
      <div className="text-left">
        <div className="patient__name text-nowrap" data-test="patientInfo_patientNames">
          {fullName}
        </div>
        <div className="patient__gender text-nowrap">
          {`${getGenderName(gender)}${gender && age ? ',' : ''} ${age},`}
        </div>
        {patient.ehrId && (
          <div className="patient__id text-nowrap" data-test="patientInfo_patientId">{`ID: ${patient.ehrId}`}</div>
        )}
      </div>
    </td>
  );
}

export default PatientInfo;
