// Libraries
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// Constants
import { EMPTY_STRING, DATE_FORMAT } from '../../../../constants/constants';
import { ENROLLED_STATUSES } from '../../../../constants/statuses';
import { CONSENT_BY, CONSENT_OUTCOME, CONSENT_TYPE } from '../../../../constants/careplan';
// Hooks
import useLoadPatientConsent from '../../../../hooks/services/useLoadPatientConsent';
import { Loading } from '../../../base/Loading';
// Lazy
const ConsentModal = lazy(() => import('./ConsentModal'));

export function Consent(props) {
  const { patientId, loadPatientData } = props;
  const { patient } = useSelector(state => state);
  const showAddBtn = patient && ENROLLED_STATUSES.includes(patient.status);
  const { consents, refetch } = useLoadPatientConsent({ patientId });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalsOpened, setModalsOpened] = useState([]);

  const renderEmptyRow = () => (
    <tr data-test="consent_emptyMsg">
      <td colSpan="5" className="p-2 border-0">
        No consents have been defined for this patient.
      </td>
    </tr>
  );

  const handleAddConsent = () => {
    setIsModalOpen(true);
  };

  const getConsentType = (consent) => {
    if (consent.type && CONSENT_TYPE[consent.type]) {
      const suffix = consent.isProviderTransition ? ' - PCP transition' : '';
      return `${CONSENT_TYPE[consent.type]}${suffix}`;
    }

    return EMPTY_STRING;
  };

  const renderRows = () => {
    const renderConsents = consents;

    if (renderConsents && !!renderConsents.length) {
      const sortedConsents = _orderBy(renderConsents, [el => moment(el.createdAt), 'id'], ['desc', 'desc']);
      return sortedConsents.map((consent) => {
        const getDateString = date => moment
          .utc(date).format(DATE_FORMAT.FULL_WITH_TIME_IN_12_HOURS);

        return (
          <tr key={`consent__row-${consent.id}`} data-test="consent_entryRow">
            <td className="py-2">
              {getConsentType(consent)}
            </td>
            <td className="py-2">
              {(consent.outcome && CONSENT_OUTCOME[consent.outcome]) || EMPTY_STRING}
            </td>
            <td className="py-2">
              {consent.userFullname || EMPTY_STRING}
            </td>
            <td className="py-2">
              {consent.consentDate && getDateString(consent.consentDate)}
            </td>
            <td className="py-2">
              {(consent.consentBy && CONSENT_BY[consent.consentBy]) || EMPTY_STRING}
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  useEffect(() => {
    if (isModalOpen && !modalsOpened.includes('isModalOpen')) {
      setModalsOpened([...modalsOpened, 'isModalOpen']);
    }
  }, [isModalOpen]);

  return (
    <div className="card border-0 mb-4">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <span className="text-uppercase">Consent</span>
        {showAddBtn && (
          <Button
            size="sm"
            variant="link-dark"
            className="position-absolute"
            style={{ right: 10 }}
            onClick={() => handleAddConsent()}
            data-test="consent_addBtn"
          >
            <i className="d-flex-center bi-plus-lg" />
          </Button>)}
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2">Consent Type</th>
              <th className="py-2">Outcome</th>
              <th className="py-2">Performed By</th>
              <th className="py-2">Date</th>
              <th className="py-2">Given By</th>
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>
      </div>
      {modalsOpened.includes('isModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          <ConsentModal
            callbackAction={refetch}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            patientId={patientId}
            loadPatientData={loadPatientData}
          />
        </Suspense>)
      }
    </div>
  );
}

export default Consent;
