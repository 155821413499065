// libraries
import React from 'react';

export function Scheduler() {
  function renderScheduler(isAM) {
    return (
      <div className="d-flex">
        <div className="ml-1">{isAM ? 'AM' : 'PM'}</div>
        <div className="flex-grow-1">
          <li>John Bongiovi</li>
          <li>Mariah Carey</li>
          <li>Samuel Jackson</li>
          <li>Bob Ross</li>
        </div>
      </div>
    );
  }

  function renderTitle() {
    return (
      <p>
        <small>
        TODAYS CALL SCHEDULER
          <b> Thursday, March 11th 2021</b>
        </small>
      </p>
    );
  }

  const schedulerBlock = (
    <div>
      <div className="title">
        {renderTitle()}
      </div>
      <div className="content">
        {renderScheduler(true)}
      </div>
      <div className="content">
        {renderScheduler(false)}
      </div>
    </div>
  );

  return (
    <div className="content d-none">
      {schedulerBlock}
    </div>
  );
}
export default Scheduler;
