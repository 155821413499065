// libraries
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// views
import { Dropdown } from 'react-bootstrap';
// constants
import { EMPTY_STRING, DATE_FORMAT, NOTIFICATION_TYPE } from '../../../constants/constants';
// services
import { getPatientHistoryStatus } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';

export function EnrollmentHistory(props) {
  const { patientId } = props;
  const ENROLLMENT_FILTERS = { ALL: 'All' };
  const [enrollments, setEnrollments] = useState([]);
  const [enrollmentsFilter, setEnrollmentsFilter] = useState(ENROLLMENT_FILTERS.ALL);

  const loadPatientHistoryStatus = () => {
    const { showNotification } = props;

    const getPatientHistoryStatusRequest = getPatientHistoryStatus(patientId);
    const getPatientHistoryStatusPromise = getPatientHistoryStatusRequest.promise;

    getPatientHistoryStatusPromise.then((data) => {
      setEnrollments(data.statuses);
    }).catch((error) => {
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not load patient history status',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => loadPatientHistoryStatus(), [patientId]);

  const filterDropDownItems = Object.keys(ENROLLMENT_FILTERS).map((key, index) => (
    <Dropdown.Item
      data-test={`enrollments_dropdownFilter_${key}`}
      key={`enrollments_filter_${index}`}
      onClick={() => {
        setEnrollmentsFilter(ENROLLMENT_FILTERS[key]);
      }}
      as="button"
      className="d-flex my-1"
    >
      <div className="pr-3">{ENROLLMENT_FILTERS[key]}</div>
    </Dropdown.Item>
  ));

  const getFilter = (
    <Dropdown
      className="position-absolute"
      style={{ left: 1 }}
    >
      <Dropdown.Toggle
        variant="ccm-light-gray"
        className="py-0"
      >
        {`Filter: ${enrollmentsFilter}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterDropDownItems}
      </Dropdown.Menu>
    </Dropdown>
  );

  function renderEmptyRow() {
    return (
      <tr data-test="enrollment_emptyMsg">
        <td colSpan="3" className="p-2 border-0">
          This patient has no known enrollments
        </td>
      </tr>
    );
  }

  const renderEnrollmentsRows = (enrollmentsData) => {
    if (enrollmentsData && enrollmentsData.length) {
      return enrollmentsData.map((enrollment, index) => {
        const dateString = enrollment.date
          ? moment.utc(enrollment.date).format(DATE_FORMAT.SHORT) : EMPTY_STRING;
        return (
          <tr className="enrollment__row" key={`enrollment_row_${index}_${enrollment.date}-${enrollment.name}`}>
            <td>{dateString}</td>
            <td>{enrollment.status || EMPTY_STRING}</td>
            <td className="d-none">{enrollment.program || EMPTY_STRING}</td>
            <td>{enrollment.user || EMPTY_STRING}</td>
            <td>{enrollment.reason || EMPTY_STRING}</td>
            <td>{enrollment.comments || EMPTY_STRING}</td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  const renderEnrollments = () => renderEnrollmentsRows(enrollments);

  return (
    <div className="card border-0 mb-4">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1" data-test="enrollmentHistory_header">
        {getFilter}
        <span className="text-uppercase">Enrollment History</span>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2">Date</th>
              <th className="py-2">Status</th>
              <th className="py-2 d-none">Program</th>
              <th className="py-2">User</th>
              <th className="py-2">Reason</th>
              <th className="py-2">Comments</th>
            </tr>
          </thead>
          <tbody data-test="enrollmentHistory_enrollmentHistoryTableBody">
            {renderEnrollments()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentHistory);
