// Libraries
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
// Components
import MyCallSchedule from './callSchedule/MyCallSchedule';
import PatientsAvailability from './callSchedule/PatientsAvailability';

export const CallSchedule = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="flight-plan-call-schedule h-100 overflow-auto pr-3">
      <div className="call-schedule-header d-flex align-items-center">
        <h4 className="text-left my-3" data-test="callSchedule_headingText">Call Schedule</h4>
      </div>
      <Tabs activeKey={tabIndex} onSelect={index => setTabIndex(index)} unmountOnExit data-test="callSchedule_tabs">
        <Tab eventKey={0} title="My Call Schedule" tabClassName="p-1 mr-2">
          <MyCallSchedule />
        </Tab>
        <Tab eventKey={1} title="Patient Availability" tabClassName="p-1 mr-2">
          <PatientsAvailability />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CallSchedule;
