import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import ReactSelect, { Async } from 'react-select';
import { Form } from 'react-bootstrap';

export const Select = ({
  label, subtext, async = false, styles = {}, withLabel = true, ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const [optionSubtext, setOptionSubtext] = useState(null);

  const getOnChangeValue = (option) => {
    if (option.subtext) {
      setOptionSubtext(option.subtext);
    } else {
      setOptionSubtext(null);
    }
    if (props.multi && props.returnValues) {
      return option.map(o => o.value);
    }
    if (async || props.multi) {
      return option;
    }
    if (props.valueKey) {
      return option[props.valueKey];
    }
    return option.value;
  };
  const onChangeUpdated = props.onChange
    ? props.onChange : option => setFieldValue(field.name, option && getOnChangeValue(option));

  const restProps = {
    ...field,
    ...props,
    searchable: true,
    clearable: false,
    value: field.value,
    className: `${styles.formControl}${meta.error ? ' is-invalid' : ''}`,
    onChange: onChangeUpdated,
  };

  return (
    <Form.Group className={styles.formGroup}>
      {withLabel && (
        <Form.Label
          htmlFor={props.id || props.name}
          className={styles.formLabel}
        >
          {label || ''}
        </Form.Label>)
      }
      {async ? (
        <Async
          {...restProps}
          loadOptions={props.loadOptions}
        />) : (
          <ReactSelect
            {...restProps}
            options={props.options}
          />
      )}
      {(optionSubtext || subtext) && <Form.Text muted>{optionSubtext || subtext}</Form.Text>}
      <Form.Control.Feedback type="invalid" className={styles.formInvalid} muted>{meta.error}</Form.Control.Feedback>
    </Form.Group>
  );
};
