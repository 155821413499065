// Libraries
import { useContext, useState, useEffect } from 'react';
import { ConfirmContext } from '../context/ConfirmContextProvider';

const useConfirm = () => {
  const [confirm, setConfirm] = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);


  const isConfirmed = (prompt, promptTitle = 'Confirm', promptProceed = 'Yes', promptCancel = 'Cancel') => {
    setNeedsCleanup(true);

    const promise = new Promise((resolve, reject) => {
      setConfirm({
        prompt,
        promptTitle,
        promptProceed,
        promptCancel,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
      });
    });

    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return false;
      },
    );
  };

  useEffect(() => () => {
    if (confirm.cancel && needsCleanup) {
      confirm.cancel();
    }
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;
