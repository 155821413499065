// Libraries
import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Button, Form } from 'react-bootstrap';
// Services
import { bulkUpdateNextActionDate } from '../../../services/patientList';
// Actions
import ShowNotification from '../../../actions/notification';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT,
} from '../../../constants/constants';
// Components
import { Datepicker } from '../../base/forms/Datepicker';

export const nextActionSchema = () => Yup.object({
  nextAction: Yup.date()
    .typeError('Must be a valid date')
    .min(moment().format(DATE_FORMAT.FULL), 'Must not be in the past')
    .required('Required'),
});

export const BulkNextActionModal = (props) => {
  const {
    isModalOpen, setIsModalOpen, selectedPatientsIds, reloadData,
  } = props;

  const {
    requestsInProgress: { count: loading },
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const DEFAULT_VALUES = {
    nextAction: new Date(),
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveNextActionDate = (values) => {
    if (loading || !selectedPatientsIds || !selectedPatientsIds.size) {
      return;
    }
    const nextActionString = moment(values.nextAction).format(DATE_FORMAT.SHORT);
    const confirmPrefix = `Set next action date to ${nextActionString} for`;
    let confirmMessage = `${confirmPrefix} selected patient?`;

    if (selectedPatientsIds && selectedPatientsIds.size > 1) {
      confirmMessage = `${confirmPrefix} ${selectedPatientsIds.size} selected patients?`;
    }

    if (!window.confirm(confirmMessage)) {
      return;
    }

    const ids = Array.from(selectedPatientsIds);

    const bulkUpdateRequest = bulkUpdateNextActionDate(values.nextAction, ids);
    const bulkUpdatePromise = bulkUpdateRequest.promise;

    bulkUpdatePromise.then(() => {
      delete bulkUpdateRequest.promise;
      showNotification({
        message: 'Next action date was updated successfully.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      if (reloadData) {
        reloadData();
      }
      handleCloseModal();
    }).catch((error) => {
      delete bulkUpdateRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to update next action date.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Change Next Action Date"
      data-test="bulkNextActionModal_onRequestClose"
    >
      <div className="simple-dialog small-dialog">
        <div className="dialog-title">
          Change Next Action Date
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="bulkNextActionModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={DEFAULT_VALUES}
          validationSchema={nextActionSchema()}
          onSubmit={(values) => {
            const formattedDate = moment(values.nextAction).format(DATE_FORMAT.FULL_SERVER);
            const updatedValues = { ...values, nextAction: formattedDate };
            saveNextActionDate(updatedValues);
          }}
          data-test="bulkNextActionModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <div className="col">
                  <Datepicker
                    label="Date"
                    name="nextAction"
                    dateFormat={DATE_FORMAT.FULL_WITH_LOWERCASE}
                    popperClassName="d-none"
                    data-test="bulkNextActionModal_dateInput2"
                  />
                </div>
                <div className="d-flex-center" data-test="bulkNextActionModal_dateInput">
                  <Datepicker
                    name="nextAction"
                    minDate={new Date()}
                    showDaysAlert
                    inline
                  />
                </div>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="bulkNextActionModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="bulkNextActionModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default BulkNextActionModal;
