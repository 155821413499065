// Libraries
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getEnrolledPatientsProvider } from '../../services/reports';

const useEnrolledPatientsProvider = () => {
  const dispatch = useDispatch();
  const [report, setReport] = useState({});
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchEnrolledPatientsProvider = async (request) => {
    if (request !== null) {
      const downloadReportRequest = getEnrolledPatientsProvider(request);
      const downloadReportPromise = downloadReportRequest.promise;
      try {
        delete downloadReportRequest.promise;
        const requestedData = await downloadReportPromise;
        setReport(requestedData);
      } catch (error) {
        delete downloadReportRequest.promise;
        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }
        showNotification({
          message: 'An unexpected error has occurred while trying to download the report.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      }
    }
  };

  return { report, refetch: fetchEnrolledPatientsProvider };
};

export default useEnrolledPatientsProvider;
