// libraries
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
// Images
import flightPlanIcon from '../../images/flight-plan.svg';

export function FlightPlanControl() {
  const { tenant: tenantUrl } = useParams();
  return (
    <span className="flight-plan-control d-flex-center mr-5">
      <NavLink
        to={`/${tenantUrl}/cn/flight-plan`}
        className={({ isActive }) => `d-flex${isActive ? ' active' : ''}`}
        data-test="flightPlanControl_link"
      >
        <img
          src={flightPlanIcon}
          alt="Flight Plan Icon"
          className="flight-plan-icon mr-1"
        />
        <span>Flight Plan</span>
      </NavLink>
    </span>
  );
}

export default FlightPlanControl;
