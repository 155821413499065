// libraries
import React, { Fragment } from 'react';
// views
import Calendar from './patientsScheduler/Calendar';
import Scheduler from './patientsScheduler/Scheduler';

export function PatientsScheduler() {
  const patientSchedulerBlock = (
    <Fragment>
      <div className="row no-gutters flex-grow-1">
        <Calendar />
      </div>
      <div className="row no-gutters flex-grow-1">
        <Scheduler />
      </div>
    </Fragment>
  );

  return (
    <div className="d-flex flex-column h-100">
      {patientSchedulerBlock}
    </div>
  );
}
export default PatientsScheduler;
