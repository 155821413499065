// libraries
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import _clone from 'lodash/clone';
import { useParams } from 'react-router-dom';
// Components
import { Button, Form } from 'react-bootstrap';
import { Select } from '../../../base/forms/Select';
import { Checkbox } from '../../../base/forms/Checkbox';
import { TextArea } from '../../../base/forms/TextArea';
import { TextInput } from '../../../base/forms/TextInput';
import { Datepicker } from '../../../base/forms/Datepicker';
// Services
import { createSurveyAttempt, createSurveyCompletion, editSurveyAttempt } from '../../../../services/patient';
// Actions
import ShowNotification from '../../../../actions/notification';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT,
  SATISFACTION_SURVEYS_RESPONSES, SATISFACTION_SURVEYS_QUESTIONS,
} from '../../../../constants/constants';
// Local Constants
export const DEFAULT_SURVEY = {
  type: '',
  date: moment.utc().format(DATE_FORMAT.FULL),
  surveyedBy: '',
  question1: '',
  question2: '',
  question3: '',
  question4: '',
  question5: '',
  note: '',
};
const SATISFACTION_SURVEYS_SCHEMA = () => Yup.object({
  type: Yup.string().oneOf(['attemptedCall', 'completedCall']).required('Required'),
  date: Yup.date().required('Required'),
  surveyedBy: Yup.string(),
  question1: Yup.string()
    .when('type', {
      is: 'completedCall',
      then: Yup.string().required('Required').nullable(),
    }),
  question2: Yup.string()
    .when('type', {
      is: 'completedCall',
      then: Yup.string().required('Required').nullable(),
    }),
  question3: Yup.string()
    .when('type', {
      is: 'completedCall',
      then: Yup.string().required('Required').nullable(),
    }),
  question4: Yup.string()
    .when('type', {
      is: 'completedCall',
      then: Yup.string().required('Required').nullable(),
    }),
  question5: Yup.string(),
  note: Yup.string()
    .when('type', {
      is: 'attemptedCall',
      then: Yup.string().required('Required').max(280, 'The limit is 280 characters'),
    }),
});


const SatisfactionSurveysModal = (props) => {
  const
    {
      isModalOpen, setIsModalOpen, handleGetSurveys,
      editMode, surveyInfo, setEditMode,
    } = props;

  const { id: patientId } = useParams();
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector(state => state.user);
  const [newSurvey, setNewSurvey] = useState(surveyInfo || DEFAULT_SURVEY);


  useEffect(() => {
    if (editMode) {
      setNewSurvey({
        ...surveyInfo,
        type: surveyInfo.type === 'CALL' ? 'attemptedCall' : 'completedCall',
        date: surveyInfo.date || moment.utc().format(DATE_FORMAT.FULL),
      });
    } else {
      const newDefaultValues = _clone(DEFAULT_SURVEY);
      newDefaultValues.surveyedBy = `${firstName} ${lastName}`;
      setNewSurvey(newDefaultValues);
    }
  }, [surveyInfo]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (editMode) setEditMode(false);
  };

  const renderAdditionalNotes = ({ type }) => type === 'attemptedCall' || editMode;
  const renderAdditionalQuestions = ({ type }) => type === 'completedCall';

  const handleCreateAttempt = (survey) => {
    const surveyAttempt = {
      date: moment(survey.date).utc().format(DATE_FORMAT.FULL_SERVER),
      note: survey.note,
    };

    const createSurveyAttemptRequest = !editMode
      ? createSurveyAttempt(patientId, surveyAttempt)
      : editSurveyAttempt(patientId, survey.id, surveyAttempt);

    const createSurveyAttemptPromise = createSurveyAttemptRequest.promise;

    return createSurveyAttemptPromise.then(() => {
      delete createSurveyAttemptRequest.promise;
      handleGetSurveys();
      handleCloseModal();
    }).catch((error) => {
      delete createSurveyAttemptRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      dispatch(ShowNotification({
        message: 'An error occurred while creating the survey',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const handleCreateCompletion = (survey) => {
    const surveyCompletion = {
      date: moment(survey.date).utc().format(DATE_FORMAT.FULL_SERVER),
      question1: survey.question1,
      question2: survey.question2,
      question3: survey.question3,
      question4: survey.question4,
      question5: survey.question5,
    };

    const createSurveyCompletionRequest = createSurveyCompletion(patientId, surveyCompletion);
    const createSurveyCompletionPromise = createSurveyCompletionRequest.promise;

    return createSurveyCompletionPromise.then(() => {
      delete createSurveyCompletionRequest.promise;
      handleGetSurveys();
      handleCloseModal();
    }).catch((error) => {
      delete createSurveyCompletionRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      dispatch(ShowNotification({
        message: 'An error occurred while creating the survey',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const handleCreateSurvey = (survey) => {
    if (survey.type === 'attemptedCall') {
      return handleCreateAttempt(survey);
    }
    return handleCreateCompletion(survey);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Satisfaction Surveys"
      data-test="satisfactionSurveys_onRequestClose"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          Satisfaction Surveys
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="satisfactionSurveys_closeBtn"
          />
        </div>
        <Formik
          initialValues={newSurvey}
          validationSchema={SATISFACTION_SURVEYS_SCHEMA()}
          onSubmit={(values) => { handleCreateSurvey(values); }}
          data-test="satisfactionSurveys_formikComponent"
        >
          {formik => (
            <Form>
              <div className="dialog-content px-4 text-left">
                <div className="row">
                  <div className="col-4">
                    <Form.Label>Type</Form.Label>
                    <Checkbox
                      id="attempted_call"
                      label="Attempted Call"
                      name="type"
                      value="attemptedCall"
                      type="radio"
                      styles={{ formGroup: 'm-0' }}
                      data-test="satisfactionSurveys_attemptedCall"
                      disabled={editMode}
                    />
                    <Checkbox
                      id="completed_call"
                      label="Completed Call"
                      name="type"
                      value="completedCall"
                      type="radio"
                      data-test="satisfactionSurveys_completedCall"
                      disabled={editMode}
                    />
                  </div>
                  <div className="col-4">
                    <Datepicker
                      id="date"
                      label="Date"
                      name="date"
                      data-test="satisfactionSurveys_dateInput"
                      disabled={editMode}
                    />
                  </div>
                  <div className="col-4">
                    <TextInput
                      id="reviewer"
                      label="Reviewer"
                      name="surveyedBy"
                      type="text"
                      data-test="satisfactionSurveys_reviewerInput"
                      readOnly
                      disabled
                    />
                  </div>
                </div>
                {renderAdditionalNotes(formik.values) && (
                <div className="row">
                  <div className="col-12">
                    <TextArea
                      id="note"
                      label="Comments"
                      rows={3}
                      name="note"
                      maxLength="280"
                    />
                  </div>
                </div>
                )
                }
                {renderAdditionalQuestions(formik.values) && (
                  <div className="row">
                    <div className="col-12">
                      <Select
                        id="question1"
                        label={SATISFACTION_SURVEYS_QUESTIONS.questionOne.modal}
                        name="question1"
                        options={SATISFACTION_SURVEYS_RESPONSES.firstOptions}
                        data-test="nextOutreachDateModal_timeInput"
                      />
                    </div>
                    <div className="col-12">
                      <Select
                        id="question2"
                        label={SATISFACTION_SURVEYS_QUESTIONS.questionTwo.modal}
                        name="question2"
                        options={SATISFACTION_SURVEYS_RESPONSES.secondOptions}
                        data-test="nextOutreachDateModal_timeInput"
                      />
                    </div>
                    <div className="col-12">
                      <Select
                        id="question3"
                        label={SATISFACTION_SURVEYS_QUESTIONS.questionThree.modal}
                        name="question3"
                        options={SATISFACTION_SURVEYS_RESPONSES.firstOptions}
                        data-test="nextOutreachDateModal_timeInput"
                      />
                    </div>
                    <div className="col-12">
                      <Select
                        id="question4"
                        label={SATISFACTION_SURVEYS_QUESTIONS.questionFour.modal}
                        name="question4"
                        options={SATISFACTION_SURVEYS_RESPONSES.secondOptions}
                        data-test="nextOutreachDateModal_timeInput"
                      />
                    </div>
                    <div className="col-12">
                      <TextArea
                        id="question5"
                        label="Comments"
                        rows={3}
                        name="question5"
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="dialog-buttons justify-content-end mt-0 mb-4 px-4">
                <Button
                  variant="light"
                  onClick={() => handleCloseModal()}
                  data-test="satisfactionSurveys_cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="satisfactionSurveys_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default SatisfactionSurveysModal;
