// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Services
import { getPrompts } from '../../services/administration';

const useLoadAdministrationPrompts = ({ category, status, type }) => {
  const [prompts, setPrompts] = useState([]);
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPrompts = useCallback(async () => {
    const getPromptsRequest = getPrompts(category, status, type);
    const getPromptsPromise = getPromptsRequest.promise;
    try {
      const data = await getPromptsPromise;
      setPrompts(data);
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load prompts.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, [category, status, type]);

  useEffect(() => {
    fetchPrompts();
  }, [fetchPrompts]);

  return { prompts, refetch: fetchPrompts };
};

export default useLoadAdministrationPrompts;
