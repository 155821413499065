import { createAction, createReducer } from '@reduxjs/toolkit';

const setRoles = createAction('SET_ROLES');

const initialState = {};

const Roles = createReducer(initialState, (builder) => {
  builder
    .addCase(setRoles, (state, action) => action.roles)
    .addDefaultCase(state => state);
});

export default Roles;
