// libraries
import React from 'react';
import { useSelector } from 'react-redux';
// Views
import HealthFactorElement from './HealthFactorElement';

const HealthFactorList = () => {
  const { administration: { adminHealthFactors } } = useSelector(state => state);
  return (
    <div className="health-factor-configuration">
      {adminHealthFactors && adminHealthFactors
        && Object.keys(adminHealthFactors).map(key => (
          <HealthFactorElement factorsTitle={key} factors={adminHealthFactors[key].healthFactors} categoryId={adminHealthFactors[key].categoryId} key={`factor_element_${key}_${adminHealthFactors[key].categoryId}`} />
        ))}
    </div>
  );
};

export default HealthFactorList;
