// Libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
// Services
import { getFactors } from '../../../services/administration';
import { fetchData } from '../../../services/helpers';
// Actions
import { SetHealthFactorList } from '../../../actions/administration';
// Views
import Administration from '../../administrator/Administration';
import UserAccount from '../../user/UserAccount';
import UserPageMenu from '../../menu/UserPageMenu';
import UserActivity from '../../administrator/UserActivity';
import PatientOutreach from '../../administrator/PatientOutreach';
import Settings from '../../settings/Settings';
import LicenceAgreement from '../../user/termsOfAgreement/LicenceAgreement';
import DemoSettings from '../../user/DemoSettings';
import PatientAttributesSettings from '../../administrator/PatientAttributesSettings';
import ProtectedRoute from './ProtectedRoute';
import PromptsConfiguration from '../../administrator/PromptsConfiguration';
import Other from '../../administrator/Other';
import SDoH from '../../administrator/SDoH';

export function User() {
  const {
    tenant: { isDemoEnv = false },
    user: { permissions: { canAccessAdminPanel: canUserAccessAdminPanel } },
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const setHealthFactorList = data => dispatch(SetHealthFactorList(data));

  const loadHealthFactors = async () => {
    const factors = await fetchData(getFactors());
    setHealthFactorList(factors);
  };

  useEffect(() => {
    loadHealthFactors();
  }, []);

  return (
    <div className="ccm-admin-main d-flex mt-2">
      <div className="h-100 ccm-admin-panel-menu">
        <UserPageMenu />
      </div>
      <div className="h-100 ccm-admin-main-content box-wrapper flex-grow-1 ml-2 py-3 pl-3 overflow-auto">
        <Routes>
          <Route
            path="administration/*"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <Administration />
              </ProtectedRoute>)}
          />
          <Route
            path="account"
            element={<UserAccount />}
          />
          <Route
            path="terms"
            element={<LicenceAgreement />}
          />
          <Route
            path="patient-outreach"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <PatientOutreach />
              </ProtectedRoute>)}
          />
          <Route
            path="sdoh"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <SDoH />
              </ProtectedRoute>)}
          />
          <Route
            path="prompts"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <PromptsConfiguration />
              </ProtectedRoute>)}
          />
          <Route
            path="patient-attributes"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <PatientAttributesSettings />
              </ProtectedRoute>)}
          />
          <Route
            path="user-activity"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <UserActivity />
              </ProtectedRoute>)}
          />
          <Route
            path="settings"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <Settings />
              </ProtectedRoute>)}
          />
          <Route
            path="demo-settings"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel && isDemoEnv}>
                <DemoSettings />
              </ProtectedRoute>)}
          />
          <Route
            path="other"
            element={(
              <ProtectedRoute allowed={canUserAccessAdminPanel}>
                <Other />
              </ProtectedRoute>)}
          />
        </Routes>
      </div>
    </div>
  );
}

export default User;
