// Libraries
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import _capitalize from 'lodash/capitalize';
// Constants
import { DATE_FORMAT } from '../../../constants/constants';

export const MetricsTitle = (props) => {
  const { tenantName, isTeamMetrics = false } = props;

  return (
    <div className="d-flex align-items-center my-4 ml-4">
      <h4 className="text-left title" data-test="metricsTitle_metricsMonthYear">
        {moment().format(DATE_FORMAT.MONTH_WITH_YEAR)}
      </h4>
      {isTeamMetrics && (
        <span className="text-left text-ccm-gray ml-2" data-test="metricsTitle_tenantName">
          {`${_capitalize(tenantName)} Team Metrics`}
        </span>
      )}
    </div>
  );
};

export function mapStateToProps(state) {
  return {
    tenantName: state.tenant.name,
  };
}

export default connect(mapStateToProps, null)(MetricsTitle);
