// Libraries
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button } from 'react-bootstrap';
// Views
import NoteModal from './NoteModal';
// Services
import { parseDraftToHtml } from '../../../services/helpers';
// Constants
import { NOTES_TYPES, DATE_FORMAT } from '../../../constants/constants';

export function EnrollmentNotes(props) {
  const { patientId, patient: { enrollmentNote } = {} } = props;
  const [initialNote, setInitialNote] = useState(
    { ...enrollmentNote, type: NOTES_TYPES.ENROLLMENT },
  );
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);

  useEffect(() => {
    setInitialNote({ ...enrollmentNote, type: NOTES_TYPES.ENROLLMENT });
  }, [enrollmentNote]);

  const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);
  const getFullName = a => `${a.firstName || ''} ${a.lastName || ''}`.replace(/  +/g, ' ');

  return (
    <Fragment>
      <div className="note-header d-flex justify-content-between mb-2">
        <span className="mr-1" data-test="enrollmentNotes_enrollmentNotesLabel">Enrollment Notes:</span>
        {enrollmentNote && (
        <div>
          <small className="text-ccm-gray mr-1" data-test="enrollmentNotes_updatedByUser">
            {getFullName(enrollmentNote.updatedByUser)}
          </small>
          <small className="text-ccm-gray" data-test="enrollmentNotes_updatedAt">
            {getDateString(enrollmentNote.updatedAt)}
          </small>
        </div>)}
      </div>

      <div className="note-body border position-relative mb-1 p-4 text-break" data-test="enrollmentNotes_SavedText">
        {enrollmentNote ? (
          enrollmentNote.text && parseDraftToHtml(enrollmentNote.text))
          : (
            <span className="text-ccm-gray">No enrollment notes have been entered.</span>
          )}
        <Button
          size="lg"
          variant="link"
          style={{ top: '5px', right: '5px' }}
          className="d-flex-center position-absolute p-0"
          onClick={() => setIsNoteModalOpen(true)}
          data-test="enrollmentNotes_editBtn"
        >
          <span className="d-flex-center bi-pencil-square" />
        </Button>

        <NoteModal
          patientId={patientId}
          initialNote={initialNote}
          isModalOpen={isNoteModalOpen}
          setIsModalOpen={setIsNoteModalOpen}
        />
      </div>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(EnrollmentNotes);
