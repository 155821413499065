// libraries
import React, { Component } from 'react';

export class Checkbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: !!props.initialValue,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;

    if (initialValue !== prevProps.initialValue) {
      this.setState({
        value: !!initialValue,
      });
    }
  }

  toggle() {
    const {
      disabled, disabledCallback, fieldKey, submitCallback,
    } = this.props;
    const { value } = this.state;

    if (!disabled) {
      this.setState({
        value: !value,
      }, () => {
        if (submitCallback) {
          submitCallback(fieldKey, !value);
        }
      });
    } else if (disabledCallback) {
      disabledCallback();
    }
  }

  render() {
    const { disabled, label, labelStyle = '' } = this.props;
    const { value } = this.state;
    const iconClassName = value ? 'checked' : 'unchecked';
    return (
      <div
        className={`custom-checkbox ${disabled ? 'custom-checkbox-disabled' : ''}`}
        onClick={this.toggle}
      >
        <div className={`icon ${iconClassName}`} />
        <div className={`label ${labelStyle}`}>{label}</div>
      </div>
    );
  }
}

export default Checkbox;
