// Libraries
import React, { useEffect, useState } from 'react';
import { Button, Nav, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import _camelCase from 'lodash/camelCase';
import _cloneDeep from 'lodash/cloneDeep';
// Actions
import { QaSearch } from '../../actions/qaSearch';
// Constants
import { STATUSES } from '../../constants/statuses';
// Services
import { isObjectsEqual } from '../../services/helpers';
// Custom Hooks
import usePrevious from '../../hooks/helpers/usePrevious';

export const QaStatusLegend = () => {
  const {
    user: { info: { permissions: userPermissions } },
    requestsInProgress: { count: loading },
    qaSearch: { searchParams: stateSearchParams },
  } = useSelector(state => state);
  const prevSearchParams = usePrevious(stateSearchParams);
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isObjectsEqual(searchParams, prevSearchParams)) {
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(stateSearchParams);
  }, [stateSearchParams]);

  const handleSearch = (params) => {
    const newParams = _cloneDeep(params);
    dispatch(QaSearch({ ...newParams }, true));
  };

  const handleSelect = (status) => {
    if (searchParams.status === status || loading) {
      return;
    }
    setSearchParams(prevParams => ({
      ...prevParams,
      status,
    }));
    handleSearch({
      ...searchParams,
      status,
    });
  };

  const handleUnselect = () => {
    if (loading) {
      return;
    }

    const updatedParams = { ...searchParams };
    delete updatedParams.status;
    setSearchParams(updatedParams);
    handleSearch(updatedParams);
  };

  const renderNavOptions = () => {
    const { status: searchKey } = searchParams;

    const renderSubstatuses = (status) => {
      const { subStatuses } = status;

      let subStatusCategoryLabel = '';
      const subStatusDropDownOptions = [];

      Object.keys(subStatuses).map((subkey, subindex) => {
        const subStatus = subStatuses[subkey];

        if (subStatus.category && (subStatus.category !== subStatusCategoryLabel)) {
          subStatusCategoryLabel = subStatus.category;
          subStatusDropDownOptions.push(
            <NavDropdown.Header key={`legend-subStatusCategory-${subindex}`}>{subStatusCategoryLabel}</NavDropdown.Header>,
          );
        }

        subStatusDropDownOptions.push(
          <NavDropdown.Item
            key={`legend-subStatusItem-${subkey}-${subindex}`}
            eventKey={subkey}
            active={searchKey === subkey}
          >
            <AvatarStatus color={status.color} icon={subkey} label={subStatus.name} />
          </NavDropdown.Item>,
        );

        return subStatusDropDownOptions;
      });

      return subStatusDropDownOptions;
    };

    const renderRemoveFilterIcon = (
      <Button variant="link" onClick={handleUnselect} className="p-0 status-remove" disabled={loading} data-test="qaStatusLegend_removeFilterIcon">
        <i className="d-flex-center bi-x" />
      </Button>
    );

    return Object.keys(STATUSES).map((key, index) => {
      const status = STATUSES[key];

      if (status.permissions && !status.permissions.some(perm => userPermissions[perm])) {
        return null;
      }

      return (status.subStatuses
        ? (
          <NavDropdown
            disabled={loading}
            key={`legend-status-${index}`}
            id={`legend-status-${_camelCase(status.name)}`}
            className={`${status.subStatuses[searchKey] ? 'status-active' : ''}`}
            title={(
              <AvatarStatus
                color={status.color}
                icon={status.subStatuses[searchKey] ? searchKey : key}
                label={status.subStatuses[searchKey]
                  ? status.subStatuses[searchKey].name : status.name}
              >
                {status.subStatuses[searchKey] && renderRemoveFilterIcon}
              </AvatarStatus>
            )}
          >
            { renderSubstatuses(status) }
          </NavDropdown>
        ) : (
          <Nav.Item
            key={`legend-status-${index}`}
            className={`${searchKey === key ? 'status-active' : ''}`}
          >
            <Nav.Link
              eventKey={key}
              disabled={loading}
              id={`legend-status-${_camelCase(status.name)}`}
            >
              <AvatarStatus
                color={status.color}
                icon={key}
                label={status.name}
              >
                {searchKey === key && renderRemoveFilterIcon}
              </AvatarStatus>
            </Nav.Link>
          </Nav.Item>
        )
      );
    });
  };

  return (
    <div className="ccm-status-legend box-wrapper d-flex-center mt-2">
      <Nav
        className="d-flex-center flex-nowrap w-100"
        onSelect={handleSelect}
        data-test="qaStatusLegend_navbar"
      >
        {renderNavOptions()}
      </Nav>
    </div>
  );
};

export const AvatarStatus = (props) => {
  const { color, icon, label } = props;
  return (
    <div className="avatar d-flex-center">
      <span className={`avatar-icon bg-ccm-${color} text-white rounded-circle mr-2`}>
        {icon}
      </span>
      <span className="avatar-label text-left">{label}</span>
      {props.children}
    </div>
  );
};

export default QaStatusLegend;
