import { USER_ROLES } from './constants';

export const INIT_HASHTAG = 'home';

export const CONFIG_NAME = 'global';

export const SUPER_USER_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  SCN: 'SCN',
  QA: 'QA',
};

export const SUPER_ADMIN_TOP_MENU_ITEMS = [
  {
    title: 'Account',
    url: 'account',
  },
  {
    title: 'Terms of Service',
    url: 'terms',
  },
];

export const SUPER_USER_MENU_ITEMS = [
  {
    title: 'Account',
    url: 'account',
    roles: [...Object.values(SUPER_USER_ROLES), USER_ROLES.ADMIN],
  },
  {
    title: 'Terms of Service',
    url: 'terms',
  },
];

export const SUPER_ROLES_FULL_NAMES = {
  SUPER_ADMIN: 'Super Administrator',
};

export const ROLES_WITH_SNOOK_ACCESS = [
  SUPER_USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, SUPER_USER_ROLES.QA, SUPER_USER_ROLES.SCN,
];
