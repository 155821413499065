// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';

// actions
import ShowNotification from '../../../actions/notification';
// constants
import { NOTIFICATION_TYPE } from '../../../constants/constants';
// services
import {
  getCNTitle,
  updateCNTitle,
  updateActivityToCarePlanPdf, getActivityToCarePlanPdf,
  getProviderTitle, updateProviderTitle,
  getProgramName, updateProgramName,
} from '../../../services/administration';
import { validateRequired } from '../../../services/helpers';
// views
import LoadingBlock from '../../menu/LoadingBlock';
import Checkbox from '../../base/Checkbox';
import EditableInput from '../../base/EditableInput';

export class CarePlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      cnTitle: '',
      providerTitle: '',
      programName: '',
      isActivityToPdfActive: false,
    };

    this.promises = {};


    this.getCNTitle = this.getCNTitle.bind(this);
    this.updateCNTitle = this.updateCNTitle.bind(this);
    this.getProviderPdfTitle = this.getProviderPdfTitle.bind(this);
    this.updateProviderPdfTitle = this.updateProviderPdfTitle.bind(this);
    this.getProgramNameApi = this.getProgramNameApi.bind(this);
    this.updateProgramNameApi = this.updateProgramNameApi.bind(this);
  }

  componentDidMount() {
    this.getCNTitle();
    this.loadActivityToCarePlan();
    this.getProviderPdfTitle();
    this.getProgramNameApi();
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  getCNTitle() {
    const { showNotification } = this.props;

    this.setState({
      loading: true,
    });

    const promiseName = 'getCNTitle';
    const getCNTitleRequest = getCNTitle();
    const getCNTitlePromise = getCNTitleRequest.promise;
    this.promises[promiseName] = getCNTitleRequest;

    getCNTitlePromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        cnTitle: data ? data.value : '',
        loading: false,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load Care Navigator title.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getProviderPdfTitle() {
    const { showNotification } = this.props;

    this.setState({
      loading: true,
    });

    const promiseName = 'getProviderTitle';
    const getProviderTitleRequest = getProviderTitle();
    const getProviderTitlePromise = getProviderTitleRequest.promise;
    this.promises[promiseName] = getProviderTitleRequest;

    getProviderTitlePromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        providerTitle: data ? data.value : '',
        loading: false,
      });
    }).catch((error) => {
      delete this.promises[promiseName];
      this.setState({
        loading: false,
      });
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: 'Could not load Care Navigator title.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  getProgramNameApi() {
    const { showNotification } = this.props;

    this.setState({
      loading: true,
    });

    const promiseName = 'getProgramName';
    const getProgramNameRequest = getProgramName();
    const getProgramNamePromise = getProgramNameRequest.promise;
    this.promises[promiseName] = getProgramNameRequest;

    getProgramNamePromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        programName: data ? data.value : '',
        loading: false,
      });
    }).catch((error) => {
      delete this.promises[promiseName];
      this.setState({
        loading: false,
      });
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: 'Could not load program name.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }


  loadActivityToCarePlan = () => {
    const { showNotification } = this.props;

    this.setState({
      loading: true,
    });

    const promiseName = 'getActivityToCarePlanPdf';
    const getActivityToCarePlanPdfRequest = getActivityToCarePlanPdf();
    const getActivityToCarePlanPdfPromise = getActivityToCarePlanPdfRequest.promise;
    this.promises[promiseName] = getActivityToCarePlanPdfRequest;

    getActivityToCarePlanPdfPromise.then((data) => {
      delete this.promises[promiseName];
      this.setState({
        isActivityToPdfActive: data.value,
        loading: false,
      });
    }).catch((error) => {
      delete this.promises[promiseName];
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      this.setState({
        loading: false,
      });
      showNotification({
        message: 'Could not get setting: time tracking activity to care plan pdf.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  updateActivityToCarePlan = () => {
    const { showNotification } = this.props;
    const { isActivityToPdfActive } = this.state;

    this.setState({
      loading: true,
    });

    const promiseName = 'updateActivityToCarePlanPdf';
    const updateActivityToCarePlanPdfRequest = updateActivityToCarePlanPdf(
      { value: !isActivityToPdfActive },
    );
    const updateActivityToCarePlanPdfPromise = updateActivityToCarePlanPdfRequest.promise;
    this.promises[promiseName] = updateActivityToCarePlanPdfRequest;

    updateActivityToCarePlanPdfPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        isActivityToPdfActive: data.value,
        loading: false,
      });
    }).catch((error) => {
      delete this.promises[promiseName];
      this.setState({
        loading: false,
      });
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not update time tracking activity to care plan pdf.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  updateCNTitle(field, value, isValid) {
    if (!isValid) {
      return;
    }

    const { showNotification } = this.props;

    const cnTitle = {
      value: value ? value.trim() : value,
    };

    const promiseName = 'updateCNTitle';
    const updateCNTitleRequest = updateCNTitle(cnTitle);
    const updateCNTitlePromise = updateCNTitleRequest.promise;
    this.promises[promiseName] = updateCNTitleRequest;

    updateCNTitlePromise.then((data) => {
      delete this.promises[promiseName];

      this.setState(state => ({
        cnTitle: data ? data.value : state.cnTitle,
      }));
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not update Care Navigator title.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  updateProviderPdfTitle(field, value, isValid) {
    if (!isValid) {
      return;
    }

    const { showNotification } = this.props;

    const providerTitle = {
      value: value ? value.trim() : value,
    };

    const promiseName = 'updateProviderTitle';
    const updateProviderTitleRequest = updateProviderTitle(providerTitle);
    const updateProviderTitlePromise = updateProviderTitleRequest.promise;
    this.promises[promiseName] = updateProviderTitleRequest;

    updateProviderTitlePromise.then((data) => {
      delete this.promises[promiseName];

      this.setState(state => ({
        providerTitle: data ? data.value : state.providerTitle,
      }));
    }).catch((error) => {
      delete this.promises[promiseName];
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: 'Could not update Provider title.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  updateProgramNameApi(field, value, isValid) {
    if (!isValid) {
      return;
    }

    const { showNotification } = this.props;

    const programName = {
      value: value ? value.trim() : value,
    };

    const promiseName = 'updateProgramName';
    const updateProgramNameRequest = updateProgramName(programName);
    const updateProgramNamePromise = updateProgramNameRequest.promise;
    this.promises[promiseName] = updateProgramNameRequest;

    updateProgramNamePromise.then((data) => {
      delete this.promises[promiseName];

      this.setState(state => ({
        programName: data ? data.value : state.programName,
      }));
    }).catch((error) => {
      delete this.promises[promiseName];
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: 'Could not update program name.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  render() {
    let content;
    const {
      loading, cnTitle, isActivityToPdfActive, providerTitle, programName,
    } = this.state;
    const { user: { isExternal } } = this.props;
    if (loading) {
      content = <LoadingBlock />;
    } else {
      content = (
        <div>

          <div className="administration-care-plan-row">
            <div className="administration-care-plan-label">Care Navigator title:</div>
            <div className="administration-care-plan-cn-title" data-test="carePlanCnTitle">
              <EditableInput
                type="text"
                submitCallback={this.updateCNTitle}
                fieldKey="cnTitle"
                initialValue={cnTitle}
                maxLength="45"
                validationCallback={validateRequired}
              />
            </div>
            <div className="administration-care-plan-cn-title-tip">
              This title will be used in printed
              version Care Plan.
            </div>
          </div>
          <div className="administration-care-plan-row">
            <div className="administration-care-plan-label">Provider title:</div>
            <div className="administration-care-plan-cn-title" data-test="carePlanProviderTitle">
              <EditableInput
                type="text"
                submitCallback={this.updateProviderPdfTitle}
                fieldKey="providerTitle"
                initialValue={providerTitle}
                maxLength="45"
                validationCallback={validateRequired}
              />
            </div>
            <div className="administration-care-plan-cn-title-tip">
              This title will be used in printed
              version Care Plan.
            </div>
          </div>
          <div className="administration-care-plan-row">
            <div className="administration-care-plan-label" data-test="carePlan_programNameLabel">Program Name:</div>
            <div className="administration-care-plan-cn-title" data-test="carePlan_programNameText">
              <EditableInput
                type="text"
                submitCallback={this.updateProgramNameApi}
                fieldKey="programName"
                initialValue={programName}
                maxLength="45"
                validationCallback={validateRequired}
                disabled={isExternal}
              />
            </div>
            <div className="administration-care-plan-cn-title-tip">
              This name will be used in patient survey texts.
            </div>
          </div>
          <div className="administration-care-plan-row">
            <div className="administration-care-plan-label">Show time tracking activity in Care Plan PDF:</div>
            <div>
              <Checkbox
                fieldKey="isActivityToPdfActive"
                initialValue={isActivityToPdfActive}
                submitCallback={this.updateActivityToCarePlan}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className="admin-section my-4"
        id="carePlan"
      >
        <h5 className="text-uppercase my-3">Care Plan</h5>
        <div className="content administration-care-plan">
          {content}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarePlan);
