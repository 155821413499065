// Libraries
import React, {
  Fragment, lazy, useEffect, useState, Suspense,
} from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
// Actions
import { UpdatePatient } from '../../actions/patient';
import ShowNotification from '../../actions/notification';
// Components
import { Loading } from '../base/Loading';
import AssignPatients from '../patients/fixedListHeader/AssignPatients';
// Services
import { getPatientProfile } from '../../services/patient';
// Constants
import {
  DATE_FORMAT, EMPTY_STRING, GENDERS, NOTIFICATION_TYPE, PES_PATIENT_CONTROL_TYPE, EMPTY_ADDRESS,
} from '../../constants/constants';
import { PATIENT_ENROLLMENT_STATUS_LABELS } from '../../constants/patientEnrollment';
import NextOutreachDate from './contents/referenceInformation/NextOutreachDate';
// Lazy
const EnrollmentHistoryModal = lazy(() => import('./EnrollmentHistoryModal'));
const PreferredPhoneModal = lazy(() => import('./PreferredPhoneModal'));


export const ReferenceInformation = (props) => {
  const { reloadPatientInfo } = props;
  const { id: patientId } = useParams();
  const {
    firstName, middleName, lastName, dateOfBirth, gender, age, ehrId, primaryPhysicians: physician,
    latestPcpVisitDate, primaryInsurance, secondaryInsurance, chronicConditions, status,
    callAttempts, recentDirectContact, recentCallAttempt, addressInfo,
    lastLoadImport, profile: { preferredName, emrPreferredName } = {},
    contactInfo: { homePhone, mobilePhone, preferredPhone } = {}, lastMailedLetterBatchDate,
  } = useSelector(state => state.patient);
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const updatePatient = data => dispatch(UpdatePatient(data));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [modalsOpened, setModalsOpened] = useState([]);

  let physicianName = 'None Specified';
  let physicianLocation = '';
  const fullName = `${firstName || ''} ${middleName || ''} ${lastName || ''}`;
  const statusDescription = PATIENT_ENROLLMENT_STATUS_LABELS[status] || '';
  const homeContact = homePhone ? homePhone.substring(1).replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1)-$2-$3') : 'Unknown';
  const mobileContact = mobilePhone ? mobilePhone.substring(1).replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1)-$2-$3') : 'Unknown';
  const preferredContact = preferredPhone ? preferredPhone.substring(1).replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1)-$2-$3') : 'Unknown';
  const formattedDate = moment(dateOfBirth, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL);
  const demographicInfo = `${GENDERS[gender] || 'N/A'}, ${age || 'N/A'} (${formattedDate})`;
  const notEmptyAddressInfo = addressInfo
    ? Object.values(addressInfo).some(e => Boolean(e)) : false;
  const address = notEmptyAddressInfo
    ? `${addressInfo.street || ''} ${`${addressInfo.city}, ` || ''} ${addressInfo.state || ''} ${addressInfo.postalCode || ''}`
    : EMPTY_ADDRESS;
  const lastOfficeDate = latestPcpVisitDate ? moment(latestPcpVisitDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.SHORT) : '';
  const primary = primaryInsurance && primaryInsurance.companyName;
  const secondary = secondaryInsurance && secondaryInsurance.companyName;
  const ccmCategories = chronicConditions && chronicConditions.length && chronicConditions.join(', ');
  const directContactLabel = recentDirectContact
    ? moment(recentDirectContact, DATE_FORMAT.FULL_WITH_TIME).format(DATE_FORMAT.SHORT)
    : EMPTY_STRING;
  const callAttemptLabel = recentCallAttempt
    ? moment(recentCallAttempt, DATE_FORMAT.FULL_WITH_TIME).format(DATE_FORMAT.SHORT)
    : EMPTY_STRING;
  const lastEmrUpdate = lastLoadImport && lastLoadImport.length > 0
    ? moment(lastLoadImport, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.SHORT)
    : 'Unknown';
  const letterMailedDate = lastMailedLetterBatchDate
    ? moment(lastMailedLetterBatchDate).format(DATE_FORMAT.SHORT)
    : EMPTY_STRING;

  if (physician && physician.length) {
    physicianName = `${physician[0].firstName || ''} ${physician[0].middleName || ''} ${physician[0].lastName || ''}`;
    physicianLocation = physician[0].location;
  }

  const loadPatientProfile = () => {
    const getProfileRequest = getPatientProfile(patientId);
    const getProfilePromise = getProfileRequest.promise;

    getProfilePromise.then((data) => {
      delete getProfileRequest.promise;
      updatePatient({ profile: data || {} });
    }).catch((error) => {
      delete getProfileRequest.promise;

      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load patient profile information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    loadPatientProfile();
  }, [patientId]);

  useEffect(() => {
    if (isModalOpen && !modalsOpened.includes('isModalOpen')) {
      setModalsOpened([...modalsOpened, 'isModalOpen']);
    }
    if (isPhoneModalOpen && !modalsOpened.includes('isPhoneModalOpen')) {
      setModalsOpened([...modalsOpened, 'isPhoneModalOpen']);
    }
  }, [isModalOpen, isPhoneModalOpen]);

  return (
    <Fragment>
      <h4 className="text-capitalize mb-4">reference information</h4>
      <div className="mb-4">
        <h5 className="text-uppercase title mb-1" data-test="referenceInformation_refInfoPatientName">{fullName}</h5>
        <p className="text-ccm-gray text-content">{statusDescription}</p>
      </div>
      <div className="mb-4">
        <h5 className="text-uppercase sub-title mb-1" data-test="referenceInformation_refInfoContact">contact</h5>
        <p className="text-content mb-0" data-test="referenceInformation_refInfoContactHome">{`Home: ${homeContact}`}</p>
        <p className="text-content mb-0" data-test="referenceInformation_refInfoContactCell">{`Cell: ${mobileContact}`}</p>
        <div className="d-flex">
          <p className="text-content mb-0" data-test="referenceInformation_refInfoContactPreferred">{`Preferred: ${preferredContact}`}</p>
          <Button
            size="lg"
            variant="link"
            className="d-flex-center p-0"
            onClick={() => setIsPhoneModalOpen(true)}
            data-test="referenceInformation_editPreferredPhone"
          >
            <span className="d-flex-center bi-pencil-square ml-1" />
          </Button>
        </div>
      </div>
      <div className="mb-4">
        <h5 className="text-uppercase sub-title mb-1" data-test="referenceInformation_refInfoDemographics">demographics</h5>
        <p className="text-content mb-0" data-test="referenceInformation_refInfoDemographicsInfo">{demographicInfo}</p>
        <p className="text-content mb-0" data-test="referenceInformation_refInfoDemographicsEhrId">{`ID: ${ehrId || 'N/A'}`}</p>
        <p className="text-content mb-0" data-test="referenceInformation_refInfoDemographicsPreferredName">{`Preferred Name: ${preferredName || 'None Specified'}`}</p>
        <p className="text-content mb-0" data-test="referenceInformation_refInfoDemographicsPreferredEHRName">{`Preferred EHR Name: ${emrPreferredName || 'None Specified'}` }</p>
        <NextOutreachDate />
        <p className="text-content mb-0" data-test="referenceInformation_homeAddress">{`Address: ${address}`}</p>
      </div>
      <div className="mb-4">
        <h5 className="text-uppercase sub-title mb-1">primary care physician</h5>
        <p className="text-content mb-0">{physicianName}</p>
        {physicianLocation && <p className="text-content mb-0">{physicianLocation}</p>}
        <p className="text-content mb-0">{`Last Office Visit: ${lastOfficeDate || 'Unknown'}`}</p>
      </div>
      <div className="mb-4">
        <h5 className="text-uppercase sub-title mb-1">insurance</h5>
        <p className="text-content mb-0">{`Primary: ${primary || 'None Specified'}`}</p>
        <p className="text-content mb-0">{`Secondary: ${secondary || 'None Specified'}`}</p>
      </div>
      <div className="mb-4">
        <h5 className="text-uppercase sub-title mb-1">ccm categories</h5>
        <p className="text-content mb-0">{ccmCategories || 'None Specified'}</p>
      </div>
      <div className="mb-4">
        <h5 className="text-uppercase sub-title mb-1">enrollment</h5>
        <p className="text-content mb-0">{`Letter mailed: ${letterMailedDate}`}</p>
        <p className="text-content mb-0">{`Call Attempts: ${callAttempts}`}</p>
        <p className="text-content mb-0">{`Last Direct: ${directContactLabel}`}</p>
        <p className="text-content mb-0">{`Last Attempt: ${callAttemptLabel}`}</p>
      </div>
      <p className="text-ccm-gray text-last-emr-update mb-4">{`LAST EMR UPDATE: ${lastEmrUpdate}`}</p>
      <Button
        className="mb-4"
        variant="primary"
        onClick={() => setIsModalOpen(true)}
        data-test="referenceInformation_downloadReport"
      >
        View Enrollment History
      </Button>
      <AssignPatients
        controlType={PES_PATIENT_CONTROL_TYPE}
        selectedPatientsIds={new Set([Number(patientId)])}
        reloadData={reloadPatientInfo}
      />
      {modalsOpened.includes('isModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          <EnrollmentHistoryModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            patientId={patientId}
          />
        </Suspense>)
      }
      {modalsOpened.includes('isPhoneModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          {isPhoneModalOpen && (
            <PreferredPhoneModal
              isModalOpen={isPhoneModalOpen}
              setIsModalOpen={setIsPhoneModalOpen}
            />
          )}
        </Suspense>)
      }
    </Fragment>
  );
};

export default ReferenceInformation;
