// Libraries
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
// Components
import { Select } from '../base/forms/Select';
// Services
import { downloadFile, fetchData } from '../../services/helpers';
import { getPhysicianTypeahead } from '../../services/providers';
// Custom Hooks
import useEnrolledPatientsProvider from '../../hooks/services/useEnrolledPatientsProvider';
// Local Constants
const FIELD_STYLES = {
  formGroup: 'd-flex w-25',
  formLabel: 'text-left mr-3 mb-0 w-50',
};
const DEFAULT_ENROLLED_PATIENTS = { physician: null };
const ENROLLED_PATIENTS_SCHEMA = () => Yup.object({
  physician: Yup.object({
    id: Yup.number('Must be a valid ID').typeError('Must be a valid ID').required('Required'),
  }).typeError('Required').required('Required'),
});

export const EnrolledPatientsProvider = () => {
  const [noResultsText, setNoResultsText] = useState('No results found...');
  const { report, refetch } = useEnrolledPatientsProvider();

  useEffect(() => {
    if (report && report.data && report.fileName) {
      downloadFile(report.data, report.fileName);
    }
  }, [report]);


  const getReport = request => refetch(request);

  const preparePhysiciansList = (physiciansData = []) => {
    const physiciansList = [];

    physiciansData.forEach((el) => {
      physiciansList.push({
        ...el,
        value: el.id,
        label: `${el.lastName ? `${el.lastName}, ` : ''}${el.firstName ? el.firstName : ''}`,
      });
    });

    physiciansList.sort((a, b) => a.label.localeCompare(b.label));

    return physiciansList;
  };

  const handlePhysicianAsyncList = async (input) => {
    if (input && input.length > 1) {
      const physicianData = await fetchData(getPhysicianTypeahead({ filter: input }));
      return { options: preparePhysiciansList(physicianData) };
    }
    return { options: [] };
  };

  return (
    <div className="reports-enrollment h-100 overflow-auto pr-3">
      <div className="reports-header d-flex align-items-center">
        <h4 className="text-left my-3 text-capitalize" data-test="enrolledPatientsProvider_headingText">enrolled patients by provider</h4>
      </div>
      <div className="container-fluid px-4 py-2">
        <Formik
          validateOnMount
          validationSchema={ENROLLED_PATIENTS_SCHEMA()}
          initialValues={DEFAULT_ENROLLED_PATIENTS}
          onSubmit={(values) => {
            getReport({ physicianId: values.physician.id });
          }}
          data-test="enrolledPatientsProvider_formikComponent"
        >
          {formik => (
            <Form>
              <div className="row w-100" data-test="enrolledPatientsProvider_physicianDropdown">
                <Select
                  async
                  id="physicianId"
                  label="Physician:"
                  name="physician"
                  noResultsText={noResultsText}
                  onInputChange={input => (input.length < 2 ? setNoResultsText('Type 2 characters min') : setNoResultsText('No results found...'))}
                  loadOptions={handlePhysicianAsyncList}
                  styles={FIELD_STYLES}
                  data-test="enrolledPatientsProvider_physicianSelect"
                />
              </div>
              <div className="row">
                <Button
                  variant="primary"
                  className="text-capitalize  mt-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="enrolledPatientsProvider_downloadBtn"
                >
                  download report
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EnrolledPatientsProvider;
