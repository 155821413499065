/* @CLINICAL_CONDITIONS_TITLES and @NON_CLINICAL_CONDITIONS_TITLES
 constants is also used as a reference for keys in templates */
export const CLINICAL_CONDITIONS_TITLES = {
  ACUTE_MYOCARDIAL_INFARCTION: {
    key: 'Acute Myocardial Infarction',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will effectively manage pain',
      'The patient will identify and implement strategies to reduce anxiety',
      'Patient will maintain blood pressure within acceptable limits set by the provider',
      'Patient will maintain daily blood pressure log',
    ],
  },
  ADHD: {
    key: 'ADHD, Conduct Disorders and Hyperkinetic Syndrome',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will implement appropriate memory retraining techniques, such as keeping calendar, writing list, memory cue games, etc.',
    ],
  },
  ALCOHOL_USE_DISORDERS: {
    key: 'Alcohol Use Disorders',
    titles: [
      'Eliminate alcohol consumption',
      'Manage alcohol triggers',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will complete 30 days alcohol free',
      'Reduce alcohol consumption',
    ],
  },
  ALZHEIMER: {
    key: 'Alzheimer\'s Disease',
    titles: [
      'Family will be able to access support groups, counseling, for assistance as needed',
      'Family will be able to identify and eliminate hazards in the patient\'s environment',
      'Family will be able to utilize information to begin making decisions for long-term plans for patient',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will be able to ambulate safely, and will not have unplanned outings',
      'Patient will groom and dress independently with minimal assistance',
      'Patient will have stable, safe environment with routine scheduling of activities to decrease anxiety and confusion',
      'Patient will remain safe from environmental hazards resulting from cognitive impairment',
    ],
  },
  ALZHEIMER_RELATED_OR_SENILE_DEMENTIA: {
    key: 'Alzheimer\'s Disease, Related Disorders or Senile Dementia',
    titles: [
      'Family will be able to access support groups, counseling, for assistance as needed',
      'Family will be able to identify and eliminate hazards in the patient\'s environment',
      'Family will be able to utilize information to begin making decisions for long-term plans for patient',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will be able to ambulate safely, and will not have unplanned outings',
      'Patient will groom and dress independently with minimal assistance',
      'Patient will have stable, safe environment with routine scheduling of activities to decrease anxiety and confusion',
      'Patient will remain safe from environmental hazards resulting from cognitive impairment',
    ],
  },
  ANEMIA: {
    key: 'Anemia',
    titles: [
      'Patient will add three foods high in iron, B-12, and folic acid to their diet',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
    ],
  },
  ANXIETY_DISORDERS: {
    key: 'Anxiety Disorders',
    titles: [
      'Find an accountability partner for exercise or other activities',
      'Identify helpful coping strategies',
      'Identify stressful triggers',
      'Increase daily activity as tolerated',
      'Increase daily activity challenge',
      'Join a silver sneakers or other senior exercise program',
      'Maintain healthy sleep patterns',
      'Maintain regular visits with mental health specialist',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Practice daily meditation',
      'Practice deep breathing exercises',
      'Schedule intro visit with a mental health specialist',
      'Share a positive thought',
      'Talk through concerns with a trusted person',
      'Use a fitness tracker to monitor & gradually increase step counts (with approval from provider)',
    ],
  },
  ASTHMA: {
    key: 'Asthma',
    titles: [
      'Follow nutritious diet',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Practice pursed lip breathing and deep breathing exercises daily',
      'Remove environmental triggers from living environment (dust, chemicals, etc)',
      'Wear supplemental O2 as ordered by provider',
    ],
  },
  ATRIAL_FIBRILLATION: {
    key: 'Atrial Fibrillation',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'Patient will maintain blood pressure within acceptable limits set by the provider',
      'Take all medications as prescribed, at the same time daily',
      'The patient will identify and implement strategies to reduce anxiety',
    ],
  },
  AUTISM: {
    key: 'Autism Spectrum Disorders',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will establish a method to communicate clearly to meet their needs',
      'Patient will learn social and communication skills to improve relationships',
      'Patient will participate in activities with others that align with their interests and mental/physical capabilities',
      'Patient will utilize devices and equipment to augment verbal communication as instructed by provider',
    ],
  },
  BENIGN_PROSTATIC_HYPERPLASIA: {
    key: 'Benign Prostatic Hyperplasia',
    titles: [
      'Limit caffeine and alcohol as these may stimulate the urge to urinate',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will be able to sleep/rest appropriately',
    ],
  },
  BIPOLAR_DISORDER: {
    key: 'Bipolar Disorder',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will find one or two solitary activities that can help relieve tensions and minimize the escalation of anxiety',
      'The patient will seek help when experiencing aggressive impulses',
      'The patient will sleep six to eight hours per night',
    ],
  },
  CANCER_ENDOMETRIAL: {
    key: 'Cancer, Endometrial',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will implement two strategies to ease pain',
    ],
  },
  CANCER_BREAST: {
    key: 'Cancer, Breast',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will implement two strategies to ease pain',
    ],
  },
  CANCER_COLORECTAL: {
    key: 'Cancer, Colorectal',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will implement two strategies to ease pain',
    ],
  },
  CANCER_LUNG: {
    key: 'Cancer, Lung',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will implement two strategies to ease pain',
    ],
  },
  CANCER_PROSTATE: {
    key: 'Cancer, Prostate',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will implement two strategies to ease pain',
    ],
  },
  CANCER_UROLOGIC: {
    key: 'Cancer, Urologic (Kidney, Renal Pelvis, and Ureter)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will implement two strategies to ease pain',
    ],
  },
  CATARACT: {
    key: 'Cataract',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient and/or family will be able to modify the environment to ensure patient safety',
      'The patient will be able to use adaptive devices to compensate for visual loss as ordered by the provider',
    ],
  },
  CEREBRAL_PALSY: {
    key: 'Cerebral Palsy',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will demonstrate independence in certain activities of daily living (ADLs)',
    ],
  },
  CHRONIC_KIDNEY_DISEASE: {
    key: 'Chronic Kidney Disease',
    titles: [
      'Follow renal diet as directed by the provider',
      'Maintain blood glucose levels within range limits set by the provider',
      'Maintain daily blood pressure log',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will maintain blood pressure within acceptable limits set by the provider',
    ],
  },
  COPD: {
    key: 'COPD',
    titles: [
      'Follow nutritious diet',
      'Maintain SpO2 reading as set by provider',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Practice pursed lip breathing and deep breathing exercises daily',
      'Remove environmental triggers from living environment (dust, chemicals, etc)',
      'Wear supplemental O2 as ordered by provider',
    ],
  },
  CYSTIC_FIBROSIS: {
    key: 'Cystic Fibrosis and Other Metabolic Development Disorders',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'Remove environmental triggers from living environment (dust, chemicals, etc)',
    ],
  },
  DEPRESSION: {
    key: 'Depression',
    titles: [
      'Avoid negative stereotypes and self-talk that can impact self esteem',
      'Eliminate or reduce alcohol consumption, as recommended by your provider',
      'Identify and avoid stressful triggers',
      'Identify helpful coping strategies',
      'Increase daily activity as tolerated',
      'Maintain regular visits with mental health specialist',
      'Mind stimulation',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Practice daily meditation',
      'Practice deep breathing exercises',
      'Schedule intro visit with a mental health specialist',
      'Share a positive thought',
      'Take all medications as prescribed, at the same time daily',
      'Talk through concerns with a trusted person',
      'Use a pill organizer to improve medication compliance',
    ],
  },
  DEPRESSION_DISORDERS: {
    key: 'Depressive Disorders',
    titles: [
      'Avoid negative stereotypes and self-talk that can impact self esteem',
      'Eliminate or reduce alcohol consumption, as recommended by your provider',
      'Identify and avoid stressful triggers',
      'Identify helpful coping strategies',
      'Increase daily activity as tolerated',
      'Maintain regular visits with mental health specialist',
      'Mind stimulation',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Practice daily meditation',
      'Practice deep breathing exercises',
      'Schedule intro visit with a mental health specialist',
      'Share a positive thought',
      'Take all medications as prescribed, at the same time daily',
      'Talk through concerns with a trusted person',
      'Use a pill organizer to improve medication compliance',
    ],
  },
  DIABETES: {
    key: 'Diabetes',
    titles: [
      'Eat more fruits and vegetables',
      'Limit sugary drinks/snacks',
      'Maintain blood glucose levels within range as set by provider',
      'Maintain daily blood glucose log',
      'Maintain healthy sleep patterns',
      'Maintain Healthy Weight/BMI',
      'Make healthy choices when eating out',
      'Make healthy food choices',
      'Obtain annual eye exam',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Reduce fast-food consumption',
      'Substitute lean proteins',
    ],
  },
  DRUG_USE_DISORDERS: {
    key: 'Drug Use Disorders',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will demonstrate active participation in a rehabilitation program',
      'The patient will engage in the planning and implementation of the treatment regimen related to substance abuse',
      'The patient will regain and maintain a healthy state with a drug-free lifestyle',
    ],
  },
  EPILEPSY: {
    key: 'Epilepsy',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will modify their environment to prevent injuries from seizures',
      'Take all medications as prescribed, at the same time daily',
    ],
  },
  FIBROMYALGIA: {
    key: 'Fibromyalgia, Chronic Pain and Fatigue',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will maintain acceptable pain level',
      'Patient\'s environment will remain free from increased pain triggers',
      'The patient will implement two strategies to ease pain',
    ],
  },
  GLAUCOMA: {
    key: 'Glaucoma',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will carry a medical identification card or wear a bracelet stating their type of glaucoma and need for medication',
      'Patient will carry prescribed medications at all times',
      'Patient will take extra precautions at night to reduce the risk of falls/injury',
      'Take all medications as prescribed, at the same time daily',
      'The patient will identify and implement strategies to reduce anxiety',
    ],
  },
  HEART_FAILURE: {
    key: 'Heart Failure',
    titles: [
      'Follow fluid restriction parameters as directed by provider',
      'Follow low sodium diet',
      'Monitor daily weights',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Reduce lower extremity edema',
      'Wear supplemental O2 as ordered by provider',
    ],
  },
  HEPATITIS_A: {
    key: 'Hepatitis A',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The client will initiate necessary lifestyle changes and participate in treatment regimen',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
      'The patient will practice good hygiene, including thorough hand washing',
    ],
  },
  HEPATITIS_B: {
    key: 'Hepatitis B (acute or unspecified)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
    ],
  },
  HEPATITIS_B_CHRONIC: {
    key: 'Hepatitis B (chronic)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
    ],
  },
  HEPATITIS_C_ACUTE: {
    key: 'Hepatitis C (acute)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
    ],
  },
  HEPATITIS_C_CHRONIC: {
    key: 'Hepatitis C (chronic)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
    ],
  },
  HEPATITIS_C_UNSPECIFIED: {
    key: 'Hepatitis C (unspecified)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
    ],
  },
  HEPATITIS_D: {
    key: 'Hepatitis D',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
    ],
  },
  HEPATITIS_E: {
    key: 'Hepatitis E',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will perform ADLs and participate in desired activities at the level of ability',
    ],
  },
  HIP_PELVIC_FRACTURE: {
    key: 'Hip/Pelvic Fracture',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will maintain normal bowel elimination patterns',
      'The patient will effectively manage pain',
    ],
  },
  HIV_AIDS: {
    key: 'Human Immunodeficiency Virus and/or Acquired Immunodeficiency Syndrome (HIV/AIDS)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Take all medications as prescribed, at the same time daily',
      'The patient will consume an ideal amount of calories for height/weight as set by provider or dietitian',
    ],
  },
  HYPERLIPIDEMIA: {
    key: 'Hyperlipidemia',
    titles: [
      'Attend all scheduled appointments with your provider',
      'Eat more fruits and vegetables',
      'Increase daily activity as tolerated',
      'Limit sugary drinks/snacks',
      'Maintain Healthy Weight/BMI',
      'Make healthy choices when eating out',
      'Make healthy food choices',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Reduce fast-food consumption',
      'Substitute lean proteins',
      'Take all medications as prescribed, at the same time daily',
    ],
  },
  HYPERTENSION: {
    key: 'Hypertension',
    titles: [
      'Attend all scheduled appointments with your provider',
      'Eat more fruits and vegetables',
      'Find an accountability partner for exercise or other activities',
      'Increase daily activity as tolerated',
      'Increase daily activity challenge',
      'Join a silver sneakers or other senior exercise program',
      'Limit sugary drinks/snacks',
      'Maintain Healthy Weight/BMI',
      'Make healthy choices when eating out',
      'Make healthy food choices',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will maintain blood pressure within acceptable limits set by the provider',
      'Practice daily meditation',
      'Practice deep breathing exercises',
      'Reduce fast-food consumption',
      'Substitute lean proteins',
      'Use a fitness tracker to monitor & gradually increase step counts (with approval from provider)',
    ],
  },
  HYPOTHYROIDISM: {
    key: 'Hypothyroidism',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will create an appropriate plan of meals based on the recommended nutrition guidelines for hypothyroidism',
      'Take all medications as prescribed, at the same time daily',
    ],
  },
  INTELLECTUAL_DISABILITIES: {
    key: 'Intellectual Disabilities and Related Conditions',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will be able to maintain effective social interaction with others',
      'Patient will groom and dress independently with minimal assistance',
    ],
  },
  ISCHEMIC_HEART_DISEASE: {
    key: 'Ischemic Heart Disease',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'Patient will maintain blood pressure within acceptable limits set by the provider',
    ],
  },
  LEARNING_DISABILITIES: {
    key: 'Learning Disabilities',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will be able to maintain effective social interaction with others',
    ],
  },
  LEUKEMIAS_LYMPHOMAS: {
    key: 'Leukemias and Lymphomas',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'The patient will implement two strategies to ease pain',
    ],
  },
  LIVER_DISEASE: {
    key: 'Liver Disease, Cirrhosis and Other Live Conditions (except Viral Hepatitis)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will initiate lifestyle behaviors to prevent recurrence',
      'The patient will maintain skin integrity',
    ],
  },
  MIGRAINE_CHRONIC_HEADACHE: {
    key: 'Migraine and Chronic Headache',
    titles: [
      'Identify helpful coping strategies and create plan to implement them',
      'Identify stressful triggers and create plan to avoid them',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will be able to complete daily tasks without disruption due to pain',
    ],
  },
  MOBILITY_IMPAIRMENTS: {
    key: 'Mobility Impairments',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will participate in rehabilitation and physical therapy as prescribed',
      'Patient/family understand basic measures on how to prevent falls',
      'The patient will utilize mobility aids to improve physical mobility and ambulation as prescribed by provider',
    ],
  },
  MULTIPLE_SCLEROSIS: {
    key: 'Multiple Sclerosis and Transverse Myelitis',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will participate in rehabilitation and physical therapy as prescribed',
      'Patient/family understand basic measures on how to prevent falls',
      'The patient will utilize mobility aids to improve physical mobility and ambulation as prescribed by provider',
    ],
  },
  MUSCULAR_DYSTROPHY: {
    key: 'Muscular Dystrophy',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will participate in rehabilitation and physical therapy as prescribed',
      'Patient/family understand basic measures on how to prevent falls',
      'The patient will utilize mobility aids to improve physical mobility and ambulation as prescribed by provider',
    ],
  },
  OBESITY: {
    key: 'Obesity',
    titles: [
      'Eat more fruits and vegetables',
      'Find an accountability partner for exercise or other activities',
      'Identify stressful triggers that result in overeating',
      'Increase daily activity as tolerated',
      'Increase daily activity challenge',
      'Join a silver sneakers or other senior exercise program',
      'Limit sugary drinks/snacks',
      'Maintain adequate water intake',
      'Maintain healthy sleep patterns',
      'Maintain Healthy Weight/BMI',
      'Make healthy choices when eating out',
      'Make healthy food choices',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will seek support groups/therapy as prescribed by provider or dietitian',
      'Prevent choking',
      'Reduce fast-food consumption',
      'Substitute lean proteins',
      'Use a fitness tracker to monitor & gradually increase step counts (with approval from provider)',
    ],
  },
  OSTEOPOROSIS: {
    key: 'Osteoporosis',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Take all medications as prescribed, at the same time daily',
      'The patient will focus on consuming an adequate intake of calcium and vitamin D as directed by their provider',
      'The patient will regain lost muscle mass and strength through physical activity, particularly weight bearing activities',
    ],
  },
  OTHER_DEVELOPMENT_DELAYS: {
    key: 'Other Development Delays',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will be able to maintain effective social interaction with others',
    ],
  },
  PARKINSON_DISEASE: {
    key: 'Parkinson\'s Disease and Secondary Parkinsonism',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'Take all medications as prescribed, at the same time daily',
      'The patient will identify factors that elicit depressive reactions and use techniques that will effectively reduce the amount and frequency of these episodes',
      'The patient\'s family will ensure safety precautions are instituted and followed as prescribed by provider',
    ],
  },
  PERIPHERAL_VASCULAR_DISEASE: {
    key: 'Peripheral Vascular Disease (PVD)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will increase physical activity without experiencing pain',
    ],
  },
  PERSONALITY_DISORDERS: {
    key: 'Personality Disorders',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will attend therapy sessions as prescribed by provider',
      'Take all medications as prescribed, at the same time daily',
      'The patient will verbalize control of feelings',
    ],
  },
  PNEUMONIA: {
    key: 'Pneumonia, All-cause',
    titles: [
      'Encourage the patient to cough and deep breathe, using spirometer as directed',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will follow a nutritious diet',
      'Take all medications as prescribed, at the same time daily',
      'Teach the patient how to recognize signs of complications (high fever, worsening shortness of breath) and to contact provider immediately if they occur',
      'Wear supplemental O2 as ordered by provider',
    ],
  },
  POST_TRAUMATIC_STRESS_DISORDER: {
    key: 'Post-Traumatic Stress Disorder (PTSD)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will display a desire for effective coping as evidenced by asking for help and reaching out to a support person/group',
      'Patient will participate in self-care activities',
    ],
  },
  PRESSURE_CHRONIC_ULCERS: {
    key: 'Pressure and Chronic Ulcers',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will keep wound care dressings intact to prevent the entrance of bacteria',
      'The patient will avoid further development of preventable pressure injuries',
    ],
  },
  RHEUMATOID_ARTHRITIS_OSTEOARTHRITIS: {
    key: 'Rheumatoid Arthritis/Osteoarthritis',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will identify and implement measures to prevent injury',
      'The patient will implement adaptive changes that promote ambulation and transferring',
      'The patient will use pharmacological and nonpharmacological pain relief strategies',
    ],
  },
  SCHIZOPHRENIA: {
    key: 'Schizophrenia',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Take all medications as prescribed, at the same time daily',
      'The patient will learn one or two diversionary tactics that work for him/her to decrease anxiety',
    ],
  },
  SCHIZOPHRENIA_AND_OTHER_PSYCHOTIC_DISORDERS: {
    key: 'Schizophrenia and Other Psychotic Disorders',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Take all medications as prescribed, at the same time daily',
      'The patient will learn one or two diversionary tactics that work for him/her to decrease anxiety',
    ],
  },
  SENSORY_BLINDNESS: {
    key: 'Sensory- Blindness and Visual Impairment',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient and/or family will be able to modify the environment to ensure patient safety',
    ],
  },
  SENSORY_DEAFNESS: {
    key: 'Sensory- Deafness and Hearing Impairment',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Patient will utilize devices and equipment to augment communication as prescribed by provider',
    ],
  },
  SICKLE_CELL_DISEASE: {
    key: 'Sickle Cell Disease',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will participate in ADLs without weakness and fatigue',
      'The patient will participate in behaviors to reduce risk factors/skin breakdown',
    ],
  },
  SPINA_BIFIDA: {
    key: 'Spina Bifida and Other Congenital Anomalies of the Nervous System',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will prioritize maintaining skin integrity',
    ],
  },
  SPINAL_CORD_INJURY: {
    key: 'Spinal Cord Injury',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will demonstrate the use of relaxation skills and diversional activities as individually indicated',
      'The patient will increase the strength of unaffected/compensatory body parts',
      'The patient will participate in group therapy or counseling sessions as prescribed by provider',
    ],
  },
  STROKE_TRANSIENT: {
    key: 'Stroke/Transient Ischemic Attack',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'The patient will establish a method of communication in which needs can be expressed',
    ],
  },
  TOBACCO_USE_CLINICAL: {
    key: 'Tobacco Use',
    titles: [
      'Chewing tobacco cessation',
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Smoking cessation',
      'Vaping cessation',
    ],
  },
  TRAUMATIC_BRAIN_INJURY: {
    key: 'Traumatic Brain Injury and Nonpsychotic Mental Disorders due to Brain Damage',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Take all medications as prescribed, at the same time daily',
    ],
  },
  VIRAL_HEPATITIS: {
    key: 'Viral Hepatitis (General)',
    titles: [
      'Patient will adhere to treatment plan and schedule, as recommended by the provider',
      'Take all medications as prescribed, at the same time daily',
    ],
  },
};

export const NON_CLINICAL_CONDITIONS_TITLES = {
  ALCOHOL_USE: {
    key: 'Alcohol Use',
    titles: [
      'Eliminate alcohol consumption',
      'Reduce alcohol consumption',
      'Patient will complete 30 days alcohol free',
      'Manage alcohol triggers',
    ],
  },
  HEALTH_COMPLIANCE: {
    key: 'Health Compliance',
    titles: [
      'Attend all scheduled appointments with your provider(s)',
      'Take all medications as prescribed, at the same time daily',
      'Use a pill organizer to improve medication compliance',
      'Stay up-to-date on vaccinations',
      'Request refills in a timely manner to prevent med lapses',
      'Maintain Healthy Weight/BMI',
    ],
  },
  HEALTHY_EATING: {
    key: 'Healthy Eating',
    titles: [
      'Eat more fruits and vegetables',
      'Reduce fast-food consumption',
      'Substitute lean proteins',
      'Make healthy choices when eating out',
      'Make healthy food choices',
      'Prevent choking',
      'Maintain Healthy Weight/BMI',
      'Limit sugary drinks/snacks',
    ],
  },
  HYDRATION: {
    key: 'Hydration',
    titles: [
      'Maintain adequate water intake',
      'Limit caffeinated beverages',
    ],
  },
  MINDSET: {
    key: 'Mindset',
    titles: [
      'Avoid stressful triggers',
      'Practice daily meditation',
      'Practice deep breathing exercises',
      'Identify helpful coping strategies',
      'Schedule intro visit with a mental health specialist',
      'Maintain regular visits with mental health specialist',
      'Avoid negative stereotypes and self-talk that can impact self esteem',
      'Talk through concerns with a trusted person',
      'Share a positive thought',
      'Mind stimulation',
      'Decrease Migraine Frequency',
    ],
  },
  PHYSICAL_ACTIVITY: {
    key: 'Physical Activity',
    titles: [
      'Increase daily activity as tolerated',
      'Find an accountability partner for exercise or other activities',
      'Join a silver sneakers or other senior exercise program',
      'Use a fitness tracker to monitor & gradually increase step counts (with approval from provider)',
      'Increase daily activity challenge',
      'Practice gentle stretching or Yoga',
    ],
  },
  SAFETY: {
    key: 'Safety',
    titles: [
      'Physical surroundings are free from trip hazards',
      'Patient understands how to safely ambulate within their home',
      'Store frequently-used items on lower shelves in the kitchen',
      'Avoid environmental exposure with cleaning, home projects, or during air quality warnings',
      'Sun safety',
      'Prevent choking',
      'Use equipment (DME) walker as prescribed',
      'Use equipment (DME) cane as prescribed',
    ],
  },
  SLEEP_PATTERNS: {
    key: 'Sleep Patterns',
    titles: [
      'Maintain healthy sleep patterns',
      'Get better sleep challenge',
    ],
  },
  SOCIAL_CONNECTIONS: {
    key: 'Social Connections',
    titles: [
      'Start a new hobby, or take up an old one',
      'Join a club or group',
      'Explore online message boards/social groups related to your interest',
      'Take a class on a topic that interests you',
      'Check in on a friend or family member that you haven\'t spoken with for a while',
    ],
  },
  TOBACCO_USE: {
    key: 'Tobacco Use',
    titles: [
      'Smoking cessation',
      'Vaping cessation',
      'Chewing tobacco cessation',
    ],
  },
};

export const TYPE_OPTIONS = isLifeStyleOnly => [
  { value: 'lifestyle', label: 'Lifestyle' },
  ...(isLifeStyleOnly ? [] : [{ value: 'clinical', label: 'Clinical Condition' }]),
];

export const OTHER_CONDITION = { name: 'Other', type: 'NON_CLINICAL' };
