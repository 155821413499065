// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
// actions
import ShowNotification from '../../actions/notification';
// constants
import { CHANGE_PASSWORD_ERRORS, DIALOG_DEFAULTS, NOTIFICATION_TYPE } from '../../constants/constants';
// services
import { changePassword } from '../../services/amazon-cognito';
import { validatePassword } from '../../services/helpers';
import { makeCancelablePromise } from '../../services/cancelablePromise';
// views
import LabeledInput from '../base/LabeledInput';
import LoadingBlock from '../menu/LoadingBlock';
import { setLastPasswordUpdate } from '../../services/login';

export class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modalOpened: false,
      errorText: '',
      form: {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      },
    };

    this.refs = {};
    this.promises = {};

    this.handleChange = this.handleChange.bind(this);
    this.saveRef = this.saveRef.bind(this);
    this.validate = this.validate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  handleChange(event) {
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.name]: event.target.value,
      },
    }));
  }

  saveRef(name, ref) {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  }

  validate() {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      result = this.refs[key].validate() && result;
    });

    return result;
  }

  openDialog() {
    this.setState({
      modalOpened: true,
    });
  }

  validateConfirmPassword() {
    const { form } = this.state;

    if (form.password !== form.confirmPassword) {
      return 'do not match';
    }

    return false;
  }

  handleCloseModal() {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: false,
      modalOpened: false,
      errorText: '',
      form: {
        oldPassword: '',
        password: '',
        confirmPassword: '',
      },
    });
  }

  changePassword(event) {
    event.preventDefault();

    const { loading } = this.state;

    if (loading || !this.validate()) {
      return;
    }

    const { showNotification } = this.props;
    const { form } = this.state;

    this.setState({
      loading: true,
      errorText: '',
    });

    const promiseName = 'changePassword';
    const changePasswordRequest = makeCancelablePromise(
      changePassword(form.oldPassword, form.password),
    );
    const changePasswordPromise = changePasswordRequest.promise;
    this.promises[promiseName] = changePasswordRequest;

    changePasswordPromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
        modalOpened: false,
        errorText: '',
        form: {
          oldPassword: '',
          password: '',
          confirmPassword: '',
        },
      });

      showNotification({
        message: 'Password was changed successfully.',
        autoHide: true,
        notificationType: 'SUCCESS',
      });

      this.handleLastPasswordUpdate();
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
        errorText: CHANGE_PASSWORD_ERRORS[error.code] || CHANGE_PASSWORD_ERRORS.defaultError,
      });
    });
  }

  handleLastPasswordUpdate() {
    const { showNotification } = this.props;

    const promiseName = 'setLastPasswordUpdate';
    const setLastPasswordUpdateRequest = setLastPasswordUpdate();
    const setLastPasswordUpdatePromise = setLastPasswordUpdateRequest.promise;
    this.promises[promiseName] = setLastPasswordUpdateRequest;

    return setLastPasswordUpdatePromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: error.message,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const {
      loading, errorText, modalOpened, form,
    } = this.state;
    let loadingBlock;

    if (loading) {
      loadingBlock = <LoadingBlock />;
    }

    let errorBlock = null;
    if (errorText && errorText.length) {
      errorBlock = (
        <div className="error-hint">
          Validation error:
          {' '}
          {errorText}
        </div>
      );
    }

    return (
      <div>
        <Button
          data-test="changePassword_changePasswordButton"
          variant="light"
          onClick={this.openDialog}
        >
          Change password
        </Button>
        <Modal
          isOpen={modalOpened}
          style={DIALOG_DEFAULTS}
          onRequestClose={this.handleCloseModal}
        >
          <div className="simple-dialog small-dialog">
            <div className="dialog-title" data-test="changePassword_changePasswordModalTitle">
              Change Password
              <div
                className="close-icon i-close"
                data-test="changePassword_changePasswordModalClose"
                onClick={this.handleCloseModal}
              />
            </div>
            <div className="dialog-content" data-test="changePassword_changePasswordModalContent">
              {errorBlock}
              {loadingBlock}
              <form
                className="form-container"
                onSubmit={this.changePassword}
              >
                <div className="form-row" data-test="changePassword_oldPassword">
                  <LabeledInput
                    ref={this.saveRef.bind(this, 'oldPassword')}
                    label="Old Password"
                    data-test="changePassword_changePasswordModalOldPassword"
                    validateCallback={validatePassword}
                    type="password"
                    name="oldPassword"
                    value={form.oldPassword}
                    handleChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-row" data-test="changePassword_newPassword">
                  <LabeledInput
                    ref={this.saveRef.bind(this, 'password')}
                    label="New Password"
                    data-test="changePassword_changePasswordModalNewPassword"
                    validateCallback={validatePassword}
                    type="password"
                    name="password"
                    value={form.password}
                    handleChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-row" data-test="changePassword_repeatPassword">
                  <LabeledInput
                    ref={this.saveRef.bind(this, 'confirmPassword')}
                    label="Repeat Password"
                    data-test="changePassword_changePasswordModalRepeatPassword"
                    validateCallback={this.validateConfirmPassword}
                    type="password"
                    name="confirmPassword"
                    value={form.confirmPassword}
                    handleChange={this.handleChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="hidden"
                />
              </form>
            </div>
            <div className="dialog-buttons justify-content-end px-4">
              <Button
                variant="light"
                data-test="changePassword_changePasswordModalCancel"
                onClick={this.handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ml-2"
                data-test="changePassword_changePasswordButtonInModal"
                onClick={this.changePassword}
              >
                Change password
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(ChangePassword);
