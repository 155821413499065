// libraries
import React from 'react';

export function ProgressBar(props) {
  const { duration, max, isPending } = props;
  const percentage = Math.min(duration / max, 1) * 100;
  const width = `${percentage}%`;
  const pendingClass = isPending ? 'pending' : 'approved';

  let className = pendingClass;
  if (percentage >= 99) {
    className = `${pendingClass} full`;
  }

  return (
    <div className="line">
      <div
        style={{ width }}
        className={className}
      />
    </div>
  );
}

export default ProgressBar;
