// Libraries
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Select from 'react-select/lib/Select';
// Constants
import {
  NOTIFICATION_TYPE,
  TENANT_FEATURES_NAMES,
} from '../../../../constants/constants';
// Services
import { getPhysiciansShort } from '../../../../services/providers';
import { getPatientSummary } from '../../../../services/patient';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdatePatient } from '../../../../actions/patient';

export function PhysicianAsyncList(props) {
  const {
    patient, patient: { billing: { billingInfo } = {} } = {},
    patientId, updatePatient, showNotification,
  } = props;
  const [isPcpPhysicianExist, setIsPcpPhysicianExist] = useState(false);
  const [physicians, setPhysicians] = useState([]);
  const physicianSelectPlaceholder = isPcpPhysicianExist ? 'Physician' : '(no PCP in EMR)';
  const [pcp, setPcp] = useState(null);
  const features = useSelector(state => state.tenant.features);

  const providerGroupFeature = features && features.length > 0
    && features.find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_MULTI_TELEHEALTH.name);
  const filterByProviderGroup = providerGroupFeature && providerGroupFeature.enabled;

  const fetchPatientSummary = async () => {
    const getPatientSummaryRequest = getPatientSummary(patientId);

    try {
      const data = await getPatientSummaryRequest.promise;
      delete getPatientSummaryRequest.promise;

      if (data && data.primaryPhysicians && data.primaryPhysicians.length) {
        setIsPcpPhysicianExist(true);
      }
      updatePatient(data);
    } catch (error) {
      delete getPatientSummaryRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load patient summary',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const getPhysicianList = async () => {
    const getPhysiciansShortRequest = getPhysiciansShort();
    const getPhysiciansShortPromise = getPhysiciansShortRequest.promise;

    try {
      const data = await getPhysiciansShortPromise;
      delete getPhysiciansShortRequest.promise;
      setPhysicians(data);
    } catch (error) {
      delete getPhysiciansShortRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load physicians list',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const processPhysiciansList = (list = [], providerGroup) => {
    const filteredList = filterByProviderGroup
      ? list.filter(physician => physician && physician.assignerCode === providerGroup)
      : list;

    return [
      ...filteredList.map(physician => ({
        ...physician,
        value: physician.id,
        label: (`${physician.firstName || ''} ${physician.middleName || ''} ${physician.lastName || ''}`)
          .trim()
          .replace(/  +/g, ' '),
      })),
    ];
  };

  const updatePhysicianById = (physician) => {
    updatePatient({
      billing: {
        ...patient.billing,
        billingInfo: {
          ...billingInfo,
          physicianId: physician ? physician.id : null,
          physicianDisplayName: `${physician.firstName}${physician.lastName ? ` ${physician.lastName}` : ''}`,
        },
      },
    });
  };

  const getFirstMatchPCP = () => {
    if (patient && patient.primaryPhysicians && patient.primaryPhysicians.length > 0) {
      const matchedPCP = patient.primaryPhysicians.filter(p => p.active && p.billable);
      if (matchedPCP.length > 0) {
        const physician = matchedPCP[0];
        if (!physician.active || !physician.billable) return '';
        const physicianFullName = `${physician.firstName}${physician.lastName ? ` ${physician.lastName}` : ''}`;
        if (pcp === null) setPcp(physician);
        return { label: physicianFullName };
      }
    }
    return '';
  };

  const getSelectedPhysician = (phId) => {
    if (!phId) return getFirstMatchPCP();
    const physicianName = billingInfo.physicianDisplayName;
    const physiciansOptions = processPhysiciansList(physicians, patient.assignerCode);
    return physiciansOptions.find(ph => ph.id === phId)
      || { label: physicianName };
  };

  const validatePhysicianExist = (physician) => {
    const physicianId = physician ? physician.id : null;
    let isPhysicianIdExist = false;
    physicians.filter(n => n).forEach((ph) => {
      if (ph.id === physicianId) {
        isPhysicianIdExist = true;
      }
    });
    return isPhysicianIdExist;
  };

  const updatePhysician = (physician) => {
    if (validatePhysicianExist(physician)) {
      updatePhysicianById(physician);
    }
  };

  useEffect(() => {
    if (pcp !== null && !billingInfo.physicianId) {
      updatePhysicianById(pcp);
    }
  }, [pcp, billingInfo]);

  useEffect(() => {
    if (
      patient && (!patient.primaryPhysicians
        || (patient.primaryPhysicians && !patient.primaryPhysicians.length))
    ) {
      fetchPatientSummary();
    }
    getPhysicianList();
  }, []);

  return (
    <Select
      name="physician-select"
      placeholder={physicianSelectPlaceholder}
      noResultsText="Physician not found..."
      clearable={false}
      value={getSelectedPhysician((billingInfo && billingInfo.physicianId) || null)}
      searchable
      options={processPhysiciansList(physicians, patient?.assignerCode)}
      onChange={updatePhysician}
      required
      data-test="physicianAsyncList_AsyncPaginate"
    />);
}

export function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PhysicianAsyncList);
