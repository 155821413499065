// Libraries
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
// Config
import { store as appStore } from '../config/store';
// Constants
import { MFA_TYPES, SIGN_IN_ERROR_CODES } from '../constants/constants';

let cognitoUser = null;
let userPool = null;

const initUserPool = (tenant) => {
  userPool = new CognitoUserPool({
    UserPoolId: tenant.identityPoolId,
    ClientId: tenant.clientId,
  });
};

export function getCurrentCognitoUser() {
  const { tenant } = appStore.getState();

  initUserPool(tenant);

  cognitoUser = userPool.getCurrentUser();
}

export function signIn(credentials, tenant, setValidateTotp, showErrorMessage) {
  if (!tenant) {
    return Promise.reject({
      message: 'The page you are trying to open does not exist. Please be sure the page address is correct.',
    });
  }

  initUserPool(tenant);

  return new Promise((resolve, reject) => {
    const { username: Username, password: Password } = credentials;
    const authenticationData = {
      Username,
      Password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username,
      Pool: userPool,
    };
    cognitoUser = new CognitoUser(userData);

    let resultAuthData = {};

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess(result) {
        resultAuthData = {
          authData: result,
        };
        resolve(resultAuthData);
      },
      onFailure(authErr) {
        reject({
          ...authErr,
          code: SIGN_IN_ERROR_CODES.INVALID_CREDENTIALS,
        });
      },
      totpRequired: () => {
        setValidateTotp(() => (challengeAnswer) => {
          if (!challengeAnswer) {
            reject({
              code: SIGN_IN_ERROR_CODES.CANCELED_TOTP,
              message: 'Multi Factor Authentication canceled.',
            });
            return;
          }
          cognitoUser.sendMFACode(challengeAnswer, {
            onSuccess(result) {
              resultAuthData = {
                authData: result,
              };
              resolve(resultAuthData);
            },
            onFailure(mfaErr) {
              showErrorMessage({
                ...mfaErr,
                code: SIGN_IN_ERROR_CODES.INVALID_TOTP,
              });
            },
          }, MFA_TYPES.APP_MFA);
        });
      },
      newPasswordRequired() {
        reject({
          code: SIGN_IN_ERROR_CODES.NEW_PASSWORD,
          message: 'You have signed in with temporary password, please provide a new one.',
        });
      },
    });
  });
}

export function getCognitoUser() {
  return cognitoUser;
}

export function getUserAttributes(Username, tenant) {
  if (!cognitoUser) {
    return Promise.reject({
      message: 'The page you are trying to open does not exist. Please be sure the page address is correct.',
    });
  }
  return new Promise((resolve, reject) => {
    cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession((sessionErr) => {
      if (sessionErr) { reject(sessionErr); return; }

      cognitoUser.getUserData((userErr, userData) => {
        if (userErr) { reject(userErr); return; }

        if ((tenant && tenant.mfaEnabled)
        && (!userData.PreferredMfaSetting || userData.PreferredMfaSetting !== MFA_TYPES.APP_MFA)) {
          reject({
            code: SIGN_IN_ERROR_CODES.TOTP_SETUP_REQUIRED,
            message: 'Secure your account by adding Multi Factor Authentication',
          });
        }

        resolve(userData.UserAttributes);
      });
    });
  });
}

export function setupTotp() {
  if (!cognitoUser) {
    return Promise.reject({
      code: SIGN_IN_ERROR_CODES.NO_AUTH,
      message: 'You must login before setting MFA.',
    });
  }
  return new Promise((resolve, reject) => {
    cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession((sessionErr) => {
      if (sessionErr) { reject(sessionErr); return; }

      cognitoUser.getUserData((userErr, userData) => {
        if (userErr) { reject(userErr); return; }

        if (userData.PreferredMfaSetting && userData.PreferredMfaSetting === MFA_TYPES.APP_MFA) {
          reject({
            code: SIGN_IN_ERROR_CODES.HAS_TOTP_SETUP,
            message: 'You have already setup MFA on your account.',
          });
        }

        userData.UserAttributes.push({
          Name: 'username',
          Value: userData.Username,
        });

        cognitoUser.associateSoftwareToken({
          onSuccess: (result) => {
            resolve(result);
          },
          onFailure: (tokenErr) => {
            reject(tokenErr);
          },
          associateSecretCode: (secretCode) => {
            resolve({ secretCode, attrs: userData.UserAttributes });
          },
        });
      });
    });
  });
}

export function verifySoftwareToken(softwareToken) {
  if (!cognitoUser || !softwareToken) {
    return Promise.reject({
      message: 'You must login before setting MFA and provide a valid code.',
    });
  }

  return new Promise((resolve, reject) => {
    cognitoUser = userPool.getCurrentUser();
    cognitoUser.getSession((sessionErr) => {
      if (sessionErr) { reject(sessionErr); return; }

      cognitoUser.verifySoftwareToken(softwareToken, 'My TOTP device', {
        onSuccess() {
          const totpMfaSettings = {
            PreferredMfa: true,
            Enabled: true,
          };
          cognitoUser.setUserMfaPreference(null, totpMfaSettings, (prefError, result) => {
            if (prefError) { reject(prefError); return; }

            resolve(result);
          });
        },
        onFailure(tokenError) {
          reject(tokenError);
        },
      });
    });
  });
}

export function getMfaOptions() {
  return new Promise((resolve, reject) => {
    cognitoUser.getMFAOptions((optionsErr, mfaOptions) => {
      if (optionsErr) { reject(optionsErr); return; }

      resolve(mfaOptions);
    });
  });
}

export function enableMfa() {
  return new Promise((resolve, reject) => {
    cognitoUser.enableMFA((mfaErr, result) => {
      if (mfaErr) { reject(mfaErr); return; }

      resolve(result);
    });
  });
}

export function disableMfa() {
  return new Promise((resolve, reject) => {
    cognitoUser.disableMFA((mfaErr, result) => {
      if (mfaErr) { reject(mfaErr); return; }

      resolve(result);
    });
  });
}

export function updatePassword(password, userAttributes) {
  if (!cognitoUser) {
    return Promise.reject({
      message: 'The page you are trying to open does not exist. Please be sure the page address is correct.',
    });
  }

  return new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
      onSuccess(result) {
        resolve({
          authData: result,
        });
      },
      onFailure: reject,
      newPasswordRequired: reject,
    });
  });
}

export function signOut() {
  if (!cognitoUser) {
    return;
  }
  cognitoUser.signOut();
  cognitoUser = null;
}

export function resetPassword(username, tenant) {
  if (!tenant) {
    return Promise.reject({
      message: 'Please select existing tenant',
    });
  }

  initUserPool(tenant);
  const Username = username;

  return new Promise((resolve, reject) => {
    const userData = {
      Username,
      Pool: userPool,
    };
    cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess() {
        resolve({ success: true });
      },
      onFailure: reject,
    });
  });
}

export function confirmPassword(username, tenant, code, password) {
  if (!tenant) {
    return Promise.reject({
      message: 'Please select existing tenant',
    });
  }

  initUserPool(tenant);
  const Username = username;

  return new Promise((resolve, reject) => {
    const userData = {
      Username,
      Pool: userPool,
    };
    cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(code, password, {
      onSuccess() {
        const authenticationData = {
          Username,
          Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess(result) {
            cognitoUser.getUserAttributes((err, attrs) => {
              if (err) {
                reject(err);
                return;
              }

              resolve({
                authData: result,
                attrs,
              });
            });
          },
          onFailure: reject,
          newPasswordRequired(userAttributes) {
            const newUserAttributes = { ...userAttributes };
            // this should never happen
            delete newUserAttributes.email_verified;
            delete newUserAttributes.phone_number_verified;
            cognitoUser.completeNewPasswordChallenge(password, newUserAttributes, this);
          },
        });
      },
      onFailure: reject,
    });
  });
}

export function confirmRegistration(code, username, tenant) {
  if (!tenant) {
    return Promise.reject({
      message: 'Please select existing tenant',
    });
  }

  initUserPool(tenant);

  const userData = {
    Username: username,
    Pool: userPool,
  };

  cognitoUser = new CognitoUser(userData);
  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
}

export function refreshToken() {
  getCurrentCognitoUser();

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((error, session) => {
      if (error) {
        reject(error);
      } else {
        const refreshTokenSession = session.getRefreshToken();
        cognitoUser.refreshSession(refreshTokenSession, (err, refreshSession) => {
          if (err) {
            reject(err);
          } else {
            resolve(refreshSession);
          }
        });
      }
    });
  });
}

export function changePassword(oldPassword, newPassword) {
  getCurrentCognitoUser();

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((error) => {
      if (error) {
        reject(error);
      } else {
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        });
      }
    });
  });
}
