// Libraries
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { Link, useParams, useNavigate } from 'react-router-dom';
// Actions
import ShowNotification from '../../actions/notification';
import SetUser from '../../actions/user';
import AddTenants, { SetGlobalLogin } from '../../actions/tenants';
// Constants
import {
  USER_ROLES, NOTIFICATION_TYPE, SIGN_IN_ERROR_CODES, AUTH_PROVIDERS,
} from '../../constants/constants';
import { CONFIG_NAME } from '../../constants/globalAdminUi';
// Hooks
import useVersion from '../../hooks/useVersion';
// Servicesß
import { makeCancelablePromise } from '../../services/cancelablePromise';
import {
  signIn as cognitoSignIn,
  getUserAttributes as getCognitoAttrs,
} from '../../services/amazon-cognito';
import {
  backendSignIn, getUser as getBackendAttrs,
} from '../../services/login';
import { saveUserInStorage, getUserFromStorage } from '../../services/userStorage';
import { getCurrentTenant } from '../../services/tenants';
// Components
import { TextInput } from '../base/forms/TextInput';
// Views
import Loading from '../base/Loading';
// images
import logo from '../../images/brand-logo.svg';

export function SignIn(props) {
  const { tenant: tenantUrl } = useParams();
  const { keycloak, initialized } = useKeycloak();

  const navigate = useNavigate();

  const {
    tenant, loading, showNotification, setUserInRedux,
    tenant: { globalLogin }, setGlobalLogin, addTenants,
  } = props;

  const { isLatestVersion } = useVersion();
  const [validVersion, setValidVersion] = useState(true);
  const [showMfaCode, setShowMfaCode] = useState(false);
  const [validateTotp, setValidateTotp] = useState(null);
  const promises = {};

  const checkVersion = async () => {
    setValidVersion(await isLatestVersion());
  };

  const redirectUserToApp = (user) => {
    const { info: { role } } = user;

    switch (role) {
      case USER_ROLES.CUSTOMER:
        navigate(`/${tenantUrl}/cn/reports`);
        break;
      case USER_ROLES.ADMIN:
      case USER_ROLES.PES:
      case USER_ROLES.CN:
        navigate(`/${tenantUrl}/cn/list`);
        break;
      default:
        navigate(`/${tenantUrl}/cn/401`);
        break;
    }
  };

  useEffect(() => {
    const user = getUserFromStorage(tenantUrl);

    if (user && tenantUrl) redirectUserToApp(user);

    checkVersion();
  }, []);

  useEffect(() => {
    if (validateTotp) {
      setShowMfaCode(true);
    } else setShowMfaCode(false);
  }, [validateTotp]);

  const showErrorMessage = (cognitoError) => {
    let errorMessage = '';
    let notificationType = NOTIFICATION_TYPE.ERROR;

    switch (cognitoError.code) {
      case SIGN_IN_ERROR_CODES.INVALID_CREDENTIALS:
      case SIGN_IN_ERROR_CODES.INVALID_TOTP:
        errorMessage = cognitoError.message;
        break;
      case SIGN_IN_ERROR_CODES.CANCELED_TOTP:
        notificationType = NOTIFICATION_TYPE.WARNING;
        errorMessage = cognitoError.message;
        break;
      case SIGN_IN_ERROR_CODES.NEW_PASSWORD:
        errorMessage = cognitoError.message;
        navigate(`/${tenantUrl}/new-pw`);
        break;
      case SIGN_IN_ERROR_CODES.TOTP_SETUP_REQUIRED:
        notificationType = NOTIFICATION_TYPE.WARNING;
        errorMessage = (
          <span className="d-flex-center">
            <i className="bi bi-shield-lock-fill h3 mr-2 mb-0" />
            {cognitoError.message}
          </span>);
        navigate(`/${tenantUrl}/setup-mfa`);
        break;
      default:
        if (cognitoError.message) {
          errorMessage = 'Incorrect username or password.';
          break;
        }
        if (cognitoError.data
          && cognitoError.data.message === SIGN_IN_ERROR_CODES.PASSWORD_EXPIRED) {
          errorMessage = 'You need to change the password, please provide a new one.';
          navigate(`/${tenantUrl}/change-pw`);
          break;
        }
        if (cognitoError.status && cognitoError.status === 401) {
          errorMessage = 'User is not authorized to perform this action.';
          break;
        }
        errorMessage = 'Server error occurred, please try again later.';
    }

    showNotification({
      message: errorMessage,
      autoHide: true,
      notificationType,
    });
  };

  const handleKeycloakSignIn = () => {
    let userData = {
      tenant,
      authData: {
        idToken: {
          jwtToken: keycloak.idToken,
          exp: keycloak.idTokenParsed.exp,
        },
        accessToken: {
          jwtToken: keycloak.token,
          exp: keycloak.tokenParsed.exp,
        },
        refreshToken: {
          jwtToken: keycloak.refreshToken,
        },
        loggedInWith: AUTH_PROVIDERS.KEYCLOAK,
      },
    };
    setUserInRedux(userData);

    const getUserRequest = getBackendAttrs();
    const getUserPromise = getUserRequest.promise;

    return getUserPromise.then((userInfo) => {
      delete getUserRequest.promise;

      userData = {
        ...userData,
        info: userInfo,
      };
      setUserInRedux(userData);
      saveUserInStorage(userData, tenantUrl);

      backendSignIn();
      redirectUserToApp(userData);
    }).catch((authError) => {
      if (authError.isCanceled) return;

      delete getUserRequest.promise;

      showErrorMessage(authError);
    });
  };

  const fetchTenantData = async () => {
    const getCurrentTenantRequest = getCurrentTenant(tenantUrl);
    const getCurrentTenantPromise = getCurrentTenantRequest.promise;
    try {
      const data = await getCurrentTenantPromise;
      if (data) {
        addTenants({
          ...data,
          ...(tenantUrl === CONFIG_NAME && { name: CONFIG_NAME }),
          globalTenant: tenantUrl === CONFIG_NAME,
        });
      } else {
        showNotification({
          notificationType: NOTIFICATION_TYPE.ERROR,
          autoHide: true,
          message: 'Please be sure the Tenant address is correct.',
        });
      }
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load patient info.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      handleKeycloakSignIn();
    }
  }, [initialized]);

  useEffect(() => {
    if (globalLogin && keycloak.authenticated) {
      setGlobalLogin(false);
      fetchTenantData();
    }
  }, [globalLogin]);

  const handleCognitoSignIn = async (formValues) => {
    const { username, password } = formValues;
    let userData = {};

    if (loading) return null;

    const signInRequest = makeCancelablePromise(cognitoSignIn(
      { username: username.toLowerCase(), password }, tenant, setValidateTotp, showErrorMessage,
    ));
    const signInPromise = signInRequest.promise;
    promises.signIn = signInRequest;

    return signInPromise.then((data) => {
      delete promises.signIn;

      userData = {
        tenant,
        authData: {
          idToken: {
            jwtToken: data.authData.idToken.jwtToken,
            exp: data.authData.idToken.payload.exp,
          },
          accessToken: {
            jwtToken: data.authData.accessToken.jwtToken,
            exp: data.authData.accessToken.payload.exp,
          },
          refreshToken: {
            jwtToken: data.authData.refreshToken.token,
          },
          loggedInWith: AUTH_PROVIDERS.COGNITO,
        },
      };

      const getUserAttrsRequest = makeCancelablePromise(getCognitoAttrs(
        username.toLowerCase(), tenant,
      ));
      const getUserAttrsPromise = getUserAttrsRequest.promise;
      promises.getUserAttributes = getUserAttrsRequest;

      return getUserAttrsPromise;
    }).then((userAttributes) => {
      delete promises.getUserAttributes;

      userData = {
        ...userData,
        attrs: userAttributes,
      };
      setUserInRedux(userData);

      const getUserRequest = getBackendAttrs();
      const getUserPromise = getUserRequest.promise;
      promises.getUser = getUserRequest;

      return getUserPromise;
    }).then((userInfo) => {
      delete promises.getUser;

      userData = {
        ...userData,
        info: userInfo,
      };
      setUserInRedux(userData);
      saveUserInStorage(userData, tenantUrl);

      backendSignIn();
      redirectUserToApp(userData);
    }).catch((authError) => {
      if (authError.isCanceled) return;

      delete promises.signIn;
      delete promises.getUser;
      delete promises.getUserAttributes;


      showErrorMessage(authError);
    });
  };

  const renderSignUpLink = () => {
    if (tenant && tenant.signUpEnabled) {
      return (
        <Link to={`/${tenantUrl}/sign-up`} className="mr-auto" data-test="signIn_signUpLink">Sign up</Link>
      );
    }
    return null;
  };

  const fieldStyles = {
    formGroup: 'mt-2',
    formControl: 'rounded-pill',
    formLabel: 'float-left text-ccm-bismark mb-2',
  };

  const renderSignIn = () => (
    <Formik
      initialValues={{
        username: '',
        password: '',
        mfaCode: '',
      }}
      validationSchema={Yup.object({
        username: Yup.string()
          .max(45, 'Max 45 characters')
          .required('Username is required'),
        password: Yup.string()
          .required('Password is required'),
      })}
      onSubmit={async (formValues) => {
        const appVersion = await isLatestVersion();
        if (appVersion) handleCognitoSignIn(formValues);
        else setValidVersion(appVersion);
      }}
      data-test="signIn_loginFormikComponent"
    >
      {formik => (
        <div className="col-3 d-flex-center">
          <div className="wrapper-login flex-grow-1 p-4">
            <Loading />
            <img
              src={logo}
              alt="SelectPatient Management logo"
              data-test="login_engoodenLogo"
              className={`logo ${loading ? 'd-none' : 'd-block'}`}
            />
            {validVersion ? (
              <Form data-test="signIn_loginFormikForm">
                <TextInput
                  label="User ID"
                  name="username"
                  autoComplete="current-username"
                  styles={fieldStyles}
                  data-test="username-field"
                />
                <TextInput
                  label="Password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  styles={fieldStyles}
                  data-test="password-field"
                />
                <Form.Group controlId="formGroup-submit">
                  <Button
                    variant="ccm-lipstick"
                    className="mt-2 px-5 rounded-pill"
                    onClick={() => formik.handleSubmit()}
                    data-test="submit-button"
                    disabled={!formik.isValid || keycloak.authenticated}
                  >
                    Sign In
                  </Button>
                </Form.Group>

                <Form.Group controlId="formGroup-keycloak">
                  {tenant && tenant.ssoEnabled && !keycloak.authenticated && (
                    <Button
                      data-test="signIn_ssoBtn"
                      size="sm"
                      variant="outline-ccm-lipstick"
                      className="mt-0 px-4 rounded-pill"
                      onClick={() => keycloak.login({ idpHint: tenant.ssoIdpHint })}
                    >
                      Login with SSO
                    </Button>)}
                  {!!keycloak.authenticated && (
                    <Button
                      size="sm"
                      variant="outline-ccm-lipstick"
                      className="mt-0 px-4 rounded-pill"
                      disabled={keycloak.authenticated}
                    >
                      Logged In (
                      {keycloak.tokenParsed.name}
                      )
                    </Button>)}
                </Form.Group>
                <Form.Group controlId="formGroupLinks">
                  <div className="d-flex-center mt-5">
                    {renderSignUpLink()}
                    <Link to={`/${tenantUrl}/reset`}>Reset password</Link>
                  </div>
                </Form.Group>
              </Form>
            ) : (
              <div className="mt-5" data-test="signIn_outdatedVersion">
                You are using an outdated version of the application, please refresh the
                page in your browser and try again. If the issue continues, please contact &nbsp;
                <a href="mailto:support@cohort.ai">support@cohort.ai.</a>
                <Button
                  variant="ccm-lipstick"
                  onClick={() => window.location.reload()}
                  className="d-block my-4 mx-auto px-5 rounded-pill"
                >
                  Refresh page
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </Formik>
  );

  const renderMfaCode = () => (
    <Formik
      initialValues={{
        mfaCode: '',
      }}
      validationSchema={Yup.object({
        mfaCode: Yup.string()
          .required('Code is required'),
      })}
      onSubmit={formValues => validateTotp && validateTotp(formValues.mfaCode)}
      data-test="signIn_mfaFormikComponent"
    >
      {formik => (
        <div className="col-4 d-flex-center">
          <div className="wrapper-login p-4">
            <img
              src={logo}
              alt="SelectPatient Management logo"
              data-test="login_engoodenLogo"
              className="logo d-block"
            />
            <h5 className="mt-5 mb-3">Almost there!</h5>
            <p className="mb-4">
              Enter the code displayed on your authenticator app.
            </p>
            <Form onSubmit={formik.handleSubmit}>
              <TextInput
                label="Code"
                name="mfaCode"
                autoComplete="off"
                styles={fieldStyles}
                data-test="mfaCode-field"
              />
              <p className="small text-left mb-4">
                <span className="bi-info-circle mr-1" />
                If you have questions, please contact your administrator.
              </p>
              <Button
                variant="ccm-lipstick"
                className="mt-2 px-5 rounded-pill"
                onClick={() => formik.handleSubmit()}
                data-test="submit-button"
                disabled={!formik.isValid}
              >
                Verify
              </Button>
              <Form.Group controlId="formGroupLinks" className="d-flex-center mt-5">
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    validateTotp(null);
                    setShowMfaCode(false);
                  }}
                  data-test="signIn_mfaBackToSignIn"
                >
                  Back to Sign In
                </Button>
              </Form.Group>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );

  return (
    <div className="ccm-form-signin row justify-content-center h-100">
      {showMfaCode ? renderMfaCode() : renderSignIn()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    tenant: state.tenant,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUserInRedux: userData => dispatch(SetUser(userData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    setGlobalLogin: data => dispatch(SetGlobalLogin(data)),
    addTenants: data => dispatch(AddTenants(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
