// Libraries
import React, { useEffect, useState } from 'react';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// actions
import { Search } from '../../../actions/search';
// Custom Hooks
import usePrevious from '../../../hooks/helpers/usePrevious';
// Constants
import { LEGEND_STATUSES, LEGEND_STATUS_BY_CATEGORY, LEGEND_STATUS_CATEGORY } from '../../../constants/statuses';


export const StatusSelector = () => {
  const {
    requestsInProgress: { count: loading },
    search: { searchParams: stateSearchParams },
    user: { info: { permissions: userPermissions } },
  } = useSelector(state => state);
  const dispatch = useDispatch();

  const prevSearchParams = usePrevious(stateSearchParams);
  const [statusLegend, setStatusLegend] = useState(LEGEND_STATUSES.ANY);
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});

  const handleSearch = (params) => {
    const newParams = _cloneDeep(params);
    dispatch(Search({ ...newParams }, true));
  };

  const saveParam = (id) => {
    setSearchParams(prevParams => ({ ...prevParams, status: id }));
    handleSearch({ ...searchParams, status: id });
  };

  const clearParam = () => {
    const updatedParams = { ...searchParams };
    delete updatedParams.status;
    setSearchParams(updatedParams);
    handleSearch(updatedParams);
  };

  const handleSelect = (status) => {
    if (searchParams.status === status.id || loading) return;
    setStatusLegend(status);
    if (status.id !== LEGEND_STATUSES.ANY.id) {
      saveParam(status.id);
    } else {
      clearParam();
    }
  };

  const renderItem = ({ color, id, name }) => (
    <div className="d-flex-center">
      <span className={`avatar-icon bg-ccm-${color} text-white rounded-circle mr-2`}>
        {id}
      </span>
      <span className="text-left status-label" data-test="statusSelector_dropDown">{name}</span>
    </div>
  );

  const renderItems = () => Object.keys(LEGEND_STATUS_BY_CATEGORY)
    .map((category, categoryIndex) => (
      <div key={`legend-status-category-${categoryIndex}`}>
        {category !== 'none' && <span className="category text-ccm-gray pl-3">{LEGEND_STATUS_CATEGORY[category]}</span>}
        {LEGEND_STATUS_BY_CATEGORY[category].map((status, statusIndex) => {
          if (status.permissions && !status.permissions.some(perm => userPermissions[perm])) {
            return null;
          }
          return (
            <Dropdown.Item
              key={`legend-status-item-${statusIndex}`}
              onClick={() => handleSelect(status)}
              as="button"
              className="d-flex my-1 border-0 px-3"
              data-test="statusSelector_dropDownItem"
            >
              {renderItem(status)}
            </Dropdown.Item>
          );
        })}
      </div>
    ));

  useEffect(() => {
    if (!_isEqual(searchParams, prevSearchParams)) {
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(stateSearchParams);

    const currentStatus = stateSearchParams && stateSearchParams.status
      ? LEGEND_STATUSES[stateSearchParams.status] : LEGEND_STATUSES.ANY;
    setStatusLegend(currentStatus);
  }, [stateSearchParams]);

  return (
    <div className="d-flex align-items-center select-status">
      <Dropdown>
        <Dropdown.Toggle variant="light">
          {renderItem(statusLegend)}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {renderItems()}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default StatusSelector;
