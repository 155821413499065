export const CONSENT_OUTCOME = {
  ACCEPTED: 'Verbally Accepted',
  DECLINED: 'Verbally Declines',
};

export const CONSENT_TYPE = {
  INITIAL: 'Initial Consent',
  RECONSENT: 'Reconsent',
};

export const CONSENT_BY = {
  PATIENT: 'Patient',
  CAREGIVER: 'Caregiver',
  PT_CAREGIVER: 'Patient/Caregiver',
};
