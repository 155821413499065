// Libraries
import React, { Fragment, useEffect, useState } from 'react';
import _isNaN from 'lodash/isNaN';
import { useSelector } from 'react-redux';
// Helpers
import { getDaysRemaining } from '../../../services/helpers';
// Views
import MetricsTitle from './MetricsTitle';
import MetricsCard from './MetricsCard';
// Constants
import { USER_ROLES } from '../../../constants/constants';
import {
  DEFAULT_CHART_VALUES, METRICS_USER_MESSAGES, METRICS_TEAM_MESSAGES, DEFAULT_BILL_METRICS,
} from '../../../constants/metrics';
// Custom Hooks
import useLoadHolidays from '../../../hooks/services/useLoadHolidays';


export const MetricsContent = (props) => {
  const { metricsData, isTeamMetrics = false, isAdmin = false } = props;

  const {
    user: { role, billingMetrics: userBillMetrics, billingMetrics: { billedBonusCount } },
    flightPlan: { billMetrics: usersBillMetrics },
  } = useSelector(state => state);

  const isCnUser = role === USER_ROLES.CN;
  const isAdminUser = role === USER_ROLES.ADMIN;
  const billedBonusValue = isCnUser && !isTeamMetrics ? billedBonusCount : null;

  const [messages] = useState(isTeamMetrics ? METRICS_TEAM_MESSAGES : METRICS_USER_MESSAGES);
  const [billing, setBilling] = useState(DEFAULT_CHART_VALUES);
  const [enrollment, setEnrollment] = useState(DEFAULT_CHART_VALUES);
  const [billingSummary, setBillingSummary] = useState('');
  const [enrollmentSummary, setEnrollmentSummary] = useState('');

  const { holidays } = useLoadHolidays();

  const getMetric = (perSchedule) => {
    const done = perSchedule && perSchedule.done ? perSchedule.done : 0;
    const goal = perSchedule && perSchedule.goal ? perSchedule.goal : 0;

    const validPercentage = (done * 100) / goal;
    return {
      done,
      goal,
      percentage: _isNaN(validPercentage) ? 0 : Math.round(validPercentage),
    };
  };

  const getMetrics = (perDay, perMonth) => ({
    daily: getMetric(perDay),
    monthly: getMetric(perMonth),
  });

  const getBillMetrics = () => {
    let updatedMetrics = DEFAULT_BILL_METRICS;

    if (isCnUser && !isTeamMetrics) updatedMetrics = userBillMetrics;
    if (isAdminUser || (isCnUser && isTeamMetrics)) updatedMetrics = usersBillMetrics;

    const {
      assignedCount, doNotBillCount, billedThisMonthCount, billedTodayCount,
    } = updatedMetrics;

    const monthlyGoal = assignedCount - doNotBillCount;
    const monthlyPercentage = (billedThisMonthCount * 100) / monthlyGoal;

    const dailyGoalFormula = (assignedCount - billedThisMonthCount) - doNotBillCount;
    /**
     * No matter what position billedTodayCount takes in the equation, the result may be
     * negative because the value can be higher or lower without any restriction to what
     * the user has billed. For that reason we take the absolute value
     */
    const totalDailyCount = Math.abs(dailyGoalFormula - billedTodayCount);
    const dailyGoal = totalDailyCount / getDaysRemaining(holidays);
    const dailyPercentage = (billedTodayCount * 100) / dailyGoal;

    return {
      daily: {
        done: billedTodayCount,
        goal: _isNaN(dailyGoal) ? 0 : Math.round(dailyGoal),
        percentage: _isNaN(dailyPercentage) ? 0 : Math.round(dailyPercentage),
      },
      monthly: {
        done: billedThisMonthCount,
        goal: monthlyGoal,
        percentage: _isNaN(monthlyPercentage) ? 0 : Math.round(monthlyPercentage),
      },
    };
  };

  const summaryText = (daily, monthly, message) => {
    const dailyDiff = daily.goal - daily.done;
    const monthlyDiff = monthly.goal - monthly.done;

    switch (true) {
      case daily.goal > 0 && dailyDiff > 0: {
        let dailyMsg = message.dailyDiff.replace('[x]', dailyDiff);

        if (dailyDiff === 1) {
          dailyMsg = dailyMsg.replace('patients are', 'patient is');
        }
        return dailyMsg;
      }
      case daily.goal > 0 && dailyDiff <= 0:
        return message.dailyComplete.replace('[x]', daily.done);
      case daily.goal === 0 && monthly.goal > 0 && monthlyDiff > 0:
        return message.monthlyDiff.replace('[x]', monthlyDiff);
      case daily.goal === 0 && monthly.goal > 0 && monthlyDiff <= 0:
        return message.monthlyComplete;
      default:
        return isAdmin ? message.noneAdmin : message.none;
    }
  };

  useEffect(() => {
    const { ENROLLED_PATIENTS_PER_DAY, ENROLLED_PATIENTS_PER_MONTH } = metricsData;

    if (isAdminUser || isCnUser) {
      const billingMetrics = getBillMetrics();
      setBilling(billingMetrics);
      setBillingSummary(summaryText(
        billingMetrics.daily,
        billingMetrics.monthly,
        messages.billing,
      ));
    }

    const enrollmentMetrics = getMetrics(ENROLLED_PATIENTS_PER_DAY, ENROLLED_PATIENTS_PER_MONTH);
    setEnrollment(enrollmentMetrics);
    setEnrollmentSummary(summaryText(
      enrollmentMetrics.daily,
      enrollmentMetrics.monthly,
      messages.enrollment,
    ));
  }, [metricsData, userBillMetrics, usersBillMetrics]);

  return (
    <Fragment>
      <MetricsTitle isTeamMetrics={isTeamMetrics} />
      <div className="container-fluid px-5">
        <div className="row">
          {(isAdminUser || isCnUser) && (
            <div className="col-6">
              <MetricsCard title="BILLING" content={billing} summary={billingSummary} billedBonusValue={billedBonusValue} />
            </div>
          )}
          <div className="col-6">
            <MetricsCard title="ENROLLMENT" content={enrollment} summary={enrollmentSummary} reverseStyle="-reverse" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MetricsContent;
