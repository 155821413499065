// Libraries
import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
// Views
import BulkChangeAttributeModal from './BulkChangeAttributeModal';

export const BulkChangeAttribute = (props) => {
  const { selectedPatientsIds, reloadData } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Fragment>
      <Button
        variant="light"
        className="d-flex-center mr-1"
        onClick={() => setIsModalOpen(true)}
        disabled={(selectedPatientsIds && selectedPatientsIds.size === 0)}
        data-test="bulkChangeAttribute_Button"
      >
        <i className="bi-tag mr-2" />
        <span className="mb-0">
          <span>Attribute</span>
        </span>
      </Button>
      <BulkChangeAttributeModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        patientIds={selectedPatientsIds}
        reloadData={reloadData}
      />
    </Fragment>
  );
};

export default BulkChangeAttribute;
