// Libraries
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
// Views
import GlobalTopMenu from './GlobalTopMenu';
// Constants
import { TIME_FORMAT } from '../../../constants/constants';

export const GlobalUser = () => {
  const {
    user: { firstName, lastName },
    tenant: { timezone, name: tenantName },
  } = useSelector(state => state);

  const formattedTimezone = timezone && timezone.replace(/\//g, ', ').replace(/(America|US), /g, '').replace(/_/g, ' ');

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className="user-control">
      <div className="user-messages">
        <div className="icon" />
        <div className="counter" />
      </div>
      <div className="d-flex text-left">
        <div className="user-credentials">
          <div className="username" data-for="tooltip-topBar" data-tip={`${firstName} ${lastName}`} data-test="logged-in-user">
            {`${firstName} ${lastName}`}
          </div>
          <div className="tenant" data-for="tooltip-topBar" data-tip={timezone ? `${moment.tz(timezone).format(TIME_FORMAT.SHORT_TIME)} (${formattedTimezone})` : ''} data-test="globalUser_tenantTimezone">{tenantName}</div>
        </div>
        <GlobalTopMenu />
      </div>
    </div>
  );
};

export default GlobalUser;
