import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { Button, Row, Form } from 'react-bootstrap';
// Services
import { addPatientReminder, updatePatientReminder } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  PATIENT_CALL_TIME_OPTIONS, DATE_FORMAT, NOTIFICATION_TYPE, DIALOG_STYLES,
} from '../../../constants/constants';
// Components
import { Select } from '../../base/forms/Select';
import { TextArea } from '../../base/forms/TextArea';
import { Datepicker } from '../../base/forms/Datepicker';

export function RemindersModal(props) {
  const {
    isModalOpen, setIsModalOpen, initialReminder,
  } = props;

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  const DEFAULT_REMINDER = {
    id: '',
    text: '',
    reminderDate: '',
    reminderTime: '',
    status: 'CREATED',
  };

  const [newReminder, setNewReminder] = useState(initialReminder || DEFAULT_REMINDER);

  useEffect(() => {
    if (initialReminder) {
      setNewReminder({
        id: initialReminder.id || '',
        text: initialReminder.text || '',
        reminderDate: initialReminder.reminderDate || '',
        reminderTime: initialReminder.reminderTime || '',
        status: initialReminder.status || 'CREATED',
      });
    } else {
      setNewReminder(DEFAULT_REMINDER);
    }
  }, [initialReminder]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveReminder = (reminder) => {
    const {
      showNotification, updatePatient,
      patientId, patient: { reminders = [] } = {},
    } = props;

    const isNewReminder = !reminder.id;

    const addReminderRequest = isNewReminder
      ? addPatientReminder(patientId, reminder) : updatePatientReminder(patientId, reminder);
    const addReminderPromise = addReminderRequest.promise;

    return addReminderPromise.then((data) => {
      delete addReminderPromise.promise;

      let updatedReminders;
      if (isNewReminder) {
        updatedReminders = [...reminders, data];
      } else {
        updatedReminders = reminders.map(r => (r.id === data.id ? data : r));
      }
      updatePatient({
        reminders: updatedReminders,
        hasReminders: updatedReminders.filter(x => x.status === 'CREATED').length,
      });

      showNotification({
        message: 'Reminder saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      handleCloseModal();
    }).catch((error) => {
      delete addReminderPromise.promise;

      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this reminder',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Reminders Modal"
      data-test="remindersModal_onRequestClose"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {`${initialReminder ? 'Edit' : 'Add'} Reminder`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="remindersModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={newReminder}
          validationSchema={Yup.object({
            text: Yup.string().trim()
              .required('Required'),
            reminderDate: Yup.date()
              .typeError('Must be a valid date')
              .min(moment(todayDate).format(DATE_FORMAT.SHORT), 'Must not be in the past'),
          })}
          onSubmit={(values, { resetForm }) => {
            const formattedDate = values.reminderDate
              ? moment(values.reminderDate).format(DATE_FORMAT.FULL) : values.reminderDate;
            const updatedValues = { ...values, reminderDate: formattedDate };
            saveReminder(updatedValues);
            resetForm();
          }}
          data-test="remindersModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <Row className="align-items-center">
                  <div className="col">
                    <TextArea
                      label="Description"
                      name="text"
                      rows={3}
                      data-test="remindersModal_dateInput"
                      placeholder="Enter actionable items here that would be clear to anyone reading the reminder. Other patient information should be entered using call notes."
                    />
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <Datepicker
                      name="reminderDate"
                      showYearDropdown
                      minDate={new Date()}
                      inline
                      todayButton="Today"
                    />
                  </div>
                  <div className="col">
                    <Datepicker
                      label="Date"
                      name="reminderDate"
                      popperClassName="d-none"
                      data-test="remindersModal_dateInput"
                    />
                    <Select
                      label="Time"
                      name="reminderTime"
                      labelKey="name"
                      options={PATIENT_CALL_TIME_OPTIONS}
                      data-test="remindersModal_timeInput"
                    />
                  </div>
                </Row>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="remindersModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="remindersModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
    timezone: state.tenant && state.tenant.timezone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemindersModal);
