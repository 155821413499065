// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { Button, Row, Form } from 'react-bootstrap';
// Services
import {
  addPatientReportedVitals, updatePatientReportedVitals,
} from '../../../../services/patient';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdatePatientHistory } from '../../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT, TIME_OPTIONS, BLOOD_SUGAR_TYPE_OPTIONS,
} from '../../../../constants/constants';
// Components
import { Select } from '../../../base/forms/Select';
import { TextInput } from '../../../base/forms/TextInput';
import { Datepicker } from '../../../base/forms/Datepicker';
import { Checkbox } from '../../../base/forms/Checkbox';

export const vitalsSchema = ({ timezone }) => Yup.object({
  date: Yup.date()
    .max(moment.tz(timezone).format(DATE_FORMAT.FULL), 'Must not be in the future')
    .required('Required'),
  height: Yup.number()
    .min(0, 'Must be greater than 0 inches')
    .max(100, 'Must be less than 100 inches')
    .test(
      'maxDigitsAfterDecimal',
      'Number field must have 2 digits after decimal or less',
      number => (!number ? true : /^([0-9]{1,3})(\.[0-9]{1,2})?$/.test(number)),
    ),
  weight: Yup.number()
    .min(0, 'Must be greater than 0 inches')
    .max(2000, 'Must be less than 2000 pounds')
    .test(
      'maxDigitsAfterDecimal',
      'Number field must have 2 digits after decimal or less',
      number => (!number ? true : /^([0-9]{1,4})(\.[0-9]{1,2})?$/.test(number)),
    ),
  bpSystolic: Yup.number()
    .min(0, 'Must be greater than 0')
    .max(500, 'Must be less than 500'),
  bpDiastolic: Yup.number()
    .min(0, 'Must be greater than 0')
    .max(500, 'Must be less than 500'),
  bloodSugar: Yup.number()
    .min(0, 'Must be greater than 0')
    .max(1000, 'Must be less than 1000')
    .test(
      'maxDigitsAfterDecimal',
      'Number field must have 2 digits after decimal or less',
      number => (!number ? true : /^([0-9]{1,4})(\.[0-9]{1,2})?$/.test(number)),
    ),
  spO2: Yup.number()
    .min(0, 'Must be greater than 0')
    .max(100, 'Must be less than 100'),
  heartRate: Yup.number()
    .max(1000, 'Must be less than 1000'),
});

export function VitalsModal(props) {
  const {
    isModalOpen, setIsModalOpen, initialVitals, timezone,
  } = props;

  const DEFAULT_VITALS = {
    id: '',
    height: '',
    weight: '',
    bpSystolic: '',
    bpDiastolic: '',
    bloodSugar: '',
    spO2: '',
    heartRate: '',
    bloodSugarTime: 'NONE',
    bloodSugarType: 'RANDOM',
    baseline: false,
    date: moment.tz(timezone).format(DATE_FORMAT.FULL),
  };

  const [newVitals, setNewVitals] = useState(initialVitals || DEFAULT_VITALS);

  useEffect(() => {
    if (initialVitals) {
      setNewVitals({
        id: initialVitals.id || '',
        height: initialVitals.height || '',
        weight: initialVitals.weight || '',
        bpSystolic: initialVitals.bpSystolic || '',
        bpDiastolic: initialVitals.bpDiastolic || '',
        bloodSugar: initialVitals.bloodSugar || '',
        spO2: initialVitals.spO2 || '',
        heartRate: initialVitals.heartRate || '',
        bloodSugarTime: initialVitals.bloodSugarTime || 'NONE',
        bloodSugarType: initialVitals.bloodSugarType || 'RANDOM',
        baseline: initialVitals.baseline || false,
        date: moment(initialVitals.date).tz(timezone).format(DATE_FORMAT.FULL)
          || moment.tz(timezone).format(DATE_FORMAT.FULL),
      });
    } else {
      setNewVitals(DEFAULT_VITALS);
    }
  }, [initialVitals]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const addVitals = (vitalEntries) => {
    const {
      patientId, showNotification,
      updatePatientHistory, patientHistory = {},
    } = props;

    const isNewVital = !vitalEntries.id;

    const addVitalsRequest = isNewVital
      ? addPatientReportedVitals(patientId, vitalEntries)
      : updatePatientReportedVitals(patientId, vitalEntries);
    const addVitalsPromise = addVitalsRequest.promise;

    return addVitalsPromise.then((data) => {
      delete addVitalsRequest.promise;
      updatePatientHistory({
        ...patientHistory,
        vitalEntries: data,
      });

      handleCloseModal();
    }).catch((error) => {
      delete addVitalsRequest.promise;

      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save patient vitals',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Log Patient Reported Vitals"
      data-test="vitalsModal_onRequestClose"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          Log Patient Reported Vitals
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="vitalsModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={newVitals}
          validationSchema={vitalsSchema({ timezone })}
          onSubmit={(values) => {
            const formattedDate = moment(values.date).format(DATE_FORMAT.FULL);
            const updatedValues = { ...values, date: formattedDate };
            addVitals(updatedValues);
          }}
          data-test="vitalsModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <Row className="align-items-center">
                  <div className="col" data-test="vitalsModal_dateInput">
                    <Datepicker
                      label="Date"
                      name="date"

                    />
                  </div>
                  <div className="col">
                    <Checkbox
                      label="Baseline"
                      name="baseline"
                    />
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <TextInput
                      label="Height (inches)"
                      name="height"
                      type="number"
                      data-test="vitalsModal_heightInput"
                    />
                  </div>
                  <div className="col">
                    <TextInput
                      label="Weight (pounds)"
                      name="weight"
                      type="number"
                      data-test="vitalsModal_weightInput"
                    />
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <TextInput
                      label="BP Systolic"
                      name="bpSystolic"
                      type="number"
                      data-test="vitalsModal_bpSystolicInput"
                    />
                  </div>
                  <span className="text-center mt-4">/</span>
                  <div className="col">
                    <TextInput
                      label="BP Diastolic"
                      name="bpDiastolic"
                      type="number"
                      data-test="vitalsModal_bpDiastolicInput"
                    />
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <TextInput
                      label="SPO2"
                      name="spO2"
                      type="number"
                      data-test="vitalsModal_spo2Input"
                    />
                  </div>
                  <div className="col">
                    <TextInput
                      label="Heart Rate"
                      name="heartRate"
                      type="number"
                      data-test="spo2Input"
                    />
                  </div>
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <TextInput
                      label="Blood Sugar"
                      name="bloodSugar"
                      type="number"
                      data-test="vitalsModal_bloodSugar"
                    />
                  </div>
                  <div className="col" data-test="vitalsModal_bloodSugarTime">
                    <Select
                      label="Blood Sugar Time"
                      name="bloodSugarTime"
                      options={TIME_OPTIONS}
                    />
                  </div>
                  <div className="col" data-test="vitalsModal_bloodSugarType">
                    <Select
                      label="Blood Sugar Type"
                      name="bloodSugarType"
                      options={BLOOD_SUGAR_TYPE_OPTIONS}
                    />
                  </div>
                </Row>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="vitalsModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="vitalsModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    timezone: state.tenant && state.tenant.timezone,
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatientHistory: patientHistoryData => dispatch(UpdatePatientHistory(patientHistoryData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VitalsModal);
