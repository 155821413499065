// libraries
import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

/**
 * QaPager component, requires props:
 * totalPages -- number of pages
 * currentPage -- number of current page
 * callback -- callback after changing page
 * maxShownCount -- optional, number of max shown pages, default is 5
 * isNextPrevShown -- optional, if it necessary to show next/prev buttons, default is false
 */
export const QaPager = (props) => {
  const buildPager = () => {
    const {
      totalPages, currentPage = 0, maxShownCount = 5, isNextPrevShown,
    } = props;
    const result = [];
    const firstShownCount = (maxShownCount - 1) / 2;
    const lastShownCount = (maxShownCount + 1) / 2;

    const pageIndexes = {
      current: currentPage,
      first: 0,
      last: totalPages ? totalPages - 1 : 0,
      start: currentPage > firstShownCount ? currentPage - firstShownCount : 0,
      end: currentPage + firstShownCount < totalPages ? currentPage + lastShownCount : totalPages,
      shownCount: Math.min(totalPages, maxShownCount),
    };

    if (!pageIndexes.last) {
      return <div />;
    }

    if ((pageIndexes.end - pageIndexes.start < pageIndexes.shownCount)) {
      if (!pageIndexes.start) {
        pageIndexes.end = pageIndexes.shownCount;
      } else {
        pageIndexes.start = pageIndexes.end - pageIndexes.shownCount;
      }
    }

    const moveToPage = (event, pageNumber) => {
      event.preventDefault();
      if (pageNumber < 0 || pageNumber >= props.totalPages) {
        return null;
      }
      props.callback(pageNumber);
      return true;
    };


    const createCallback = pageNumber => event => moveToPage(event, pageNumber);

    result.push(
      <Button
        data-test="qaPager_firstPage"
        key="first"
        variant="secondary"
        disabled={pageIndexes.current < 1}
        onClick={createCallback(pageIndexes.first)}
      >
        First Page
      </Button>,
    );

    if (isNextPrevShown) {
      result.push(
        <Button
          key="prev"
          variant="secondary"
          disabled={pageIndexes.current < 1}
          onClick={createCallback(pageIndexes.current - 1)}
        >
          Previous Page
        </Button>,
      );
    }

    for (let i = pageIndexes.start; i < pageIndexes.end; i++) {
      result.push(
        <Button
          key={i}
          variant="secondary"
          className={pageIndexes.current === i ? 'active' : ''}
          onClick={createCallback(i)}
        >
          {i + 1}
        </Button>,
      );
    }

    if (isNextPrevShown) {
      result.push(
        <Button
          data-test="qaPager_nextPage"
          key="next"
          variant="secondary"
          disabled={pageIndexes.current >= pageIndexes.last}
          onClick={createCallback(pageIndexes.current + 1)}
        >
          Next Page
        </Button>,
      );
    }

    result.push(
      <Button
        data-test="qaPager_lastPage"
        key="last"
        variant="secondary"
        disabled={pageIndexes.current >= pageIndexes.last}
        onClick={createCallback(pageIndexes.last)}
      >
        Last Page
      </Button>,
    );

    return result;
  };

  return (
    <div className="ccm-table-pager">
      <ButtonGroup aria-label="Table Pager" data-test="qaPager_pageSection">
        {buildPager()}
      </ButtonGroup>
    </div>
  );
};

export default QaPager;
