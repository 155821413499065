// Libraries
import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import _debounce from 'lodash/debounce';
// Constants
import { FLIGHT_PLAN_FILTERS } from '../../../constants/constants';

export const Filter = (props) => {
  const { setFilters, initialFilters } = props;

  const inputRef = useRef(null);
  const [filter, setFilter] = useState(initialFilters.filter);
  const [status, setStatus] = useState(initialFilters.status);
  const [inputId, setInputId] = useState(initialFilters.inputId);

  const debouncedHandleSearch = useMemo(() => _debounce(setInputId, 1000), []);

  // Cleanup for debounce
  useEffect(() => () => debouncedHandleSearch.cancel(), []);

  useEffect(() => {
    if (filter === 'status') {
      setFilters({
        ...initialFilters, filter, status, inputId: '',
      });
    } else {
      setFilters({
        ...initialFilters, filter, inputId, status: '',
      });
    }
  }, [status, inputId]);

  const validInputValue = ({ value, maxLength }) => {
    const number = value.slice(0, maxLength);
    if (inputRef.current !== null) inputRef.current.value = number;
    debouncedHandleSearch(number);
  };

  useEffect(() => {
    setInputId('');
    if (inputRef.current !== null) inputRef.current.value = '';

    if (filter === 'status') setStatus('CC');
    else setStatus('');
  }, [filter]);

  const renderInput = () => (
    <Form.Control
      name="filterInput"
      type="number"
      ref={inputRef}
      maxLength={filter === 'engoodenId' ? 9 : 50}
      autoComplete="off"
      data-test="filter_input"
      className="input p-0"
      onChange={ev => validInputValue(ev.target)}
    />
  );

  const renderDropDownItems = (menuItems, onAction) => menuItems.map((element, index) => (
    <Dropdown.Item
      as="button"
      key={`flight_plan_filter_${index}`}
      onClick={() => onAction(element.value)}
      data-test="filter_dropDownItem"
      className="d-flex my-1 border-0 text-ccm-blue px-3 item"
    >
      <div>{element.label}</div>
    </Dropdown.Item>
  ));

  const renderSubSelect = () => (
    <Dropdown>
      <Dropdown.Toggle
        className="toggle border-0 text-ccm-blue p-1"
        variant="light"
        data-test="filter_statusMenuDropDown"
      >
        {FLIGHT_PLAN_FILTERS.statuses.find(el => el.value === status).label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="menu-status">
        {renderDropDownItems(FLIGHT_PLAN_FILTERS.statuses, setStatus)}
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <div className="filter d-flex w-25 align-items-center">
      <h6 className="text-left text-uppercase mb-0 mr-2">Filter By:</h6>
      <Dropdown className="select-alert mr-1">
        <Dropdown.Toggle
          variant="light"
          className="toggle border-0 text-ccm-blue p-1"
          data-test="filter_filterMenuDropDown"
        >
          {FLIGHT_PLAN_FILTERS.filters.find(el => el.value === filter).label}
        </Dropdown.Toggle>
        <Dropdown.Menu className="menu-filter">
          {renderDropDownItems(FLIGHT_PLAN_FILTERS.filters, setFilter)}
        </Dropdown.Menu>
      </Dropdown>
      {filter === 'status' ? renderSubSelect() : renderInput()}
    </div>
  );
};

export default Filter;
