// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
// constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// services
import {
  getBillingHistoryReport,
  getBillingHistoryExcelReport,
} from '../../services/administration';
import { downloadFile, isObjectEmpty } from '../../services/helpers';
// views
import BillingHistoryTable from '../shared/billingHistory/BillingHistoryTable';
import ShowNotification, { HideNotification } from '../../actions/notification';

// exported for testing purposes
export function prepareSearchQuery(params) {
  const { sort, dateRange, currentPage } = params;
  const result = {};

  if (sort.key) {
    result.sortBy = sort.key;
    result.sortOrder = sort.reverse ? 'DESC' : 'ASC';
  }

  if (!isObjectEmpty(dateRange)) {
    if (dateRange.from) {
      result.fromDate = `${dateRange.from} 00:00`;
    }

    if (dateRange.to) {
      result.toDate = `${dateRange.to} 23:59`;
    }
  }

  result.pageNumber = currentPage;

  return result;
}

export class BillingHistory extends Component {
  state = {
    billingHistory: [],
    totalPages: 0,
    parameters: {},
    loading: false,
  };

  promises = {};

  pageTop = React.createRef();

  componentWillUnmount() {
    Object.keys(this.promises)
      .forEach((key) => {
        this.promises[key].cancel();
      });
  }

  getBillingHistoryData = async () => {
    const { showNotification } = this.props;
    const { parameters } = this.state;

    this.setState({
      loading: true,
    });

    const promiseName = 'getBillingHistory';
    const getBillingHistoryReportRequest = getBillingHistoryReport(parameters);
    const getBillingHistoryReportPromise = getBillingHistoryReportRequest.promise;
    this.promises[promiseName] = getBillingHistoryReportRequest;
    try {
      const data = await getBillingHistoryReportPromise;
      delete this.promises[promiseName];

      this.setState({
        billingHistory: data.billingReports,
        totalPages: data.totalPages,
        loading: false,
      });

      this.pageTop.current.scrollIntoView({ block: 'start' });
    } catch (error) {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load Care Navigator title.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  getBillingHistory = (params) => {
    this.setState({
      parameters: prepareSearchQuery(params),
    }, this.getBillingHistoryData);
  };

  downloadReport = async (params) => {
    const { showNotification } = this.props;
    const promiseName = 'getBillingExcel';
    const getBillingExcelRequest = getBillingHistoryExcelReport(prepareSearchQuery(params));
    const getBillingExcelPromise = getBillingExcelRequest.promise;
    this.promises[promiseName] = getBillingExcelRequest;

    try {
      const resp = await getBillingExcelPromise;
      delete this.promises[promiseName];
      downloadFile(resp.data, resp.fileName);
    } catch (error) {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Can\'t download report.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  render() {
    return (
      <div ref={this.pageTop} className="admin-section p-3">
        <h4 className="text-uppercase text-left mt-2 mb-4" data-test="billingHistory_ccmChargesTitle">CCM Charges</h4>
        <BillingHistoryTable
          getBillingHistory={this.getBillingHistory}
          downloadReport={(dateRange) => {
            this.downloadReport(dateRange);
          }}
          billingHistory={this.state.billingHistory}
          totalPages={this.state.totalPages}
          shouldDisplayPatientData
          loading={this.state.loading}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(BillingHistory);
