// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
// Views
import NoteEditor from '../../shared/NoteEditor';
// Services
import { updateNote } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import { NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT } from '../../../constants/constants';

export function NoteModal(props) {
  const {
    patientId, initialNote, showNotification, isModalOpen, setIsModalOpen,
  } = props;

  const [newNote, setNewNote] = useState(initialNote);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    setNewNote(initialNote);
  }, [initialNote]);

  useEffect(() => {
    if (initialNote.text && newNote.text
      && JSON.stringify(initialNote.text) === JSON.stringify(newNote.text)) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [newNote]);

  const savePatientNote = () => {
    const { updatePatient } = props;


    const updateNoteRequest = updateNote(patientId, {
      id: newNote.id || '',
      text: newNote.text,
      type: newNote.type.dataType,
    });
    const updateNotePromise = updateNoteRequest.promise;

    return updateNotePromise.then((data) => {
      delete updateNoteRequest.promise;
      updatePatient({ [newNote.type.noteType]: data });
    }).catch((error) => {
      delete updateNoteRequest.promise;
      if (error.isCanceled) {
        return;
      }

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error occurred while attempting to save this note.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleCloseModal = () => {
    setNewNote(initialNote);
    setIsModalOpen(false);
  };

  const handleCreateNote = () => {
    savePatientNote();
    handleCloseModal();
  };

  const handleNoteText = (noteText) => {
    setNewNote({
      ...newNote,
      text: noteText,
    });
  };

  const getDateString = date => moment(date).format(DATE_FORMAT.SHORT);
  const getFullName = a => `${a.firstName || ''} ${a.lastName || ''}`.replace(/  +/g, ' ');

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Note Modal"
      data-test="noteModal_modal"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {`${initialNote && initialNote.updatedAt ? 'Edit' : 'New'} Note`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="noteModal_modalCloseButton"
          />
        </div>
        <div className="dialog-content">
          {initialNote && initialNote.updatedAt && (
          <div className="text-right">
            <small className="text-ccm-gray">{getFullName(initialNote.updatedByUser)}</small>
            <small className="text-ccm-gray ml-1">{getDateString(initialNote.updatedAt)}</small>
          </div>)}
          <div className="border rounded mt-1" style={{ height: 250, maxHeight: 300 }} data-test="noteModal_textArea">
            <NoteEditor
              note={newNote}
              handleChangeQaNote={noteText => handleNoteText(noteText)}
              data-test="noteModal_noteEditor"
            />
          </div>
        </div>
        <div className="dialog-buttons justify-content-end px-4">
          <Button variant="light" onClick={() => handleCloseModal()} data-test="noteModal_cancelBtn">Cancel</Button>
          <Button
            variant="primary"
            className="ml-2"
            disabled={saveDisabled}
            onClick={() => handleCreateNote()}
            data-test="noteModal_saveBtn"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);
