import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when) {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (!showPrompt) setConfirmedNavigation(false);
  }, [showPrompt]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = useCallback((nextLocation) => {
    // in if condition we are checking next location and current location are equals or not
    if (!confirmedNavigation
            && nextLocation.location.pathname !== location.pathname) {
      setShowPrompt(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }, [confirmedNavigation, navigate]);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
