// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Button, Form } from 'react-bootstrap';
import Checkbox from '../../../base/Checkbox';
// actions
import ShowNotification from '../../../../actions/notification';
// constants
import { DIALOG_DEFAULTS, NOTIFICATION_TYPE } from '../../../../constants/constants';
// services
import { updateUserPassword } from '../../../../services/administration';
import ErrorBuilder from '../../../../services/errorMessageBuilder';
import { validatePassword, autoGeneratePassword, copyToClipboard } from '../../../../services/helpers';
// views
import LabeledInput from '../../../base/LabeledInput';
import LoadingBlock from '../../../menu/LoadingBlock';

export class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modalOpened: false,
      showPassword: false,
      form: {
        password: '',
        confirmPassword: '',
      },
    };

    this.refs = {};

    this.promises = {};

    this.handleChange = this.handleChange.bind(this);
    this.saveRef = this.saveRef.bind(this);
    this.validate = this.validate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.validateConfirmPassword = this.validateConfirmPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  handleChange(event) {
    this.setState(state => ({
      form: {
        ...state.form,
        [event.target.name]: event.target.value,
      },
    }));
  }

  saveRef(name, ref) {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  }

  validate() {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      result = this.refs[key].validate() && result;
    });

    return result;
  }

  openDialog() {
    this.setState({
      modalOpened: true,
    });
  }

  validateConfirmPassword() {
    const { form } = this.state;

    if (form.password !== form.confirmPassword) {
      return 'do not match';
    }

    return false;
  }

  closeDialog() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      loading: false,
      modalOpened: false,
      form: {
        password: '',
        confirmPassword: '',
      },
    });
  }

  changePassword(event) {
    event.preventDefault();

    if (this.state.loading || !this.validate()) {
      return;
    }

    this.setState({
      loading: true,
    });

    const { showNotification } = this.props;
    const promiseName = 'updateUserPassword';

    const updateUserPasswordRequest = updateUserPassword(this.props.user.id,
      { newPassword: this.state.form.password });

    const updateUserPasswordPromise = updateUserPasswordRequest.promise;
    this.promises[promiseName] = updateUserPasswordRequest;

    updateUserPasswordPromise.then(() => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
        modalOpened: false,
        form: {
          password: '',
          confirmPassword: '',
        },
      });

      showNotification({
        message: 'Password was changed successfully.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    }).catch((error) => {
      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }

      showNotification({
        message: ErrorBuilder(error.data),
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });

      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const { form, showPassword } = this.state;

    let loading;
    if (this.state.loading) {
      loading = <LoadingBlock />;
    }

    const setShowPassword = () => {
      this.setState({
        showPassword: !showPassword,
      });
    };

    return (
      <div>
        <Button
          data-test="changePassword_button"
          variant="light"
          className="mr-2"
          onClick={this.openDialog}
        >
          Change password
        </Button>
        <Modal
          isOpen={this.state.modalOpened}
          style={DIALOG_DEFAULTS}
          onRequestClose={this.closeDialog}
          contentLabel="Change User Password"
        >
          <div className="simple-dialog small-dialog">
            <div className="dialog-title">
              Change Password
              <div
                className="close-icon i-close"
                onClick={this.closeDialog}
              />
            </div>
            <div className="dialog-content">
              {loading}
              <form
                className="form-container"
                onSubmit={this.changePassword}
              >
                <div className="d-flex flex-column w-100 align-items-start mb-3">
                  <Button
                    variant="primary"
                    className=""
                    onClick={() => {
                      const password = autoGeneratePassword();
                      copyToClipboard(password);
                      this.setState({
                        form: {
                          password,
                          confirmPassword: password,
                        },
                      });
                    }}
                    data-test="changePassword_generatePassword"
                  >
                    Auto-Generate Password
                  </Button>
                  <Form.Text muted>Creates new password and copies to clipboard</Form.Text>
                  <div className="d-flex my-2">
                    <Form.Text className="">Show password:</Form.Text>
                    <div className="ml-3" data-test="changePassword_showPasswordCheckBox">
                      <Checkbox
                        fieldKey="showPassword"
                        initialValue={showPassword}
                        submitCallback={() => setShowPassword()}
                        data-test="changePassword_showPassword"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row pb-2">
                  <LabeledInput
                    data-test="changePassword_newPassword"
                    ref={this.saveRef.bind(this, 'password')}
                    label="New Password"
                    validateCallback={validatePassword}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={form.password}
                    handleChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-row">
                  <LabeledInput
                    data-test="changePassword_repeatPassword"
                    ref={this.saveRef.bind(this, 'confirmPassword')}
                    label="Repeat Password"
                    validateCallback={this.validateConfirmPassword}
                    type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    value={form.confirmPassword}
                    handleChange={this.handleChange}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="hidden"
                />
              </form>
            </div>
            <div className="dialog-buttons justify-content-end px-4">
              <Button
                variant="light"
                data-test="changePassword_cancelButton"
                onClick={this.closeDialog}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="ml-2"
                data-test="changePassword_saveButton"
                onClick={this.changePassword}
              >
                Change password
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(ChangePassword);
