// libraries
import React from 'react';
// Hooks
import useLoadResources from '../../../hooks/services/useLoadResources';
// Views
import ResourcesList from './ResourcesList';

const Resources = () => {
  useLoadResources(true);
  return (
    <div id="resources" className="mt-5">
      <h4 className="text-uppercase text-left my-3" data-test="resources_title">Resources</h4>
      <ResourcesList />
    </div>
  );
};

export default Resources;
