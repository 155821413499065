// Libraries
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Accordion, Card, Button } from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
// Views
import QaPcpFilter from './qaSearch/QaPcpFilter';
import QaPatientFilter from './qaSearch/QaPatientFilter';
import QaCcmFilter from './qaSearch/QaCcmFilter';
import QaFilterInformation from './qaSearch/QaFilterInformation';
// Services
import { isObjectsEqual } from '../../services/helpers';
// Custom Hooks
import usePrevious from '../../hooks/helpers/usePrevious';


export const QaSearch = (props) => {
  const {
    qaSearch: { searchParams: stateSearchParams, isSearchParamsOverwritten },
  } = useSelector(state => state);

  const prevSearchParams = usePrevious(stateSearchParams);
  const [searchParams, setSearchParams] = useState(stateSearchParams || {});

  useEffect(() => {
    if (!isObjectsEqual(searchParams, prevSearchParams)) {
      let newSearchParams;
      if (searchParams.status !== 'CS' && searchParams.status !== 'PS') {
        delete searchParams.suspendReason;
      }
      if (isSearchParamsOverwritten) {
        newSearchParams = { ...searchParams };
      } else {
        newSearchParams = {
          ...searchParams,
          ...(searchParams.status && { status: searchParams.status }),
        };
      }
      setSearchParams(newSearchParams);
    }
  }, [searchParams]);

  const { handlePanelOpened, isOpened } = props;

  return (
    <div className="ccm-search-wrapper d-flex flex-column h-100">
      <Button
        size="lg"
        variant="link"
        className="mb-0"
        onClick={handlePanelOpened}
        data-test="qaSearch_searchMenu"
      >
        <i className="bi-list ml-1" />
      </Button>
      {isOpened
        && (<Button
          size="sm"
          variant="link"
          data-for="tooltip-qaSearch"
          data-tip='Use "*" as a wildcard in the text fields to see all patients with any value in the corresponding parameter'
        >
          <i className="bi-info-circle-fill" />
        </Button>
        )
      }
      <Accordion className="w-100 flex-grow-1 overflow-auto">
        <Card className="border-0">
          <ContextAccordionHeader eventKey="0" isOpened={isOpened} openPanel={handlePanelOpened}>
            <i className="card-header-icon bi-person-fill mr-2" data-test="qaSearch_patientCard" />
            {isOpened && <span className="card-header-title" data-test="patientSearch_patientMenu">Patient</span>}
          </ContextAccordionHeader>
          <Accordion.Collapse eventKey="0">
            <QaPatientFilter />
          </Accordion.Collapse>
        </Card>

        <Card className="border-0">
          <ContextAccordionHeader eventKey="1" isOpened={isOpened} openPanel={handlePanelOpened}>
            <i className="card-header-icon bi-building mr-2" data-test="qaSearch_pcpCard" />
            {isOpened && <span className="card-header-title" data-test="patientSearch_pcpMenu">PCP</span>}
          </ContextAccordionHeader>
          <Accordion.Collapse eventKey="1">
            <QaPcpFilter />
          </Accordion.Collapse>
        </Card>

        <Card className="border-0">
          <ContextAccordionHeader eventKey="2" isOpened={isOpened} openPanel={handlePanelOpened}>
            <i className="card-header-icon bi-journal-text mr-2" data-test="qaSearch_ccmCard" />
            {isOpened && <span className="card-header-title" data-test="patientSearch_ccmMenu">CCM</span>}
          </ContextAccordionHeader>
          <Accordion.Collapse eventKey="2">
            <QaCcmFilter />
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <div className="enabled-filters mt-auto pt-2" data-test="qaSearch_enabledFilters">
        <QaFilterInformation />
      </div>
      <ReactTooltip id="tooltip-qaSearch" type="info" effect="float" place="right" />
    </div>
  );
};

export function ContextAccordionHeader({
  children, eventKey, callback, openPanel, isOpened,
}) {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const handleClick = () => {
    if (!isOpened) openPanel();
    decoratedOnClick();
  };

  useEffect(() => {
    if (!isOpened && isCurrentEventKey) {
      decoratedOnClick();
    }
    ReactTooltip.rebuild();
  }, [isOpened]);

  return (
    <Card.Header className={`${isCurrentEventKey ? 'is-active' : ''} py-3`}>
      <Button
        variant="link"
        onClick={handleClick}
        className="d-flex align-items-center w-100 p-0"
        data-test="qaSearch_accordionHeader"
      >
        {children}
      </Button>
    </Card.Header>
  );
}

export default QaSearch;
