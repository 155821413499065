// libraries
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
// constants
import { DATE_FORMAT } from '../../constants/constants';
// styles
import 'react-datepicker/dist/react-datepicker.css';
// services
import { validateDate } from '../../services/helpers';

const DEFAULT_MAX_DATE = '2038-01-18';
const DEFAULT_MIN_DATE = '1970-01-02';

const DATE_SEPARATOR = '/';

function isEmpty(value) {
  return !value && value !== 0;
}

const getNumberWithNull = value => (`0${value}`).slice(-2);

export class OneFieldDatePicker extends Component {
  constructor(props) {
    super(props);

    const isValueEmpty = isEmpty(props.initialValue);
    const value = !isValueEmpty ? moment(props.initialValue).toDate() : '';

    this.state = {
      value,
      oldValue: value,
      validation: {
        valid: true,
        validationMessage: '',
      },
      isDateUpdated: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;

    if (initialValue !== prevProps.initialValue) {
      const isValueEmpty = isEmpty(initialValue);
      const value = !isValueEmpty ? moment(initialValue).toDate() : '';

      this.setState({
        value,
        oldValue: value,
      });
    }
  }

  validate = (submitCallback) => {
    const { validationCallback = validateDate } = this.props;

    if (!validationCallback) {
      if (submitCallback) {
        submitCallback();
      }
      return true;
    }

    const { value, oldValue } = this.state;
    const { maxDate = DEFAULT_MAX_DATE, minDate = DEFAULT_MIN_DATE, ...params } = this.props;

    const validationResult = validationCallback(value, { ...params, maxDate, minDate });

    this.setState({
      validation: {
        valid: !validationResult,
        validationMessage: validationResult,
      },
      value: validationResult ? oldValue : value,
    }, submitCallback);

    return !validationResult;
  };

  submit = () => {
    const { fieldKey, submitCallback } = this.props;
    const { value, oldValue, validation: { valid } } = this.state;
    const newValue = value ? moment(value).format(DATE_FORMAT.FULL_SERVER) : null;

    if (!submitCallback || oldValue === newValue) {
      return;
    }

    submitCallback(fieldKey, newValue, valid);

    this.setState({
      oldValue: value,
    });
  };

  handleChangeDate = (date) => {
    const { required } = this.props;
    this.setState(state => ({
      value: (required && !date) ? state.value : date,
    }), this.validate);
  };

  handleChangeRaw = (element) => {
    const { timezone } = this.props;
    const { value } = element;
    const dateInput = element;

    if (value) {
      const allSeparatorsInDate = value.match(/\//g);
      const numberOfSeparators = allSeparatorsInDate && allSeparatorsInDate.length;

      if (numberOfSeparators) {
        const dateData = value.split(DATE_SEPARATOR);
        const month = +dateData[0];
        const day = dateData[1];
        const year = dateData[2];
        const currentYear = moment().tz(timezone).year();

        if (!month) {
          return;
        }

        // update month in date
        if (numberOfSeparators === 1) {
          dateData[0] = getNumberWithNull(month);
        }

        // update day in date
        if (numberOfSeparators !== 1 && !year) {
          dateData[1] = day && getNumberWithNull(day);
        }

        // update month in datepicker's input
        let newDate = dateData.join(DATE_SEPARATOR);
        dateInput.value = newDate;
        this.setState({
          isDateUpdated: moment(newDate).isValid(),
        });

        // update year in date
        if (numberOfSeparators === 2 && year) {
          if (year > 99 && year < 1000) {
            dateData[2] = currentYear;
          } else if (year > 49 && year < 100) {
            dateData[2] = `${(currentYear - 1).toString().slice(2)}${getNumberWithNull(year)}`;
          } else if (year < 49) {
            dateData[2] = `${currentYear.toString().slice(2)}${getNumberWithNull(year)}`;
          }
        }

        // update the displayed date
        newDate = moment(dateData.join('/'), DATE_FORMAT.FULL).toDate();
        this.handleChangeDate(newDate);
      }
    }
  };

  getClassName = (date, selectDate, today) => {
    let dayClassName = '';
    const currentDate = moment(date).format(DATE_FORMAT.FULL);

    if (currentDate === selectDate) {
      dayClassName += 'day-selected ';
    }

    if (currentDate === today) {
      dayClassName += 'day-today';
    }
    return dayClassName;
  };

  render() {
    const {
      disabled, maxDate, minDate, wrapperClassName,
      todayButton, notDisplayMinDay, timezone, dateFormat, dataTest,
    } = this.props;
    const { value, validation, isDateUpdated } = this.state;
    let className = wrapperClassName || '';
    const newMinDate = notDisplayMinDay && minDate
      ? moment(minDate).add(1, 'd').format(DATE_FORMAT.FULL)
      : minDate;
    let selectDate = moment(value).format(DATE_FORMAT.FULL);
    const today = moment.tz(timezone).format(DATE_FORMAT.FULL);
    let validationLabel;

    if (!validation.valid) {
      className += ' invalid';
      validationLabel = <small className="validation-label d-block mt-1">{validation.validationMessage}</small>;
    }

    if (!value && (minDate || maxDate)) {
      if (moment(today).diff(newMinDate) < moment(today).diff(maxDate)) {
        selectDate = moment(newMinDate).format(DATE_FORMAT.FULL);
      } else {
        selectDate = moment(maxDate).format(DATE_FORMAT.FULL);
      }
    }

    const newMaxDate = maxDate
      ? moment(maxDate).toDate()
      : moment.tz(DEFAULT_MAX_DATE, timezone).toDate();
    const validMinDate = minDate
      ? moment(newMinDate).toDate()
      : moment.tz(DEFAULT_MIN_DATE, timezone).toDate();

    const content = (
      <DatePicker
        todayButton={todayButton ? 'Today' : null}
        selected={value}
        onChange={this.handleChangeDate}
        name="date"
        maxDate={newMaxDate}
        minDate={validMinDate}
        disabled={disabled}
        autoComplete="off"
        className={value !== '' ? 'dateText' : ''}
        dayClassName={date => (this.getClassName(date, selectDate, today))}
        dateFormat={dateFormat}
        onChangeRaw={event => this.handleChangeRaw(event.target)}
        openToDate={isDateUpdated ? value : null}
        onCalendarClose={() => { this.validate(this.submit); }}
        {...(dataTest && { 'data-test': dataTest })}
      />
    );

    return (
      <div className={`one-field-date-picker ${className}`} {...(dataTest && { 'data-test': dataTest })}>
        {content}
        {validationLabel}
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    timezone: state.tenant && state.tenant.timezone,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(OneFieldDatePicker);
