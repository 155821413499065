// Libraries
import {
  object, string, mixed, date,
} from 'yup';
import moment from 'moment-timezone';
// Services
import { formatPhone } from '../../../services/helpers';
// Constants
import { EHR_OPTIONS, HIPAA_OPTIONS } from '../../careTeam';
import { DATE_FORMAT } from '../../constants';


export const ASSIGN_PATIENTS_SCHEMA = () => object({
  type: string().required('Required'),
  tenantsSelect: object().typeError('Select a valid user')
    .required('Required'),
});

export const RESOURCE_SCHEMA = () => object({
  interventionCategory: string().typeError('Select a valid category').required('Required'),
  interventionType: string().typeError('Select a valid type').required('Required'),
  interventionNote: string().required('Required'),
});

export const CARE_TEAM_SCHEMA = () => object({
  category: string()
    .typeError('Select a valid Category')
    .required('Required'),
  type: string()
    .typeError('Select a valid Type/Relationship')
    .required('Required'),
  isInEhr: mixed()
    .oneOf(EHR_OPTIONS.map(item => item.value))
    .defined(),
  name: string()
    .strict(true)
    .min(1, 'The minimum is one character')
    .max(250, 'The limit is 250 characters')
    .trim('The Name cannot include leading or trailing spaces.')
    .required('Required'),
  phoneNumber: string()
    .test('Length', 'must contain 10 digits', (value) => {
      const valueFormatted = formatPhone(value);
      return (!value || valueFormatted === '') ? true : valueFormatted.replace(/\D/g, '').length === 10;
    }),
  hipaaConsentType: mixed()
    .oneOf([...HIPAA_OPTIONS.map(item => item.value), null]),
  streetAddress: string()
    .strict(true)
    .max(500, 'The limit is 500 characters')
    .trim('The Street cannot include leading or trailing spaces.'),
  city: string()
    .strict(true)
    .max(250, 'The limit is 250 characters')
    .trim('The City cannot include leading or trailing spaces.'),
  state: string()
    .strict(true)
    .max(2, 'The limit is 2 digits')
    .trim('The State cannot include leading or trailing spaces.'),
  zipcode: string()
    .strict(true)
    .max(10, 'The limit is 10 characters')
    .trim('The Zip cannot include leading or trailing spaces.'),
  note: string()
    .max(1000, 'The limit is 1000 digits'),
});

export const INSURANCE_SCHEMA = () => object({
  companyName: string()
    .min(1, 'The minimum is one character')
    .max(250, 'The limit is 250 characters')
    .required('Required'),
  memberId: string()
    .max(250, 'The limit is 250 characters'),
  effectiveDate: date()
    .nullable()
    .typeError('Must be a valid date')
    .min(moment.utc().format(DATE_FORMAT.SHORT), 'Must not be in the past'),
});
