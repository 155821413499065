// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
// Services
import { getDaysRemaining } from '../../services/helpers';
// Views
import ApproveBilling from '../base/ApproveBilling';
import AssignPatients from './fixedListHeader/AssignPatients';
import BulkUpdateNextActionDate from './fixedListHeader/BulkUpdateNextActionDate';
import BulkChangePatientStatus from './fixedListHeader/BulkChangePatientStatus';
import BulkFilter from './fixedListHeader/BulkFilter';
import BulkChangeAttribute from './fixedListHeader/BulkChangeAttribute';
import useLoadHolidays from '../../hooks/services/useLoadHolidays';

/**
 * PatientListActions component, requires props:
 * selectedPatientsIds -- array of selected patients id
 * loadPatients -- function to load patients
 * unSelectCallback -- callback for unselecting patients
 */

export const PatientListActions = (props) => {
  const { selectedPatientsIds, isOpened } = props;
  const { permissions: userPermissions } = useSelector(state => state.user);

  const { holidays } = useLoadHolidays();

  function reloadData() {
    try {
      props.unSelectCallback();
      props.loadPatients();
    } catch (err) {
    }
  }

  let assignPatientsButton;

  if (userPermissions.canAssignPatients) {
    assignPatientsButton = (
      <AssignPatients
        reloadData={reloadData}
        selectedPatientsIds={selectedPatientsIds}
      />
    );
  }
  return (
    <div className="patient-list-actions d-flex-center h-100">
      {isOpened && (
        <div className="h-100 d-flex-center flex-column border rounded px-2 py-1">
          <small className="text-nowrap text-uppercase">Days remaining</small>
          <h5 className="mb-0">{getDaysRemaining(holidays)}</h5>
        </div>)}
      <div className="d-flex-center-between px-1 ml-1 flex-grow-1 flex-wrap">
        <div className="h-100 d-flex-center flex-column pr-3 py-1">
          <small className={`text-nowrap text-uppercase ${selectedPatientsIds && !selectedPatientsIds.size > 0 && 'text-ccm-inactive'}`}>Bulk Actions</small>
        </div>
        {assignPatientsButton}
        <ApproveBilling
          selectedPatientsIds={Array.from(selectedPatientsIds)}
          reloadData={reloadData}
          data-test="patientListAction_approveBilling"
        />
        <BulkUpdateNextActionDate
          selectedPatientsIds={selectedPatientsIds}
          reloadData={reloadData}
        />
        <BulkChangePatientStatus
          selectedPatientsIds={selectedPatientsIds}
          reloadData={reloadData}
        />
        <BulkFilter />
        <BulkChangeAttribute
          selectedPatientsIds={selectedPatientsIds}
          reloadData={reloadData}
        />
      </div>
    </div>
  );
};

export default PatientListActions;
