// Config
import { store } from '../config/store';
// Action
import { UpdateUserAuth } from '../actions/user';
// Constants
import { AUTH_PROVIDERS } from '../constants/constants';
// Services
import { getTenant } from './helpers';
import { getUserFromStorage, updateUserAuthInStorage } from './userStorage';

export const setKeyCloakTokens = (keycloak) => {
  const tenantUrl = getTenant();
  const user = getUserFromStorage(tenantUrl);

  if (user && user.authData.loggedInWith === AUTH_PROVIDERS.KEYCLOAK) {
    const authData = {
      idToken: {
        jwtToken: keycloak.idToken,
        exp: keycloak.idTokenParsed.exp,
      },
      accessToken: {
        jwtToken: keycloak.token,
        exp: keycloak.tokenParsed.exp,
      },
      refreshToken: {
        jwtToken: keycloak.refreshToken,
      },
      loggedInWith: AUTH_PROVIDERS.KEYCLOAK,
    };
    store.dispatch(UpdateUserAuth(authData));
    updateUserAuthInStorage(authData, tenantUrl);
  }
};
