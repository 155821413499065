// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
// Components
import { withRouter } from '../shared/WithRouter';
// Views
import PatientInfo from './PatientInfo';
import LoadingBlock from './LoadingBlock';
// Services
import { isObjectsEqual } from '../../services/helpers';
// Constants
import { ENABLE_PATIENT_PROMPTS } from '../../constants/prompts';
import { CN_MENU_ITEMS, ELIGIBILITY_ISSUES, USER_ROLES } from '../../constants/constants';

// exported for testing purposes
export function checkPatient(patient) {
  return patient && Object.keys(patient).length > 0;
}

export class PatientPageMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: !checkPatient(props.patient),
    };
  }

  componentDidUpdate(prevProps) {
    const { patient } = this.props;
    if (!isObjectsEqual(patient, prevProps.patient)) {
      this.setState({
        loading: !checkPatient(patient),
      });
    }
  }

  renderMenuItems() {
    return CN_MENU_ITEMS.map((item) => {
      const menuItems = [];

      menuItems.push(this.renderMenuItem(item, false));

      return menuItems;
    });
  }

  renderMenuItem(item) {
    const {
      patient = {}, enablePrompts,
      user: { isExternal: isExternalUser = true, role: userRole },
    } = this.props;

    const isMenuItemHidden = userRole === USER_ROLES.PES && item.isHiddenForPES;
    const isMenuItemInternalOnly = isExternalUser && item.isInternalOnly;

    if ((patient.permissions && item.permission && !patient.permissions[item.permission])
      || isMenuItemHidden || isMenuItemInternalOnly) {
      return null;
    }

    if (!enablePrompts && item.url === 'prompts') return null;

    let iconBlock;
    switch (item.iconProperty) {
      case 'actionsRequired':
        if (patient.billing && patient.billing.sendingStatus === 'ACTIONS_REQUIRED') {
          iconBlock = <span className="bi-exclamation-triangle text-danger ml-2" />;
        }
        break;
      case 'billing':
      case 'finalize': {
        let isBillingWarningActive = false;
        if (patient.billing && patient.billing.issues && patient.billing.issues.length) {
          isBillingWarningActive = patient.billing.issues.some(
            issue => ELIGIBILITY_ISSUES[issue]
              && ELIGIBILITY_ISSUES[issue].menuTab === item.iconProperty,
          );
        }

        let hasBeenFinalizedThisMonth = true;
        if (item.iconProperty === 'finalize') {
          const { recentCp } = patient;
          if (recentCp !== null && recentCp !== undefined) {
            const recentCpTimestamp = Date.parse(recentCp);
            if (!Number.isNaN(recentCpTimestamp)) {
              const recentCpDate = new Date(recentCpTimestamp);
              const currentDate = new Date();
              hasBeenFinalizedThisMonth = recentCpDate.getUTCMonth() === currentDate.getMonth()
                && recentCpDate.getFullYear() === currentDate.getFullYear();
            }
          }
        }

        if (isBillingWarningActive || !hasBeenFinalizedThisMonth) {
          iconBlock = <span className="bi-exclamation-triangle text-danger ml-2" />;
        }
        break;
      }
      case 'prompts':
        if (patient.missingPrompts) {
          iconBlock = <span className="bi-exclamation-triangle text-danger ml-2" />;
        }
        break;
      default:
    }

    return (
      <NavLink
        key={`menu-item-${item.id || item.url}`}
        to={item.url}
        className={({ isActive }) => `d-block text-left my-2${isActive ? ' active' : ''}`}
      >
        {item.title}
        {iconBlock}
      </NavLink>
    );
  }

  render() {
    const { reloadData, params: { id: patientId } } = this.props;

    return (
      <div className="ccm-patient-menu-wrapper d-flex flex-column h-100">
        <div className="patient-menu-info">
          {this.state.loading ? <LoadingBlock /> : (
            <PatientInfo
              patientId={patientId}
              reloadData={reloadData}
            />
          )}
        </div>
        <div className="patient-menu-items box-wrapper flex-grow-1 overflow-auto mt-2 py-2" data-test="patientMenuItems">
          {this.renderMenuItems()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const features = state.tenant && state.tenant.features && state.tenant.features.length > 0
    ? state.tenant.features : [];
  const patientPrompts = features.filter(item => item.featureName === ENABLE_PATIENT_PROMPTS);
  const enablePrompts = patientPrompts && patientPrompts.length === 1 && patientPrompts[0].enabled;

  return {
    enablePrompts,
    user: state.user,
    patient: state.patient,
  };
}

export default withRouter(connect(mapStateToProps)(PatientPageMenu));
