// Libs
import React, {
  Fragment, useEffect, useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _startCase from 'lodash/startCase';
import _capitalize from 'lodash/capitalize';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
// Actions
import { ResetSideBarQaSearch } from '../../../actions/qaSearch';
// Services
import { getCcmConditions } from '../../../services/providers';
import { fetchData, prepareCnList } from '../../../services/helpers';
// Constants
import { SEARCH_PARAMS } from '../../../constants/constants';


export const QaFilterInformation = () => {
  const {
    user, cnList, pesList,
    requestsInProgress: { count: loading }, tenant: { tags = [] },
    qaSearch: { searchParams: stateSearchParams, pcpPhysicianName, pcpLocationName },
  } = useSelector(state => state);

  const [tagIdsList, setTagIdsList] = useState([]);
  const [ccmConditionsList, setCcmConditionsList] = useState([]);

  const dispatch = useDispatch();
  const { ...enabledFilters } = stateSearchParams;
  const parseKey = key => _startCase(key).replace(/\s+/g, '_').toUpperCase();

  const ownerList = prepareCnList([...cnList, ...pesList], user);
  const getSelectedOwner = ownerValue => ownerList.find(ph => ph.value === ownerValue) || { label: '' };

  const getTagIdsList = () => {
    if (tagIdsList.length === 0) {
      const activeTags = tags.filter(el => !el.deleted);
      setTagIdsList(activeTags);
    }
  };

  const handleCcmAsyncList = async () => {
    if (ccmConditionsList.length === 0) {
      const ccmData = await fetchData(getCcmConditions());
      setCcmConditionsList(ccmData);
      return ccmData;
    }
    return ccmConditionsList;
  };

  const getFiltersLabel = Object.entries(enabledFilters).map(([key, value]) => {
    let valueToShow = '';
    switch (key) {
      case SEARCH_PARAMS.CARE_NAVIGATOR_ID.key:
        valueToShow = getSelectedOwner(value).label;
        break;
      case SEARCH_PARAMS.CHRONIC_CONDITION_IDS.key:
        if (ccmConditionsList && ccmConditionsList.length > 0) {
          const conditions = [];
          value.forEach((val) => {
            conditions.push(
              ccmConditionsList.find(ccmCondition => ccmCondition.id === val).name,
            );
          });
          valueToShow = conditions.join(', ');
        }
        break;
      case SEARCH_PARAMS.TAG_IDS.key:
        if (tagIdsList && tagIdsList.length > 0) {
          const conditions = [];
          value.forEach((val) => {
            conditions.push(
              tagIdsList.find(tag => tag.id === val).displayName,
            );
          });
          valueToShow = conditions.join(', ');
        }
        break;
      case SEARCH_PARAMS.PHYSICIAN_ID.key:
        if (pcpPhysicianName) valueToShow = pcpPhysicianName;
        break;
      case SEARCH_PARAMS.PRIMARY_PHYSICIAN_LOCATION_ID.key:
        if (pcpLocationName) valueToShow = pcpLocationName;
        break;
      case SEARCH_PARAMS.EHR_IDS.key:
      case SEARCH_PARAMS.PATIENT_IDS.key:
        valueToShow = '';
        break;
      default:
        valueToShow = `${_capitalize(_startCase(value))}`;
    }

    return (
      <p key={`filter__${key}`} className="mb-1">
        {`${SEARCH_PARAMS[parseKey(key)] ? SEARCH_PARAMS[parseKey(key)].label : key}${valueToShow ? `: ${valueToShow}` : ''}`}
      </p>
    );
  });

  const resetSideBarSearchAction = () => {
    if (loading) return;
    dispatch(ResetSideBarQaSearch());
  };

  useEffect(() => getTagIdsList(), [tags]);

  useEffect(() => {
    handleCcmAsyncList();
  }, []);

  const cleanFiltersPopover = (
    <Popover>
      <Popover.Content>Clear sidebar filters</Popover.Content>
    </Popover>);

  const getFiltersPopover = (
    <Popover>
      <Popover.Title><b>Enabled filters</b></Popover.Title>
      <Popover.Content>
        {!Object.keys(enabledFilters).length ? 'No filters enabled' : getFiltersLabel}
      </Popover.Content>
    </Popover>);

  const renderFiltersCount = count => <span className="filters-badge d-flex-center rounded-circle">{count}</span>;

  return (
    <Fragment>
      <OverlayTrigger trigger={['hover', 'click']} placement="top" overlay={cleanFiltersPopover}>
        <Button
          variant="link"
          className={`position-relative p-1 ${!Object.keys(enabledFilters).length ? 'inactive' : ''}`}
          onClick={resetSideBarSearchAction}
          disabled={!(Object.keys(enabledFilters).filter(filter => filter !== 'status').length > 0)}
          data-test="qaSearch_clearSidebarFilters"
        >
          <i className={`bi-funnel${!Object.keys(enabledFilters).length ? '' : '-fill'}`} />
          <i data-test="qaSearch_clearSidebarFiltersBtn" className={`clean-filters-badge d-flex-center bi bi-x-circle-fill ${Object.keys(enabledFilters).filter(filter => filter !== 'status').length > 0 ? 'text-ccm-red' : 'text-ccm-gray'}`} />
        </Button>
      </OverlayTrigger>
      <OverlayTrigger trigger={['hover', 'click']} placement="right" overlay={getFiltersPopover}>
        <Button
          variant="link"
          className={`position-relative p-1 ${!Object.keys(enabledFilters).length ? 'inactive' : ''}`}
        >
          <i className={`bi-funnel${!Object.keys(enabledFilters).length ? '' : '-fill'}`} data-test="qaSearch_activeSidebarFiltersBtn" />
          {renderFiltersCount(
            !Object.keys(enabledFilters).length ? 0 : Object.keys(enabledFilters).length,
          )}
        </Button>
      </OverlayTrigger>
    </Fragment>);
};

export default QaFilterInformation;
