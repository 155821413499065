import React from 'react';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import ReactTooltip from 'react-tooltip';

const WYSIWYG_ACTIVITIES = [
  'Care Plan - Notes created',
  'Care Plan - Notes changed',
  'Care Plan - Notes deleted',
];

const TABLE_VALUE_LENGTH = 13;

const convertToText = (value) => {
  let result = '—';
  try {
    result = convertFromRaw(JSON.parse(value)).getPlainText();
  } catch (e) {
    result = value || '—';
  }
  return result;
};

const getActivityCell = (item, field) => {
  const value = WYSIWYG_ACTIVITIES.includes(item.name)
    ? convertToText(item[field])
    : (item[field] || '—');

  const tooltip = (value.length > TABLE_VALUE_LENGTH ? (
    <ReactTooltip
      id={`tooltip-${item.activityId}-${field}`}
      type="info"
      place="bottom"
      effect="float"
    />)
    : null);

  return (
    <td data-for={`tooltip-${item.activityId}-${field}`} data-tip={value}>
      {value.length > TABLE_VALUE_LENGTH ? `${value.substring(0, TABLE_VALUE_LENGTH - 1)}...` : value}
      {tooltip}
    </td>
  );
};

const UserActivityTable = ({ data }) => (
  <table className="problem-list-table user-activity-table">
    <thead>
      <tr>
        <td>Username</td>
        <td>Date</td>
        <td>Activity</td>
        <td>Patient ID</td>
        <td>Old value</td>
        <td>New value</td>
      </tr>
    </thead>
    <tbody data-test="userActivityTable_tableBody">
      {data.map((item => (
        <tr key={item.activityId}>
          <td>{item.user ? `${item.user.firstName} ${item.user.lastName}` : '—'}</td>
          <td>{item.date ? moment(item.date).format('MM/DD/YY h:mm a') : 'N/A'}</td>
          <td>{item.name}</td>
          <td>{item.patientEhrId || '—'}</td>
          {getActivityCell(item, 'oldValue')}
          {getActivityCell(item, 'newValue')}
        </tr>
      )))}
    </tbody>
  </table>
);

export default UserActivityTable;
