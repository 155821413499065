// Libraries
import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
// Actions
import { Search } from '../../../actions/search';
// Constants
import {
  DIALOG_STYLES, BULK_FILTER_IDS_REGEX, BULK_FILTER_NUMBERS_IDS_REGEX,
} from '../../../constants/constants';
// Components
import { TextArea } from '../../base/forms/TextArea';
import { Checkbox } from '../../base/forms/Checkbox';


export const patientStatusSchema = () => Yup.object({
  identifierType: Yup.string(),
  textAreaIds: Yup.string()
    .when('identifierType',
      (identifierType) => {
        if (identifierType !== 'patientIds') return Yup.string().required('Required').matches(BULK_FILTER_IDS_REGEX, 'Must contain separated alphanumeric delimited by commas');
        return Yup.string().required('Required').matches(BULK_FILTER_NUMBERS_IDS_REGEX, 'Must contain separated numeric delimited by commas');
      }),
});

/**
 * BulkFilterModal component, requires props:
 * isModalOpen - boolean to open modal
 * setIsModalOpen - function to change state isModalOpen variable
 */

export const BulkFilterModal = (props) => {
  const {
    isModalOpen, setIsModalOpen,
  } = props;

  const dispatch = useDispatch();
  const search = searchParams => dispatch(Search({ ...searchParams }, true, true));

  const DEFAULT_VALUES = {
    identifierType: 'patientIds',
    textAreaIds: '',
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveFilter = (values) => {
    search({
      ...(values.identifierType === 'patientIds' && { patientIds: values.ids }),
      ...(values.identifierType === 'ehrIds' && { ehrIds: values.ids }),
    });
    handleCloseModal();
  };


  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Change Status"
      data-test="bulkFilterModal_onRequestClose"
    >
      <div className="simple-dialog">
        <div className="dialog-title">
          Filter Patients
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="bulkFilterModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={DEFAULT_VALUES}
          validationSchema={patientStatusSchema()}
          onSubmit={(values) => {
            const formattedIds = values.textAreaIds.replace(/\s+/g, '').split(',');
            const updatedValues = { ...values, ids: formattedIds };
            saveFilter(updatedValues);
          }}
          data-test="bulkFilterModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <div className="col" data-test="bulkFilterModal_selectStatus">
                  <Form.Label>Identifier Type</Form.Label>
                  <div className="d-flex">
                    <Checkbox
                      id="bulkFilterModal_checkboxEhrIds"
                      data-test="bulkFilterModal_checkboxEhrIds"
                      label="EMR IDs"
                      name="identifierType"
                      type="radio"
                      className="mr-4"
                      value="ehrIds"
                    />
                    <Checkbox
                      id="bulkFilterModal_checkboxPatientIds"
                      data-test="bulkFilterModal_checkboxPatientIds"
                      label="SelectPatient Management Patient IDs"
                      type="radio"
                      name="identifierType"
                      value="patientIds"
                    />
                  </div>
                </div>
                <div className="col" data-test="bulkFilterModal_dateInput">
                  <TextArea
                    label="IDs"
                    placeholder="This field must contain comma separated list of numeric IDs. No special characters or other text can be included."
                    name="textAreaIds"
                    data-test="bulkFilterModal_attributeDescription"
                    rows={15}
                  />
                </div>
                <p style={{ fontSize: '0.8rem' }} className="font-weight-bold text-center">
                  Note: Applying this filter will remove
                  any existing filters in the current patient list
                </p>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="bulkFilterModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!(formik.isValid && formik.dirty)}
                  data-test="bulkFilterModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default BulkFilterModal;
