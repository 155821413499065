// Libraries
import React from 'react';
import Modal from 'react-modal';
// Components
import { Button } from 'react-bootstrap';
// Constants
import { DIALOG_STYLES } from '../../../constants/constants';

export const SuspendBillingConfirmModal = (props) => {
  const {
    isModalOpen, setIsModalOpen, saveSuspendBilling,
  } = props;

  const handleCloseModal = () => setIsModalOpen(false);

  const handleDataSend = () => {
    saveSuspendBilling({ onHold: false });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Suspend Billing"
      data-test="suspendBillingConfirmModal_onRequestClose"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          <i className="bi bi-exclamation-triangle-fill text-ccm-orange mr-3" />
          Are you sure?
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="suspendBillingConfirmModal_closeBtn"
          />
        </div>
        <div className="dialog-content text-left px-3 py-4">
          <span>
            Turning this setting off will immediately re-enable billing for this patient.
            If you have logged 20+ minutes of time this month, with at least one direct contact,
            a bill will be sent for this patient. Are you sure you want to continue?
          </span>
        </div>
      </div>
      <div className="dialog-buttons justify-content-end mt-0 mb-4 px-4">
        <Button
          variant="light"
          onClick={() => handleCloseModal()}
          data-test="suspendBillingConfirmModal_cancelBtn"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="ml-2"
          onClick={() => handleDataSend()}
          data-test="suspendBillingConfirmModal_saveBtn"
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};

export default SuspendBillingConfirmModal;
