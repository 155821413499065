import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import _groupBy from 'lodash/groupBy';
import _camelCase from 'lodash/camelCase';
import { connect } from 'react-redux';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { Button, Row, Form } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
// Services
import { updateNextActionDate, getNodPatientsByDate } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  USER_ROLES, PATIENT_CALL_TIME_OPTIONS, DATE_FORMAT,
  NOTIFICATION_TYPE, DIALOG_STYLES, NEXT_OUTREACH_DATE_BLANK,
} from '../../../constants/constants';
// Components
import { Select } from '../../base/forms/Select';
import { Checkbox } from '../../base/forms/Checkbox';
import { Datepicker } from '../../base/forms/Datepicker';

export function NextOutreachDateModal(props) {
  const {
    isModalOpen, setIsModalOpen, initialNextOutreachDate,
    user: { id: userId, role: userRole }, showNotification,
    patient: {
      firstName, lastName,
      profile: {
        preferredCallTime,
        consentToTextMessages: emrTextConsent,
        textMessageConsent: engoodenTextConsent,
      } = {},
    } = {}, nextOutreachElementSave,
  } = props;

  const isCnOrPes = userRole === USER_ROLES.CN || userRole === USER_ROLES.PES;
  const profilePreferredCallTime = (preferredCallTime === undefined || preferredCallTime === 'NONE')
    ? 'ANYTIME' : preferredCallTime;

  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);

  // This is the maximum year for java.sql.Timestamp
  const maxValidDate = moment('12/31/9999', DATE_FORMAT.FULL_WITH_LOWERCASE);

  const DEFAULT_NEXT_OUTREACH_DATE = {
    id: '',
    date: moment(),
    preferredCallTime: profilePreferredCallTime,
    contactMethod: 'phone',
  };

  const [newOutreachDate, setNewOutreachDate] = useState(DEFAULT_NEXT_OUTREACH_DATE);

  const getPreferredCallTime = (initialValues) => {
    if (initialValues.reminderTime === null) {
      return 'NONE';
    }
    return initialValues.reminderTime
      && initialValues.reminderDate
      ? initialValues.reminderTime : profilePreferredCallTime;
  };

  useEffect(() => {
    if (initialNextOutreachDate) {
      setNewOutreachDate({
        id: initialNextOutreachDate.id || '',
        date: (initialNextOutreachDate.reminderDate
          && moment(initialNextOutreachDate.reminderDate).isValid())
          ? moment(initialNextOutreachDate.reminderDate)
          : moment(),
        preferredCallTime: getPreferredCallTime(initialNextOutreachDate),
        contactMethod: initialNextOutreachDate.contactMethod || 'phone',
      });
    } else {
      setNewOutreachDate(DEFAULT_NEXT_OUTREACH_DATE);
    }
  }, [initialNextOutreachDate, preferredCallTime]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveNextOutreachDate = (updatedNextOutreachInfo) => {
    const { updatePatient, patientId, loading } = props;

    if (loading) return {};

    const updateNextOutreachDateRequest = updateNextActionDate(patientId, updatedNextOutreachInfo);
    const updateNextOutreachDatePromise = updateNextOutreachDateRequest.promise;

    return updateNextOutreachDatePromise.then(() => {
      delete updateNextOutreachDatePromise.promise;
      const reminderDateUpdated = updatedNextOutreachInfo && updatedNextOutreachInfo.date
        ? moment(updatedNextOutreachInfo.date, DATE_FORMAT.FULL)
          .format(DATE_FORMAT.FULL_SERVER) : null;
      updatePatient({
        nextActionDateReminderInfo: {
          id: updatedNextOutreachInfo.id,
          reminderDate: reminderDateUpdated,
          contactMethod: updatedNextOutreachInfo.contactMethod,
          reminderTime: updatedNextOutreachInfo.preferredCallTime,
        },
      });
      if (nextOutreachElementSave) {
        nextOutreachElementSave({
          id: updatedNextOutreachInfo.id,
          reminderDate: reminderDateUpdated,
          contactMethod: updatedNextOutreachInfo.contactMethod,
          reminderTime: updatedNextOutreachInfo.preferredCallTime,
        });
      }
      showNotification({
        message: 'Next outreach info saved.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
      handleCloseModal();
    }).catch((error) => {
      delete updateNextOutreachDatePromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) return;

      showNotification({
        message: 'Could not update next outreach info.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleCallTimeOptions = () => {
    const callTimeOptions = [];
    PATIENT_CALL_TIME_OPTIONS.forEach((opt) => {
      callTimeOptions.push({
        value: opt.value,
        label: `${opt.name} ${preferredCallTime === profilePreferredCallTime && profilePreferredCallTime === opt.value ? '(Preferred)' : ''}`,
      });
    });
    return callTimeOptions;
  };

  const clearNextOutreachDate = async () => {
    saveNextOutreachDate(NEXT_OUTREACH_DATE_BLANK);
    handleCloseModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Next Outreach Date Modal"
      data-test="nextOutreachDateModal_onRequestClose"
    >
      <div className="simple-dialog medium-dialog nextOutreachDate-modal">
        <div className="dialog-title">
          {`${initialNextOutreachDate ? 'Edit' : 'Add'} Next Outreach Date`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="nextOutreachDateModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={newOutreachDate}
          validationSchema={Yup.object({
            date: Yup.date()
              .typeError('Must be a valid date')
              .min(moment(todayDate).format(DATE_FORMAT.SHORT), 'Must not be in the past')
              .max(maxValidDate, 'The year of the date should not exceed 4 digits')
              .required('Required'),
            preferredCallTime: Yup.string().typeError('Please select a time frame').required('Required'),
            contactMethod: Yup.string().oneOf(['phone', 'text']).required('Required'),
          })}
          onSubmit={(values, { resetForm }) => {
            const formattedDate = values.date
              ? moment(values.date, DATE_FORMAT.FULL).format(DATE_FORMAT.FULL) : null;
            const updatedValues = { ...values, date: formattedDate };
            saveNextOutreachDate(updatedValues);
            resetForm();
          }}
          data-test="nextOutreachDateModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="text-left dialog-content">
                <Row className="align-items-center mb-3">
                  <div className="col">
                    <Datepicker
                      name="date"
                      showYearDropdown
                      minDate={new Date()}
                      maxDate={maxValidDate.toDate()}
                      showDaysAlert
                      inline
                      todayButton="Today"
                    />
                  </div>
                  <div className="col" data-test="nextOutreachDateModal">
                    <Datepicker
                      label="Date"
                      name="date"
                      dateFormat={DATE_FORMAT.FULL_WITH_LOWERCASE}
                      popperClassName="d-none"
                      data-test="nextOutreachDateModal_dateInput"
                    />
                    <Select
                      label="Time"
                      name="preferredCallTime"
                      options={handleCallTimeOptions()}
                      data-test="nextOutreachDateModal_timeInput"
                    />
                    <Form.Label>Type</Form.Label>
                    <Checkbox
                      id="outreachType_call"
                      label={<span className="bi-telephone-fill">&nbsp;Monthly Outreach Call</span>}
                      name="contactMethod"
                      value="phone"
                      type="radio"
                      data-test="nextOutreachDateModal_callInput"
                    />
                    {((engoodenTextConsent && engoodenTextConsent === 'ALLTEXTS') || emrTextConsent) && (
                    <Checkbox
                      id="outreachType_text"
                      label={<span className="bi-chat-right-dots" data-test="nextOutreachDateModal_textInput">&nbsp;Text Check-in</span>}
                      name="contactMethod"
                      value="text"
                      type="radio"
                    />)}
                  </div>
                </Row>
                {isCnOrPes && (
                <NodPatientsTable
                  maxValidDate={maxValidDate}
                  userId={userId}
                  showNotification={showNotification}
                  patientName={`${firstName} ${lastName}`}
                  data-test="nextOutreachDateModal_callSchedule"
                />
                )}
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="nextOutreachDateModal_cancelBtn">Cancel</Button>
                <Button
                  variant="light"
                  className="ml-2"
                  onClick={() => clearNextOutreachDate()}
                  data-test="nextOutreachDateModal_clearBtn"
                  data-for="tooltip-nextOutreachDate"
                  data-tip="Remove this Next Outreach Date"
                >
                  Clear Next Outreach Date
                </Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="nextOutreachDateModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ReactTooltip id="tooltip-nextOutreachDate" type="info" effect="solid" place="top" />
    </Modal>
  );
}

export const NodPatientsTable = (props) => {
  const { values: formikValues } = useFormikContext();
  const {
    userId, showNotification, patientName, maxValidDate,
  } = props;

  const [nodPatients, setNodPatients] = useState({});

  const loadNodPatients = (selectedDate) => {
    const getNodPatientsRequest = getNodPatientsByDate(userId, selectedDate);
    const getNodPatientsPromise = getNodPatientsRequest.promise;

    return getNodPatientsPromise.then((data) => {
      setNodPatients(data);
    }).catch((error) => {
      delete getNodPatientsRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load Next Outreach Date patients, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  useEffect(() => {
    if (formikValues && formikValues.date) {
      const selectedDate = moment(formikValues.date, DATE_FORMAT.FULL_WITH_LOWERCASE);
      const difference = selectedDate.diff(maxValidDate, 'years');

      if (difference < 0) {
        loadNodPatients(moment(formikValues.date, DATE_FORMAT.FULL)
          .format(DATE_FORMAT.FULL_SERVER));
      }
    }
  }, [formikValues]);

  const renderEmptyRow = () => (
    <p className="call-schedule__empty" data-test="nextOutreachDateModal_emptyCallSchedule">
      No scheduled patients on this day
    </p>);

  const renderPatientsRows = (patients) => {
    const groupedNodPatientsByTime = _groupBy(patients, el => el.time);
    return Object.entries(groupedNodPatientsByTime).map(([subcategory, rows]) => (
      <div
        className="p-2"
        key={`callSchedule_${_camelCase(subcategory)}`}
        data-test={`nextOutreachDateModal_callSchedule${_camelCase(subcategory)}`}
      >
        <p className="call-schedule__subcategory-name mb-1">{subcategory}</p>
        {rows.map(el => (
          <p
            key={`callSchedule_${_camelCase(el.name)}`}
            className={`call-schedule__patient-name ml-2 mb-1 ${patientName === el.name ? 'active' : ''}`}
            data-test="nextOutreachDateModal_callSchedulePatient"
          >
            {el.name}
          </p>))}
      </div>));
  };

  const renderNodPatientsTable = () => {
    const getTimeCategory = (time) => {
      switch (time) {
        case 'Morning':
        case 'Late morning':
        case 'Early morning':
          return 'Morning';
        case 'Afternoon':
        case 'Late afternoon':
        case 'Early afternoon':
          return 'Afternoon';
        default:
          return 'Anytime';
      }
    };
    const nodPatientsWithTime = [];
    Object.entries(nodPatients).map(([time, patients]) => patients.map(
      patient => nodPatientsWithTime.push(
        { name: patient.fullName, category: getTimeCategory(time), time },
      ),
    ));

    if (nodPatientsWithTime.length > 0) {
      const groupedNodPatientsByCat = _groupBy(nodPatientsWithTime, el => el.category);
      return Object.entries(groupedNodPatientsByCat).map(([category, patients]) => (
        <div
          key={`callSchedule_${category}`}
          className="call-schedule-category col-4"
          data-test={`nextOutreachDateModal_callSchedule${category}`}
        >
          <div className="call-schedule__category-name bg-ccm-blue px-2 py-1">
            {category}
          </div>
          {renderPatientsRows(patients)}
        </div>));
    }
    return renderEmptyRow();
  };

  return (
    <Row className="no-gutters call-schedule">
      <div className="call-schedule-heading d-flex-center mb-3">
        <i className="bi-calendar-check mr-2" />
        <span className="mb-0 mr-2" data-test="nextOutreachDateModal_callSchedule">Call Schedule</span>
        {formikValues && formikValues.date && (
        <small className="text-ccm-gray" data-test="nextOutreachDateModal_selectedDate">
          {moment(formikValues.date, DATE_FORMAT.FULL).format(DATE_FORMAT.FULL_WITH_DAY)}
        </small>)}
      </div>
      <Row data-test="nextOutreachDateModal_callScheduleBody" className="call-schedule-content overflow-auto no-gutters w-100">
        {renderNodPatientsTable()}
      </Row>
    </Row>);
};

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NextOutreachDateModal);
