// libraries
import React, { useState } from 'react';
import moment from 'moment-timezone';
import _groupBy from 'lodash/groupBy';
import { connect } from 'react-redux';
// views
import { Dropdown } from 'react-bootstrap';
// constants
import { EMPTY_STRING, DATE_FORMAT } from '../../../constants/constants';

const defaultYear = moment().format(DATE_FORMAT.YEAR);

export function Procedures(props) {
  const { patientHistory: { procedures } = {} } = props;
  const PROCEDURES_FILTERS = { [defaultYear]: { name: 'This Year' } };
  const proceduresWithYear = procedures && procedures.map(
    procedure => ({ ...procedure, createdAtYear: moment(procedure.date).format(DATE_FORMAT.YEAR) }),
  );
  const proceduresYearGrouped = _groupBy(proceduresWithYear, note => note.createdAtYear);
  Object.keys(proceduresYearGrouped).forEach((year) => {
    PROCEDURES_FILTERS[year] = {
      name: `${year}`,
    };
  });
  const [proceduresFilter, setProceduresFilter] = useState(defaultYear);

  const filterDropDownItems = Object.keys(PROCEDURES_FILTERS).map((key, index) => {
    const yearName = defaultYear === key ? 'This Year' : key;
    return (
      <Dropdown.Item
        data-test={`procedures_dropdownFilter_${index}`}
        key={`procedures_filter_${index}`}
        onClick={() => {
          setProceduresFilter(PROCEDURES_FILTERS[key].name);
        }}
        as="button"
        className="d-flex my-1"
      >
        <div className="pr-3">{yearName}</div>
      </Dropdown.Item>
    );
  });

  const getFilter = (
    <Dropdown
      className="position-absolute"
      style={{ left: 1 }}
    >
      <Dropdown.Toggle
        variant="ccm-light-gray"
        className="py-0"
      >
        {`Filter: ${defaultYear === proceduresFilter ? 'This Year' : proceduresFilter}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterDropDownItems}
      </Dropdown.Menu>
    </Dropdown>
  );

  function renderEmptyRow() {
    return (
      <tr>
        <td colSpan="3" className="p-2 border-0">
          This patient has no known procedures
        </td>
      </tr>
    );
  }

  const renderProceduresRows = (proceduresData) => {
    if (proceduresData && proceduresData.length) {
      return proceduresData.map((procedure, index) => {
        const dateString = procedure.date
          ? moment(procedure.date).format(DATE_FORMAT.SHORT) : EMPTY_STRING;
        let physicianName = EMPTY_STRING;
        if (procedure.physicianName) {
          physicianName = (
            `${procedure.physicianName.firstName || ''} ${procedure.physicianName.middleName || ''} ${procedure.physicianName.lastName || ''}`
          ).trim();
        }
        return (
          <tr key={`procedure_row_${index}_${procedure.date}-${procedure.name}`}>
            <td>
              {dateString}
            </td>
            <td>
              {physicianName}
            </td>
            <td>
              {procedure.name || EMPTY_STRING}
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  const renderProcedures = () => renderProceduresRows(proceduresYearGrouped[proceduresFilter]);

  return (
    <div className="card border-0 mb-4">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        {getFilter}
        <span className="text-uppercase">Procedures</span>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2">Date</th>
              <th className="py-2">Physician</th>
              <th className="py-2">Name</th>
            </tr>
          </thead>
          <tbody>
            {renderProcedures()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

export default connect(mapStateToProps)(Procedures);
