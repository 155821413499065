// Libraries
import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
// Actions
import ShowNotification from '../../actions/notification';
// Services
import { updateReviewedStatus } from '../../services/patient';
// Components
import Pager from '../patients/Pager';
import { PatientInfo } from '../menu/PatientInfo';
import UserAssignment from './filter/UserAssignment';
// Hooks
import usePrevious from '../../hooks/helpers/usePrevious';
import useLoadHistoryStatus from '../../hooks/services/useLoadHistoryStatus';
// Constants
import {
  DATE_FORMAT, EMPTY_STRING, FLIGHT_PLAN_STATUSES_FILTERS, NOTIFICATION_TYPE, REVIEW_NAMES,
} from '../../constants/constants';
import { DEFAULT_PAGE_SIZE } from '../../constants/pageSizes';
import StatusChangeUndoModal from './statusChangeReview/StatusChangeUndoModal';

export const StatusChangeReview = () => {
  const { tenant: tenantUrl } = useParams();

  const DEFAULT_FILTERS = {
    newStatus: '',
    oldStatus: '',
    reviewStatus: 'NOT_REVIEWED',
    dateRange: 'LAST_7_DAYS',
    userId: '',
  };

  const dispatch = useDispatch();

  const showNotification = notificationData => dispatch(ShowNotification(notificationData));


  const [currentPage, setCurrentPage] = useState(0);
  const [collapseAll, setCollapseAll] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const prevFilters = usePrevious(filters);
  const {
    historyStatuses, historyStatuses: { totalCount, totalPages }, refetch,
  } = useLoadHistoryStatus({ pageNumber: currentPage, optionalFilter: filters });

  const updateReviewedPatientStatus = (patientId, statusHistoryId, body) => {
    const updateReviewedStatusRequest = updateReviewedStatus(patientId, statusHistoryId, body);
    const updateReviewedStatusPromise = updateReviewedStatusRequest.promise;

    return updateReviewedStatusPromise.then(() => {
      delete updateReviewedStatusRequest.promise;
      refetch();
    }).catch((error) => {
      delete updateReviewedStatusRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not update history status, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getUrl = patientId => `/${tenantUrl}/cn/patient/${patientId}/summary`;

  const renderNoResults = () => {
    const noResultMsg = filters.filter === 'status' ? 'No history found' : 'No patient found';
    return (
      <div className="align-content w-100 mt-5">
        <span>{noResultMsg}</span>
      </div>
    );
  };

  const showingResults = () => {
    const tempCount = (currentPage + 1) === totalPages
      ? totalCount : DEFAULT_PAGE_SIZE * (currentPage + 1);
    return `${(currentPage * DEFAULT_PAGE_SIZE) + 1} - ${tempCount} of ${totalCount}`;
  };

  const handleOpenModal = (isOpen, patient) => {
    setSelectedPatient(patient);
    setIsModalOpen(isOpen);
  };

  useEffect(() => {
    if (!_isEqual(filters, prevFilters)) {
      setCurrentPage(0);
    }
  }, [
    filters.newStatus, filters.oldStatus,
    filters.reviewStatus, filters.dateRange,
  ]);

  return (
    <div className="flight-plan-status-change h-100 overflow-auto pr-3">
      <div className="d-flex my-3"><h4 className="text-left" data-test="statusChangeReview_headingText">Status Change Review</h4></div>
      <div className="d-flex align-items-center mt-0 mb-3">
        <CustomDropdown
          title="New Status"
          name="newStatus"
          selectedValue={filters.newStatus}
          handleOnChange={setFilters}
          options={FLIGHT_PLAN_STATUSES_FILTERS.newStatus}
          dataTest={{ toggle: 'statusChangeReview_newFilter', item: 'statusChangeReview_newFilterItem' }}
        />
        <CustomDropdown
          title="Old Status"
          name="oldStatus"
          selectedValue={filters.oldStatus}
          handleOnChange={setFilters}
          options={FLIGHT_PLAN_STATUSES_FILTERS.oldStatus}
          dataTest={{ toggle: 'statusChangeReview_oldFilter', item: 'statusChangeReview_oldFilterItem' }}
        />
        <CustomDropdown
          title="Review status"
          name="reviewStatus"
          selectedValue={filters.reviewStatus}
          handleOnChange={setFilters}
          options={FLIGHT_PLAN_STATUSES_FILTERS.reviewStatus}
          dataTest={{ toggle: 'statusChangeReview_reviewFilter', item: 'statusChangeReview_reviewFilterItem' }}
        />
        <CustomDropdown
          title="Date Range"
          name="dateRange"
          selectedValue={filters.dateRange}
          handleOnChange={setFilters}
          options={FLIGHT_PLAN_STATUSES_FILTERS.dateRange}
          dataTest={{ toggle: 'statusChangeReview_dateRangeFilter', item: 'statusChangeReview_dateRangeFilterItem' }}
        />
        <UserAssignment
          selectedValue={filters.userId}
          handleOnChange={setFilters}
        />
        <div className="ml-auto d-flex-center">
          <span className="medium-header-text">{`Results: ${showingResults()}`}</span>
          <Button
            size="sm"
            variant="link-dark"
            className="d-flex-center ml-2"
            onClick={() => setCollapseAll(!collapseAll)}
            data-test="statusChangeReview_collapseAllBtn"
          >
            <span className="medium-header-text">{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
            <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
          </Button>
        </div>
      </div>
      <div className="row no-gutters status-content">
        {!_isEmpty(historyStatuses) && historyStatuses.patients.map(patient => (
          <PatientHistoryStatusRow
            key={`flight-plan-patient__${patient.patientInfo.id}`}
            patient={patient}
            collapseAll={collapseAll}
            toUrl={getUrl(patient.patientInfo.id)}
            refreshCallback={refetch}
            handleAction={updateReviewedPatientStatus}
            handleOpenModal={handleOpenModal}
            data-test="statusChangeReview_patientHistoryRow"
          />
        ))}
        {
          (_isEmpty(historyStatuses)
          || !historyStatuses.patients.length) && renderNoResults()
        }
      </div>
      <div className="row statuses-pager no-gutters d-flex-center my-3">
        <Pager
          totalPages={totalPages}
          maxShownCount={5}
          isNextPrevShown
          currentPage={currentPage}
          callback={page => setCurrentPage(page)}
          data-test="statusChangeReview_pager"
        />
      </div>
      <StatusChangeUndoModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        patient={selectedPatient}
        reloadData={refetch}
      />
    </div>
  );
};

export const PatientHistoryStatusRow = (props) => {
  const {
    patient, collapseAll, refreshCallback,
    toUrl, handleAction, handleOpenModal,
  } = props;
  const [opened, setOpened] = useState(true);
  const dateOfChange = patient.dateOfChange ? moment(patient.dateOfChange, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL) : '';
  const dateOfReviewed = patient.reviewedDate ? moment(patient.reviewedDate, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL) : '';

  useEffect(() => {
    setOpened(!collapseAll);
  }, [collapseAll]);

  return (
    <div className={`row no-gutters w-100 mb-2 reminderRow-${opened ? 'opened' : 'closed'}`} data-test="statusChangeReview_rows">
      <div className={`col-4 patient-info border border-right-0 patientInfo-${opened ? 'opened' : 'closed'}`}>
        <PatientInfo
          patient={patient.patientInfo}
          fullInfo={opened}
          refreshCallback={refreshCallback}
          toUrl={toUrl}
          showNodElement
        />
      </div>
      <div className="col-8 d-flex flex-column border border-left-0 text-left p-3">
        <div className="content">
          <p className={`${opened ? '' : 'mb-0'}`} data-test="statusChangeReview_assignedCn">
            {`${patient.changedBy ? `${patient.changedBy}` : ''} changed patient’s status from ${patient.oldStatus} to ${patient.newStatus} on ${dateOfChange}`}
          </p>
          {opened && (
            <Fragment>
              <p data-test="statusChangeReview_reason">{`Reason: ${patient.reason || EMPTY_STRING}`}</p>
              <p data-test="statusChangeReview_note">{`Note: ${patient.note || EMPTY_STRING}`}</p>
            </Fragment>
          )}
        </div>
        {patient.reviewStatus === REVIEW_NAMES.NOT_REVIEWED.name && opened && (
          <div className="d-flex mt-auto justify-content-end">
            <Button
              size="sm"
              key="patient-status-undo"
              variant="danger"
              className="ml-2"
              onClick={() => handleOpenModal(true, patient)}
              data-test="statusChangeReview_undoBtn"
            >
              Undo this change
            </Button>
            <Button
              variant="success"
              size="sm"
              key="patient-status-is-fine"
              className="ml-2"
              onClick={() => handleAction(patient.patientInfo.id, patient.id, { reviewStatus: 'REVIEWED_ACCEPT' })}
              data-test="statusChangeReview_isFineBtn"
            >
              This is fine
            </Button>
          </div>
        )}
        {!(patient.reviewStatus === REVIEW_NAMES.NOT_REVIEWED.name) && opened && (
          <div className="d-flex flex-column mt-auto" data-test="statusChangeReview_approvalInfo">
            { patient.reviewStatus === REVIEW_NAMES.REVIEWED_ACCEPT.name && (
              <Fragment>
                <strong>{`Approved by ${patient.reviewedUser} on ${dateOfReviewed}`}</strong>
              </Fragment>
            )}
            { patient.reviewStatus === REVIEW_NAMES.REVIEWED_DENIED.name && (
              <Fragment>
                <strong>{`Denied by ${patient.reviewedUser} on ${dateOfReviewed}`}</strong>
                <p>{`Note: ${patient.reviewedNote || EMPTY_STRING}`}</p>
              </Fragment>
            )}
          </div>
        )}
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ top: '1rem', right: '0' }}
          onClick={() => setOpened(!opened)}
          data-test="statusChangeReview_collapseButton"
        >
          <i className={`d-flex-center bi-caret-${opened ? 'down' : 'up'}-fill ml-1`} />
        </Button>
      </div>
    </div>
  );
};

export const CustomDropdown = (props) => {
  const {
    title, selectedValue, handleOnChange, dataTest, options, name,
  } = props;

  const renderDropDownItems = () => options.map((element, index) => (
    <Dropdown.Item
      key={`${title}_filter_${index}`}
      onClick={() => handleOnChange(prevState => ({ ...prevState, [name]: element.value }))}
      as="button"
      className="item d-flex my-1 border-0 text-ccm-blue px-3 item"
      data-test={dataTest.item}
    >
      <div className="pr-3">{element.label}</div>
    </Dropdown.Item>
  ));
  const value = FLIGHT_PLAN_STATUSES_FILTERS[name].find(el => el.value === selectedValue || '');

  return (
    <Fragment>
      <h6 className="filter text-left text-uppercase mb-0 mr-1">
        {title}
        :
      </h6>
      <Dropdown data-test="statusChangeReview_filter" className="filter select-filter mr-1">
        <Dropdown.Toggle
          variant="light"
          className="toggle border-0 text-ccm-blue p-1"
          data-test={dataTest.toggle}
        >
          {value ? value.label : ''}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {renderDropDownItems()}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default StatusChangeReview;
