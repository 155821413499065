// libraries
import React from 'react';

export const NotFound = () => (
  <div className="row justify-content-center h-100">
    <div className="col-3 d-flex-center flex-column">
      <h1 className="text-ccm-lipstick display-2 mb-3 p-0">404</h1>
      <h1 className="mb-4 p-0">Page not found</h1>
      <p>The requested page does not exist.</p>
    </div>
  </div>
);

export default NotFound;
