// Libraries
import React, { useEffect, useState } from 'react';
import _sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Form } from 'react-bootstrap';
// Actions
import ShowNotification from '../../../../actions/notification';
import { UpdateLetterBatch } from '../../../../actions/letterBatch';
// Services
import { fetchData } from '../../../../services/helpers';
import {
  getCcmConditions, getInsurancesTypeahead, getPhysicianTypeahead,
} from '../../../../services/providers';
import { getLanguageCodes } from '../../../../services/patientList';
import { createLetterBatch } from '../../../../services/patient';
// Components
import { Select } from '../../../base/forms/Select';
import { TextArea } from '../../../base/forms/TextArea';
import { Checkbox } from '../../../base/forms/Checkbox';
import { TextInput } from '../../../base/forms/TextInput';
import { Datepicker } from '../../../base/forms/Datepicker';
// Constants
import { STATUSES } from '../../../../constants/statuses';
import { DATE_FORMAT, NOTIFICATION_TYPE, OUTREACH_TYPE_OPTIONS } from '../../../../constants/constants';

export const letterBatchSchema = () => Yup.object({
  outreachType: Yup.string().typeError('Select a valid Outreach Type')
    .required('Required'),
  minAge: Yup.number()
    .min(0, 'Must be greater than 0'),
  limit: Yup.number()
    .min(1, 'Must be greater than 1')
    .max(50000, 'Must be less than 50,000'),
  enrolledDate: Yup.date('Must be a valid date')
    .typeError('Must be a valid date').nullable(true),
  minRafUplift: Yup.number()
    .nullable()
    .positive()
    .min(0, 'Must be greater than 0')
    .max(100, 'Must be less than 100')
    .test(
      'maxDigitsAfterDecimal',
      'Number field must have 2 digits after decimal or less',
      number => (!number ? true : /^([0-9]{1,3})(\.[0-9]{1,2})?$/.test(number)),
    ),
});

export const LetterBatch = (props) => {
  const { formRetrivied } = props;
  const DEFAULT_LETTER = {
    outreachType: '',
    patientStatus: null,
    minAge: '',
    physicianIds: null,
    insurances: null,
    languages: 'eng,null',
    chronicConditions: null,
    enrolledDate: '',
    minRafUplift: null,
    reportingAvailable: false,
    comment: '',
    limit: '',
    tags: null,
  };

  const nullLanguage = { code: 'null', displayName: 'Not specified' };
  const engLanguageCode3 = { code: 'eng', displayName: 'English' };
  const engLanguages = ['English', 'english', 'eng', 'en'];

  const [languagesList, setLanguagesList] = useState([engLanguageCode3, nullLanguage]);
  const [ccmConditionsList, setCcmConditionsList] = useState(null);
  const [noResultsText, setNoResultsText] = useState('No results found...');
  const [initialValues, setInitialValues] = useState(DEFAULT_LETTER);
  const [tagIdsList, setTagIdsList] = useState(null);

  const getStatusesOptions = () => {
    const options = [];
    Object.entries(STATUSES).forEach(([key, status]) => {
      if (status.subStatuses) {
        Object.entries(status.subStatuses).forEach(([subKey, subStatus]) => options.push({
          value: subKey,
          label: `${subStatus.name} (${subKey})`,
        }));
      } else {
        options.push({
          value: key,
          label: `${status.name} (${key})`,
        });
      }
    });
    return options;
  };

  const preparePhysiciansList = (physiciansData = []) => {
    const physiciansList = [];

    physiciansData.forEach((el) => {
      physiciansList.push({
        ...el,
        value: el.id,
        label: `${el.lastName ? `${el.lastName}, ` : ''}${el.firstName ? el.firstName : ''}`,
      });
    });

    physiciansList.sort((a, b) => a.label.localeCompare(b.label));

    return physiciansList;
  };

  const handleCcmAsyncList = async () => {
    if (!ccmConditionsList) {
      const reasonsData = await fetchData(getCcmConditions());
      setCcmConditionsList(reasonsData);
      return reasonsData;
    }
    return ccmConditionsList;
  };

  const handleLanguagesAsyncList = async () => {
    if (languagesList.length === 2) {
      const languagesData = await fetchData(getLanguageCodes());
      setLanguagesList([...languagesData, nullLanguage]);
      return [...languagesData, nullLanguage];
    }
    return languagesList;
  };

  const handleInsuranceAsyncList = async (input) => {
    if (input && input.length > 1) {
      const insuranceData = await fetchData(getInsurancesTypeahead(input));
      return { options: insuranceData };
    }
    return { options: [] };
  };

  const handlePhysicianAsyncList = async (input) => {
    if (input && input.length > 1) {
      const physicianData = await fetchData(getPhysicianTypeahead({ filter: input }));
      return { options: preparePhysiciansList(physicianData) };
    }
    return { options: [] };
  };

  const handleTagIdsAsyncList = () => {
    const { tags } = props;
    if (!tagIdsList || (tagIdsList && tagIdsList.length === 0)) {
      const activeTags = tags ? tags.filter(el => !el.deleted) : [];
      setTagIdsList(activeTags);
      return activeTags;
    }
    return tagIdsList;
  };

  const saveLetterBatch = (newLetterBatch) => {
    const {
      letterBatch, letterBatch: { letterBatchList = [] }, showNotification, updateLetterBatch,
    } = props;

    const addGoalRequest = createLetterBatch(newLetterBatch);
    const addGoalPromise = addGoalRequest.promise;

    return addGoalPromise.then((data) => {
      delete addGoalPromise.promise;

      updateLetterBatch({
        ...letterBatch,
        letterBatchList: [...letterBatchList, data],
      });
    }).catch((error) => {
      delete addGoalPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this letter batch',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  /**
   * Adds the values 'en', 'eng', 'english' and 'English' when
   * there is 'en' or 'eng' or 'english' or 'English' in the array
   */
  const restructureLanguages = (languages) => {
    if (languages.includes('English') || languages.includes('english') || languages.includes('eng') || languages.includes('en')) {
      return [...new Set([...languages, ...engLanguages])];
    }
    return languages;
  };

  useEffect(() => setInitialValues(formRetrivied || DEFAULT_LETTER), [formRetrivied]);

  return (
    <div className="card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <span className="text-uppercase">Generate letter batch</span>
      </div>
      <div className="card-body">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={letterBatchSchema()}
          onSubmit={(values) => {
            const formattedLanguages = values.languages
              && !!values.languages.length && values.languages.split`,`;
            const formattedConditions = values.chronicConditions
              && !!values.chronicConditions.length && values.chronicConditions.map(el => el.id);
            const formattedInsurances = values.insurances
              && !!values.insurances.length && values.insurances.map(el => el.companyName);
            const formattedPhysicians = values.physicianIds
              && !!values.physicianIds.length && values.physicianIds.map(el => el.id);
            const formattedEnrolledDate = values.enrolledDate
              ? moment(values.enrolledDate).format(DATE_FORMAT.FULL_SERVER) : values.enrolledDate;
            const formattedStatus = values.patientStatus && !!values.patientStatus.length && values.patientStatus.split`,`;
            const formattedTags = values.tags
              && !!values.tags.length && values.tags.split`,`.map(Number);
            saveLetterBatch({
              ...values,
              enrolledDate: formattedEnrolledDate,
              languages: formattedLanguages && formattedLanguages.length > 0
                ? restructureLanguages(formattedLanguages) : null,
              insurances: formattedInsurances || null,
              physicianIds: formattedPhysicians || null,
              chronicConditions: formattedConditions || null,
              patientStatus: formattedStatus || null,
              tags: formattedTags || null,
            });
          }}
          data-test="letterBatch_formikComponent"
        >
          {formik => (
            <Form className="w-100 text-left">
              <Row className="align-items-center">
                <div className="col-4" data-test="letterBatch_outReach">
                  <Select
                    label="Outreach Type"
                    name="outreachType"
                    options={_sortBy(OUTREACH_TYPE_OPTIONS, el => el.label)}
                  />
                </div>
                <div className="col-4" data-test="letterBatch_patientStatus">
                  <Select
                    multi
                    simpleValue
                    label="Patient Status"
                    name="patientStatus"
                    noResultsText="Status not found..."
                    options={getStatusesOptions()}
                    data-test="letterBatch_status"
                  />
                </div>
                <div className="col-4" data-test="letterBatch_patientAge">
                  <TextInput
                    label="Minimum Age"
                    name="minAge"
                    type="number"
                  />
                </div>
              </Row>
              <Row className="align-items-center">
                <div className="col-4" data-test="letterBatch_patientPhysician">
                  <Select
                    async
                    multi
                    label="Physician"
                    name="physicianIds"
                    noResultsText={noResultsText}
                    onInputChange={input => (input.length < 2 ? setNoResultsText('Type 2 characters min') : setNoResultsText('No results found...'))}
                    loadOptions={handlePhysicianAsyncList}
                    data-test="letterBatch_physiciansField"
                  />
                </div>
                <div className="col-4" data-test="letterBatch_patientInsurance">
                  <Select
                    async
                    multi
                    label="Insurance"
                    name="insurances"
                    valueKey="planId"
                    labelKey="companyName"
                    noResultsText={noResultsText}
                    onInputChange={input => (input.length < 2 ? setNoResultsText('Type 2 characters min') : setNoResultsText('No results found...'))}
                    loadOptions={handleInsuranceAsyncList}
                    data-test="letterBatch_insurancesField"
                  />
                </div>
                <div className="col-4" data-test="letterBatch_patientChronicCondition">
                  <Select
                    multi
                    label="Conditions"
                    name="chronicConditions"
                    valueKey="id"
                    labelKey="name"
                    noResultsText="CCM Condition not found..."
                    options={ccmConditionsList}
                    onFocus={handleCcmAsyncList}
                    data-test="letterBatch_conditionsField"
                  />
                </div>
              </Row>
              <Row className="align-items-center">
                <div className="col-4" data-test="letterBatch_patientLanguage">
                  <Select
                    multi
                    simpleValue
                    label="Languages"
                    name="languages"
                    valueKey="code"
                    labelKey="displayName"
                    noResultsText="Language not found..."
                    options={languagesList}
                    onFocus={handleLanguagesAsyncList}
                    data-test="letterBatch_languagesField"
                  />
                </div>
                <div className="col-4" data-test="letterBatch_patientEnrolledDate">
                  <Datepicker
                    label="Enrolled As Of"
                    name="enrolledDate"
                    maxDate={new Date()}
                  />
                </div>
                <div className="col-4" data-test="letterBatch_patientEnrolledRAFScore">
                  <TextInput
                    label="Minimum RAF Score"
                    name="minRafUplift"
                    type="number"
                    maxLength="5"
                  />
                </div>
              </Row>
              <Row className="align-items-end">
                <div className="col-4" data-test="letterBatch_patientTags">
                  <Select
                    multi
                    simpleValue
                    label="Patient Attributes"
                    name="tags"
                    valueKey="id"
                    labelKey="displayName"
                    noResultsText="Patient attribute not found..."
                    options={tagIdsList}
                    onFocus={handleTagIdsAsyncList}
                    data-test="letterBatch_tagsField"
                  />
                </div>
                <div className="col-4" data-test="letterBatch_patientEnrolledReportsView">
                  <Checkbox
                    type="checkbox"
                    label="Make this report available in Reports view"
                    name="reportingAvailable"
                  />
                </div>
              </Row>
              <Row>
                <div className="col" data-test="letterBatch_commentTextArea">
                  <TextArea
                    label="Comments"
                    name="comment"
                    rows={5}
                  />
                </div>
              </Row>
              <Row className="align-items-center">
                <div className="col">
                  <TextInput
                    label="Patient Count Limit*"
                    subtext="*if specified, will ignore patients from previous batches"
                    name="limit"
                    type="number"
                  />
                </div>
                <div className="col text-right">
                  <Button
                    variant="light"
                    className="text-capitalize"
                    onClick={() => formik.resetForm({ values: DEFAULT_LETTER })}
                    data-test="letterBatch_clearBtn"
                  >
                    <span>clear entries</span>
                  </Button>
                  <Button
                    variant="success"
                    className="ml-2 text-capitalize"
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.isValid}
                    data-test="letterBatch_saveBtn"
                  >
                    <i className="bi-download mr-2" />
                    <span>generate Batch</span>
                  </Button>
                </div>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    letterBatch: state.letterBatch,
    tags: state.tenant.tags,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLetterBatch: batchData => dispatch(UpdateLetterBatch(batchData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LetterBatch);
