// libraries
import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
// services
import {
  getBillingReportJSON,
} from '../../../services/administration';
// constants
import { DIALOG_DEFAULTS, NOTIFICATION_TYPE } from '../../../constants/constants';
// views
import { LoadingBlock } from '../../menu/LoadingBlock';

export class BillingHistoryModal extends Component {
  state = {
    modalOpened: false,
    content: '',
    loading: true,
  };

  promises = {};

  openModal = () => {
    this.getReportJSON(this.props.reportId);
    this.setState({
      modalOpened: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpened: false,
    });
  };

  getReportJSON = async (id) => {
    const { showNotification } = this.props;
    const promiseName = 'getBillingReportJSON';
    const getBillingReportJSONRequest = getBillingReportJSON(id);
    const getBillingReportJSONPromise = getBillingReportJSONRequest.promise;
    this.promises[promiseName] = getBillingReportJSONRequest;

    getBillingReportJSONPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
        content: data.json,
      });
    })
      .catch((error) => {
        if (error.isCanceled) {
          return;
        }

        delete this.promises[promiseName];

        if (error.status === 401 || error.status === 403) {
          return;
        }

        showNotification({
          message: 'Could not load Care Navigator title.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
  };

  render() {
    const { content, loading, modalOpened } = this.state;
    const { billingItemName } = this.props;


    return (
      <Fragment>
        <button
          className="button action-button button--icon"
          onClick={this.openModal}
          type="button"
          title="View request JSON"
        >
          View
        </button>

        <Modal
          isOpen={modalOpened}
          style={DIALOG_DEFAULTS}
          onRequestClose={this.closeModal}
        >
          <div className="simple-dialog">
            <div className="dialog-title">
              {billingItemName || 'Billing history'}
              <div
                className="close-icon i-close"
                onClick={this.closeModal}
              />
            </div>
            <div className="dialog-content billing-report-json">
              { loading && <LoadingBlock /> }
              <pre>
                {content && JSON.stringify(JSON.parse(content), null, 2)}
              </pre>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default BillingHistoryModal;
