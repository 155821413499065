// Libraries
import React, { useState, Fragment, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
// Services
import { getGoals } from '../../../../services/goals';
import {
  setBillingInfo, setComplexCCMStatus, getPatientInfo, getPatientProfile, getBillingInfo,
} from '../../../../services/patient';
import { getPhysician, saveBillingProvider } from '../../../../services/providers';
import { isObjectsEqual } from '../../../../services/helpers';
import { updateUserInfoInStorage } from '../../../../services/userStorage';
// Actions
import ShowNotification from '../../../../actions/notification';
import SetPatient, { UpdatePatient } from '../../../../actions/patient';
import { UpdateUserGoals } from '../../../../actions/user';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, USER_ROLES,
  BILLING_FORM_CODE_ERRORS, BILLING_FORM_CODE_POSITIONS,
  MIN_DIAGNOSIS, MAX_DIAGNOSIS,
} from '../../../../constants/constants';
import { NON_PRIMARY_DX_CODES } from '../../../../constants/codes/nonPrimaryDxCodes';
// Components
import { withRouter } from '../../../shared/WithRouter';

export function ConfirmBilling(props) {
  const {
    patientId, patient: { billing = {} } = {}, patient,
    user: { role: userRole, progressMap } = {},
    setPatient, updatePatient, updateGoals,
    confirmSuccessCallback, showNotification,
    setValidationErrors, prevPhysicianName, setDisplayPcpMessage,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceSubmit, setForceSubmit] = useState(false);
  const [isDataAllowedToSend, setIsDataAllowedToSend] = useState(true);
  const [validationWarnings, setValidationWarnings] = useState([]);

  const handleCloseModal = () => {
    setValidationWarnings([]);
    setIsModalOpen(false);
  };

  const isPrimaryDxInvalid = () => {
    const { billingInfo: { problems } } = billing;
    const firstProblem = problems.filter(p => p.order === 1);
    if (firstProblem && firstProblem.length > 0) {
      if (NON_PRIMARY_DX_CODES.includes(firstProblem[0].code)) {
        return firstProblem[0].code;
      }
    }

    return false;
  };

  const handleBillingFormCodeExclusions = (exclusions) => {
    const exclusionEq = exclusions.eq ? exclusions.eq.join(', ') : '';
    const exclusionOr = exclusions.or ? exclusions.or.join(', ') : '';

    return { exclusionEq, exclusionOr };
  };

  const handleErrors = (errors, message) => {
    let errorsMessage = '';

    if (Object.keys(errors).length) {
      Object.keys(errors).forEach((e) => {
        errorsMessage += ` \n${errors[e]}`;
      });
    }

    showNotification({
      message: `Could not update billing information due to ${message}: ${errorsMessage}`,
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
  };

  const handleBillingFormCodeErrors = (error) => {
    const { data: { message: errorToParse } } = error;
    let message;
    let isThisDataAllowedToSend = true;

    try {
      const data = JSON.parse(errorToParse);
      let tempValidationWarnings = [];
      Object.keys(data).forEach((key) => {
        const problemCode = key;
        let exclusions;
        let textColor = '';

        if (data[key].length) {
          data[key].forEach((elem) => {
            const constrains = elem.notAllowedIfInHistory || elem.shouldBeInHistory;
            const {
              invalidCodePosition, invalidCode, invalidHistoryCode,
              notAllowedHistoryCode, shouldBeInHistory, notBillableCode,
            } = BILLING_FORM_CODE_ERRORS;
            if (constrains) {
              exclusions = handleBillingFormCodeExclusions(constrains);
            }

            switch (elem.code) {
              case invalidCodePosition.code:
                message = `Code ${problemCode} only allowed in the ${BILLING_FORM_CODE_POSITIONS[elem.position]} position`;
                isThisDataAllowedToSend = invalidCodePosition.isDataAllowedToSend
                  && isThisDataAllowedToSend;
                textColor = invalidCodePosition.color;
                break;
              case invalidCode.code:
                message = `Problem's ICD10 code ${problemCode} does not correspond to one of the patient's chronic conditions`;
                isThisDataAllowedToSend = invalidCode.isDataAllowedToSend
                  && isThisDataAllowedToSend;
                textColor = invalidCode.color;
                break;
              case invalidHistoryCode.code:
                message = `Problem's ICD10 code ${problemCode} is not in the billing history`;
                isThisDataAllowedToSend = invalidHistoryCode.isDataAllowedToSend
                  && isThisDataAllowedToSend;
                textColor = invalidHistoryCode.color;
                break;
              case notAllowedHistoryCode.code:
                if (exclusions) {
                  message = `Invalid code - because ${exclusions.exclusionOr} is in the billing history, ${problemCode} is not allowed`;
                  isThisDataAllowedToSend = notAllowedHistoryCode.isDataAllowedToSend
                    && isThisDataAllowedToSend;
                  textColor = notAllowedHistoryCode.color;
                }
                break;
              case shouldBeInHistory.code:
                if (exclusions) {
                  message = `If ${exclusions.exclusionEq} is in the billing history, make sure one of ${exclusions.exclusionOr} is also in the billing history`;
                  isThisDataAllowedToSend = shouldBeInHistory.isDataAllowedToSend
                    && isThisDataAllowedToSend;
                  textColor = shouldBeInHistory.color;
                }
                break;
              case notBillableCode.code:
                message = `This problem's ICD10 code ${problemCode} is not a billable code as there are multiple codes below it that contain a greater level of detail. You should work with the partner and your SCN to have the provider enter a more granular code. Check ICD10data.com for more information.`;
                isThisDataAllowedToSend = notBillableCode.isDataAllowedToSend
                  && isThisDataAllowedToSend;
                textColor = notBillableCode.color;
                break;
              default:
            }

            if (message) {
              tempValidationWarnings = [...tempValidationWarnings, { message, textColor }];
            }
          });
        }
        setValidationWarnings([...tempValidationWarnings]);
      });

      setIsDataAllowedToSend(isThisDataAllowedToSend);
      setIsModalOpen(true);
    } catch (e) {
      if (error.data.errors && error.data.message) {
        handleErrors(error.data.errors, error.data.message);
      } else {
        showNotification({
          message: 'Could not update billing information, please try again later',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      }
    }
  };

  const updatePhysicianRequest = async (physicianId) => {
    const saveBillingProviderRequest = saveBillingProvider(patientId, physicianId);
    const saveBillingProviderPromise = saveBillingProviderRequest.promise;

    await saveBillingProviderPromise.then(() => {
      delete saveBillingProviderRequest.promise;
    }).catch((error) => {
      delete saveBillingProviderRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not save billing physician',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const validatePhysician = async () => {
    const { billingInfo: { physicianId } } = billing;
    const emptyErrorName = 'EMPTY_PHYSICIAN';
    const notBillableErrorName = 'BE_BILLABLE_PHYSICIAN';

    if (physicianId) {
      const getPhysicianRequest = getPhysician(physicianId);
      const getPhysicianPromise = getPhysicianRequest.promise;
      const isValidPhysician = () => getPhysicianPromise.then((data) => {
        delete getPhysicianRequest.promise;
        if (data && data.id && data.billable) {
          return false;
        }
        return notBillableErrorName;
      }).catch((error) => {
        delete getPhysicianRequest.promise;
        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }
        showNotification({
          message: 'Could not load physician data',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
      const result = await isValidPhysician();
      if (result === false) await updatePhysicianRequest(physicianId);
      return result;
    }
    return emptyErrorName;
  };

  const validateProblemsSize = () => {
    const { billingInfo } = billing;
    const errorSize = 'PROBLEMS_COUNT';

    if (billingInfo && billingInfo.problems) {
      if (billingInfo.problems.length < MIN_DIAGNOSIS) {
        return errorSize;
      }
      if (billingInfo.problems.length > MAX_DIAGNOSIS) {
        return errorSize;
      }
    }
    return false;
  };

  const validateRepeatedProblems = () => {
    const { billingInfo } = billing;
    const errorIdentical = 'TWO_IDENTICAL_CODES';

    if (billingInfo && billingInfo.problems) {
      const billingProblems = billingInfo.problems;
      const problemFiltered = billingProblems.map(item => item.code)
        .filter((value, index, self) => self.indexOf(value) === index);
      if (problemFiltered.length !== billingProblems.length) {
        return errorIdentical;
      }
    }
    return false;
  };

  const validateBillingForm = async () => {
    const allValidationErrors = [
      validateProblemsSize(),
      validateRepeatedProblems(),
      await validatePhysician(),
    ];

    const validateErrors = allValidationErrors.filter(validateError => validateError);

    setValidationErrors(validateErrors);

    return validateErrors.length;
  };

  const getGoalsData = () => {
    const { params: { tenant: tenantUrl } } = props;
    /* patientId it's for testing purposes */
    const getGoalsRequest = getGoals(patientId);
    const getGoalsPromise = getGoalsRequest.promise;

    getGoalsPromise.then((data) => {
      delete getGoalsRequest.promise;

      if (!isObjectsEqual(progressMap, data)) {
        updateGoals(data);
        updateUserInfoInStorage({ progressMap: data }, tenantUrl);
      }
    }).catch((error) => {
      delete getGoalsRequest.promise;

      if (error.isCanceled) {
        return;
      }

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load goals data',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const loadBillingInfo = (newBilling) => {
    const getBillingInfoRequest = getBillingInfo(patientId);
    const getBillingInfoPromise = getBillingInfoRequest.promise;

    getBillingInfoPromise.then((data) => {
      delete getBillingInfoRequest.promise;

      let problems = [];
      if (data.problems && data.problems.length) {
        problems = data.problems.map(problem => ({
          ...problem,
          newIndex: `pc${problem.id}`,
        }));
      }

      const billingInfo = {
        ...data,
        problems,
      };
      updatePatient({ billing: { ...newBilling, billingInfo } });
    }).catch((error) => {
      delete getBillingInfoRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load billing information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const loadPatientData = () => {
    const promises = {};
    const getPatientInfoRequest = getPatientInfo(patientId);
    const getPatientInfoPromise = getPatientInfoRequest.promise;
    promises.patientInfo = getPatientInfoRequest;

    getPatientInfoPromise.then((data) => {
      delete promises.patientInfo;

      setPatient({ ...patient, ...data });
      loadBillingInfo(data.billing);

      const getProfileRequest = getPatientProfile(patientId);
      const getProfilePromise = getProfileRequest.promise;
      promises.getProfile = getProfileRequest;
      return getProfilePromise;
    }).then((data) => {
      delete promises.getProfile;

      updatePatient({ profile: data || {} });
    }).catch((error) => {
      delete promises.getProfile;
      delete promises.patientInfo;

      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'Could not load patient information, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const updateComplexCCM = () => {
    const { billingInfo: { complexCcmStatus } = {} } = billing;
    const complexCCMRequest = setComplexCCMStatus(patientId, complexCcmStatus, forceSubmit);
    const complexCCMPromise = complexCCMRequest.promise;

    complexCCMPromise.then(() => {
      delete complexCCMRequest.promise;
    }).catch((error) => {
      delete complexCCMRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save the Complex CCM, please try again later.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const submitBillingForm = async () => {
    const { billingInfo, permissions: { canComplexCcmBeEnabled } } = billing;

    if (await validateBillingForm()) {
      return;
    }

    const invalidPrimaryDx = isPrimaryDxInvalid();
    if (invalidPrimaryDx) {
      showNotification({
        message: `Unacceptable principal diagnosis: ${invalidPrimaryDx}. Please correct before confirming billing.`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      return;
    }

    const billingForm = {
      physicianId: billingInfo.physicianId,
      problems: billingInfo.problems,
    };

    const setBillingInfoRequest = setBillingInfo(patientId, billingForm, forceSubmit);
    const setBillingInfoPromise = setBillingInfoRequest.promise;

    setBillingInfoPromise.then(() => {
      delete setBillingInfoRequest.promise;
      confirmSuccessCallback();

      if (canComplexCcmBeEnabled) {
        updateComplexCCM();
      }

      loadPatientData();

      if (userRole !== USER_ROLES.ADMIN) {
        getGoalsData();
      }

      const currentPhysicianName = billingInfo && billingInfo.physicianDisplayName ? billingInfo.physicianDisplayName : '';
      if (prevPhysicianName && prevPhysicianName !== currentPhysicianName) {
        setDisplayPcpMessage(true);
      }

      showNotification({
        message: 'Billing information was successfully updated.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });

      handleCloseModal();
    }).catch((error) => {
      delete setBillingInfoRequest.promise;
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      if (error.data.message) {
        handleBillingFormCodeErrors(error);
      } else {
        showNotification({
          message: 'Could not update billing information, please try again later',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      }
    });
  };

  const forceSubmitBillingForm = () => {
    setForceSubmit(true);
    handleCloseModal();
  };

  useEffect(() => {
    if (forceSubmit) {
      submitBillingForm();
      setForceSubmit(false);
    }
  }, [forceSubmit]);

  const ConfirmBillingModal = () => (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Validation warning"
    >
      <div className="simple-dialog small-dialog" data-test="confirmBilling_validation">
        <div className="dialog-title">
          Validation warning
          <div
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
          />
        </div>
        <div className="dialog-content">
          {validationWarnings && validationWarnings.length && (
            <ul className="dialog-list">
              {validationWarnings.map(({ message: validationMessage, textColor }, index) => (
                <li key={`billing-validation-warning-${index}`} className={textColor}>
                  {validationMessage}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="dialog-buttons">
          <div
            className="button active-button"
            onClick={() => handleCloseModal()}
            data-test="confirmBilling_cancelAnywayButton"
          >
            Cancel
          </div>

          {isDataAllowedToSend && (
          <div
            className="button active-button"
            onClick={() => forceSubmitBillingForm()}
            data-test="confirmBilling_submitAnywayButton"
          >
            Submit anyway
          </div>
          )}
        </div>
      </div>
    </Modal>
  );

  return (
    <Fragment>
      <Button
        variant="primary"
        size="sm"
        className="ml-2"
        onClick={() => submitBillingForm()}
        data-test="confirmBilling_confirmButton"
      >
        Confirm
      </Button>
      <ConfirmBillingModal />
    </Fragment>
  );
}

export function mapStateToProps(state) {
  return {
    timezone: state.tenant && state.tenant.timezone,
    patient: state.patient,
    physiciansList: (state.physicians && state.physicians.physiciansList
      ? state.physicians.physiciansList : []),
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    setPatient: patientData => dispatch(SetPatient(patientData)),
    updateGoals: value => dispatch(UpdateUserGoals(value)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmBilling));
