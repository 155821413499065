// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
import { SetHFResources } from '../../actions/administration';
// Services
import { getResources } from '../../services/administration';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';


const useLoadResources = (saveInRedux) => {
  const [resources, setResources] = useState({});
  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchResources = useCallback(async () => {
    const getResourcesRequest = getResources();
    const getResourcesPromise = getResourcesRequest.promise;
    try {
      const data = await getResourcesPromise;
      setResources(data);
      if (saveInRedux) {
        dispatch(SetHFResources(data));
      }
    } catch (error) {
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to load health factors resources.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  }, []);

  useEffect(() => { fetchResources(); }, [fetchResources]);

  return { resources, refetch: fetchResources };
};

export default useLoadResources;
