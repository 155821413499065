// Libraries
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Accordion, Card } from 'react-bootstrap';
// Views
import NoteModal from './NoteModal';
// Services
import { parseDraftToHtml } from '../../../services/helpers';
// Constants
import { NOTES_TYPES, DATE_FORMAT } from '../../../constants/constants';

export function PersonalNotes(props) {
  const { patientId, patient: { careNavigatorNote, carePlanNotes } = {} } = props;
  const [initialNote, setInitialNote] = useState(
    { ...careNavigatorNote, type: NOTES_TYPES.CARE_PLAN },
  );
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);

  useEffect(() => {
    setInitialNote({ ...careNavigatorNote, type: NOTES_TYPES.CARE_PLAN });
  }, [careNavigatorNote]);

  const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);
  const getFullName = a => `${a.firstName || ''} ${a.lastName || ''}`.replace(/  +/g, ' ');

  return (
    <Fragment>
      <div className="note-header d-flex justify-content-between mb-2">
        <span className="mr-1">Personal Patient Notes:</span>
        {careNavigatorNote && (
        <div>
          <small className="text-ccm-gray mr-1">
            {getFullName(careNavigatorNote.updatedByUser)}
          </small>
          <small className="text-ccm-gray">
            {getDateString(careNavigatorNote.updatedAt)}
          </small>
        </div>)}
      </div>

      <div className="note-body border position-relative mb-1 p-2" data-test="personalNotes_note">
        {careNavigatorNote ? (
          parseDraftToHtml(careNavigatorNote.text))
          : (
            <span className="text-ccm-gray">No personal notes have been entered.</span>
          )}
        <Button
          size="lg"
          variant="link"
          style={{ top: '5px', right: '5px' }}
          className="d-flex-center position-absolute p-0"
          onClick={() => setIsNoteModalOpen(true)}
          data-test="personalNotes_editBtn"
        >
          <span className="d-flex-center bi-pencil-square" />
        </Button>

        <NoteModal
          patientId={patientId}
          initialNote={initialNote}
          isModalOpen={isNoteModalOpen}
          setIsModalOpen={setIsNoteModalOpen}
        />
      </div>

      {carePlanNotes && !!carePlanNotes.length && (
      <div className="note-footer">
        <Accordion>
          <Card>
            <Card.Header className="px-2 py-0">
              <Accordion.Toggle as={Button} variant="link" eventKey="0" className="d-flex-center m-auto p-0">
                Care Plan Notes
                <span className="d-flex-center bi-caret-down-fill ml-2" />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body className="px-2 py-0">
                {carePlanNotes.map((note, index) => (
                  <div key={`carePlanNote__${index}`} className="border-bottom mb-1 py-2">
                    <small className="text-ccm-gray">
                      {getDateString(note.createdAt)}
                    </small>
                    {!!note.text.length && parseDraftToHtml(note.text)}
                  </div>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>)}
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(PersonalNotes);
