// libraries
import React from 'react';
import { Button } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
// services
import { logoutUser } from '../../../services/login';

export function Unauthorized() {
  const { keycloak } = useKeycloak();
  return (
    <div className="row justify-content-center h-100">
      <div className="col-3 d-flex-center flex-column">
        <h1 className="text-ccm-lipstick display-2 mb-3 p-0">401</h1>
        <h1 className="mb-4 p-0">Unauthorized</h1>
        <p className="mb-4">This request requires user authentication.</p>
        <Button
          variant="outline-primary"
          className="py-1 border-0"
          onClick={() => logoutUser(keycloak)}
          data-test="401-signInButton"
        >
          Go to sign in page
        </Button>
      </div>
    </div>
  );
}

export default Unauthorized;
