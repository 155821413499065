// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// views
import Contact from './patientProfile/Contact';
import Outreach from './patientProfile/Outreach';
import Insurance from './patientProfile/Insurance';
import Diagnoses from './patientProfile/Diagnoses';
import Allergies from './patientProfile/Allergies';
import Preferences from './patientProfile/Preferences';
import CareTeam from './patientProfile/careTeam/CareTeam';
import PatientDemographics from './patientProfile/PatientDemographics';
// Constants
import { USER_ROLES } from '../../constants/constants';


export function PatientProfile() {
  const { id: patientId } = useParams();
  const { role: userRole } = useSelector(state => state.user);

  const isCnUser = userRole === USER_ROLES.CN;
  const isPesUser = userRole === USER_ROLES.PES;
  const isAdminUser = userRole === USER_ROLES.ADMIN;

  return (
    <div className="patient-profile h-100 overflow-auto pr-3">
      <h4 className="text-uppercase text-left">Patient Profile</h4>
      <div className="row text-left border-bottom no-gutters pb-3 mb-3">
        <div className="col">
          <h5>Contact</h5>
          <Contact patientId={patientId} />
        </div>
        <div className="patient-preferences col-5">
          <h5>Preferences</h5>
          <Preferences patientId={patientId} />
        </div>
      </div>
      {(isCnUser || isPesUser || isAdminUser) && (
        <div className="row border-bottom no-gutters pb-3 mb-3">
          <div className="col">
            <CareTeam />
          </div>
        </div>
      )}
      <div className="row text-left border-bottom no-gutters pb-3 mb-3">
        <div className="col">
          <PatientDemographics />
        </div>
      </div>
      <Outreach patientId={patientId} />
      <div className="row text-left border-bottom no-gutters pb-3 mb-3">
        <div className="col">
          <h5>Diagnoses</h5>
          <Diagnoses />
        </div>
        <div className="col-5">
          <div className="d-flex">
            <h5>Allergies / Adverse Reactions</h5>
          </div>
          <Allergies />
        </div>
      </div>
      <div className="row text-left no-gutters">
        <div className="col">
          <h5>Insurance</h5>
          <Insurance />
        </div>
      </div>
    </div>
  );
}

export default PatientProfile;
