// Libraries
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
// Constants
import { EMPTY_STRING } from '../../../constants/constants';

export function Insurance(props) {
  const {
    patient: {
      primaryInsurance, secondaryInsurance,
    } = {},
  } = props;

  return (
    <Fragment>
      <div>
        <span>Primary: </span>
        <span>
          {primaryInsurance ? `${primaryInsurance.companyName}, ID: ${primaryInsurance.planId}` : EMPTY_STRING}
        </span>
      </div>
      <div>
        <span>Secondary: </span>
        <span>
          {secondaryInsurance ? `${secondaryInsurance.companyName}, ID: ${secondaryInsurance.planId}` : EMPTY_STRING}
        </span>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(Insurance);
