// Libs
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../constants/constants';

const useConditionalFetch = (request, shouldFetch) => {
  const [data, setData] = useState(null);

  const dispatch = useDispatch();
  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await request().promise;
        setData(response);
      } catch (error) {
        if (error.status === 401 || error.status === 403) {
          return;
        }
        showNotification({
          message: 'An unexpected error has occurred while trying to fetch the request.',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      }
    }

    if (shouldFetch) fetchData();
  }, [shouldFetch]);

  return { data };
};

export default useConditionalFetch;
