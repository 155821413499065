import { createAction, createReducer } from '@reduxjs/toolkit';

const addRequestToProgress = createAction('ADD_REQUEST_TO_PROGRESS');
const removeRequestFromProgress = createAction('REMOVE_REQUEST_FROM_PROGRESS');

const initialState = {
  count: 0,
};

const RequestsInProgress = createReducer(initialState, (builder) => {
  builder
    .addCase(addRequestToProgress, (state) => {
      state.count++;
    })
    .addCase(removeRequestFromProgress, (state) => {
      state.count--;
    })
    .addDefaultCase(state => state);
});

export default RequestsInProgress;
