// libraries
import React, { useState, Fragment } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
// views
import { Dropdown } from 'react-bootstrap';
// constants
import { EMPTY_STRING, DATE_FORMAT } from '../../../constants/constants';

export function formatPhysicianNameString(physician) {
  return (
    `${physician.firstName || ''} ${physician.middleName || ''} ${physician.lastName || ''}`
  ).trim();
}

const ENCOUNTERS_FILTERS = {
  RECENT: 'Recent & Upcoming',
  PAST: 'Past 12 Months',
  FUTURE: 'Future Visits',
};

export function Encounters(props) {
  const {
    patientHistory: {
      recentMeetings, upcomingMeetings, olderMeetings, futureMeetings,
    } = {},
  } = props;
  const [encounterFilter, setEncounterFilter] = useState(ENCOUNTERS_FILTERS.RECENT);

  const filterDropDownItems = Object.keys(ENCOUNTERS_FILTERS).map((key, index) => (
    <Dropdown.Item
      data-test={`encounters_dropdownFilter_${key}`}
      key={`encounters_filter_${index}`}
      onClick={() => {
        setEncounterFilter(ENCOUNTERS_FILTERS[key]);
      }}
      as="button"
      className="d-flex my-1"
    >
      <div className="pr-3">{ENCOUNTERS_FILTERS[key]}</div>
    </Dropdown.Item>
  ));

  const getFilter = (
    <Dropdown
      className="position-absolute"
      style={{ left: 1 }}
    >
      <Dropdown.Toggle
        variant="ccm-light-gray"
        className="py-0"
      >
        {`Filter: ${encounterFilter}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterDropDownItems}
      </Dropdown.Menu>
    </Dropdown>
  );

  function renderEmptyRow(encounterType) {
    const stringType = encounterType && encounterType.toLowerCase();
    return (
      <tr>
        <td colSpan="5" className="p-2 border-0">
          {`This patient has no ${stringType} encounters`}
        </td>
      </tr>
    );
  }

  const renderEncountersRows = (encountersData) => {
    if (encountersData && encountersData.length) {
      return encountersData.map((encounter, index) => (
        <tr key={`encounter_row_${index}`}>
          <td>
            { encounter.date
              ? moment(encounter.date).format(DATE_FORMAT.SHORT) : EMPTY_STRING}
          </td>
          <td>
            { encounter.physicianName
              ? formatPhysicianNameString(encounter.physicianName) : EMPTY_STRING
            }
          </td>
          <td>
            {encounter.type || EMPTY_STRING}
          </td>
          <td>
            {encounter.purpose || EMPTY_STRING}
          </td>
          <td>
            {encounter.status || EMPTY_STRING}
          </td>
        </tr>
      ));
    }
    return renderEmptyRow(encounterFilter);
  };

  const renderRecentEncountersRows = (
    <Fragment>
      <tr>
        <td colSpan="5" className="border-0 py-1">
          Upcoming - Next 3 months
        </td>
      </tr>
      {renderEncountersRows(upcomingMeetings)}
      <tr>
        <td colSpan="5" className="border-0 py-1">
          Recent - Past 3 months
        </td>
      </tr>
      {renderEncountersRows(recentMeetings)}
    </Fragment>
  );

  const renderEncounters = () => {
    switch (encounterFilter) {
      case ENCOUNTERS_FILTERS.PAST:
        return renderEncountersRows(olderMeetings);
      case ENCOUNTERS_FILTERS.FUTURE:
        return renderEncountersRows([...upcomingMeetings, ...futureMeetings]);
      default:
        return renderRecentEncountersRows;
    }
  };


  return (
    <div className="card border-0 mb-4">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        {getFilter}
        <span className="text-uppercase">Encounters</span>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2">Date</th>
              <th className="py-2">Physician</th>
              <th className="py-2">Type</th>
              <th className="py-2">Reason for visit</th>
              <th className="py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {renderEncounters()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const mapStateToProps = state => ({
  patientHistory: state.patient && state.patient.patientHistory,
});

export default connect(mapStateToProps)(Encounters);
