// Libraries
import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
// Views
import BulkFilterModal from './BulkFilterModal';

export const BulkFilter = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Fragment>
      <Button
        variant="light"
        className="d-flex-center mr-1"
        onClick={() => setIsModalOpen(true)}
        data-test="bulkFilter_filterButton"
      >
        <i className="bi-filter mr-2" />
        <span className="mb-0">
          <span>Select&nbsp;</span>
          <span>by ID</span>
        </span>
      </Button>
      <BulkFilterModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Fragment>
  );
};

export default BulkFilter;
