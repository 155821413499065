// Libraries
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
// Services
import {
  createNextAppointment, updateNextAppointment,
} from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT,
} from '../../../constants/constants';
// Components
import { Datepicker } from '../../base/forms/Datepicker';
import TimeSelector from '../../base/TimeSelector';

export function AnnualWellnessVisit(props) {
  const { id: patientId } = useParams();
  const {
    isModalOpen, setIsModalOpen, initialAppointment,
  } = props;

  const DEFAULT_APPOINTMENT = {
    appointmentType: 'AWV',
    patientId: patientId || null,
    nextAppointmentDate: '',
    nextAppointmentTime: null,
  };

  const getInitialAppointment = () => {
    if (initialAppointment) {
      return {
        ...initialAppointment,
        nextAppointmentTime: moment(initialAppointment.nextAppointmentDate),
      };
    }
    return DEFAULT_APPOINTMENT;
  };

  const [newAppointment, setNewAppointment] = useState(getInitialAppointment());

  useEffect(() => {
    if (initialAppointment) {
      setNewAppointment({
        id: initialAppointment.id || '',
        appointmentType: initialAppointment.appointmentType || 'AWV',
        nextAppointmentDate: initialAppointment.nextAppointmentDate
          ? moment(initialAppointment.nextAppointmentDate)
            .format(DATE_FORMAT.SHORT) : '',
        nextAppointmentTime: initialAppointment.nextAppointmentDate
          ? moment(initialAppointment.nextAppointmentDate) : null,
      });
    } else {
      setNewAppointment(DEFAULT_APPOINTMENT);
    }
  }, [initialAppointment]);

  useEffect(() => {
    if (patientId) setNewAppointment({ ...newAppointment, patientId });
  }, [patientId]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const saveNextAWV = (appointment) => {
    const { showNotification, updatePatient } = props;

    const isNewAWV = !appointment.id;

    const addAWVRequest = isNewAWV
      ? createNextAppointment(appointment) : updateNextAppointment(appointment);
    const addAWVPromise = addAWVRequest.promise;

    return addAWVPromise.then((data) => {
      delete addAWVPromise.promise;

      updatePatient({ nextWellnessVisit: data });

      handleCloseModal();
    }).catch((error) => {
      delete addAWVPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error occurred while attempting to save this Annual Wellness Visit',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Next Annual Wellness Visit Modal"
      data-test="annualWVModal_onRequestClose"
    >
      <div className="simple-dialog small-dialog">
        <div className="dialog-title">
          {`${initialAppointment ? 'Edit' : 'Add'} Next Annual Wellness Visit`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="annualWVModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={newAppointment}
          validationSchema={Yup.object({
            nextAppointmentDate: Yup.date('Must be a valid date').typeError('Must be a valid date')
              .min(moment.utc().format(DATE_FORMAT.SHORT), 'Must not be in the past')
              .required('Required'),
            nextAppointmentTime: Yup.date('Must be a valid time').typeError('Must be a valid time')
              .required('Required'),
          })}
          onSubmit={(values, { resetForm }) => {
            const formattedDate = moment(values.nextAppointmentDate)
              .format(DATE_FORMAT.FULL_SERVER);
            const formattedTime = moment(values.nextAppointmentTime).format(DATE_FORMAT.ONLY_TIME);
            const currentDate = `${formattedDate} ${formattedTime}`;
            const updatedValues = { ...values, nextAppointmentDate: currentDate };
            delete updatedValues.nextAppointmentTime;

            saveNextAWV(updatedValues);
            resetForm();
          }}
          data-test="annualWVModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="dialog-content text-left" data-test="annualWVModal_dateInput">
                <Datepicker
                  label="Date"
                  name="nextAppointmentDate"
                  dateFormat="MM/dd/yyyy"
                  autoComplete="off"
                />
                <TimeSelector
                  time={formik.values.nextAppointmentTime}
                  setTime={newTime => formik.setFieldValue('nextAppointmentTime', newTime)}
                />
                {formik.errors.nextAppointmentTime && (
                  <Form.Text className="text-danger">{formik.errors.nextAppointmentTime}</Form.Text>
                )}
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="annualWVModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="annualWVModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnualWellnessVisit);
