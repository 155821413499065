import { createAction, createReducer } from '@reduxjs/toolkit';

const setDefaultSearchQaParams = createAction('SET_DEFAULT_QA_SEARCH_PARAMS');
const closeQaSearch = createAction('CLOSE_QA_SEARCH');
const resetQaSearch = createAction('RESET_QA_SEARCH');
const resetSidebarQaSearch = createAction('RESET_SIDEBAR_QA_SEARCH');
const qaSearch = createAction('QA_SEARCH');
const qaPaginate = createAction('QA_PAGINATE');
const qaSort = createAction('QA_SORT');
const qaSetFilterNames = createAction('QA_SET_FILTER_NAMES');


const initialState = {
  active: false,
  searchParams: {
    status: 'CC',
  },
  isSearchParamsOverwritten: true,
};

const Search = createReducer(initialState, (builder) => {
  builder
    .addCase(setDefaultSearchQaParams, (state, action) => {
      state.searchParams = action.searchParams
        || { ...initialState.searchParams };
    })
    .addCase(closeQaSearch, (state) => {
      state.active = false;
      state.isSearchParamsOverwritten = false;
    })
    .addCase(resetQaSearch, (state) => {
      state.searchParams = initialState.searchParams;
    })
    .addCase(resetSidebarQaSearch, (state) => {
      const initialStatus = state.searchParams && state.searchParams.status ? state.searchParams.status : '';
      const finalStatus = {
        ...initialState.searchParams, ...(initialStatus && { status: initialStatus }),
      };
      if (!initialStatus) delete finalStatus.status;
      state.searchParams = finalStatus;
    })
    .addCase(qaSearch, (state, action) => {
      state.searchParams = action.searchParams;
      state.pageNumber = 0;
      state.isSearchParamsOverwritten = action.isSearchParamsOverwritten;
    })
    .addCase(qaPaginate, (state, action) => {
      state.pageNumber = action.pageNumber;
      state.pageSize = action.pageSize;
      state.isSearchParamsOverwritten = false;
    })
    .addCase(qaSort, (state, action) => {
      state.sortParams = action.sortParams;
      state.isSearchParamsOverwritten = false;
    })
    .addCase(qaSetFilterNames, (state, action) => {
      if ('pcpPhysicianName' in action) {
        state.pcpPhysicianName = action.pcpPhysicianName;
      }
      if ('pcpLocationName' in action) {
        state.pcpLocationName = action.pcpLocationName;
      }
    })
    .addDefaultCase(state => state);
});

export default Search;
