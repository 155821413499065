// Libraries
import React, {
  useContext, useState, useEffect, Suspense, lazy,
} from 'react';
import _groupBy from 'lodash/groupBy';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import {
  Accordion, Card, Button, Dropdown,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { useDispatch, useSelector } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
// Actions
import ShowNotification from '../../../actions/notification';
import { SetHFResources } from '../../../actions/administration';
// Services
import { deleteResource } from '../../../services/administration';
// Constants
import {
  DATE_FORMAT, EMPTY_STRING, NONE_SPECIFIED_STRING, NOTIFICATION_TYPE, RESOURCES_FILTERS,
} from '../../../constants/constants';
// Views
import { Loading } from '../../base/Loading';
import { formatPhone, getInterventionType } from '../../../services/helpers';
// Lazy
const ResourceElementModal = lazy(() => import('./ResourceElementModal'));

export function ResourcesSection(props) {
  const { resources, resourcesTitle, categoryId } = props;
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const { administration: { resources: adminResources } } = useSelector(state => state);

  const openUrl = (url, target) => { window.open(url, target); };

  const [collapseAll, setCollapseAll] = useState(true);
  const [resourceFilter, setResourceFilter] = useState('ACTIVE');
  const [selectedResource, setSelectedResource] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalsOpened, setModalsOpened] = useState([]);

  const resourcesStatusGrouped = _groupBy(resources, resource => resource.status);

  if (resourcesStatusGrouped.ACTIVE || resourcesStatusGrouped.INACTIVE) {
    resourcesStatusGrouped.ALL = [
      ...resourcesStatusGrouped.ACTIVE || [],
      ...resourcesStatusGrouped.INACTIVE || []];
  }

  const handleAddEdit = (resource) => {
    setSelectedResource({ ...resource, category: resourcesTitle, categoryId });
    setIsModalOpen(true);
  };

  function renderEmptyRow() {
    return (
      <tr data-test="healthFactorElement_emptyMsg">
        <td colSpan="6" className="p-2 border-0">
          No resources have been defined for this health factor category.
        </td>
      </tr>
    );
  }

  const handleDelete = (resource) => {
    if (!window.confirm('Are you sure you want to remove the selected resource?')) {
      return;
    }

    const deleteResourceRequest = deleteResource(resource);
    const deleteResourcePromise = deleteResourceRequest.promise;

    deleteResourcePromise.then(() => {
      delete deleteResourceRequest.promise;
      const adminResourcesUpdated = _cloneDeep(adminResources);
      const resourcesUpdated = adminResourcesUpdated[resourcesTitle]
        ? adminResourcesUpdated[resourcesTitle].resources : [];
      adminResourcesUpdated[resourcesTitle].resources = resourcesUpdated
        .filter(g => g.id !== resource.id);
      dispatch(SetHFResources(adminResourcesUpdated));
    }).catch((error) => {
      delete deleteResourceRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete resource, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderDropDownItems = Object.keys(RESOURCES_FILTERS).reverse().map((key, index) => (
    <Dropdown.Item
      key={`qas_filter_${index}`}
      onClick={() => {
        setResourceFilter(key);
      }}
      as="button"
      className="d-flex my-1"
      data-test="resourceElement_filterItem"
    >
      <div className="pr-3">{RESOURCES_FILTERS[key].name}</div>
    </Dropdown.Item>
  ));

  useEffect(() => {
    if (isModalOpen && !modalsOpened.includes('isModalOpen')) {
      setModalsOpened([...modalsOpened, 'isModalOpen']);
    }
  }, [isModalOpen]);

  const renderRows = () => {
    const resourcesToRender = resourcesStatusGrouped[resourceFilter];
    if (resourcesToRender && resourcesToRender.length) {
      const sortedByDate = _orderBy(resourcesToRender, [el => moment(el.createdAt), 'id'], ['desc', 'desc']);
      return sortedByDate.map((resource) => {
        const getDateString = date => moment.utc(date).format(DATE_FORMAT.SHORT);

        return (
          <tr key={`resource__row-${resource.id}`} data-test={`resourceElement-${resourcesTitle}_tableBody`}>
            <td colSpan="6" className="p-0">
              <Accordion defaultActiveKey="0">
                <Card className="border-0 rounded-0">
                  <Card.Header className="bg-light border-0 p-0">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="resource__name">
                            {resource.name || EMPTY_STRING}
                          </td>
                          <td className="resource__phone">
                            {resource.phone ? formatPhone(resource.phone) : EMPTY_STRING}
                          </td>
                          <td className="resource__link">
                            {resource.url ? (
                              <span
                                name="url"
                                value={resource.url}
                                onClick={() => openUrl(resource.url, resource.name)}
                                className="resource-url text-ccm-blue"
                                data-test="resourceModal_urlLabel"
                              >
                                {resource.url}
                              </span>) : NONE_SPECIFIED_STRING}
                          </td>
                          <td className="resource__status">
                            {resource.status || EMPTY_STRING}
                          </td>
                          <td className="resource__actions px-0">
                            <CustomToggle eventKey="0" collapseAll={collapseAll} />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <div className="d-flex mb-2 justify-content-between mr-5">
                        <p className="mb-2">
                          <span className="mr-2">Address:</span>
                          {resource.address || EMPTY_STRING}
                        </p>
                        <p className="mr-5">
                          <span className="mr-2">Last updated by:</span>
                          {(resource.lastUpdatedByUser || resource.lastUpdatedDate)
                            ? `${resource.lastUpdatedByUser}, ${getDateString(resource.lastUpdatedDate)}` : EMPTY_STRING}
                        </p>
                      </div>
                      <div className="d-flex mb-3 justify-content-between">
                        <div className="d-flex">
                          <p>
                            <span className="mr-2">Notes:</span>
                            {resource.notes || EMPTY_STRING}
                          </p>
                        </div>
                        {resource.status !== 'DELETED' && (
                          <Button size="xs" className="resource-btn" onClick={() => handleAddEdit(resource)} data-test="resourceElement_editBtn">
                            Edit
                          </Button>
                        )}
                      </div>
                      <div className="d-flex">
                        <div className="d-flex mr-4">
                          <p className="mr-2">Intervention Category:</p>
                          <div className="flex-grow-1">
                            <p className="white-space-pre-line">{resource.interventionCategory ? getInterventionType(resource.interventionCategory) : EMPTY_STRING}</p>
                          </div>
                        </div>
                        <div className="d-flex ml-4">
                          <p className="mr-2">Intervention Type:</p>
                          <div className="flex-grow-1">
                            <p className="white-space-pre-line">{resource.interventionType || EMPTY_STRING}</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <p className="mr-2 note">Intervention Note:</p>
                          <div className="flex-grow-1">
                            <p className="white-space-pre-line">{resource.interventionNote || EMPTY_STRING}</p>
                          </div>
                        </div>
                        <div>
                          {resource.status !== 'DELETED' && (
                            <Button size="xs" className="resource-btn" variant="danger" onClick={() => handleDelete(resource)} data-test="resourceElement_deleteBtn">
                              Delete
                            </Button>)}
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </td>
          </tr>
        );
      });
    }
    return renderEmptyRow();
  };

  return (
    <div className="resources card border-0">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        <Dropdown
          className="position-absolute"
          style={{ left: 1 }}
          data-test="resourceElement_filter"
        >
          <Dropdown.Toggle
            variant="ccm-light-gray"
            className="py-0"
          >
            {`Filter: ${RESOURCES_FILTERS[resourceFilter].name}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {renderDropDownItems}
          </Dropdown.Menu>
        </Dropdown>
        <span className="text-uppercase">{resourcesTitle}</span>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '3rem' }}
          onClick={() => setCollapseAll(!collapseAll)}
          data-test="resourceElement_collapseAllBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        <Button
          size="sm"
          variant="link-dark"
          className="position-absolute"
          style={{ right: 10 }}
          onClick={() => handleAddEdit(null)}
          data-test="resourceElement_addBtn"
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2 resource__name">Name</th>
              <th className="py-2 resource__phone">Phone</th>
              <th className="py-2 resource__link">Link</th>
              <th className="py-2 resource__status">Status</th>
              <th className="py-2 resource__actions px-0" />
            </tr>
          </thead>
          <tbody>
            {renderRows()}
          </tbody>
        </table>
      </div>
      { modalsOpened.includes('isModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          {<ResourceElementModal
            initialResource={selectedResource}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />}
        </Suspense>)
      }
    </div>
  );
}

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0" onClick={decoratedOnClick}>
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

export default ResourcesSection;
