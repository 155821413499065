// Libraries
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export const UserAssignment = (props) => {
  const { openDialog } = props;
  const { assignedCareNavigator, tempTakeoverUser } = useSelector(state => state.patient);

  let assignedCareNavigatorName = 'N/A';
  let tempTakeoverUserName;

  if (assignedCareNavigator) {
    assignedCareNavigatorName = `${assignedCareNavigator.firstName || ''} ${assignedCareNavigator.lastName || ''}`;
  }

  if (tempTakeoverUser) {
    tempTakeoverUserName = `${tempTakeoverUser.firstName || ''} ${tempTakeoverUser.lastName || ''}`;
  }

  return (
    <div className="d-flex-center">
      <span className="owner__name mr-2" data-test="ownerName">
        {assignedCareNavigatorName}
      </span>
      {tempTakeoverUser && (
        <span className="takeover__name mr-2 text-ccm-red" data-test="assignPatient_takeoverName">
          {`TT: ${tempTakeoverUserName}`}
        </span>
      )}
      <Button
        size="sm"
        variant="primary"
        onClick={() => openDialog()}
        data-test="reassignLink"
      >
        Assign
      </Button>
    </div>
  );
};

export default UserAssignment;
