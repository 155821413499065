// Libraries
import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// Components
import { TextInput } from '../../base/forms/TextInput';
// Actions
import ShowNotification from '../../../actions/notification';
// Services
import { resendReport } from '../../../services/administration';
// Schema
import { resendReportSchema } from '../../../constants/forms/schemas/adminOtherPage';
// Constants
import { NOTIFICATION_TYPE } from '../../../constants/constants';
// Local Constants
const DEFAULT_VALUES = { patientEhrId: '', billingReportId: '', user: '' };


export const ResendReport = () => {
  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));

  const processData = async (data) => {
    const resendReportRequest = resendReport(data);
    const resendReportPromise = resendReportRequest.promise;

    try {
      await resendReportPromise;
      delete resendReportRequest.promise;
      showNotification({
        message: 'Service successfully executed',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });
    } catch (error) {
      delete resendReportRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred. Please try again.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  return (
    <div className="">
      <Formik
        initialValues={DEFAULT_VALUES}
        validationSchema={resendReportSchema()}
        onSubmit={values => processData(values)}
        data-test="resendReport_formikComponent"
      >
        {formik => (
          <Form className="container-fluid w-100 text-left">
            <div className="row">
              <div className="col">
                <TextInput
                  type="text"
                  label="Patient EHR ID"
                  name="patientEhrId"
                  autoComplete="off"
                />
              </div>
              <div className="col">
                <TextInput
                  type="number"
                  label="Billing Report ID"
                  name="billingReportId"
                  autoComplete="off"
                />
              </div>
              <div className="col">
                <TextInput
                  type="text"
                  label="Username"
                  name="user"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Button
                  variant="primary"
                  disabled={!formik.isValid}
                  onClick={() => formik.handleSubmit()}
                  className="text-capitalize"
                  data-test="resendReport_saveBtn"
                >
                  send
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResendReport;
