// Libraries
import React from 'react';
import { Button } from 'react-bootstrap';

export const ButtonAssignment = (props) => {
  const { openDialog, selectedPatientsIds } = props;

  return (
    <Button
      variant="light"
      className="d-flex-center mr-1"
      onClick={() => openDialog()}
      disabled={selectedPatientsIds && selectedPatientsIds.size === 0}
      data-test="bulkAssignPatientsButton"
    >
      <i className="bi-people-fill mr-2" />
      <span className="mb-0">
        <span>Assign</span>
      </span>
    </Button>
  );
};

export default ButtonAssignment;
