// Libraries
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ProgressBar } from 'react-bootstrap';
// Actions
import ShowNotification from '../../actions/notification';
import { UpdateUserBillingMetrics } from '../../actions/user';
// services
import { getUser } from '../../services/login';
// Helpers
import { getDaysRemaining } from '../../services/helpers';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Custom Hooks
import useLoadHolidays from '../../hooks/services/useLoadHolidays';


export const CnPatientListMetrics = () => {
  const {
    billingMetrics, billingMetrics: {
      assignedCount, doNotBillCount, billedThisMonthCount, billedBonusCount,
    },
  } = useSelector(state => state.user);

  const [percentage, setPercentage] = useState(0);

  const { holidays } = useLoadHolidays();

  const dispatch = useDispatch();
  const showNotification = data => dispatch(ShowNotification(data));
  const updateUserBillingMetrics = data => dispatch(UpdateUserBillingMetrics(data));

  const getPercentage = () => {
    const totalCount = assignedCount - doNotBillCount;
    if (totalCount > 0) return Math.round((billedThisMonthCount * 100) / totalCount);
    return 0;
  };

  const getUpdatedMetrics = async () => {
    const getUserRequest = getUser();
    const getUserPromise = getUserRequest.promise;

    try {
      const data = await getUserPromise;
      delete getUserRequest.promise;
      updateUserBillingMetrics(data.billingMetrics);
    } catch (error) {
      delete getUserRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred. Please try again.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const bonusLabel = billedBonusCount > 0 ? ` (+${billedBonusCount})` : '';

  useEffect(() => {
    setPercentage(getPercentage());
  }, [billingMetrics]);

  useEffect(() => { getUpdatedMetrics(); }, []);

  return (
    <div className="patient-progress-bars d-flex justify-content-around align-items-center w-100 h-100">
      <div className="h-100 d-flex-center border rounded p-2 mr-1">
        <span className="metric__title text-nowrap text-uppercase">days remaining:</span>
        <h6 className="mb-0 ml-1">{getDaysRemaining(holidays)}</h6>
      </div>
      <div className="h-100 d-flex-center flex-grow-1 border rounded p-2 mx-1">
        <div className="d-flex-center w-100 ml-2">
          <span className="metric__title text-nowrap text-capitalize">total assigned:</span>
          <h6 className="mb-0 ml-1">{assignedCount}</h6>
        </div>
        <div className="d-flex-center w-100 ml-2">
          <span className="metric__title text-nowrap text-uppercase">dnb:</span>
          <h6 className="mb-0 ml-1">{doNotBillCount}</h6>
        </div>
        <div className="d-flex-center w-100 ml-2">
          <span className="metric__title text-nowrap text-capitalize">billed:</span>
          <h6 className="mb-0 ml-1">
            {billedThisMonthCount}
            {bonusLabel}
          </h6>
        </div>
        <div className="d-flex-center w-100 ml-2">
          <span className="metric__title text-nowrap text-capitalize mr-1">{`${percentage}% complete:`}</span>
          <ProgressBar variant="success" now={percentage} style={{ width: '50px', height: '6px' }} />
        </div>
      </div>
    </div>
  );
};

export default CnPatientListMetrics;
