import React from 'react';

import { useLocation, useParams, useNavigate } from 'react-router-dom';

export const withRouter = (Component) => {
  const ComponentWithRouterProp = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        params={params}
        ref={props.wrappedRef}
      />
    );
  };
  return ComponentWithRouterProp;
};
