// Libraries
import React from 'react';
// Views
import QuickFilter from './QuickFilter';
import StatusSelector from './StatusSelector';

export function HeaderLegend(props) {
  const { needsCnCount } = props;

  return (
    <div className="ccm-status-legend box-wrapper d-flex mt-2">
      <StatusSelector />
      <QuickFilter
        needsCnCount={needsCnCount}
      />
    </div>
  );
}

export default HeaderLegend;
