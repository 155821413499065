// Libraries
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
// Constants
import { SORTING_OPTIONS } from '../../../constants/constants';
// Views

export const PatientTableHeader = (props) => {
  const { search: { sort }, requestsInProgress: { count: loading } } = useSelector(state => state);
  const isFirstRender = useRef(true);

  const [sortParams, setSortParams] = useState({
    key: sort ? sort.key : null,
    reverse: sort ? !!sort.reverse : false,
  });

  const handleSortKey = (key) => {
    if (key === sortParams.key) {
      if (!sortParams.reverse) {
        setSortParams({
          ...sortParams,
          reverse: true,
        });
      } else {
        setSortParams({
          key: null,
          reverse: false,
        });
      }
    } else {
      setSortParams({
        key,
        reverse: false,
      });
    }
  };

  const handleSortPatients = () => {
    const { sortPatients } = props;

    sortPatients(sortParams);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    handleSortPatients();
  }, [sortParams]);

  useEffect(() => {
    if (sort && sort.key !== sortParams.key) {
      setSortParams({
        ...sortParams,
        key: sort.key,
      });
    } else if (sort && sort.reverse !== sortParams.reverse) {
      setSortParams({
        ...sortParams,
        reverse: sort.reverse,
      });
    }
  }, [sort]);

  const caretSort = <i className={`d-flex-center bi-caret-${sortParams.reverse ? 'up' : 'down'}-fill ml-1`} />;

  return (
    <thead>
      <tr data-test="tableHeader_qaTabHeader">
        <th className="patientCol__status p-0" />
        <th className="patientCol__info">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.FULL_NAME)}
            data-test="tableHeader_fullNameSort"
          >
            Name
            {sortParams.key === SORTING_OPTIONS.FULL_NAME && caretSort}
          </Button>
        </th>
        <th className="patientCol__conditions" data-test="patient_attributeTableHeader">Patient Attributes</th>
        <th className="patientCol__conditions" data-test="patient_enrollmentTableHeader">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.ENROLLMENT_DATE)}
            data-test="tableHeader_enrollmentDate"
          >
            Enrollment Date
            {sortParams.key === SORTING_OPTIONS.ENROLLMENT_DATE && caretSort}
          </Button>
        </th>
        <th className="patientCol__assignedCn">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.CN_FULL_NAME)}
            data-test="tableHeader_cnFullNameSort"
          >
            Assigned
            {sortParams.key === SORTING_OPTIONS.CN_FULL_NAME && caretSort}
          </Button>
        </th>
        <th className="patientCol__ptSurvey">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.LAST_SATISFACTION_SURVEY)}
            data-test="tableHeader_ptSurvey"
          >
            PT SAT SURVEY
            {sortParams.key === SORTING_OPTIONS.LAST_SATISFACTION_SURVEY && caretSort}
          </Button>
        </th>
        <th className="patientCol__lastQa">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.LAST_QA)}
            data-test="tableHeader_lastQa"
          >
            LAST QA
            {sortParams.key === SORTING_OPTIONS.LAST_QA && caretSort}
          </Button>
        </th>
        <th className="patientCol__qaStatus">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.LAST_QA_STATUS)}
            data-test="tableHeader_qaStatus"
          >
            QA Status
            {sortParams.key === SORTING_OPTIONS.LAST_QA_STATUS && caretSort}
          </Button>
        </th>
        <th className="patientCol__lastBilled">
          <Button
            variant="link"
            disabled={loading}
            className="d-flex-center mx-auto"
            onClick={() => handleSortKey(SORTING_OPTIONS.LAST_BILLED_DATE)}
            data-test="tableHeader_lastBilled"
          >
            LAST BILLED DATE
            {sortParams.key === SORTING_OPTIONS.LAST_BILLED_DATE && caretSort}
          </Button>
        </th>
      </tr>
    </thead>
  );
};

export default PatientTableHeader;
