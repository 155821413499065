// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import _isArray from 'lodash/isArray';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
// Services
import { addPatientMedicalEvents, updatePatientMedicalEvents } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT, DEFAULT_FIELDS_LENGTH,
} from '../../../constants/constants';
// Components
import { Select } from '../../base/forms/Select';
import { Datepicker } from '../../base/forms/Datepicker';
import { TextInput } from '../../base/forms/TextInput';
import { Checkbox } from '../../base/forms/Checkbox';
import { TextArea } from '../../base/forms/TextArea';

export const medicalEventSchema = () => Yup.object({
  startDate: Yup.date().required('Required').nullable(true),
  endDate: Yup.date('Must be a valid date').typeError('Must be a valid date').nullable(true),
  readmission: Yup.boolean(),
  location: Yup.string().nullable(true).max(DEFAULT_FIELDS_LENGTH.DEFAULT_THIRD_LENGTH),
  reason: Yup.string().required('Required').trim().max(DEFAULT_FIELDS_LENGTH.DEFAULT_THIRD_LENGTH),
  comment: Yup.string(),
  numVisits: Yup.number().min(0, 'Must be greater than 0'),
  providerName: Yup.string()
    .when('typeId', (typeId, schema) => ([6, 7].some(t => t === typeId) ? schema.trim().required('Required') : schema.nullable(true))),
});

const fieldStyles = {
  formLabel: 'd-none',
};

const FIELDS_TO_BE_DISPLAYED = {
  readmission: [1],
  endDate: [1, 4, 5, 8, 9, 10, 11, 12, 13],
  location: [1, 2, 3, 4, 5, 8, 9, 10, 11, 12, 13],
  providerName: [4, 5, 6, 7, 13],
  numVisits: [4, 5, 13],
};

export function MedicalEventsModal(props) {
  const {
    patientId, showNotification, patientUtils: { patientEventTypes } = {},
    isModalOpen, setIsModalOpen, initialMedicalEvent,
    medicalEvents, setMedicalEvents,
  } = props;

  const DEFAULT_MEDICAL_EVENT = {
    id: null,
    comment: '',
    startDate: moment().format(DATE_FORMAT.SHORT),
    endDate: '',
    location: '',
    numVisits: 0,
    readmission: false,
    reason: '',
    providerName: '',
    source: '',
    typeId: 1,
  };

  const [medicalEvent, setMedicalEvent] = useState(initialMedicalEvent || DEFAULT_MEDICAL_EVENT);

  useEffect(() => {
    if (initialMedicalEvent) {
      setMedicalEvent({
        id: initialMedicalEvent.id || '',
        comment: initialMedicalEvent.comment || '',
        startDate: initialMedicalEvent.startDate
          ? moment.utc(initialMedicalEvent.startDate).format(DATE_FORMAT.SHORT)
          : moment.utc().format(DATE_FORMAT.SHORT),
        endDate: initialMedicalEvent.endDate
          ? moment.utc(initialMedicalEvent.endDate).format(DATE_FORMAT.SHORT)
          : '',
        location: initialMedicalEvent.location || '',
        reason: initialMedicalEvent.reason || '',
        providerName: initialMedicalEvent.providerName || '',
        source: initialMedicalEvent.source || '',
        numVisits: initialMedicalEvent.numVisits || 0,
        typeId: initialMedicalEvent.typeId || null,
        readmission: initialMedicalEvent.readmission || false,
      });
    } else {
      setMedicalEvent(DEFAULT_MEDICAL_EVENT);
    }
  }, [initialMedicalEvent]);

  const addNewPatientMedicalEvent = (medicalEventData) => {
    const isNewMedicalEvent = !medicalEventData.id;
    const addMedicalEventsRequest = isNewMedicalEvent
      ? addPatientMedicalEvents(patientId, medicalEventData)
      : updatePatientMedicalEvents(patientId, medicalEventData.id, medicalEventData);
    const addMedicalEventPromise = addMedicalEventsRequest.promise;

    return addMedicalEventPromise.then((data) => {
      delete addMedicalEventsRequest.promise;

      const oldMedicalEvents = _isArray(medicalEvents) ? medicalEvents : [];
      let updatedMedicalEvents;
      if (isNewMedicalEvent) {
        updatedMedicalEvents = [...oldMedicalEvents, data];
      } else {
        updatedMedicalEvents = oldMedicalEvents.map(p => (p.id === data.id ? data : p));
      }
      updatedMedicalEvents = _reverse(_sortBy(updatedMedicalEvents, el => moment(el.startDate)));
      setMedicalEvents(updatedMedicalEvents);
    }).catch((error) => {
      delete addMedicalEventsRequest.promise;
      if (error.isCanceled) {
        return;
      }
      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred while attempting to save this medical event',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCreateMedicalEvents = (values) => {
    addNewPatientMedicalEvent(values);
    handleCloseModal();
  };

  const getMedicalEventsValues = () => {
    const options = patientEventTypes && patientEventTypes.map(key => ({
      value: key.id,
      label: key.displayName,
    }));
    return options;
  };

  const cleanFormikValue = (stringValue, formik, isBoolean) => {
    if (!FIELDS_TO_BE_DISPLAYED[stringValue].some(t => t === formik.values.typeId) && (formik.values[stringValue] !== null && formik.values[stringValue] !== '' && formik.values[stringValue])) {
      formik.setFieldValue(stringValue, isBoolean ? false : '');
    }
    return null;
  };

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="MedicalEvent"
      data-test="medicalEventsModal_onRequestClose"
    >
      <div className="simple-dialog medium-dialog">
        <div className="dialog-title">
          {`${initialMedicalEvent ? 'Edit' : 'Add'} MedicalEvent`}
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="medicalEventsModal_closeBtn"
          />
        </div>
        <Formik
          initialValues={medicalEvent}
          validationSchema={medicalEventSchema({})}
          onSubmit={(values) => {
            const formattedStartDate = moment(values.startDate).format(DATE_FORMAT.FULL_SERVER);
            const formattedEndDate = values.endDate
              ? moment(values.endDate).format(DATE_FORMAT.FULL_SERVER) : '';
            const updatedValues = {
              ...values,
              startDate: formattedStartDate,
              endDate: formattedEndDate,
              reason: values.reason.trim(),
            };
            handleCreateMedicalEvents(updatedValues);
          }}
          data-test="medicalEventsModal_formikComponent"
        >
          {formik => (
            <Form>
              <div className="dialog-content text-left">
                <Row className="align-items-center">
                  <div className="col-6" data-test="medicalEventsModal_eventTypeInput">
                    <Select
                      label="Event Type"
                      name="typeId"
                      clearable={false}
                      options={getMedicalEventsValues()}
                      disabled={formik.values && !!formik.values.id}
                    />
                  </div>
                  <div className="col-3" data-test="medicalEventsModal_startDateInput">
                    <Datepicker
                      label="Start Date"
                      name="startDate"
                      maxDate={moment(formik.values.endDate).toDate()}
                    />
                  </div>
                  { FIELDS_TO_BE_DISPLAYED.endDate.some(t => t === formik.values.typeId) ? (
                    <div className="col-3" data-test="medicalEventsModal_endDateInput">
                      <Datepicker
                        label="End Date"
                        name="endDate"
                        minDate={moment(formik.values.startDate).toDate()}
                      />
                    </div>) : cleanFormikValue('endDate', formik)
                  }
                </Row>
                <Row className="align-items-center">
                  { FIELDS_TO_BE_DISPLAYED.location.some(t => t === formik.values.typeId) ? (
                    <div className="col-4">
                      <TextInput
                        label="Location"
                        name="location"
                        data-test="medicalEventsModal_locationInput"
                      />
                    </div>) : cleanFormikValue('location', formik)
                  }
                  <div className="col-4">
                    <TextInput
                      label="Reason"
                      name="reason"
                      data-test="medicalEventsModal_reasonInput"
                    />
                  </div>
                  { FIELDS_TO_BE_DISPLAYED.numVisits.some(t => t === formik.values.typeId) ? (
                    <div className="col-4">
                      <TextInput
                        label="Visits"
                        name="numVisits"
                        type="number"
                        data-test="medicalEventsModal_visitsInput"
                      />
                    </div>) : cleanFormikValue('numVisits', formik)
                  }
                </Row>
                <Row>
                  { FIELDS_TO_BE_DISPLAYED.providerName.some(t => t === formik.values.typeId) ? (
                    <div className="col-4">
                      <TextInput
                        label="Provider name"
                        name="providerName"
                        data-test="medicalEventsModal_providerInput"
                      />
                    </div>) : cleanFormikValue('providerName', formik)
                  }
                  { FIELDS_TO_BE_DISPLAYED.readmission.some(t => t === formik.values.typeId) ? (
                    <div className="col-4 d-flex flex-column justify-content-between">
                      <Form.Label>Readmission</Form.Label>
                      <Checkbox
                        label={formik.values.readmission ? 'Yes' : 'No'}
                        name="readmission"
                        type="switch"
                        defaultChecked={formik.values.readmission}
                        onChange={ev => formik.setFieldValue(
                          'readmission', ev && formik.values && !formik.values.readmission,
                        )}
                        styles={fieldStyles}
                        data-test="medicalEventsModal_checkbox"
                      />
                    </div>) : cleanFormikValue('readmission', formik, true)
                  }
                </Row>
                <Row className="align-items-center">
                  <div className="col">
                    <TextArea
                      label="Comments"
                      name="comment"
                      rows={5}
                      data-test="medicalEventsModal_comments"
                      maxLength="500"
                    />
                  </div>
                </Row>
              </div>
              <div className="dialog-buttons justify-content-end px-4">
                <Button variant="light" onClick={() => handleCloseModal()} data-test="medicalEventsModal_cancelBtn">Cancel</Button>
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={() => formik.handleSubmit()}
                  disabled={!formik.isValid}
                  data-test="medicalEventsModal_saveBtn"
                >
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    patientUtils: state.patient && state.patient.utils,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalEventsModal);
