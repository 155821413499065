// libraries
import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { Button } from 'react-bootstrap';
// constants
import { DIALOG_STYLES } from '../../../../constants/constants';
// views
import CcmCharges from './history/EligibilityBillingHistory';
import BillingHistory from './history/BillingHistoryTable';

const TABLES = {
  ccm: 'CCM Charges',
  'billing-history': 'Billing history',
};

export class History extends Component {
  state = {
    isModalOpened: false,
    displayTable: 'ccm',
  };

  openDialog = (event) => {
    event.currentTarget.blur();
    this.setState({
      isModalOpened: true,
    });
  };

  closeDialog = () => {
    this.setState({
      isModalOpened: false,
    });
  };

  setDisplayTableValue = (event) => {
    this.setState({
      displayTable: event.target.value,
    });
  };

  renderRadioButton(title, value) {
    const { displayTable } = this.state;
    const isRadioChecked = (displayTable === value);
    const labelClassName = `radio-container ${isRadioChecked
      ? ''
      : 'radio-container-inactive'}`;

    return (
      <label className={labelClassName} key={`radio-${value}`}>
        <input
          type="radio"
          name="displayTable"
          value={value}
          onClick={this.setDisplayTableValue}
          defaultChecked={isRadioChecked}
        />
        <div className="radio-icon" />
        {title}
      </label>
    );
  }

  renderDialogContent() {
    const { patientId } = this.props;
    const { displayTable } = this.state;

    if (displayTable === 'ccm') {
      return (<CcmCharges patientId={patientId} />);
    }

    return (<BillingHistory patientId={patientId} />);
  }

  render() {
    const { isModalOpened } = this.state;

    return (
      <Fragment>
        <Button
          variant="link"
          size="lg"
          onClick={this.openDialog}
          data-tip="Billing history"
          data-for="tooltip-historyButton"
        >
          <i className="d-flex-center bi bi-clock-history" />
        </Button>
        <ReactTooltip id="tooltip-historyButton" place="bottom" effect="float" type="info" />
        <Modal
          isOpen={isModalOpened}
          style={DIALOG_STYLES}
          onRequestClose={this.closeDialog}
          contentLabel="billingHistory"
        >
          <div className="simple-dialog big-dialog">
            <div className="dialog-title">
              <div className="history-radios">
                {Object.keys(TABLES).map(
                  key => this.renderRadioButton(TABLES[key], key),
                )}
              </div>
              <div
                className="close-icon i-close"
                onClick={this.closeDialog}
              />
            </div>
            <div className="history-dialog-content">
              {this.renderDialogContent()}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default History;
