// Async list loader for User dropdowns containing: Lastname, Firstname (username)
// Implements Infinite Scrolling and Typeahead search

const MAX_USERS_LIST = 6;

export async function getAsyncList(input, prevOptions, finalSelectList) {
  await new Promise(resolve => setTimeout(() => resolve(), 1000));

  let filteredOptions;
  if (!input) {
    filteredOptions = finalSelectList;
  } else {
    const str = new RegExp(input, 'gi');
    filteredOptions = finalSelectList.filter(
      i => i.label.match(str)
        || (i.firstName && i.firstName.match(str))
        || (i.lastName && i.lastName.match(str))
        || (i.username && i.username.match(str)),
    );
  }
  const hasMore = filteredOptions.length > prevOptions.length + MAX_USERS_LIST;

  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + MAX_USERS_LIST,
  );

  return {
    options: slicedOptions,
    hasMore,
  };
}
