// Libraries
import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

export function UpdatedPatientMessage() {
  const navigate = useNavigate();
  const { tenant: tenantUrl } = useParams();

  return (
    <div className="row justify-content-center h-100">
      <div className="col-4 d-flex-center flex-column">
        <div className="border px-5 pt-3 pb-5 bg-light rounded">
          <span style={{ fontSize: '4rem' }} className="bi bi-clipboard2-check text-ccm-gray" />
          <h4 data-test="updatedPatientMessage_messageHeader">You have successfully updated the patient status.</h4>
          <Button
            onClick={() => navigate(`/${tenantUrl}/cn/list`)}
            className="mt-4 px-5 rounded-pill"
            variant="ccm-lipstick"
            data-test="updatedPatientMessage_goBackBtn"
          >
            Go back to patient list
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UpdatedPatientMessage;
