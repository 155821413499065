// Libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
// Actions
import ShowNotification, { HideNotification } from '../../actions/notification';
// Services
import { updatePassword } from '../../services/amazon-cognito';
import { setLastPasswordUpdate } from '../../services/login';
import { makeCancelablePromise } from '../../services/cancelablePromise';

// Components
import { TextInput } from '../base/forms/TextInput';
import { withRouter } from '../shared/WithRouter';
// Views
import Loading from '../base/Loading';
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Images
import logo from '../../images/brand-logo.svg';

export class NewPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.promises = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }


  handleSubmit(values) {
    const { password } = values;
    const {
      navigate, params: { tenant: tenantUrl }, hideNotification, showNotification,
    } = this.props;

    if (this.state.loading) {
      return null;
    }


    hideNotification();

    this.setState({
      loading: true,
    });

    const updatePasswordRequest = makeCancelablePromise(updatePassword(password));
    const updatePasswordPromise = updatePasswordRequest.promise;
    this.promises.updatePassword = updatePasswordRequest;

    return updatePasswordPromise.then((data) => {
      delete this.promises.updatePassword;


      showNotification({
        message: 'Your password has been successfully changed.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.SUCCESS,
      });

      if (data && data.authData && data.authData.accessToken) {
        const { jwtToken } = data.authData.accessToken;
        this.handleLastPasswordUpdate(jwtToken);
      } else {
        this.setState({
          loading: false,
        });

        navigate(`/${tenantUrl}`);
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises.updatePassword;

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: error.message,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      this.setState({
        loading: false,
      });
    });
  }

  handleLastPasswordUpdate(jwtToken) {
    const {
      tenant: { mfaEnabled = false }, showNotification,
      navigate, params: { tenant: tenantUrl },
    } = this.props;

    const setLastPasswordUpdateRequest = setLastPasswordUpdate(jwtToken);
    const setLastPasswordUpdatePromise = setLastPasswordUpdateRequest.promise;
    this.promises.setLastPasswordUpdate = setLastPasswordUpdateRequest;

    return setLastPasswordUpdatePromise.then(() => {
      delete this.promises.setLastPasswordUpdate;

      this.setState({
        loading: false,
      });

      navigate(`/${tenantUrl}${mfaEnabled ? '/setup-mfa' : ''}`);
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises.setLastPasswordUpdate;

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: error.message,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const { loading } = this.state;
    const { params: { tenant: tenantUrl } } = this.props;

    const fieldStyles = {
      formGroup: 'mt-2',
      formControl: 'rounded-pill',
      formLabel: 'float-left text-ccm-bismark mb-2',
    };

    return (
      <div className="ccm-form-signin row justify-content-center h-100">
        <div className="col-3 d-flex-center">
          <Formik
            initialValues={{
              password: '',
            }}
            validationSchema={Yup.object({
              password: Yup.string()
                .required('Password is required')
                .min(8, 'Password is too short, minimum length is 8 symbols')
                .matches(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*[A-Z])(?=.*[.!@#$%^&*()_+=-]).+$/, 'Must contain digits, lowercase letters, uppercase letters and a special character(s)'),
            })}
            onSubmit={values => this.handleSubmit(values)}
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit} className="wrapper-login flex-grow-1 p-4">
                <Loading />
                <img
                  src={logo}
                  alt="SelectPatient Management logo"
                  data-test="login_engoodenLogo"
                  className={`logo ${loading ? 'd-none' : 'd-block'}`}
                />
                <TextInput
                  label="New Password"
                  name="password"
                  type="password"
                  styles={fieldStyles}
                  data-test="password-field"
                />
                <Button
                  variant="ccm-lipstick"
                  className="mt-2 px-5 rounded-pill"
                  onClick={() => formik.handleSubmit()}
                  data-test="submit-button"
                  disabled={!formik.isValid}
                >
                  Send
                </Button>
                <Form.Group controlId="formGroupLinks">
                  <div className="d-flex justify-content-center mt-5">
                    <Link to={`/${tenantUrl}`}>Sign in</Link>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tenant: state.tenant,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hideNotification: () => dispatch(HideNotification()),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPassword));
