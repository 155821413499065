// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Row } from 'react-bootstrap';
import moment from 'moment-timezone';
import _cloneDeep from 'lodash/cloneDeep';
// actions
import ShowNotification from '../../../../actions/notification';
import { SetCnList } from '../../../../actions/careNavigators';
import { SetPesList } from '../../../../actions/patientEnrollmentSpecialist';
import SetUsersList from '../../../../actions/users';
// constants
import {
  DIALOG_STYLES,
  NOTIFICATION_TYPE,
  ROLE_FULL_NAMES,
  USER_ROLES,
  USER_STATUS,
  USER_TYPES, DATE_FORMAT, DEFAULT_FIELDS_LENGTH,
  USERS_SORT_STATUSES, DIGITAL_PHONE_LENGTH,
} from '../../../../constants/constants';
// services
import { changeRole, getActiveUsers, updateUser } from '../../../../services/administration';
import ErrorBuilder from '../../../../services/errorMessageBuilder';
import { formatPhone, formatPhoneOnlyDigits, formatPhoneForRequest } from '../../../../services/helpers';
// views
import LoadingBlock from '../../../menu/LoadingBlock';
// Components
import { Select } from '../../../base/forms/Select';
import { Datepicker } from '../../../base/forms/Datepicker';
import { TextInput } from '../../../base/forms/TextInput';

const EXTERNAL_KEY = 'external';

export class EditUser extends Component {
  constructor(props) {
    super(props);

    const {
      user: {
        id, firstName, lastName, phoneNumber, role,
        external, startDate, mobile, email, ringCentralPhone, zoomPhone,
      } = {},
    } = props;

    this.state = {
      user: {
        id,
        firstName,
        lastName,
        phoneNumber,
        startDate,
        mobile,
        ringCentralPhone,
        zoomPhone,
        email,
        role: role && role.name,
        external: external || external === null,
      },
      isRoleCanBeChanged: props.user.permissions.roleCanBeChanged,
      modalOpened: false,
    };

    this.promises = {};

    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.saveRef = this.saveRef.bind(this);
    this.validate = this.validate.bind(this);
    this.updateField = this.updateField.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.changeRole = this.changeRole.bind(this);
    this.sendUpdateUserRequest = this.sendUpdateUserRequest.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  getRoles = () => {
    const formatedRoles = Object.keys(ROLE_FULL_NAMES).map(key => ({
      value: key,
      label: ROLE_FULL_NAMES[key],
    }));

    return formatedRoles.sort(
      ({ label: firstRole }, { label: secondRole }) => firstRole.localeCompare(secondRole),
    );
  };

  getTypes = () => Object.keys(USER_TYPES).map(key => ({
    value: EXTERNAL_KEY === key,
    label: USER_TYPES[key],
  }));

  handleTypeSelect = ({ value }) => {
    this.setState(state => ({
      user: {
        ...state.user,
        external: value,
      },
    }));
  };

  isEditExist = () => {
    const {
      firstName, lastName, external, startDate,
    } = this.state.user;
    const {
      firstName: oldFirstName, lastName: oldLastName,
      external: oldExternal, startDate: oldStartDate,
    } = this.props.user;

    const formattedOldStartDate = oldStartDate ? moment(oldStartDate).format(DATE_FORMAT.FULL)
      : null;

    return !(firstName === oldFirstName && lastName === oldLastName
      && (external === oldExternal) && formattedOldStartDate === startDate);
  }

  isRoleEdited = () => {
    const { role } = this.state.user;
    const { role: oldRole } = this.props.user;

    return (role === oldRole.name || role.value === oldRole.name);
  }

  openDialog() {
    const { user } = this.props;

    this.setState({
      user: {
        ...user,
        role: user.role.name,
      },
      modalOpened: true,
    });
  }

  closeDialog() {
    this.setState({
      modalOpened: false,
    });
  }

  saveRef(name, ref) {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  }

  validate() {
    let result = true;

    Object.keys(this.refs).forEach((key) => {
      result = this.refs[key].validate() && result;
    });

    return result;
  }

  async sendUpdateUserRequest() {
    const { user } = this.state;
    const {
      showNotification, submitCallback, user: initialUser,
      setUsersList, users: { usersList }, loading, activityStatus,
    } = this.props;
    let userRoleUpdated;

    if (loading || !this.validate()) {
      return;
    }

    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber || null,
      startDate: user.startDate || null,
      external: user.external,
      mobile: user.mobile || null,
      ringCentralPhone: user.ringCentralPhone || null,
      zoomPhone: user.zoomPhone || null,
      email: user.email || null,
    };

    const newRoleName = user.role.value || user.role;

    if (initialUser.role.name !== newRoleName) {
      userRoleUpdated = await this.changeRole(newRoleName, !this.isEditExist());
      if (!this.isEditExist()) {
        return;
      }
    }

    const promiseName = 'updateUser';
    const updateUserRequest = updateUser(user.id, data);
    const updateUserPromise = updateUserRequest.promise;
    this.promises[promiseName] = updateUserRequest;

    updateUserPromise.then(() => {
      delete this.promises[promiseName];

      const usersListLoaded = _cloneDeep(usersList);
      const userUpdatedIndex = usersListLoaded[activityStatus].data.findIndex(
        u => u.id === user.id,
      );
      usersListLoaded[activityStatus].data[userUpdatedIndex] = {
        ...initialUser,
        firstName: data.firstName,
        lastName: data.lastName,
        startDate: data.startDate,
        external: data.external,
        mobile: data.mobile,
        ringCentralPhone: user.ringCentralPhone,
        zoomPhone: user.zoomPhone,
        email: data.email,
        ...(userRoleUpdated && { role: userRoleUpdated }),
      };

      setUsersList({
        usersList: usersListLoaded,
      });

      if (submitCallback) {
        submitCallback(data);
      }

      this.closeDialog();
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: error.data ? error.data.message : ErrorBuilder(error.data),
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  updateField(field, value, isValid) {
    if (!isValid) {
      return;
    }

    this.setState(state => ({
      user: {
        ...state.user,
        [field]: value,
      },
    }));
  }

  async changeRole(newRoleName, usersHaveToBeUpdated) {
    const { submitCallback, user: initialUser } = this.props;
    const { user } = this.state;
    let updatedRole;

    let confirmMessage = 'Are you sure you want to change user\'s role?';

    if (initialUser.role.name === USER_ROLES.CN && newRoleName === USER_ROLES.M
      && initialUser.statistic.numberOfManagedPatients) {
      confirmMessage = `Warning, This action will change CN role to Manager and unassign all patients currently assigned to this CN. ${confirmMessage}`;
    }

    if (window.confirm(confirmMessage)) {
      const {
        setCnList, setPesList, showNotification, setUsersList, users: { usersList },
      } = this.props;

      const promiseNames = ['changeRole', 'getActiveUsers'];
      const changeRoleRequest = changeRole(user.id, { role: newRoleName });
      const changeRolePromise = changeRoleRequest.promise;
      this.promises[promiseNames[0]] = changeRoleRequest;

      await changeRolePromise.then((data) => {
        delete this.promises[promiseNames[0]];

        this.setState({
          modalOpened: false,
        });

        if (usersHaveToBeUpdated) {
          const usersListLoaded = _cloneDeep(usersList);
          const userUpdatedIndex = usersListLoaded[USERS_SORT_STATUSES.enabled].data.findIndex(
            u => u.id === user.id,
          );
          usersListLoaded[USERS_SORT_STATUSES.enabled].data[userUpdatedIndex] = {
            ...initialUser, role: data.role,
          };
          setUsersList({
            usersList: usersListLoaded,
          });
        }

        updatedRole = data.role;

        const getActiveUsersRequest = getActiveUsers(USER_STATUS.ACTIVE, true);
        const getActiveUsersPromise = getActiveUsersRequest.promise;
        this.promises[promiseNames[1]] = getActiveUsersRequest;

        getActiveUsersPromise.then((usersData) => {
          promiseNames.forEach((promiseName) => {
            delete this.promises[promiseName];
          });
          const cnData = usersData.users
            ? usersData.users.filter(u => u.role.name === USER_ROLES.CN) : [];
          const pesData = usersData.users
            ? usersData.users.filter(u => u.role.name === USER_ROLES.PES) : [];
          if (cnData) {
            setCnList(cnData);
          }
          if (pesData) {
            setPesList(pesData);
          }
        }).catch((error) => {
          if (error.isCanceled) {
            return;
          }
          promiseNames.forEach((promiseName) => {
            delete this.promises[promiseName];
          });
        });

        if (submitCallback) {
          const roleData = { role: { name: newRoleName } };
          submitCallback(roleData);
        }
      }).catch((error) => {
        delete this.promises[promiseNames[0]];
        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }
        showNotification({
          message: ErrorBuilder(error.data),
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
    }
    return updatedRole;
  }

  handleRoleSelect(value) {
    this.setState(state => ({
      user: {
        ...state.user,
        role: value,
      },
    }));
  }

  render() {
    const {
      user: {
        external, role, firstName, lastName, startDate, mobile, email,
        ringCentralPhone, zoomPhone,
      }, modalOpened, isRoleCanBeChanged,
    } = this.state;
    const {
      currentUser: { isExternal: isExternalUser = true, role: userRole }, loading,
    } = this.props;

    const isInternalAdmin = !isExternalUser
      && (isExternalUser !== null) && (userRole === USER_ROLES.ADMIN);

    let loadingBlock;
    if (loading) {
      loadingBlock = <LoadingBlock />;
    }
    return (
      <div>
        <Button
          variant="light"
          className="mr-2"
          onClick={this.openDialog}
          data-test="editUser_editBtn"
        >
          Edit
        </Button>
        <Modal
          isOpen={modalOpened}
          style={DIALOG_STYLES}
          onRequestClose={this.closeDialog}
          contentLabel=""
        >
          <div className="simple-dialog medium-dialog" data-test="administrator_editUserModal">
            <div className="dialog-title" data-test="administrator_editUserHeader">
              Edit User
              <div
                className="close-icon i-close"
                onClick={this.closeDialog}
              />
            </div>
            <Formik
              initialValues={{
                firstName: firstName || '',
                lastName: lastName || '',
                startDate: startDate ? moment(startDate).format(DATE_FORMAT.SHORT) : '',
                userRole: role || '',
                userType: external,
                mobile: mobile || '',
                ringCentralPhone: ringCentralPhone || '',
                zoomPhone: zoomPhone || '',
                email: email || '',
              }}
              validationSchema={Yup.object({
                firstName: Yup.string().required('Required').max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH)
                  .strict(true)
                  .trim('The First Name cannot include leading or trailing spaces.'),
                lastName: Yup.string().required('Required').max(DEFAULT_FIELDS_LENGTH.DEFAULT_LENGTH)
                  .strict(true)
                  .trim('The Last Name cannot include leading or trailing spaces.'),
                startDate: Yup.date().typeError('Must be a valid date')
                  .when('userRole', (roleSelect, schema) => (roleSelect !== USER_ROLES.ADMIN ? schema.required('Required') : schema.nullable(true))),
                email: Yup.string().required('Required').email('Invalid email').max(DEFAULT_FIELDS_LENGTH.DEFAULT_THIRD_LENGTH),
                mobile: Yup.string().test('Length',
                  'must contain 10 digits',
                  (value) => {
                    const valueFormatted = formatPhone(value);
                    return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
                  }),
                ringCentralPhone: Yup.string().test('Length',
                  'must contain 10 digits',
                  (value) => {
                    const valueFormatted = formatPhone(value);
                    return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
                  }),
                zoomPhone: Yup.string().test('Length',
                  'must contain 10 digits',
                  (value) => {
                    const valueFormatted = formatPhone(value);
                    return !value ? true : valueFormatted.replace(/\D/g, '').length === 10;
                  }),
                userRole: Yup.string().typeError('Please select a value from the list').required('Required').oneOf(Object.keys(ROLE_FULL_NAMES)),
                ...(!isExternalUser && { userType: Yup.boolean().typeError('Please select a value from the list').required('Required') }),
              })}
              onSubmit={(values, { resetForm }) => {
                const currentDate = values.startDate;
                const formattedDate = currentDate
                  ? moment(currentDate).format(DATE_FORMAT.FULL) : null;
                const mobileDigits = formatPhoneOnlyDigits(values.mobile);
                const ringCentralDigits = formatPhoneOnlyDigits(values.ringCentralPhone);
                const zoomPhoneDigits = formatPhoneOnlyDigits(values.zoomPhone);
                const mobileValue = mobileDigits && mobileDigits.length === DIGITAL_PHONE_LENGTH
                  ? formatPhoneForRequest(values.mobile) : values.mobile;
                const ringCentralPhoneValue = ringCentralDigits
                  && ringCentralDigits.length === DIGITAL_PHONE_LENGTH
                  ? formatPhoneForRequest(values.ringCentralPhone) : values.ringCentralPhone;
                const zoomPhoneValue = zoomPhoneDigits
                  && zoomPhoneDigits.length === DIGITAL_PHONE_LENGTH
                  ? formatPhoneForRequest(values.zoomPhone) : values.zoomPhone;
                this.setState(state => ({
                  user: {
                    ...state.user,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    startDate: formattedDate,
                    role: values.userRole,
                    external: values.userType,
                    mobile: mobileValue,
                    ringCentralPhone: ringCentralPhoneValue,
                    zoomPhone: zoomPhoneValue,
                    email: values.email,
                  },
                }), () => this.sendUpdateUserRequest());
                resetForm();
              }}
              data-test="editUser_formikComponent"
            >
              {formik => (
                <Form>
                  <div className="dialog-content text-left">
                    {loadingBlock}
                    <Row className="align-items-center">
                      <div className="col">
                        <TextInput
                          label="First name"
                          name="firstName"
                        />
                      </div>
                      <div className="col">
                        <TextInput
                          label="Last name"
                          name="lastName"
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className="col">
                        <Datepicker
                          label="Start date"
                          name="startDate"
                          subtext="SelectPatient Management start date"
                          autoComplete="off"
                        />
                      </div>
                    </Row>
                    <Row className="align-items-center">
                      <div className="col">
                        <TextInput
                          label="Email"
                          name="email"
                        />
                      </div>
                      <div className="col">
                        <TextInput
                          label="Cell Phone"
                          name="mobile"
                          maxLength="14"
                          value={(
                            formik.values.mobile
                            && formatPhone(formik.values.mobile)
                          )}
                        />
                      </div>
                    </Row>
                    <Row className="align-items-center">
                      <div className="col">
                        <TextInput
                          label="Ring Central Phone Number"
                          data-test="editUser_ringCentralPhoneNumberInput"
                          name="ringCentralPhone"
                          maxLength="14"
                          value={(
                            formik.values.ringCentralPhone
                            && formatPhone(formik.values.ringCentralPhone)
                          )}
                        />
                      </div>
                      <div className="col">
                        <TextInput
                          label="Zoom Phone Number"
                          data-test="editUser_zoomPhoneNumberInput"
                          name="zoomPhone"
                          maxLength="14"
                          value={(
                            formik.values.zoomPhone
                            && formatPhone(formik.values.zoomPhone)
                          )}
                        />
                      </div>
                    </Row>
                    <Row className="align-items-center">
                      <div className="col">
                        {isRoleCanBeChanged && (
                          <Select
                            label="User role"
                            name="userRole"
                            placeholder="Role"
                            options={this.getRoles()}
                            searchable={false}
                          />
                        )}
                      </div>
                      <div className="col">
                        {isInternalAdmin && (
                          <Select
                            label="User type"
                            name="userType"
                            placeholder="Type"
                            options={this.getTypes()}
                            searchable={false}
                          />
                        )}
                      </div>
                    </Row>
                  </div>
                  <div className="dialog-buttons justify-content-end px-4 mt-1 mb-3">
                    <Button variant="light" onClick={this.closeDialog} data-test="editUser_cancelBtn">Cancel</Button>
                    <Button
                      variant="primary"
                      className="ml-2"
                      onClick={() => formik.handleSubmit()}
                      disabled={!formik.isValid}
                      data-test="editUser_saveBtn"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user,
    users: state.users,
    loading: state.requestsInProgress.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCnList: cnListData => dispatch(SetCnList(cnListData)),
    setPesList: pesListData => dispatch(SetPesList(pesListData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
    setUsersList: usersData => dispatch(SetUsersList(usersData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
