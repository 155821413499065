// Libraries
import React, { useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSelector, useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
import { SetBillMetrics } from '../../actions/flightPlan';
import { UpdateUserBillingMetrics } from '../../actions/user';
// Views
import MetricsContent from './metrics/MetricsContent';
import MetricsBreakdown from './metrics/MetricsBreakdown';
// Services
import { getUser } from '../../services/login';
import { getMetrics, getAdminMetrics } from '../../services/metrics';
// Constants
import { NOTIFICATION_TYPE, USER_ROLES } from '../../constants/constants';
import { DEFAULT_BILL_METRICS, DEFAULT_METRICS } from '../../constants/metrics';


export const Metrics = () => {
  const { role } = useSelector(state => state.user);

  const isCnUser = role === USER_ROLES.CN;
  const isPesUser = role === USER_ROLES.PES;
  const isAdminUser = role === USER_ROLES.ADMIN;

  const [tabIndex, setTabIndex] = useState(isAdminUser ? 1 : 0);
  const [metricBreakdown, setMetricBreakdown] = useState([]);
  const [myMetrics, setMyMetrics] = useState(DEFAULT_METRICS);
  const [teamMetrics, setTeamMetrics] = useState(DEFAULT_METRICS);
  const [billMetricBreakdown, setBillMetricBreakdown] = useState([]);

  const dispatch = useDispatch();
  const setBillMetrics = data => dispatch(SetBillMetrics(data));
  const showNotification = data => dispatch(ShowNotification(data));
  const updateUserBillingMetrics = data => dispatch(UpdateUserBillingMetrics(data));

  const getAllMetrics = () => {
    const getMetricsRequest = getMetrics();
    const getMetricsPromise = getMetricsRequest.promise;

    return getMetricsPromise.then((data) => {
      delete getMetricsRequest.promise;

      if (!_isEmpty(data.userProgress)) setMyMetrics(data.userProgress);
      if (!_isEmpty(data.teamProgress)) setTeamMetrics(data.teamProgress);
      if (!_isEmpty(data.billingMetrics)) setBillMetrics(data.billingMetrics);
    }).catch((error) => {
      delete getMetricsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load Flight Plan Metrics, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getUsersBillMetrics = (dataMetrics) => {
    const totalValues = dataMetrics.reduce(
      (accumulator, currentValue) => {
        const result = { ...accumulator };
        result.assignedCount += currentValue.metrics.assignedCount;
        result.doNotBillCount += currentValue.metrics.doNotBillCount;
        result.billedTodayCount += currentValue.metrics.billedTodayCount;
        result.billedThisMonthCount += currentValue.metrics.billedThisMonthCount;
        return result;
      }, DEFAULT_BILL_METRICS,
    );
    setBillMetrics(totalValues);
  };

  const getAdminRoleMetrics = () => {
    const getAdminMetricsRequest = getAdminMetrics();
    const getAdminMetricsPromise = getAdminMetricsRequest.promise;

    return getAdminMetricsPromise.then((data) => {
      delete getAdminMetricsRequest.promise;

      if (!_isEmpty(data.teamProgress)) setTeamMetrics(data.teamProgress);
      if (!_isEmpty(data.userProgress)) setMetricBreakdown(data.userProgress);
      if (!_isEmpty(data.billingMetrics)) {
        getUsersBillMetrics(data.billingMetrics);
        setBillMetricBreakdown(data.billingMetrics);
      }
    }).catch((error) => {
      delete getAdminMetricsRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not load Flight Plan Metrics, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const getUpdatedMetrics = async () => {
    const getUserRequest = getUser();
    const getUserPromise = getUserRequest.promise;

    try {
      const data = await getUserPromise;
      delete getUserRequest.promise;
      updateUserBillingMetrics(data.billingMetrics);
    } catch (error) {
      delete getUserRequest.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error occurred. Please try again.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const getTeamMetricsTitle = () => {
    if (isAdminUser) return 'Overall Team Metrics';
    return 'Team Metrics';
  };

  useEffect(() => {
    if (isCnUser || isPesUser) {
      getUpdatedMetrics();
      getAllMetrics();
    }
    if (isAdminUser) getAdminRoleMetrics();
  }, []);

  return (
    <div className="flight-plan-metrics h-100 overflow-auto pr-3">
      <div className="d-flex align-items-center">
        <h4 className="text-left my-3">Metrics</h4>
      </div>
      <Tabs activeKey={tabIndex} onSelect={index => setTabIndex(index)} unmountOnExit data-test="metrics_tabs">
        <Tab eventKey={0} title="My Metrics" tabClassName={`p-1 mr-2 ${isAdminUser ? 'd-none' : ''}`}>
          <MetricsContent metricsData={myMetrics} />
        </Tab>
        <Tab eventKey={1} title={getTeamMetricsTitle()} tabClassName="p-1 mr-2">
          <MetricsContent isTeamMetrics isAdmin={isAdminUser} metricsData={teamMetrics} />
        </Tab>
        <Tab eventKey={2} title="Individual Metric Breakdown" tabClassName={`p-1 mr-2 ${!isAdminUser ? 'd-none' : ''}`}>
          <MetricsBreakdown
            isTeamMetric
            metricsData={metricBreakdown}
            billMetricBreakdown={billMetricBreakdown}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Metrics;
