// Libraries
import React, { useEffect, useState } from 'react';
import moment from 'moment';
// Contants
import { COUNTDOWN_TIMEOUT } from '../../../constants/constants';

export const CountDownTimer = ({ onAction }) => {
  const [timer, setTimer] = useState(COUNTDOWN_TIMEOUT);
  const [referenceTime, setReferenceTime] = useState(Date.now());
  let idTimeOut = null;

  useEffect(() => {
    const countDownUntilZero = () => {
      setTimer((prevTime) => {
        if (prevTime <= 0) return 0;

        const now = Date.now();
        const interval = now - referenceTime;
        setReferenceTime(now);
        return prevTime - interval;
      });
    };

    idTimeOut = setTimeout(countDownUntilZero, 100);

    if ((timer / 1000).toFixed(0) < 1) {
      onAction();
    }

    return () => clearTimeout(idTimeOut);
  }, [timer]);

  const renderCountdown = () => {
    const newTime = moment.duration(timer);
    const options = { minimumIntegerDigits: 2, useGrouping: false };
    return `${newTime.minutes()}:${newTime
      .seconds()
      .toLocaleString('en-US', options)}`;
  };

  return (<span>{renderCountdown()}</span>);
};

export default CountDownTimer;
