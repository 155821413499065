// libraries
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// views
import SatisfactionSurveysModal from './SatisfactionSurveysModal';
import SatisfactionSurveysRow from './SatisfactionSurveysRow';
// Services
import { getSurveys } from '../../../../services/patient';
// Actions
import ShowNotification from '../../../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../../../constants/constants';

const SatisfactionSurveys = () => {
  const { id: patientId } = useParams();
  const [surveyList, setSurveyList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState(null);

  const dispatch = useDispatch();

  const openSatisfactionSurveysModal = () => setIsModalOpen(true);

  const handleGetSurveys = () => {
    const createSurveyAttemptRequest = getSurveys(patientId);
    const createSurveyAttemptPromise = createSurveyAttemptRequest.promise;

    return createSurveyAttemptPromise.then((response) => {
      delete createSurveyAttemptRequest.promise;

      const { attempts = [], completions = [] } = response;

      setSurveyList([...attempts, ...completions]);
    }).catch((error) => {
      delete createSurveyAttemptRequest.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      dispatch(ShowNotification({
        message: 'An error occurred while getting the surveys',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      }));
    });
  };

  const handleEditSurvey = (survey) => {
    setEditMode(true);
    setSurveyInfo(survey);
    openSatisfactionSurveysModal();
  };

  useEffect(() => {
    handleGetSurveys();
  }, []);

  useEffect(() => {
    if (!editMode) setSurveyInfo(null);
  }, [editMode]);

  return (
    <div className="card border-0 mb-4">
      <div className="card-header d-flex-center rounded-0 border bg-ccm-light-gray text-ccm-bismark py-1">
        <span className="text-uppercase ml-auto">Satisfaction Surveys</span>
        <Button
          size="sm"
          variant="link-dark"
          className="ml-auto"
          data-test="satisfactionSurvey_openModalBtn"
          onClick={() => openSatisfactionSurveysModal()}
        >
          <i className="d-flex-center bi-plus-lg" />
        </Button>
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2 w-25">Date</th>
              <th className="py-2 w-25">Type</th>
              <th className="py-2 w-25">Reviewer</th>
              <th className="py-2 w-25" />
            </tr>
          </thead>
          <tbody data-test="enrollmentHistory_enrollmentHistoryTableBody">
            <SatisfactionSurveysRow
              surveyList={surveyList}
              handleGetSurveys={handleGetSurveys}
              handleEditSurvey={handleEditSurvey}
            />
          </tbody>
        </table>
      </div>
      <SatisfactionSurveysModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleGetSurveys={handleGetSurveys}
        editMode={editMode}
        surveyInfo={surveyInfo}
        setEditMode={setEditMode}
      />
    </div>
  );
};

export default SatisfactionSurveys;
