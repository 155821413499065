// libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Button } from 'react-bootstrap';
// services
import { validateDate } from '../../../services/helpers';
// views
import Pager from '../../patients/Pager';
import { Paginate, Sort } from '../../../actions/search';
import ShowNotification, { HideNotification } from '../../../actions/notification';
import BillingHistoryItem from './BillingHistoryItem';
import OneFieldDatePicker from '../../base/OneFieldDatePicker';

// constants
import { DATE_FORMAT } from '../../../constants/constants';

const MAX_SHOWN_PAGES = 3;
const SORT_BY_DATE_PARAMETER = 'DATE_OF_SERVICE';

export class BillingHistoryTable extends Component {
  state = {
    params: {
      sort: {
        key: SORT_BY_DATE_PARAMETER,
        reverse: true,
      },
      currentPage: 0,
      dateRange: {},
    },
  };

  componentDidMount() {
    this.getBillingHistory();
  }

  getBillingHistory = () => {
    const { getBillingHistory } = this.props;

    if (!getBillingHistory) {
      return;
    }

    getBillingHistory(this.state.params);
  };

  downloadActivitiesPdf = () => {
    const { downloadReport } = this.props;
    const { params } = this.state;
    downloadReport(params);
  };

  updateDateRange = (key, value, isValid) => {
    if (!isValid) {
      return;
    }

    this.setState(prevState => ({
      params: {
        ...prevState.params,
        currentPage: 0,
        dateRange: {
          ...prevState.params.dateRange,
          [key]: value,
        },
      },
    }), this.getBillingHistory);
  };

  calculateClass = (prop) => {
    const { params: { sort: { reverse, key } } } = this.state;

    let result = reverse ? ' reverse' : ' ';

    if (key === prop) {
      result += ' active';
    }

    return result;
  };

  sortBy = (property) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        sort: {
          key: property,
          reverse: (prevState.params.sort.key === property)
            ? !prevState.params.sort.reverse
            : false,
        },
      },
    }), this.getBillingHistory);
  };

  createClickHandler = prop => () => this.sortBy(prop);

  goToPage = (pageNumber) => {
    const { loading } = this.props;

    if (loading) {
      return;
    }

    this.setState(prevState => ({
      params: {
        ...prevState.params,
        currentPage: pageNumber,
      },
    }), this.getBillingHistory);
  };

  updateValidateDate = (name, ref) => {
    try {
      this.refs = {
        ...this.refs,
        [name]: ref.getWrappedInstance(),
      };

      Object.values(this.refs).forEach((value) => {
        value.validate();
      });
    } catch (err) {
    }
  };

  renderTableHead = () => {
    const { shouldDisplayPatientData } = this.props;
    let patientData;
    if (shouldDisplayPatientData) {
      patientData = (
        <Fragment>
          <td>Patient ID</td>
          <td>Patient name</td>
        </Fragment>
      );
    }

    return (
      <tr>
        <td />
        <td
          className={`sortable date-column${this.calculateClass(SORT_BY_DATE_PARAMETER)}`}
          onClick={this.createClickHandler(SORT_BY_DATE_PARAMETER)}
        >
          DOS
        </td>
        {patientData}
        <td>Total minutes spent</td>
        <td>Status</td>
        <td>ICD10 codes</td>
        <td>CPT code</td>
        <td />
      </tr>
    );
  };

  renderTableData() {
    const { billingHistory, shouldDisplayPatientData } = this.props;

    return billingHistory.map(billingItem => (
      <BillingHistoryItem
        key={`billingHistoryItem__${billingItem.patientId}__${billingItem.reportId}`}
        billingItem={billingItem}
        shouldDisplayPatientData={shouldDisplayPatientData}
      />
    ));
  }

  renderDateRange() {
    const { dateRange } = this.state.params;
    const { timezone, loading } = this.props;

    const currentDate = timezone
      ? moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER)
      : moment().format(DATE_FORMAT.FULL_SERVER);
    return (
      <div className="auditing-date-range">
        <div className="flex-wrapper">
          <div className="label">From:</div>
          <OneFieldDatePicker
            ref={this.updateValidateDate.bind(this, 'from')}
            submitCallback={this.updateDateRange}
            fieldKey="from"
            initialValue={dateRange.from}
            maxDate={dateRange.to || currentDate}
            maxDateName="End date"
            dateName="Start date"
            validationCallback={validateDate}
            disabled={loading}
          />
        </div>

        <div className="flex-wrapper">
          <div className="label">To:</div>
          <OneFieldDatePicker
            ref={this.updateValidateDate.bind(this, 'to')}
            submitCallback={this.updateDateRange}
            fieldKey="to"
            initialValue={dateRange.to}
            minDate={dateRange.from}
            minDateName="Start date"
            maxDate={currentDate}
            dateName="End date"
            validationCallback={validateDate}
            disabled={loading}
          />
        </div>
        <Button
          variant="primary"
          onClick={this.downloadActivitiesPdf}
          disabled={loading}
        >
          Download
        </Button>
      </div>
    );
  }

  render() {
    const { params: { currentPage } } = this.state;
    const { billingHistory, totalPages } = this.props;

    if (!billingHistory) {
      return null;
    }

    return (
      <div>
        {this.renderDateRange()}
        <table className="problem-list-table billing-history-table">
          <thead>
            {this.renderTableHead()}
          </thead>
          <tbody>
            {this.renderTableData()}
          </tbody>
        </table>
        <Pager
          totalPages={totalPages}
          callback={this.goToPage}
          maxShownCount={MAX_SHOWN_PAGES}
          currentPage={currentPage}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchParams: state.search.searchParams,
  pageNumber: state.search.pageNumber,
  pageSize: state.search.pageSize,
  sortParams: state.search.sortParams,
  isSessionLoaded: state.search.isSessionLoaded,
  timezone: state.tenant && state.tenant.timezone,
});

const mapDispatchToProps = dispatch => ({
  hideNotification: () => dispatch(HideNotification()),
  paginate: paginationData => dispatch(Paginate(paginationData)),
  showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  sort: sortingData => dispatch(Sort(sortingData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingHistoryTable);
