// Libraries
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _cloneDeep from 'lodash/cloneDeep';
// Constants
import {
  DATE_FORMAT, USER_BILLING_STATUSES, BILLING_STATUSES, ELIGIBILITY_ISSUES,
} from '../../../constants/constants';

export function BillingStatus(props) {
  const {
    patient, patient: { billing: patientBilling, status: patientStatus, billingSettings } = {},
  } = props;

  const isCSDoNotBill = billingSettings && billingSettings.doNotBill && patientStatus === 'CS';

  if (!isCSDoNotBill
      && (!patient || !patientBilling || !USER_BILLING_STATUSES.includes(patientStatus))) {
    return (
      <span>
        Billing Status:
        <span className="text-ccm-gray"> Unknown</span>
      </span>);
  }

  const {
    sendingStatus, canNotBeSubmittedReason, issues: billingIssues,
    incorrectlyBilledPeriods: billingIncorrectlyBilledPeriods,
    billingInfo: { onHoldStatusInfo: onHoldDescription } = {},
  } = patientBilling || {};

  const nBillingIssues = billingIssues ? _cloneDeep(billingIssues) : [];
  let billingStatus = sendingStatus ? BILLING_STATUSES[sendingStatus] : null;

  billingStatus = isCSDoNotBill ? BILLING_STATUSES.ON_HOLD : BILLING_STATUSES[sendingStatus];

  if (!billingStatus) {
    return (
      <span>
        Billing Status:
        <span className="text-ccm-gray"> Unknown</span>
      </span>);
  }

  let billingDescription;
  const statusDescriptions = billingStatus.tooltips || {};

  if (canNotBeSubmittedReason) {
    const customDescription = statusDescriptions[canNotBeSubmittedReason];
    billingDescription = customDescription || billingStatus.defaultTooltip
      ? `(${customDescription || billingStatus.defaultTooltip})` : null;
  }
  const isEmpty = value => (value == null || value.trim().length === 0);
  let userLabel;
  let onHoldDate;
  if (onHoldDescription) {
    userLabel = !isEmpty(onHoldDescription.userName) ? onHoldDescription.userName : 'System';
    onHoldDate = !isEmpty(onHoldDescription.date) ? ` ${moment(onHoldDescription.date, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL)}` : null;
  }
  const billingStatusBlock = (
    <Fragment>
      <div className="d-flex">
        <span className="p-1">Billing Status:</span>
        <span className="p-1 text-ccm-gray">
          {billingStatus.name}
          {billingDescription}
        </span>
        { sendingStatus === 'ON_HOLD' || isCSDoNotBill
          ? (
            <div className="text-nowrap text-ccm-gray text-right small ml-auto d-flex align-items-center" data-test="billingStatus_labelUserInfo">
              {userLabel}
              {onHoldDate}
            </div>
          ) : ''}
      </div>


    </Fragment>
  );

  let billingIssuesBlock;
  if (isCSDoNotBill) nBillingIssues.push('BILLING_ON_HOLD');
  if (nBillingIssues && nBillingIssues.length) {
    billingIssuesBlock = (
      <ul data-test="billingStatus_alerts" className="mt-2">
        {
          nBillingIssues.map((issue, index) => {
            if (!ELIGIBILITY_ISSUES[issue]) {
              return null;
            }

            return (
              <li key={`billing-issues-${index}`} className="text-danger">
                {ELIGIBILITY_ISSUES[issue].name}
              </li>
            );
          })
        }
      </ul>
    );
  }

  let billingIncorrectlyBilledPeriodsBlock;

  if (billingIncorrectlyBilledPeriods && billingIncorrectlyBilledPeriods.length
      && sendingStatus === 'SUBMITTED_TO_BILLING') {
    let billingIncorrectlyBilledPeriodsDates = '';
    billingIncorrectlyBilledPeriods.forEach((period) => {
      if (period.reason !== 'TCM_ENROLLMENT') {
        return;
      }

      let startDateText;
      let endDateText;
      if (period.startedAt) {
        startDateText = moment(
          period.startedAt, DATE_FORMAT.FULL_SERVER,
        ).format(DATE_FORMAT.FULL);
      }
      if (period.endedAt) {
        endDateText = moment(period.endedAt, DATE_FORMAT.FULL_SERVER).format(DATE_FORMAT.FULL);
      }

      billingIncorrectlyBilledPeriodsDates += `${startDateText}-${endDateText}, `;
    });

    billingIncorrectlyBilledPeriodsDates = billingIncorrectlyBilledPeriodsDates.substring(0,
      billingIncorrectlyBilledPeriodsDates.length - 2);

    billingIncorrectlyBilledPeriodsBlock = (
      <div>
        {`Bill for CCM services for the patient has already been submitted on ${billingIncorrectlyBilledPeriodsDates}. TCM and CCM cannot bill for services provided during the same month.`}
      </div>
    );
  }

  return (
    <div data-test="billingStatus_billingStatusLable">
      {billingStatusBlock}
      {billingIncorrectlyBilledPeriodsBlock}
      {billingIssuesBlock}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

export default connect(mapStateToProps)(BillingStatus);
