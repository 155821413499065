// Libraries
import React, {
  useEffect, useState, Fragment, lazy, Suspense,
} from 'react';
import { Button } from 'react-bootstrap';
import _camelCase from 'lodash/camelCase';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// Services
import { getHealthFactors } from '../../services/patient';
// Actions
import { UpdatePatient } from '../../actions/patient';
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
// Components
const ResourceModal = lazy(() => import('./healthFactors/ResourceModal'));
const HealthFactorsModal = lazy(() => import('./healthFactors/HealthFactorsModal'));


export const HealthFactors = () => {
  const { id: patientId } = useParams();
  const { healthFactors = {} } = useSelector(state => state.patient);

  const dispatch = useDispatch();
  const updatePatient = data => dispatch(UpdatePatient(data));
  const showNotification = data => dispatch(ShowNotification(data));

  const [factor, setFactor] = useState();
  const [resourceItem, setResourceItem] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [factorList, setFactorList] = useState(healthFactors);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);

  const allFalseValues = item => !item.value;
  const getNote = item => item.displayName === 'Additional Information';
  const excludeNote = item => item.displayName !== 'Additional Information';

  const openHealthFactorModal = (item) => {
    setFactor(item);
    setIsModalOpen(true);
  };

  const openResourceModal = (item) => {
    setResourceItem(item);
    setIsResourceModalOpen(true);
  };

  const fetchFactors = async () => {
    const fetchRequest = getHealthFactors(patientId);
    const fetchPromise = fetchRequest.promise;

    try {
      const data = await fetchPromise;
      delete fetchRequest.promise;
      updatePatient({ healthFactors: data });
    } catch (error) {
      delete fetchPromise.promise;
      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }
      showNotification({
        message: 'An error has occurred while attempting to load the patient health factors.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    }
  };

  const updateFactor = (item) => {
    const updatedFactorList = factorList.map(e => (e.id === item.id ? item : e));
    updatePatient({ healthFactors: updatedFactorList });
    showNotification({
      message: 'Successfully updated Health Factors.',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.SUCCESS,
    });
  };

  const renderNote = (value) => {
    const factorNote = value.factors.filter(getNote);
    return factorNote && factorNote.length > 0 && factorNote[0].note
      ? <span className="text-break">{factorNote[0].note}</span>
      : <span className="text-ccm-gray"><em>none</em></span>;
  };

  const renderResources = (resources) => {
    if (resources && resources.length > 0 && resources.filter(resource => resource.status !== 'DELETED').length > 0) {
      return (
        <ul className="text-ccm-blue">
          {resources.filter(resource => resource.status !== 'DELETED').map(resource => (
            <li
              className="mb-1"
              onClick={() => openResourceModal(resource)}
              key={`resource__${resource.healthFactorCategoryId}__${resource.id}`}
              data-test={`resource__${resource.healthFactorCategoryId}__${resource.id}`}
            >
              <span>{resource.name}</span>
            </li>
          ))}
        </ul>
      );
    }

    return (
      <span className="d-block text-ccm-gray">
        <em>No resources have been added for this category</em>
      </span>
    );
  };

  useEffect(() => { setFactorList(healthFactors); }, [healthFactors]);

  useEffect(() => { fetchFactors(); }, []);

  return (
    <Fragment>
      <div className="patient-health-factors h-100 pr-2 overflow-auto text-left">
        <h4 className="text-uppercase my-3">patient health factors</h4>
        {(factorList && factorList.length > 0)
        && factorList.map(item => (
          <div key={`healthFactor__${item.id}${_camelCase(item.displayName)}`} className="health-factor px-2 py-3">
            <div className="d-flex-center-between">
              <h5 className="mb-0">{item.displayName}</h5>
              <Button
                size="lg"
                variant="link"
                className="p-0 m-0"
                onClick={() => openHealthFactorModal(item)}
                data-test={`healthFactors_editHealthFactor_${item.id}`}
              >
                <i className="d-flex-center bi-pencil-square" />
              </Button>
            </div>
            <div className="d-flex">
              <div className="w-50">
                <ul className="pl-4 my-3">
                  {item.factors.filter(excludeNote).map(subItem => (
                    subItem.value && (
                    <li key={`subFactor__${subItem.id}${_camelCase(subItem.displayName)}`} className="mb-1">
                      <span>{subItem.displayName}</span>
                    </li>)
                  ))}
                </ul>
                {item.factors.filter(excludeNote).every(allFalseValues) && (
                  <span className="d-block text-ccm-gray ml-2 mb-3"><em>none specified</em></span>
                )}
                <div className="ml-2">
                  <h6>Additional Information:</h6>
                  <p>{renderNote(item)}</p>
                </div>
              </div>
              <div className="w-50 resources-text">
                <h5 className="font-italic text-capitalize">resources</h5>
                {renderResources(item.resources)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Suspense fallback={null}>
        {isModalOpen && (
          <HealthFactorsModal
            factor={factor}
            patientId={patientId}
            isModalOpen={isModalOpen}
            updateFactor={updateFactor}
            setIsModalOpen={setIsModalOpen}
          />
        )}
        {isResourceModalOpen && (
          <ResourceModal
            resource={resourceItem}
            isModalOpen={isResourceModalOpen}
            setIsModalOpen={setIsResourceModalOpen}
          />
        )}
      </Suspense>
    </Fragment>
  );
};

export default HealthFactors;
