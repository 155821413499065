// libraries
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
// Images

export function QAControl() {
  const { tenant: tenantUrl } = useParams();
  return (
    <span className="qa-control d-flex-center mr-5">
      <NavLink
        to={`/${tenantUrl}/cn/qa-control`}
        className={({ isActive }) => `d-flex-center${isActive ? ' active' : ''}`}
        data-test="qaControl_link"
      >
        <i className="qa-control-icon bi bi-journal-check mr-1" />
        <span>QA</span>
      </NavLink>
    </span>
  );
}

export default QAControl;
