// libraries
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { useKeycloak } from '@react-keycloak/web';
import { useParams, useNavigate } from 'react-router-dom';
// actions
import { UnBlockRouteTransitions } from '../../actions/router';
// constants
import {
  ADMIN_TOP_MENU_ITEMS, DEMO_ITEM, OTHER_ITEM,
  USER_ROLES, USER_TOP_MENU_ITEMS,
} from '../../constants/constants';
import messages from '../../constants/messages';
// services
import { logoutUser } from '../../services/login';

export const TopMenu = (props) => {
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();
  const { tenant: tenantUrl } = useParams();

  const getConfirmLeavingMessage = () => {
    const {
      isTimer, isActivity, isBilling, isExistingActivity,
    } = props;
    let confirmMessage = '';

    if (isTimer) {
      confirmMessage = (isActivity || isBilling || isExistingActivity)
        ? messages.leaveUnSavedData
        : messages.leaveUnStoppedTimer;
    } else {
      if (isActivity || isExistingActivity) {
        confirmMessage = messages.leaveUnSavedTimeTracking;
      }

      if (isBilling) {
        confirmMessage = messages.leaveUnSavedBilling;
      }
    }

    return confirmMessage;
  };

  const handleLogout = () => {
    const { isBlocked, unblockTransitions } = props;

    // check if route transitions are allowed
    // (not allowed if there is unsaved time tracking record or running timer)
    if (!isBlocked) {
      logoutUser(keycloak);
    } else {
      const confirmMessage = getConfirmLeavingMessage();

      if (window.confirm(confirmMessage)) {
        unblockTransitions();
        setTimeout(() => {
          logoutUser(keycloak);
        }, 500);
      }
    }
  };

  const goTo = (url) => {
    navigate(url);
  };

  const pushMenuItems = items => items.map((item, index) => {
    const url = `/${tenantUrl}/cn/${item.url}`;

    return (
      <Dropdown.Item
        key={`top-menu-item-${index}`}
        onClick={() => goTo(url)}
        data-test={`${item.url}-menu-item`}
      >
        {item.title}
      </Dropdown.Item>
    );
  });

  const renderMenuItems = () => {
    const { user: { role, isExternal }, isDemoEnv = false } = props;
    const menuItems = [];

    // Push Admin Items
    if (role === USER_ROLES.ADMIN) {
      menuItems.push(pushMenuItems(ADMIN_TOP_MENU_ITEMS));
    }

    // Push All users Items
    menuItems.push(pushMenuItems(USER_TOP_MENU_ITEMS));

    // Push Demo Item
    if (role === USER_ROLES.ADMIN && !isExternal && isDemoEnv) {
      menuItems.push(pushMenuItems([DEMO_ITEM]));
    }

    // Push Other Item
    if (role === USER_ROLES.ADMIN) {
      menuItems.push(pushMenuItems([OTHER_ITEM]));
    }

    menuItems.push(<Fragment key="fragment">
      <Dropdown.Divider key="divider" />
      <Dropdown.Item
        key="logout"
        onClick={() => handleLogout()}
        data-test="logout-menu-item"
      >
        Logout
      </Dropdown.Item>
    </Fragment>);

    return menuItems;
  };

  return (
    <Dropdown className="user-menu">
      <Dropdown.Toggle variant="link" data-test="user-menu-toggle" />
      <Dropdown.Menu>
        {renderMenuItems()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    isBlocked: state.router.blocked,
    isDemoEnv: state.tenant && state.tenant.isDemoEnv,
    isTimer: state.router.elements && state.router.elements.isTimer,
    isActivity: state.router.elements && state.router.elements.isActivity,
    isExistingActivity: state.router.elements && state.router.elements.isExistingActivity,
    isBilling: state.router.elements && state.router.elements.isBilling,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    unblockTransitions: () => dispatch(UnBlockRouteTransitions()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
