// libraries
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate, useParams } from 'react-router-dom';
// constants
import { LOCAL_STORAGE_TIMER_KEY } from '../../../constants/constants';
import { logoutUser } from '../../../services/login';

export function SessionExpired(props) {
  const { keycloak } = useKeycloak();

  const { tenant: tenantUrl } = useParams();
  const navigate = useNavigate();

  const goToLogin = () => {
    const { isTimer, timer } = props;

    if (isTimer && timer) {
      localStorage.setItem(LOCAL_STORAGE_TIMER_KEY, timer);
    }
    navigate(`/${tenantUrl}`);
  };

  useEffect(() => {
    logoutUser(keycloak, false);
  }, []);

  return (
    <div className="row justify-content-center h-100">
      <div className="col-4 d-flex-center flex-column">
        <div className="border px-5 pt-3 pb-5 bg-light rounded">
          <span style={{ fontSize: '4rem' }} className="bi bi-activity text-ccm-gray" />
          <h4>Your session has expired.</h4>
          <p className="mt-3 text-ccm-oslo">Uh oh, it looks like your session expired</p>
          <Button
            onClick={() => goToLogin()}
            className="mt-1 px-5 rounded-pill"
            variant="ccm-lipstick"
            data-test="sessionExpired_goBackLogin"
          >
            Go back to login
          </Button>
        </div>
      </div>
    </div>
  );
}

export function mapStateToProps(state) {
  return {
    timer: state.router.elements && state.router.elements.timer,
    isTimer: state.router.elements && state.router.elements.isTimer,
  };
}

export default connect(mapStateToProps, null)(SessionExpired);
