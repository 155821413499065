// libraries
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Button } from 'react-bootstrap';
// actions
import ShowNotification from '../../actions/notification';
// constants
import {
  DIALOG_DEFAULTS, NOTIFICATION_TYPE,
} from '../../constants/constants';
// services
import { approveBilling } from '../../services/approveBilling';
// views
import LoadingBlock from '../menu/LoadingBlock';

export class ApproveBilling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modalOpened: false,
    };

    this.promises = {};

    this.openDialog = this.openDialog.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.approveBilling = this.approveBilling.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentWillUnmount() {
    Object.keys(this.promises).forEach((key) => {
      this.promises[key].cancel();
    });
  }

  openDialog() {
    const {
      selectedPatientsIds,
    } = this.props;

    if (!selectedPatientsIds || !selectedPatientsIds.length) {
      return;
    }


    this.setState({
      modalOpened: true,
    });
  }

  handleCloseModal() {
    if (this.state.loading) {
      return;
    }

    this.setState({
      modalOpened: false,
    });
  }

  cancel() {
    this.handleCloseModal();
  }

  approveBilling() {
    const {
      selectedPatientsIds, reloadData, showNotification, submitCallback,
    } = this.props;

    if (this.state.loading || !selectedPatientsIds || !selectedPatientsIds.length) {
      return;
    }

    this.setState({
      loading: true,
    });

    const ids = Array.from(selectedPatientsIds);
    const promiseName = 'approveBilling';
    const approveBillingRequest = approveBilling(ids);
    const approveBillingPromise = approveBillingRequest.promise;
    this.promises[promiseName] = approveBillingPromise;

    approveBillingPromise.then((response) => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
        modalOpened: false,
      });

      let notificationMessage = '';
      let notificationType = NOTIFICATION_TYPE.SUCCESS;

      const patientsApproved = response && response.approvedPatientIds
        && response.approvedPatientIds.length ? response.length : 0;

      if (patientsApproved === 0) {
        notificationMessage = 'None of the selected patients require approval.';
        notificationType = NOTIFICATION_TYPE.ERROR;
      } else if (patientsApproved > 1) {
        notificationMessage = `Billing successfully approved for ${patientsApproved} patients.`;
      } else {
        notificationMessage = 'Billing successfully approved.';
      }

      showNotification({
        message: notificationMessage,
        autoHide: true,
        notificationType,
      });

      if (reloadData) {
        reloadData();
      }

      if (submitCallback) {
        submitCallback(true);
      }
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
        modalOpened: false,
      });

      const errorMsg = error.error || (error.data ? error.data.message : 'unknown error');

      showNotification({
        message: `Could not approve billing due to error: ${errorMsg}`,
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });

      if (submitCallback) {
        submitCallback(false);
      }
    });
  }

  renderModal() {
    const { selectedPatientsIds } = this.props;

    const selectedPatientsCount = selectedPatientsIds ? selectedPatientsIds.length : 0;

    let loadingBlock;
    if (this.state.loading) {
      loadingBlock = <LoadingBlock />;
    }

    let confirmMessage = '';

    if (selectedPatientsCount > 1) {
      confirmMessage = `Are you sure you want to approve and submit bills for these ${selectedPatientsCount} selected patients?`;
    } else {
      confirmMessage = 'Are you sure you want to approve and submit bills for this patient?';
    }

    return (
      <Modal
        isOpen={this.state.modalOpened}
        style={DIALOG_DEFAULTS}
        onRequestClose={this.cancel}
        contentLabel="Approve Billing"
      >
        <div className="simple-dialog medium-dialog">
          <div className="dialog-title">
            Approve Billing
            <button
              type="button"
              className="close-icon i-close"
              onClick={this.handleCloseModal}
              data-test="approveBilling_modalCloseButton"
            />
          </div>
          <div className="dialog-content">
            {loadingBlock}
            <p className="text-left mt-3">
              {confirmMessage}
            </p>
          </div>
          <div className="dialog-buttons justify-content-end px-4">
            <Button
              variant="light"
              onClick={this.cancel}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="ml-2"
              onClick={this.approveBilling}
            >
              Approve
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  renderCompactButton() {
    return (
      <Button
        size="sm"
        className="my-1"
        variant="outline-ccm-orange"
        onClick={this.openDialog}
      >
        <i className="bi-clipboard-check" />
      </Button>
    );
  }

  renderFullSizeButton() {
    const { selectedPatientsIds } = this.props;
    return (
      <Button
        variant="light"
        className="d-flex-center mr-1"
        onClick={this.openDialog}
        disabled={selectedPatientsIds && selectedPatientsIds.length === 0}
        data-test="bulkApproveBillingButton"
      >
        <i className="bi-clipboard-check mr-2" />
        <span className="mb-0">
          <span>Approve&nbsp;</span>
          <span>Bill</span>
        </span>
      </Button>
    );
  }

  render() {
    const { renderCompact } = this.props;
    let approveBillingButton;

    if (renderCompact) {
      approveBillingButton = this.renderCompactButton();
    } else {
      approveBillingButton = this.renderFullSizeButton();
    }

    return (
      <Fragment>
        {approveBillingButton}
        {this.renderModal()}
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(null, mapDispatchToProps)(ApproveBilling);
