export function SetDefaultSearchParams(searchParams) {
  return {
    type: 'SET_DEFAULT_SEARCH_PARAMS',
    searchParams,
  };
}

export function Search(searchParams, isSearchParamsOverwritten) {
  return {
    type: 'SEARCH',
    searchParams,
    isSearchParamsOverwritten: !!isSearchParamsOverwritten,
  };
}

export function ResetSearch() {
  return {
    type: 'RESET_SEARCH',
  };
}

export function ResetSideBarSearch() {
  return {
    type: 'RESET_SIDEBAR_SEARCH',
  };
}

export function OpenSearch() {
  return {
    type: 'OPEN_SEARCH',
  };
}

export function CloseSearch() {
  return {
    type: 'CLOSE_SEARCH',
  };
}

export function LoadSession(searchParams, pageNumber, pageSize, sortParams) {
  return {
    type: 'LOAD_SESSION',
    searchParams,
    pageNumber,
    pageSize,
    sortParams,
  };
}

export function LoadEmptySession(cnPesId) {
  return {
    type: 'LOAD_EMPTY_SESSION',
    cnPesId,
  };
}

export function Paginate(pageNumber, pageSize) {
  return {
    type: 'PAGINATE',
    pageNumber,
    pageSize,
  };
}

export function Sort(sortParams) {
  return {
    type: 'SORT',
    sortParams,
  };
}
