// Libraries
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// Actions
import ShowNotification from '../../actions/notification';
// Constants
import { NOTIFICATION_TYPE } from '../../constants/constants';
import { PATIENTS_LIST_PAGE_SIZE } from '../../constants/pageSizes';
// Services
import { searchQaPatients } from '../../services/patientList';
import { prepareSearchQuery } from '../../components/patients/PatientList';

const useQaPatients = ({
  pageSize = PATIENTS_LIST_PAGE_SIZE, searchParams, sort, currentPage,
}) => {
  const [patientsList, setPatientsList] = useState({});
  const promises = {};
  const dispatch = useDispatch();

  const showNotification = notificationData => dispatch(ShowNotification(notificationData));

  const fetchPatientsListData = useCallback(async () => {
    const promiseName = 'searchQaPatients';
    const searchQaPatientsRequest = searchQaPatients(pageSize,
      prepareSearchQuery(searchParams, sort, currentPage));
    const searchQaPatientsPromise = searchQaPatientsRequest.promise;
    promises[promiseName] = searchQaPatientsRequest;

    return searchQaPatientsPromise.then((data) => {
      delete promises[promiseName];
      setPatientsList(data);
    }).catch((error) => {
      delete promises[promiseName];
      if (error.status === 401 || error.status === 403 || error.isCanceled) {
        return;
      }
      showNotification({
        message: 'An unexpected error has occurred while trying to load the qa patient list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }, [pageSize, searchParams, sort, currentPage]);

  useEffect(() => {
    fetchPatientsListData();
  }, [fetchPatientsListData]);

  return { patientsList, refetch: fetchPatientsListData };
};

export default useQaPatients;
