// libraries
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import { Button } from 'react-bootstrap';
// Views
import HealthFactorList from './HealthFactorsList';
import { Loading } from '../../base/Loading';
// Hooks
import useLoadHealthFactors from '../../../hooks/services/useLoadHealthFactors';
// Lazy
const HealthFactorCategoryModal = lazy(() => import('./HealthFactorCategoryModal'));

const HealthFactors = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalsOpened, setModalsOpened] = useState([]);
  const { refetch } = useLoadHealthFactors(true);

  const addNewCategory = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (isModalOpen && !modalsOpened.includes('isModalOpen')) {
      setModalsOpened([...modalsOpened, 'isModalOpen']);
    }
  }, [isModalOpen]);
  return (
    <div id="healthFactors">
      <h4 className="text-uppercase text-left my-3" data-test="healthFactors_title">Health Factors</h4>
      <div className="my-2 text-right">
        <Button size="sm" onClick={() => addNewCategory()} data-test="healthFactors_addBtn">Add New Category</Button>
      </div>
      <HealthFactorList />
      { modalsOpened.includes('isModalOpen') && (
        <Suspense fallback={<Loading forceLoading />}>
          <HealthFactorCategoryModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            callbackAction={refetch}
          />
        </Suspense>)
      }
    </div>
  );
};

export default HealthFactors;
