// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
// views
import TopMenu from './TopMenu';
// constants
import { TIME_FORMAT } from '../../constants/constants';

export class User extends Component {
  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const {
      timezone, firstName, lastName, tenantName,
    } = this.props;
    const formattedTimezone = timezone && timezone.replace(/\//g, ', ').replace(/(America|US), /g, '').replace(/_/g, ' ');

    return (
      <div className="user-control">
        <div className="user-messages">
          <div className="icon" />
          <div className="counter" />
        </div>
        <div className="d-flex text-left">
          <div className="user-credentials">
            <div className="username" data-for="tooltip-topBar" data-tip={`${firstName} ${lastName}`} data-test="logged-in-user">
              {`${firstName} ${lastName}`}
            </div>
            <div className="tenant" data-for="tooltip-topBar" data-tip={timezone ? `${moment.tz(timezone).format(TIME_FORMAT.SHORT_TIME)} (${formattedTimezone})` : ''} data-test="user_tenantTimezone">{tenantName}</div>
          </div>
          <TopMenu />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  timezone: state.tenant && state.tenant.timezone,
  firstName: state.user.firstName,
  lastName: state.user.lastName,
  tenantName: state.tenant && state.tenant.name,
  requestsInProgress: state.requestsInProgress.count,
});

export default connect(mapStateToProps)(User);
