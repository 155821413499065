// libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
// actions
import {
  NOTIFICATION_TYPE,
  KEY_CODE,
  TENANT_FEATURES_NAMES,
} from '../../../constants/constants';
import ShowNotification from '../../../actions/notification';
import { SetBillingPhysiciansList } from '../../../actions/physicians';
// services
import { getBillablePhysiciansConfig } from '../../../services/administration';
import { getPhysicians } from '../../../services/settings';
import { getBillingPhysicians, getLocations } from '../../../services/providers';
// view
import Pager from '../../patients/Pager';
import BillableProviderRow from './BillableProviderRow';

const MAX_SHOWN_PAGES = 3;
const PAGE_SIZE = 20;

const SORT_VALUES = {
  firstName: 'firstName',
  lastName: 'lastName',
};

const FILTER_BY_BILLABLE = {
  billable: 'Yes',
  notBillable: 'No',
  all: 'All',
};

const FILTER_BY_ACTIVE = {
  active: 'Yes',
  notActive: 'No',
  all: 'All',
};

export class BillableProviders extends Component {
  promises = [];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      physicians: [],
      locations: [],
      currentPage: 0,
      totalPages: 0,
      billableFilter: FILTER_BY_BILLABLE.billable,
      activeFilter: FILTER_BY_ACTIVE.active,
      lastNameFilter: {
        value: '',
        active: false,
      },
      sort: {
        key: '',
        reverse: false,
      },
      isProviderGroupEnabled: props.isProviderGroupEnabled,
      isEhrIdRequired: false,
      isNpiRequired: false,
      isLocationRequired: false,
      isLocationEditable: false,
    };
    this.onKeyUp = this.onKeyUp.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.clickOnSearchIcon = this.clickOnSearchIcon.bind(this);
  }

  componentDidMount() {
    this.loadPhysicians();
    this.loadBillingPhysiciansList();
    this.loadLocationList();
    this.loadBillableProviderConifg();
  }

  componentWillUnmount() {
    Object.keys(this.promises)
      .forEach((key) => {
        this.promises[key].cancel();
      });
  }

  onKeyUp(event) {
    const { lastNameFilter } = this.state;

    if (event.keyCode === KEY_CODE.ENTER) {
      this.setState({
        lastNameFilter: { ...lastNameFilter, active: true },
      }, this.setFilterByLastName(event));
    }
    if (!event.target.value) {
      this.setState({
        lastNameFilter: { ...lastNameFilter, active: false },
      }, this.setFilterByLastName(event));
    }
  }

  loadLocationList = () => {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const locationList = [];

    const promiseName = 'getLocations';
    const getLocationsRequest = getLocations();
    const getLocationsPromise = getLocationsRequest.promise;
    this.promises[promiseName] = getLocationsRequest;

    getLocationsPromise.then((data) => {
      delete this.promises[promiseName];

      if (data) {
        data.forEach((location) => {
          locationList.push({
            value: location.id,
            label: location.name,
          });
        });
      }

      this.setState({
        loading: false,
        locations: locationList,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      const { showNotification } = this.props;

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'An error occurred while trying to load the list of provider locations.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  loadBillableProviderConifg = () => {
    const { showNotification } = this.props;

    this.setState({
      loading: true,
    });

    const promiseName = 'getBillablePhysiciansConfig';
    const getBillablePhysicianConfigRequest = getBillablePhysiciansConfig();
    const getBillablePhysicianConfigPromise = getBillablePhysicianConfigRequest.promise;
    this.promises[promiseName] = getBillablePhysicianConfigRequest;

    getBillablePhysicianConfigPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        isEhrIdRequired: data ? data.requireEhrId : false,
        isNpiRequired: data ? data.requireNpi : false,
        isLocationRequired: data ? data.requireLocation : false,
        isLocationEditable: data ? data.allowLocationEdit : false,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'An error occurred while trying to load the provider configuration options.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  }

  loadBillingPhysiciansList = () => {
    const { setBillingPhysiciansList } = this.props;
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const promiseName = 'getBillingPhysicians';
    const getBillingPhysiciansRequest = getBillingPhysicians();
    const getBillingPhysiciansPromise = getBillingPhysiciansRequest.promise;
    this.promises[promiseName] = getBillingPhysiciansRequest;

    getBillingPhysiciansPromise.then((data) => {
      delete this.promises[promiseName];

      if (data) {
        setBillingPhysiciansList(data);
      }

      this.setState({
        loading: false,
      });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      const { showNotification } = this.props;

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load physicians list.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  goToPage = (page) => {
    const { loading } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      currentPage: page,
    }, this.loadPhysicians);
  };

  loadPhysicians = () => {
    const {
      loading, currentPage, sort, billableFilter, activeFilter, lastNameFilter,
    } = this.state;

    if (loading) {
      return;
    }

    this.setState({
      loading: true,
    });

    const searchParams = {
      pageNumber: currentPage,
      sortBy: sort.key,
      sortOrder: sort.reverse ? 'DESC' : 'ASC',
    };

    let billable;
    if (billableFilter !== FILTER_BY_BILLABLE.all) {
      billable = billableFilter === FILTER_BY_BILLABLE.billable;
    }
    let active;
    if (activeFilter !== FILTER_BY_ACTIVE.all) {
      active = activeFilter === FILTER_BY_ACTIVE.active;
    }

    const { showNotification } = this.props;
    const promiseName = 'getPhysicians';
    const getPhysiciansRequest = getPhysicians(
      PAGE_SIZE, searchParams, billable, active, lastNameFilter.value,
    );

    const getPhysiciansPromise = getPhysiciansRequest.promise;
    this.promises[promiseName] = getPhysiciansRequest;

    getPhysiciansPromise.then((data) => {
      delete this.promises[promiseName];

      this.setState({
        loading: false,
        physicians: data.physicians,
        totalPages: data.totalPages,
      });

      if ((!data.physicians || !data.physicians.length) && currentPage) {
        this.goToPage(currentPage - 1);
      }

      this.props.pageTop.current.scrollIntoView({ block: 'start' });
    }).catch((error) => {
      if (error.isCanceled) {
        return;
      }

      delete this.promises[promiseName];

      if (error.status === 401 || error.status === 403) {
        return;
      }

      this.setState({
        loading: false,
      });

      showNotification({
        message: 'Could not load users data.',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  calculateClass = (prop) => {
    const { sort: { reverse, key } } = this.state;

    let result = '';

    if (key === prop) {
      result += ' active';
      result += reverse ? ' reverse' : ' ';
    }

    return result;
  };

  sortBy = (property) => {
    this.setState(prevState => ({
      ...prevState,
      sort: {
        key: property,
        reverse: (prevState.sort.key === property)
          ? !prevState.sort.reverse
          : false,
      },
    }), this.loadPhysicians);
  };

  setFilterByBillable = (event) => {
    const { value } = event.target;

    if (this.state.loading || this.state.billableFilter === value) {
      return;
    }

    this.setState({
      currentPage: 0,
      billableFilter: value,
      sort: {
        key: '',
        reverse: false,
      },
    }, this.loadPhysicians);
  };

  setFilterByActive = (event) => {
    const { value } = event.target;

    if (this.state.loading || this.state.activeFilter === value) {
      return;
    }

    this.setState({
      currentPage: 0,
      activeFilter: value,
      sort: {
        key: '',
        reverse: false,
      },
    }, this.loadPhysicians);
  };

  setFilterByLastName = (event) => {
    const { value } = event.target;
    const { lastNameFilter } = this.state;

    if (this.state.loading) {
      return;
    }

    this.setState({
      currentPage: 0,
      lastNameFilter: { ...lastNameFilter, value },
      sort: {
        key: '',
        reverse: false,
      },
    }, this.loadPhysicians);
  };

  handleChange(event) {
    const { value } = event.target;
    this.setState({
      lastNameFilter: { value },
    });
  }

  clickOnSearchIcon() {
    if (this.state.loading) {
      return;
    }
    this.loadPhysicians();
  }

  renderRadioBtn = (value, isActive = false) => {
    const { loading, billableFilter, activeFilter } = this.state;
    const filterObj = {
      filter: isActive ? activeFilter : billableFilter,
      filterName: isActive ? 'billable' : 'active',
      setFilter: isActive ? this.setFilterByActive : this.setFilterByBillable,
    };
    return (
      <label
        className={`radio-container ${loading ? 'radio-container-disabled' : ''}
        ${filterObj.filter !== value
          ? 'radio-container-inactive'
          : ''}`}
      >
        <input
          type="radio"
          name={filterObj.filterName}
          value={value}
          onClick={loading ? () => {} : filterObj.setFilter}
          checked={filterObj.filter === value}
        />
        <div className="radio-icon" />
        {value}
      </label>
    );
  };

  renderPhysicians = () => {
    const {
      physicians,
      isProviderGroupEnabled,
      locations,
      isEhrIdRequired,
      isNpiRequired,
      isLocationRequired,
      isLocationEditable,
    } = this.state;
    if (!physicians || !physicians.length) {
      return null;
    }

    return physicians.map(physician => (
      <BillableProviderRow
        key={`physician-${physician.id}`}
        physician={physician}
        locationList={locations}
        isProviderGroupEnabled={isProviderGroupEnabled}
        isEhrIdRequired={isEhrIdRequired}
        isNpiRequired={isNpiRequired}
        isLocationRequired={isLocationRequired}
        isLocationEditable={isLocationEditable}
        updateCallback={this.loadPhysicians}
      />
    ));
  };

  render() {
    const {
      totalPages,
      currentPage,
      loading,
      lastNameFilter,
      isProviderGroupEnabled,
      locations,
      isEhrIdRequired,
      isNpiRequired,
    } = this.state;

    return (
      <div
        className="admin-section p-3"
        id="billable-providers"
      >
        <div className="title">
          <div className="row">
            <div className="col text-left">
              <h5 className="text-uppercase my-3">Billable Providers</h5>
            </div>
            <div className="col billing-providers-radios">
              <span className="mb-1">Billable:</span>
              {this.renderRadioBtn(FILTER_BY_BILLABLE.billable)}
              {this.renderRadioBtn(FILTER_BY_BILLABLE.notBillable)}
              {this.renderRadioBtn(FILTER_BY_BILLABLE.all)}
            </div>
            <div className="col active-providers-radios">
              <span className="mb-1">Active:</span>
              {this.renderRadioBtn(FILTER_BY_ACTIVE.active, true)}
              {this.renderRadioBtn(FILTER_BY_ACTIVE.notActive, true)}
              {this.renderRadioBtn(FILTER_BY_ACTIVE.all, true)}
            </div>
          </div>
          <div className="search-field-container">
            <div
              className="i-search search-icon"
              onClick={this.clickOnSearchIcon}
            />
            <input
              type="search"
              name="searchProvider"
              placeholder="Search by last name"
              value={lastNameFilter.value || ''}
              onKeyUp={this.onKeyUp}
              onChange={this.handleChange}
              onInput={this.onKeyUp}
              className={`${lastNameFilter.active ? 'applied' : ''}`}
              data-test="searchProvider-field"
            />
          </div>
        </div>
        <div className="content">
          <table className="billable-providers-table">
            <tbody>
              <tr className="billable-providers-table-title">
                <td
                  className={`sortable physician-name ${this.calculateClass(SORT_VALUES.firstName)}`}
                  onClick={loading ? null : () => (this.sortBy(SORT_VALUES.firstName))}
                >
                  First name
                </td>
                <td
                  className={`sortable physician-name ${this.calculateClass(SORT_VALUES.lastName)}`}
                  onClick={loading ? null : () => (this.sortBy(SORT_VALUES.lastName))}
                >
                  Last name
                </td>
                { isProviderGroupEnabled && <td id="providerGroupHeader">Telehealth Group</td>}
                <td>Billable</td>
                { isNpiRequired && (<td id="npiHeader">NPI</td>) }
                { isEhrIdRequired && (<td id="ehrIdHeader">EHRID</td>) }
                { (locations && locations.length > 0) && (<td id="locationHeader">Location</td>) }
                <td>Active</td>
              </tr>
              {this.renderPhysicians()}
            </tbody>
          </table>

          <div>
            <Pager
              totalPages={totalPages}
              callback={this.goToPage}
              maxShownCount={MAX_SHOWN_PAGES}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const providerGroupEnabledFeature = (state.tenant
    && state.tenant.features
    && state.tenant.features.length > 0
    ? state.tenant.features : [])
    .find(f => f.featureName === TENANT_FEATURES_NAMES.ENABLE_MULTI_TELEHEALTH.name);
  const isProviderGroupEnabled = providerGroupEnabledFeature && providerGroupEnabledFeature.enabled;
  return {
    isProviderGroupEnabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setBillingPhysiciansList: billingPhysiciansListData => dispatch(
      SetBillingPhysiciansList(billingPhysiciansListData),
    ),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillableProviders);
