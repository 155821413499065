import { createAction, createReducer } from '@reduxjs/toolkit';

const cnList = createAction('CN_LIST');

const initialState = [];

const CnList = createReducer(initialState, (builder) => {
  builder
    .addCase(cnList, (state, action) => action.cnList)
    .addDefaultCase(state => state);
});

export default CnList;
