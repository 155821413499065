// Libraries
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Async } from 'react-select';
import { useParams } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
// Services
import { createNextAppointment, updateNextAppointment } from '../../../services/patient';
import { fetchData, validateDate } from '../../../services/helpers';
import { getPhysicianTypeahead } from '../../../services/providers';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient } from '../../../actions/patient';
// Constants
import {
  NOTIFICATION_TYPE, DIALOG_STYLES, DATE_FORMAT,
} from '../../../constants/constants';
// Components
import OneFieldDatePicker from '../../base/OneFieldDatePicker';
import TimeSelector from '../../base/TimeSelector';

export function NextAppointmentModal(props) {
  const { id: patientId } = useParams();
  const {
    isModalOpen, setIsModalOpen, initialPCPAppointment, initialAWVAppointment, timezone,
    showNotification, updatePatient,
  } = props;

  const [alsoAWV, setAlsoAWV] = useState(false);
  const [appointment, setAppointment] = useState(initialPCPAppointment);
  const [noResultsText, setNoResultsText] = useState('Physician not found...');

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDate = (date) => {
    if (date && date.length) {
      return date.substring(0, 10);
    }
    return '';
  };

  const getTime = (date) => {
    if (date && date.length) {
      return moment(date);
    }
    return '';
  };

  const setNextAppointment = () => {
    const newAppointment = {
      id: appointment.id,
      physician: appointment.physician,
      nextAppointmentDate: appointment.date && appointment.time
        ? `${appointment.date} ${appointment.time.format(DATE_FORMAT.ONLY_TIME)}`
        : null,
    };

    if (appointment && appointment.id) {
      const nextPCPRequest = updateNextAppointment(newAppointment);
      const nextPCPPromise = nextPCPRequest.promise;

      return nextPCPPromise.then((data) => {
        delete nextPCPRequest.promise;
        updatePatient({ nextAppointment: data });
        handleCloseModal(false);
      }).catch((error) => {
        delete nextPCPRequest.promise;
        if (error.isCanceled || error.status === 401 || error.status === 403) {
          return;
        }

        showNotification({
          message: 'An error occurred while attempting to save next appointment',
          autoHide: true,
          notificationType: NOTIFICATION_TYPE.ERROR,
        });
      });
    }
    showNotification({
      message: 'No Next Appointment has been created',
      autoHide: true,
      notificationType: NOTIFICATION_TYPE.ERROR,
    });
    return {};
  };

  const saveNextAWV = () => {
    const nextAWV = {
      ...initialAWVAppointment,
      patientId,
      appointmentType: 'AWV',
      nextAppointmentDate: appointment.date && appointment.time
        ? `${appointment.date} ${appointment.time.format(DATE_FORMAT.ONLY_TIME)}`
        : null,
    };

    const isNewAWV = !nextAWV.id;

    const addAWVRequest = isNewAWV
      ? createNextAppointment(nextAWV) : updateNextAppointment(nextAWV);
    const addAWVPromise = addAWVRequest.promise;

    return addAWVPromise.then((data) => {
      delete addAWVPromise.promise;

      updatePatient({ nextWellnessVisit: data });

      setAlsoAWV(false);
      handleCloseModal();
    }).catch((error) => {
      delete addAWVPromise.promise;

      if (error.isCanceled || error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'An error occurred while attempting to save this Annual Wellness Visit',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleDateChange = (key, value, isValid) => {
    if (!isValid) return;

    setAppointment({
      ...appointment,
      date: value,
    });
  };

  const handleTimeChange = (newTime) => {
    setAppointment({
      ...appointment,
      time: newTime,
    });
  };

  const handleSelect = (selectedPhysician) => {
    setAppointment({
      ...appointment,
      physician: selectedPhysician,
    });
  };

  const getPhysicianOption = el => ({
    ...el,
    value: el.id,
    label: `${el.lastName ? `${el.lastName}, ` : ''}${el.firstName ? el.firstName : ''}`,
  });

  const preparePhysiciansList = (physiciansData = []) => {
    const newPhysiciansList = [];

    physiciansData.forEach((el) => {
      newPhysiciansList.push(getPhysicianOption(el));
    });

    newPhysiciansList.sort((a, b) => a.label.localeCompare(b.label));

    return newPhysiciansList;
  };

  const handlePhysiciansAsyncList = async (input) => {
    if (input && input.length > 1) {
      const physiciansData = await fetchData(getPhysicianTypeahead(
        { filter: input, billingStatus: true },
      ));
      return { options: preparePhysiciansList(physiciansData) };
    }
    return { options: [] };
  };

  const getDefaultPhysician = () => {
    const { patient: { primaryPhysicians = [] } } = props;
    const defaultPrimaryPhysician = primaryPhysicians[0];

    if (initialPCPAppointment && initialPCPAppointment.physician) {
      return getPhysicianOption(initialPCPAppointment.physician);
    }
    if (defaultPrimaryPhysician) {
      return getPhysicianOption(defaultPrimaryPhysician);
    }
    return {};
  };

  useEffect(() => {
    setAppointment({
      id: initialPCPAppointment ? initialPCPAppointment.id : null,
      date: initialPCPAppointment ? getDate(initialPCPAppointment.nextAppointmentDate) : '',
      time: initialPCPAppointment ? getTime(initialPCPAppointment.nextAppointmentDate) : '',
      physician: getDefaultPhysician(),
    });
  }, [initialPCPAppointment]);

  return (
    <Modal
      isOpen={isModalOpen}
      style={DIALOG_STYLES}
      onRequestClose={() => handleCloseModal()}
      contentLabel="Edit Next Appointment"
      data-test="nextAppointment_modal"
    >
      <div className="simple-dialog small-dialog">
        <div className="dialog-title">
          Edit PCP Next Appointment
          <button
            type="button"
            className="close-icon i-close"
            onClick={() => handleCloseModal()}
            data-test="nextAppointment_modalCloseButton"
          />
        </div>
        <div className="dialog-content text-left">
          <Form>
            <Form.Group controlId="formAppointmentDate" data-test="nextAppointmentModal_dateField">
              <Form.Label>Date</Form.Label>
              <OneFieldDatePicker
                submitCallback={handleDateChange}
                fieldKey="date"
                initialValue={(appointment && appointment.date) || ''}
                validationCallback={validateDate}
                minDate={moment.tz(timezone).format(DATE_FORMAT.FULL_SERVER)}
                dateName="Next appointment date"
                minDateName="today"
                wrapperClassName={`${appointment && !appointment.date && appointment.time && 'invalid'}`}
                data-test="nextAppointment_datePicker"
              />
            </Form.Group>
            <TimeSelector
              time={(appointment && appointment.time) || null}
              setTime={handleTimeChange}
            />
            <Form.Group controlId="formAppointmentProvider" data-test="nextAppointmentModal_provider">
              <Form.Label>ProviderName</Form.Label>
              <Async
                name="physician"
                placeholder="Select provider"
                noResultsText={noResultsText}
                loadOptions={handlePhysiciansAsyncList}
                value={appointment && appointment.physician}
                onChange={handleSelect}
                onInputChange={input => (input.length < 2 ? setNoResultsText('Type 2 characters min') : setNoResultsText('Physician not found...'))}
                data-test="nextAppointment_physicianSelect"
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="checkbox"
                id="alsoAWV_Check"
                label="This is also an AWV"
                data-test="nextAppointmentModal_alsoAWV_Check"
                checked={alsoAWV}
                onChange={() => setAlsoAWV(!alsoAWV)}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="dialog-buttons justify-content-end px-4">
          <Button
            variant="light"
            onClick={() => handleCloseModal()}
            data-test="nextAppointment_modalCancelButton"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="ml-2"
            disabled={
              (!appointment || !appointment.date || !appointment.time || !appointment.physician)
            }
            onClick={() => {
              setNextAppointment();
              if (alsoAWV) saveNextAWV();
            }}
            data-test="nextAppointment_modalSaveButton"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export function mapStateToProps(state) {
  return {
    patient: state.patient,
    timezone: state.tenant && state.tenant.timezone,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NextAppointmentModal);
