// libraries
import React from 'react';
// views
import ProgressBar from './ProgressBar';

export function CompoundProgressBar(props) {
  const { timePeriod: initialTimePeriod, duration: initialDuration } = props;
  const { isPending } = props;
  const defaultTimePeriod = 20;
  const timePeriod = initialTimePeriod || defaultTimePeriod;
  const maxTime = timePeriod * 3;
  const values = [];

  const duration = Math.min(initialDuration, maxTime);

  for (let i = 0; i < Math.floor(duration / timePeriod); i++) {
    values.push(timePeriod);
  }

  if (duration % timePeriod > 0 || duration === 0) {
    values.push(duration % timePeriod);
  }

  const lines = values.map((value, index) => (
    <ProgressBar
      duration={value}
      max={timePeriod}
      key={`progress-bar${index}`}
      isPending={isPending}
    />
  ));

  return (
    <div className="lines">
      {lines}
    </div>
  );
}

export default CompoundProgressBar;
