// Libraries
import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _camelCase from 'lodash/camelCase';
// views
import { Dropdown, Button } from 'react-bootstrap';
import InputMask from 'react-input-mask';
// Actions
import { UpdatePatient } from '../../../actions/patient';
import ShowNotification from '../../../actions/notification';
// Services
import { validatePhoneRequired } from '../../../services/helpers';
import { saveChoiceInput } from '../../../services/patient';
// constants
import {
  PHONE_MASK, NOTIFICATION_TYPE, KEY_CODE, NONE_SPECIFIED_STRING,
} from '../../../constants/constants';

const DEFAULT_VALUES = {
  OTHER: 'other',
};

export function DropdownSelect(props) {
  const [isValid, setIsValid] = useState(true);
  const [customTextValue, setCustomTextValue] = useState();
  const [validationMessage, setValidationMessage] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const {
    hookValue, setHookValue, haveCustomPhone, updatePatient,
    customValue, setCustomValue, items = [], format, iconClass, patient,
    multiSelect = false, configuration, patientId, showNotification,
    handleSaveAction, disabled,
  } = props;
  const defaultValue = items && items.filter(item => item.default)[0]
    ? items.filter(item => item.default)[0].name : 'None';
  const stringHookValue = Array.isArray(hookValue) && hookValue.length ? hookValue.join(', ') : hookValue;
  let selectedValue;
  switch (typeof stringHookValue) {
    case 'boolean':
      selectedValue = stringHookValue;
      break;
    case 'string':
      selectedValue = stringHookValue !== 'NONE' ? stringHookValue : NONE_SPECIFIED_STRING;
      break;
    default:
      selectedValue = defaultValue;
  }
  const initialValues = items.reduce((accumulator, currentValue) => {
    accumulator[currentValue.value] = currentValue.active;
    return accumulator;
  }, {});

  useEffect(() => {
    if (items && items.length) {
      const otherIndex = items.map(item => item.value).indexOf(DEFAULT_VALUES.OTHER);
      if (otherIndex !== -1) {
        setCustomTextValue(items[otherIndex].active);
      }
    }
  }, [items]);

  const saveCheckboxInput = (values) => {
    if (!configuration || !configuration.endpoint) {
      return null;
    }
    const saveChoiceInputRequest = saveChoiceInput(configuration.endpoint, values, patientId);
    const saveChoiceInputPromise = saveChoiceInputRequest.promise;

    return saveChoiceInputPromise.then((data) => {
      delete saveChoiceInputRequest.promise;
      updatePatient({
        [configuration.reduxName]: {
          ...patient[configuration.reduxName],
          values: data,
        },
      });
    }).catch((error) => {
      delete saveChoiceInputRequest.promise;
      if (error.isCanceled) {
        return;
      }

      if (error.status === 401 || error.status === 403) {
        return;
      }

      showNotification({
        message: 'Could not update the field',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const handleCustomPhoneSubmit = (value) => {
    const validationResult = value ? validatePhoneRequired(value) : true;
    if (typeof validationResult === 'boolean') {
      if (value && value.replace(/\D/g, '')) {
        setHookValue(value);
        handleSaveAction(value.replace(/\D/g, ''));
      }
      setIsValid(true);
    } else {
      setIsValid(false);
      setValidationMessage(validationResult);
    }
  };

  const handleCustomPhoneChange = (value) => {
    const validationResult = value ? validatePhoneRequired(value) : true;
    setCustomValue(value);
    if (typeof validationResult === 'boolean') {
      setIsValid(true);
    } else {
      setIsValid(false);
      setValidationMessage(validationResult);
    }
  };

  const onKeyUp = (event) => {
    if (event.keyCode === KEY_CODE.ENTER) {
      handleCustomPhoneSubmit(event.target.value);
    }
  };

  const handleSaveOtherOption = (item, value) => {
    if (value !== item.active) {
      initialValues[item.value] = item.value !== DEFAULT_VALUES.OTHER
        ? !initialValues[item.value] : customTextValue;
      if (hookValue.includes(item.name) && !value) {
        hookValue.splice(hookValue.indexOf(item.name), 1);
        setHookValue(hookValue);
      } else if (!hookValue.includes(item.name)) {
        setHookValue([...hookValue, item.name]);
      }
      saveCheckboxInput(initialValues);
    }
  };

  const dropDownItems = items.map((item, index) => {
    const getOptionText = () => (haveCustomPhone
      ? <span>{`${item.name}: ${format ? format(item.value) : item.value}`}</span>
      : (
        <Fragment>
          <span>{item.name}</span>
          {item.extraLabel && <span className="text-ccm-bali-hai ml-1">{item.extraLabel}</span>}
        </Fragment>));

    return (
      <Dropdown.Item
        data-test={`dropdownSelect_${_camelCase(item.name)}_${index}`}
        key={`contact_${_camelCase(item.name)}_${index}`}
        onClick={() => {
          setHookValue(item.value);
          if (handleSaveAction) handleSaveAction(item.value);
        }}
        as="button"
        className="d-flex my-1"
      >
        <div className="w-100 pr-3">
          { iconClass && <span className={iconClass} />}
          {getOptionText()}
        </div>
      </Dropdown.Item>
    );
  });


  const customText = item => (
    <div
      key={`custom_text_${item.name}`}
      className="px-3"
    >
      <div className="dropdown-item p-0">
        <input
          data-test="dropdownSelect_customText"
          type="checkbox"
          className="mr-2"
          checked={item.active || isChecked}
          onClick={() => {
            if (item.active || isChecked) {
              initialValues[item.value] = item.value !== DEFAULT_VALUES.OTHER
                ? !initialValues[item.value] : '';
              if (customTextValue && customTextValue !== '') {
                if (hookValue.includes(item.name) && customTextValue) {
                  hookValue.splice(hookValue.indexOf(item.name), 1);
                  setHookValue(hookValue);
                } else if (!hookValue.includes(item.name)) {
                  setHookValue([...hookValue, item.name]);
                }
                setCustomTextValue('');
                saveCheckboxInput(initialValues);
              }
              setIsChecked(false);
            } else {
              setIsChecked(true);
            }
          }}
        />
        <span>{`${item.name}:`}</span>
      </div>
      {
        (item.active || isChecked) && (
          <Dropdown.ItemText className="py-2 d-flex-center flex-column">
            <div className="d-flex flex-nowrap pt-1">
              <InputMask
                className="border mr-1"
                type="text"
                fieldKey="customText"
                value={customTextValue}
                onChange={e => setCustomTextValue(e.target.value)}
                onKeyUp={(e) => {
                  if (e.keyCode === KEY_CODE.ENTER && (e.target.value !== item.active)) {
                    initialValues[item.value] = item.value !== DEFAULT_VALUES.OTHER
                      ? !initialValues[item.value] : customTextValue;
                    if (hookValue.includes(item.name) && !e.target.value) {
                      hookValue.splice(hookValue.indexOf(item.name), 1);
                      setHookValue(hookValue);
                    } else if (!hookValue.includes(item.name)) {
                      setHookValue([...hookValue, item.name]);
                    }
                    saveCheckboxInput(initialValues);
                  }
                }}
                name="customPhone"
                maskChar=" "
                data-test="dropdownSelect_addCustomText"
              />
              <Button
                data-test="dropdownSelect_saveButton"
                size="xs"
                variant="primary"
                onClick={() => handleSaveOtherOption(item, customTextValue)}
              >
                Save
              </Button>
            </div>
          </Dropdown.ItemText>
        )
      }
    </div>
  );

  const multiSelectDropDownItems = items.map((item, index) => {
    let optionText = item.name;
    optionText = haveCustomPhone ? `${item.name}: ${format ? format(item.value) : item.value}` : optionText;
    if (item.value === DEFAULT_VALUES.OTHER) {
      return customText(item);
    }
    return (
      <div
        key={`custom_text_${item.name}`}
        className="d-flex align-items-center px-3"
      >
        { iconClass && <i className={iconClass} />}
        <input
          type="checkbox"
          className="mr-1"
          checked={item.active}
          onClick={() => {
            if (hookValue.includes(item.name)) {
              hookValue.splice(hookValue.indexOf(item.name), 1);
              setHookValue(hookValue);
            } else {
              setHookValue([...hookValue, item.name]);
            }
            initialValues[item.value] = !initialValues[item.value];
            saveCheckboxInput(initialValues);
          }}
        />
        <Dropdown.ItemText
          key={`contact_${item.name}_${index}`}
          className="d-flex px-1 text-nowrap"
        >
          { optionText }
        </Dropdown.ItemText>
      </div>
    );
  });

  const validationLabel = <span className="invalid-feedback d-block text-center">{validationMessage}</span>;

  const customPhone = (
    <Dropdown.ItemText
      key="custom_phone"
      className="py-2 d-flex-center flex-column"
    >
      <div className="d-flex-center flex-nowrap">
        Custom:
        <InputMask
          className="border rounded mx-1"
          type="text"
          value={customValue}
          onChange={e => handleCustomPhoneChange(e.target.value)}
          name="customPhone"
          placeholder="Type a custom phone"
          onKeyUp={onKeyUp}
          mask={PHONE_MASK}
          maskChar=" "
          data-test="dropdownSelect_addCustomPhone"
        />
        <Button
          data-test="dropdownSelect_customPhoneButton"
          size="xs"
          variant="primary"
          onClick={() => handleCustomPhoneSubmit(customValue)}
          disabled={!isValid}
        >
          Select
        </Button>
      </div>
      {!isValid && validationLabel}
    </Dropdown.ItemText>
  );

  const getValueName = (value) => {
    const item = items.find(el => el.value === value);
    return item ? item.name : value;
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="link"
        className="py-0"
        disabled={disabled}
      >
        {format ? `${format(selectedValue)}` : getValueName(selectedValue)}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {multiSelect ? multiSelectDropDownItems : dropDownItems}
        {haveCustomPhone && customPhone}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function mapStateToProps(state) {
  return {
    patient: state.patient,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownSelect);
