// libraries
import React, { Component } from 'react';
// views
import Team from './administration/Team';
import Workload from './administration/Workload';
import CarePlan from './administration/CarePlan';
import EligibilityRules from './administration/EligibilityRules';
import Features from './administration/Features';

export class Administration extends Component {
  constructor(props) {
    super(props);

    this.refs = {};

    this.reloadWorkloadData = this.reloadWorkloadData.bind(this);
  }

  saveRef(name, ref) {
    this.refs = {
      ...this.refs,
      [name]: ref,
    };
  }

  reloadWorkloadData() {
    try {
      this.refs.workload.getWrappedInstance().getWorkloadData();
    } catch (err) {
    }
  }

  render() {
    return (
      <div className="h-100 overflow-auto pr-3">
        <h4 className="text-uppercase text-left my-3" data-test="administration_title">Administration</h4>
        <Team
          changeRolesCallback={this.reloadWorkloadData}
        />
        <Workload wrappedComponentRef={this.saveRef.bind(this, 'workload')} />
        <CarePlan />
        <Features />
        <EligibilityRules />
      </div>
    );
  }
}

export default Administration;
