// libraries
import React, {
  useState, Fragment, useEffect, useContext,
} from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import {
  Accordion, Card, Dropdown, Button,
} from 'react-bootstrap';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
// views
import ImmunizationsModal from './ImmunizationsModal';
// constants
import { EMPTY_STRING, DATE_FORMAT, NOTIFICATION_TYPE } from '../../../constants/constants';
// services
import { deletePatientImmunization } from '../../../services/patient';
// Actions
import ShowNotification from '../../../actions/notification';
import { UpdatePatient, UpdatePatientHistory } from '../../../actions/patient';
// Hooks
import useConfirm from '../../../hooks/useConfirm';

const IMMUNIZATIONS_FILTERS = { ALL: 'All' };


const IMMUNIZATIONS_EHR = 'EHR';

export function Immunizations(props) {
  const [collapseAll, setCollapseAll] = useState(true);
  const { patientHistory: { immunizations } = {}, patientId } = props;
  const [immunizationsFilter, setImmunizationsFilter] = useState(IMMUNIZATIONS_FILTERS.ALL);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImmunization, setSelectedImmunization] = useState(null);
  const { isConfirmed } = useConfirm();

  const getModal = (
    <Fragment>
      <Button
        data-test="immunization_addBtn"
        size="sm"
        variant="link-dark"
        className="position-absolute"
        style={{ right: 10 }}
        onClick={() => {
          setSelectedImmunization(null);
          return setIsModalOpen(true);
        }}
      >
        <i className="d-flex-center bi-plus-lg" />
      </Button>
      <ImmunizationsModal
        patientId={patientId}
        initialImmunization={selectedImmunization}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Fragment>
  );

  const filterDropDownItems = Object.keys(IMMUNIZATIONS_FILTERS).map((key, index) => (
    <Dropdown.Item
      data-test={`immunizations_dropdownFilter_${key}`}
      key={`immunizations_filter_${index}`}
      onClick={() => {
        setImmunizationsFilter(IMMUNIZATIONS_FILTERS[key]);
      }}
      as="button"
      className="d-flex my-1"
    >
      <div className="pr-3">{IMMUNIZATIONS_FILTERS[key]}</div>
    </Dropdown.Item>
  ));

  const getFilter = (
    <Dropdown
      className="position-absolute"
      style={{ left: 1 }}
    >
      <Dropdown.Toggle
        variant="ccm-light-gray"
        className="py-0"
      >
        {`Filter: ${immunizationsFilter}`}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filterDropDownItems}
      </Dropdown.Menu>
    </Dropdown>
  );

  function renderEmptyRow() {
    return (
      <tr data-test="immunization_emptyMsg">
        <td colSpan="4" className="p-2 border-0">
          This patient has no known immunizations
        </td>
      </tr>
    );
  }

  const handleAddEditImmunization = (immunization) => {
    setSelectedImmunization(immunization);
    setIsModalOpen(true);
  };

  const handleDeleteImmunization = async (immunization) => {
    const {
      updatePatient, updatePatientHistory, showNotification, patientHistory,
    } = props;
    const confirmed = await isConfirmed('Are you sure you want to remove the selected immunization?');

    if (!confirmed) {
      return;
    }

    const deleteImmunizationRequest = deletePatientImmunization(patientId, immunization.id);
    const deleteImmunizationPromise = deleteImmunizationRequest.promise;

    deleteImmunizationPromise.then(() => {
      delete deleteImmunizationRequest.promise;
      const updatedImmunizations = immunizations.filter(p => p.id !== immunization.id);

      updatePatient({
        immunizations: updatedImmunizations,
      });
      updatePatientHistory({
        ...patientHistory,
        immunizations: updatedImmunizations,
      });
    }).catch((error) => {
      delete deleteImmunizationRequest.promise;
      if (error.isCanceled) {
        return;
      }
      showNotification({
        message: 'Could not delete patient immunization, please try again later',
        autoHide: true,
        notificationType: NOTIFICATION_TYPE.ERROR,
      });
    });
  };

  const renderImmunizationsRows = (immunizationsData) => {
    const isEHR = value => (value && value.source === IMMUNIZATIONS_EHR);
    if (immunizationsData && immunizationsData.length) {
      const sortedImmunizationsByDate = _reverse(
        _sortBy(immunizationsData, el => moment(el.date)),
      );
      return sortedImmunizationsByDate.map((immunization, index) => (
        <tr key={`immunization_row_${index}_${immunization.date}-${immunization.name}-${immunization.manufacturer}`} data-test="immunization_entryRow">
          <td colSpan="6" className="p-0">
            <Accordion>
              <Card className="border-0 rounded-0">
                <Card.Header className="bg-light border-0 p-0">
                  <table className={`table mb-0 ${isEHR(immunization) ? '' : 'table-hover'}`}>
                    <tbody>
                      <tr className="immunization__row">
                        <td className="immunization__date" onClick={() => (isEHR(immunization) ? {} : handleAddEditImmunization(immunization))} data-test="immunization_editBtn">
                          {immunization.date
                            ? moment(immunization.date).format(DATE_FORMAT.SHORT) : EMPTY_STRING
                          }
                        </td>
                        <td className="immunization__source" onClick={() => (isEHR(immunization) ? {} : handleAddEditImmunization(immunization))} data-test="immunization_editBtn">
                          {immunization.source || EMPTY_STRING}
                        </td>
                        <td className="immunization__name" onClick={() => (isEHR(immunization) ? {} : handleAddEditImmunization(immunization))} data-test="immunization_editBtn">
                          {immunization.name || EMPTY_STRING}
                        </td>
                        <td className="immunization__manufacturer" onClick={() => (isEHR(immunization) ? {} : handleAddEditImmunization(immunization))} data-test="immunization_editBtn">
                          {immunization.manufacturer || EMPTY_STRING}
                        </td>
                        <td className="immunization__status" onClick={() => (isEHR(immunization) ? {} : handleAddEditImmunization(immunization))} data-test="immunization_editBtn">
                          {immunization.status || EMPTY_STRING}
                        </td>
                        <td className="immunization__action px-0 text-right">
                          {!isEHR(immunization) && (
                            <Button variant="link-dark" className="pl-0 pr-3" onClick={() => handleDeleteImmunization(immunization)} data-test="immunization_deleteBtn">
                              <i className="bi-trash" />
                            </Button>
                          )}
                          <CustomToggle eventKey="1" collapseAll={collapseAll} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className="d-flex">
                      <p className="mr-2">Note:</p>
                      <div className="flex-grow-1">
                        <i>{immunization.note ? immunization.note : EMPTY_STRING}</i>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </td>
        </tr>
      ));
    }
    return renderEmptyRow();
  };

  return (
    <div className="history-immunizations card border-0 mb-4">
      <div className="card-header rounded-0 bg-ccm-light-gray border text-ccm-bismark d-flex-center py-1">
        {getFilter}
        <span className="text-uppercase">Immunizations</span>
        <Button
          size="sm"
          variant="link-dark"
          className="d-flex-center position-absolute"
          style={{ right: '3rem' }}
          onClick={() => setCollapseAll(!collapseAll)}
          data-test="immunization_collapseAllBtn"
        >
          <span>{`${collapseAll ? 'Expand' : 'Collapse'} all`}</span>
          <i className={`d-flex-center bi-caret-${collapseAll ? 'down' : 'up'}-fill ml-1`} />
        </Button>
        {getModal}
      </div>
      <div className="card-body ccm-table-container px-0">
        <table className="table w-100 text-left">
          <thead>
            <tr>
              <th className="py-2 immunization__date" data-test="immunization_columnDate">Date</th>
              <th className="py-2 immunization__source" data-test="immunization_columnReporter">Reporter</th>
              <th className="py-2 immunization__name" data-test="immunization_columnName">Name</th>
              <th className="py-2 immunization__manufacturer" data-test="immunization_columnManufacturer">Manufacturer</th>
              <th className="py-2 immunization__status" data-test="immunization_columnStatus">Status</th>
              <th className="py-2 immunization__action px-0" data-test="immunization_columnAction" />
            </tr>
          </thead>
          <tbody>
            {renderImmunizationsRows(immunizations)}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const CustomToggle = ({ eventKey, collapseAll }) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKey;
  const decoratedOnClick = useAccordionToggle(eventKey, () => {});

  useEffect(() => {
    if ((collapseAll && isCurrentEventKey) || (!collapseAll && !isCurrentEventKey)) {
      decoratedOnClick();
    }
  }, [collapseAll]);

  return (
    <Button variant="link-dark" className="p-0 pr-1" onClick={decoratedOnClick}>
      <i className={`d-flex-center bi-caret-${isCurrentEventKey ? 'up' : 'down'}-fill`} />
    </Button>
  );
};

export function mapStateToProps(state) {
  return {
    patientHistory: state.patient && state.patient.patientHistory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePatient: patientData => dispatch(UpdatePatient(patientData)),
    updatePatientHistory: patientHistoryData => dispatch(UpdatePatientHistory(patientHistoryData)),
    showNotification: notificationData => dispatch(ShowNotification(notificationData)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Immunizations);
